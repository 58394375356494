import { FC, useEffect } from 'react';
import useQueryNoCache from 'utils/hooks/useQueryNoCache';
import { QueryKey } from 'components/insights/constants';
import AnalyticsApi from 'api/analytics';
import InitializationStatus from 'app/modules/insights/components/InitializationStatus';
import { modalRoutes } from 'constants/routes';
import { useModalHistory } from 'context/ModalRouter';
import { ApiClientError } from 'api/client';

type Props = {
  children: JSX.Element;
};

const InsightsInitializationGate: FC<Props> = (props) => {
  const history = useModalHistory();
  const initialization = useQueryNoCache(
    QueryKey.initialization(),
    async () => {
      const {
        hasLinkedInConnected,
        hasFacebookConnected,
        isFirstTimeInAnalytics,
      } = await AnalyticsApi.userStatus();

      const noPagesConnected = !hasFacebookConnected && !hasLinkedInConnected;

      if (noPagesConnected) {
        throw new ApiClientError({
          userMessage: 'User has not connected any profiles.',
        });
      }

      if (isFirstTimeInAnalytics) {
        const { completed } = await AnalyticsApi.loadPages();

        return completed;
      }

      return true;
    },
  );

  const isInitialized = !!initialization.data;

  const isLoading = initialization.isLoading;

  useEffect(() => {
    if (!isInitialized && !isLoading && history.activeModals.length === 0) {
      history.replace({
        hash: modalRoutes.WELCOME,
      });
    }
  }, [isInitialized, isLoading, history, history.activeModals]);

  if (isLoading) {
    return <InitializationStatus status="loading" />;
  }

  if (isInitialized) {
    return props.children;
  }

  return null;
};
export default InsightsInitializationGate;
