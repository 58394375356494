import { get, httpDelete, post } from 'api/client';
import env from 'config';
import type {
  Pagination,
  Order,
  Filter,
  ExportAdsToFacebookApiResponse,
  Ad,
  AdArguments,
} from 'types/Ad';
import { sanitizeNotAllowedMediumKeys } from 'utils/post';
import { AdsAPIResponse, ExternalAdPreviewsResponse } from 'api/adsplanner';

export const ad = {
  get: (id: number): Promise<Ad> => {
    return get(`ads-planner/ad/${id}`, {
      baseURL: env.API_SERVICE_ENDPOINT,
    });
  },
  all: (
    adSetId: number,
    pagination: Pagination,
    order: Order,
    filter: Filter,
  ): Promise<AdsAPIResponse> => {
    return get('ads-planner/ad', {
      baseURL: env.API_SERVICE_ENDPOINT,
      params: {
        adSetId,
        limit: pagination.limit,
        offset: pagination.offset,
        orderBy: order.orderBy,
        orderDirection: order.orderDirection,
        tags: filter.tags.length ? filter.tags.join() : [],
        workflow_statuses: filter.workflowStatuses.length
          ? filter.workflowStatuses.join()
          : [],
      },
    });
  },
  externalPreviews: (id: number): Promise<ExternalAdPreviewsResponse> => {
    return get(`ads-planner/ad/${id}/get-ad-previews`, {
      baseURL: env.API_SERVICE_ENDPOINT,
    });
  },
  save: (params: {
    id?: number;
    adSetId?: number;
    data: AdArguments;
  }): Promise<Ad> => {
    const sanitizedData = sanitizeNotAllowedMediumKeys(params.data);

    if (params.id) {
      return post(`ads-planner/ad/${params.id}`, sanitizedData, {
        baseURL: env.API_SERVICE_ENDPOINT,
      });
    }

    return post('ads-planner/ad', sanitizedData, {
      baseURL: env.API_SERVICE_ENDPOINT,
      params: {
        ad_set_id: params.adSetId,
      },
    });
  },
  export: (
    ids: number[],
    adAccountId: number,
  ): Promise<ExportAdsToFacebookApiResponse> => {
    return post(
      `ads-planner/ad/export`,
      {
        ads: ids.join(),
        adAccountId,
      },
      {
        baseURL: env.API_SERVICE_ENDPOINT,
      },
    );
  },
  delete: (id: number): Promise<Ad> => {
    return httpDelete(`ads-planner/ad/${id}`, {
      baseURL: env.API_SERVICE_ENDPOINT,
    });
  },
  duplicate: (params: {
    ads: number[];
    adSets: number[];
    adAccountId: number;
  }): Promise<AdsAPIResponse> => {
    return post(
      'ads-planner/ad/duplicate',
      {
        ad_account_id: params.adAccountId,
        ads: params.ads.join(),
        ad_sets: params.adSets.join(),
      },
      {
        baseURL: env.API_SERVICE_ENDPOINT,
      },
    );
  },
  move: (params: {
    ads: number[];
    adSets: number[];
    adAccountId: number;
  }): Promise<AdsAPIResponse> => {
    return post(
      'ads-planner/ad/move',
      {
        ad_account_id: params.adAccountId,
        ads: params.ads.join(),
        ad_sets: params.adSets.join(),
      },
      {
        baseURL: env.API_SERVICE_ENDPOINT,
      },
    );
  },
};
