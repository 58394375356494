import env from 'config';
import { get } from 'api/client';
import { ExternalFacebookAdAccount } from 'types/AdProfile';
import { AssociatedInstagramAccountsResponse } from 'api/adsplanner';

export const adProfile = {
  allRemote: (
    adAccountId: number,
  ): Promise<{
    availableFacebookProfiles: ExternalFacebookAdAccount[];
  }> => {
    return get('ads-planner/ad-profile/available-on-remote', {
      baseURL: env.API_SERVICE_ENDPOINT,
      params: {
        adAccountId,
      },
    });
  },
  allInstagramAssociated: (params: {
    pageExternalId: string;
    adAccountId: number;
  }): Promise<AssociatedInstagramAccountsResponse> => {
    return get('ads-planner/ad-profile/associated-ig-accounts', {
      baseURL: env.API_SERVICE_ENDPOINT,
      params,
    });
  },
};
