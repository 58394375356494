import { get, post } from 'api/client';
import { MentionableUser, UserAssignedToPage } from 'types/User';
import { FacebookPages, InstagramPages } from 'types/Facebook';
import { PageData, PageDetail } from 'types/Page';
import { jsonToFormData } from 'utils/formData';
import { getExtendedTimeout } from 'utils/request';
import config from 'config';

type CreateParams = {
  name?: string;
  userName?: string;
  type: number;
  logo?: File | null;
};

type CreateFacebookParams = {
  pages: FacebookPages;
  token: string;
};

type CreateInstagramParams = {
  pages: InstagramPages;
  token: string;
};

type CreateLinkedinParams = {
  profileId?: string;
};

type UpdatePageParams = {
  id: number;
  timezone?: string;
};

type CreateGoogleMyBusinessParams = {
  selectedLocations: {
    accountId: string;
    locationId: string;
  }[];
  accessTokenId: number;
};

const PageApi = {
  async getAll(): Promise<ApiResponse<PageData[]>> {
    return get<ApiResponse<PageData[]>>('/pages', {
      baseURL: config.API_SERVICE_ENDPOINT,
    });
  },
  detail(id: number): Promise<PageDetail> {
    return get(`/page/${id}`, {
      baseURL: config.API_SERVICE_ENDPOINT,
    });
  },
  assignedUsers(ids: number[]): Promise<UserAssignedToPage[]> {
    return get('/pages/ajaxGetAssignedUsers', {
      params: {
        ids: JSON.stringify(ids),
      },
    });
  },
  create(params: CreateParams): Promise<{ id: number }> {
    const formData = new FormData();

    if (params.name) {
      formData.append('name', params.name);
    }

    if (params.userName) {
      formData.append('username', params.userName);
    }

    formData.append('type', params.type.toString());

    if (params.logo) {
      formData.append('medialibrary_files_logo[0]', params.logo);
    }

    return post('/pages/ajaxCreatePage', formData).then((data: any) => {
      if (typeof data?.id === 'string') {
        data.id = Number(data.id);
      }

      return data;
    });
  },
  update(params: UpdatePageParams) {
    return post('/pages/ajaxUpdatePage', jsonToFormData(params));
  },
  createPinterest(boardId: string): Promise<{ id: number }> {
    const formData = new FormData();

    formData.append('pinterestBoardIdent', boardId);

    return post('/pages/ajaxCreatePinterestPage', formData);
  },
  createGoogleMyBusiness(
    params: CreateGoogleMyBusinessParams,
  ): Promise<{ ids: number[] }> {
    const formData = new FormData();

    formData.append(
      'selectedLocations',
      JSON.stringify(params.selectedLocations),
    );
    formData.append('accessTokenId', params.accessTokenId.toString());

    return post('/pages/ajaxCreateGoogleMyBusinessPage', formData, {
      timeout: getExtendedTimeout(3),
    });
  },
  createFacebook(params: CreateFacebookParams): Promise<{ id: number[] }> {
    const formData = new FormData();

    formData.append('userAccessToken', params.token);
    formData.append('pages', JSON.stringify(params.pages));

    return post('/pages/ajaxCreateFacebookPages', formData);
  },
  createInstagram(params: CreateInstagramParams): Promise<{ ids: number[] }> {
    const formData = new FormData();

    formData.append('userAccessToken', params.token);
    formData.append('pages', JSON.stringify(params.pages));

    return post('/Pages/ajaxCreateAuthorizedInstagramPages', formData);
  },
  createLinkedin(params: CreateLinkedinParams): Promise<{ id: number }> {
    if (!params.profileId) {
      // creating personalProfile
      return post('/pages/ajaxCreateNewLinkedInProfile');
    }

    const formData = new FormData();

    formData.append('profileId', params.profileId);

    return post('/pages/ajaxCreateLinkedInPage', formData);
  },
  mentionableUsers(pageId: number): Promise<MentionableUser[]> {
    return get('/user/ajaxGetMentionableUsers', {
      params: { id: pageId },
      timeout: getExtendedTimeout(),
    });
  },
};

export default PageApi;
