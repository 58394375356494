import {
  faCirclePlus,
  faDisplay,
  faMobile,
} from '@fortawesome/pro-regular-svg-icons';
import { faTableLayout } from '@fortawesome/pro-solid-svg-icons';
import { PostPreviewVariant } from '@kontentino/kontentino-post-previews';
import { Icon, Switch } from '@kontentino/ui';
import React, { FC } from 'react';

type Props = {
  availableVariants?: PostPreviewVariant[];
  activeVariant: PostPreviewVariant;
  onVariantChange(variant: PostPreviewVariant): void;
};

const PostPreviewLayoutSwitch: FC<Props> = ({
  availableVariants = ['desktop', 'mobile'],
  activeVariant,
  onVariantChange,
}) => {
  return (
    <Switch.List className="tw-mb-3 tw-w-full tw-max-w-[100px]">
      {VARIANTS.filter((type) => availableVariants.includes(type.key)).map(
        (variant) => {
          return (
            <Switch.Tab
              key={variant.key}
              active={variant.key === activeVariant}
              onClick={() => onVariantChange(variant.key as PostPreviewVariant)}
              type="button"
              data-name={`type-switch-item-${variant.key}`}
              data-cy={`type-switch-item-${variant.key}`}
            >
              {variant.icon && <Icon icon={variant.icon} size="md" />}
            </Switch.Tab>
          );
        },
      )}
    </Switch.List>
  );
};

export const VARIANTS = [
  {
    key: 'desktop',
    icon: faDisplay,
    label: 'Desktop',
  },
  {
    key: 'rightColumn',
    icon: faTableLayout,
    label: 'Right side',
  },
  {
    key: 'mobile',
    icon: faMobile,
    label: 'Mobile',
  },

  {
    key: 'story',
    icon: faCirclePlus,
    label: 'Story',
  },
] as const;

export default PostPreviewLayoutSwitch;
