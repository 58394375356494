import { FC, useState } from 'react';
import { Provider } from 'components/adsPlanner/context';
import {
  defaultEndDate,
  defaultStartDate,
  getInitialFilters,
} from 'components/adsPlanner/utils/filters';
import { deleteQueryParameter, setQueryParameter } from 'utils/url';
import type { EffectiveStatuses, Objective } from 'types/Ad';
import type { PostStatuses } from 'types/Post';
import { OrderDirection } from 'app/types';
import { Dayjs } from 'dayjs';
import DateUtils from 'app/utils/date';

export type AdsPlannerState = {
  filters: FilterState;
  setSelectedDateRange: (from: Dayjs | null, to: Dayjs | null) => void;
  changeOrderDirection: (direction: OrderDirection) => void;
  changeSelectedLabels: (labels: number[]) => void;
  changeSelectedWorkflowStatuses: (statuses: PostStatuses) => void;
  changeSelectedEffectiveStatuses: (statuses: EffectiveStatuses) => void;
  changeSelectedObjectives: (objectives: Objective[]) => void;
  changeSearchQuery: (query: string) => void;
};

export type FilterState = {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  orderDirection: OrderDirection;
  selectedLabels: number[];
  selectedWorkflowStatuses: PostStatuses;
  selectedEffectiveStatuses: EffectiveStatuses;
  searchQuery: string;
  selectedObjectives: Objective[];
};

type Props = {
  children: (props: AdsPlannerState) => JSX.Element;
};

const Container: FC<Props> = ({ children }) => {
  const [filters, setFilters] = useState<FilterState>(getInitialFilters);

  const setSelectedDateRange = (
    dateFrom: Dayjs | null,
    dateTo: Dayjs | null,
  ) => {
    const startDate = dateFrom ?? defaultStartDate;
    const endDate = dateTo ?? defaultEndDate;

    setFilters((prevFilters) => ({
      ...prevFilters,
      startDate,
      endDate,
    }));
    setQueryParameter('from', DateUtils.toDateString(startDate));
    setQueryParameter('to', DateUtils.toDateString(endDate));
  };

  const changeOrderDirection = (direction: OrderDirection) => {
    setFilters((prevFilters) => {
      if (direction === 'asc') {
        deleteQueryParameter('order');
      } else {
        setQueryParameter('order', direction);
      }

      return {
        ...prevFilters,
        orderDirection: direction,
      };
    });
  };

  const changeSelectedWorkflowStatuses = (statuses: PostStatuses) => {
    setFilters((prevFilters) => {
      const newStatuses = [...statuses];

      if (newStatuses.length) {
        setQueryParameter('workflow_statuses', statuses);
      } else {
        deleteQueryParameter('workflow_statuses');
      }

      return {
        ...prevFilters,
        selectedWorkflowStatuses: newStatuses,
      };
    });
  };

  const changeSelectedEffectiveStatuses = (statuses: EffectiveStatuses) => {
    setFilters((prevFilters) => {
      const newStatuses = [...statuses];

      if (newStatuses.length) {
        setQueryParameter('effective_statuses', newStatuses);
      } else {
        deleteQueryParameter('effective_statuses');
      }

      return {
        ...prevFilters,
        selectedEffectiveStatuses: newStatuses,
      };
    });
  };

  const changeSelectedLabels = (labels: number[]) => {
    setFilters((prevFilters) => {
      const newLabels = [...labels];

      if (newLabels.length) {
        setQueryParameter('labels', newLabels);
      } else {
        deleteQueryParameter('labels');
      }

      return {
        ...prevFilters,
        selectedLabels: newLabels,
      };
    });
  };

  const changeSelectedObjectives = (objectives: Objective[]) => {
    setFilters((prevFilters) => {
      const newObjectives = [...objectives];

      if (newObjectives.length) {
        setQueryParameter('objectives', newObjectives);
      } else {
        deleteQueryParameter('objectives');
      }

      return {
        ...prevFilters,
        selectedObjectives: newObjectives,
      };
    });
  };

  const changeSearchQuery = (query: string) => {
    setFilters((prevFilters) => {
      if (query.length === 0) {
        deleteQueryParameter('search');
      } else {
        setQueryParameter('search', query);
      }

      return {
        ...prevFilters,
        searchQuery: query,
      };
    });
  };

  const stateValues = {
    filters,
    setSelectedDateRange,
    changeOrderDirection,
    changeSelectedWorkflowStatuses,
    changeSelectedEffectiveStatuses,
    changeSelectedLabels,
    changeSelectedObjectives,
    changeSearchQuery,
  };

  return <Provider value={stateValues}>{children(stateValues)}</Provider>;
};

export default Container;
