import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { PageNumberType } from '@kontentino/kontentino-constants/Pages';

export enum ProfileSelectorFilterOptionKey {
  All = 'all',
  Profiles = 'profiles',
  ProfileGroups = 'profileGroups',
  Selected = 'selected',
}

export type ProfileSelectorPageTypes = Exclude<PageNumberType, 0 | 7>;
export type ProfileSelectorFilterOptionProperty = {
  key: ProfileSelectorFilterOptionKey | ProfileSelectorPageTypes;
  name: string;
  icon: IconDefinition;
  iconColor?: string;
  i18nKey?: string;
};
