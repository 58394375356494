import { FC } from 'react';
import style from './profileSelectorSearch/ProfileSelectorSearch.module.scss';
import { useTranslation } from 'react-i18next';
import { Icon, TextInput } from '@kontentino/ui';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';

type Props = {
  value: string;
  onChange: (value: string) => void;
};

const ProfileSelectorSearch: FC<Props> = ({ value, onChange }) => {
  const { t } = useTranslation();

  return (
    <div className={style.searchWrapper}>
      <TextInput
        placeholder={`${t('search')}...`}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        autoFocus
        iconBefore={<Icon icon={faSearch} className="tw-text-grayscale-120" />}
      />
      {value.length > 0 && (
        <div className={style.clearButton} onClick={() => onChange('')}>
          Clear
        </div>
      )}
    </div>
  );
};

export default ProfileSelectorSearch;
