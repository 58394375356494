import { ActivityIndicator, Alert } from '@kontentino/ui';
import i18n from 'i18n';
import { FC } from 'react';

type Props = { error: string };

const AdAccountsInitLoader: FC<Props> = ({ error }) => {
  let bodyComponent = null;

  if (error.length) {
    bodyComponent = <Alert variant="danger">{error}</Alert>;
  } else {
    bodyComponent = (
      <>
        <ActivityIndicator className="tw-h-10 tw-w-10" />
        <div className="tw-mt-6" data-cy="ads-planner-init-loader">
          {i18n.loadingAdsPlanner}
        </div>
      </>
    );
  }

  return (
    <div className="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center">
      {bodyComponent}
    </div>
  );
};

export default AdAccountsInitLoader;
