import React, { FC } from 'react';
import InitializingSpinner from 'app/modules/insights/components/initializingStatus/InitializingSpinner';
import InitializingMessage from './initializingStatus/InitializingMessage';

type Props = {
  status: 'loading' | 'error';
  message?: {
    main?: string;
    secondary?: string;
  };
};

const InitializationStatus: FC<Props> = ({ message, status }) => {
  return (
    <div className="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center">
      <div>
        {status === 'loading' && <InitializingSpinner />}
        {(message || status === 'error') && (
          <InitializingMessage
            message={
              message?.main ?? 'Oops we were not able to fetch your data'
            }
            secondaryMessage={
              message?.secondary ?? 'Please refresh page to try again'
            }
          />
        )}
      </div>
    </div>
  );
};
export default InitializationStatus;
