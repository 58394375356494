import Swal, { SweetAlertOptions } from 'sweetalert2';
import i18n from 'i18n';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { Icon, IconButton } from '@kontentino/ui';
import ReactDOMServer from 'react-dom/server';
import React from 'react';

const closeButtonHtml = ReactDOMServer.renderToString(
  <IconButton variant="plain" size="small" icon={<Icon icon={faXmark} />} />,
);

export function popupConfirm(options?: SweetAlertOptions) {
  return Swal.fire({
    showCancelButton: true,
    showCloseButton: true,
    title: i18n.areYouSure,
    confirmButtonText: i18n.confirm,
    denyButtonText: i18n.cancel,
    cancelButtonText: i18n.cancel,
    reverseButtons: true,
    closeButtonHtml,
    showClass: {
      popup: 'popup-show',
    },
    hideClass: {
      popup: 'popup-hide',
    },
    ...options,
  });
}

export function popupCommon(options?: SweetAlertOptions) {
  return Swal.fire({
    ...options,
    closeButtonHtml,
  });
}

export function popupSuccess(options?: SweetAlertOptions) {
  return Swal.fire({
    ...options,
    title: 'Success!',
    icon: 'success',
    closeButtonHtml,
  });
}

export function popupError(options?: SweetAlertOptions) {
  return Swal.fire({
    ...options,
    title: 'Error!',
    icon: 'error',
    closeButtonHtml,
  });
}

const Popup = {
  confirm: popupConfirm,
  common: popupCommon,
  success: popupSuccess,
  error: popupError,
};

export default Popup;
