import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormGroup } from '@kontentino/kontentino-ui/build/form';
import { InputLabel, TextArea, TextInput } from '@kontentino/kontentino-ui';
import { SelectSocialProfiles } from '@kontentino/kontentino-ui/build/new-ui';
import { Switch } from '@kontentino/kontentino-ui/build/evergreenUI';
import i18n from 'i18n';
import { togglePrimitiveItemInArray } from 'utils/helpers';
import style from './Form.module.scss';
import { HashtagPreset } from 'types/HashtagPresets';
import { useQuery } from 'react-query';
import { queryKey } from 'constants/queryKey';
import { HashtagPresetsApi } from 'api/hashtagPresets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { Button, Tooltip } from '@kontentino/ui';
import Modal from 'components/shared/Modal';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

type Props = {
  onClose(): void;
  onSubmit(values: FormState): void;
  defaultValues?: Partial<
    Pick<HashtagPreset, 'id' | 'name' | 'hashtags' | 'pages' | 'isGlobal'>
  > | null;
  isLoading: boolean;
};

type FormState = z.infer<typeof schema>;

const schema = z.object({
  id: z.number().optional().or(z.string()),
  name: z.string(),
  hashtags: z.string(),
  pages: z.array(z.number()),
  isGlobal: z.boolean(),
});

const Form: FC<Props> = (props) => {
  const { register, control, handleSubmit, formState, watch } =
    useForm<FormState>({
      resolver: zodResolver(schema),
      defaultValues: props.defaultValues
        ? {
            hashtags: props.defaultValues.hashtags,
            name: props.defaultValues.name,
            pages: props.defaultValues.pages,
            id: props.defaultValues.id,
            isGlobal: props.defaultValues.isGlobal,
          }
        : undefined,
    });
  const isGlobal = watch('isGlobal');
  const { data } = useQuery(
    [queryKey.hashtagPresetsAssignablePages],
    HashtagPresetsApi.getAssignablePages,
  );

  const pages = data ?? [];

  return (
    <form onSubmit={handleSubmit(props.onSubmit)}>
      <Modal.Content>
        <input type="hidden" {...register('id')} />
        <FormGroup>
          <InputLabel isRequired error={formState.errors.name?.message}>
            Preset name
          </InputLabel>
          <TextInput {...register('name')} />
        </FormGroup>
        <FormGroup>
          <InputLabel isRequired error={formState.errors.hashtags?.message}>
            Hashtags
          </InputLabel>
          <TextArea {...register('hashtags')} className={style.textarea} />
        </FormGroup>
        <Controller
          control={control}
          name="isGlobal"
          defaultValue={false}
          render={({ field: { value, onChange } }) => (
            <FormGroup>
              <div
                data-name="hashtag-presets_edit-modal_global-toggle"
                className="tw-flex tw-flex-col tw-items-center"
              >
                <InputLabel>
                  Global preset{' '}
                  <Tooltip content="Global hashtag presets will be visible in all the social media profiles in your account.">
                    <span>
                      <FontAwesomeIcon icon={faCircleQuestion} />
                    </span>
                  </Tooltip>
                </InputLabel>
                <Switch
                  checked={value}
                  onChange={() => onChange(!value)}
                  height={20}
                  marginLeft={8}
                />
              </div>
            </FormGroup>
          )}
        />
        <Controller
          control={control}
          name="pages"
          defaultValue={[]}
          render={({ field: { value, onChange } }) => {
            const allPageIds = pages.map((page) => page.id);

            return (
              <FormGroup>
                <InputLabel isRequired={!isGlobal}>Profiles</InputLabel>
                <SelectSocialProfiles
                  disabledIds={isGlobal ? allPageIds : []}
                  headerTitle={`${value.length} selected`}
                  profiles={pages.map((page) => ({
                    ...page,
                    logo: page.logoSrc ? { src: page.logoSrc } : undefined,
                  }))}
                  selectedIds={value}
                  onSelect={(id) =>
                    onChange(togglePrimitiveItemInArray(id, value))
                  }
                  onSelectNone={() => onChange([])}
                  onSelectAll={() => onChange(allPageIds)}
                />
              </FormGroup>
            );
          }}
        />
      </Modal.Content>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={props.onClose}
          data-name="hashtag-presets_edit-modal_cancel"
        >
          {i18n.close}
        </Button>
        <Button
          variant="primary"
          type="submit"
          isLoading={props.isLoading}
          data-name="hashtag-presets_edit-modal_save"
        >
          {i18n.confirm}
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default Form;
