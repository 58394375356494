import React, { forwardRef, ReactNode, useEffect } from 'react';
import { animated } from 'react-spring';
import { useAccordionWidget } from 'app/modules/posts/components/accordionWidget/hooks/useAccordionWidget';
import { Icon, IconButton } from '@kontentino/ui';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { runIfFn } from 'app/utils/run-if-fn';

type Props = {
  title: ReactNode | ((isOpen: boolean) => ReactNode);
  titleSuffix?: ReactNode | ((isOpen: boolean) => ReactNode);
  icon?: ReactNode;
  initialIsOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'title'>;

const AccordionWidget = forwardRef<HTMLDivElement, Props>(
  (
    {
      title,
      titleSuffix,
      icon,
      children,
      className,
      initialIsOpen,
      onOpenChange,
      ...rest
    },
    ref,
  ) => {
    const {
      bodyStyle,
      headerStyle,
      chevronStyle,
      bodyRef,
      isOpen,
      setIsOpen,
      bodyMaxHeight,
    } = useAccordionWidget({ initialIsOpen });

    useEffect(() => {
      onOpenChange?.(isOpen);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (
      <div
        className={clsx('tw-rounded tw-bg-white tw-shadow-sm', className)}
        ref={ref}
        {...rest}
      >
        <animated.div
          style={headerStyle}
          className={clsx(
            'tw-group/accordionTitle tw-flex tw-cursor-pointer tw-select-none tw-items-center tw-gap-2',
          )}
          onClick={setIsOpen.toggle}
        >
          {icon}
          <div className="tw-flex tw-items-center tw-gap-1 tw-text-base tw-font-semibold tw-text-grayscale-180">
            {typeof title === 'function' ? title(isOpen) : title}
          </div>
          <div className="tw-ml-auto tw-flex tw-items-center">
            {runIfFn(titleSuffix, isOpen)}
            <animated.div className="tw-ml-4 " style={chevronStyle}>
              <IconButton
                variant="plain"
                className="tw-text-grayscale-100 group-hover/accordionTitle:tw-text-primary-100"
                icon={<Icon icon={faChevronDown} />}
              />
            </animated.div>
          </div>
        </animated.div>
        <animated.div
          style={{
            ...bodyStyle,
            visibility: bodyStyle.opacity.to((opacity) =>
              opacity === 0 ? 'hidden' : 'visible',
            ),
            overflow: bodyStyle.opacity.to((opacity) =>
              opacity < 1 ? 'hidden' : 'unset',
            ),
            maxHeight: bodyStyle.maxHeight.to((maxHeight) =>
              `${bodyMaxHeight}px` === maxHeight ? 'unset' : maxHeight,
            ),
          }}
          ref={bodyRef}
        >
          <div className="tw-px-4 tw-pb-4 tw-pt-0">{children}</div>
        </animated.div>
      </div>
    );
  },
);

export default AccordionWidget;
