export enum TextEditorFeature {
  UndoRedo = 'undoRedo',
  CharacterCounter = 'characterCounter',
  EmojiPicker = 'emojiPicker',
  PageMention = 'pageMention',
  UserMention = 'userMention',
  HashtagMention = 'hashtagMention',
  DirectFbMention = 'directFbMention',
  HashtagsPresets = 'hashtagsPresets',
  LinkShortener = 'linkShortener',
  FileAttachments = 'fileAttachments',
  AIContent = 'aiContent',
}

export type TextEditorFeatures = TextEditorFeature[];

export const DEFAULT_TEXT_EDITOR_FEATURES = [
  TextEditorFeature.UndoRedo,
  TextEditorFeature.CharacterCounter,
  TextEditorFeature.EmojiPicker,
  TextEditorFeature.PageMention,
  TextEditorFeature.HashtagMention,
  TextEditorFeature.DirectFbMention,
  TextEditorFeature.HashtagsPresets,
  TextEditorFeature.LinkShortener,
  TextEditorFeature.AIContent,
];

export const TEXT_EDITOR_SUGGESTIONS_QUERY_START_LENGTH = 3;
export const TEXT_EDITOR_SUGGESTIONS_DEBOUNCE_DELAY = 500;
