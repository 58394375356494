import React, { FC } from 'react';
import StandardPostProfile from 'app/modules/insights/components/standardPostPreview/StandardPostProfile';
import TextContent from 'app/modules/insights/components/standardPostPreview/TextContent';
import { StandardPost } from 'app/types/StandardPostPreview';
import AttachmentsCards from './standardPostPreview/AttachmentsCards';
import MedalRankBadge, {
  MedalRank,
} from 'app/modules/insights/components/MedalRankBadge';
import PostTypeIndicator from 'app/modules/insights/components/PostTypeIndicator';
import MetricValue from 'app/modules/insights/components/standardPostPreview/MetricValue';
import { mergeTailwindClasses } from '@kontentino/ui';
import { MetricProperty } from '../types/MetricProperty';
import clsx from 'clsx';

export type StandardPostPreviewProps = {
  post: StandardPost;
  medalRank?: MedalRank;
  metric?: MetricProperty & { value: string };
  allowVideoPlayback?: boolean;
  classNames?: {
    root?: string;
    content?: string;
    profileRoot?: string;
    profileAvatar?: string;
    profilePageType?: string;
    textContent?: string;
  };
};

type Props = StandardPostPreviewProps;

const StandardPostPreview: FC<Props> = ({
  post,
  medalRank,
  metric,
  allowVideoPlayback,
  classNames,
}) => {
  return (
    <div
      className={mergeTailwindClasses(
        clsx(
          'tw-relative tw-flex tw-h-[680px] tw-w-[514px] tw-flex-col tw-overflow-hidden tw-rounded-md tw-bg-white',
          classNames?.root,
        ),
      )}
    >
      <AttachmentsCards post={post} allowVideoPlayback={allowVideoPlayback} />
      {medalRank && (
        <MedalRankBadge
          rank={medalRank}
          className="tw-absolute tw-left-2 tw-top-2"
        />
      )}
      <PostTypeIndicator
        className="tw-absolute tw-right-2 tw-top-2"
        postType={post.type}
        pageType={post.page.type}
      />
      <div
        className={mergeTailwindClasses(
          'tw-flex tw-flex-col tw-gap-4 tw-p-4',
          classNames?.content,
        )}
      >
        <StandardPostProfile post={post} classNames={classNames} />
        {post.text && (
          <>
            <TextContent text={post.text} className={classNames?.textContent} />
            <div className="tw-h-px tw-bg-grayscale-20" />
          </>
        )}
        {metric && <MetricValue metric={metric} />}
      </div>
    </div>
  );
};

export default StandardPostPreview;
