import React, { FC, useEffect } from 'react';
import { Field, TextInput } from '@kontentino/ui';
import { useForm } from 'react-hook-form';
import {
  generateUrlWithUtmTags,
  parseUrlToUtmFields,
} from 'components/shared/utmTagBuilder/UtmTagBuilder.utils';
import clsx from 'clsx';

type Props = {
  className?: string;
  value?: string;
  onChange?(url: string): void;
  parseFieldsOnUrlsChange?: boolean;
};

export type Form = {
  url: string;
  utm_source: string;
  utm_medium: string;
  utm_campaign: string;
  utm_term: string;
  utm_content: string;
};

const UtmTagBuilder: FC<Props> = (props) => {
  const { register, handleSubmit, setValue } = useForm<Form>({
    defaultValues: parseUrlToUtmFields(props.value ?? ''),
  });

  function onChange(values: Form) {
    const generated = generateUrlWithUtmTags({
      ...values,
      url: props.value ? props.value : values.url,
    });

    if (generated.status) {
      props.onChange?.(generated.data);
    }
  }

  useEffect(() => {
    if (props.parseFieldsOnUrlsChange && props.value) {
      Object.entries(parseUrlToUtmFields(props.value)).forEach(
        ([key, value]) => {
          setValue(key as keyof Form, value);
        },
      );
    }
  }, [props.value, props.parseFieldsOnUrlsChange, setValue]);

  return (
    <form
      className={clsx(props.className, 'tw-flex tw-flex-col tw-gap-4')}
      onBlur={handleSubmit(onChange)}
    >
      {!props.value && (
        <Field.Group>
          <Field.Label required>URL Address</Field.Label>
          <TextInput {...register('url')} />
        </Field.Group>
      )}
      <Field.Group>
        <Field.Label required>UTM Source</Field.Label>
        <TextInput {...register('utm_source')} />
        <Field.Caption>The referrer: (e.g. google, newsletter)</Field.Caption>
      </Field.Group>
      <Field.Group>
        <Field.Label required>UTM Medium </Field.Label>
        <TextInput {...register('utm_medium')} />
        <Field.Caption>
          Marketing medium: (e.g. cpc, banner, email)
        </Field.Caption>
      </Field.Group>
      <Field.Group>
        <Field.Label required>UTM Campaign</Field.Label>
        <TextInput {...register('utm_campaign')} />
        <Field.Caption>
          Product, promo code, or slogan: (e.g. spring_sale)
        </Field.Caption>
      </Field.Group>
      <Field.Group>
        <Field.Label required>UTM Term</Field.Label>
        <TextInput {...register('utm_term')} />
        <Field.Caption>Identify the paid keywords</Field.Caption>
      </Field.Group>
      <Field.Group>
        <Field.Label required>Campaign content</Field.Label>
        <TextInput {...register('utm_content')} />
        <Field.Caption>Use to differentiate ads</Field.Caption>
      </Field.Group>
    </form>
  );
};

export default UtmTagBuilder;
