import { TimeLabels, TimeLabelsMap } from 'app/types/Time';

const DateTimeConfig = {
  DEFAULT_DATE_RAW_FORMAT: 'YYYY-MM-DD',
  timeDisplay: {
    [TimeLabels.Seconds]: 's',
    [TimeLabels.Minutes]: 'm',
    [TimeLabels.Hours]: 'h',
  } as TimeLabelsMap,
};

export default DateTimeConfig;
