import React, { FC, useMemo } from 'react';
import { StandardPostDetailedMetricAdditionalData } from 'app/types/StandardPostDetailed';
import SegmentDistributionBar from 'app/modules/insights/components/SegmentDistributionBar';
import AdditionalDataRow from './AdditionalDataRow';

type Props = {
  additionalData: StandardPostDetailedMetricAdditionalData[];
  withSegments?: boolean;
};

const colorPalette = ['#505FEE', '#00C4F7', '#8746FF', '#F0D42B', '#EF8F51'];

const MetricRowWithAdditionalData: FC<Props> = ({
  additionalData,
  withSegments = true,
}) => {
  const sumValue = useMemo(
    () => additionalData.reduce((sum, data) => sum + data.value, 0),
    [additionalData],
  );

  const segments = withSegments
    ? additionalData.map((data, index) => ({
        color:
          index < colorPalette.length
            ? colorPalette[index]
            : colorPalette[index % colorPalette.length],
        width: `${(data.value / sumValue) * 100}%`,
      }))
    : undefined;

  return (
    <div className="tw-mt-[26px] tw-pl-6 tw-pr-[30px]">
      {segments && (
        <SegmentDistributionBar segments={segments} className="tw-mb-6" />
      )}
      {additionalData.map((data, index) => (
        <React.Fragment key={index}>
          {index > 0 && (
            <div className="tw-full tw-h-[1px] tw-bg-grayscale-20" />
          )}
          <AdditionalDataRow
            label={data.name}
            value={data.value}
            legendColor={segments ? segments[index].color : undefined}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

export default MetricRowWithAdditionalData;
