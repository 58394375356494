import { gql } from '@apollo/client';

export const FB_BENCHMARKS_VALUES_FRAGMENT = gql`
  fragment FbBenchmarksValues on FacebookBenchmarkItem {
    comments
    engagement
    engagementRate
    organicReach
    organicReachUnique
    paidReach
    paidReachUnique
    linkClicks
    postImpressions
    postImpressionsUnique
    postClicks
    shares
    totalReach
    totalReachUnique
    videoViews
  }
`;

export const IG_BENCHMARKS_VALUES_FRAGMENT = gql`
  fragment IgBenchmarksValues on InstagramBenchmarkItem {
    impressions
    reach
    engagement
    saved
    comments
    likes
  }
`;

export const LI_BENCHMARKS_VALUES_FRAGMENT = gql`
  fragment LiBenchmarksValues on LinkedInBenchmarkItemType {
    comments
    topLevelComments
    postClicks
    postImpressions
    postImpressionsUnique
    likes
    engagement
    engagementRate
    shares
  }
`;
