import { FC } from 'react';
import StatusesFilter from 'components/workflowOptions/postsSelection/StatusesFilter';
import Posts from 'components/workflowOptions/postsSelection/Posts';
import ModalBodyWithSidebarLayout from 'components/shared/workflowOptions/ModalBodyWithSidebarLayout';
import { ListPost } from 'types/PostDetail';
import { usePostsContainer } from 'components/posts/context';
import { formatAndSortPostsMetadataStatuses } from 'app/modules/posts/utils/formatAndSortPostsMetadataStatuses';

type Props = {
  selectedStatuses: number[];
  posts: ListPost[];
  onSelectPostStatus: (status: number) => void;
  selectedPosts: ListPost[];
  disabledPosts?: number[];
  warningMessages?: Record<string, string>;
  onSelectPost: (post: ListPost) => void;
  onSelectAllPosts: () => void;
  onUnselectAllPosts: () => void;
  className?: string | null;
};

const PostsSelection: FC<Props> = ({
  selectedStatuses,
  posts,
  onSelectPostStatus,
  selectedPosts,
  disabledPosts,
  warningMessages,
  onSelectPost,
  onSelectAllPosts,
  onUnselectAllPosts,
  className,
}) => {
  const { meta } = usePostsContainer();

  return (
    <ModalBodyWithSidebarLayout
      className={className}
      sidebarContent={
        <StatusesFilter
          statuses={formatAndSortPostsMetadataStatuses(
            meta?.status ?? null,
          ).map((status) => ({
            status: status.value,
            count: status.postCount,
          }))}
          selectedStatuses={selectedStatuses}
          onSelectPostStatus={onSelectPostStatus}
        />
      }
      mainContent={
        <Posts
          posts={posts}
          selectedPosts={selectedPosts}
          disabledPosts={disabledPosts}
          warningMessages={warningMessages}
          onSelectPost={onSelectPost}
          onSelectAllPosts={onSelectAllPosts}
          onUnselectAllPosts={onUnselectAllPosts}
        />
      }
    />
  );
};

export default PostsSelection;
