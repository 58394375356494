import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useOutsideClick } from 'utils/hooks/useOutsideClick';
import DateUtils, { formatDate, formatDateToMonthAndDay } from 'app/utils/date';
import { DateRangeOnChangeParams } from 'types/Date';
import clsx from 'clsx';
import Placeholder from 'app/components/simpleDatePicker/components/Placeholder';
import style from './SimpleDatePicker.module.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from '@kontentino/ui';
import SimpleDatePickerHeaderDefault from 'app/components/simpleDatePicker/components/SimpleDatePickerHeaderDefault';
import SimpleDatePickerHeaderCustomPeriod from 'app/components/simpleDatePicker/components/SimpleDatePickerHeaderCustomPeriod';
import dayjs, { Dayjs } from 'dayjs';
import useSetState from 'utils/hooks/useSetState';
import { getDatesWithConstraints, isDateWithinRange } from './utils';
import { useTranslation } from 'react-i18next';
import SimpleDatePickerFooter from 'app/components/simpleDatePicker/components/SimpleDatePickerFooter';

type Props = {
  onDateChange(values: DateRangeOnChangeParams): void;
  startDate?: Dayjs | null;
  endDate?: Dayjs | null;
  className?: {
    wrapper?: string;
    placeholder?: string;
  };
  dataName?: {
    picker?: string;
    placeholder?: string;
  };
  dataCy?: string;
  maxDate?: Dayjs | null;
  minDate?: Dayjs | null;
  onDayMouseEnter?: (date: Date) => void;
  isRangeDateViewActive?: boolean;
  onToggleCustomDateRangeView?: (status: boolean) => void;
  refreshKey?: string;
  rangeLimitRuleSetter?: (date: string) => string;
};

const SimpleDatePicker: FC<Props> = ({
  onDateChange,
  startDate,
  endDate,
  className,
  dataName,
  dataCy,
  maxDate,
  minDate,
  onDayMouseEnter,
  isRangeDateViewActive,
  onToggleCustomDateRangeView,
  refreshKey,
  rangeLimitRuleSetter,
}) => {
  const { t } = useTranslation();
  const [dateValidState, setDateValidState] = useSetState<{
    startDate: boolean;
    endDate: boolean;
  }>({
    startDate: true,
    endDate: true,
  });
  const [startDateTemp, setStartDateTemp] = useState<Date | null>(
    startDate?.toDate() ?? null,
  );
  const [endDateTemp, setEndDateTemp] = useState<Date | null>(
    endDate?.toDate() ?? null,
  );
  const [isShown, setIsShown] = useState(false);
  const [maxDateLimit, setMaxDateLimit] = useState<Props['maxDate']>(maxDate);
  const { elementRef, addListener, removeListener } =
    useOutsideClick<HTMLDivElement>(onHide);

  const selected = useMemo(() => {
    if (isRangeDateViewActive) {
      return null;
    }

    return startDate?.toDate() ?? null;
  }, [startDate, isRangeDateViewActive]);

  function onSubmit(dates: DateRangeOnChangeParams) {
    onDateChange(
      getDatesWithConstraints(dates, {
        maxEndDate: maxDate,
      }),
    );
    onHide();
  }

  const onShow = () => {
    setIsShown(true);
    addListener();
  };

  function onHide() {
    setIsShown(false);
    removeListener();
  }

  const getPlaceholderLabel = useCallback(() => {
    if (isRangeDateViewActive) {
      let startDateString = t('notSelected');
      let endDateString = t('notSelected');
      const isTempStartDateValid = DateUtils.isValid(startDateTemp);
      const isTempEndDateValid = DateUtils.isValid(endDateTemp);

      if (!isTempStartDateValid && !isTempEndDateValid) {
        return `${t('selectDateRange')}...`;
      }

      if (isTempStartDateValid) {
        startDateString = dayjs(startDateTemp).isSame(
          dayjs(endDateTemp),
          'year',
        )
          ? formatDateToMonthAndDay(startDateTemp)
          : formatDate(startDateTemp);
      }

      if (isTempEndDateValid) {
        endDateString = formatDate(endDateTemp);
      }

      return `${startDateString} - ${endDateString}`;
    }

    return DateUtils.toMonthYearString(startDate ?? null);
  }, [startDate, startDateTemp, endDateTemp, isRangeDateViewActive, t]);

  const clearCustomPeriod = () => {
    onDateChange(
      getDatesWithConstraints(
        {
          startDate: dayjs().startOf('M'),
          endDate: dayjs().endOf('M'),
        },
        { maxEndDate: maxDate },
      ),
    );
    onStartDateChange(null);
    onEndDateChange(null);
    setMaxDateLimit(maxDate);
    onToggleCustomDateRangeView?.(false);
  };

  const handleRangeSelect = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    onStartDateChange(start);
    onEndDateChange(end);
  };

  const handleDateSelect = (date?: Date) => {
    if (date) {
      const startDate = dayjs(date);
      const endDate = dayjs(date);

      if (startDate && endDate) {
        onSubmit({
          startDate: startDate.startOf('M'),
          endDate: endDate.endOf('M'),
        });
      }
    }
  };

  const handleDateRangeSubmit = () => {
    onSubmit({
      startDate: dayjs(startDateTemp),
      endDate: dayjs(endDateTemp),
    });
  };

  const onStartDateChange = (date: Date | null) => {
    setStartDateTemp(date);

    if (date) {
      const newMaxDate =
        rangeLimitRuleSetter?.(DateUtils.toDateString(dayjs(date))) ?? maxDate;
      setMaxDateLimit(dayjs(newMaxDate));
    }
  };

  const onEndDateChange = (date: Date | null) => {
    setEndDateTemp(date);
  };

  const handleRangeSelectCancel = () => {
    onHide();
    onStartDateChange(startDate?.toDate() ?? null);
    onEndDateChange(endDate?.toDate() ?? null);
  };

  useEffect(() => {
    setDateValidState({
      startDate: startDate
        ? isDateWithinRange(
            startDate.toDate(),
            minDate?.toDate(),
            maxDate?.toDate(),
          )
        : false,
      endDate: endDate
        ? isDateWithinRange(
            endDate.toDate(),
            minDate?.toDate(),
            maxDate?.toDate(),
          )
        : false,
    });
  }, [startDate, endDate, minDate, maxDate, setDateValidState]);

  useEffect(() => {
    if (!isShown && isRangeDateViewActive) {
      if (
        !startDate?.isSame(dayjs(startDateTemp)) ||
        !endDate?.isSame(dayjs(endDateTemp))
      ) {
        setStartDateTemp(startDate?.toDate() ?? null);
        setEndDateTemp(endDate?.toDate() ?? null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShown]);

  return (
    <div
      ref={elementRef}
      className={clsx(
        'tw-relative tw-inline-flex tw-flex-col',
        className?.wrapper,
      )}
    >
      <ReactDatePicker
        key={startDateTemp?.getTime()}
        selected={selected ?? startDateTemp}
        maxDate={maxDateLimit?.toDate() ?? null}
        minDate={minDate?.toDate() ?? null}
        startDate={startDateTemp ?? null}
        endDate={endDateTemp ?? null}
        onClickOutside={onHide}
        open={isShown}
        shouldCloseOnSelect={!isRangeDateViewActive}
        showMonthYearPicker={!isRangeDateViewActive}
        dateFormat={!isRangeDateViewActive ? 'MM/yyyy' : undefined}
        data-name={dataName?.picker}
        popperClassName={style.datePicker}
        onDayMouseEnter={onDayMouseEnter}
        renderCustomHeader={(props) =>
          isRangeDateViewActive ? (
            <SimpleDatePickerHeaderCustomPeriod
              {...props}
              key={refreshKey}
              minDate={minDate}
              maxDate={maxDateLimit}
              startDate={startDateTemp}
              endDate={endDateTemp}
              onStartDateChange={(date) => {
                onStartDateChange(date);
                onEndDateChange(null);
              }}
              onEndDateChange={onEndDateChange}
              customDateValidState={dateValidState}
            />
          ) : (
            <SimpleDatePickerHeaderDefault
              {...props}
              key={refreshKey}
              minDate={minDate}
              maxDate={maxDateLimit}
            />
          )
        }
        onInputClick={isShown ? onHide : onShow}
        customInput={
          <Placeholder
            key={refreshKey}
            label={getPlaceholderLabel()}
            isActive={isShown}
            className={className?.placeholder}
            dataName={dataName?.placeholder}
            dataCy={dataCy}
          />
        }
        renderDayContents={(day) => <span className="day">{day}</span>}
        onChange={(date) => {
          isRangeDateViewActive
            ? handleRangeSelect(date as [Date | null, Date | null])
            : handleDateSelect(date as Date);
        }}
        selectsRange={isRangeDateViewActive}
      >
        <>
          {!isRangeDateViewActive && (
            <Button
              variant={'ghost'}
              onClick={() => onToggleCustomDateRangeView?.(true)}
              className="tw-h-min !tw-p-2"
            >
              {t('customPeriod')}
            </Button>
          )}
          {isRangeDateViewActive && (
            <SimpleDatePickerFooter
              onClear={clearCustomPeriod}
              onCancel={handleRangeSelectCancel}
              onSubmit={handleDateRangeSubmit}
              disabled={{
                submit:
                  !(startDateTemp && endDateTemp) ||
                  !dateValidState.startDate ||
                  !dateValidState.endDate,
              }}
            />
          )}
        </>
      </ReactDatePicker>
    </div>
  );
};

export default SimpleDatePicker;
