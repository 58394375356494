import { useMemo } from 'react';
import { Ads } from 'types/Ad';
import { PostApprovalWorkflowPermissions } from 'utils/post/permissions';

export const useTimeSavers = (selectedAds: Ads) => {
  return useMemo(
    () => ({
      canAssignAds: selectedAds.every(
        (adPost) =>
          PostApprovalWorkflowPermissions(adPost.ad_post_status).canAssign,
      ),
      canSendAdsForClientApproval: selectedAds.every(
        (adPost) =>
          PostApprovalWorkflowPermissions(adPost.ad_post_status)
            .canBeSendToClientApproval,
      ),
      canSendAdsForInternalApproval: selectedAds.every(
        (adPost) =>
          PostApprovalWorkflowPermissions(adPost.ad_post_status)
            .canBeSendToInternalApproval,
      ),
      canExportAds: selectedAds.every((adPost) => adPost.is_exportable),
    }),
    [selectedAds],
  );
};
