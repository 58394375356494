import {
  IMAGE_FILE_EXTENSIONS,
  IMAGE_FILE_MIME_TYPES,
  MAX_FILE_SIZE,
  VIDEO_FILE_EXTENSIONS,
  VIDEO_FILE_MIME_TYPES,
} from '@kontentino/kontentino-constants/Files';
import { v4 } from 'uuid';
import Logger from 'utils/logger';

const FilesUtil = {
  getBase64(file: File): Promise<string | undefined> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString());
      reader.onerror = (error) => reject(error);
    });
  },
  isValidImage(file: File): string | undefined {
    if (file.type && !IMAGE_FILE_MIME_TYPES.includes(file.type)) {
      return 'File is not supported image.';
    }

    if (file.size && file.size > MAX_FILE_SIZE.DEFAULT_IMAGE) {
      return `Image exceeds maximum file size ${FilesUtil.formatBytes(
        MAX_FILE_SIZE.DEFAULT_IMAGE,
      )}`;
    }
  },
  formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  },
  getFileNameExtension(name: string) {
    return name.split('.').pop()?.toLowerCase() ?? 'file';
  },
  filterFilesByOptions(
    files: File[],
    options: {
      mimeTypes?: Array<string>;
      extensions?: Array<string>;
      maxFileSize?: number;
    },
  ) {
    return files.filter((file) => {
      const size = file.size;
      const type = file.type.toLowerCase();
      const extension = file.name.split('.').pop();

      const { mimeTypes, extensions, maxFileSize } = options;

      function meetsConditions() {
        return (
          !(mimeTypes && !mimeTypes.includes(type)) ||
          !(extensions && !extensions.includes(extension ?? '')) ||
          !(Number.isNaN(size) || (maxFileSize && size > maxFileSize))
        );
      }

      return meetsConditions();
    });
  },
  detectFiles(files: File[]) {
    const videos = files.filter((file) =>
      [
        VIDEO_FILE_MIME_TYPES.includes(file.type),
        VIDEO_FILE_EXTENSIONS.includes(
          FilesUtil.getFileNameExtension(file.name),
        ),
      ].some(Boolean),
    );

    const images = files.filter((file) =>
      [
        IMAGE_FILE_MIME_TYPES.includes(file.type),
        IMAGE_FILE_EXTENSIONS.includes(
          FilesUtil.getFileNameExtension(file.name),
        ),
      ].some(Boolean),
    );

    const supportedFileNames = [
      ...videos.map((f) => f.name),
      ...images.map((f) => f.name),
    ];

    const other = files.filter(
      (file) => !supportedFileNames.includes(file.name),
    );

    return { videos, images, other };
  },
  splitFileToChunks(file: File) {
    const CHUNK_FILE_SIZE = 10 * 1024 * 1024;
    const chunks = [];

    let currentOffset = 0;

    while (currentOffset <= file.size) {
      const nextOffset = currentOffset + CHUNK_FILE_SIZE;

      const chunk = file.slice(currentOffset, nextOffset);
      chunks.push(chunk);

      currentOffset = nextOffset;
    }

    return chunks;
  },
  async base64ToPngFile(base64: string) {
    const fileBlob = await (await fetch(base64)).blob();

    const fileName = `${v4()}.png`;
    const fileDate = new Date().getTime();
    const fileType = fileBlob.type;

    return new File([fileBlob], fileName, {
      lastModified: fileDate,
      type: fileType,
    });
  },
  async downloadFromUrl(fileUrl: string) {
    try {
      const response = await fetch(fileUrl, {
        method: 'GET',
      });

      const disposition = response.headers.get('content-disposition');
      let fileName = '';
      if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }
      }
      const buffer = await response.arrayBuffer();
      const url = URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement('a');
      link.href = url;
      link.download =
        fileName || fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      Logger.error(error);
    }
  },
};

export default FilesUtil;
