import React, { FC, ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import FatalError from 'app/components/FatalError';
import { isDebugMode } from 'utils/helpers';
import DataLayer from 'app/utils/dataLayer';

type Props = {
  children?: ReactNode;
};

const ErrorBoundary: FC<Props> = ({ children }) => {
  return (
    <Sentry.ErrorBoundary
      fallback={<FatalError />}
      onError={(error) =>
        DataLayer.push({
          event: DataLayer.events.APP_FAILED_TO_ERROR_BOUNDARY,
          error,
        })
      }
      showDialog={isDebugMode()}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
