import React, { FC } from 'react';
import Head from 'components/Head';
import BackdropLoader from 'app/components/BackdropLoader';

type Props = {};

const Initializing: FC<Props> = (props) => {
  return (
    <>
      <Head />
      <BackdropLoader />
    </>
  );
};

export default Initializing;
