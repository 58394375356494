import { useEffect, useRef, useState } from 'react';
import { useSpring } from 'react-spring';
import useBoolean from 'utils/hooks/useBoolean';

const config = { tension: 265, clamp: true };
const defaultBodyMaxHeight = 1000;

export function useAccordionWidget({
  initialIsOpen,
}: {
  initialIsOpen?: boolean;
}) {
  const [isOpen, setIsOpen] = useBoolean(
    typeof initialIsOpen === 'boolean' ? initialIsOpen : true,
  );
  const [bodyMaxHeight, setBodyMaxHeight] = useState(defaultBodyMaxHeight);
  const bodyRef = useRef<HTMLDivElement>(null);
  const bodyStyle = useSpring({
    config,
    opacity: isOpen ? 1 : 0,
    maxHeight: isOpen ? `${bodyMaxHeight}px` : '0px',
  });
  const chevronStyle = useSpring({
    config,
    rotate: isOpen ? 180 : 0,
  });
  const headerStyle = useSpring({
    config,
    padding: isOpen ? '1rem 1rem' : '0.5rem 1rem',
  });

  useEffect(() => {
    function calcBodyMaxHeight() {
      if (bodyRef.current) {
        let elementHeight = bodyRef.current.scrollHeight;
        const rectHeight = bodyRef.current.getBoundingClientRect().height;

        if (elementHeight < rectHeight) {
          elementHeight = rectHeight;
        }

        elementHeight = Math.ceil(elementHeight);

        if (elementHeight > 0) {
          setBodyMaxHeight(elementHeight);
        }
      }
    }

    const observer = new MutationObserver(calcBodyMaxHeight);

    if (bodyRef.current) {
      observer.observe(bodyRef.current, { childList: true, subtree: true });
      window.addEventListener('resize', calcBodyMaxHeight);
    }

    return () => {
      observer.disconnect();
      window.removeEventListener('resize', calcBodyMaxHeight);
    };
  }, [bodyRef]);

  return {
    isOpen,
    setIsOpen,
    bodyRef,
    bodyStyle,
    bodyMaxHeight,
    chevronStyle,
    headerStyle,
  };
}
