import React, { FC } from 'react';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import { PagePost } from 'types/insights/CompetitionAnalysis';
import FacebookPostRow from './postRow/FacebookPostRow';
import InstagramPostRow from './postRow/InstagramPostRow';
import BasePostRow from './postRow/BasePostRow';

type Props = {
  post: PagePost;
  canShowReactions: boolean;
};

const PostRow: FC<Props> = ({ post, canShowReactions }) => {
  function renderPostRow() {
    switch (post.pageInfo.type) {
      case TYPE.FACEBOOK:
        return <FacebookPostRow showReactions={canShowReactions} post={post} />;
      case TYPE.INSTAGRAM:
        return <InstagramPostRow post={post} />;
      default:
        return null;
    }
  }

  return <BasePostRow post={post}>{renderPostRow()}</BasePostRow>;
};

export default PostRow;
