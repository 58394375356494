import { FC } from 'react';
import DatePicker from 'components/shared/DatePicker';
import { InsightsState } from 'components/insights/Container';
import { useEffectOnce } from 'utils/hooks/useEffectOnce';
import dayjs from 'dayjs';

type Props = Pick<
  InsightsState,
  'changeDateFilter' | 'filterState' | 'clearComparedDateFilter'
> & {};

const PeriodToCompareDatePicker: FC<Props> = ({
  filterState,
  changeDateFilter,
  clearComparedDateFilter,
}) => {
  useEffectOnce(() => {
    return () => clearComparedDateFilter();
  });

  return (
    <DatePicker
      className="tw-h-[36px] tw-w-[200px]"
      shouldSubmitOnRangeSelect={false}
      shouldPreselect={false}
      startDate={filterState.comparedStartDate}
      endDate={filterState.comparedEndDate}
      onDateChange={(dates) => changeDateFilter(dates, true)}
      withPresets={false}
      autoSelectPeriod={filterState.endDate.diff(filterState.startDate, 'days')}
      maxEndDate={dayjs()}
      placeholderDataName="insights_date-range-compare_button"
      dataName={{
        dateRangePicker: 'insights_date-range-compare_dayPicker',
        footer: {
          cancelButton: 'insights_date-range-compare_cancel',
          submitButton: 'insights_date-range-compare_submit',
        },
      }}
    />
  );
};

export default PeriodToCompareDatePicker;
