import { STATUS } from '@kontentino/kontentino-constants/Posts';
import { UserPermission } from 'constants/userPermission';
import { Size } from 'types/Attachments';

export const POST_STATUS_BY_USER_PERMISSION: Record<string, number> = {
  [UserPermission.POST_LIST_DRAFT]: STATUS.DRAFT,
  [UserPermission.POST_LIST_COMPLETED]: STATUS.COMPLETED,
  [UserPermission.POST_LIST_WAITING_FOR_TASK]: STATUS.WAITING_FOR,
  [UserPermission.POST_LIST_TASK_DONE]: STATUS.DONE,
  [UserPermission.POST_LIST_WAITING_FOR_APPROVAL]:
    STATUS.WAITING_FOR_INTERNAL_APPROVAL,
  [UserPermission.POST_LIST_INTERNALLY_REJECTED]: STATUS.REJECTED,
  [UserPermission.POST_LIST_INTERNALLY_APPROVED]: STATUS.INTERNAL_APPROVED,
  [UserPermission.POST_LIST_AT_CLIENT]: STATUS.AT_CLIENT,
  [UserPermission.POST_LIST_REJECTED_BY_CLIENT]: STATUS.REJECTED_BY_CLIENT,
  [UserPermission.POST_LIST_APPROVED_BY_CLIENT]: STATUS.APPROVED,
  [UserPermission.POST_LIST_IS_SCHEDULING]: STATUS.IS_SCHEDULING,
  [UserPermission.POST_LIST_SCHEDULED]: STATUS.SCHEDULED,
  [UserPermission.POST_LIST_POSTED]: STATUS.POSTED,
  [UserPermission.POST_LIST_EXPORT_FAILED]: STATUS.EXPORT_FAILED,
};

export enum FirstCommentStatus {
  Published = 'published',
  Failed = 'failed',
}

export const COMBINED_MEDIA_DOWNLOAD_SIZES: Size[] = [
  {
    tKey: 'attachmentSize.original',
    value: 'original',
  },
  {
    tKey: 'attachmentSize.thumb',
    value: 'thumb',
  },
  {
    tKey: 'attachmentSize.lightbox',
    value: 'lightbox',
  },
];

export const VIDEO_MEDIA_DOWNLOAD_SIZES: Size[] = [
  {
    tKey: 'attachmentSize.original',
    value: 'original',
  },
];
