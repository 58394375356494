import React, { FC, useContext, useMemo, useState } from 'react';

type ContextValue = {
  displayActions: boolean;
  setIsHoveringOverParent: (showActions: boolean) => void;
  setIsDropdownClicked: (isDropdownClicked: boolean) => void;
  hideActions: () => void;
};

const CommentActionHover = React.createContext<ContextValue | undefined>(
  undefined,
);

type Props = {
  children: React.ReactNode;
};

export const CommentActionHoverProvider: FC<Props> = ({ children }) => {
  const [isHoveringOverParent, setIsHoveringOverParent] = useState(false);
  const [isDropdownClicked, setIsDropdownClicked] = useState(false);

  const hideActions = () => {
    setIsHoveringOverParent(false);
    setIsDropdownClicked(false);
  };

  const displayActions = useMemo(() => {
    return isHoveringOverParent || isDropdownClicked;
  }, [isHoveringOverParent, isDropdownClicked]);

  const value = {
    displayActions,
    setIsHoveringOverParent,
    setIsDropdownClicked,
    hideActions,
  };

  return (
    <CommentActionHover.Provider value={value}>
      {children}
    </CommentActionHover.Provider>
  );
};

export const useCommentActionHover = () => {
  const context = useContext(CommentActionHover);

  if (!context) {
    throw new Error('Must be used within CommentActionHoverProvider');
  }

  return context;
};
