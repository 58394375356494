import { FC } from 'react';
import ProfileSelectorFilterOption from './ProfileSelectorFilterOption';
import AllSelectionToggler from 'app/components/AllSelectionToggler';
import { ProfileSelectorFilterProps } from 'app/components/profileSelector/types/filterProps';
import ProfileSelectorSearch from './ProfileSelectorSearch';
import style from './profileSelectorDropdown/ProfileSelectorDropdown.module.scss';
import clsx from 'clsx';

type Props = {
  optionsElement: JSX.Element;
  filter: ProfileSelectorFilterProps;
  footer?: JSX.Element;
  onToggleAllSelection?: (value: 'all' | 'none') => void;
  isSelectedAll: boolean;
  title: string;
  dataName?: {
    filter?: string;
    options?: string;
  };
  classNames?: {
    dropdownWrapperInner?: string;
  };
};

const ProfileSelectorDropdown: FC<Props> = ({
  optionsElement,
  title,
  filter,
  footer,
  onToggleAllSelection,
  isSelectedAll,
  dataName,
  classNames,
}) => {
  return (
    <div className={style.dropdownWrapper}>
      <ProfileSelectorSearch
        value={filter.searchQuery}
        onChange={filter.onSearch}
      />
      <div
        className={clsx(
          style.dropdownWrapperInner,
          classNames?.dropdownWrapperInner,
        )}
      >
        {filter.options && (
          <div
            className={style.filterOptionsWrapper}
            data-name={dataName?.filter}
          >
            <div className={style.filterOptionsWrapperList}>
              {filter.options.map((filterOption) => (
                <ProfileSelectorFilterOption
                  key={filterOption.key}
                  option={filterOption}
                  onClick={filter.onSelectFilterOption}
                  isSelected={filter.selectedFilterOption === filterOption.key}
                />
              ))}
            </div>
            {filter.footer && (
              <div className={style.filterOptionsFooter}>{filter.footer}</div>
            )}
          </div>
        )}

        <div className={style.optionsWrapper} data-name={dataName?.options}>
          <div className={style.optionsHeader}>
            <span className="tw-text-base tw-font-bold">{title}</span>
            {onToggleAllSelection && (
              <AllSelectionToggler
                dataCy={{
                  allButton: 'profile-selector-all-button',
                  noneButton: 'profile-selector-none-button',
                }}
                selected={isSelectedAll ? 'all' : undefined}
                onSelect={onToggleAllSelection}
              />
            )}
          </div>
          <div className={style.optionsList}>{optionsElement}</div>
        </div>
      </div>
      {footer}
    </div>
  );
};

export default ProfileSelectorDropdown;
