import BaseSubscriptionModal from 'app/components/subscriptionModals/BaseSubscriptionModal';
import Benefits from 'app/components/subscriptionModals/Benefits';
import insightsIcon from 'app/images/insightsModalGreet.svg';
import { FC } from 'react';
import { SUBSCRIPTION_MODAL_BENEFITS } from './subscriptionModalBenefits';
import SubscriptionExpiredButtons from './SubscriptionExpiredButtons';
import SubscriptionExpiredFooter from './SubscriptionExpiredFooter';

type Props = {
  open: boolean;
};

const InsightsTrialExpired: FC<Props> = ({ open }) => {
  return (
    <BaseSubscriptionModal
      open={open}
      title={
        <div className="tw-text-xl tw-font-medium">
          Your <strong>free trial</strong> of <strong>Analytics</strong> has
          ended
        </div>
      }
      image={<img src={insightsIcon} alt="Analytics icon" />}
      buttons={
        <SubscriptionExpiredButtons primaryText={'Purchase Analytics'} />
      }
      benefits={
        <Benefits
          title={'Purchase Analytics now and keep the benefits of:'}
          lists={SUBSCRIPTION_MODAL_BENEFITS.insights}
        />
      }
      footer={
        <SubscriptionExpiredFooter
          text={
            'No need for Analytics anymore? Sorry to hear that, you can leave us feedback here'
          }
        />
      }
    />
  );
};

export default InsightsTrialExpired;
