import { Toggle } from '@kontentino/ui';
import React, { FC } from 'react';
import { useDemoContentToggle } from 'app/modules/posts/hooks/useDemoContentToggle';
import { useTranslation } from 'react-i18next';

const DemoContentToggle: FC = () => {
  const { toggle, isEnabled } = useDemoContentToggle();
  const { t } = useTranslation();

  return (
    <div
      data-name="posts-profile_selector-demo-content-toggle"
      className="tw-flex tw-select-none tw-items-center tw-rounded-md tw-bg-purple-10 tw-px-2 tw-py-[6px]"
    >
      <Toggle
        checked={isEnabled}
        onChange={() => toggle.mutate({ enabled: !isEnabled })}
      />
      <span className="tw-ml-2 tw-text-sm  tw-font-medium">
        {t('showDemoContent')}
      </span>
    </div>
  );
};

export default DemoContentToggle;
