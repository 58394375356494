import { Alert, Button, toast, ToastOptions } from '@kontentino/ui';
import React, { FC, ReactNode } from 'react';
import './../../styles/toast.scss';
import { Slide, ToastContentProps } from 'react-toastify';
import NumberUtil from 'utils/number';

type ToastType = 'info' | 'success' | 'warning' | 'default' | 'error';

const MIN_AUTOCLOSE_DELAY_MS = 2000;
const MAX_AUTOCLOSE_DELAY_MS = 60000;

function getRawMessage(message: string | ReactNode) {
  return typeof message !== 'string'
    ? `${message}`.replace(/<[^>]*>/g, '')
    : message;
}

const getAutoCloseDelay = (message: string | ReactNode) => {
  const text = getRawMessage(message);
  const delayTime = text.length * 60;

  return NumberUtil.clamp(
    delayTime,
    MIN_AUTOCLOSE_DELAY_MS,
    MAX_AUTOCLOSE_DELAY_MS,
  );
};

const CloseButton: FC<Partial<ToastContentProps>> = ({ closeToast }) => (
  <Button
    onClick={closeToast}
    variant="ghost-dark"
    data-cy="toast-default-close-button"
  >
    Cancel
  </Button>
);

function getToastId(
  message: string | ReactNode,
  toastId?: ToastOptions['toastId'],
) {
  if (typeof message === 'string' && !toastId) {
    return message;
  }

  return toastId;
}

export const showToast = (
  message: ReactNode,
  type?: ToastType,
  options?: ToastOptions,
) => {
  const toastId = getToastId(message, options?.toastId);

  switch (type) {
    case 'error':
    case 'warning':
      toast(
        () => (
          <Alert variant="danger" outline className="tw-border-0">
            {message}
          </Alert>
        ),
        {
          hideProgressBar: true,
          position: 'top-right',
          autoClose: false,
          closeOnClick: false,
          containerId: 'error',
          className: 'toast--error',
          toastId,
          ...options,
        },
      );
      break;
    default:
      toast(message, {
        hideProgressBar: true,
        position: 'bottom-left',
        autoClose: getAutoCloseDelay(message),
        closeOnClick: false,
        closeButton: options?.closeButton ?? <CloseButton />,
        transition: Slide,
        toastId,
        ...options,
      });
      break;
  }
};

export const useToast = () => showToast;
