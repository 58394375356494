import { UserPermission } from 'constants/userPermission';
import { useCallback, useMemo } from 'react';
import { usePermissionsStore } from '@kontentino/react-permissions-gate';

export function useUserPermissions() {
  const permissionsKeys = usePermissionsStore().permissions as UserPermission[];

  const hasSome = useCallback(
    (scopes: UserPermission | UserPermission[]) => {
      const scopesToCheck = Array.isArray(scopes) ? scopes : [scopes];

      return scopesToCheck.some((key) => permissionsKeys.includes(key));
    },
    [permissionsKeys],
  );

  const hasEvery = useCallback(
    (scopes: UserPermission | UserPermission[]) => {
      const scopesToCheck = Array.isArray(scopes) ? scopes : [scopes];

      return scopesToCheck.every((key) => permissionsKeys.includes(key));
    },
    [permissionsKeys],
  );

  return useMemo(
    () => ({
      hasSome,
      hasEvery,
    }),
    [hasSome, hasEvery],
  );
}
