import React, { FC } from 'react';
import style from './list/List.module.scss';
import { PostSelectorCard } from '@kontentino/kontentino-ui';
import { formatDateUTC } from 'app/utils/date';
import classes from 'react-style-classes';
import { WorkflowStatusUtils } from 'app/utils/workflowStatus';
import { ListPost } from 'types/PostDetail';
import { useTheme } from 'utils/hooks/useTheme';
import { usePostsLayoutState } from 'app/modules/posts/hooks/usePostsLayoutState';

type Props = {
  posts: ListPost[];
  selectedPosts: ListPost[];
  disabledPosts?: number[];
  warningMessages?: Record<string, string>;
  onSelectPost: (post: ListPost) => void;
  className?: string;
};

const List: FC<Props> = ({
  posts,
  selectedPosts,
  disabledPosts,
  warningMessages,
  onSelectPost,
  className,
}) => {
  const theme = useTheme();
  const { postStatusVariant } = usePostsLayoutState();

  return (
    <div className={classes(style.list, className)} translate="no">
      {posts.map((post) => (
        <PostSelectorCard
          key={`post-${post.id}`}
          onSelect={() => onSelectPost(post)}
          isSelected={selectedPosts
            .map((selectedPost) => selectedPost.id)
            .includes(post.id)}
          isDisabled={disabledPosts?.includes(post.id)}
          pageType={post.page.type}
          pageName={post.page.name}
          assignedUsers={post.assigned_users.map((user) => ({
            id: user.id,
            name: user.name,
            url: user.avatar?.src ?? null,
          }))}
          postText={post.text}
          statusColor={
            theme.colors.status[postStatusVariant][post.status].primary
          }
          statusLabel={WorkflowStatusUtils.getShortLabel(post.status)}
          dateLabel={post?.date ? formatDateUTC(post.date) : ''}
          imageUrl={post.preview_image?.src ?? null}
          warningMessage={warningMessages?.[post.id]}
          className={style.item}
        />
      ))}
    </div>
  );
};

export default List;
