import { FC } from 'react';
import { User } from 'types/User';
import userStyle from './userActionsMenu/userActions/UserActions.module.scss';
import { Avatar, Button } from '@kontentino/ui';

type PublicUserProps = {
  user: Pick<User, 'name' | 'avatar' | 'role'>;
};

export const PublicUserActions: FC<PublicUserProps> = ({ user }) => {
  return (
    <div className={userStyle.userActions}>
      <Button className={userStyle.dropDownButton} variant="ghost">
        <span className={userStyle.title}>
          <Avatar src={user.avatar?.src} size={32} name={user.name} />
          <span className={userStyle.userName}>{user.name}</span>
        </span>
      </Button>
    </div>
  );
};

export default PublicUserActions;
