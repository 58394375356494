import { FC } from 'react';
import Pusher from 'pusher-js';
import useInAppNotificationsCountQuery from 'app/modules/inAppNotifications/hooks/useInAppNotificationsCountQuery';
import { useEffectOnce } from 'utils/hooks/useEffectOnce';
import { useAccount } from 'modules/account/accountSelector';
import { useUser } from 'modules/user/userSelector';
import PusherConfig from 'app/config/pusher';
import Logger from 'utils/logger';

const InAppNotificationsEventProvider: FC = () => {
  const { refetch } = useInAppNotificationsCountQuery();
  const account = useAccount();
  const user = useUser();

  useEffectOnce(() => {
    const channelName = `user-${account.publicId}-${user.localId}`;
    Logger.info('Pusher subscribed to channel: %s', channelName);

    const pusher = new Pusher(PusherConfig.KEY || '', {
      cluster: PusherConfig.CLUSTER || '',
    });

    const channel = pusher.subscribe(channelName);

    channel.bind(PusherConfig.EVENT_KEYS.NOTIFICATION_COUNT, () => {
      Logger.info(
        'Pusher event triggered: %s',
        PusherConfig.EVENT_KEYS.NOTIFICATION_COUNT,
      );
      refetch();
    });

    return () => {
      channel.unbind(PusherConfig.EVENT_KEYS.NOTIFICATION_COUNT);
      pusher.unsubscribe(channelName);
    };
  });

  return null;
};

export default InAppNotificationsEventProvider;
