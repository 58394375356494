import { Checkbox, DropdownMenu, Icon } from '@kontentino/ui';
import { faChevronRight, faTags } from '@fortawesome/pro-regular-svg-icons';
import { useRef, useState } from 'react';
import { useAdsPlannerContainer } from 'components/adsPlanner/context';
import _debounce from 'lodash.debounce';
import { useEffectOnce } from 'utils/hooks/useEffectOnce';
import { togglePrimitiveItemInArray } from 'utils/helpers';
import AdsPlannerConfig from 'app/config/adsPlanner';
import { useAdsPlannerBaseDataContext } from 'components/adsPlanner/BaseData';

const AdsPlannerFiltersLabelsSubmenu = () => {
  const { labels } = useAdsPlannerBaseDataContext();
  const { filters, changeSelectedLabels } = useAdsPlannerContainer();
  const [selectedValues, setSelectedValues] = useState<number[]>(
    filters.selectedLabels,
  );

  const notifyChangedLabel = useRef(
    _debounce((data: number[]) => {
      changeSelectedLabels(data);
    }, AdsPlannerConfig.FILTER_DELAY),
  );

  useEffectOnce(() => {
    return () => {
      notifyChangedLabel.current.cancel();
    };
  });

  const handleChangeLabel = (label: number) => {
    setSelectedValues((prevSelectedValues) => {
      const labels = togglePrimitiveItemInArray<number>(
        label,
        prevSelectedValues,
      );

      notifyChangedLabel.current(labels);
      return [...labels];
    });
  };

  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger disabled={labels.length === 0}>
        <DropdownMenu.Item data-name="" data-cy="">
          <Icon icon={faTags} />
          Label
          <Icon size="sm" className="tw-ml-auto" icon={faChevronRight} />
        </DropdownMenu.Item>
      </DropdownMenu.SubTrigger>
      <DropdownMenu.Portal>
        <DropdownMenu.SubContent className="tw-z-10 tw-max-h-[70vh] tw-overflow-y-auto">
          {labels.map((label) => (
            <div key={`label-${label.id}`}>
              <DropdownMenu.Item onSelect={() => handleChangeLabel(label.id)}>
                <Checkbox
                  size="small"
                  onChange={() => null}
                  checked={selectedValues.includes(label.id)}
                />
                <div
                  className="tw-h-[12px] tw-w-[12px] tw-rounded-full"
                  style={{ backgroundColor: label.color }}
                />
                {label.name}
              </DropdownMenu.Item>
            </div>
          ))}
        </DropdownMenu.SubContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Sub>
  );
};

export default AdsPlannerFiltersLabelsSubmenu;
