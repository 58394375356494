import { BrowserStorageKey } from 'components/adsPlanner/constants';
import React, { FC, useCallback, useEffect } from 'react';
import { AdAccount, AdAccounts } from 'types/AdAccount';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { getInitialSelectedAdAccount } from 'components/adsPlanner/utils/filters';

const AdAccountSelectionContext = React.createContext<{
  selectedAdAccount: AdAccount | null;
}>({
  selectedAdAccount: null,
});

const AdAccountSelection: FC<{
  data: AdAccounts;
  children(params: {
    selected: {
      id: number | null;
    };
    select(adAccount: AdAccount): void;
  }): JSX.Element;
}> = ({ children, data }) => {
  const [selectedAdAccountId, setSelectedAdAccount] = useLocalStorage<
    number | null
  >(BrowserStorageKey.AD_ACCOUNT, getInitialSelectedAdAccount());
  const [selectedAdAccount, setSelectedAdAccountData] =
    useLocalStorage<AdAccount | null>(BrowserStorageKey.AD_ACCOUNT_DATA, null);

  const selectAdAccount = useCallback(
    (adAccount?: AdAccount) => {
      if (adAccount) {
        setSelectedAdAccountData(adAccount || null);
        setSelectedAdAccount(adAccount.id);
      }
    },
    [setSelectedAdAccount, setSelectedAdAccountData],
  );

  useEffect(() => {
    if (selectedAdAccountId === null) {
      selectAdAccount(data[0]);
    } else {
      selectAdAccount(data.find((item) => item.id === selectedAdAccountId));
    }
  }, [data, selectAdAccount, selectedAdAccountId]);

  return (
    <AdAccountSelectionContext.Provider
      value={{
        selectedAdAccount,
      }}
    >
      {children({
        selected: {
          id: selectedAdAccountId,
        },
        select: selectAdAccount,
      })}
    </AdAccountSelectionContext.Provider>
  );
};

export default AdAccountSelection;

export const useAdAccountSelectionContext = () => {
  const context = React.useContext(AdAccountSelectionContext);

  if (!context) {
    throw new Error('Must be used within AdAccountSelectionProvider');
  }

  return context;
};
