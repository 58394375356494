import React, { useContext, FC } from 'react';
import type { CampaignsState } from 'components/adsPlanner/campaign/CampaignsListContainer';

// @ts-ignore we do not need to implement default value
const CampaignsContext = React.createContext<CampaignsState>({});

type Props = {
  value: CampaignsState;
  children: React.ReactNode;
};

export const Provider: FC<Props> = ({ value, children }) => (
  <CampaignsContext.Provider value={value}>
    {children}
  </CampaignsContext.Provider>
);

export const useCampaignsContainer = () => {
  return useContext(CampaignsContext);
};
