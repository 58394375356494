import { FC } from 'react';
import { GraphContent } from 'types/insights/Graphs';
import { GraphType } from 'constants/analytics';
import LineChart from 'components/insights/graphs/LineChart';
import BarChart from 'components/insights/graphs/BarChart';
import PieChart from 'components/insights/graphs/PieChart';
import AreaChart from 'components/insights/graphs/AreaChart';

type Props = {
  graphType: GraphType;
  data: GraphContent[];
  primaryDataKey: string;
  secondaryDataKey?: string | null;
  isOnExport?: boolean;
  shouldDisplayAllTicks?: boolean;
};

const Graphs: FC<Props> = ({
  graphType,
  data,
  primaryDataKey,
  secondaryDataKey,
  isOnExport,
  shouldDisplayAllTicks,
}) => {
  switch (graphType) {
    case GraphType.LineChart:
      return (
        <LineChart
          primaryDataKey={primaryDataKey}
          secondaryDataKey={secondaryDataKey}
          data={data}
          isAnimationActive={!isOnExport}
        />
      );
    case GraphType.BarChart:
      return (
        <BarChart
          primaryDataKey={primaryDataKey}
          secondaryDataKey={secondaryDataKey}
          data={data}
          isAnimationActive={!isOnExport}
          shouldDisplayAllTicks={shouldDisplayAllTicks}
        />
      );
    case GraphType.PieChart:
      return (
        <PieChart
          isAnimationActive={!isOnExport}
          primaryDataKey={primaryDataKey}
          data={data}
        />
      );
    case GraphType.AreaChart:
      return (
        <AreaChart
          primaryDataKey={primaryDataKey}
          secondaryDataKey={secondaryDataKey}
          data={data}
          isAnimationActive={!isOnExport}
        />
      );
    default:
      return null;
  }
};

export default Graphs;
