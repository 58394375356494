import { Avatar, Chip, DropdownMenu, Icon, IconButton } from '@kontentino/ui';
import {
  faBolt,
  faBusinessTime,
  faChevronRight,
  faFilter,
  faIcons,
  faTags,
  faTasks,
} from '@fortawesome/pro-regular-svg-icons';
import { FC, useState } from 'react';
import WorkflowStatusIcon from 'app/components/workflowStatus/WorkflowStatusIcon';
import { useTranslation } from 'react-i18next';
import { useUser } from 'modules/user/userSelector';
import { PostsMeta } from 'types/Post';
import { PseudoPostType } from 'constants/post';
import {
  ChangePostsFilterHandler,
  FilterState,
} from 'app/modules/posts/hooks/useFilterState';
import PostTypeIcon from './PostTypeIcon';
import PostsFiltersDropdownOption from 'app/modules/posts/components/postsFiltersDropdown/PostsFiltersDropdownOption';
import { formatAndSortPostsMetadataStatuses } from 'app/modules/posts/utils/formatAndSortPostsMetadataStatuses';
import { RedDotIndicator } from 'app/components/RedDotIndicator';

type Props = {
  filterData: PostsMeta | null;
  activeData: FilterState;
  onReset: () => void;
  onChange: ChangePostsFilterHandler;
};

const getActiveFiltersCount = (activeData: FilterState) => {
  return [
    activeData.selectedLabels.length > 0,
    activeData.selectedPseudoTypes.length > 0,
    activeData.selectedStatuses.length > 0,
    activeData.selectedProjects.length > 0,
    activeData.selectedUserIds.length > 0,
  ].filter(Boolean).length;
};

const PostsFiltersDropdown: FC<Props> = ({
  activeData,
  filterData,
  onReset,
  onChange,
}) => {
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(false);
  const activeFiltersCount = getActiveFiltersCount(activeData);
  const currentUser = useUser();
  const { t } = useTranslation();
  const assignedToMePostsCount =
    filterData?.assignedUser.find((user) => user.id === currentUser.id)
      ?.postCount ?? 0;

  return (
    <span className="tw-relative">
      <DropdownMenu.Root
        open={isMainMenuOpen}
        onOpenChange={(open) => {
          if (!open) return;
          setIsMainMenuOpen(true);
        }}
      >
        <DropdownMenu.Trigger>
          <IconButton
            data-name="posts-filters"
            size={'medium'}
            className="tw-h-9"
            variant="secondary"
            icon={<Icon icon={faFilter} />}
          />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
          className="tw-z-10 tw-min-w-[260px]"
          data-name="posts-filters-options"
          onInteractOutside={() => setIsMainMenuOpen(false)}
          onEscapeKeyDown={() => setIsMainMenuOpen(false)}
        >
          <PostsFiltersDropdownOption
            disabled={!assignedToMePostsCount}
            name="posts-filter-dropdown-option-assigned-to-me-shortcut-option"
            onSelect={() =>
              onChange({
                key: 'selectedUserIds',
                value: currentUser.id,
              })
            }
            isChecked={activeData.selectedUserIds.includes(currentUser.id)}
            label={t('assignedToMe')}
            postsCount={assignedToMePostsCount}
            iconBefore={
              <Avatar
                size={20}
                src={currentUser.avatar?.src}
                name={currentUser.name}
              />
            }
          />
          <DropdownMenu.Separator />
          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger>
              <DropdownMenu.Item
                data-name="posts-filter-dropdown-option-status"
                data-cy="posts-filter-dropdown-option-status"
              >
                <Icon icon={faBolt} />
                Status
                <div className="tw-ml-auto tw-flex tw-items-center tw-gap-2">
                  {activeData.selectedStatuses.length > 0 && (
                    <RedDotIndicator />
                  )}
                  <Icon
                    size="sm"
                    className="tw-text-grayscale-100"
                    icon={faChevronRight}
                  />
                </div>
              </DropdownMenu.Item>
            </DropdownMenu.SubTrigger>
            <DropdownMenu.Portal>
              <DropdownMenu.SubContent className="tw-z-10 tw-max-h-[350px] tw-overflow-y-auto">
                {formatAndSortPostsMetadataStatuses(
                  filterData?.status ?? null,
                ).map((status) => (
                  <PostsFiltersDropdownOption
                    key={`post-status-${status.value}`}
                    onSelect={() =>
                      onChange({
                        key: 'selectedStatuses',
                        value: status.value,
                      })
                    }
                    isChecked={activeData.selectedStatuses.includes(
                      status.value,
                    )}
                    label={t(`workflowStatus.label.${status.value}`)}
                    postsCount={status.postCount}
                    iconBefore={<WorkflowStatusIcon status={status.value} />}
                  />
                ))}
              </DropdownMenu.SubContent>
            </DropdownMenu.Portal>
          </DropdownMenu.Sub>

          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger>
              <DropdownMenu.Item
                data-name="posts-filter-dropdown-option-post-type"
                data-cy="posts-filter-dropdown-option-post-type"
              >
                <Icon icon={faIcons} />
                Post type
                <div className="tw-ml-auto tw-flex tw-items-center tw-gap-2">
                  {activeData.selectedPseudoTypes.length > 0 && (
                    <RedDotIndicator />
                  )}
                  <Icon
                    size="sm"
                    className="tw-text-grayscale-100"
                    icon={faChevronRight}
                  />
                </div>
              </DropdownMenu.Item>
            </DropdownMenu.SubTrigger>
            <DropdownMenu.Portal>
              <DropdownMenu.SubContent className="tw-z-10 tw-max-h-[350px] tw-overflow-y-auto">
                {Object.entries(filterData?.type ?? {})?.map(
                  ([postType, count]) => (
                    <PostsFiltersDropdownOption
                      key={`post-type-${postType}`}
                      onSelect={() =>
                        onChange({
                          key: 'selectedPseudoTypes',
                          value: postType as PseudoPostType,
                        })
                      }
                      isChecked={activeData.selectedPseudoTypes.includes(
                        postType as PseudoPostType,
                      )}
                      iconBefore={
                        <PostTypeIcon type={postType as PseudoPostType} />
                      }
                      label={t(`postPseudoType.${postType}`)}
                      postsCount={count}
                    />
                  ),
                )}
              </DropdownMenu.SubContent>
            </DropdownMenu.Portal>
          </DropdownMenu.Sub>

          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger
              disabled={!filterData?.assignedUser.length}
            >
              <DropdownMenu.Item
                data-name="posts-filter-dropdown-option-assigned-to"
                data-cy="posts-filter-dropdown-option-assigned-to"
              >
                <Icon icon={faTasks} />
                Assigned to
                <div className="tw-ml-auto tw-flex tw-items-center tw-gap-2">
                  {activeData.selectedUserIds.length > 0 && <RedDotIndicator />}
                  <Icon
                    size="sm"
                    className="tw-text-grayscale-100"
                    icon={faChevronRight}
                  />
                </div>
              </DropdownMenu.Item>
            </DropdownMenu.SubTrigger>
            <DropdownMenu.Portal>
              <DropdownMenu.SubContent className="tw-z-10 tw-max-h-[350px] tw-overflow-y-auto">
                {filterData?.assignedUser.map((user) => (
                  <PostsFiltersDropdownOption
                    key={`post-assigned-to-${user.id}`}
                    onSelect={() =>
                      onChange({
                        key: 'selectedUserIds',
                        value: user.id,
                      })
                    }
                    isChecked={activeData.selectedUserIds.includes(user.id)}
                    label={user.id === currentUser.id ? t('me') : user.name}
                    postsCount={user.postCount}
                    iconBefore={
                      <Avatar
                        size={20}
                        src={user.avatar ?? ''}
                        name={user.name}
                      />
                    }
                  />
                ))}
              </DropdownMenu.SubContent>
            </DropdownMenu.Portal>
          </DropdownMenu.Sub>

          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger disabled={!filterData?.label.length}>
              <DropdownMenu.Item
                data-name="posts-filter-dropdown-option-label"
                data-cy="posts-filter-dropdown-option-label"
              >
                <Icon icon={faTags} />
                Label
                <div className="tw-ml-auto tw-flex tw-items-center tw-gap-2">
                  {activeData.selectedLabels.length > 0 && <RedDotIndicator />}
                  <Icon
                    size="sm"
                    className="tw-text-grayscale-100"
                    icon={faChevronRight}
                  />
                </div>
              </DropdownMenu.Item>
            </DropdownMenu.SubTrigger>
            <DropdownMenu.Portal>
              <DropdownMenu.SubContent className="tw-z-10 tw-max-h-[350px] tw-overflow-y-auto">
                {filterData?.label.map((label) => (
                  <PostsFiltersDropdownOption
                    key={`label-${label.id}`}
                    onSelect={() =>
                      onChange({
                        key: 'selectedLabels',
                        value: label.id,
                      })
                    }
                    isChecked={activeData.selectedLabels.includes(label.id)}
                    label={label.name}
                    postsCount={label.postCount}
                    iconBefore={
                      <div
                        className="tw-h-[12px] tw-w-[12px] tw-rounded-full"
                        style={{ backgroundColor: label.color }}
                      />
                    }
                  />
                ))}
              </DropdownMenu.SubContent>
            </DropdownMenu.Portal>
          </DropdownMenu.Sub>

          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger disabled={!filterData?.project?.length}>
              <DropdownMenu.Item
                data-name="posts-filter-dropdown-option-content-plan"
                data-cy="posts-filter-dropdown-option-content-plan"
              >
                <Icon icon={faBusinessTime} />
                Content plan
                <div className="tw-ml-auto tw-flex tw-items-center tw-gap-2">
                  {activeData.selectedProjects.length > 0 && (
                    <RedDotIndicator />
                  )}
                  <Icon
                    size="sm"
                    className="tw-text-grayscale-100"
                    icon={faChevronRight}
                  />
                </div>
              </DropdownMenu.Item>
            </DropdownMenu.SubTrigger>
            <DropdownMenu.Portal>
              <DropdownMenu.SubContent className="tw-z-10 tw-max-h-[350px] tw-overflow-y-auto">
                {filterData?.project.map((project) => (
                  <PostsFiltersDropdownOption
                    key={`project-${project.id}`}
                    onSelect={() =>
                      onChange({
                        key: 'selectedProjects',
                        value: project.id,
                      })
                    }
                    isChecked={activeData.selectedProjects.includes(project.id)}
                    label={project.name}
                    postsCount={project.postCount}
                  />
                ))}
              </DropdownMenu.SubContent>
            </DropdownMenu.Portal>
          </DropdownMenu.Sub>

          <DropdownMenu.Separator />
          <DropdownMenu.Item
            onClick={onReset}
            data-name="posts-filters-clear-all"
            data-cy="posts-filter-dropdown-option-clear-filters"
          >
            <span className="tw-w-full tw-text-right tw-text-md tw-font-medium">
              Clear all filters
            </span>
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
      {activeFiltersCount > 0 && (
        <Chip
          className="tw-absolute -tw-right-2 -tw-top-2 tw-border tw-border-white"
          variant="alert"
        >
          {activeFiltersCount}
        </Chip>
      )}
    </span>
  );
};

export default PostsFiltersDropdown;
