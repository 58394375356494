import { FC } from 'react';
import classes from 'react-style-classes';
import style from './profileSelectorFilterOption/ProfileSelectorFilterOption.module.scss';
import { colors, Icon } from '@kontentino/ui';
import {
  ProfileSelectorFilterOptionKey,
  ProfileSelectorFilterOptionProperty,
  ProfileSelectorPageTypes,
} from 'app/components/profileSelector/types/profileSelectorFilter';
import { useTranslation } from 'react-i18next';

type Props = {
  option: ProfileSelectorFilterOptionProperty;
  onClick?: (
    id: ProfileSelectorPageTypes | ProfileSelectorFilterOptionKey,
  ) => void;
  isSelected?: boolean;
};

const ProfileSelectorFilterOption: FC<Props> = ({
  option,
  onClick,
  isSelected,
}) => {
  const { t } = useTranslation();

  return (
    <div
      data-cy={`profile-management-sidebar-filter-item-${option.key}`}
      key={option.key}
      onClick={() => onClick?.(option.key)}
      className={classes(
        style.filterOptionButton,
        isSelected && style.filterOptionButtonActive,
      )}
    >
      <Icon
        icon={option.icon}
        size={16}
        color={option.iconColor ?? colors.gray80}
      />
      <div className={style.label}>
        {option.i18nKey ? t(option.i18nKey) : option.name}
      </div>
    </div>
  );
};

export default ProfileSelectorFilterOption;
