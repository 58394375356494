import { FC } from 'react';
import { filterPostsByStatusSelection } from 'app/modules/posts/modules/timeSavers/utils/filterPostsByStatusSelection';
import { usePost } from 'modules/post/postSelector';
import { PostDetail } from 'types/PostDetail';

type Params = {
  posts: PostDetail[];
  allPosts: PostDetail[];
};

type Props = {
  children: (params: Params) => JSX.Element;
  allowedStatuses: number[];
  selectedStatuses: number[];
};

const PostsSelectionContainer: FC<Props> = ({
  children,
  allowedStatuses,
  selectedStatuses,
}) => {
  const { posts: allPosts } = usePost();
  const posts = filterPostsByStatusSelection({
    posts: allPosts,
    allowedStatuses,
    selectedStatuses,
  });

  return children({
    posts,
    allPosts,
  });
};

export default PostsSelectionContainer;
