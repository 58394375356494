export const LOW_CONTRAST_RATIO = 4.5;

const ColorUtils = {
  hexToRGBA: (hexCode: string, alpha: number = 0.1) => {
    let hex = hexCode.replace('#', '');

    if (hex.length === 3) {
      hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${alpha})`;
  },
  getContrastRatio: (foregroundColor: string, backgroundColor: string) => {
    const cleanForeHexColor = foregroundColor.replace(/^#/, '');
    const cleanBackHexColor = backgroundColor.replace(/^#/, '');

    const getLuminance = (hexColor: string): number => {
      const rgb = parseInt(hexColor, 16);
      const r = (rgb >> 16) & 0xff;
      const g = (rgb >> 8) & 0xff;
      const b = (rgb >> 0) & 0xff;

      const sRGB = (value: number) => {
        const linear = value / 255;
        return linear <= 0.04045
          ? linear / 12.92
          : Math.pow((linear + 0.055) / 1.055, 2.4);
      };

      const gammaCorrectedR = sRGB(r);
      const gammaCorrectedG = sRGB(g);
      const gammaCorrectedB = sRGB(b);

      return (
        0.2126 * gammaCorrectedR +
        0.7152 * gammaCorrectedG +
        0.0722 * gammaCorrectedB
      );
    };

    const luminance1 = getLuminance(cleanForeHexColor);
    const luminance2 = getLuminance(cleanBackHexColor);

    return luminance1 > luminance2
      ? (luminance1 + 0.05) / (luminance2 + 0.05)
      : (luminance2 + 0.05) / (luminance1 + 0.05);
  },

  hasLowContrastRatio: (
    foregroundColor: string,
    backgroundColor: string,
    ratio: number = LOW_CONTRAST_RATIO,
  ) => {
    const contrastRatio = ColorUtils.getContrastRatio(
      foregroundColor,
      backgroundColor,
    );

    return contrastRatio <= ratio;
  },
};

export default ColorUtils;
