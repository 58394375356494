import React, { FC, ReactNode } from 'react';
import { Icon, IconButton } from '@kontentino/ui';
import { faClose } from '@fortawesome/pro-solid-svg-icons';

export type InfoBannerProps = {
  onClose?: () => void;
  content?: ReactNode;
};

const InfoBanner: FC<InfoBannerProps> = ({ content, onClose }) => {
  return (
    <div className="tw-relative tw-z-10 tw-flex tw-items-center tw-justify-center tw-bg-primary-100 tw-py-3 tw-pl-8 tw-pr-[60px] tw-text-center tw-text-white">
      <div>{content}</div>
      <div className="tw-absolute tw-right-1">
        <IconButton onClick={onClose} icon={<Icon icon={faClose} />} />
      </div>
    </div>
  );
};

export default InfoBanner;
