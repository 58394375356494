import { ModalManager } from 'context/ModalRouter';
import { PreSelectedValues } from 'app/components/timeSavers/assignMultipleTasksModal/Container';

const getParsedQueryValueToArray = (input: string): number[] => {
  const parsedValue = JSON.parse(input);

  if (Number.isFinite(parsedValue)) {
    return [parsedValue];
  } else if (Array.isArray(parsedValue)) {
    return parsedValue;
  }

  return [];
};

const getPreselectedValuesFromParams = (
  modalManager: ModalManager,
): PreSelectedValues => {
  let values: PreSelectedValues = {
    profiles: [],
    posts: [],
  };

  const queryParameters = modalManager.getQueryParameters([
    'profiles',
    'posts',
  ]);

  if (queryParameters.profiles) {
    values.profiles = getParsedQueryValueToArray(queryParameters.profiles);
  }

  if (queryParameters.posts) {
    values.posts = getParsedQueryValueToArray(queryParameters.posts);
  }

  return values;
};

export default getPreselectedValuesFromParams;
