import { Tooltip } from '@kontentino/ui';
import clsx from 'clsx';
import { forwardRef } from 'react';
import useBoolean from 'utils/hooks/useBoolean';

type Props = {
  label: React.ReactNode;
  icon: React.ReactNode;
};

const VerticalNavigationMenuOption = forwardRef<HTMLButtonElement, Props>(
  ({ label, icon, ...props }, ref) => {
    const [isHovered, setIsHovered] = useBoolean(false);

    return (
      <Tooltip
        placement="right"
        content={label}
        arrow={false}
        className="tw-shadow-md"
      >
        <span
          onMouseEnter={() => setIsHovered.on()}
          onMouseLeave={() => setIsHovered.off()}
          ref={ref}
          {...props}
          className="tw-relative tw-flex tw-h-full tw-w-full tw-flex-1 tw-cursor-pointer tw-items-center tw-justify-center tw-text-inherit tw-transition-all tw-duration-100 tw-ease-in-out hover:tw-text-primary-100"
        >
          <span
            className={clsx(
              'tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-md',
              {
                'tw-bg-primary-10': isHovered,
              },
            )}
          >
            {icon}
          </span>
        </span>
      </Tooltip>
    );
  },
);

export default VerticalNavigationMenuOption;
