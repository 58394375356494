import React, { FC } from 'react';
import List from './posts/List';
import SelectMoreItemsHeader from 'app/components/selectMoreItems/SelectMoreItemsHeader';
import { ListPost } from 'types/PostDetail';

type Props = {
  posts: ListPost[];
  selectedPosts: ListPost[];
  disabledPosts?: number[];
  warningMessages?: Record<string, string>;
  onSelectPost: (post: ListPost) => void;
  onSelectAllPosts: () => void;
  onUnselectAllPosts: () => void;
};

const Posts: FC<Props> = ({
  posts,
  selectedPosts,
  disabledPosts,
  warningMessages,
  onSelectPost,
  onSelectAllPosts,
  onUnselectAllPosts,
}) => {
  return (
    <div className="tw-w-full">
      <SelectMoreItemsHeader
        totalCount={posts.length - (disabledPosts?.length ?? 0)}
        selectedCount={selectedPosts.length}
        onSelectAll={() => onSelectAllPosts()}
        onSelectNone={() => onUnselectAllPosts()}
        className="tw-m-4"
      />
      <List
        posts={posts}
        disabledPosts={disabledPosts}
        warningMessages={warningMessages}
        selectedPosts={selectedPosts}
        onSelectPost={onSelectPost}
      />
    </div>
  );
};

export default Posts;
