import { FC } from 'react';
import { useLottie } from 'lottie-react';
import LoadingSpinnerAnimationData from 'app/shared/lottieAnimations/loadingSpinner.json';
import styles from 'app/modules/insights/components/initializingStatus/initializingSpinner/InitializingSpinner.module.scss';

const animationStyles = {
  maxWidth: '175px',
  maxHeight: '175px',
  background: 'transparent',
};

const animationOptions = {
  animationData: LoadingSpinnerAnimationData,
  loop: true,
  autoplay: true,
};

type Props = {
  text?: string;
};

const InitializingSpinner: FC<Props> = ({
  text = 'We are gathering your data, thanks for your patience.',
}) => {
  const { View } = useLottie(animationOptions, animationStyles);

  return (
    <div className={styles.initializingSpinner}>
      {View}
      <span className={styles.text}>{text}</span>
    </div>
  );
};

export default InitializingSpinner;
