import { FC } from 'react';
import style from './PublicPostPreview.module.scss';
import { modalRoutes } from 'constants/routes';
import { useModalHistory } from 'context/ModalRouter';
import { Button, Icon } from '@kontentino/ui';
import { faCheck, faClose } from '@fortawesome/pro-regular-svg-icons';

export const PostPreviewFooter: FC = () => {
  const modalHistory = useModalHistory();

  return (
    <div className={style.footer}>
      <Button
        iconBefore={<Icon icon={faCheck} />}
        variant="success"
        onClick={() => {
          modalHistory.push({
            hash: modalRoutes.PUBLIC_INVITE_USER,
            state: { actionType: 'cta' },
          });
        }}
        data-name="public-link-workflow-buttons"
        data-cy="public-link-workflow-button_approve"
      >
        Approve
      </Button>
      <Button
        iconBefore={<Icon icon={faClose} />}
        className="tw-ml-4"
        variant="danger"
        onClick={() =>
          modalHistory.push({
            hash: modalRoutes.PUBLIC_INVITE_USER,
            state: { actionType: 'cta' },
          })
        }
        data-name="public-link-workflow-buttons"
        data-cy="public-link-workflow-button_rework"
      >
        Rework
      </Button>
    </div>
  );
};
