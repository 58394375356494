import { useEffect, useMemo, useState } from 'react';
import { useFileDropzone } from 'app/hooks/useFileDropzone';
import PostsConfig from 'app/config/posts';
import { TextEditorFeature } from 'app/modules/textEditor/constants/textEditorFeature';
import { Module } from 'config';
import { useToast } from 'app/hooks/useToast';
import { useTranslation } from 'react-i18next';
import { Editor } from '@tiptap/core';
import { CommentAttachment } from 'app/modules/comments/types';
import { useMutation } from 'react-query';
import { ApiClientError } from 'api/client';
import CommentApi from 'app/modules/comments/api';
import { v4 as uuid } from 'uuid';

type Props = {
  features: TextEditorFeature[];
  editor: Editor | null;
};

export type LocalFileAttachment = {
  id: string;
  isUploading: boolean;
  uploadProgress?: number;
  hasError: boolean;
  rawFile: File;
  file: undefined;
};

export type UploadedFileAttachment = {
  id: number;
  isUploading: false;
  uploadProgress?: undefined;
  hasError: boolean;
  rawFile?: File;
  file: MarkOptional<CommentAttachment, 'comment_id' | 'medium_id'>;
};

export type FileAttachment = LocalFileAttachment | UploadedFileAttachment;

export const useFileAttachments = ({ features, editor }: Props) => {
  const toast = useToast();
  const [fileAttachments, setFileAttachments] = useState<FileAttachment[]>([]);
  const { t } = useTranslation();
  const dropzone = useFileDropzone({
    onDrop: addFiles,
    maxFiles: PostsConfig.MAX_FILES_TO_ACCEPT,
    disabled: !features.includes(TextEditorFeature.FileAttachments),
    noClick: true,
  });

  const isUploading = useMemo(
    () => fileAttachments.some((file) => file.isUploading),
    [fileAttachments],
  );

  const uploadedFileAttachments = useMemo(
    () => fileAttachments.filter((file) => file.file) as LocalFileAttachment[],
    [fileAttachments],
  );

  const uploadFile = useMutation(
    (fileEntry: LocalFileAttachment) => {
      updateById(fileEntry.id, (file) => ({
        ...(file as LocalFileAttachment),
        isUploading: true,
      }));
      return CommentApi.uploadCommentFile({
        file: fileEntry.rawFile,
        onProgress(progress) {
          updateById(fileEntry.id, (file) => ({
            ...(file as LocalFileAttachment),
            uploadProgress: progress,
            isUploading: true,
          }));
        },
      });
    },
    {
      onSuccess: async (response, fileEntry) => {
        updateById(fileEntry.id, () => ({
          id: response.id,
          isUploading: false,
          hasError: false,
          rawFile: fileEntry.rawFile,
          file: response,
        }));
      },
      onError(e: ApiClientError, fileEntry) {
        removeFileById(fileEntry.id);
        toast(e?.userMessage ?? t('somethingWentWrong'), 'error');
      },
    },
  );

  function addFiles(files: File[]) {
    const allFiles = fileAttachments.length + files.length;
    if (allFiles > Module.Pages.TEXT_EDITOR_MAX_ATTACHMENTS) {
      toast(
        t('validation.youCanSelectUpToAttachments', {
          count: Module.Pages.TEXT_EDITOR_MAX_ATTACHMENTS,
        }),
        'warning',
      );
      return;
    }

    setFileAttachments((prevFiles) => {
      const newFiles = prevFiles;
      files.forEach((file) => {
        const newFile = {
          id: uuid(),
          isUploading: false,
          hasError: false,
          rawFile: file,
          file: undefined,
        };
        prevFiles.push(newFile);
        uploadFile.mutate(newFile);
      });

      return newFiles.slice(0, Module.Pages.TEXT_EDITOR_MAX_ATTACHMENTS);
    });
  }

  function removeFileById(id: FileAttachment['id']) {
    setFileAttachments((prevFiles) => {
      return prevFiles.filter((file) => file.id !== id);
    });
  }

  function updateById(
    id: FileAttachment['id'],
    updatedAttachment: (file: FileAttachment) => FileAttachment,
  ) {
    setFileAttachments((prevFiles) => {
      return prevFiles.map((file) => {
        if (file.id === id) {
          return updatedAttachment(file);
        }
        return file;
      });
    });
  }

  useEffect(() => {
    if (editor?.storage?.fileAttachments) {
      editor.storage.fileAttachments.setFiles = (
        attachments: FileAttachment[],
      ) => setFileAttachments(attachments);
      editor.storage.fileAttachments.getFiles = () => uploadedFileAttachments;
      editor.storage.fileAttachments.clearFiles = () => setFileAttachments([]);
    }
  }, [editor, uploadedFileAttachments, setFileAttachments]);

  return {
    fileAttachments,
    isUploading,
    uploadedFileAttachments,
    removeFileById,
    dropzone,
  };
};
