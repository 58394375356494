import { FC } from 'react';
import { TooltipProps } from 'recharts';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import DateUtils, { formatDate } from 'app/utils/date';
import NumberUtil from 'utils/number';
import { faArrowUpRight } from '@fortawesome/pro-regular-svg-icons';
import { Icon } from '@kontentino/ui';
import { useTranslation } from 'react-i18next';

type Props = TooltipProps<ValueType, NameType> & {
  metricLabels?: string[];
  title: string;
  isCompared: boolean;
  isCombinedMetric?: boolean;
};

export const GraphTooltip: FC<Props> = ({
  active,
  payload,
  title,
  metricLabels,
  isCompared,
  isCombinedMetric,
}) => {
  const { t } = useTranslation();
  if (!active || !payload?.length) return null;

  return (
    <div className="tw-rounded-md tw-bg-grayscale-180 tw-p-3">
      <div className="tw-flex tw-items-center tw-gap-6">
        <span className="tw-text-sm tw-font-semibold tw-text-white">
          {t(title)}
        </span>
        {payload.length === 1 && (
          <span className="tw-text-sm tw-font-semibold tw-text-white">
            {NumberUtil.compact(Number(payload[0].value))}
          </span>
        )}
      </div>
      {payload && payload.length > 0 && (
        <div className="tw-mb-1 tw-text-sm tw-text-grayscale-90">
          {DateUtils.isValid(payload[0]?.payload?.date)
            ? formatDate(payload[0]?.payload?.date)
            : payload[0]?.payload?.date}
          {!isCombinedMetric && !isCompared && (
            <>
              {payload.length > 1 && !isCombinedMetric && !isCompared && (
                <span>&nbsp;vs&nbsp;</span>
              )}
              {payload[1]?.payload?.comparedDate &&
              DateUtils.isValid(payload[1]?.payload?.comparedDate)
                ? formatDate(payload[1]?.payload?.comparedDate)
                : payload[1]?.payload?.comparedDate}
            </>
          )}
        </div>
      )}

      {payload.length > 1 && (
        <div className="tw-flex tw-flex-col">
          {payload.map((data, index) => (
            <div
              key={data.dataKey}
              className="tw-flex tw-items-center tw-gap-2 tw-border-b tw-border-grayscale-160 tw-py-1 last:tw-border-b-0 last:tw-pb-0"
            >
              <div
                className="tw-h-3 tw-w-3 tw-rounded-sm"
                style={{ backgroundColor: data.color }}
              >
                &nbsp;
              </div>
              {metricLabels && metricLabels[index] && (
                <span className="tw-mr-8 tw-text-sm tw-capitalize tw-text-white">
                  {metricLabels[index]}
                </span>
              )}
              <div className="tw-ml-auto tw-flex tw-items-center tw-gap-2">
                <Icon
                  icon={faArrowUpRight}
                  className="tw-text-grayscale-90"
                  size={16}
                />
                <p className="tw-ml-auto tw-min-w-[20px] tw-text-right tw-text-sm tw-font-semibold tw-text-white">
                  {typeof data.value === 'number'
                    ? NumberUtil.compact(data.value)
                    : NumberUtil.compact(Number(data.value))}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
