import {
  faArrowRightFromBracket,
  faBadgeDollar,
  faBolt,
  faBookmark,
  faBullseyeArrow,
  faBullseyePointer,
  faCircleBolt,
  faClock,
  faComment,
  faEye,
  faFaceSmileRelaxed,
  faHandPointer,
  faHandPointLeft,
  faHandPointRight,
  faHeart,
  faMedal,
  faReplyAll,
  faShare,
  faShareNodes,
  faUsersRectangle,
  faVideo,
  faVideoArrowUpRight,
  faVideoPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { colors } from '@kontentino/ui';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import {
  MetricProperties,
  MetricProperty,
} from 'app/modules/insights/types/MetricProperty';

export const ALL_METRICS_PROPERTY: MetricProperty = {
  key: 'allMetrics',
  title: 'All Metrics',
  color: colors.primary.default,
  icon: faMedal,
};

export const COMMON_METRIC_PROPERTIES: MetricProperties = {
  allMetrics: ALL_METRICS_PROPERTY,
};

export const FB_METRICS_PROPERTIES: MetricProperties = {
  totalReachUnique: {
    key: 'totalReachUnique',
    title: 'Reach',
    color: colors.primary.default,
    icon: faBullseyeArrow,
    name: 'post_total_reach_unique',
    queryParameter: 'METRICS_POST_TOTAL_REACH_UNIQUE',
    customSubMetrics: [
      { name: 'post_impressions_paid_unique', title: 'Paid' },
      { name: 'post_impressions_organic_unique', title: 'Organic' },
    ],
  },
  postImpressionsUnique: {
    key: 'postImpressionsUnique',
    title: 'Calculated reach',
    color: colors.success.default,
    icon: faBullseyePointer,
    info: 'This calculation includes the number of unique people reached through either organic or paid reach. Additionally, FB estimates this metric through data sampling, which provide directional insights for outcomes that are hard to precisely quantify.',
    name: 'post_impressions_unique',
    queryParameter: 'METRICS_POST_IMPRESSIONS_UNIQUE',
  },
  postImpressions: {
    key: 'postImpressions',
    title: 'Impressions',
    color: colors.secondary.default,
    icon: faEye,
    name: 'post_impressions',
    queryParameter: 'METRICS_POST_IMPRESSIONS',
    customSubMetrics: [
      { name: 'post_impressions_paid', title: 'Paid' },
      { name: 'post_impressions_organic', title: 'Organic' },
    ],
  },
  reactions: {
    key: 'reactions',
    title: 'Reactions',
    color: colors.turquoise.default,
    icon: faFaceSmileRelaxed,
    name: 'post_reactions_by_type_total',
    queryParameter: 'METRICS_POST_REACTIONS_BY_TYPE_TOTAL___SUM',
  },
  comments: {
    key: 'comments',
    title: 'Comments',
    color: colors.warning.default,
    icon: faComment,
    name: 'post_comments',
    queryParameter: 'METRICS_POST_COMMENTS',
  },
  shares: {
    key: 'shares',
    title: 'Shares',
    color: colors.blueberry.dark,
    icon: faShareNodes,
    name: 'post_shares',
    queryParameter: 'METRICS_POST_SHARES',
  },
  postClicks: {
    key: 'postClicks',
    title: 'Post clicks',
    color: colors.teal.default,
    icon: faHandPointer,
    name: 'post_clicks_by_type',
    queryParameter: 'METRICS_POST_CLICKS_BY_TYPE___SUM',
  },
  engagement: {
    key: 'engagement',
    title: 'Engagement',
    color: colors.gray80,
    icon: faBolt,
    name: 'post_engagement',
    queryParameter: 'METRICS_POST_ENGAGED_USERS',
    customSubMetrics: [
      { name: 'post_comments', title: 'Comments' },
      { name: 'post_shares', title: 'Shares' },
      { name: 'post_reactions_by_type_total', title: 'Reactions' },
      { name: 'post_clicks_by_type', title: 'Clicks' },
    ],
  },
  engagedUsers: {
    key: 'engagedUsers',
    title: 'Engaged users',
    color: colors.blueAlt.dark,
    icon: faUsersRectangle,
    name: 'post_engaged_users',
    queryParameter: 'METRICS_POST_COMMENTS',
  },
  engagementRate: {
    key: 'engagementRate',
    title: 'Engagement rate',
    color: colors.danger.default,
    icon: faCircleBolt,
    name: 'post_engagement_rate',
    queryParameter: 'METRICS_POST_ENGAGEMENT_RATE',
  },
  videoAverageTimeWatched: {
    key: 'videoAverageTimeWatched',
    title: 'Average time watched',
    color: colors.burgundy.dark,
    icon: faClock,
    name: 'post_video_avg_time_watched',
    queryParameter: 'METRICS_POST_VIDEO_AVG_TIME_WATCHED',
  },
  videoViews: {
    key: 'videoViews',
    title: 'Video views',
    color: colors.lime.dark,
    icon: faVideo,
    name: 'post_video_views',
    queryParameter: 'METRICS_POST_VIDEO_VIEWS',
  },
  videoCompleteViewsOrganic: {
    key: 'videoCompleteViewsOrganic',
    title: 'Video complete views organic',
    color: colors.orange.default,
    icon: faVideoArrowUpRight,
    name: 'post_video_complete_views_organic',
    queryParameter: 'METRICS_POST_VIDEO_COMPLETE_VIEWS_ORGANIC',
  },
  videoCompleteViewsPaid: {
    key: 'videoCompleteViewsPaid',
    title: 'Video complete views paid',
    color: '#DD33FF',
    icon: faBadgeDollar,
    name: 'post_video_complete_views_paid',
    queryParameter: 'METRICS_POST_VIDEO_COMPLETE_VIEWS_PAID',
  },
};

export const IG_METRICS_PROPERTIES: MetricProperties = {
  reach: {
    key: 'reach',
    title: 'Reach',
    color: colors.primary.default,
    icon: faBullseyeArrow,
    name: 'reach',
    queryParameter: 'METRICS_REACH',
  },
  impressions: {
    key: 'impressions',
    title: 'Impressions',
    color: colors.success.default,
    icon: faEye,
    name: 'impressions',
    queryParameter: 'METRICS_IMPRESSIONS',
  },
  engagement: {
    key: 'engagement',
    title: 'Engagement',
    color: colors.secondary.default,
    icon: faBolt,
    name: 'engagement',
    queryParameter: 'METRICS_ENGAGEMENT',
  },
  likes: {
    key: 'likes',
    title: 'Likes',
    color: colors.turquoise.default,
    icon: faHeart,
    name: 'likes',
    queryParameter: 'METRICS_LIKES',
  },
  comments: {
    key: 'comments',
    title: 'Comments',
    color: colors.warning.default,
    icon: faComment,
    name: 'comments',
    queryParameter: 'METRICS_COMMENTS',
  },
  saved: {
    key: 'saved',
    title: 'Saved',
    color: colors.blueberry.dark,
    icon: faBookmark,
    name: 'saved',
    queryParameter: 'METRICS_SAVED',
  },
  videoViews: {
    key: 'videoViews',
    title: 'Video views',
    color: colors.teal.default,
    icon: faVideo,
    name: 'video_views',
    queryParameter: 'METRICS_VIDEO_VIEWS',
  },
  replies: {
    key: 'replies',
    title: 'Replies',
    color: colors.gray80,
    icon: faReplyAll,
    name: 'replies',
    queryParameter: 'METRICS_REPLIES',
  },
  exits: {
    key: 'exits',
    title: 'Exits',
    color: colors.blueAlt.dark,
    icon: faArrowRightFromBracket,
    name: 'exits',
    queryParameter: 'METRICS_EXITS',
  },
  tapsBack: {
    key: 'tapsBack',
    title: 'Taps back',
    color: colors.danger.default,
    icon: faHandPointLeft,
    name: 'taps_back',
    queryParameter: 'METRICS_TAPS_BACK',
  },
  tapsForward: {
    key: 'tapsForward',
    title: 'Taps forward',
    color: colors.lime.dark,
    icon: faHandPointRight,
    name: 'taps_forward',
    queryParameter: 'METRICS_TAPS_FORWARD',
  },
};

export const LI_METRICS_PROPERTIES: MetricProperties = {
  reactions: {
    key: 'reactions',
    title: 'Reactions',
    color: colors.primary.default,
    icon: faFaceSmileRelaxed,
    name: 'reaction',
    queryParameter: 'METRICS_REACTION___SUM',
  },
  comments: {
    key: 'comments',
    title: 'Comments',
    color: colors.success.default,
    icon: faComment,
    name: 'commentCount',
    queryParameter: 'METRICS_COMMENTCOUNT',
    customSubMetrics: [
      { name: 'commentCountTopLevel', title: 'Top level comments' },
      { name: 'replies', title: 'Replies' },
    ],
  },
  shares: {
    key: 'shares',
    title: 'Shares',
    color: colors.secondary.default,
    icon: faShare,
    name: 'shareCount',
    queryParameter: 'METRICS_SHARECOUNT',
  },
  postImpressions: {
    key: 'postImpressions',
    title: 'Impressions',
    color: colors.turquoise.default,
    icon: faEye,
    name: 'impressionCount',
    queryParameter: 'METRICS_IMPRESSIONCOUNT',
  },
  postClicks: {
    key: 'postClicks',
    title: 'Clicks',
    color: colors.warning.default,
    icon: faHandPointer,
    name: 'clickCount',
    queryParameter: 'METRICS_CLICKCOUNT',
  },
  engagement: {
    key: 'engagement',
    title: 'Engagement',
    color: colors.blueberry.dark,
    icon: faBolt,
    name: 'post_engagement',
    queryParameter: 'METRICS_POST_ENGAGEMENT',
  },
  engagementRate: {
    key: 'engagementRate',
    title: 'Engagement rate',
    color: colors.teal.default,
    icon: faCircleBolt,
    name: 'post_engagement_rate',
    queryParameter: 'METRICS_POST_ENGAGEMENT_RATE',
  },
  videoViews: {
    key: 'videoViews',
    title: 'Video views',
    color: colors.gray80,
    icon: faVideo,
    name: 'VIDEO_VIEW',
    queryParameter: 'METRICS_VIDEOSTATISTICS__VIDEO_VIEW',
  },
  viewers: {
    key: 'viewers',
    title: 'Engaged views',
    color: colors.blueAlt.dark,
    icon: faVideoPlus,
    name: 'VIEWER',
    queryParameter: 'METRICS_VIDEOSTATISTICS__VIEWER',
  },
  timeWatched: {
    key: 'timeWatched',
    title: 'Time watched',
    color: colors.danger.default,
    icon: faClock,
    name: 'timeWatched',
    queryParameter: 'METRICS_VIDEOSTATISTICS__TIME_WATCHED',
  },
};

export const getMetricsForPageType = (
  pageType: number,
  includeCommon: boolean = false,
) => {
  function getPageTypeMetrics() {
    switch (pageType) {
      case TYPE.FACEBOOK:
        return FB_METRICS_PROPERTIES;
      case TYPE.INSTAGRAM:
        return IG_METRICS_PROPERTIES;
      case TYPE.LINKEDIN:
        return LI_METRICS_PROPERTIES;
      default:
        return {};
    }
  }

  return {
    ...(includeCommon ? COMMON_METRIC_PROPERTIES : undefined),
    ...getPageTypeMetrics(),
  };
};

export const getMetricForPageType = (
  pageType: number,
  metric: string,
  includeCommon: boolean = false,
): MetricProperty | undefined => {
  return getMetricsForPageType(pageType, includeCommon)[metric];
};
