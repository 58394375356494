import React, { FC } from 'react';
import clsx from 'clsx';
import { Tooltip } from '@kontentino/ui';
import { useTranslation } from 'react-i18next';

type Props = {
  totalCount: number;
  selectedCount: number;
  onSelectAll?: (e: React.MouseEvent) => void;
  onSelectNone?: (e: React.MouseEvent) => void;
  className?: string;
  disabled?: boolean | string;
};

const SelectMoreItemsHeader: FC<Props> = ({
  selectedCount,
  totalCount,
  onSelectNone,
  onSelectAll,
  className,
  disabled,
}) => {
  const { t } = useTranslation();

  const disabledReason =
    disabled || (totalCount === 0 && t('noItemsEligibleForSelection'));

  return (
    <div
      className={clsx(
        'tw-flex tw-h-10 tw-items-center tw-justify-between tw-rounded tw-bg-grayscale-5 tw-px-4 tw-text-md tw-font-medium tw-text-grayscale-180',
        className,
      )}
    >
      <div>
        <span
          className={clsx({
            'tw-font-medium tw-text-primary-100':
              selectedCount !== totalCount && selectedCount > 0,
          })}
        >
          <span className="tw-font-medium">{selectedCount}</span>{' '}
          <span className="tw-lowercase">{t('selected')}</span>
        </span>
      </div>
      {onSelectAll && onSelectNone && (
        <Tooltip content={typeof disabledReason === 'string' && disabledReason}>
          <span
            className={clsx({
              'tw-cursor-not-allowed tw-text-grayscale-100': disabledReason,
            })}
          >
            <span
              onClick={disabledReason ? undefined : onSelectAll}
              className={clsx({
                'tw-text-primary-100 tw-underline':
                  selectedCount === totalCount && totalCount > 0,
                'tw-cursor-pointer hover:tw-underline': !disabledReason,
              })}
            >
              {t('all')}
            </span>
            <span> / </span>
            <span
              onClick={disabledReason ? undefined : onSelectNone}
              className={clsx({
                'tw-text-primary-100 tw-underline':
                  selectedCount === 0 && totalCount > 0,
                'tw-cursor-pointer hover:tw-underline': !disabledReason,
              })}
            >
              {t('none')}
            </span>
          </span>
        </Tooltip>
      )}
    </div>
  );
};

export default SelectMoreItemsHeader;
