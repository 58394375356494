import { useEffect, useState } from 'react';
import FilesUtil from 'utils/files';
import { useEffectOnce } from 'utils/hooks/useEffectOnce';

export function useMemoizedImageUrls(files: File[]) {
  const [fileUrls, setFileUrls] = useState<Record<string, string>>({});

  useEffect(() => {
    setFileUrls((prev) => {
      const { images } = FilesUtil.detectFiles(files);

      images.forEach((file: File) => {
        if (!prev[file.name]) {
          prev[file.name] = URL.createObjectURL(file);
        }
      });

      return { ...prev };
    });
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files.map(({ name }) => name).join('-')]);

  useEffectOnce(() => {
    return () => {
      Object.values(fileUrls).forEach((url) => {
        URL.revokeObjectURL(url);
      });
    };
  });

  return fileUrls;
}
