import { ExpenseName, TotalExpenseObject } from 'types/AnalyticsPost';

const currencyMap: Record<string, string> = {
  EUR: '€',
};

export const getAmountSpent = (
  totalExpenses?: Record<ExpenseName, TotalExpenseObject>,
) => {
  const budgetData = totalExpenses
    ? Object.values<TotalExpenseObject>(totalExpenses).reduce(
        (acc, totalExpense) => {
          if (!acc.currency.length) {
            acc.currency = totalExpense.currency;
          }
          acc.value += totalExpense.budget;
          return acc;
        },
        {
          value: 0,
          currency: '',
        },
      )
    : undefined;

  return budgetData && budgetData.value
    ? `${budgetData.value}${
        currencyMap[budgetData.currency] ?? budgetData.currency
      }`
    : 'Not available';
};
