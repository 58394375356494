import React, { FC } from 'react';
import FilesUtil from 'utils/files';
import RecordEntriesList from 'components/shared/RecordEntriesList';
import { useMemoizedImageUrls } from 'app/modules/textEditor/hooks/useMemoizedImageUrls';
import { Tooltip } from '@kontentino/ui';
import { AttachmentsConfig } from 'config/attachments';
import { FileAttachment } from '../hooks/useFileAttachments';
import clsx from 'clsx';
import MediaPreview from 'app/components/compoundMediaPreview';

type Props = {
  attachments: FileAttachment[];
  onRemoveClick(id: string | number): void;
  isDisabled?: boolean;
  className?: string;
};

const FileAttachments: FC<Props> = ({
  attachments,
  isDisabled,
  onRemoveClick,
  className,
}) => {
  const imageUrls = useMemoizedImageUrls(
    attachments.filter((a) => !!a.rawFile).map((a) => a.rawFile!),
  );

  return (
    <>
      {attachments.length > 0 && (
        <div
          className={clsx(
            'tw-flex tw-flex-wrap tw-gap-2 tw-px-4 tw-pb-2 ',
            className,
          )}
        >
          {attachments.map((attachment) => {
            const previewSrc =
              attachment.file?.thumb_size_url ??
              imageUrls[attachment.rawFile?.name ?? ''];

            return (
              <Tooltip
                key={attachment.id}
                delay={AttachmentsConfig.TOOLTIP_DELAY}
                content={
                  <RecordEntriesList
                    record={{
                      name:
                        attachment.file?.file_name ?? attachment.rawFile!.name,
                      size: FilesUtil.formatBytes(
                        attachment.file?.file_size ?? attachment.rawFile!.size,
                        1,
                      ),
                      type: FilesUtil.getFileNameExtension(
                        attachment.file?.file_name ?? attachment.rawFile!.name,
                      ),
                    }}
                  />
                }
              >
                <MediaPreview.Root
                  className="tw-h-[4rem] tw-w-[4rem]"
                  isLoading={attachment.isUploading}
                >
                  <MediaPreview.Image
                    loadingProgress={attachment.uploadProgress}
                    src={previewSrc}
                    fileName={
                      attachment.file?.file_name ?? attachment.rawFile?.name
                    }
                  />
                  <MediaPreview.Overlay />
                  <MediaPreview.Actions>
                    <MediaPreview.ActionButton
                      actionType="remove"
                      onClick={
                        isDisabled
                          ? undefined
                          : () => onRemoveClick(attachment.id)
                      }
                    />
                  </MediaPreview.Actions>
                </MediaPreview.Root>
              </Tooltip>
            );
          })}
        </div>
      )}
    </>
  );
};

export default FileAttachments;
