import React, { FC, useState } from 'react';
import { Button, Icon } from '@kontentino/ui';
import { faCreditCard } from '@fortawesome/pro-light-svg-icons';
import { openUrl } from 'utils/url';
import { pluralize } from 'utils/lang';
import OldApp from 'config/oldApp';

type Props = {
  daysLeft: number;
};

const TrialDaysLeftButton: FC<Props> = ({ daysLeft }) => {
  const [isHovered, setHovered] = useState(false);

  return (
    <Button
      variant="special"
      className="tw-h-[36px] tw-whitespace-nowrap"
      iconBefore={isHovered && <Icon icon={faCreditCard} />}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => openUrl(OldApp.URL.BILLING)}
    >
      {isHovered ? 'Buy now' : `${pluralize(daysLeft, 'day')} left`}
    </Button>
  );
};

export default TrialDaysLeftButton;
