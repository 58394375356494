import { FC } from 'react';
import Container, {
  PagePerformanceParams,
} from 'components/analyticsPdfExports/pageExport/Container';
import { formatDate } from 'app/utils/date';
import { getQueryParameters } from 'utils/url';
import style from 'components/analyticsPdfExports/pageExport/PageExport.module.scss';
import { getGraphsToUse } from 'components/insights/utils/getGraphsToUse';
import GraphSection from 'components/insights/pagePerformance/pagePerformanceGraphs/GraphSection';
import TableHead from 'components/analyticsPdfExports/TableHead';
import TableRowItem from 'components/analyticsPdfExports/TableRowItem';
import {
  AGGREGATED_METRICS,
  RESTRICTED_METRICS,
} from 'constants/analyticsGraphs';
import { GraphStructure } from 'types/insights/Graphs';

type Props = {};

type CommonGraphProps = {
  key: string;
  id: string;
  pageType: number;
  graph: GraphStructure;
  startDate?: string;
  endDate?: string;
  comparedStartDate?: string;
  comparedEndDate?: string;
  isOnExport: boolean;
};

const PageExport: FC<Props> = () => {
  const { dateFrom, dateTo } = getQueryParameters(
    ['dateFrom', 'dateTo'],
    window.location.search,
  );
  const { pageType } = getQueryParameters(
    ['pageFansActiveTab', 'cumulativeReachActiveTab', 'pageType'],
    window.location.search,
  );

  const renderAggregatedMetricsGraph = (
    graph: GraphStructure,
    commonProps: CommonGraphProps,
  ) => (
    <TableRowItem key={graph.metricParameter}>
      <div />
    </TableRowItem>
  );

  const renderPageFansGraph = (
    graph: GraphStructure,
    commonProps: CommonGraphProps,
  ) => (
    <TableRowItem key={graph.metricParameter}>
      <div />
    </TableRowItem>
  );

  const renderPagePostsImpressions = (
    graph: GraphStructure,
    commonProps: CommonGraphProps,
  ) => (
    <TableRowItem key={graph.metricParameter}>
      <div />
    </TableRowItem>
  );

  const renderGraph = (
    graph: GraphStructure,
    graphData: PagePerformanceParams,
  ) => {
    const commonGraphProps = {
      key: graph.metricParameter,
      id: graphData.id,
      pageType: Number(pageType),
      graph,
      startDate: graphData.dateFrom,
      endDate: graphData.dateTo,
      comparedStartDate: graphData.comparedStartDate,
      comparedEndDate: graphData.comparedEndDate,
      isOnExport: true,
    };

    if (AGGREGATED_METRICS.includes(graph.metricParameter)) {
      return renderAggregatedMetricsGraph(graph, commonGraphProps);
    }

    switch (graph.metricParameter) {
      case 'page_fan_adds_by_paid_non_paid_unique':
        return renderPageFansGraph(graph, commonGraphProps);

      case 'page_posts_impressions_unique':
        return renderPagePostsImpressions(graph, commonGraphProps);

      default:
        return (
          <TableRowItem key={graph.metricParameter}>
            <GraphSection {...commonGraphProps} />
          </TableRowItem>
        );
    }
  };

  const getAvailableGraphs = (graphData: PagePerformanceParams | null) => {
    if (graphData) {
      const graphsToUse = getGraphsToUse(graphData.pageType);

      return graphData.restricted
        ? graphsToUse.filter(
            (graph) => !RESTRICTED_METRICS.includes(graph.metricParameter),
          )
        : graphsToUse;
    }

    return [];
  };

  const renderTableBody = (graphData: PagePerformanceParams | null) => {
    if (graphData) {
      return (
        <tbody>
          {getAvailableGraphs(graphData).map((graph) =>
            renderGraph(graph, graphData),
          )}
        </tbody>
      );
    }

    return null;
  };

  return (
    <Container>
      {(params) => (
        <table className={style.wrapperTable}>
          <TableHead isPrimary>
            {params.pageName}
            {!!dateFrom && !!dateTo && (
              <span className={style.smaller}>
                {`${formatDate(dateFrom)} - ${formatDate(dateTo)}`}
              </span>
            )}
          </TableHead>
          {renderTableBody(params.graphData)}
        </table>
      )}
    </Container>
  );
};

export default PageExport;
