import { useMemo, useState } from 'react';
import { Page } from 'app/modules/insights/types/Page';
import {
  ProfileSelectorFilterOptionKey,
  ProfileSelectorPageTypes,
} from 'app/components/profileSelector/types/profileSelectorFilter';
import { PROFILE_SELECTOR_FILTER_OPTIONS_PROPERTIES } from 'app/components/profileSelector/constants';
import getPageTypeFilterOptions from 'app/components/profileSelector/utils/getPageTypeFilterOptions';
import getFilteredDataBySearchQuery from 'app/components/profileSelector/utils/getFilteredDataBySearchQuery';

const useInsightsSelectorFilter = (pages: Page[]) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedFilterOption, setSelectedFilterOption] = useState<
    ProfileSelectorFilterOptionKey | ProfileSelectorPageTypes
  >(ProfileSelectorFilterOptionKey.All);

  const sidebarPageTypeOptions = useMemo(() => {
    return getPageTypeFilterOptions(
      pages.map((page) => page._type) as ProfileSelectorPageTypes[],
    );
  }, [pages]);

  const sidebarFilterOptions = [
    {
      key: ProfileSelectorFilterOptionKey.All,
      ...PROFILE_SELECTOR_FILTER_OPTIONS_PROPERTIES[
        ProfileSelectorFilterOptionKey.All
      ],
    },
    ...sidebarPageTypeOptions,
  ];

  const filteredPagesBySelectedType = useMemo(() => {
    if (selectedFilterOption === ProfileSelectorFilterOptionKey.All) {
      return pages;
    }

    if (!Number.isNaN(selectedFilterOption)) {
      return pages.filter((page) => page._type === selectedFilterOption);
    }

    return [];
  }, [pages, selectedFilterOption]);

  const filteredPages = useMemo(() => {
    return getFilteredDataBySearchQuery<Page>(
      searchQuery,
      filteredPagesBySelectedType,
    );
  }, [filteredPagesBySelectedType, searchQuery]);

  return {
    sidebarFilterOptions,
    searchQuery,
    setSearchQuery,
    selectedFilterOption,
    selectFilterOption: setSelectedFilterOption,
    filteredPages,
  };
};

export default useInsightsSelectorFilter;
