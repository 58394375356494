import React, { useContext, FC } from 'react';
import type { AdsPlannerState } from 'components/adsPlanner/Container';

// @ts-ignore we do not need to implement default value
const AdsPlannerContext = React.createContext<AdsPlannerState>({});

type Props = {
  value: AdsPlannerState;
  children: React.ReactNode;
};

export const Provider: FC<Props> = ({ value, children }) => (
  <AdsPlannerContext.Provider value={value}>
    {children}
  </AdsPlannerContext.Provider>
);

export const useAdsPlannerContainer = () => {
  return useContext(AdsPlannerContext);
};
