import React, { FC, ReactNode } from 'react';
import {
  Modal as ResponsiveModal,
  ModalProps as ResponsiveModalProps,
} from 'react-responsive-modal';
import './modalDialog/ModalDialog.scss';
import { useModalContext } from './ModalProvider';
import clsx from 'clsx';
import { mergeTailwindClasses } from '@kontentino/ui';

export type ModalDialogProps = {
  id?: string;
  children: ReactNode | ReactNode[];
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'responsive';
  focusTrapped?: boolean;
} & Pick<ResponsiveModalProps, 'classNames' | 'styles'>;

const ModalDialog: FC<ModalDialogProps> = ({ size = 'sm', ...props }) => {
  const modalContext = useModalContext();

  return (
    <ResponsiveModal
      styles={props.styles}
      modalId={props.id}
      center
      closeOnEsc={false}
      classNames={{
        ...props.classNames,
        modal: mergeTailwindClasses(
          clsx(
            'tw-w-full tw-overflow-y-hidden',
            {
              '!tw-max-w-[400px]': size === 'xs',
              '!tw-max-w-[576px]': size === 'sm',
              '!tw-max-w-[768px]': size === 'md',
              '!tw-max-w-[992px]': size === 'lg',
              'tw-max-h-[min(800px,90vh)] !tw-overflow-auto':
                modalContext.isContentHeightAutoResizable,
            },
            props.classNames?.modal,
          ),
        ),
      }}
      showCloseIcon={false}
      focusTrapped={props.focusTrapped}
      {...modalContext}
    >
      {props.children}
    </ResponsiveModal>
  );
};

export default ModalDialog;
