import {
  faArrowDownRight,
  faArrowUpRight,
  faHyphen,
} from '@fortawesome/pro-regular-svg-icons';
import { Icon } from '@kontentino/ui';
import clsx from 'clsx';
import React, { FC, useMemo } from 'react';
import { formatCompactPercentageValue } from 'app/modules/insights/modules/pagePerformance/utils/format';

type MetricBadgeProps = {
  percentage: number;
  size?: 'sm' | 'lg';
};

const MetricBadge: FC<MetricBadgeProps> = ({ percentage = 0, size = 'lg' }) => {
  const { colorScheme, icon } = useMemo(() => {
    switch (Math.sign(percentage) as 0 | 1 | -1) {
      case 0:
        return {
          colorScheme: 'grayscale',
          icon: faHyphen,
        };
      case 1:
        return {
          colorScheme: 'success',
          icon: faArrowUpRight,
        };
      case -1:
        return {
          colorScheme: 'danger',
          icon: faArrowDownRight,
        };
    }
  }, [percentage]);

  function formatPercentage(value: number) {
    return `${Math.sign(value) === 1 ? '+' : ''}${formatCompactPercentageValue(
      value,
    )}`;
  }

  return (
    <div
      className={clsx(
        `tw-inline-flex tw-items-center tw-rounded tw-bg-${colorScheme}-10 tw-gap-1`,
        {
          'tw-px-1 tw-py-[3px]': size === 'sm',
          'tw-px-2 tw-py-1': size === 'lg',
        },
      )}
    >
      <Icon icon={icon} className={`tw-text-${colorScheme}-100`} />
      <span
        className={clsx(
          `tw-text-${colorScheme}-100 tw-text-sm tw-font-medium`,
          {
            'tw-text-sm': size === 'lg',
            'tw-text-xs': size === 'sm',
          },
        )}
      >
        {formatPercentage(percentage)}
      </span>
    </div>
  );
};

export default MetricBadge;
