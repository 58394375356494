import { DATE_PRESETS } from '@kontentino/kontentino-constants/Presets';
import dayjs, { Dayjs } from 'dayjs';
import { DateRangeOnChangeParams } from 'types/Date';

export const getDatesFromPreset = (
  preset: string | null,
): DateRangeOnChangeParams => {
  let startDate, endDate;

  switch (preset) {
    case DATE_PRESETS.TODAY:
      startDate = dayjs();
      endDate = dayjs();
      break;
    case DATE_PRESETS.YESTERDAY:
      startDate = dayjs().subtract(1, 'days');
      endDate = dayjs().subtract(1, 'days');
      break;
    case DATE_PRESETS.TOMORROW:
      startDate = dayjs().add(1, 'days');
      endDate = dayjs().add(1, 'days');
      break;
    case DATE_PRESETS.LAST_7_DAYS:
      startDate = dayjs().subtract(7, 'days');
      endDate = dayjs();
      break;
    case DATE_PRESETS.LAST_14_DAYS:
      startDate = dayjs().subtract(14, 'days');
      endDate = dayjs();
      break;
    case DATE_PRESETS.LAST_30_DAYS:
      startDate = dayjs().subtract(30, 'days');
      endDate = dayjs();
      break;
    case DATE_PRESETS.LAST_WEEK:
      startDate = dayjs().startOf('week').subtract(7, 'days');
      endDate = dayjs().endOf('week').subtract(7, 'days');
      break;
    case DATE_PRESETS.THIS_WEEK:
      startDate = dayjs().startOf('week');
      endDate = dayjs().endOf('week');
      break;
    case DATE_PRESETS.THIS_MONTH:
      startDate = dayjs().startOf('month');
      endDate = dayjs().endOf('month');
      break;
    case DATE_PRESETS.LAST_MONTH:
      startDate = dayjs().subtract(1, 'months').startOf('month');
      endDate = dayjs().subtract(1, 'months').endOf('month');
      break;
    case DATE_PRESETS.NEXT_7_DAYS:
      startDate = dayjs().add(1, 'days');
      endDate = dayjs().add(7, 'days');
      break;
    case DATE_PRESETS.NEXT_14_DAYS:
      startDate = dayjs().add(1, 'days');
      endDate = dayjs().add(14, 'days');
      break;
    case DATE_PRESETS.NEXT_30_DAYS:
      startDate = dayjs().add(1, 'days');
      endDate = dayjs().add(30, 'days');
      break;
    case DATE_PRESETS.NEXT_WEEK:
      startDate = dayjs().startOf('week').add(7, 'days');
      endDate = dayjs().endOf('week').add(7, 'days');
      break;
    case DATE_PRESETS.NEXT_MONTH:
      startDate = dayjs().add(1, 'months').startOf('month');
      endDate = dayjs().add(1, 'months').endOf('month');
      break;
    default:
      startDate = null;
      endDate = null;
      break;
  }

  return { startDate, endDate };
};

type Options = { maxEndDate?: Dayjs | null };

export function getDatesWithConstraints(
  dates: DateRangeOnChangeParams,
  options: Options,
) {
  return Object.fromEntries(
    Object.entries(dates).map(([key, value]) => {
      if (
        value &&
        options?.maxEndDate &&
        value.isAfter(options.maxEndDate, 'day')
      ) {
        value = options.maxEndDate;
      }

      return [key, value];
    }),
  ) as DateRangeOnChangeParams;
}

export function getDatesAutoSelectPeriodWithConstrains(
  dates: DateRangeOnChangeParams,
  autoSelectPeriod: number,
  options: Options,
): DateRangeOnChangeParams {
  const newDates = { ...dates };

  if (newDates.startDate && options.maxEndDate) {
    const possibleEndDate = newDates.startDate
      .clone()
      .add(autoSelectPeriod, 'day');

    if (possibleEndDate.isAfter(options.maxEndDate, 'day')) {
      newDates.endDate = options.maxEndDate.clone();
      newDates.startDate = newDates.endDate
        .clone()
        .subtract(autoSelectPeriod, 'day');
    } else {
      newDates.endDate = possibleEndDate;
    }

    return newDates;
  }

  return {
    startDate: null,
    endDate: null,
  };
}
