import Logger from 'utils/logger';
import { getQueryParameter } from 'utils/url';
import type { FilterState } from 'components/adsPlanner/Container';
import { BrowserStorage } from 'utils/browserStorage';
import { BrowserStorageKey } from 'components/adsPlanner/constants';
import { OrderDirection } from 'app/types';
import dayjs from 'dayjs';

export const defaultStartDate = dayjs().subtract(1, 'month');
export const defaultEndDate = dayjs().add(3, 'month');

export const getInitialSelectedAdAccount = () => {
  const adAccountFromQuery = getQueryParameter('adAccountId');

  if (
    adAccountFromQuery.length &&
    Number.isInteger(parseInt(adAccountFromQuery))
  ) {
    BrowserStorage.set(BrowserStorageKey.AD_ACCOUNT, adAccountFromQuery);

    return parseInt(adAccountFromQuery);
  }

  const adAccountFromLocalStorage = BrowserStorage.get(
    BrowserStorageKey.AD_ACCOUNT,
  );
  if (
    adAccountFromLocalStorage &&
    Number.isInteger(parseInt(adAccountFromLocalStorage))
  ) {
    return parseInt(adAccountFromLocalStorage);
  }

  return null;
};

export const getInitialFilters = (): FilterState => {
  const initialFilters: FilterState = {
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    orderDirection: 'asc' as OrderDirection,
    selectedLabels: [],
    selectedWorkflowStatuses: [],
    selectedEffectiveStatuses: [],
    selectedObjectives: [],
    searchQuery: '',
  };

  try {
    const startDate = dayjs(getQueryParameter('from'));
    const endDate = dayjs(getQueryParameter('to'));

    if (startDate.isValid() && endDate.isValid()) {
      initialFilters.startDate = startDate;
      initialFilters.endDate = endDate;
    }

    const workflowStatuses = getQueryParameter('workflow_statuses');
    if (workflowStatuses) {
      const parsedWorkflowStatuses = JSON.parse(workflowStatuses);

      if (Array.isArray(parsedWorkflowStatuses)) {
        initialFilters.selectedWorkflowStatuses = parsedWorkflowStatuses.filter(
          (id) => Number.isInteger(id),
        );
      } else {
        if (Number.isInteger(parsedWorkflowStatuses)) {
          initialFilters.selectedWorkflowStatuses = [parsedWorkflowStatuses];
        }
      }
    }

    const effectiveStatuses = getQueryParameter('effective_statuses');
    if (effectiveStatuses) {
      const parsedEffectiveStatuses = JSON.parse(effectiveStatuses);

      if (Array.isArray(parsedEffectiveStatuses)) {
        initialFilters.selectedEffectiveStatuses =
          parsedEffectiveStatuses.filter((id) => Number.isInteger(id));
      } else {
        if (Number.isInteger(parsedEffectiveStatuses)) {
          initialFilters.selectedEffectiveStatuses = [parsedEffectiveStatuses];
        }
      }
    }

    const labels = getQueryParameter('labels');
    if (labels) {
      const parsedLabels = JSON.parse(labels);

      if (Array.isArray(parsedLabels)) {
        initialFilters.selectedLabels = parsedLabels.filter((id) =>
          Number.isInteger(id),
        );
      } else {
        if (Number.isInteger(parsedLabels)) {
          initialFilters.selectedLabels = [parsedLabels];
        }
      }
    }

    const orderDirection = getQueryParameter('order');
    if (orderDirection.length) {
      initialFilters.orderDirection = orderDirection as OrderDirection;
    }

    const query = getQueryParameter('search');
    if (query) {
      initialFilters.searchQuery = query;
    }
  } catch (e) {
    Logger.error(e);
  }

  return initialFilters;
};
