import { ForwardedRef, MutableRefObject, SyntheticEvent } from 'react';

export function stopPropagation<T extends SyntheticEvent | Event>(
  callback?: (e?: T) => void,
) {
  return (e?: T) => {
    e?.stopPropagation();
    callback?.(e);
  };
}

export function preventDefault<T extends SyntheticEvent | Event>(
  callback?: (e?: T) => void,
) {
  return (e: T) => {
    e.preventDefault();
    callback?.(e);
  };
}

export function multiRef<T extends HTMLElement>(
  ...refHandlers: (
    | ((node: T | null) => void)
    | MutableRefObject<T>
    | null
    | ForwardedRef<T>
  )[]
) {
  return (node: T | null) => {
    refHandlers.forEach((refHandler) => {
      if (typeof refHandler === 'function') {
        refHandler(node);
      } else if (refHandler?.current && node) {
        refHandler.current = node;
      }
    });
  };
}
