import { FC } from 'react';
import style from 'components/date-range-picker/presets/Item.module.scss';
import classes from 'react-style-classes';

type Props = {
  isActive: boolean;
  onClick: AnyFunction;
  label: string;
};

const Item: FC<Props> = ({ isActive, onClick, label }) => (
  <div
    onClick={onClick}
    tabIndex={0}
    className={classes(style.item, isActive && style.active)}
  >
    {label}
  </div>
);

export default Item;
