import type { ModuleStatus } from 'types/ModuleStatus';
import { PagesActionName } from 'modules/page/pageActions';
import { MODULE_STATUS } from 'constants/modules';
import { PageData, ProfileGroup } from 'types/Page';

export interface PageStore {
  status: ModuleStatus;
  pages: PageData[];
  profileGroups: ProfileGroup[];
}

function getInitialState(): PageStore {
  return {
    status: MODULE_STATUS.Idle,
    pages: [],
    profileGroups: [],
  };
}

function pageReducer(state = getInitialState(), action: any) {
  switch (action.type) {
    case PagesActionName.SET_MODULE_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case PagesActionName.SET_PAGES_DATA:
      return {
        ...state,
        pages: action.payload.pages,
        profileGroups: action.payload.profileGroups,
      };
    default:
      return state;
  }
}

export default pageReducer;
