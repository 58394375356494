import React, { FC } from 'react';
import likeIcon from './reactionIcons/like.svg';
import loveIcon from './reactionIcons/love.svg';
import hahaIcon from './reactionIcons/haha.svg';
import wowIcon from './reactionIcons/wow.svg';
import sadIcon from './reactionIcons/sad.svg';
import angryIcon from './reactionIcons/angry.svg';

const reactionIcons = [
  likeIcon,
  loveIcon,
  hahaIcon,
  wowIcon,
  sadIcon,
  angryIcon,
];

const ReactionsIcons: FC = () => {
  return (
    <div className="tw-flex tw-w-[45%] tw-items-center tw-justify-center">
      {reactionIcons.map((type, index) => (
        <div
          key={index}
          className="tw-flex tw-h-5 tw-w-[calc(100%/6)] tw-justify-center"
        >
          <span
            className="tw-block tw-h-4 tw-w-4 tw-bg-contain   tw-bg-center tw-bg-no-repeat tw-text-center"
            style={{
              backgroundImage: `url(${type})`,
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default ReactionsIcons;
