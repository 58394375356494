import React, { FC, useMemo } from 'react';
import Link from 'components/shared/Link';
import { colors } from '@kontentino/ui';

type Props = {
  children: string;
};

const MARKDOWN_LINK_REGEX = /\[(.*?)\]\((.*?)\)/g;

function parseMarkdown(text: string) {
  const parsed = [];

  let lastIndex = 0;
  const regex = new RegExp(MARKDOWN_LINK_REGEX);
  let match = regex.exec(text);

  while (match) {
    if (match.index > lastIndex) {
      parsed.push(
        <span key={`text-${parsed.length}`}>
          {text.slice(lastIndex, match.index)}
        </span>,
      );
    }

    parsed.push(
      <Link
        key={`link-${parsed.length}`}
        to={match[2]}
        target="_blank"
        style={{ color: colors.primary.default, textDecoration: 'underline' }}
      >
        {match[1]}
      </Link>,
    );

    lastIndex = regex.lastIndex;
    match = regex.exec(text);
  }

  if (lastIndex < text.length) {
    parsed.push(
      <span key={`text-${parsed.length}`}>{text.slice(lastIndex)}</span>,
    );
  }

  return parsed;
}

const Markdown: FC<Props> = ({ children }) => {
  const parsedMarkdown = useMemo(() => parseMarkdown(children), [children]);

  return <>{parsedMarkdown}</>;
};

export default Markdown;
