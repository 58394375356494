import dayjs, { Dayjs } from 'dayjs';
import { DateRangeOnChangeParams } from 'types/Date';

export const isDateWithinRange = (
  date: Date,
  startDate: Date | null = null,
  endDate: Date | null = null,
) => {
  if (startDate && endDate) {
    return dayjs(date).isBetween(dayjs(startDate), dayjs(endDate));
  }

  if (startDate && !endDate) {
    return dayjs(date).isAfter(dayjs(startDate));
  }

  if (!startDate && endDate) {
    return dayjs(date).isBefore(dayjs(endDate));
  }

  return true;
};

export function getDatesWithConstraints(
  dates: DateRangeOnChangeParams,
  options: { maxEndDate?: Dayjs | null },
) {
  return Object.fromEntries(
    Object.entries(dates).map(([key, value]) => {
      if (
        value &&
        options?.maxEndDate &&
        value.isAfter(options.maxEndDate, 'day')
      ) {
        value = options.maxEndDate;
      }

      return [key, value];
    }),
  ) as DateRangeOnChangeParams;
}
