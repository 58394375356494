import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import { Icon, IconButton } from '@kontentino/ui';
import { usePostsContainer } from 'components/posts/context';
import dayjs, { Dayjs } from 'dayjs';
import React, { FC, useCallback, useMemo } from 'react';

type Props = {
  maxDate?: Dayjs | null;
  minDate?: Dayjs | null;
};

const PostsLayoutTopBarMonthSwitcher: FC<Props> = ({ maxDate, minDate }) => {
  const postsContainer = usePostsContainer();
  const isNextMonthDisabled = useMemo(() => {
    return dayjs(postsContainer.filterState.startDate)
      .add(1, 'month')
      .isAfter(maxDate);
  }, [maxDate, postsContainer.filterState.startDate]);

  const isPreviousMonthDisabled = useMemo(() => {
    return dayjs(postsContainer.filterState.startDate)
      .subtract(1, 'month')
      .isBefore(minDate);
  }, [minDate, postsContainer.filterState.startDate]);

  const increaseMonth = useCallback(() => {
    const newDate = dayjs(postsContainer.filterState.startDate).add(1, 'month');
    !isNextMonthDisabled &&
      postsContainer.changeFilter(
        {
          key: 'startDate',
          value: newDate.startOf('month').format('YYYY-MM-DD'),
        },
        {
          key: 'endDate',
          value: newDate.endOf('month').format('YYYY-MM-DD'),
        },
      );
  }, [postsContainer, isNextMonthDisabled]);

  const decreaseMonth = useCallback(() => {
    const newDate = dayjs(postsContainer.filterState.startDate).subtract(
      1,
      'month',
    );
    !isPreviousMonthDisabled &&
      postsContainer.changeFilter(
        {
          key: 'startDate',
          value: newDate.startOf('month').format('YYYY-MM-DD'),
        },
        {
          key: 'endDate',
          value: newDate.endOf('month').format('YYYY-MM-DD'),
        },
      );
  }, [postsContainer, isPreviousMonthDisabled]);

  return (
    <div className="tw-flex tw-gap-x-2">
      <IconButton
        onClick={decreaseMonth}
        variant="light"
        icon={<Icon icon={faChevronLeft} />}
        size={'medium'}
        disabled={isPreviousMonthDisabled}
      />
      <IconButton
        onClick={increaseMonth}
        variant="light"
        icon={<Icon icon={faChevronRight} />}
        size={'medium'}
        disabled={isNextMonthDisabled}
      />
    </div>
  );
};

export default PostsLayoutTopBarMonthSwitcher;
