import { FC } from 'react';
import { NavigationItem } from 'components/navigation/utils/libs';
import { useModalHistory } from 'context/ModalRouter';
import { modalRoutes } from 'constants/routes';
import { preventDefault } from 'utils/mouseEvent';
import MenuLink from './MenuLink';

type Props = {
  menuItems: NavigationItem[];
};

const PublicMenuItems: FC<Props> = ({ menuItems }) => {
  const history = useModalHistory();

  return (
    <div
      className="tw-flex tw-flex-col tw-gap-y-4"
      onClick={preventDefault(() =>
        history.push({
          hash: modalRoutes.PUBLIC_INVITE_USER,
          state: { actionType: 'navbar' },
        }),
      )}
    >
      {menuItems.map((item) => (
        <MenuLink key={item.key} item={item} />
      ))}
    </div>
  );
};

export default PublicMenuItems;
