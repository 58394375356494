import React, { forwardRef, ReactNode } from 'react';
import { mergeTailwindClasses, Tooltip } from '@kontentino/ui';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

type Props = {
  children?: ReactNode;
  disabled?: string | boolean;
  isFocused?: boolean;
  filesDropzoneShown?: boolean;
  classNames?: {
    root?: string;
    rootFocused?: string;
    dropOverlay?: string;
  };
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'className'>;

const EditorRoot = forwardRef<HTMLDivElement, Props>(
  (
    { children, classNames, disabled, isFocused, filesDropzoneShown, ...rest },
    ref,
  ) => {
    const { t } = useTranslation();

    return (
      <Tooltip content={typeof disabled === 'string' ? disabled : undefined}>
        <div
          ref={ref}
          className={mergeTailwindClasses(
            clsx(
              'tw-relative tw-rounded tw-ring-1 tw-ring-grayscale-30',
              {
                'tw-cursor-not-allowed tw-bg-grayscale-20': !!disabled,
              },
              classNames?.root,
              {
                [classNames?.rootFocused ?? 'tw-ring-primary-100']: isFocused,
              },
            ),
          )}
          {...rest}
        >
          {children}
          {filesDropzoneShown && (
            <div
              className={mergeTailwindClasses(
                clsx(
                  'tw-absolute tw-left-0 tw-top-0 tw-z-10 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded tw-bg-primary-10',
                  classNames?.dropOverlay,
                ),
              )}
            >
              <span className="tw-text-md tw-font-medium tw-text-primary-100">
                {t('dropFilesToUpload')}
              </span>
            </div>
          )}
        </div>
      </Tooltip>
    );
  },
);

export default EditorRoot;
