import React, { FC, useEffect, useState } from 'react';
import VerticalNavigation from 'components/navigation/VerticalNavigation';
import { PublicUtils } from 'components/publicPreviews/publicUtils';
import { PostPreviewFooter } from 'components/publicPreviews/post/PostPreviewFooter';
import PublicPostPreview from 'components/publicPreviews/post/PublicPostPreview';
import { getQueryParameter, setQueryParameter } from 'utils/url';
import { modalRoutes } from 'constants/routes';
import PublicInviteUser from 'pages/PublicInviteUser';
import ModalRoute from 'context/ModalRouter';
import { useLocation } from 'react-router-dom';

export const PublicPostOverview: FC = () => {
  const location = useLocation();
  const [token] = useState(() => getQueryParameter('token'));

  useEffect(() => {
    // keep token always present in url in case user refresh the page
    if (token) {
      setQueryParameter('token', token);
    }
  }, [location, token]);

  return (
    <div className="tw-flex tw-flex-1">
      <VerticalNavigation
        isPublic
        menuItems={PublicUtils.publicMock.menuItems}
        user={PublicUtils.publicMock.user}
        logoLinkProps={{
          'data-name': 'public-link-logo',
          isRouterLink: false,
          to: 'https://www.kontentino.com/',
        }}
      />
      <div className="tw-ml-[64px] tw-flex tw-h-screen tw-flex-1 tw-flex-col tw-overflow-hidden">
        <PublicPostPreview token={token} />
        <PostPreviewFooter />
      </div>
      <ModalRoute
        path={modalRoutes.PUBLIC_INVITE_USER}
        component={(props) => <PublicInviteUser token={token} {...props} />}
      />
    </div>
  );
};
