import { DropzoneOptions, useDropzone } from 'react-dropzone';

export type UseFileDropzoneOptions = Omit<
  DropzoneOptions,
  'useFsAccessApi' | 'maxFiles'
> &
  Required<Pick<DropzoneOptions, 'maxFiles' | 'onDrop'>>;

export function useFileDropzone(options: UseFileDropzoneOptions) {
  return useDropzone({
    ...options,
    useFsAccessApi: false,
  });
}
