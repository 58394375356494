/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import isEqual from 'lodash.isequal';
import { getQueryParameter, getQueryParameters } from 'utils/url';
import { useEffectOnce } from 'utils/hooks/useEffectOnce';
import { useLocation, useNavigate } from 'react-router-dom';

type ModalManagerContextState = {
  getActive(path: string): ModalManager | undefined;
  history: ModalHistory;
};

export type ModalRouteProps = {
  modalHistory: ModalHistory;
  modalManager: ModalManager;
};

export type ModalHistory = {
  push(p: Params): void;
  replace(p: Params): void;
  close(key: string): void;
  closeLast(): void;
  closeAll(): void;
  goBack(): void;
  readonly activeModals: ActiveModals;
  isActive(key: string): boolean;
};

export type ModalManager = {
  open: boolean;
  destroy: () => void;
  close: () => void;
  closeAll: () => void;
  search: string | undefined;
  getQueryParameter(name: string, defaultValue?: string): string;
  getQueryParameters(
    names: string[],
    options?: { parse: boolean },
  ): Record<string, string | null>;
  state: any;
  key: string;
};

const ModalRouterContext = createContext<ModalManagerContextState | undefined>(
  undefined,
);

type Props = {
  children: ReactNode | ReactNode[];
};

export type ActiveModal = {
  key: string;
  open: boolean;
  search: string;
  state: any;
};

export type ActiveModals = ActiveModal[];

type Params = {
  hash?: string;
  search?: string;
  state?: object;
};

export const ModalRouter: FC<Props> = ({ children }) => {
  const [activeModals, setActiveModals] = useState<ActiveModals>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const searchBeforeModalOpened = useRef('');

  useEffect(() => {
    if (!location.hash || location.hash === '#') {
      searchBeforeModalOpened.current = location.search;
    }
  }, [location.hash, location.search]);

  function getCurrentModalsState() {
    const currentModals = parseHash(location.hash);

    return {
      currentModals,
      changed: !isEqual(
        currentModals,
        activeModals.map((p) => p.key),
      ),
    };
  }

  useEffect(manageModals, [location.hash]);

  function manageModals() {
    const { changed, currentModals } = getCurrentModalsState();

    if (changed) {
      setActiveModals((prevActiveModals) => {
        let activeModals = [...prevActiveModals];

        (function closeOld() {
          activeModals = activeModals.map((m) =>
            currentModals.includes(m.key) ? m : { ...m, open: false },
          );
        })();

        (function openNew() {
          const activeModalsKeys = activeModals.map((m) => m.key);
          const modalKeysToOpen = currentModals.filter(
            (key) => !activeModalsKeys.includes(key),
          );

          const modalsToOpen = modalKeysToOpen.map((key) => ({
            key,
            open: true,
            search: location.search,
            state: location.state,
          }));

          activeModals.push(...modalsToOpen);
        })();

        return activeModals;
      });
    }
  }

  useEffect(updateSearchOfLastModal, [location.search]);

  function updateSearchOfLastModal() {
    const { changed, currentModals } = getCurrentModalsState();

    if (currentModals.length && !changed) {
      const lastActive = currentModals[currentModals.length - 1];

      setActiveModals((prev) =>
        prev.map((m) => ({
          ...m,
          search: m.key === lastActive ? location.search : m.search,
        })),
      );
    }
  }

  function push(params: Params) {
    const config = {
      hash: params.hash ? params.hash : location.hash,
      search: params.search,
      state: params.state,
    };

    if (
      params.hash &&
      parseHash(location.hash).length > 0 &&
      params.hash.replace('#', '') !== location.hash.replace('#', '')
    ) {
      config.hash = `${location.hash}/${params.hash}`;
    }

    navigate(config);
  }

  function goBack() {
    navigate(-1);
  }

  function close(key: string) {
    let hash = location.hash;

    hash = hash.replace(`/${key}`, '');
    hash = hash.replace(key, '');

    const index = activeModals.findIndex((m) => m.key === key);

    let prevSearch = searchBeforeModalOpened.current;

    if (index >= 1) {
      prevSearch = activeModals[index - 1].search;
    }

    navigate({ hash, search: prevSearch }, { replace: true });
  }

  function closeLast() {
    const parsed = parseHash(location.hash);

    if (parsed.length > 0) {
      close(parsed[parsed.length - 1]);
    }
  }

  function closeAll() {
    navigate({ hash: '' }, { replace: true });
  }

  function replace(params: Params) {
    if (params.hash) {
      navigate(
        {
          hash: params.hash,
          search: params.search,
        },
        { replace: true, state: params.state },
      );
      return;
    }

    const parsed = parseHash(location.hash);

    if (parsed.length) {
      navigate(
        {
          hash: parsed[parsed.length - 1],
          search: params.search,
        },
        { replace: true, state: params.state },
      );
    }
  }

  function isActive(key: string) {
    return !!activeModals.find((modal) => modal.key === key);
  }

  function getModalManager(modal: ActiveModal) {
    function destroy() {
      setActiveModals((prev) => prev.filter((m) => m.key !== modal.key));
    }

    return {
      ...modal,
      destroy: destroy,
      close: () => close(modal.key),
      closeAll,
      getQueryParameter: (name: string, defaultValue?: string) =>
        getQueryParameter(name, defaultValue, modal.search),
      getQueryParameters: (name: string[], options?: { parse: boolean }) =>
        getQueryParameters(name, modal.search, options),
    };
  }

  const modalManagers = useMemo(() => {
    const modalManagers: Record<string, ModalManager> = {};

    activeModals.forEach((modal, index) => {
      modalManagers[modal.key] = getModalManager(modal);
    });

    return modalManagers;
  }, [activeModals]);

  function getActive(path: string): ModalManager | undefined {
    return modalManagers[path];
  }

  const modalHistory = useMemo(
    () => ({
      push,
      replace,
      close,
      closeLast,
      closeAll,
      goBack,
      activeModals,
      isActive,
    }),
    [activeModals, navigate],
  );

  return (
    <ModalRouterContext.Provider
      value={{
        getActive,
        history: modalHistory,
      }}
    >
      {children}
    </ModalRouterContext.Provider>
  );
};

function useModalRouter() {
  const context = React.useContext(ModalRouterContext);

  if (!context) {
    throw new Error('Must be used within ModalRouter Provider ');
  }

  return context;
}

export function useModalHistory(): ModalHistory {
  return useModalRouter().history;
}

export const useActiveModalRoute = (path: string): ModalRouteProps | null => {
  const modalRouter = useModalRouter();
  const modalManager = modalRouter.getActive(path);

  if (!modalManager) {
    return null;
  }

  return {
    modalHistory: modalRouter.history,
    modalManager,
  };
};

function parseHash(value: string) {
  return value
    .replace('#', '')
    .split('/')
    .filter((s) => !!s);
}

const ModalRoute: FC<{
  component: React.ComponentType<ModalRouteProps>;
  path: string;
}> = ({ component: Component, path }) => {
  const modalRoute = useActiveModalRoute(path);

  if (modalRoute) {
    return (
      <ActiveModalRoute {...modalRoute}>
        <Component {...modalRoute} />
      </ActiveModalRoute>
    );
  }

  return null;
};

const ActiveModalRoute: FC<{
  modalManager: ModalRouteProps['modalManager'];
  children: ReactNode;
}> = ({ children, modalManager }) => {
  useEffectOnce(() => {
    return () => {
      modalManager.destroy();
    };
  });

  return <>{children}</>;
};

export default ModalRoute;
