import { PostDetail } from 'types/PostDetail';

export const getPostAttachmentCount = (
  cards: PostDetail['attachments']['cards'],
) => {
  const counts = {
    videos: 0,
    images: 0,
  };

  const mediums = cards.map((card) => card.medium);

  const videos = mediums.filter((attachment) => attachment?.type === 'video');
  const images = mediums.filter((attachment) => attachment?.type === 'image');

  counts.images = images.length;
  counts.videos = videos.length;

  return counts;
};
