import { GraphType } from 'constants/analytics';
import { GraphStructure } from 'types/insights/Graphs';
import { BrowserStorage } from 'utils/browserStorage';
import { getQueryParameter } from 'utils/url';

const getGraphTypeFromURLQuery = (metric: string) => {
  const type = Number(getQueryParameter(metric));

  if (type && Object.values(GraphType).includes(type)) {
    return type;
  }
};

const getGraphTypeFromLocalStorage = (metric: string) => {
  const type = BrowserStorage.get(
    BrowserStorage.keys.Insights.InsightsGraphsConfig,
  );

  if (type) {
    const parsedTypes = JSON.parse(type);
    if (parsedTypes && parsedTypes[metric]) {
      return parsedTypes[metric];
    }
  }
};

export const getPreselectedGraphType = (graph: GraphStructure) => {
  return (
    getGraphTypeFromURLQuery(graph.metricParameter) ??
    getGraphTypeFromLocalStorage(graph.metricParameter)
  );
};
