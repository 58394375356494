import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isViewRestrictedForPageType } from '../utils/isViewRestrictedForPageType';

export function useRedirectRestrictedViewToDefault(
  pageType: number | undefined,
  defaultRoute: string,
) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pageType && isViewRestrictedForPageType(location.pathname, pageType)) {
      navigate(defaultRoute, { replace: true });
    }
  }, [defaultRoute, location, navigate, pageType]);
}
