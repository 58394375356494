import React, { FC } from 'react';
import GenericGraph from '../GenericGraph';
import {
  AggregatedMetric,
  GraphData,
  MetricGraphData,
  MetricGraphLegendLabels,
  MetricTableData,
} from 'app/modules/insights/types/PagePerformance';
import MetricTable from '../MetricTable';
import AggregatedMetricValue from '../aggregatedMetricValues/components/AggregatedMetricValue';
import { FilterState } from 'app/modules/insights/hooks/useFilterState';

type Props = {
  generalData: MarkOptional<AggregatedMetric, 'metricKey'>;
  graphData: MetricGraphData;
  isCompared?: boolean;
  legendLabels?: MetricGraphLegendLabels;
  isCombinedMetric: boolean;
  metricTableData?: MetricTableData;
  title: string;
  combinedMetricComparedData: GraphData[];
  filterState: Pick<
    FilterState,
    'startDate' | 'endDate' | 'comparedEndDate' | 'comparedStartDate'
  >;
  dataName?: {
    summaryMetrics?: string;
    metricTable?: string;
  };
};

const PageMetricCardCollapsedContent: FC<Props> = (props) => {
  const {
    generalData,
    graphData,
    isCompared,
    legendLabels,
    isCombinedMetric,
    metricTableData,
    dataName,
    title,
    combinedMetricComparedData,
    filterState,
  } = props;

  return (
    <div className="tw-flex tw-flex-col">
      <div className="tw-mb-6">
        <AggregatedMetricValue
          value={generalData.value}
          difference={generalData.difference}
          isCompared={isCompared}
        />
      </div>
      <div>
        {metricTableData && metricTableData['default'] ? (
          <MetricTable
            metrics={metricTableData['default']}
            isCompared={isCompared}
            dataName={{
              table: dataName?.metricTable,
            }}
          />
        ) : (
          <GenericGraph
            filterState={filterState}
            title={title}
            variant={graphData.graphValues.variant}
            data={graphData.graphValues.data}
            isCompared={isCompared}
            legendLabels={legendLabels}
            isCombinedMetric={isCombinedMetric}
            combinedMetricComparedData={combinedMetricComparedData}
          />
        )}
      </div>
    </div>
  );
};

export default PageMetricCardCollapsedContent;
