import { STATUS_WAITING_FOR } from '@kontentino/kontentino-constants/Posts';
import {
  AvatarGroup,
  Badge,
  BadgeProps,
  mergeTailwindClasses,
} from '@kontentino/ui';
import React, { FC } from 'react';
import WorkflowStatusIcon from './WorkflowStatusIcon';
import { WorkflowStatusUtils } from 'app/utils/workflowStatus';
import clsx from 'clsx';
import { usePostsLayoutState } from 'app/modules/posts/hooks/usePostsLayoutState';

type Props = {
  status: number;
  assignedUsers?: {
    id: number;
    name: string;
    avatar?: {
      src: string | undefined;
    };
  }[];
  variant?: 'plain' | 'subtle';
  size?: BadgeProps['size'];
  className?: string;
};

const WorkflowStatusBadge: FC<Props> = ({
  status,
  assignedUsers,
  variant = 'subtle',
  size = 'large',
  className,
}) => {
  const { postStatusVariant } = usePostsLayoutState();

  return (
    <Badge
      data-cy={`post-header-status-${status}`}
      size={size}
      className={mergeTailwindClasses(
        clsx(`tw-gap-2 tw-whitespace-nowrap tw-text-grayscale-180`, {
          [`tw-bg-status-${postStatusVariant}-${status}-secondary`]:
            variant === 'subtle',
          [`tw-bg-transparent tw-p-0 tw-text-status-${postStatusVariant}-${status}-primary`]:
            variant === 'plain',
        }),
        className,
      )}
    >
      <WorkflowStatusIcon status={status} />
      {`${WorkflowStatusUtils.getLabel(status)} ${
        status === STATUS_WAITING_FOR && !!assignedUsers?.length ? ' to ' : ''
      } `}
      {!!assignedUsers?.length && (
        <AvatarGroup
          avatars={assignedUsers.map((user) => ({
            ...user,
            src: user.avatar?.src,
          }))}
          limit={2}
        />
      )}
    </Badge>
  );
};

export default WorkflowStatusBadge;
