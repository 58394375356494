import React, { FC } from 'react';
import StringUtils from 'utils/string';
import { mergeTailwindClasses } from '@kontentino/ui';

type Props = {
  text: string;
  className?: string;
};

const TextContent: FC<Props> = ({ text, className }) => {
  return (
    <div
      className={mergeTailwindClasses(
        'tw-line-clamp-3 tw-overflow-hidden tw-text-sm tw-text-grayscale-180',
        className,
      )}
    >
      {StringUtils.truncate(text)}
    </div>
  );
};

export default TextContent;
