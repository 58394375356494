import { useMutation, useQuery } from 'react-query';
import AnalyticsApi from 'api/analytics';
import { JobBatchStatus } from 'app/modules/insights/types/Job';
import { create } from 'zustand';
import { useToast } from 'app/hooks/useToast';
import i18n from 'i18n';
import AnalyticsJobsApi from 'api/analytics/jobs';
import { useEffect, useMemo } from 'react';
import Logger from 'utils/logger';
import { ApiClientError } from 'api/client';
import { QueryKey } from 'components/insights/constants';
import dayjs from 'dayjs';
import Markdown from 'app/components/Markdown';
import semanticJoin from 'array-semantic-join';
import InsightsConfig from 'app/config/insights';

function joinErrorMessages(jobBatchStatus: JobBatchStatus) {
  return semanticJoin(
    [
      jobBatchStatus.message,
      ...(jobBatchStatus.errors ? jobBatchStatus.errors : []),
    ]
      .filter((msg): msg is string => typeof msg === 'string')
      .slice(0, InsightsConfig.SYNC_ERROR_MESSAGES_TO_SHOW_LIMIT),
  );
}

const useSyncInsightsState = create<{
  jobStatusProgress: JobBatchStatus | null;
  setJobStatusProgress: (value: JobBatchStatus | null) => void;
  abortController: AbortController;
  resetProgress(): void;
}>((set, get) => ({
  jobStatusProgress: null,
  setJobStatusProgress: (jobStatusProgress) => {
    set({ jobStatusProgress });
  },
  abortController: new AbortController(),
  resetProgress: () => {
    get().abortController.abort();
    set({
      abortController: new AbortController(),
      jobStatusProgress: null,
    });
  },
}));

type Props = {
  pageType: number;
  id: string;
};

const useSyncProfileInsights = ({ pageType, id }: Props) => {
  const toast = useToast();
  const {
    jobStatusProgress,
    setJobStatusProgress,
    abortController,
    resetProgress,
  } = useSyncInsightsState();

  const variables = { pageType, id };

  const syncManualTrigger = useMutation(
    async () => {
      const jobOptions = {
        abortSignal: abortController.signal,
        onProgress: setJobStatusProgress,
      };

      const currentPageSync = await AnalyticsApi.pageSyncStatus(variables);

      if (
        currentPageSync?.batch?.id &&
        ['running', 'queued'].includes(currentPageSync.status)
      ) {
        return await AnalyticsJobsApi.waitUntilJobBatchCompletion(
          currentPageSync.batch.id,
          jobOptions,
        );
      }

      const newPageSync = await AnalyticsApi.runPageSync(variables);

      return await AnalyticsJobsApi.waitUntilJobBatchCompletion(
        newPageSync.batch.id,
        jobOptions,
      );
    },
    {
      onError(e: ApiClientError) {
        toast(
          <Markdown>
            {e?.userMessage ?? i18n.somethingWentWrongTryAgain}
          </Markdown>,
          'error',
        );
      },
      onSuccess({ jobBatchStatus, completed }) {
        if (jobBatchStatus?.status === 'failed') {
          const messages = joinErrorMessages(jobBatchStatus);

          toast(
            <Markdown>{messages ?? i18n.somethingWentWrongTryAgain}</Markdown>,
            'error',
          );
          Logger.error(jobBatchStatus);
          return;
        }

        if (completed) {
          toast('Your analytics data successfully fetched', 'success');
        }
      },
      onSettled() {
        resetProgress();
      },
    },
  );

  const syncAutoTrigger = useQuery(
    QueryKey.pageSyncStatus(variables.id),
    async () => {
      const currentSyncStatus = await AnalyticsApi.pageSyncStatus(variables);

      if (currentSyncStatus === null) {
        await syncManualTrigger.mutateAsync();
      }

      if (
        currentSyncStatus &&
        ['running', 'queued'].includes(currentSyncStatus.status)
      ) {
        syncManualTrigger.mutate();
      }

      return currentSyncStatus;
    },
    { cacheTime: 0 },
  );

  function cleanup() {
    resetProgress();
    syncManualTrigger.reset();
    syncAutoTrigger.remove();
  }

  const lastFinishedAt = useMemo(() => {
    const batch =
      syncAutoTrigger.data?.batch ||
      syncManualTrigger.data?.jobBatchStatus?.batch;

    return batch?.finishedAt ? dayjs(batch.finishedAt) : undefined;
  }, [syncAutoTrigger.data, syncManualTrigger.data]);

  useEffect(() => {
    return () => {
      cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    syncAutoTrigger,
    syncManualTrigger,
    jobStatusProgress,
    lastFinishedAt,
    cleanup,
  };
};

export default useSyncProfileInsights;
