import React, { FC } from 'react';
import clsx from 'clsx';
import WorkflowStatusIcon from 'app/components/workflowStatus/WorkflowStatusIcon';
import { STATUS } from '@kontentino/kontentino-constants/Posts';
import { WorkflowStatusUtils } from 'app/utils/workflowStatus';

import { CommentConvertor } from 'app/modules/comments/utils/commentConvertor';
import { Comment } from 'app/modules/comments/types';
import { usePostsLayoutState } from 'app/modules/posts/hooks/usePostsLayoutState';

type Props = {
  comment: Comment;
};

const CommentsListItemFooter: FC<Props> = ({
  comment: { type, meta, user },
}) => {
  const { postStatusVariant } = usePostsLayoutState();
  const postStatus = CommentConvertor.commentTypeToStatus(type);

  const assignedUsersLabel = () => {
    const assignedUsers = meta?.assignedUsers ?? [];

    if (postStatus === STATUS.WAITING_FOR && !!assignedUsers.length) {
      const firstName = assignedUsers[0]?.name ?? '';
      const additionalMessage =
        assignedUsers.length > 1 ? ` and ${assignedUsers.length - 1} more` : '';

      return `to ${firstName}${additionalMessage}`;
    }

    return '';
  };

  return (
    <>
      {postStatus && (
        <div
          className={clsx('tw-mt-1 tw-flex tw-items-center', {
            'tw-mr-10 tw-justify-end': user.its_me,
            'tw-ml-10': !user.its_me,
          })}
        >
          <WorkflowStatusIcon
            status={postStatus}
            variant="regular"
            className="tw-text-sm"
          />
          <span
            className={`tw-text-status-${postStatusVariant}-${postStatus}-primary tw-ml-1 tw-text-sm tw-font-semibold`}
            data-cy="post-comment_status-label"
          >{`${WorkflowStatusUtils.getLabel(
            postStatus,
          )} ${assignedUsersLabel()}`}</span>
        </div>
      )}
    </>
  );
};

export default CommentsListItemFooter;
