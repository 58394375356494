import { UserPermission } from 'constants/userPermission';
import { ModalHistory } from 'context/ModalRouter';
import { postActions } from './definitions/post';
import IntercomUtils from 'app/utils/intercom';
import DataLayer from 'app/utils/dataLayer';
import { commentActions } from './definitions/comment';
import { AppStore } from 'modules/app/appReducer';
import { profileActions } from './definitions/profile';
import { UserStore } from 'modules/user/userReducer';
import { AccountStore } from 'modules/account/accountReducer';
import HotjarUtils from 'app/utils/hotjar';

export type Action = {
  id: string;
  label: string;
  scopes?: UserPermission[] | UserPermission;
  icon?: React.ReactElement;
  isApplicable?: (...actionParams: any) => boolean;
  perform: (...actionParams: any) => Promise<unknown> | unknown;
};

export type ActionProps<TAction extends Action> = {
  action: TAction;
} & (Parameters<TAction['perform']>[0] extends undefined
  ? { actionParams?: undefined }
  : { actionParams: Parameters<TAction['perform']>[0] });

export type ActionContext = {
  modalHistory: ModalHistory;
  appStore: AppStore;
  source:
    | 'post-detail'
    | 'post-card'
    | 'profile-selector'
    | 'add-new-dropdown'
    | 'post-revision';
  user: UserStore;
  account: AccountStore;
};

export const appActions = (context: ActionContext) => ({
  postActions: postActions(context),
  commentActions: commentActions(context),
  profileActions: profileActions(context),
});

export function createAction<TAction extends Action>(
  definition: TAction,
  context: ActionContext,
) {
  return {
    ...definition,
    perform: (...args: Parameters<TAction['perform']>) => {
      const eventKey = `${context.source
        .toUpperCase()
        .replace('-', '_')}_${definition.id.toUpperCase().replace('-', '_')}`;

      // Backward compatibility for events send to Intercom
      const intercomEvent =
        IntercomUtils.events[eventKey as keyof typeof IntercomUtils.events];
      if (!!intercomEvent) {
        IntercomUtils.track(intercomEvent);
      }

      // Backward compatibility for events send to Hotjar
      const hojarEvent =
        HOJAR_EVENTS_MAP[eventKey as keyof typeof HOJAR_EVENTS_MAP];
      if (!!hojarEvent) {
        HotjarUtils.trackEvent(eventKey);
      }

      const dataLayerEvent =
        DataLayer.events[eventKey as keyof typeof DataLayer.events];
      if (!!dataLayerEvent) {
        DataLayer.push({
          event: dataLayerEvent,
        });
      }

      return definition.perform(...args);
    },
  } satisfies TAction;
}

const HOJAR_EVENTS_MAP = {
  profile_selector_add_profile:
    HotjarUtils.eventKey.profileSelectorAddProfileClick,
  add_new_dropdown_add_profile:
    HotjarUtils.eventKey.addProfileClickInAddNewDropdown,
};
