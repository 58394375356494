import React, { FC } from 'react';
import { Icon } from '@kontentino/ui';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

type Props = {
  icon?: IconDefinition;
  label: string;
  value: string | number | null;
};

const ValueBox: FC<Props> = ({ icon, label, value }) => (
  <>
    <div className="tw-mb-3 tw-flex tw-items-center  tw-font-medium tw-text-grayscale-100">
      {icon && <Icon icon={icon} marginRight={8} />}
      {label}
    </div>
    <div className="tw-text-xl tw-font-bold tw-text-grayscale-120">{value}</div>
  </>
);

export default ValueBox;
