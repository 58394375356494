import { GraphType } from 'constants/analytics';
import { GraphStructure } from 'types/insights/Graphs';

const UNIVERSAL_GRAPH_TYPES = [
  GraphType.AreaChart,
  GraphType.LineChart,
  GraphType.BarChart,
];

export enum GraphKeysForComparing {
  default = 'default period',
  compared = 'compared period',
}

export const AGGREGATED_METRICS = [
  'page_fans_locale',
  'page_fans_city',
  'page_fans_country',
  'page_fans_gender_age',
  'page_fans_online',
  'page_impressions_by_city_unique',
  'page_impressions_by_age_gender_unique',
  'page_impressions_by_country_unique',
  'page_impressions_by_locale_unique',
  'page_views_external_referrals',
  'page_positive_feedback_by_type',
  'audience_country',
  'audience_gender_age',
  'audience_locale',
  'audience_city',
  'page_fans_by_like_source',
  'page_fans_by_unlike_source_unique',
  'page_impressions_by_story_type',
];

export const RESTRICTED_METRICS = [
  'page_fans_locale',
  'page_fans_city',
  'page_fans_country',
  'page_fans_gender_age',
  'page_fans_online',
  'page_impressions_by_age_gender_unique',
  'page_impressions_by_city_unique',
  'page_impressions_by_country_unique',
  'page_impressions_by_locale_unique',
  'page_fans_by_like_source',
  'page_views_external_referrals',
];

export const METRICS_WITH_LOAD_MORE_AVAILABLE = [
  'page_fans_locale',
  'page_fans_city',
  'page_fans_country',
  'page_fans_gender_age',
  'page_impressions_by_age_gender_unique',
  'page_impressions_by_city_unique',
  'page_impressions_by_country_unique',
  'page_impressions_by_locale_unique',
];

export const METRIC_KEY_OF_DAY_CHART = 'page_fans_online';
export const EXTERNAL_DOMAINS_REFFERAL_METRIC_KEY =
  'page_views_external_referrals';

export const FACEBOOK_GRAPHS: GraphStructure[] = [
  {
    title: 'Page likes by locale',
    metricParameter: 'page_fans_locale',
    primaryDataKey: 'likes',
    availableGraphTypes: [GraphType.BarChart],
    selectedGraphType: GraphType.BarChart,
  },
  {
    title: 'Page likes by city',
    metricParameter: 'page_fans_city',
    primaryDataKey: 'likes',
    availableGraphTypes: [GraphType.BarChart],
    selectedGraphType: GraphType.BarChart,
  },
  {
    title: 'Page likes by country',
    metricParameter: 'page_fans_country',
    primaryDataKey: 'likes',
    availableGraphTypes: [GraphType.BarChart],
    selectedGraphType: GraphType.BarChart,
  },
  {
    title: 'Page likes by Gender and Age',
    metricParameter: 'page_fans_gender_age',
    primaryDataKey: 'fans',
    availableGraphTypes: [GraphType.BarChart],
    selectedGraphType: GraphType.BarChart,
  },
  {
    title: 'Page fans online',
    metricParameter: 'page_fans_online',
    primaryDataKey: 'fans online',
    availableGraphTypes: [GraphType.BarChart],
    selectedGraphType: GraphType.BarChart,
  },
  {
    title: 'Page reach by age and gender',
    metricParameter: 'page_impressions_by_age_gender_unique',
    primaryDataKey: 'impressions',
    availableGraphTypes: [GraphType.BarChart],
    selectedGraphType: GraphType.BarChart,
  },
  {
    title: 'Page reach by city',
    metricParameter: 'page_impressions_by_city_unique',
    primaryDataKey: 'impressions',
    availableGraphTypes: [GraphType.BarChart],
    selectedGraphType: GraphType.BarChart,
  },
  {
    title: 'Page reach by country',
    metricParameter: 'page_impressions_by_country_unique',
    primaryDataKey: 'impressions',
    availableGraphTypes: [GraphType.BarChart],
    selectedGraphType: GraphType.BarChart,
  },
  {
    title: 'Page reach by locale',
    metricParameter: 'page_impressions_by_locale_unique',
    primaryDataKey: 'impressions',
    availableGraphTypes: [GraphType.BarChart],
    selectedGraphType: GraphType.BarChart,
  },
  {
    title: 'Page fans by paid/non paid',
    metricParameter: 'page_fan_adds_by_paid_non_paid_unique',
    primaryDataKey: 'users',
    availableGraphTypes: UNIVERSAL_GRAPH_TYPES,
    selectedGraphType: GraphType.AreaChart,
  },
  {
    title: 'Page post cumulative reach',
    metricParameter: 'page_posts_impressions_unique',
    primaryDataKey: 'impressions',
    availableGraphTypes: UNIVERSAL_GRAPH_TYPES,
    selectedGraphType: GraphType.AreaChart,
  },
  {
    title: 'Engagement rate',
    metricParameter: 'page_engagement_rate',
    primaryDataKey: 'rate in %',
    availableGraphTypes: UNIVERSAL_GRAPH_TYPES,
    selectedGraphType: GraphType.AreaChart,
  },
  {
    title: 'Page likes by source',
    metricParameter: 'page_fans_by_like_source',
    primaryDataKey: 'likes',
    availableGraphTypes: [GraphType.BarChart],
    selectedGraphType: GraphType.BarChart,
  },
  {
    title: 'Page unlikes by source',
    metricParameter: 'page_fans_by_unlike_source_unique',
    primaryDataKey: 'unlikes',
    availableGraphTypes: [GraphType.BarChart],
    selectedGraphType: GraphType.BarChart,
  },
  {
    title: 'Page impressions by type',
    metricParameter: 'page_impressions_by_story_type',
    primaryDataKey: 'impressions',
    availableGraphTypes: [GraphType.BarChart],
    selectedGraphType: GraphType.BarChart,
  },
  {
    title: 'Top referring external domains - total',
    metricParameter: 'page_views_external_referrals',
    primaryDataKey: 'referrals',
    availableGraphTypes: [GraphType.BarChart],
    selectedGraphType: GraphType.BarChart,
  },
  {
    title: 'Page engaged users',
    metricParameter: 'page_engaged_users',
    primaryDataKey: 'users',
    availableGraphTypes: UNIVERSAL_GRAPH_TYPES,
    selectedGraphType: GraphType.AreaChart,
  },
  {
    title: 'Page impressions unique',
    metricParameter: 'page_impressions_unique',
    primaryDataKey: 'users',
    availableGraphTypes: UNIVERSAL_GRAPH_TYPES,
    selectedGraphType: GraphType.AreaChart,
  },
  {
    title: 'Page fans',
    metricParameter: 'page_fans',
    primaryDataKey: 'fans',
    availableGraphTypes: UNIVERSAL_GRAPH_TYPES,
    selectedGraphType: GraphType.AreaChart,
  },
  {
    title: 'Page unlikes',
    metricParameter: 'page_fan_removes_unique',
    primaryDataKey: 'unlikes',
    availableGraphTypes: UNIVERSAL_GRAPH_TYPES,
    selectedGraphType: GraphType.AreaChart,
  },
  {
    title: 'Page post engagements',
    metricParameter: 'page_post_engagements',
    primaryDataKey: 'users',
    availableGraphTypes: UNIVERSAL_GRAPH_TYPES,
    selectedGraphType: GraphType.AreaChart,
  },
  {
    title: 'Page post impressions paid',
    metricParameter: 'page_posts_impressions_paid_unique',
    primaryDataKey: 'impressions',
    availableGraphTypes: UNIVERSAL_GRAPH_TYPES,
    selectedGraphType: GraphType.AreaChart,
  },
];

export const INSTAGRAM_GRAPHS: GraphStructure[] = [
  {
    title: 'Impressions',
    metricParameter: 'impressions',
    primaryDataKey: 'impressions',
    availableGraphTypes: UNIVERSAL_GRAPH_TYPES,
    selectedGraphType: GraphType.AreaChart,
  },
  {
    title: 'Email contacts',
    metricParameter: 'email_contacts',
    primaryDataKey: 'contacts',
    availableGraphTypes: UNIVERSAL_GRAPH_TYPES,
    selectedGraphType: GraphType.AreaChart,
  },
  {
    title: 'Phone call clicks',
    metricParameter: 'phone_call_clicks',
    primaryDataKey: 'clicks',
    availableGraphTypes: UNIVERSAL_GRAPH_TYPES,
    selectedGraphType: GraphType.AreaChart,
  },
  {
    title: 'Get directions clicks',
    metricParameter: 'get_directions_clicks',
    primaryDataKey: 'clicks',
    availableGraphTypes: UNIVERSAL_GRAPH_TYPES,
    selectedGraphType: GraphType.AreaChart,
  },
  {
    title: 'Audience country',
    metricParameter: 'audience_country',
    primaryDataKey: 'fans',
    availableGraphTypes: [GraphType.BarChart],
    selectedGraphType: GraphType.BarChart,
  },
  {
    title: 'Audience gender age',
    metricParameter: 'audience_gender_age',
    primaryDataKey: 'fans',
    availableGraphTypes: [GraphType.BarChart],
    selectedGraphType: GraphType.BarChart,
  },
  {
    title: 'Audience locale',
    metricParameter: 'audience_locale',
    primaryDataKey: 'fans',
    availableGraphTypes: [GraphType.BarChart],
    selectedGraphType: GraphType.BarChart,
  },
  {
    title: 'Audience city',
    metricParameter: 'audience_city',
    primaryDataKey: 'fans',
    availableGraphTypes: [GraphType.BarChart],
    selectedGraphType: GraphType.BarChart,
  },
];
