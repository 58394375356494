import React, { FC } from 'react';
import {
  AreaChart,
  BarChart,
  CartesianGrid,
  Legend,
  LineChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import GraphLegend from './graph/GraphLegend';
import GraphAxis from './graph/GraphAxis';
import GraphContent from './graph/GraphContent';
import GraphGradient from './graph/GraphGradient';
import {
  GraphData,
  GraphVariant,
  MetricGraphLegendLabels,
} from 'app/modules/insights/types/PagePerformance';
import { colors } from '@kontentino/ui';
import { GraphTooltip } from './graph/GraphTooltip';
import { FilterState } from 'app/modules/insights/hooks/useFilterState';
import { getGraphTooltipLabels } from 'app/modules/insights/modules/pagePerformance/utils/getGraphLabels';

type Props = {
  title: string;
  filterState: Pick<
    FilterState,
    'startDate' | 'endDate' | 'comparedEndDate' | 'comparedStartDate'
  >;
  variant: GraphVariant;
  data: GraphData[];
  isCompared?: boolean;
  expanded?: boolean;
  legendLabels?: MetricGraphLegendLabels;
  showTooltip?: boolean;
  isCombinedMetric?: boolean;
  combinedMetricComparedData?: GraphData[];
};

const getChartByVariant = (variant: string) => {
  switch (variant) {
    case 'line':
      return LineChart;
    case 'area':
      return AreaChart;
    case 'bar':
      return BarChart;
    default:
      return null;
  }
};

const GenericGraph: FC<Props> = ({
  filterState,
  data,
  variant,
  isCompared = false,
  expanded = false,
  legendLabels,
  showTooltip = true,
  isCombinedMetric,
  title,
  combinedMetricComparedData,
}) => {
  const dynamicVariant = isCombinedMetric && isCompared ? 'bar' : variant;
  const dynamicData =
    isCombinedMetric && isCompared ? combinedMetricComparedData : data;

  const ChartComponent = getChartByVariant(dynamicVariant);
  const tooltipLabels = getGraphTooltipLabels({
    filterState,
    legendLabels,
    isCompared,
    isCombinedMetric,
  });

  if (!ChartComponent) return null;
  return (
    <ResponsiveContainer minHeight={208} maxHeight={272} height="100%">
      <ChartComponent
        data={dynamicData}
        barGap={0}
        margin={{
          left: 6,
        }}
      >
        <CartesianGrid
          vertical={false}
          strokeDasharray="2"
          stroke={colors.gray05}
        />
        {showTooltip && (
          <Tooltip
            allowEscapeViewBox={{ y: true }}
            content={
              <GraphTooltip
                title={title}
                isCompared={isCompared}
                metricLabels={tooltipLabels}
                isCombinedMetric={isCombinedMetric}
              />
            }
          />
        )}
        {((isCombinedMetric && !!legendLabels) ||
          (isCompared && !!legendLabels)) && (
          <Legend
            content={
              <GraphLegend
                labels={legendLabels}
                isCombinedMetric={isCombinedMetric}
                isCompared={isCompared}
              />
            }
            verticalAlign="top"
            wrapperStyle={{
              top: 0,
              left: 0,
            }}
          />
        )}
        {dynamicVariant === 'area' && GraphGradient({})}
        {GraphAxis({
          dataKey: 'date',
          showAxis: { xAxis: true, yAxis: true },
          isCompared,
          customTickFormat: isCombinedMetric && isCompared,
        })}
        {GraphContent({
          variant: dynamicVariant,
          dataKey: 'value',
          secondDataKey: isCombinedMetric ? 'secondValue' : undefined,
          isCompared: isCompared,
          comparedDataKey: isCompared ? 'comparedValue' : undefined,
          comparedSecondDataKey:
            isCompared && isCombinedMetric ? 'comparedSecondValue' : undefined,
        })}
      </ChartComponent>
    </ResponsiveContainer>
  );
};

export default GenericGraph;
