import BaseSubscriptionModal from 'app/components/subscriptionModals/BaseSubscriptionModal';
import Benefits from 'app/components/subscriptionModals/Benefits';
import adsPlannerIcon from 'app/images/adsplannerModalGreet.svg';
import { FC } from 'react';
import { SUBSCRIPTION_MODAL_BENEFITS } from './subscriptionModalBenefits';
import SubscriptionExpiredButtons from './SubscriptionExpiredButtons';
import SubscriptionExpiredFooter from './SubscriptionExpiredFooter';

type Props = {
  open: boolean;
};

const AdsPlannerTrialExpired: FC<Props> = ({ open }) => {
  return (
    <BaseSubscriptionModal
      open={open}
      title={
        <div className="tw-text-xl tw-font-medium">
          Your <strong>free trial</strong> of <strong>Ads Planner</strong> has
          just ended
        </div>
      }
      image={<img src={adsPlannerIcon} alt="Ads planner icon" />}
      buttons={
        <SubscriptionExpiredButtons primaryText={'Purchase Ads Planner'} />
      }
      benefits={
        <Benefits
          title={
            'Subscribe to one of our plans, so you don’t lose the benefit of:'
          }
          lists={SUBSCRIPTION_MODAL_BENEFITS.adsPlanner}
        />
      }
      footer={
        <SubscriptionExpiredFooter
          text={
            'No need for Ads Planner anymore? Sorry to hear that, you can leave us feedback here'
          }
        />
      }
    />
  );
};

export default AdsPlannerTrialExpired;
