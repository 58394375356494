import { FC } from 'react';
import style from 'components/analyticsPdfExports/tableHead/TableHead.module.scss';

type Props = {
  children: any;
  isPrimary?: boolean;
};

const TableHead: FC<Props> = ({ children, isPrimary }) => {
  return (
    <thead
      className={isPrimary ? style.tableHeadMain : style.tableHeadSecondary}
    >
      <tr>
        <th>{children}</th>
      </tr>
    </thead>
  );
};

export default TableHead;
