import { PostsMeta } from 'types/Post';
import { SORTED_STATUSES } from 'utils/statuses';
import { STATUS_EXPORT_FAILED } from '@kontentino/kontentino-constants/Posts';
import ArrayUtils from 'app/utils/array';

export const formatAndSortPostsMetadataStatuses = (
  statuses: PostsMeta['status'],
) => {
  const formattedStatuses = Object.entries(statuses ?? {}).map(
    ([key, value]) => ({
      value: Number.parseInt(key),
      postCount: value,
    }),
  );

  return ArrayUtils.sortByArray(formattedStatuses, {
    key: 'value',
    order: SORTED_STATUSES,
  }).filter((status) => status.value !== STATUS_EXPORT_FAILED);
};
