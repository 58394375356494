import { Module } from 'config';
import { FC } from 'react';
import Link from 'components/shared/Link';

type Props = {
  text: string;
};

const SubscriptionExpiredFooter: FC<Props> = ({ text }) => {
  return (
    <>
      {text + ' '}
      <Link to={Module.OldApp.URL.MY_PROFILE}>
        <span className="tw-cursor-pointer tw-text-grayscale-100 tw-underline">
          here
        </span>
      </Link>
      .
    </>
  );
};

export default SubscriptionExpiredFooter;
