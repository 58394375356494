import { FC, useCallback, useEffect, useState } from 'react';
import { FocusedInputType } from 'components/date-range-picker/DateRangePicker';
import {
  getDatesAutoSelectPeriodWithConstrains,
  getDatesFromPreset,
  getDatesWithConstraints,
} from 'components/date-range-picker/utils/libs';
import { DateRangeOnChangeParams } from 'types/Date';
import { Dayjs } from 'dayjs';

type Props = {
  children(x: ControlsContainerParams): JSX.Element;
  initialStartDate?: Dayjs | null;
  initialEndDate?: Dayjs | null;
  onPreselect?: AnyFunction;
  autoSelectPeriod?: number;
  maxEndDate?: Dayjs | null;
};

export type ControlsContainerParams = {
  onDatesChange: AnyFunction;
  onFocusChange: AnyFunction;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  focusedInput: FocusedInputType;
  onPresetClick: AnyFunction;
  selectedPreset: string | null;
};

const ControlsContainer: FC<Props> = ({
  children,
  initialStartDate,
  initialEndDate,
  onPreselect,
  autoSelectPeriod,
  maxEndDate,
}) => {
  const [startDate, setStartDate] = useState<Dayjs | null>(
    initialStartDate ?? null,
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(initialEndDate ?? null);
  const [selectedPreset, setSelectedPreset] = useState<string | null>(null);
  const [focusedInput, setFocusedInput] =
    useState<FocusedInputType>('startDate');

  const preselect = useCallback(
    ({ startDate, endDate }: DateRangeOnChangeParams) =>
      onPreselect?.({ startDate, endDate }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    preselect({ startDate, endDate });
  }, [startDate, endDate, preselect]);

  const handleDatesChange = ({
    startDate,
    endDate,
  }: DateRangeOnChangeParams) => {
    let dates = getDatesWithConstraints({ startDate, endDate }, { maxEndDate });

    if (typeof autoSelectPeriod === 'number') {
      dates = getDatesAutoSelectPeriodWithConstrains(dates, autoSelectPeriod, {
        maxEndDate,
      });
    }

    setSelectedPreset(null);
    setStartDate(dates.startDate);
    setEndDate(dates.endDate);
  };

  const handleFocusedInputChange = (focusedInput: FocusedInputType) => {
    if (!autoSelectPeriod) {
      setFocusedInput(!focusedInput ? 'startDate' : focusedInput);
    }
  };

  const handleSelectPreset = (preset: string | null) => {
    const presetDates = getDatesWithConstraints(getDatesFromPreset(preset), {
      maxEndDate,
    });

    setStartDate(presetDates.startDate);
    setEndDate(presetDates.endDate);
    setSelectedPreset(preset);
  };

  return children({
    onDatesChange: handleDatesChange,
    onFocusChange: handleFocusedInputChange,
    startDate,
    endDate,
    focusedInput,
    selectedPreset,
    onPresetClick: handleSelectPreset,
  });
};

export default ControlsContainer;
