import DateUtils, { formatDate } from 'app/utils/date';
import MetricHeader from 'app/modules/insights/modules/postsPerformance/components/MetricHeader';
import SinglePostSkeleton from 'app/modules/insights/modules/postsPerformance/components/SinglePostSkeleton';
import { usePostsPerformanceState } from 'app/modules/insights/modules/postsPerformance/hooks/usePostsPerformanceState';
import { getMetricsForPageType } from 'app/modules/insights/modules/postsPerformance/utils/metricProperty';
import { Page } from 'app/modules/insights/types/Page';
import ArrayUtils from 'app/utils/array';
import { AdAccountsConnection } from 'components/insights/postsPerformance/postsList/adAccountConnection/AdAccountsConnection';
import NoPostsMessage from 'components/insights/postsPerformance/postsList/NoPostsMessage';
import { FC, useCallback } from 'react';
import { useEffectOnce } from 'utils/hooks/useEffectOnce';
import { usePostsPageQuery } from '../hooks/usePagePostsQuery';
import { usePostsByMetricQuery } from '../hooks/usePostsByMetricQuery';
import PostsUnavailable from './bestPostsForMetrics/PostsUnavailable';
import SinglePost from './SinglePost';
import { PostCollapsedLayoutVariant } from 'app/modules/insights/modules/postsPerformance/components/postCollapsed/PostCollapsedLayout';
import { MetricProperty } from 'app/modules/insights/types/MetricProperty';
import { PageNumberType } from '@kontentino/kontentino-constants/Pages';
import { Dayjs } from 'dayjs';

type Props = {
  page: Page;
  startDate: Dayjs;
  endDate: Dayjs;
  metric: MetricProperty;
  types: number[];
  onPostsCountChanged?: (count: Record<string, number> | undefined) => void;
  paginateBy?: number;
  postCollapsedLayoutVariant?: PostCollapsedLayoutVariant;
};

const PostsByMetricList: FC<Props> = (props) => {
  const { setMetricHeader } = usePostsPerformanceState();
  const { page, metric } = props;
  const { posts, loading, error, hasMorePosts, sentryRef, refetch } =
    usePostsByMetricQuery(props);

  const { data: pageData } = usePostsPageQuery(props);

  useEffectOnce(() => {
    return () => setMetricHeader(undefined);
  });

  const handleOnVisibleChange = useCallback(
    (
      visibility: {
        isVisible: boolean;
        wasEverVisible: boolean;
      },
      metric: MetricProperty,
    ) => {
      if (visibility.wasEverVisible) {
        setMetricHeader(!visibility.isVisible ? metric : undefined);
      }
    },
    [setMetricHeader],
  );

  return (
    <>
      <MetricHeader
        metric={getMetricsForPageType(page?._type, true)[metric.key]}
        onVisibleChange={handleOnVisibleChange}
        className="tw-mb-6"
      />
      {loading &&
        ArrayUtils.generate(3).map((val) => <SinglePostSkeleton key={val} />)}
      {!loading && posts.length === 0 && (
        <NoPostsMessage
          selectedEndDate={formatDate(DateUtils.toDateString(props.endDate))}
          selectedStartDate={formatDate(
            DateUtils.toDateString(props.startDate),
          )}
        />
      )}
      {pageData?.hasPaidReach && (
        <AdAccountsConnection pageId={page.id} onToggled={refetch} />
      )}
      {error && (
        <PostsUnavailable variant="danger">
          {error.message ??
            `We were unable to get posts available for this metric and selected
          time frame in your profile.`}
        </PostsUnavailable>
      )}
      {pageData &&
        posts.map((post, index) => (
          <div
            key={`post-${index}`}
            style={{ breakInside: 'avoid' }}
            data-name={`insights_posts-performance_single-metric_post${
              index === 0 ? '-first' : ''
            }`}
          >
            <SinglePost
              pageType={page._type as PageNumberType}
              post={post}
              index={index}
              metric={metric}
              benchmarks={pageData.benchmarks}
              collapsedLayoutVariant={props.postCollapsedLayoutVariant}
            />
          </div>
        ))}
      {hasMorePosts && (
        <div ref={sentryRef}>
          <SinglePostSkeleton />
        </div>
      )}
    </>
  );
};

export default PostsByMetricList;
