import { InsightsQueryKey } from 'app/utils/queryKey';
import { CompetitionAnalysisQueryParams } from 'app/modules/insights/modules/competitionAnalysis/types/competitionAnalysisFilter';

export const QueryKey = {
  initialization: () => InsightsQueryKey('initialization'),
  pageSyncStatus: (id: string) => InsightsQueryKey('pageSyncStatus', id),
  searchExternalPages: (query: string, pageType: number) =>
    InsightsQueryKey('searchExternalPages', [query, pageType]),
  pagesCompetitionAnalysis: (
    params: CompetitionAnalysisQueryParams & { paginationPage: number },
  ) => InsightsQueryKey('pagesCompetitionAnalysis', params),
  getAdAccounts: 'getAdAccounts',
};

export const AD_ACCOUNT_CONNECT_MESSAGE =
  'Some metrics might be missing due to Ad accounts not being connected, to enable more metrics please connect your Ad account.';
