import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import {
  ModalContextValue,
  useModalContext,
} from 'components/shared/modal/ModalProvider';
import { mergeTailwindClasses } from '@kontentino/ui';

type Props = {
  children?: ReactNode | ((context: ModalContextValue) => ReactNode);
  withDivider?: boolean;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;

const ModalFooter: FC<Props> = ({
  className,
  children,
  style,
  withDivider = false,
  ...rest
}) => {
  const modalContext = useModalContext();

  return (
    <div
      className={mergeTailwindClasses(
        clsx(
          'tw-flex tw-items-center tw-justify-end tw-gap-3 tw-p-6',
          {
            'tw-sticky tw-bottom-0 tw-z-10 tw-bg-white':
              modalContext.isContentHeightAutoResizable,
            'tw-border-t tw-border-t-grayscale-10': withDivider,
          },
          className,
        ),
      )}
      {...rest}
    >
      {typeof children === 'function' ? children(modalContext) : children}
    </div>
  );
};

export default ModalFooter;
