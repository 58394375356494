import { useQuery } from '@apollo/client';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import { StandardPostUtils } from 'app/modules/insights/utils/standardPost';
import { FB_POSTS_QUERY_NEW } from 'graphql/insights/queries/fbPostsQueryNew';
import { IG_POSTS_QUERY_NEW } from 'graphql/insights/queries/igPostsQueryNew';
import { LI_POSTS_QUERY_NEW } from 'graphql/insights/queries/liPostsQueryNew';
import { fbPostsQueryNew } from 'graphql/insights/queries/__generated__/fbPostsQueryNew';
import { igPostsQueryNew } from 'graphql/insights/queries/__generated__/igPostsQueryNew';
import { liPostsQueryNew } from 'graphql/insights/queries/__generated__/liPostsQueryNew';
import { PageType } from 'graphql/insights/types';
import { useMemo } from 'react';
import useInfiniteScroll, {
  UseInfiniteScrollHookArgs,
} from 'utils/hooks/useInfiniteScroll';
import { getSupportedPostTypesForMetric } from '../utils/getSupportedPostTypesForMetric';
import { parseIgPostTypesToQueryVariables } from '../utils/parseIgPostTypesToQueryVariables';
import { MetricProperty } from '../../../types/MetricProperty';
import InsightsConfig from 'app/config/insights';
import { OrderDirection } from 'app/types';
import { Dayjs } from 'dayjs';
import DateUtils from 'app/utils/date';

type Props = {
  page: PageType;
  startDate: Dayjs;
  endDate: Dayjs;
  metric: MetricProperty;
  types: number[];
  paginateBy?: number;
};

export function usePostsByMetricQuery({
  page,
  startDate,
  endDate,
  metric,
  types,
  paginateBy = InsightsConfig.MAX_POSTS_PER_METRIC,
}: Props) {
  const variables = {
    pageId: page.id,
    startDate: DateUtils.toDateString(startDate),
    endDate: DateUtils.toDateString(endDate),
    first: paginateBy,
    after: '',
    types:
      types.length === 0
        ? getSupportedPostTypesForMetric(metric.key, page?._type)
        : types,
    orderBy: [
      {
        field: metric.queryParameter,
        direction: OrderDirection.Desc.toUpperCase(),
      },
    ],
  };

  const fb = useQuery<fbPostsQueryNew>(FB_POSTS_QUERY_NEW, {
    variables,
    skip: page._type !== TYPE.FACEBOOK,
  });

  const ig = useQuery<igPostsQueryNew>(IG_POSTS_QUERY_NEW, {
    variables: {
      ...variables,
      ...parseIgPostTypesToQueryVariables(variables.types),
    },
    skip: page._type !== TYPE.INSTAGRAM,
  });

  const li = useQuery<liPostsQueryNew>(LI_POSTS_QUERY_NEW, {
    variables,
    skip: page._type !== TYPE.LINKEDIN,
  });

  const posts = useMemo(() => {
    const postPage = {
      name: page.name ?? 'Unknown Profile',
      logo: page.picture,
    };

    switch (page._type) {
      case TYPE.INSTAGRAM:
        return ig.data?.posts?.edges.map(({ node }) =>
          StandardPostUtils.fromIgPostDetailed(node, postPage),
        );
      case TYPE.FACEBOOK:
        return fb.data?.posts?.edges.map(({ node }) =>
          StandardPostUtils.fromFbPostDetailed(node, postPage),
        );
      case TYPE.LINKEDIN:
        return li.data?.posts?.edges.map(({ node }) =>
          StandardPostUtils.fromLiPostDetailed(node, postPage),
        );
    }
  }, [ig.data, fb.data, li.data, page._type, page.name, page.picture]);

  const { pageInfo, totalCount, fetchMore } = useMemo(() => {
    switch (page._type) {
      case TYPE.INSTAGRAM:
        return {
          pageInfo: ig.data?.posts?.pageInfo,
          totalCount: ig.data?.posts?.totalCount,
          fetchMore: ig.fetchMore,
        };
      case TYPE.FACEBOOK:
        return {
          pageInfo: fb.data?.posts?.pageInfo,
          totalCount: fb.data?.posts?.totalCount,
          fetchMore: fb.fetchMore,
        };
      case TYPE.LINKEDIN:
        return {
          pageInfo: li.data?.posts?.pageInfo,
          totalCount: li.data?.posts?.totalCount,
          fetchMore: li.fetchMore,
        };
    }
    return {};
  }, [
    ig.data,
    fb.data,
    li.data,
    li.fetchMore,
    ig.fetchMore,
    fb.fetchMore,
    page._type,
  ]);

  const [sentryRef] = useInfiniteScroll({
    loading: fb.loading || ig.loading || li.loading,
    onLoadMore: () => {
      return fetchMore && pageInfo?.endCursor
        ? fetchMore({
            variables: {
              ...variables,
              after: pageInfo?.endCursor,
            },
            updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
              if (!fetchMoreResult || !fetchMoreResult.posts) {
                return previousResult;
              }

              const previousEdges = previousResult.posts?.edges;
              const fetchMoreEdges = fetchMoreResult.posts?.edges;

              fetchMoreResult.posts.edges = [
                ...previousEdges,
                ...fetchMoreEdges,
              ];

              return { ...fetchMoreResult };
            },
          })
        : undefined;
    },
    hasNextPage: posts && totalCount && totalCount > posts.length,
  } as UseInfiniteScrollHookArgs);

  const postLimit = posts && posts.length > 0 ? posts.length : variables.first;

  return {
    posts: posts ?? [],
    loading: fb.loading || ig.loading || li.loading,
    error: fb.error || ig.error || li.error,
    sentryRef: sentryRef,
    refetch: fb.refetch || ig.refetch || li.refetch,
    hasMorePosts:
      posts && posts.length > 0 && totalCount && totalCount > postLimit,
  };
}
