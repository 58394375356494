import { Outlet, RouteObject } from 'react-router-dom';
import routeConfig from 'app/config/routes';
import React from 'react';
import InsightsLite from 'app/modules/insightsLite/components/InsightsLite';

export const insightsLiteRouter: RouteObject = {
  path: routeConfig.insightsLite.path,
  element: <Outlet />,
  children: [
    {
      index: true,
      element: <InsightsLite />,
    },
  ],
};
