import { TYPE } from '@kontentino/kontentino-constants/Pages';

export const getInsightsPageTypeAlias = (type: number) => {
  switch (type) {
    case TYPE.FACEBOOK:
      return 'fb';
    case TYPE.INSTAGRAM:
      return 'ig';
    case TYPE.LINKEDIN:
      return 'li';
    default:
      return 'fb';
  }
};
