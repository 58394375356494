import { EntityTypes } from 'constants/analytics';
import { FC } from 'react';
import { getQueryParameter } from 'utils/url';
import PageExport from 'components/analyticsPdfExports/PageExport';

type Props = {};

const ExportsSwitch: FC<Props> = () => {
  const type = getQueryParameter('entityType');

  switch (type) {
    case EntityTypes.Pages:
      return <PageExport />;
    default:
      return null;
  }
};

export default ExportsSwitch;
