import React, { FC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const CommentsListItemReplyBubbleWrapper: FC<Props> = ({ children }) => {
  return (
    <div
      className={
        'tw-max-w-[496px] tw-flex-grow-0 tw-gap-2 tw-rounded-xl tw-border tw-border-grayscale-10 tw-px-4 tw-py-[6px] tw-pb-6 tw-text-xs tw-font-regular tw-text-grayscale-180'
      }
    >
      {children}
    </div>
  );
};

export default CommentsListItemReplyBubbleWrapper;
