import React, { FC } from 'react';
import { IG_METRICS_PROPERTIES } from 'app/modules/insights/modules/postsPerformance/utils/metricProperty';
import { StandardPostDetailedMetrics } from 'app/types/StandardPostDetailed';
import ValueBox from './ValueBox';
import { getValueBox } from 'app/modules/insights/modules/postsPerformance/utils/getValueBox';

type Props = {
  metrics: StandardPostDetailedMetrics;
};

const selectedProperties = [
  IG_METRICS_PROPERTIES.reach,
  IG_METRICS_PROPERTIES.engagement,
  IG_METRICS_PROPERTIES.impressions,
  IG_METRICS_PROPERTIES.likes,
];

const InstagramValueBoxes: FC<Props> = ({ metrics }) => {
  const valueBoxes = selectedProperties.map((selectedProperty) =>
    getValueBox(metrics[selectedProperty.key], selectedProperty),
  );

  return (
    <div className="tw-flex tw-flex-wrap">
      {valueBoxes.map((valueBox, index) => (
        <React.Fragment key={`instagram-value-box-${index}`}>
          <div className="tw-flex-1 tw-p-4">
            <ValueBox {...valueBox} />
          </div>
          {index + 1 < valueBoxes.length && (
            <div className="tw-w-[1px] tw-bg-grayscale-20" />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default InstagramValueBoxes;
