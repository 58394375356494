import { FC } from 'react';
import AMAZONLOGO from './../images/amazon.svg';
import KONTENTINOLOGO from './../images/kontentino.svg';
import SKILLSHARELOGO from './../images/skillshare.svg';
import ReferralReward from './ReferralReward';

const rewards = [
  {
    img: {
      src: AMAZONLOGO,
      alt: 'Amazon company',
    },
    price: '€50',
    label: 'Amazon voucher, or',
  },
  {
    img: {
      src: SKILLSHARELOGO,
      alt: 'SkillShare company',
    },
    price: '€72',
    label: 'Skillshare subscription for 6 months, or',
  },
  {
    img: {
      src: KONTENTINOLOGO,
      alt: 'Kontentino company',
    },
    price: '€100',
    label: 'off your own Kontentino subscription.',
  },
];

const ReferralRewardsList: FC = () => {
  return (
    <div className="tw-my-6 tw-flex tw-flex-col tw-gap-6">
      {rewards.map((reward, index) => (
        <ReferralReward key={index} {...reward} />
      ))}
    </div>
  );
};

export default ReferralRewardsList;
