import { FC } from 'react';
import style from 'components/analyticsPdfExports/competitionAnalysisExport/CompetitionAnalysisExport.module.scss';
import PageRow from 'components/insights/competitionAnalysis/pagesPerformance/PageRow';
import TableHead from 'components/analyticsPdfExports/TableHead';
import TableRowItem from 'components/analyticsPdfExports/TableRowItem';
import PageRowHeader from 'components/insights/competitionAnalysis/pagesPerformance/PageRowHeader';
import PostRowHeader from 'components/insights/competitionAnalysis/postsPerformance/PostRowHeader';
import PostRow from 'components/insights/competitionAnalysis/postsPerformance/PostRow';
import { getMinMaxValueAndCount } from 'components/insights/competitionAnalysis/utils/getMinMaxValueAndCount';
import { Page } from 'app/modules/insights/types/Page';
import Skeleton from 'react-loading-skeleton';
import { useQuery } from 'react-query';
import CompetitionAnalysisApi from 'api/analytics/competitionAnalysis';
import { ALL_PAGES_PAGINATION } from 'app/modules/insights/constants/pagination';
import { QueryKey } from 'components/insights/constants';

type Props = {
  showReactions: boolean;
  startDate: string;
  endDate: string;
  page: Page;
  token: string;
  comparedPageIds: string[];
};

const CompetitionAnalysisExport: FC<Props> = ({
  showReactions,
  page,
  endDate,
  startDate,
  token,
  comparedPageIds,
}) => {
  const variables = {
    pageType: page._type,
    pageIds: comparedPageIds,
    dateFrom: startDate,
    dateTo: endDate,
    paginationPage: ALL_PAGES_PAGINATION,
  };

  const { isLoading, data } = useQuery(
    QueryKey.pagesCompetitionAnalysis(variables),
    () =>
      CompetitionAnalysisApi.getPagesAnalysis({
        ...variables,
        token,
      }),
  );

  return (
    <>
      {isLoading && <Skeleton width="100%" height={'200px'} />}

      {data && (
        <table className={style.wrapperTable}>
          <TableHead isPrimary>Competition analysis for {page.name}</TableHead>
          <tbody>
            <TableRowItem className={style.sectionPadding}>
              <table className={style.sectionWrapper}>
                <TableHead>Pages performance</TableHead>
                <tbody>
                  <TableRowItem>
                    <table className={style.listWrapper}>
                      <TableHead>
                        <PageRowHeader pageType={data?.pages[0]?.type} />
                      </TableHead>
                      <tbody>
                        {data.pages.map((page, index) => (
                          <TableRowItem className={style.listItem} key={index}>
                            <PageRow
                              key={page._id}
                              pageType={page.type}
                              imageUrl={page.picture}
                              pageName={page.name}
                              followersTotal={getMinMaxValueAndCount(
                                'followersTotal',
                                index,
                                data.pages,
                              )}
                              posts={getMinMaxValueAndCount(
                                'posts',
                                index,
                                data.pages,
                              )}
                              interactions={getMinMaxValueAndCount(
                                'interactions',
                                index,
                                data.pages,
                              )}
                              comments={getMinMaxValueAndCount(
                                'comments',
                                index,
                                data.pages,
                              )}
                              shares={getMinMaxValueAndCount(
                                'shares',
                                index,
                                data.pages,
                              )}
                              reactionsTotal={getMinMaxValueAndCount(
                                'reactionsTotal',
                                index,
                                data.pages,
                              )}
                              likes={getMinMaxValueAndCount(
                                'likes',
                                index,
                                data.pages,
                              )}
                              isHighlighted={data.pages.length > 1}
                            />
                          </TableRowItem>
                        ))}
                      </tbody>
                    </table>
                  </TableRowItem>
                </tbody>
              </table>
            </TableRowItem>
            <TableRowItem className={style.sectionPadding}>
              <table className={style.sectionWrapper}>
                <TableHead>Posts performance</TableHead>
                <tbody>
                  <TableRowItem>
                    <table className={style.listWrapper}>
                      <TableHead>
                        <PostRowHeader
                          pageType={data.posts[0]?.pageInfo?.type}
                          canShowReactions={showReactions}
                        />
                      </TableHead>
                      <tbody>
                        {data.posts.map((post, index) => (
                          <TableRowItem key={index} className={style.listItem}>
                            <PostRow
                              post={post}
                              canShowReactions={showReactions}
                            />
                          </TableRowItem>
                        ))}
                      </tbody>
                    </table>
                  </TableRowItem>
                </tbody>
              </table>
            </TableRowItem>
          </tbody>
        </table>
      )}
    </>
  );
};

export default CompetitionAnalysisExport;
