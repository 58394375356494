import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

export type CharacterCounterCountConfig = {
  characters: number;
  hashtags: number;
  mentions: number;
};

type Props = {
  limit?: number;
  count: CharacterCounterCountConfig;
};

const CharacterCounter: FC<Props> = ({ limit, count }) => {
  const { t } = useTranslation();
  const {
    characters: charCount,
    hashtags: hashtagsCount,
    mentions: mentionsCount,
  } = count;

  if (typeof limit === 'number') {
    const getPercentageLimit = () => {
      const percentage = Math.round((100 / limit) * charCount);

      return percentage > 100 ? 100 : percentage;
    };

    return (
      <div className="tw-ml-auto tw-flex tw-items-center tw-text-xs tw-text-grayscale-100">
        <svg
          height="14"
          width="14"
          viewBox="0 0 20 20"
          className={clsx({
            'tw-text-danger-100': charCount > limit,
            'tw-text-primary-100': charCount <= limit,
          })}
        >
          <circle r="10" cx="10" cy="10" fill="#e9ecef" />
          <circle
            r="5"
            cx="10"
            cy="10"
            fill="transparent"
            stroke="currentColor"
            strokeWidth="10"
            strokeDasharray={`calc(${getPercentageLimit()} * 31.4 / 100) 31.4`}
            transform="rotate(-90) translate(-20)"
          />
          <circle r="6" cx="10" cy="10" fill="white" />
        </svg>
        <div className="tw-ml-1">
          {`${charCount}/${limit} ${t('character', { count: limit })}`}
          {hashtagsCount > 0 &&
            `,${hashtagsCount} ${t('hashtag', { count: hashtagsCount })}`}
          {mentionsCount > 0 &&
            `,${mentionsCount} ${t('mention', { count: mentionsCount })}`}
        </div>
      </div>
    );
  }

  return (
    <div className="tw-ml-auto tw-text-xs tw-text-grayscale-100">
      {charCount} {t('character', { count: charCount })}
      {hashtagsCount > 0 &&
        `,${hashtagsCount} ${t('hashtag', { count: hashtagsCount })}`}
      {mentionsCount > 0 &&
        `,${mentionsCount} ${t('mention', { count: mentionsCount })}`}
    </div>
  );
};

export default CharacterCounter;
