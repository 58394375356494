import React, { FC } from 'react';
import { useLinkShortenerContainer } from 'app/modules/textEditor/components/linkShortener/LinkShortener.utils';
import ArrayUtils from 'app/utils/array';
import LinkShortenerItem from 'app/modules/textEditor/components/linkShortener/LinkShortenerItem';
import CreateEditShortenLinkModal from 'app/modules/textEditor/components/linkShortener/CreateEditShortenLinkModal';
import StringUtils from 'utils/string';
import StatisticsModal from 'app/modules/textEditor/components/linkShortener/StatisticsModal';
import { Icon } from '@kontentino/ui';
import { faInfoCircle, faPlus } from '@fortawesome/pro-regular-svg-icons';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import OptionsBarFeature from '../OptionsBarFeature';

type Props = {
  onCopyToText?(link: string): void;
  pageId?: number;
  dataName?: string;
};

const skeletons = ArrayUtils.generate(5);

const LinkShortener: FC<Props> = (props) => {
  const container = useLinkShortenerContainer(props);
  const { t } = useTranslation();
  const filteredLinks = container.shortenLinks?.edges
    .map(({ node }) => node)
    .filter((link) =>
      [link.name, link.url, link.shortUrl].some((value) =>
        StringUtils.isSubstring(container.filter, value),
      ),
    );

  return (
    <>
      <OptionsBarFeature
        title={t('linkShortener')}
        dataName={props.dataName}
        iconAfter={
          <Icon icon={faInfoCircle} className="tw-text-grayscale-100" />
        }
        tooltipContent={t('shortenyourLinksAddUtmTags')}
        itemAction={{
          label: t('shortenedLink'),
          icon: <Icon icon={faPlus} />,
          onClick: () => container.setShortenLinkToEdit(null),
        }}
        search={{
          action: (value) => container.setFilter(value),
          value: container.filter,
        }}
      >
        {container.isLoading &&
          skeletons.map((i) => <Skeleton height={35} key={i} />)}
        {filteredLinks?.map((shortenLink) => (
          <LinkShortenerItem
            shortenLink={shortenLink}
            key={shortenLink.id}
            onCopyToClipboard={container.copyToClipboard}
            onCopyToText={props.onCopyToText}
            onEdit={(shortenLink) =>
              container.setShortenLinkToEdit(shortenLink)
            }
            onStatistics={container.setLinkIdStatsToSee}
            onDelete={container.remove}
          />
        ))}
        <p className="tw-py-3 tw-text-center tw-text-sm tw-text-grayscale-100">
          {container.shortenLinks?.edges.length === 0 && t('noShortenedLink')}

          {filteredLinks?.length === 0 &&
            !!container.shortenLinks?.edges.length &&
            t('weDidntFindAnyShortenedLink')}
        </p>
      </OptionsBarFeature>
      <CreateEditShortenLinkModal
        shortenLink={container.hashtagToEdit}
        onClose={() => container.setShortenLinkToEdit(undefined)}
        onCopyToText={props.onCopyToText}
        pageId={props.pageId}
      />
      <StatisticsModal
        onClose={() => container.setLinkIdStatsToSee(undefined)}
        linkId={container.linkIdStatsToSee}
      />
    </>
  );
};

export default LinkShortener;
