import { get, post, httpDelete } from 'api/client';
import config from 'config';
import { AnalyticsAdAccount } from 'types/AdAccount';

const AnalyticsAdAccountsApi = {
  getAvailableAdAccounts: (): Promise<AnalyticsAdAccount[]> => {
    return get('/api/fb/fad/accounts', {
      baseURL: config.ANALYTICS_ENDPOINT,
    });
  },
  toggleAdAccount: (params: {
    pageId: string;
    facebookAdAccountId: string;
    connect: boolean;
  }): Promise<{}> => {
    if (params.connect) {
      return post(
        `/api/fb/fad/page/${params.pageId}`,
        { facebookAdAccountId: params.facebookAdAccountId },
        {
          baseURL: config.ANALYTICS_ENDPOINT,
        },
      );
    }

    return httpDelete(
      `/api/fb/fad/page/${params.pageId}/${params.facebookAdAccountId}`,
      {
        baseURL: config.ANALYTICS_ENDPOINT,
      },
    );
  },
};

export default AnalyticsAdAccountsApi;
