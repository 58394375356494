import React, { FC, useEffect, useState } from 'react';
import { Button, Tooltip } from '@kontentino/ui';
import clsx from 'clsx';
import { CommentReactionSummary } from 'app/modules/comments/types';
import { useReactionSummary } from '../providers/ReactionSummaryProvider';
import { usePostCommunicationContext } from '../../../PostCommunicationProvider';

type Props = {
  reaction: CommentReactionSummary;
};

const CommentsListItemReaction: FC<Props> = ({ reaction }) => {
  const [tempReaction, setTempReaction] = useState(reaction);
  const { onToggle } = useReactionSummary();
  const { disableCommunicationActions } = usePostCommunicationContext();

  useEffect(() => {
    setTempReaction(reaction);
  }, [reaction]);

  const handleToggleReaction = () => {
    const prevReaction = { ...tempReaction };

    setTempReaction((oldReaction) => ({
      ...oldReaction,
      my_reaction: oldReaction.my_reaction ? null : { id: -1 },
      count: oldReaction.count + (oldReaction.my_reaction ? -1 : 1),
    }));

    onToggle({ reaction, onError: () => setTempReaction(prevReaction) });
  };

  if (tempReaction.count === 0) return null;

  return (
    <Tooltip
      content={
        <>
          {tempReaction.users
            .map((author) => [author.name, author.surname].join(' '))
            .join(', ')}
        </>
      }
      key={tempReaction.content}
    >
      <div className="tw-flex tw-items-center tw-justify-center">
        <Button
          iconBefore={tempReaction.content}
          variant="secondary"
          className={clsx(
            'tw-h-auto !tw-min-w-[35px] tw-rounded-full tw-border tw-px-1',
            {
              ' !tw-border-primary-100 tw-text-primary-100':
                tempReaction.my_reaction,
              'tw-border-secondary-20': !tempReaction.my_reaction,
              'tw-cursor-not-allowed': disableCommunicationActions,
            },
          )}
          onClick={() => !disableCommunicationActions && handleToggleReaction()}
        >
          <span className="tw-text-sm">{tempReaction.count}</span>
        </Button>
      </div>
    </Tooltip>
  );
};

export default CommentsListItemReaction;
