import React, { FC } from 'react';
import { Icon, mergeTailwindClasses } from '@kontentino/ui';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';

type Props = {
  onClick: () => void;
  label: string | React.ReactNode;
  isActive?: boolean;
  className?: string | null;
  isDisabled?: boolean;
  isSelected?: boolean;
  dataName?: string;
  dataCy?: string;
};

const Placeholder: FC<Props> = ({
  onClick,
  label,
  isActive,
  isDisabled,
  isSelected,
  className,
  dataName,
  dataCy,
}) => (
  <div
    className={mergeTailwindClasses(
      clsx(
        'tw-z-0 tw-flex tw-h-10 tw-w-full tw-min-w-[200px] tw-cursor-pointer tw-items-center tw-rounded tw-border-0 tw-bg-white tw-px-3 tw-text-md tw-font-regular tw-text-grayscale-180 tw-outline-none tw-ring-1',
        {
          'tw-ring-primary-100': isActive,
          'tw-ring-grayscale-30': !isActive,
          'tw-text-grayscale-100': !isSelected,
          'tw-cursor-not-allowed tw-bg-grayscale-5 tw-text-grayscale-50':
            isDisabled,
        },
        className,
      ),
    )}
    tabIndex={0}
    onClick={isDisabled ? undefined : onClick}
    data-name={dataName}
    data-cy={dataCy}
  >
    <span>{label}</span>
    <Icon icon={faChevronDown} className="tw-ml-auto tw-text-grayscale-100" />
  </div>
);

export default Placeholder;
