import { Button } from '@kontentino/ui';
import { ModalRouteProps } from 'context/ModalRouter';
import React, { FC } from 'react';
import BaseWelcomeModal from './BaseWelcomeModal';
import ADSPLANNER_ILLUSTRATION from './illustrations/adsplanner_welcome.svg';
import Link from 'components/shared/Link';
import routes from 'constants/routes';
import { useTranslation } from 'react-i18next';

type Props = ModalRouteProps;

const AdsPlannerWelcomeModal: FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <BaseWelcomeModal
      modalHistory={props.modalHistory}
      modalManager={props.modalManager}
      image={<img src={ADSPLANNER_ILLUSTRATION} alt="Ads Planner icon" />}
      title={t('welcomeModals.adsplanner.title')}
      description={t('welcomeModals.adsplanner.description')}
      buttons={
        <Link
          to={routes.ADS_PLANNER}
          style={{ flexBasis: '100%' }}
          isRouterLink
        >
          <Button className="tw-w-full">
            {t('welcomeModals.connectMyAdAccounts')}
          </Button>
        </Link>
      }
    />
  );
};

export default AdsPlannerWelcomeModal;
