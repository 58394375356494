import PageTypeUtils from 'app/utils/pageType';
import { PageNumberType } from '@kontentino/kontentino-constants/Pages';

const getSocialMediaTypeForBrandLogo = (pageType?: number) => {
  return pageType
    ? (PageTypeUtils.getNameFromNumber(pageType as PageNumberType) as
        | 'facebook'
        | 'instagram'
        | 'linkedin'
        | 'pinterest'
        | 'twitter'
        | 'google_my_business')
    : undefined;
};

export default getSocialMediaTypeForBrandLogo;
