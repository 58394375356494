import store from 'store';
import {
  createSearchFromParams,
  createStandardSearch,
  getBasePath,
  openBlank,
} from 'utils/url';
import config from 'config';
import { PageNumberType } from '@kontentino/kontentino-constants/Pages';
import { post } from 'api/client';
import { getGraphsConfigurationForPages } from 'components/analyticsPdfExports/utils/getGraphsConfigurationForPages';
import { EntityTypes } from 'constants/analytics';
import routes from 'constants/routes';
import { formatDate } from 'app/utils/date';
import { Page } from 'app/modules/insights/types/Page';
import { MetricProperty } from 'app/modules/insights/types/MetricProperty';

type PdfExportParams = {
  entityType: string;
  id: string;
  dateFrom: string;
  dateTo: string;
  pageType: PageNumberType;
  order: string;
  postTypes: number[];
  metric: string;
  comparedStartDate: string | null;
  comparedEndDate: string | null;
  areMetricsRestricted: boolean;
};

type ExportPdfPostsPerformanceParams = {
  startDate: string;
  endDate: string;
  metric: MetricProperty;
  page: Page;
  types: number[];
};

type ExportPdfCompetitionAnalysisParams = {
  startDate: string;
  endDate: string;
  page: Page;
  showReactions: boolean;
  comparedPageIds: string[];
};

const AnalyticsExportsApi = {
  token: (ids: string): Promise<{ accessToken: string }> => {
    return post(
      `/api/export?ids=${ids}`,
      {},
      {
        baseURL: config.ANALYTICS_ENDPOINT,
      },
    );
  },
  exportCSV: (
    socialMediaType: string,
    exportEntityType: string,
    dateFrom: string,
    dateTo: string,
    id: string,
  ) => {
    const authToken = store.getState().app.authToken;

    const searchParams = createSearchFromParams({
      dateFrom,
      dateTo,
      ids: id,
      accessToken: authToken,
    });

    openBlank(
      `${config.ANALYTICS_ENDPOINT}/${socialMediaType}/export/${exportEntityType}${searchParams}`,
    );
  },
  exportPDF: async (params: PdfExportParams) => {
    const res = await AnalyticsExportsApi.token(params.id);

    const paramsToCreate: Record<string, string | number | null> = {
      entityType: params.entityType,
      dateFrom: params.dateFrom,
      dateTo: params.dateTo,
      id: params.id,
      pageType: params.pageType,
      token: res.accessToken,
      restricted: params.areMetricsRestricted ? '1' : '0',
      order: params.order,
      metric: params.metric,
    };

    if (params.comparedStartDate && params.comparedEndDate) {
      paramsToCreate.comparedStartDate = params.comparedStartDate;
      paramsToCreate.comparedEndDate = params.comparedEndDate;
    }

    const searchParams = createSearchFromParams(paramsToCreate);

    const getUrlConfigParams = () => {
      if (params.entityType === EntityTypes.Pages) {
        return getGraphsConfigurationForPages();
      }

      return '';
    };

    const urlConfigParams = getUrlConfigParams();

    const url = encodeURIComponent(
      `${window.location.origin}${getBasePath()}${
        routes.REPORTS
      }${searchParams}${urlConfigParams}`,
    );

    return openBlank(`${config.SCREENSHOT_SERVICE}/pdf?ready=true&url=${url}`);
  },
  async exportPDFCompetitionAnalysis(
    params: ExportPdfCompetitionAnalysisParams,
  ) {
    const { accessToken } = await AnalyticsExportsApi.token(params.page.id);

    const url = `${window.location.origin}${getBasePath()}${
      routes.PUBLIC_PDF_REPORTS_INSIGHTS_COMPETITION_ANALYSIS
    }${createStandardSearch({
      startDate: params.startDate,
      endDate: params.endDate,
      page: JSON.stringify(params.page),
      showReactions: params.showReactions ? 1 : 0,
      token: accessToken,
      comparedPageIds: params.comparedPageIds,
    })}`;

    const dateRangeForFilename = formatDateRangeForFilename({
      startDate: params.startDate,
      endDate: params.endDate,
    });

    const queryParams = createStandardSearch({
      url,
      filename: `${params.page.name} - Competition analysis ${dateRangeForFilename}`,
    });

    return openBlank(`${config.SCREENSHOT_SERVICE}/pdf${queryParams}`);
  },
  async exportPDFPostsPerformance(params: ExportPdfPostsPerformanceParams) {
    const { accessToken } = await AnalyticsExportsApi.token(params.page.id);

    const url = `${window.location.origin}${getBasePath()}${
      routes.PUBLIC_PDF_REPORTS_INSIGHTS_POSTS_PERFORMANCE
    }${createStandardSearch({
      startDate: params.startDate,
      endDate: params.endDate,
      metricKey: params.metric.key,
      page: JSON.stringify(params.page),
      token: accessToken,
      types: params.types,
    })}`;

    const dateRangeForFilename = formatDateRangeForFilename({
      startDate: params.startDate,
      endDate: params.endDate,
    });

    const queryParams = createStandardSearch({
      url,
      filename: `${params.page.name} - ${params.metric.title} ${dateRangeForFilename}`,
    });

    return openBlank(`${config.SCREENSHOT_SERVICE}/pdf${queryParams}`);
  },
};

function formatDateRangeForFilename({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) {
  return `(${formatDate(startDate)}-${formatDate(endDate)})`.replace(
    /\//g,
    '.',
  );
}

export default AnalyticsExportsApi;
