import Logger from 'utils/logger';
import { Account } from 'types/Account';
import { User } from 'types/User';

declare global {
  interface Window {
    heap?: {
      identify: (userId: string) => void;
      addUserProperties: (properties: Record<string, any>) => void;
      addEventProperties: (properties: Record<string, any>) => void;
      clearEventProperties: () => void;
      track: (eventName: string) => void;
    };
  }
}

function getAccountState(account: Account) {
  if (account.isExpired) {
    return account.isTrial ? 'expired_trial' : 'churned';
  }

  return account.isTrial ? 'trial' : 'subscribed';
}

const HeapUtils = {
  identifyUser: (account: Account, user: User) => {
    try {
      if (
        typeof window.heap?.identify === 'function' &&
        typeof window.heap?.addUserProperties === 'function'
      ) {
        const userIdentification = `${account.publicId}-${user.id}`;
        const userProperties = {
          id: userIdentification,
          name: user.name,
          role: user.role,
          company: user.intercom?.company.name,
          isTrial: account.isTrial,
          isSubscription: account.isSubscription,
          usingNewAnalytics: user.intercom?.usingNewAnalytics,
          usingNewCalendar: user.intercom?.usingNewCalendar,
          planLabel: account.plan.label,
        };

        window.heap?.identify(userIdentification);
        window.heap?.addUserProperties(userProperties);
        Logger.log('Setting heap user properties: %o', userProperties);
      } else {
        throw new Error('Heap is not defined on window');
      }
    } catch (e) {
      Logger.error(e);
    }
  },
  setEventProperties: (account: Account) => {
    try {
      if (
        typeof window.heap?.addEventProperties === 'function' &&
        typeof window.heap?.clearEventProperties === 'function'
      ) {
        const accountState = getAccountState(account);
        const properties = {
          'Account state': accountState,
          'Account has profile connected': !!account.plan.pageCount,
          'Pricing plan': account.plan.label || 'none',
          'New app': 'true',
        };

        window.heap?.clearEventProperties();
        window.heap?.addEventProperties(properties);
        Logger.log('Setting heap event properties: %o', properties);
      } else {
        throw new Error('Heap is not defined on window');
      }
    } catch (e) {
      Logger.error(e);
    }
  },
  track(eventName: string) {
    try {
      if (typeof window.heap?.track !== 'function') {
        Logger.info('Track heap event: %s', eventName);
        return;
      }

      window.heap.track(eventName);
    } catch (e) {
      Logger.error(e);
    }
  },
  events: {
    AI_CONTENT_COPY_TEXT_TO_CLIPBOARD: 'ai-content_copy-text-to-clipboard',
  },
};

export default HeapUtils;
