import { FC } from 'react';
import { AssignTasksParams } from 'api/post';
import { useMutation, useQueryClient } from 'react-query';
import Logger from 'utils/logger';
import { useToast } from 'app/hooks/useToast';
import i18n from 'i18n';
import { QUERY_KEY } from 'components/adsPlanner/constants';
import PostTimeSaversApi from 'app/api/postTimeSavers';
import UserpilotUtils from 'app/utils/userpilot';
import { queryKey } from 'constants/queryKey';

type Params = {
  submitAssignTasks: (params: AssignTasksParams) => void;
  isLoading: boolean;
};

type Props = {
  children: (params: Params) => JSX.Element;
  successCallback?: AnyFunction;
};

const RequestContainer: FC<Props> = ({ children, successCallback }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { mutate: mutateAssignTasks, isLoading: isAssigningLoading } =
    useMutation(PostTimeSaversApi.assignTasks, {
      onSuccess: (data) => {
        UserpilotUtils.track(UserpilotUtils.events.ASSIGNED_TASK);
        queryClient.invalidateQueries(QUERY_KEY.ADS_FOR_AD_SET);
        queryClient.invalidateQueries(queryKey.dashboardPosts());
        queryClient.invalidateQueries(queryKey.dashboardMyTasks());
        queryClient.invalidateQueries(queryKey.dashboardTasksStatus());
        queryClient.invalidateQueries(queryKey.postsMeta());
        queryClient.invalidateQueries(queryKey.posts());
        successCallback?.();
      },
      onError: (error) => {
        toast(i18n.somethingWentWrongTryAgain, 'error');
        Logger.error(error);
      },
    });

  const handleSubmitAssignTasks = (formValues: AssignTasksParams) => {
    mutateAssignTasks({ ...formValues });
  };

  return children({
    submitAssignTasks: handleSubmitAssignTasks,
    isLoading: isAssigningLoading,
  });
};

export default RequestContainer;
