import React, { useContext } from 'react';
import { PostsState } from 'components/posts/Container';

const PostsContainerContext = React.createContext<PostsState | undefined>(
  undefined,
);

type PostsProviderProps = {
  value: PostsState;
  children: React.ReactNode;
};

export function PostsContainerProvider({
  value,
  children,
}: PostsProviderProps) {
  return (
    <PostsContainerContext.Provider value={value}>
      {children}
    </PostsContainerContext.Provider>
  );
}

export function usePostsContainer() {
  const context = useContext(PostsContainerContext);

  if (!context) {
    throw new Error('Must be used within PostsContainerProvider');
  }

  return context;
}
