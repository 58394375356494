import clsx from 'clsx';
import React, { FC, useRef } from 'react';
import style from 'app/components/progressIndicator/ProgressIndicator.module.scss';

type Props = {
  progress: number;
  color?: 'primary' | 'white';
  size?: number;
};

const INITIAL_STROKE_DASH = '282.78302001953125';

const ProgressIndicator: FC<Props> = ({
  progress,
  color = 'primary',
  size = 36,
}) => {
  const meter = useRef<SVGPathElement>(null);

  function getCurrentStrokeDash() {
    const path = meter.current;

    if (!path) {
      return INITIAL_STROKE_DASH;
    }
    const length = path.getTotalLength();
    const percentageLength = length * ((100 - progress) / 100);

    return `${Math.max(0, percentageLength)}`;
  }

  return (
    <svg
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      width={size}
      height={size}
      className={clsx(style.progress, {
        'tw-text-primary-100/25': color === 'primary',
        'tw-text-white/25': color === 'white',
      })}
    >
      <circle r="45" cx="100" cy="100" />
      <path
        ref={meter}
        className={clsx(style.meter, {
          'tw-text-primary-100': color === 'primary',
          'tw-text-white': color === 'white',
        })}
        d="M55,100a45,45 0 1,0 90,0a45,45 0 1,0 -90,0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDashoffset={getCurrentStrokeDash()}
        strokeDasharray={INITIAL_STROKE_DASH}
      />
    </svg>
  );
};

export default ProgressIndicator;
