import { get, post } from 'api/client';
import { ProfileGroupAvailableUsers } from 'types/ProfileGroup';
import { ProfileGroup } from 'types/Page';
import config from 'config';

type CreateParams = {
  name: string;
  logo: File | null | undefined;
  profilesIds: number[];
  assignedUsersIds: number[];
};

type EditParams = CreateParams & { id: number };

type SyncParams = {
  id: number;
  name: string;
  profilesIds: number[];
  assignedUsersIds: number[];
};

export type GetUserOptions = {
  availableUsers: ProfileGroupAvailableUsers;
  assignedUsers: number[];
};

const ProfileGroupApi = {
  getAll(): Promise<ApiResponse<ProfileGroup[]>> {
    return get('presets', {
      baseURL: config.API_SERVICE_ENDPOINT,
    });
  },
  async create(params: CreateParams): Promise<undefined> {
    const formData = new FormData();

    formData.append('name', params.name);

    if (params.logo) {
      formData.append('medialibrary_files_logo[0]', params.logo);
    }

    const preset: { id: number } = await post(
      'presets/ajaxCreatePreset',
      formData,
    );
    return ProfileGroupApi.sync({ ...params, id: preset.id });
  },

  async edit(params: EditParams): Promise<undefined> {
    const formData = new FormData();

    formData.append('preset_id', params.id.toString());
    formData.append('name', params.name);

    if (params.logo) {
      formData.append('medialibrary_files_logo[0]', params.logo);
    } else if (params.logo === undefined) {
      formData.append('keep_logo', '1');
    }

    await post('/presets/ajaxUpdatePreset', formData);

    return ProfileGroupApi.sync(params);
  },

  async sync(params: SyncParams): Promise<undefined> {
    const formData = new FormData();

    formData.append('preset_id', params.id.toString());
    formData.append('page_ids', params.profilesIds.join());

    await post('presets/ajaxSyncPresetPages', formData);

    const formData2 = new FormData();

    formData2.append('preset_id', params.id.toString());
    formData2.append('user_ids', params.assignedUsersIds.join());

    return post('presets/ajaxSyncPresetUsers', formData2);
  },

  get(id: number): Promise<ProfileGroup> {
    return get('presets/ajaxGetPreset', {
      params: { preset_id: id },
    });
  },

  getUserOptions(groupId?: number): Promise<GetUserOptions> {
    return get('presets/ajaxGetAllUsers', {
      params: groupId ? { preset_id: groupId } : undefined,
    });
  },

  delete(groupId: number): Promise<undefined> {
    const formData = new FormData();

    formData.append('preset_id', groupId.toString());

    return post('/presets/ajaxDeletePreset', formData);
  },
};

export default ProfileGroupApi;
