import { CommentAction } from 'app/modules/comments/types';

export const isUserCommentAction = (
  action: CommentAction | null | undefined,
) => {
  return action && ['edit', 'reply'].includes(action);
};

export const isEditCommentAction = (action: CommentAction | null) => {
  return action && ['edit'].includes(action);
};

export const isReworkCommentAction = (action: CommentAction | null) => {
  return action && ['internal_rework', 'client_rework'].includes(action);
};
