import {
  ALL_METRICS_PROPERTY,
  getMetricForPageType,
} from 'app/modules/insights/modules/postsPerformance/utils/metricProperty';
import { Page } from 'app/modules/insights/types/Page';
import { useMemo } from 'react';
import { FilterState } from 'app/modules/insights/hooks/useFilterState';
import { allPagesQuery_pages_edges_node } from 'graphql/insights/queries/__generated__/allPagesQuery';
import { MetricProperty } from '../types/MetricProperty';

export type ActiveState = {
  metric: MetricProperty;
  page: Page | undefined;
};

type UseActiveState = {
  filterState: Pick<FilterState, 'selectedMetric' | 'selectedProfiles'>;
  pages: allPagesQuery_pages_edges_node[];
};

export function useActiveState({ filterState, pages }: UseActiveState) {
  return useMemo(() => {
    const page = pages.find((page) =>
      filterState.selectedProfiles.includes(page._id),
    );

    return {
      metric:
        (page?._type
          ? getMetricForPageType(page._type, filterState.selectedMetric)
          : undefined) ?? ALL_METRICS_PROPERTY,
      page,
    };
  }, [filterState.selectedMetric, filterState.selectedProfiles, pages]);
}
