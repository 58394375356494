const PusherConfig = {
  LOG_TO_CONSOLE: process.env.REACT_APP_PUSHER_LOGGER === 'true',
  KEY: process.env.REACT_APP_PUSHER_KEY,
  CLUSTER: process.env.REACT_APP_PUSHER_CLUSTER,
  EVENT_KEYS: {
    NOTIFICATION_COUNT: 'notification-count',
  },
};

export default PusherConfig;
