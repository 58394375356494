import config from 'config';
import Logger from 'utils/logger';

const FacebookUtils = {
  init() {
    try {
      window?.FB?.init({
        appId: config.FACEBOOK_APP_ID,
        version: config.FACEBOOK_SDK_VERSION,
      });
    } catch (e) {
      Logger.error(e, 'Unable to init Facebook SDK');
    }
  },
  verifyScopes(
    grantedScopes: string | string[],
    requiredScopes: string | string[],
  ): boolean {
    const granted = Array.isArray(grantedScopes)
      ? grantedScopes
      : grantedScopes.split(',');
    const required = Array.isArray(requiredScopes)
      ? requiredScopes
      : requiredScopes.split(',');

    return required
      .map((scope) => granted.includes(scope))
      .every((isGranted) => isGranted);
  },
};

export default FacebookUtils;
