import React, { FC, useEffect, useState } from 'react';
import { PostLabel } from 'types/Post';
import { useQuery } from 'react-query';
import AdsPlannerApi from 'api/adsplanner';
import { getAdAccountLabels } from 'components/adsPlanner/utils/adAccount';

const AdsPlannerBaseDataContext = React.createContext<{
  labels: PostLabel[];
}>({
  labels: [],
});

const BaseData: FC<{
  adAccountId: number;
  children: JSX.Element;
}> = ({ adAccountId, children }) => {
  const [labels, setLabels] = useState<PostLabel[]>([]);
  const { data } = useQuery(['baseData'], () =>
    AdsPlannerApi.adAccount.baseData(),
  );

  useEffect(() => {
    if (data) {
      setLabels(getAdAccountLabels(adAccountId, data.allTags));
    }
  }, [adAccountId, data]);

  return (
    <AdsPlannerBaseDataContext.Provider
      value={{
        labels,
      }}
    >
      {children}
    </AdsPlannerBaseDataContext.Provider>
  );
};

export default BaseData;

export const useAdsPlannerBaseDataContext = () => {
  const context = React.useContext(AdsPlannerBaseDataContext);

  if (!context) {
    throw new Error('Must be used within AdsPlannerBaseDataProvider');
  }

  return context;
};
