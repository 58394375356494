import React, { FC } from 'react';
import { mergeTailwindClasses } from '@kontentino/ui';
import MedalRankBadge, {
  MedalRank,
} from 'app/modules/insights/components/MedalRankBadge';
import PostTypeIndicator from 'app/modules/insights/components/PostTypeIndicator';
import { StandardPost } from 'app/types/StandardPostPreview';
import StringUtils from 'utils/string';
import clsx from 'clsx';

type Props = {
  post: Pick<StandardPost, 'type' | 'text' | 'attachments'> & {
    page: Pick<StandardPost['page'], 'type'>;
  };
  medalRank?: MedalRank;
  className?: string;
};

const StandardPostThumbnail: FC<Props> = ({ post, medalRank, className }) => {
  return (
    <div
      className={mergeTailwindClasses(
        clsx(
          'tw-relative tw-flex tw-h-[192px] tw-w-[192px] tw-rounded-md tw-bg-grayscale-20',
          className,
        ),
      )}
    >
      {post.attachments.cards.length === 0 && post.text && (
        <div className="tw-self-end tw-break-all tw-p-2 tw-text-sm">
          {StringUtils.truncate(post.text)}
        </div>
      )}
      {post.attachments.cards.length > 0 && (
        <img
          src={
            post.attachments.cards[0].medium.image?.src ||
            post.attachments.cards[0].medium.video?.thumbSrc
          }
          alt="post-thumbnail"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
            borderRadius: 8,
          }}
        />
      )}
      {medalRank && (
        <MedalRankBadge
          className="tw-absolute tw-left-1 tw-top-1"
          rank={medalRank}
        />
      )}
      <PostTypeIndicator
        className="tw-absolute tw-right-2 tw-top-2"
        pageType={post.page.type}
        postType={post.type}
      />
    </div>
  );
};

export default StandardPostThumbnail;
