import React, { FC } from 'react';
import { getMedalRankFromIndex } from 'app/modules/insights/utils/getMedalRankFromIndex';
import StandardPostThumbnail from 'app/modules/insights/components/StandardPostThumbnail';
import { StandardPostDetailed } from 'app/types/StandardPostDetailed';
import BenchmarkBars from 'app/modules/insights/components/BenchmarkBars';
import { MetricProperty } from 'app/modules/insights/types/MetricProperty';
import PerformanceInfo from 'app/modules/insights/components/PerformanceInfo';
import ActionButtons from './ActionButtons';
import { formatDateTimeUTC } from 'app/utils/date';
import MetricsValueBoxes from 'app/modules/insights/modules/postsPerformance/components/postCollapsed/MetricsValueBoxes';
import PostCollapsedLayout, {
  PostCollapsedLayoutVariant,
} from 'app/modules/insights/modules/postsPerformance/components/postCollapsed/PostCollapsedLayout';

type Props = {
  pageType: number;
  post: StandardPostDetailed;
  metric: MetricProperty;
  index: number;
  toggleExpanded: () => void;
  expanded?: boolean;
  performanceValues: {
    benchmark: number;
    metric: number;
    max: number;
  };
  seeOnClickHandler?: () => void;
  layoutVariant?: PostCollapsedLayoutVariant;
};

const PostCollapsed: FC<Props> = ({
  post,
  index,
  pageType,
  metric,
  toggleExpanded,
  expanded,
  performanceValues,
  seeOnClickHandler,
  layoutVariant = 'grid',
}) => {
  return (
    <PostCollapsedLayout
      variant={layoutVariant}
      thumbnail={
        <StandardPostThumbnail
          post={post}
          medalRank={getMedalRankFromIndex(index)}
          className="tw-mr-4"
        />
      }
      benchmarkBars={
        <BenchmarkBars
          metricLabel={metric.title}
          metricValue={performanceValues.metric}
          metricKey={metric.key}
          metricValueTotal={performanceValues.max}
          benchmark={performanceValues.benchmark}
        />
      }
      performanceInfo={
        <PerformanceInfo
          metricValue={performanceValues.metric}
          benchmark={performanceValues.benchmark}
        />
      }
      metricsValueBoxes={<MetricsValueBoxes post={post} pageType={pageType} />}
      publishedAt={
        <div className="tw-mb-2 tw-text-md tw-font-medium tw-text-grayscale-70">
          Published {formatDateTimeUTC(post.createdAt)}
        </div>
      }
      actionButtons={
        <ActionButtons
          pageType={pageType}
          showMoreClick={toggleExpanded}
          seeOnClick={seeOnClickHandler}
          expanded={expanded}
        />
      }
    />
  );
};

export default PostCollapsed;
