import { TYPE } from '@kontentino/kontentino-constants/Pages';

export enum POST_TYPE_FB {
  STATUS = 1,
  LINK = 2,
  PHOTO = 3,
  GALLERY = 4,
  VIDEO = 5,
}

export enum POST_TYPE_IG {
  IMAGE = 1,
  VIDEO = 2,
  CAROUSEL = 3,
  STORY = 4,
  REEL = 5,
}

export enum POST_TYPE_LI {
  ARTICLE = 1,
  IMAGE = 2,
  NONE = 3,
  RICH = 4,
  VIDEO = 5,
  LEARNING_COURSE = 6,
  JOB = 7,
  QUESTION = 8,
  ANSWER = 9,
  CAROUSEL = 10,
  TOPIC = 11,
  NATIVE_DOCUMENT = 12,
  URN_REFERENCE = 13,
  LIVE_VIDEO = 14,
}

export const POST_TYPE = {
  [TYPE.FACEBOOK]: POST_TYPE_FB,
  [TYPE.INSTAGRAM]: POST_TYPE_IG,
  [TYPE.LINKEDIN]: POST_TYPE_LI,
};

export enum MEDIA_PRODUCT_TYPE_IG {
  FEED = 'feed',
  STORY = 'story',
  REEL = 'reels',
}

export const IG_MEDIA_PRODUCT_TO_TYPE_MAP: {
  [key: string]: POST_TYPE_IG;
} = {
  [MEDIA_PRODUCT_TYPE_IG.STORY]: POST_TYPE_IG.STORY,
  [MEDIA_PRODUCT_TYPE_IG.REEL]: POST_TYPE_IG.REEL,
};
