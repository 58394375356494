import { useToast } from 'app/hooks/useToast';
import { usePostCommunicationContext } from '../../PostCommunicationProvider';
import CommentsApi from 'app/modules/comments/api';
import { ApiClientError, queryClient } from 'api/client';
import { queryKey } from 'constants/queryKey';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Comment } from 'app/modules/comments/types';

type UseCommentPin = {
  toggleCommentPin: () => void;
  isLoading: boolean;
};

const useCommentPin = (comment: Comment): UseCommentPin => {
  const toast = useToast();
  const { t } = useTranslation();
  const { postId } = usePostCommunicationContext();

  const togglePin = useMutation(
    () => {
      return CommentsApi.pin({
        postId,
        comment_id: comment.id,
        pinned: !comment.is_pinned,
      });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(queryKey.postCommentsThread());
        queryClient.invalidateQueries(queryKey.postCommentsPinnedThread());
        toast(t(`commentActions.${data.is_pinned ? 'pin' : 'unpin'}.success`));
      },
      onError(e: ApiClientError) {
        toast(e?.userMessage ?? t('somethingWentWrong'), 'error');
      },
    },
  );

  return {
    toggleCommentPin: togglePin.mutate,
    isLoading: togglePin.isLoading,
  };
};

export default useCommentPin;
