import React, { useState } from 'react';
import Link from 'components/shared/Link';
import {
  NavigationItem,
  NavigationItemKey,
} from 'components/navigation/utils/libs';
import { getNavigationItemUrl } from 'components/navigation/verticalNavigation/mainMenu/MenuItems';
import { Icon } from '@kontentino/ui';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import FeatureBadge from 'app/components/FeatureBadge';
import { VerticalMenuSecondaryItemWrapper } from '../VerticalMenuSecondaryItemWrapper';
import { matchPath, useLocation } from 'react-router-dom';

type Props = {
  item: NavigationItem;
  isActive?: boolean;
};

const MenuSecondaryLink: React.FC<Props> = ({ item, isActive }) => {
  const location = useLocation();
  const matchedRoute = matchPath(location.pathname, item.routerUrl ?? '');

  const slug = `navigation-link-${item.key}`;
  const { Insights, AdsPlanner } = NavigationItemKey;
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Link
      to={getNavigationItemUrl(item)}
      isRouterLink={!!item.routerUrl}
      data-name={slug}
      data-cy={slug}
    >
      <VerticalMenuSecondaryItemWrapper
        isActiveMenu={isActive}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {item.icon && (
          <Icon
            className={clsx(
              'tw-text-grayscale-100 tw-transition-all tw-duration-100 tw-ease-in-out',
              {
                'tw-text-primary-100': !!matchedRoute || isHovered,
              },
            )}
            icon={item.icon}
            size="md"
          />
        )}
        <span
          className={clsx(
            'tw-flex tw-items-center tw-whitespace-nowrap tw-text-sm tw-font-medium tw-text-inherit',
            { 'tw-text-primary-100': isActive },
          )}
        >
          {item.label && <span className="tw-text-md">{item.label}</span>}
          {item.routerUrl &&
            (item.key === Insights || item.key === AdsPlanner) && (
              <FeatureBadge
                className={clsx('tw-ml-auto tw-mr-2', {
                  'tw-text-primary-100': isActive,
                })}
              >
                {item.key === Insights && <Icon icon={faSparkles} />}
                <span>{item.key === Insights ? 'NEW' : 'BETA'}</span>
              </FeatureBadge>
            )}
        </span>
      </VerticalMenuSecondaryItemWrapper>
    </Link>
  );
};

export default MenuSecondaryLink;
