import React, { lazy, Suspense } from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import routeConfig from 'app/config/routes';
import AdsPlanner from 'pages/AdsPlanner';
import Skeleton from 'react-loading-skeleton';
import AdsPlannerContextProvider from 'app/modules/adsPlanner/context';

const List = lazy(() => import('../../../pages/adsPlanner/List'));

export const adsPlannerRouter: RouteObject = {
  path: routeConfig.adsPlanner.path,
  element: (
    <AdsPlannerContextProvider>
      <AdsPlanner>
        <Suspense fallback={<Skeleton width="100%" height="100%" />}>
          <Outlet />
        </Suspense>
      </AdsPlanner>
    </AdsPlannerContextProvider>
  ),
  children: [
    {
      index: true,
      element: <Navigate to={routeConfig.adsPlanner.routes.list.path} />,
    },
    {
      path: routeConfig.adsPlanner.routes.list.path,
      element: (
        <Suspense fallback={<Skeleton width="100%" height="100%" />}>
          <List />
        </Suspense>
      ),
    },
  ],
};
