import { FC } from 'react';
import { getQueryParameter } from 'utils/url';
import { ApolloProvider } from '@apollo/client';
import ExportsSwitch from 'components/analyticsPdfExports/ExportsSwitch';
import { createInsightsApolloClient } from 'app/modules/insights/utils/createAuthorizedInsightsApolloClient';

type Props = {};

const AnalyticsPdfExports: FC<Props> = () => {
  const client = createInsightsApolloClient(getQueryParameter('token'));

  return (
    <ApolloProvider client={client}>
      <ExportsSwitch />
    </ApolloProvider>
  );
};

export default AnalyticsPdfExports;
