import { gql } from '@apollo/client';

export const IG_POSTS_QUERY_NEW = gql`
  query igPostsQueryNew(
    $pageId: String!
    $first: Int
    $after: String
    $startDate: String!
    $endDate: String!
    $orderBy: [InstagramPostsOrder]
    $types: [Int]
    $mediaProductTypes: [String]
  ) {
    posts: instagramPosts(
      instagramPageId: $pageId
      first: $first
      after: $after
      dateFrom: $startDate
      dateTo: $endDate
      orderBy: $orderBy
      types: $types
      mediaProductTypes: $mediaProductTypes
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          _id
          picture
          video
          createdTime
          type
          permalink
          videoTitle
          mediaProductType
          message
          children {
            type
            picture
            video
            _id
          }
          metrics {
            edges {
              node {
                id
                metricName
                metricValue
              }
            }
          }
        }
      }
    }
  }
`;
