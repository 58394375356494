import {
  IMAGE_FILE_EXTENSIONS,
  IMAGE_FILE_MIME_TYPES,
  VIDEO_FILE_EXTENSIONS,
  VIDEO_FILE_MIME_TYPES,
} from '@kontentino/kontentino-constants/Files';
import { MediaLibraryItemFileType } from 'types/MediaLibrary';

export const MediaUtils = {
  getAcceptedVideoImageAttachments() {
    const EXTENSIONS = [
      ...IMAGE_FILE_EXTENSIONS,
      ...VIDEO_FILE_EXTENSIONS,
    ] as string[];

    return {
      accept: {
        ...this.getAcceptFromExtensions('image', IMAGE_FILE_EXTENSIONS),
        ...this.getAcceptFromExtensions('video', VIDEO_FILE_EXTENSIONS),
      },
      mimeTypes: EXTENSIONS,
      extensions: EXTENSIONS,
      label: this.getLabelFromExtensions(EXTENSIONS),
    };
  },
  getAcceptedVideoAttachments() {
    return {
      accept: this.getAcceptFromExtensions('video', VIDEO_FILE_EXTENSIONS),
      mimeTypes: VIDEO_FILE_MIME_TYPES as string[],
      extensions: VIDEO_FILE_EXTENSIONS as string[],
      label: this.getLabelFromExtensions(VIDEO_FILE_EXTENSIONS),
    };
  },
  getAcceptedImageAttachments() {
    return {
      accept: this.getAcceptFromExtensions('image', IMAGE_FILE_EXTENSIONS),
      mimeTypes: IMAGE_FILE_MIME_TYPES as string[],
      extensions: IMAGE_FILE_EXTENSIONS as string[],
      label: this.getLabelFromExtensions(IMAGE_FILE_EXTENSIONS),
    };
  },
  getAcceptedAttachmentsByFileType: (fileTypes: MediaLibraryItemFileType[]) => {
    if (fileTypes.includes('video') && !fileTypes.includes('image')) {
      return MediaUtils.getAcceptedVideoAttachments();
    }

    if (fileTypes.includes('image') && !fileTypes.includes('video')) {
      return MediaUtils.getAcceptedImageAttachments();
    }

    return MediaUtils.getAcceptedVideoImageAttachments();
  },
  getAcceptFromExtensions(mime: 'image' | 'video', extensions: string[]) {
    if (extensions.length === 0) {
      return {
        [`${mime}/*`]: [],
      };
    }

    return {
      [`${mime}/${extensions[0]}`]: extensions.map((ext) => `.${ext}`),
    };
  },
  getLabelFromExtensions(extensions: string[]) {
    return extensions.map((ext) => `.${ext}`).join(', ');
  },
};

export default MediaUtils;
