import { gql } from '@apollo/client';

export const FB_PAGE_STATISTICS = gql`
  query fbPageStatistics($id: String!, $dateFrom: String!, $dateTo: String!) {
    facebookPage(id: $id) {
      id
      statistics(dateFrom: $dateFrom, dateTo: $dateTo) {
        totalReachUnique
        postImpressionsUnique
        postImpressions
        totalReach
        reactions
        comments
        shares
        postClicks
        engagement
        engagedUsers
        engagementRate
        videoAverageTimeWatched
        videoViews
        videoCompleteViewsOrganic
        videoCompleteViewsPaid
      }
    }
  }
`;

export const IG_PAGE_STATISTICS = gql`
  query igPageStatistics($id: String!, $dateFrom: String!, $dateTo: String!) {
    instagramPage(id: $id) {
      id
      statistics(dateFrom: $dateFrom, dateTo: $dateTo) {
        reach
        impressions
        engagement
        likes
        comments
        saved
        videoViews
        replies
        exits
        tapsBack
        tapsForward
      }
    }
  }
`;

export const LI_PAGE_STATISTICS = gql`
  query liPageStatistics($id: String!, $dateFrom: String!, $dateTo: String!) {
    linkedInPage(id: $id) {
      id
      statistics(dateFrom: $dateFrom, dateTo: $dateTo) {
        reactions
        comments
        shares
        postImpressions
        postClicks
        engagement
        engagementRate
        videoViews
        viewers
        timeWatched
      }
    }
  }
`;
