import { FC } from 'react';

type ReferralRewardProps = {
  img: {
    src: string;
    alt: string;
  };
  price: string;
  label: string;
};

const ReferralReward: FC<ReferralRewardProps> = ({ img, price, label }) => {
  return (
    <div className="tw-inline-flex tw-items-center tw-gap-4">
      <div className="tw-flex tw-justify-center">
        <img src={img.src} alt={img.alt} width={40} height={40} />
      </div>
      <div className="tw-inline-flex tw-gap-1">
        <div className="tw-text-md">
          <strong>{price}&nbsp;</strong>
          {label}
        </div>
      </div>
    </div>
  );
};

export default ReferralReward;
