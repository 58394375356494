import { FC } from 'react';
import { PageNumberType, TYPE } from '@kontentino/kontentino-constants/Pages';
import FacebookPostRowHeader from './postRowHeader/FacebookPostRowHeader';
import InstagramPostRowHeader from './postRowHeader/InstagramPostRowHeader';

type Props = {
  pageType: PageNumberType;
  canShowReactions: boolean;
};

const PostRowHeader: FC<Props> = ({ pageType, canShowReactions }) => {
  switch (pageType) {
    case TYPE.FACEBOOK:
      return <FacebookPostRowHeader canShowReactions={canShowReactions} />;
    case TYPE.INSTAGRAM:
      return <InstagramPostRowHeader />;
    default:
      return null;
  }
};

export default PostRowHeader;
