import React, { FC } from 'react';
import NumberUtil from 'utils/number';
import { mergeTailwindClasses } from '@kontentino/ui';
import clsx from 'clsx';

type Props = {
  metricValue: number;
  benchmark: number;
};

const performanceProps = {
  positive: {
    prefix: '+',
  },
  negative: {
    prefix: '-',
  },
  neutral: {
    prefix: '',
  },
};

const PerformanceInfo: FC<Props> = ({ metricValue, benchmark }) => {
  const performance =
    benchmark !== 0
      ? NumberUtil.roundToDecimals(
          Math.abs((metricValue / benchmark) * 100 - 100),
        )
      : 0;

  const performanceProp =
    metricValue < benchmark
      ? performanceProps.negative
      : metricValue > benchmark
      ? performanceProps.positive
      : performanceProps.neutral;

  return (
    <div className="tw-flex tw-max-h-[56px] tw-flex-col">
      <div className="tw-mb-2 tw-text-md tw-font-medium tw-text-grayscale-100">
        Performance vs Benchmark
      </div>
      <div
        className={mergeTailwindClasses(
          clsx('tw-text-xl tw-font-bold tw-text-grayscale-100', {
            'tw-text-danger-100': metricValue < benchmark,
            'tw-text-status-7-primary': metricValue > benchmark,
          }),
        )}
      >
        {performanceProp.prefix} {performance} %
      </div>
    </div>
  );
};

export default PerformanceInfo;
