import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { Icon } from '@kontentino/ui';
import { ActionContext, createAction } from 'actions';
import i18n from 'app/utils/i18n';
import { modalRoutes } from 'constants/routes';
import { UserPermission } from 'constants/userPermission';
import { PostDetail } from 'types/PostDetail';

export const deletePost = (context: ActionContext) =>
  createAction(
    {
      id: 'delete-post',
      label: i18n.t('delete'),
      icon: <Icon icon={faTrash} />,
      scopes: UserPermission.POST_DELETE,
      perform: ({ id }: { id: PostDetail['id'] }) => {
        context.modalHistory.push({
          hash: modalRoutes.DELETE_POST,
          search: `?id=${id}`,
        });
      },
    },
    context,
  );
