import { create } from 'zustand';
import { useInsightsContainer } from 'components/insights/context';
import { useEffect } from 'react';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import { CompetitionAnalysisPage } from 'app/modules/insights/modules/competitionAnalysis/types/page';

export type CompetitionAnalysisState = {
  selectedPages: CompetitionAnalysisPage[];
  setSelectedPages: (pages: CompetitionAnalysisPage[]) => void;
  onTogglePage: (page: CompetitionAnalysisPage) => void;
  showReactions: boolean;
  onToggleShowReactions: () => void;
};

const useCompetitionAnalysisState = create<CompetitionAnalysisState>(
  (set, get) => ({
    selectedPages: [],
    showReactions: false,
    onToggleShowReactions: () => {
      set({ showReactions: !get().showReactions });
    },
    setSelectedPages: (pages: CompetitionAnalysisPage[]) =>
      set({ selectedPages: pages }),
    onTogglePage: (page) => {
      const { selectedPages } = get();
      const selectedPagesIds = selectedPages.map((page) => page._id);

      set({
        selectedPages: selectedPagesIds.includes(page._id)
          ? selectedPages.filter(({ _id }) => _id !== page._id)
          : [...selectedPages, page],
      });
    },
  }),
);

export const useCompetitionAnalysis = () => {
  const {
    activeState: { page },
  } = useInsightsContainer();
  const competitionAnalysisState = useCompetitionAnalysisState();

  useEffect(() => {
    if (page?._id) {
      const activePage = {
        _id: page._type === TYPE.INSTAGRAM ? page.username ?? '' : page._id,
        name: page.name ?? '',
        _type: page._type,
      };
      competitionAnalysisState.setSelectedPages([activePage]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page?._id]);

  return competitionAnalysisState;
};
