import { QUERY_KEY } from 'components/adsPlanner/constants';
import dayjs from 'dayjs';
import DateUtils from 'app/utils/date';
import { useAdsPlannerContainer } from 'components/adsPlanner/context';
import { useAdAccountSelectionContext } from 'components/adsPlanner/adAccount/AdAccountSelection';

const useCampaignsQueryKey = () => {
  const { selectedAdAccount } = useAdAccountSelectionContext();
  const { filters } = useAdsPlannerContainer();

  return [
    QUERY_KEY.CAMPAIGNS,
    {
      adAccountId: selectedAdAccount?.id,
      orderDirection: filters.orderDirection,
      startDate: DateUtils.toDateString(dayjs(filters.startDate)),
      endDate: DateUtils.toDateString(dayjs(filters.endDate)),
      selectedLabels: filters.selectedLabels,
      selectedWorkflowStatuses: filters.selectedWorkflowStatuses,
      selectedEffectiveStatuses: filters.selectedEffectiveStatuses,
    },
  ];
};

export default useCampaignsQueryKey;
