import React, {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ModalManager } from 'context/ModalRouter';

const ModalContext = createContext<ModalContextValue | undefined>(undefined);

export type ModalContextValue = {
  onEscKeyDown: (event: KeyboardEvent) => void;
  container: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  onAnimationEnd?: (() => void) | undefined;
  isLoading?: boolean;
  isContentHeightAutoResizable?: boolean;
  dataName?: {
    wrapper?: string;
    closeButton?: string;
  };
  dataCy?: {
    wrapper?: string;
    closeButton?: string;
  };
};

export type ModalProviderProps = {
  modalManager?: ModalManager;
  children: ReactNode;
  open?: boolean;
  onClose?: () => void;
  onEscKeyDown?: (event: KeyboardEvent) => void;
} & Pick<
  ModalContextValue,
  'dataName' | 'dataCy' | 'isContentHeightAutoResizable' | 'isLoading'
>;

export const ModalProvider: FC<ModalProviderProps> = ({
  open = true,
  onClose = () => {},
  isLoading = false,
  isContentHeightAutoResizable = true,
  dataName,
  dataCy,
  ...props
}) => {
  const [modalRoot] = useState(() => document.getElementById('modal-root'));
  const mutableKeyDown = useRef<(e: KeyboardEvent) => void>(() => {});

  function getContextValue() {
    const commonValues: Omit<ModalContextValue, 'open' | 'onClose'> = {
      onEscKeyDown: (event: KeyboardEvent) => mutableKeyDown.current(event),
      container: modalRoot,
      isLoading,
      isContentHeightAutoResizable: isContentHeightAutoResizable,
      dataCy,
      dataName,
    };

    if (props.modalManager) {
      return {
        open: props.modalManager.open,
        onClose: () => {
          onClose();
          props.modalManager?.close();
        },
        onAnimationEnd: props.modalManager.open
          ? undefined
          : props.modalManager.destroy,
        ...commonValues,
      };
    }

    return {
      open,
      onClose,
      ...commonValues,
    };
  }

  useEffect(function assignMutableKeyDown() {
    function handleEscKeyDown() {
      const canClose = [
        document.getElementById('SRLLightbox'),
        document.getElementById('filerobot-image-editor-root'),
        document.querySelector('.lg-container.lg-show'),
      ].every((priorityElement) => !priorityElement);

      if (canClose) {
        contextValue.onClose();
      }
    }

    if (props.onEscKeyDown) {
      mutableKeyDown.current = props.onEscKeyDown;
    } else {
      mutableKeyDown.current = handleEscKeyDown;
    }
  });

  const contextValue = getContextValue();

  return (
    <ModalContext.Provider value={contextValue}>
      {props.children}
    </ModalContext.Provider>
  );
};

export function useModalContext() {
  const context = React.useContext(ModalContext);

  if (!context) {
    throw new Error('useModalContext must be used within a ModalProvider');
  }

  return context;
}
