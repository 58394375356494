import { copyTextToClipboard } from '@kontentino/kontentino-utils/clipboard';
import i18n from 'i18n';
import { showToast } from 'app/hooks/useToast';

export async function copyToClipboard(
  text: string,
  options?: { message?: string },
) {
  try {
    await copyTextToClipboard(text);
    showToast(options?.message ?? i18n.copiedToClipboard, 'success');
  } catch (e) {
    showToast(i18n.somethingWentWrong, 'error');
  }
}

const useCopyToClipboard = () => copyToClipboard;

export default useCopyToClipboard;
