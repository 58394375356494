import React, { FC } from 'react';
import { Button, ButtonProps, Icon } from '@kontentino/ui';
import { faCheck, faClose } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';

type Props = {
  thread: 'client' | 'internal';
  icon: 'check' | 'close';
  className?: string;
} & Omit<ButtonProps, 'size'>;

const CommentWorkflowButton: FC<Props> = ({
  children,
  thread,
  icon,
  className,
  variant,
  ...rest
}) => {
  return (
    <Button
      size="large"
      variant={variant}
      iconBefore={<Icon icon={icon === 'check' ? faCheck : faClose} />}
      data-cy={`post-comment-${thread}-${variant}-button`}
      data-name={`post-comment-${thread}-${variant}-button`}
      className={clsx('tw-flex-1 tw-whitespace-nowrap', className)}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default CommentWorkflowButton;
