import React, { FC, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { getRouteName } from 'app/utils/routes';

type Props = {
  title?: string;
};

const Head: FC<Props> = ({ title }) => {
  const location = useLocation();
  const routeName = useMemo(
    () => getRouteName(location.pathname),
    [location.pathname],
  );

  return (
    <Helmet>
      <title translate="no">{`${routeName}${
        title ? ' | ' + title : ''
      }`}</title>
    </Helmet>
  );
};

export default Head;
