import { Button } from '@kontentino/ui';
import BaseSubscriptionModal from 'app/components/subscriptionModals/BaseSubscriptionModal';
import insightsIcon from 'app/images/insightsModalGreet.svg';
import IntercomUtils from 'app/utils/intercom';
import { FC } from 'react';

type Props = {
  open: boolean;
  onStartTrialNow(): void;
};

const InsightsTrialStart: FC<Props> = ({ open, onStartTrialNow }) => {
  return (
    <BaseSubscriptionModal
      open={open}
      title={
        <div className="tw-text-xl tw-font-medium">
          Enjoy a <strong>free</strong> trial of <strong>Analytics</strong>
        </div>
      }
      description={
        <div className="tw-text-center tw-text-md ">
          Explore more of Kontentino with a{' '}
          <span className="tw-font-bold">10-day free trial of Analytics</span>
          {'. '}
          <br />
          🥳 You’ll go deep into the performance of your content, with different
          ways to visualize and share your data.
        </div>
      }
      image={<img src={insightsIcon} alt="Analytics icon" />}
      buttons={
        <>
          <Button onClick={onStartTrialNow} className="tw-w-full">
            Start trial now
          </Button>

          <Button
            variant={'secondary'}
            onClick={() =>
              IntercomUtils.newConversation('Hello! Just having a question')
            }
            className="tw-w-full"
          >
            Contact us
          </Button>
        </>
      }
    />
  );
};

export default InsightsTrialStart;
