import { faClipboardList } from '@fortawesome/pro-regular-svg-icons';
import { ActivityIndicator, Button, Icon } from '@kontentino/ui';
import useCopyToClipboard from 'app/hooks/useCopyToClipboard';
import { FC } from 'react';
import clsx from 'clsx';

type Props = {
  value: string;
  isLoading: boolean;
};

const CopyToClipboard: FC<Props> = ({ value, isLoading }) => {
  const copyValueToClipboard = useCopyToClipboard();

  return (
    <>
      <div className="tw-flex tw-items-center tw-justify-between tw-rounded-sm tw-border tw-border-solid tw-border-grayscale-30 tw-px-2 tw-py-2">
        {isLoading && <ActivityIndicator className="tw-h-7 tw-w-7" />}

        <div
          className="tw-text-md"
          data-cy="referral-program-modal-copy-to-clipboard-value"
        >
          {value}
        </div>
        <div
          onClick={() => copyValueToClipboard(value)}
          className={clsx(
            'tw-flex tw-cursor-pointer tw-items-center tw-justify-center',
            {
              'tw-pointer-events-none tw-cursor-not-allowed tw-opacity-40':
                isLoading,
            },
          )}
        >
          <Icon
            icon={faClipboardList}
            className="tw-text-grayscale-90 hover:tw-text-primary-100"
          />
        </div>
      </div>
      <Button
        variant="primary"
        iconBefore={<Icon icon={faClipboardList} />}
        className="tw-mt-6 tw-w-full"
        onClick={() => copyValueToClipboard(value)}
        disabled={isLoading}
        data-cy="referral-program-modal-btn-copy-to-clipboard"
      >
        Copy to clipboard
      </Button>
    </>
  );
};
export default CopyToClipboard;
