import type {
  Order,
  Pagination,
  Filter,
  CampaignPOSTApiResponse,
  RemoteCampaignsApiResponse,
  Campaign,
  BudgetManagementParams,
} from 'types/Campaign';
import { get, httpDelete, post } from 'api/client';
import env from 'config';
import type { Objective } from 'types/Ad';
import { DEFAULT_TIMEOUT } from 'constants/request';
import { CampaignKPIMutationVariables } from 'components/adsPlanner/hooks/useCampaignKPIGoalMutation';

export const campaign = {
  get: (id: number): Promise<Campaign> => {
    return get(`ads-planner/campaign/${id}`, {
      baseURL: env.API_SERVICE_ENDPOINT,
    });
  },
  campaigns: (
    adAccountId: number,
    pagination?: Pagination,
    order?: Order,
    filter?: Filter,
  ): Promise<Omit<ApiResponse, 'data'> & { data: Campaign[] }> => {
    return get('ads-planner/campaign', {
      baseURL: env.API_SERVICE_ENDPOINT,
      params: {
        adAccountId,
        limit: pagination?.limit,
        offset: pagination?.offset,
        orderBy: order?.orderBy,
        orderDirection: order?.orderDirection,
        start_date: filter?.startDate,
        end_date: filter?.endDate,
        tags: filter?.tags.length ? filter.tags.join() : [],
        workflow_statuses: filter?.workflowStatuses.length
          ? filter?.workflowStatuses.join()
          : [],
        effective_statuses: filter?.effectiveStatuses.length
          ? filter.effectiveStatuses.join()
          : [],
        objectiveIds: filter?.objectives.length ? filter.objectives.join() : [],
      },
    });
  },
  datelessCampaigns: (
    adAccountId: number,
    filter?: Pick<Filter, 'objectives'>,
  ): Promise<Omit<ApiResponse, 'data'> & { data: Campaign[] }> => {
    return get('ads-planner/campaign/dateless', {
      baseURL: env.API_SERVICE_ENDPOINT,
      params: {
        adAccountId,
        objectiveIds: filter?.objectives.length ? filter.objectives.join() : [],
      },
    });
  },
  create: (params: {
    adAccountId: number;
    name: string;
    objective: Objective;
    budgetManagement?: BudgetManagementParams;
  }): Promise<CampaignPOSTApiResponse> => {
    const campaignInfoParams = {
      name: params.name,
      objective: params.objective,
    };

    return post(
      'ads-planner/campaign',
      {
        ...campaignInfoParams,
        ...params.budgetManagement,
      },
      {
        baseURL: env.API_SERVICE_ENDPOINT,
        params: {
          adAccountId: params.adAccountId,
        },
      },
    );
  },
  edit: (params: {
    id: number;
    name: string;
    objective: Objective;
    budgetManagement?: BudgetManagementParams;
  }): Promise<CampaignPOSTApiResponse> => {
    const campaignInfoParams = {
      name: params.name,
      objective: params.objective,
    };

    return post(
      `ads-planner/campaign/${params.id}`,
      {
        ...campaignInfoParams,
        ...params.budgetManagement,
      },
      {
        baseURL: env.API_SERVICE_ENDPOINT,
      },
    );
  },
  delete: (id: number): Promise<[]> => {
    return httpDelete(`ads-planner/campaign/${id}`, {
      baseURL: env.API_SERVICE_ENDPOINT,
    });
  },
  remoteCampaigns: (
    adAccountId: number,
  ): Promise<RemoteCampaignsApiResponse> => {
    return get('ads-planner/campaign/available-on-remote', {
      params: { adAccountId },
      baseURL: env.API_SERVICE_ENDPOINT,
    });
  },
  sync: (params: {
    adAccountId: number;
    selectedCampaigns: string[];
  }): Promise<any> => {
    // TODO return type
    return post(
      'ads-planner/campaign/syncSelected',
      {
        selectedCampaigns: params.selectedCampaigns.join(),
      },
      {
        baseURL: env.API_SERVICE_ENDPOINT,
        params: {
          adAccountId: params.adAccountId,
        },
        timeout: DEFAULT_TIMEOUT * 5,
      },
    );
  },
  duplicate: (params: {
    adAccountId: number;
    campaigns: number[];
  }): Promise<Omit<ApiResponse, 'data'> & { data: Campaign[] }> => {
    return post('ads-planner/campaign/duplicate', null, {
      baseURL: env.API_SERVICE_ENDPOINT,
      params: {
        adAccountId: params.adAccountId,
        campaigns: params.campaigns.join(),
      },
    });
  },
  setKPIGoal: (
    params: CampaignKPIMutationVariables,
  ): Promise<Promise<Omit<ApiResponse, 'data'> & { data: Campaign }>> => {
    return post('ads-planner/campaign/set-goal', null, {
      baseURL: env.API_SERVICE_ENDPOINT,
      params,
    });
  },
};
