import { get, post } from 'api/client';
import env from 'config';
import dayjs from 'dayjs';
import { InAppNotification, NotificationCount } from 'types/InAppNotification';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const InAppNotificationsApi = {
  getUnseenCount: (): Promise<NotificationCount> => {
    return get('me/notifications/count', {
      baseURL: env.NOTIFICATIONS_API_ENDPOINT,
    });
  },
  get: (page: number = 1): Promise<Array<InAppNotification>> => {
    return get('me/notifications', {
      baseURL: env.NOTIFICATIONS_API_ENDPOINT,
      params: { page },
    });
  },
  markAllAsRead: (): Promise<{ status: boolean }> => {
    return post(
      'me/notifications/mark-all-as-read',
      {
        until: dayjs().utc().unix(),
      },
      {
        baseURL: env.NOTIFICATIONS_API_ENDPOINT,
      },
    );
  },
  toggleRead: (id: number, read: boolean): Promise<{ status: boolean }> => {
    if (!read) {
      return post(`notifications/${id}/mark-as-read`, null, {
        baseURL: env.NOTIFICATIONS_API_ENDPOINT,
      });
    }

    return post(`notifications/${id}/mark-as-unread`, null, {
      baseURL: env.NOTIFICATIONS_API_ENDPOINT,
    });
  },
  markAllAsSeen: (): Promise<{ status: boolean }> => {
    return post(
      'me/notifications/mark-all-as-seen',
      {
        until: dayjs().utc().unix(),
      },
      {
        baseURL: env.NOTIFICATIONS_API_ENDPOINT,
      },
    );
  },
};

export default InAppNotificationsApi;
