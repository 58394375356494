import React, { ComponentProps, FC } from 'react';
import clsx from 'clsx';
import { HyperText } from '@kontentino/kontentino-post-previews';

type Props = {
  isCurrentUser: boolean;
  mentionedUsers: {
    id: number;
    name: string;
    surname: string;
    role?: string;
  }[];
  colorVariant: 'primary' | 'purple';
  className?: string;
  disableMentionStyle?: boolean;
  children: string;
  options?: ComponentProps<typeof HyperText>['options'];
};

const CommentsHyperText: FC<Props> = ({
  colorVariant,
  children,
  isCurrentUser,
  options,
  mentionedUsers,
  disableMentionStyle,
}) => {
  return (
    <HyperText
      options={{
        addAttributes: disableMentionStyle
          ? undefined
          : ({ type, data }) => {
              if (type === 'link') {
                return {
                  class: clsx({
                    '!tw-text-primary-20':
                      isCurrentUser && colorVariant === 'primary',
                    '!tw-text-purple-20 ':
                      isCurrentUser && colorVariant === 'purple',
                    '!tw-text-primary-90':
                      !isCurrentUser && colorVariant === 'primary',
                    '!tw-text-purple-100 ':
                      !isCurrentUser && colorVariant === 'purple',
                  }),
                };
              }

              if (type === 'user-mention') {
                const isPurple = colorVariant === 'purple';

                return {
                  class: clsx('tw-rounded-sm tw-px-1', {
                    '!tw-bg-purple-100 !tw-text-white [&>a]:!tw-text-white':
                      isCurrentUser && isPurple,
                    '!tw-bg-primary-100 !tw-text-white [&>a]:!tw-text-white':
                      isCurrentUser && !isPurple,
                    '!tw-bg-primary-10 !tw-text-primary-100 [&>a]:!tw-text-primary-100':
                      !isCurrentUser && !isPurple,
                    '!tw-bg-purple-20 !tw-text-purple-100 [&>a]:!tw-text-purple-100':
                      !isCurrentUser && isPurple,
                  }),
                };
              }
            },
        mentionedUsers:
          mentionedUsers?.map((user) => ({
            id: user.id,
            name: `${user.name}${!!user.surname ? ` ${user.surname}` : ''}`,
            role: user.role,
          })) ?? [],
        ...options,
      }}
      className="[&>a]:tw-whitespace-normal [&>a]:tw-break-all [&>strong]:tw-font-regular"
    >
      {children}
    </HyperText>
  );
};

export default CommentsHyperText;
