import { Action, ActionContext } from 'actions';
import { deletePost } from './deletePost';
import { movePost } from './movePost';
import { downloadPostAssets } from './downloadPostAssets';
import { changePostStatus } from './changePostStatus';
import { sharePostExternally } from './sharePostExternally';
import { duplicatePost } from './duplicatePost';
import { copyPostLink } from './copyPostLink';
import { editPost } from './editPost';
import { copyPostRevisionLink } from './copyPostRevisionLink';

export const postActions = (context: ActionContext) =>
  ({
    duplicate: duplicatePost(context),
    copyLink: copyPostLink(context),
    copyPostRevisionLink: copyPostRevisionLink(context),
    edit: editPost(context),
    shareExternally: sharePostExternally(context),
    move: movePost(context),
    changeStatus: changePostStatus(context),
    delete: deletePost(context),
    downloadAssets: downloadPostAssets(context),
  } satisfies Record<string, Action>);
