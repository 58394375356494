import React, { FC } from 'react';
import { Page } from 'app/modules/insights/types/Page';
import ProfileSelectorOption from 'app/components/profileSelector/components/ProfileSelectorOption';
import ProfileSelectorInfoMessage from 'app/components/profileSelector/components/ProfileSelectorInfoMessage';
import PageTypeUtils from 'app/utils/pageType';
import { PageNumberType } from '@kontentino/kontentino-constants/Pages';

type Props = {
  pages: Page[];
  selectedPageId: string;
  onSelectPage: (id: string) => void;
  onEditProfileClick: (pageType: number) => void;
};

const InsightsPageSelectorOptions: FC<Props> = ({
  pages,
  selectedPageId,
  onSelectPage,
  onEditProfileClick,
}) => {
  if (pages.length === 0) {
    return <ProfileSelectorInfoMessage message="No profiles found" />;
  }

  return (
    <>
      {pages.map((page) => (
        <ProfileSelectorOption
          isSingleSelect
          dataName={`insights-page-select-${PageTypeUtils.getName(
            page._type as PageNumberType,
          )}`}
          key={`page-option-${page.id}`}
          dataCy="page-selector-page-item"
          option={{
            label: page.name ?? '',
            socialMediaType: page._type,
            logoSrc: page.picture ?? undefined,
          }}
          eventHandlers={{
            onClick: () => onSelectPage(page._id),
            onEditClick: () => onEditProfileClick(page._type),
          }}
          isChecked={selectedPageId === page._id}
        />
      ))}
    </>
  );
};

export default InsightsPageSelectorOptions;
