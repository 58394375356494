import { useMemo } from 'react';
import { ListPost, PostDetail, PostDetailWithDate } from 'types/PostDetail';

export type SegmentedPosts = {
  groupedByDate: { [key: string]: ListPost[] };
  postsWithDate: PostDetailWithDate[];
  postIdeas: ListPost[];
  allPosts: ListPost[];
};

function getSegmentedPosts(posts: ListPost[]): SegmentedPosts {
  const groupedByDate: { [key: string]: ListPost[] } = {};
  const postsWithDate: PostDetailWithDate[] = [];
  const postIdeas: ListPost[] = [];

  posts.forEach((post) => {
    if (post.date !== null) {
      postsWithDate.push(post as PostDetailWithDate);

      if (Array.isArray(groupedByDate[post.date])) {
        groupedByDate[post.date].push(post);
      } else {
        groupedByDate[post.date] = [post];
      }
    } else {
      postIdeas.push(post);
    }
  });

  return {
    groupedByDate,
    postsWithDate,
    postIdeas,
    allPosts: posts,
  };
}

export const useSegmentedPosts = (posts: PostDetail[]) => {
  return useMemo(() => getSegmentedPosts(posts), [posts]);
};
