import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';

type Props = {
  children: ReactNode;
  columns: number;
  gap: number;
  className?: string;
};

const SimpleGrid: FC<Props> = ({ children, columns, className, gap }) => {
  return (
    <div
      style={{
        gridTemplateColumns: `repeat(${columns}, minmax(0px,100%))`,
        gap,
      }}
      className={clsx('tw-grid', className)}
    >
      {children}
    </div>
  );
};

export default SimpleGrid;
