import React, { FC, ReactNode } from 'react';
import CommentsListItemContent from './CommentsListItemContent';
import { useTranslation } from 'react-i18next';
import CommentsListItemBubbleWrapper from './CommentsListItemBubbleWrapper';
import CommentsListItemReplyBubbleWrapper from './CommentsListItemReplyBubbleWrapper';
import clsx from 'clsx';
import { Comment } from 'app/modules/comments/types';

type Props = {
  children: ReactNode;
  comment: Comment;
  colorVariant: 'primary' | 'purple';
};

const CommentsListItemBubble: FC<Props> = ({
  children,
  comment,
  colorVariant,
}) => {
  const { t } = useTranslation();
  const { user } = comment;
  const parentComment = comment.parent;

  return (
    <div
      className={clsx('tw-flex tw-flex-col', {
        'tw-items-end': user.its_me,
        'tw-items-start': !user.its_me,
      })}
    >
      {parentComment && (
        <CommentsListItemReplyBubbleWrapper>
          <CommentsListItemContent
            disableMentionStyle
            comment={parentComment}
            textOptions={{
              maxLength: 140,
              ellipsis: false,
              expandable: true,
              expandableTrigger: {
                more: (
                  <span className="tw-cursor-pointer tw-lowercase tw-text-grayscale-50">
                    {`...${t('showMore')}`}
                  </span>
                ),
                less: (
                  <span className="tw-cursor-pointer tw-lowercase tw-text-grayscale-50">
                    {`...${t('showLess')}`}
                  </span>
                ),
              },
            }}
          />
        </CommentsListItemReplyBubbleWrapper>
      )}
      <CommentsListItemBubbleWrapper
        isCurrent={user.its_me}
        colorVariant={colorVariant}
        isReplyBubble={!!parentComment}
      >
        {children}
      </CommentsListItemBubbleWrapper>
    </div>
  );
};

export default CommentsListItemBubble;
