import React, { ChangeEvent, FC } from 'react';
import { Field, Icon, IconButton, TextInput } from '@kontentino/ui';
import { SimpleDatePickerHeaderProps } from 'app/components/simpleDatePicker/types';
import dayjs from 'dayjs';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import DateUtils from 'app/utils/date';

type SimpleDatePickerHeaderCustomPeriodProps = {
  startDate?: Date | null;
  endDate?: Date | null;
  onStartDateChange?: (date: Date | null) => void;
  onEndDateChange?: (date: Date | null) => void;
  customDateValidState: {
    startDate: boolean;
    endDate: boolean;
  };
} & SimpleDatePickerHeaderProps;

const SimpleDatePickerHeaderCustomPeriod: FC<
  SimpleDatePickerHeaderCustomPeriodProps
> = ({
  decreaseMonth,
  increaseMonth,
  date,
  minDate,
  maxDate,
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  customDateValidState,
}) => {
  const { t } = useTranslation();
  const year = date.getFullYear();

  const isNextMonthDisabled = maxDate
    ? dayjs(date).add(1, 'month').isAfter(maxDate)
    : false;
  const isPreviousMonthDisabled = minDate
    ? dayjs(date).subtract(1, 'month').isBefore(minDate)
    : false;

  const handleEndDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      if (dayjs(new Date(e.target.value)).isBefore(dayjs(startDate))) {
        onStartDateChange?.(new Date(e.target.value));
        onEndDateChange?.(null);
      } else {
        onEndDateChange?.(new Date(e.target.value));
      }
    } else {
      onEndDateChange?.(null);
    }
  };

  const handleStartDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      if (dayjs(new Date(e.target.value)).isAfter(dayjs(endDate))) {
        onStartDateChange?.(new Date(e.target.value));
        onEndDateChange?.(null);
      } else {
        onStartDateChange?.(new Date(e.target.value));
      }
    } else {
      onStartDateChange?.(null);
      onEndDateChange?.(null);
    }
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-y-4">
      {onStartDateChange && (
        <Field.Group>
          <Field.Label required>{t('start')}</Field.Label>
          <TextInput
            onChange={handleStartDateChange}
            type="date"
            value={DateUtils.toDateString(dayjs(startDate))}
            max={DateUtils.toDateString(dayjs(maxDate))}
            min={DateUtils.toDateString(dayjs(minDate))}
            error={!customDateValidState.startDate}
          />
          <Field.Error>
            {!customDateValidState.startDate && t('invalidDate')}
          </Field.Error>
        </Field.Group>
      )}
      {onEndDateChange && (
        <Field.Group>
          <Field.Label required>{t('end')}</Field.Label>
          <TextInput
            disabled={!startDate}
            type="date"
            onChange={handleEndDateChange}
            value={DateUtils.toDateString(dayjs(endDate))}
            max={maxDate ? DateUtils.toDateString(dayjs(maxDate)) : undefined}
            min={
              startDate && !endDate
                ? DateUtils.toDateString(dayjs(startDate))
                : DateUtils.toDateString(dayjs(minDate))
            }
            error={!customDateValidState.endDate}
          />
          <Field.Error>
            {!customDateValidState.endDate && t('invalidDate')}
          </Field.Error>
        </Field.Group>
      )}
      <div className="tw-flex tw-items-center tw-justify-between">
        <span className="tw-text-md tw-font-medium">
          {dayjs(date).format('MMMM')} {year}
        </span>
        <div className="tw-flex tw-gap-x-1">
          <IconButton
            onClick={decreaseMonth}
            variant="light"
            icon={<Icon size={'md'} icon={faChevronLeft} />}
            size={'small'}
            disabled={isPreviousMonthDisabled}
          />
          <IconButton
            onClick={increaseMonth}
            variant="light"
            icon={<Icon size={'md'} icon={faChevronRight} />}
            size={'small'}
            disabled={isNextMonthDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default SimpleDatePickerHeaderCustomPeriod;
