import { FC } from 'react';
import PageApi from 'api/page';
import { User } from 'types/User';
import { queryKey } from 'constants/queryKey';
import ArrayUtils from 'app/utils/array';
import useQueryNoCache from 'utils/hooks/useQueryNoCache';

export type AssignedUser = Pick<User, 'id' | 'name' | 'avatar' | 'role'> & {
  is_demo: boolean;
};

type Params = {
  users: AssignedUser[];
  isLoading: boolean;
};

type Props = {
  pageIds: number[];
  children: (params: Params) => JSX.Element;
};

const PageUsersContainer: FC<Props> = ({ pageIds, children }) => {
  const { data, isLoading } = useQueryNoCache(
    queryKey.assignedPageUsers(pageIds),
    () => PageApi.assignedUsers(pageIds),
    {
      enabled: pageIds.length > 0,
    },
  );

  const getOrderedUsers = () => {
    if (Array.isArray(data)) {
      return ArrayUtils.sortByKey(data, 'name') as AssignedUser[];
    }

    return [];
  };

  return children({
    users: getOrderedUsers(),
    isLoading,
  });
};

export default PageUsersContainer;
