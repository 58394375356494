import { TYPE } from '@kontentino/kontentino-constants/Pages';
import { Dayjs } from 'dayjs';
import { DropEffect } from 'types/dnd';

export enum PseudoPostType {
  Unknown = 'unknown',
  Ad = 'ad',
  Status = 'status',
  Link = 'link',
  Carousel = 'carousel',
  Story = 'story',
  Gallery = 'gallery',
  Video = 'video',
  Photo = 'photo',
  Event = 'event',
  Offer = 'offer',
  Reel = 'reel',
  PhotoGallery = 'photoGallery',
}

export enum PostExportVariant {
  Images = 1,
  Txt,
  Pdf,
  Csv,
}

export const PostTextMaxCharLimit: Record<number, number> = {
  [TYPE.INSTAGRAM]: 2200,
  [TYPE.LINKEDIN]: 3000,
  [TYPE.PINTEREST]: 500,
  [TYPE.TWITTER]: 280,
  [TYPE.GOOGLE_MY_BUSINESS]: 1500,
  [TYPE.YOUTUBE]: 5000,
  [TYPE.TIKTOK]: 4000,
  [TYPE.THREADS]: 500,
};

export type PostDndDropItem = {
  postId: number;
};

export type PostDndDropResult = {
  area?: PostDndDropType;
  dropEffect: DropEffect;
  date?: Dayjs;
  postId?: number;
};

export enum PostDndDragType {
  CalendarPostCard = 'CalendarPostCard',
  GridPostCard = 'GridPostCard',
  SidePanelPost = 'SidePanelPost',
}

export enum PostDndDropType {
  CalendarDateDay = 'CalendarDateDay',
  PostsSidePanelIdeas = 'PostsSidePanelIdeas',
  MoveDropArea = 'MoveDropArea',
  DuplicateDropArea = 'DuplicateDropArea',
  GridPostCard = 'GridPostCard',
}
