import { getUniquePrimitiveItemsFromArray } from 'utils/helpers';
import {
  REGEX_COMMENT_USER_MENTION,
  REGEX_MENTION,
  REGEX_NETWORK_MENTION,
} from '@kontentino/kontentino-constants/Regex';

type MentionableUser = { id: string | number; name: string; role?: string };

const TextEditorConvertor = {
  textToHtml(
    text = '',
    options?: {
      mentionableUsers?: MentionableUser[];
    },
  ) {
    function getMentionsFromText(text: string) {
      const rawProfileMentions = getUniquePrimitiveItemsFromArray([
        ...(text.match(new RegExp(REGEX_NETWORK_MENTION, 'gi'))?.map(String) ??
          []),
        ...(text.match(new RegExp(REGEX_MENTION, 'gi'))?.map(String) ?? []),
      ]);

      const rawUserMentions = getUniquePrimitiveItemsFromArray(
        text.match(new RegExp(REGEX_COMMENT_USER_MENTION, 'gi'))?.map(String) ??
          [],
      );

      const mentions: Record<string, MentionableUser> = {};

      function getMentionId(mention: string) {
        return mention.match(/(\d+)/gi)?.toString();
      }

      rawProfileMentions.forEach((rawMention) => {
        const id = getMentionId(rawMention);

        const name = rawMention
          .match(new RegExp(/\[\D+]/, 'gi'))
          ?.toString()
          ?.replace(/([\][])/g, '');

        if (id && name) {
          mentions[rawMention] = { id, name };
        }
      });

      rawUserMentions.forEach((rawMention) => {
        const id = getMentionId(rawMention);

        const user = options?.mentionableUsers?.find(
          (user) => `${user.id}` === id,
        );

        if (id && user) {
          mentions[rawMention] = { id, name: user.name, role: user.role };
        }
      });

      return Object.entries(mentions);
    }

    function convertTextMentionsToHtml(
      text: string,
      mentions: [string, MentionableUser][],
    ) {
      let html = text;

      mentions.forEach(([mentionText, mention]) => {
        html = html.replaceAll(
          mentionText,
          TextEditorConvertor.suggestionToMentionTag(mention),
        );
      });

      return html;
    }

    function convertLineBreaksToBrTag(text: string) {
      const LINE_BREAK = /(\r\n|\n|\r)/gm;

      return `<p>${text.replace(LINE_BREAK, '</br>')}</p>`;
    }

    const mentions = getMentionsFromText(text);

    const processes = [
      (val: string) => convertTextMentionsToHtml(val, mentions),
      (val: string) => convertLineBreaksToBrTag(val),
    ];

    let result = text;

    processes.forEach((process) => (result = process(result)));

    return result;
  },
  suggestionToMentionTag(mention: MentionableUser) {
    function recordToHtmlAttributes(
      record: Record<string, string | number | undefined>,
    ) {
      return Object.entries(record)
        .filter(([, value]) => !!value)
        .map(([key]) => `${key}='${record[key]}'`)
        .join(' ');
    }

    return `<span data-type='mention' class='mention' ${recordToHtmlAttributes({
      'data-id': mention.id,
      'data-label': mention.name,
      'data-role': mention.role,
    })} contenteditable='false'>${mention.name}</span>`;
  },
};

export default TextEditorConvertor;
