import { FC } from 'react';
import { UIDropdown } from '@kontentino/kontentino-ui';
import style from 'components/insights/infoCard/header/graphTypeSelect/GraphTypeSelect.module.scss';
import { GraphType, GraphLabels } from 'constants/analytics';
import { stopPropagation } from 'utils/mouseEvent';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { Icon } from '@kontentino/ui';

type Props = {
  availableGraphTypes: GraphType[];
  activeGraphType: GraphType;
  onChangeGraphType: (type: GraphType) => void;
};

const GraphTypeSelect: FC<Props> = ({
  availableGraphTypes,
  activeGraphType,
  onChangeGraphType,
}) => {
  return (
    <UIDropdown
      title={
        <span className={style.title}>
          {GraphLabels[activeGraphType]}
          <Icon icon={faChevronDown} size="sm" />
        </span>
      }
      buttonVariant="tertiary"
      buttonClassName={style.button}
    >
      {availableGraphTypes.map((type) => (
        <UIDropdown.Item
          onClick={stopPropagation(() => onChangeGraphType(type))}
          isActive={type === activeGraphType}
          key={type}
        >
          {GraphLabels[type]}
        </UIDropdown.Item>
      ))}
    </UIDropdown>
  );
};

export default GraphTypeSelect;
