import { Icon, IconButton, Tooltip } from '@kontentino/ui';
import {
  faEdit,
  faEye,
  faFilm,
  faTrash,
  faCloudArrowUp,
  faArrowDownToLine,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { ComponentProps, FC } from 'react';

const ACTION_TYPES = {
  edit: {
    icon: faEdit,
    label: 'edit',
  },
  customThumbnail: {
    icon: faFilm,
    label: 'customThumbnail',
  },
  delete: {
    icon: faTrash,
    label: 'delete',
  },
  download: {
    icon: faArrowDownToLine,
    label: 'download',
  },
  preview: {
    icon: faEye,
    label: 'preview',
  },
  replace: {
    icon: faCloudArrowUp,
    label: 'replace',
  },
  remove: {
    icon: faXmark,
    label: 'remove',
  },
} as const;

export type MediaPreviewActionButtonProps = {
  actionType: keyof typeof ACTION_TYPES;
} & ComponentProps<typeof IconButton>;

export const MediaPreviewActionButton: FC<MediaPreviewActionButtonProps> = ({
  actionType,
  ...props
}) => {
  const { t } = useTranslation();
  const { icon, label } = ACTION_TYPES[actionType];

  return (
    <Tooltip content={t(label)}>
      <IconButton
        icon={<Icon icon={icon} />}
        size="small"
        variant="secondary"
        className="tw-rounded-none"
        {...props}
      />
    </Tooltip>
  );
};
