import { IntercomUser } from 'types/User';
import satismeter from 'satismeter-loader';
import Logger from 'utils/logger';
import config from 'config';

const SatismeterUtils = {
  init({ user_hash, app_id, user_id, ...user }: IntercomUser) {
    try {
      if (this.shouldSkip()) {
        Logger.log('Skipping satismeter initialization');
        return;
      }

      satismeter({
        writeKey: config.SATISMETER_APP_ID,
        userId: user_id,
        traits: user,
      });
    } catch (e) {
      Logger.error(e);
    }
  },
  shouldSkip() {
    return !config.SATISMETER_APP_ID;
  },
};

export default SatismeterUtils;
