import { MetricProperty } from 'app/modules/insights/types/MetricProperty';
import { StandardPostDetailedMetric } from 'app/types/StandardPostDetailed';

export const getValueBox = (
  metric: StandardPostDetailedMetric,
  property: MetricProperty,
) => ({
  label: property.title,
  value: metric && metric.value ? metric.value : 0,
});
