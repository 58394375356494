import React, { FC } from 'react';
import { Icon } from '@kontentino/ui';
import { MetricProperty } from '../../types/MetricProperty';

type Props = {
  metric: MetricProperty & { value: string };
};

const MetricValue: FC<Props> = ({ metric }) => {
  return (
    <div className="tw-flex tw-items-center tw-justify-between">
      <div className="tw-flex tw-flex-shrink-0 tw-items-center">
        {metric.icon && (
          <Icon
            icon={metric.icon}
            size="lg"
            className="tw-text-grayscale-100"
          />
        )}
        <div className="tw-ml-2 tw-inline tw-text-md tw-font-medium">
          {metric.title}
        </div>
      </div>
      <div className="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-text-lg tw-font-semibold">
        {metric.value}
      </div>
    </div>
  );
};

export default MetricValue;
