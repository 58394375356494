import { Checkbox, DropdownMenu, Icon } from '@kontentino/ui';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import {
  faBoxArchive,
  faChevronRight,
  faCircleExclamation,
  faCirclePause,
  faSpinner,
  faSquare,
  faTrash,
  faWavePulse,
} from '@fortawesome/pro-regular-svg-icons';
import {
  EFFECTIVE_STATUS,
  EFFECTIVE_STATUS_LABEL,
} from '@kontentino/kontentino-constants/Ads';
import { useRef, useState } from 'react';
import { EffectiveStatus, EffectiveStatuses } from 'types/Ad';
import { useAdsPlannerContainer } from 'components/adsPlanner/context';
import _debounce from 'lodash.debounce';
import { useEffectOnce } from 'utils/hooks/useEffectOnce';
import { togglePrimitiveItemInArray } from 'utils/helpers';
import AdsPlannerConfig from 'app/config/adsPlanner';

const getStatusOptionIcon = (status: number) => {
  switch (status) {
    case EFFECTIVE_STATUS.CAMPAIGN.ACTIVE:
      return faWavePulse;
    case EFFECTIVE_STATUS.CAMPAIGN.ARCHIVED:
      return faBoxArchive;
    case EFFECTIVE_STATUS.CAMPAIGN.DELETED:
      return faTrash;
    case EFFECTIVE_STATUS.CAMPAIGN.IN_PROCESS:
      return faSpinner;
    case EFFECTIVE_STATUS.CAMPAIGN.PAUSED:
      return faCirclePause;
    case EFFECTIVE_STATUS.CAMPAIGN.WITH_ISSUES:
      return faCircleExclamation;
    default:
      return faSquare;
  }
};

const AdsPlannerFiltersEffectiveStatusSubmenu = () => {
  const { filters, changeSelectedEffectiveStatuses } = useAdsPlannerContainer();
  const [selectedValues, setSelectedValues] = useState<EffectiveStatuses>(
    filters.selectedEffectiveStatuses,
  );

  const notifyChangedStatus = useRef(
    _debounce((data: EffectiveStatuses) => {
      changeSelectedEffectiveStatuses(data);
    }, AdsPlannerConfig.FILTER_DELAY),
  );

  useEffectOnce(() => {
    return () => {
      notifyChangedStatus.current.cancel();
    };
  });

  const handleChangeStatus = (status: EffectiveStatus) => {
    setSelectedValues((prevSelectedValues) => {
      const statuses = togglePrimitiveItemInArray<EffectiveStatus>(
        status,
        prevSelectedValues,
      );

      notifyChangedStatus.current(statuses);
      return [...statuses];
    });
  };

  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger>
        <DropdownMenu.Item data-name="" data-cy="">
          <Icon icon={faFacebook} />
          Status from Facebook
          <Icon size="sm" className="tw-ml-auto" icon={faChevronRight} />
        </DropdownMenu.Item>
      </DropdownMenu.SubTrigger>
      <DropdownMenu.Portal>
        <DropdownMenu.SubContent className="tw-z-10">
          {Object.values(EFFECTIVE_STATUS.CAMPAIGN).map((status) => (
            <DropdownMenu.Item
              key={`effective-status-${status}`}
              onSelect={() => handleChangeStatus(status)}
            >
              <Checkbox
                size="small"
                onChange={() => null}
                checked={selectedValues.includes(status)}
              />
              <Icon icon={getStatusOptionIcon(status)} />
              {EFFECTIVE_STATUS_LABEL.CAMPAIGN[status]}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.SubContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Sub>
  );
};

export default AdsPlannerFiltersEffectiveStatusSubmenu;
