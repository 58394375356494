import { FC } from 'react';
import UISkeleton from 'react-loading-skeleton';

type Props = {};

const Skeleton: FC<Props> = () => {
  return (
    <UISkeleton width="100%" height="330px" style={{ marginBottom: 16 }} />
  );
};

export default Skeleton;
