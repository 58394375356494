import StringUtils from 'utils/string';

const getFilteredDataBySearchQuery = <T extends { name: string | null }>(
  query: string,
  data: T[],
) => {
  return query.length
    ? data.filter((page) => StringUtils.isSubstring(query, page.name ?? ''))
    : data;
};

export default getFilteredDataBySearchQuery;
