import React, { FC } from 'react';
import style from 'app/modules/insights/components/standardPostPreview/attachmentsCards/attachmentsCarousel/AttachmentsCarousel.module.scss';
import { StandardPostMedium } from 'app/types/StandardPostPreview';

type Props = {
  height: number;
  medium: StandardPostMedium;
  allowVideoPlayback?: boolean;
};

const AttachmentMedium: FC<Props> = ({
  height,
  medium,
  allowVideoPlayback,
}) => {
  return (
    <>
      {medium?.image && (
        <div
          className={style.carouselItemImage}
          style={{
            height,
            backgroundImage: `url('${medium.image.src}')`,
          }}
        />
      )}
      {medium?.video &&
        (allowVideoPlayback ? (
          <video
            className={style.carouselItemVideo}
            src={medium.video.src}
            controls
            width="100%"
            height="100%"
          />
        ) : (
          <div
            className={style.carouselItemImage}
            style={{
              height,
              backgroundImage: `url('${medium.video.thumbSrc}')`,
            }}
          />
        ))}
    </>
  );
};

export default AttachmentMedium;
