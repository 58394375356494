import AdAccountsSelectorOption from 'app/modules/adsPlanner/components/adAccountsSelector/components/AdAccountsSelectorOption';
import React, { FC } from 'react';
import { AdAccount } from 'types/AdAccount';
import ProfileSelectorInfoMessage from 'app/components/profileSelector/components/ProfileSelectorInfoMessage';

type Props = {
  adAccounts: AdAccount[];
  selectedAdAccountId: number | null;
  onSelectAdAccount: (adAccount: AdAccount) => void;
};

const AdAccountsSelectorOptions: FC<Props> = ({
  adAccounts,
  selectedAdAccountId,
  onSelectAdAccount,
}) => {
  if (adAccounts.length === 0) {
    return <ProfileSelectorInfoMessage message="No Ad accounts found" />;
  }

  return (
    <>
      {adAccounts.map((adAccount) => (
        <AdAccountsSelectorOption
          key={`ad-account-option-${adAccount.id}`}
          adAccount={adAccount}
          isChecked={selectedAdAccountId === adAccount.id}
          onSelect={() => onSelectAdAccount(adAccount)}
        />
      ))}
    </>
  );
};

export default AdAccountsSelectorOptions;
