import React, { FC, ReactElement } from 'react';
import UserPermissionGate from 'components/UserPermissionGate';
import { UserPermission } from 'constants/userPermission';
import { useTranslation } from 'react-i18next';
import CommentWorkflowButton from 'app/modules/posts/modules/post/components/postCommunication/comments/commentsList/CommentWorkflowButton';
import StringUtils from 'utils/string';
import { useUpdateApprovalPostStatus } from 'app/modules/posts/modules/detail/hooks/useUpdateApprovalPostStatus';
import { usePostCommunicationContext } from 'app/modules/posts/modules/post/components/postCommunication/PostCommunicationProvider';
import { Comment, CommentThread } from 'app/modules/comments/types';
import TrackVisibility from 'app/components/TrackVisibility';
import { CommentsConfig } from 'app/modules/comments/config';

type Props = {
  enableRespondToApprovalRequest?: boolean;
  comment: Comment;
  onIsVisibleChange?: (isVisible: boolean) => void;
};

const CommentWorkflow: FC<Props> = ({
  comment,
  enableRespondToApprovalRequest,
  onIsVisibleChange,
}) => {
  const { postId, resetCommentAction, initReworkCommentAction } =
    usePostCommunicationContext();
  const updateApprovalStatus = useUpdateApprovalPostStatus(postId);
  const { meta, user, type } = comment;
  const { t } = useTranslation();

  function renderWorkflows(content: ReactElement) {
    return onIsVisibleChange ? (
      <TrackVisibility onIsVisibleChange={onIsVisibleChange}>
        {content}
      </TrackVisibility>
    ) : (
      content
    );
  }

  const renderApprovalButtons = (
    threadType: Exclude<CommentThread, 'pinned'>,
  ) => {
    if (meta?.approval_status && meta?.approval_status !== 'manually_changed') {
      return (
        <CommentWorkflowButton
          thread={threadType}
          variant="secondary"
          icon={meta.approval_status.includes('approve') ? 'check' : 'close'}
          disabled
        >
          {t(
            `commentsWorkflowActions.${StringUtils.toCamelCase(
              meta.approval_status,
            )}`,
          )}
        </CommentWorkflowButton>
      );
    }

    return (
      <UserPermissionGate
        scopes={
          threadType === 'client'
            ? UserPermission.POST_CLIENT_APPROVE
            : UserPermission.POST_INTERNAL_APPROVE
        }
        deniedProps={{ disabled: true }}
      >
        <CommentWorkflowButton
          thread={threadType}
          variant={user.its_me ? 'tertiary' : 'success'}
          icon="check"
          isLoading={
            updateApprovalStatus.updateThroughComment.isLoading &&
            updateApprovalStatus.updateThroughComment.variables?.action ===
              `${threadType}_approval`
          }
          disabled={
            !!meta?.approval_status ||
            !enableRespondToApprovalRequest ||
            updateApprovalStatus.updateThroughComment.isLoading
          }
          onClick={() => {
            resetCommentAction();
            updateApprovalStatus.updateThroughComment.mutate({
              thread: threadType,
              action: `${threadType}_approval`,
              text: t('commentsWorkflowMessages.approve'),
            });
          }}
        >
          {t(
            `commentsWorkflowActions.${
              threadType === 'client' ? 'clientApprove' : 'internallyApprove'
            }`,
          )}
        </CommentWorkflowButton>
        <CommentWorkflowButton
          thread={threadType}
          variant={user.its_me ? 'tertiary' : 'danger'}
          icon="close"
          isLoading={
            updateApprovalStatus.updateThroughComment.isLoading &&
            updateApprovalStatus.updateThroughComment.variables?.action ===
              `${threadType}_rework`
          }
          disabled={
            !!meta?.approval_status ||
            !enableRespondToApprovalRequest ||
            updateApprovalStatus.updateThroughComment.isLoading
          }
          onClick={() => initReworkCommentAction(threadType)}
        >
          {t(
            `commentsWorkflowActions.${
              threadType === 'client' ? 'clientReject' : 'internallyReject'
            }`,
          )}
        </CommentWorkflowButton>
      </UserPermissionGate>
    );
  };

  return renderWorkflows(
    <div data-name={CommentsConfig.APPROVAL_REQUEST_WORKFLOW_DATA_NAME} data-id>
      {type === 'client_approval_request' && (
        <div className="tw-flex tw-gap-3 tw-pb-2 tw-pt-5">
          {renderApprovalButtons('client')}
        </div>
      )}
      {type === 'internal_approval_request' && (
        <div className="tw-flex tw-gap-3 tw-pb-2 tw-pt-5">
          {renderApprovalButtons('internal')}
        </div>
      )}
    </div>,
  );
};

export default CommentWorkflow;
