import { BrandLogo, Chip, Icon } from '@kontentino/ui';
import { FC } from 'react';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import getSocialMediaTypeForBrandLogo from '../../../utils/getSocialMediaTypeForBrandLogo';
import { ProfileSelectorPlaceholderData } from '../types/profileSelectorPlaceholder';
import { useTranslation } from 'react-i18next';
import useSubscriptionInfo from 'app/hooks/useSubscriptionInfo';
import clsx from 'clsx';

type Props = {
  data: ProfileSelectorPlaceholderData;
  onClick(): void;
  dataName?: string;
};

const ProfileSelectorPlaceholder: FC<Props> = ({ data, onClick, dataName }) => {
  const { isTrial } = useSubscriptionInfo();
  const { t } = useTranslation();

  return (
    <div
      onClick={onClick}
      className="tw-flex tw-h-[36px] tw-cursor-pointer tw-items-center tw-justify-between tw-rounded tw-bg-grayscale-10 tw-px-2 xl:tw-w-[180px] xl:tw-max-w-[180px] 2xl:tw-w-[250px] 2xl:tw-max-w-[250px]"
      data-cy="profile-management-dropdown-placeholder"
      data-name={dataName}
    >
      <div className="tw-flex tw-w-full tw-items-center tw-gap-x-2 tw-overflow-hidden tw-text-ellipsis">
        {data.length === 1 ? (
          <>
            <BrandLogo
              size={24}
              iconSize={12}
              src={data[0].logoSrc}
              name={data[0].name}
              socialMediaType={getSocialMediaTypeForBrandLogo(data[0]?.type)}
            />
            <span
              translate="no"
              className={clsx('tw-truncate tw-text-md tw-text-grayscale-180', {
                'tw-hidden xl:tw-block': isTrial,
                'tw-hidden lg:tw-block': !isTrial,
              })}
            >
              {data[0].name}
            </span>
          </>
        ) : (
          <>
            <Chip data-cy="profile-selector-placeholder-selected-count-indicator">
              {data.length}
            </Chip>
            <span
              className={clsx('tw-truncate', {
                'tw-hidden xl:tw-block': isTrial,
                'tw-hidden lg:tw-block': !isTrial,
              })}
            >
              {t('profilesSelected')}
            </span>
          </>
        )}
      </div>
      <Icon icon={faChevronDown} />
    </div>
  );
};

export default ProfileSelectorPlaceholder;
