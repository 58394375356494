import { gql } from '@apollo/client';

export const LI_POSTS_QUERY_NEW = gql`
  query liPostsQueryNew(
    $pageId: String!
    $first: Int
    $after: String
    $startDate: String!
    $endDate: String!
    $orderBy: [LinkedInPostsOrder]
    $types: [Int]
  ) {
    posts: linkedInPosts(
      linkedInPageId: $pageId
      first: $first
      after: $after
      dateFrom: $startDate
      dateTo: $endDate
      orderBy: $orderBy
      types: $types
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          _id
          type
          message
          media {
            media
            thumbnails {
              url
            }
            originalUrl
          }
          permalink
          createdTime
          metrics {
            edges {
              node {
                id
                metricName
                metricValue
                additionalData {
                  edges {
                    node {
                      name
                      value
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
