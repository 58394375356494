import { FC } from 'react';
import { Button } from '@kontentino/ui';
import { useTranslation } from 'react-i18next';

type Props = {
  onClear: () => void;
  onCancel: () => void;
  onSubmit: () => void;
  disabled?: {
    submit?: boolean;
  };
};

const SimpleDatePickerFooter: FC<Props> = ({
  onClear,
  onCancel,
  onSubmit,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <div className="tw-flex tw-w-full tw-justify-between tw-p-2">
      <Button variant={'ghost'} onClick={onClear}>
        {t('clear')}
      </Button>
      <div className="tw-flex tw-gap-x-3">
        <Button variant={'light'} onClick={onCancel}>
          {t('cancel')}
        </Button>
        <Button disabled={disabled?.submit} onClick={onSubmit}>
          {t('apply')}
        </Button>
      </div>
    </div>
  );
};

export default SimpleDatePickerFooter;
