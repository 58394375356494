import { FC, useEffect } from 'react';
import { MetricProperty } from 'app/modules/insights/types/MetricProperty';
import useTrackVisibility from 'utils/hooks/useTrackVisibility';
import { Icon } from '@kontentino/ui';

type Props = {
  onVisibleChange?(
    visibility: {
      isVisible: boolean;
      wasEverVisible: boolean;
    },
    metric: MetricProperty,
  ): void;
  metric: MetricProperty;
  className?: string;
};

const MetricHeader: FC<Props> = ({ onVisibleChange, metric, className }) => {
  const [ref, { isVisible, wasEverVisible }] = useTrackVisibility();

  useEffect(() => {
    onVisibleChange?.({ isVisible, wasEverVisible }, metric);
  }, [isVisible, wasEverVisible, ref, metric, onVisibleChange]);

  return (
    <div className={className} ref={ref}>
      <h3 className="tw-mb-0 tw-text-xl tw-font-medium [&_svg]:tw-mr-3">
        {metric.icon && (
          <Icon icon={metric.icon} color={metric.color} size="inherit" />
        )}
        <span>{metric.title}</span>
      </h3>
    </div>
  );
};

export default MetricHeader;
