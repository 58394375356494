import { MODULE_STATUS } from 'constants/modules';
import { createAction } from 'modules/helpers';
import { ModuleStatus } from 'types/ModuleStatus';
import { Dispatch, Store } from 'types/Store';
import Logger from 'utils/logger';
import PageApi from 'api/page';
import ProfileGroupApi from 'api/profileGroup';

export const PagesActionName = {
  SET_PAGES_DATA: 'PagesAction-SET_PAGES_DATA',
  SET_MODULE_STATUS: 'PagesAction-SET_MODULE_STATUS',
};

export const PagesAction = {
  setPagesData: createAction<any>(PagesActionName.SET_PAGES_DATA),
  setModuleStatus: createAction<ModuleStatus>(
    PagesActionName.SET_MODULE_STATUS,
  ),
};

export const PageThunk = {
  requestPages: () => async (dispatch: Dispatch, getState: () => Store) => {
    dispatch(PagesAction.setModuleStatus(MODULE_STATUS.Loading));

    try {
      const pagesData = await PageApi.getAll();
      const profileGroupsData = await ProfileGroupApi.getAll();

      const payload = {
        pages: pagesData.data,
        profileGroups: profileGroupsData.data,
      };

      dispatch(PagesAction.setPagesData(payload));
      dispatch(PagesAction.setModuleStatus(MODULE_STATUS.Succeeded));

      return {
        profiles: pagesData.data,
        profileGroups: profileGroupsData.data,
      };
    } catch (e) {
      Logger.error(e);
      dispatch(PagesAction.setModuleStatus(MODULE_STATUS.Failed));
    }
  },
};
