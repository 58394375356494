import React, { lazy, Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import routeConfig from 'app/config/routes';
import Posts from 'pages/Posts';
import { Outlet } from 'react-router';
import SocialLoginContextProvider from 'app/context/socialLogin';
import Skeleton from 'react-loading-skeleton';

const Kanban = lazy(() => import('../../../pages/posts/Kanban'));
const Calendar = lazy(() => import('../../../pages/posts/Calendar'));
const Grid = lazy(() => import('app/modules/posts/modules/grid'));
const List = lazy(() => import('app/modules/posts/modules/list'));

export const postsRouter: RouteObject = {
  path: routeConfig.posts.path,
  element: (
    <SocialLoginContextProvider>
      <Posts>
        <Suspense
          fallback={
            <div className="tw-h-full tw-p-4">
              <Skeleton width="100%" height="100%" />
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </Posts>
    </SocialLoginContextProvider>
  ),
  children: [
    {
      index: true,
      element: <Navigate to={routeConfig.posts.routes.calendar.path} />,
    },
    {
      path: routeConfig.posts.routes.calendar.path,
      element: <Calendar />,
    },
    {
      path: routeConfig.posts.routes.kanban.path,
      element: <Kanban />,
    },
    {
      path: routeConfig.posts.routes.grid.path,
      element: <Grid />,
    },
    {
      path: routeConfig.posts.routes.list.path,
      element: <List />,
    },
  ],
};
