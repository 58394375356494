import { FC, useState } from 'react';
import Modal from 'components/shared/Modal';
import ModalBody from 'app/components/timeSavers/assignMultipleTasksModal/ModalBody';
import AssignContainer from 'app/components/timeSavers/assignMultipleTasksModal/Container';
import { STEPS } from 'app/components/timeSavers/assignMultipleTasksModal/utils/constants';
import RequestContainer from 'app/components/timeSavers/assignMultipleTasksModal/RequestContainer';
import { ModalManager } from 'context/ModalRouter';
import StepsHeader from 'components/shared/workflowOptions/StepsHeader';

type Props = {
  modalManager: ModalManager;
  onSuccess?: () => void;
};

const steps = Object.values(STEPS);

const AssignTasksWithPostsSelection: FC<Props> = (props) => {
  const [activeStep, setActiveStep] = useState<number>(steps[0].step);

  const goNext = () => {
    setActiveStep((prevStep) => {
      const nextStep = prevStep + 1;

      if (nextStep > steps[steps.length - 1].step) {
        return steps[steps.length - 1].step;
      }

      return nextStep;
    });
  };

  const goBack = () => {
    setActiveStep((prevStep) => {
      const nextStep = prevStep - 1;

      if (prevStep < steps[0].step) {
        return steps[0].step;
      }

      return nextStep;
    });
  };

  return (
    <Modal modalManager={props.modalManager} size="lg">
      <Modal.Header
        title="Assign multiple tasks"
        onGoBack={
          activeStep === STEPS.SELECT_ASSIGNEES.step ? goBack : undefined
        }
      >
        <StepsHeader steps={steps} activeStep={activeStep} />
      </Modal.Header>
      <RequestContainer successCallback={props.onSuccess}>
        {(requestParams) => (
          <AssignContainer onSubmit={requestParams.submitAssignTasks}>
            {(params) => (
              <ModalBody
                onSelectPostStatus={params.togglePostStatus}
                selectedStatuses={params.selectedStatuses}
                onSelectPost={params.togglePost}
                selectedPosts={params.selectedPosts}
                onSelectMultiplePosts={params.selectPosts}
                activeStep={activeStep}
                onGoNext={goNext}
                onGoBack={goBack}
                selectedPagesIds={params.selectedPagesIds}
                taskMessage={params.formState.taskMessage}
                isUrgent={params.formState.isUrgent}
                canSendEmail={params.formState.canSendEmailNotification}
                onChangeSelectedUsers={params.handleChangeSelectedUsers}
                initialSelectedUsersIds={params.formState.selectedUsers}
                onTaskMessageChange={params.changeMessage}
                onSelectUrgent={params.selectUrgent}
                onSelectEmailNotification={params.selectEmailNotification}
                onAssignTasks={params.assignTasks}
                isSubmitting={requestParams.isLoading}
                formErrors={params.formErrors}
              />
            )}
          </AssignContainer>
        )}
      </RequestContainer>
    </Modal>
  );
};

export default AssignTasksWithPostsSelection;
