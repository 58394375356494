import ProfileSelector from 'app/components/ProfileSelector';
import React, { FC } from 'react';
import { Page } from 'app/modules/insights/types/Page';
import useInsightsSelectorFilter from './insightsPageSelector/hooks/useInsightsSelectorFilter';
import InsightsPageSelectorOptions from './insightsPageSelector/components/InsightsPageSelectorOptions';
import getProfileSelectorTitleByFilterOption from 'app/components/profileSelector/utils/getProfileSelectorTitleByFilterOption';
import useInsightsSocialLogin from 'app/modules/insights/hooks/useInsightsSocialLogin';
import { useActions } from 'app/hooks/useActions';
import { ActionButton } from 'app/components/actions/ActionButton';

type Props = {
  pages: Page[];
  selectedPageId: string;
  onSelectPage: (id: string) => void;
};

const InsightsPageSelector: FC<Props> = ({
  pages,
  selectedPageId,
  onSelectPage,
}) => {
  const {
    searchQuery,
    filteredPages,
    setSearchQuery,
    selectFilterOption,
    sidebarFilterOptions,
    selectedFilterOption,
  } = useInsightsSelectorFilter(pages);
  const { authorize } = useInsightsSocialLogin();

  const getPlaceholderData = () => {
    const selectedPage = pages.find((page) => page._id === selectedPageId);

    return [
      {
        name: selectedPage?.name ?? 'Select page',
        type: selectedPage?._type,
        logoSrc: selectedPage?.picture ?? undefined,
      },
    ];
  };

  const { profileActions } = useActions('profile-selector');

  return (
    <ProfileSelector
      title={getProfileSelectorTitleByFilterOption(selectedFilterOption)}
      optionsElement={
        <InsightsPageSelectorOptions
          pages={filteredPages}
          selectedPageId={selectedPageId}
          onSelectPage={onSelectPage}
          onEditProfileClick={authorize}
        />
      }
      filter={{
        searchQuery,
        selectedFilterOption,
        onSearch: setSearchQuery,
        options: sidebarFilterOptions,
        onSelectFilterOption: selectFilterOption,
      }}
      isSelectedAll={false}
      placeholderData={getPlaceholderData()}
      footer={
        <div className="tw-border-t tw-border-solid tw-border-grayscale-20 tw-p-4">
          <ActionButton
            action={profileActions.add}
            style={{ width: '100%' }}
            variant="tertiary"
            size="small"
            data-name="insights_profile-management_add-profile"
          />
        </div>
      }
      dataName={{
        placeholder: 'insights_profile-management_dropdown',
        options: 'insights_profile-management_profileList',
        filter: 'insights_profile-management_filter',
      }}
    />
  );
};

export default InsightsPageSelector;
