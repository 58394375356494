import TimeUtils from 'app/utils/time';

export const DEFAULT_TIMEOUT = TimeUtils.calcMilliseconds.seconds(20);
export const EXTENDED_TIMEOUT = TimeUtils.calcMilliseconds.minutes(1);

export const DEFAULT_HEADERS: Record<string, any> = {
  // this is needed because of backend, based on this its handled as ajax on backend
  'X-Requested-With': 'XMLHttpRequest',
  accept: 'application/json',
};
