import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import {
  getUniquePrimitiveItemsFromArray,
  togglePrimitiveItemInArray,
} from 'utils/helpers';
import {
  getAreAllPostsSelected,
  getSelectedPostsBetweenRange,
} from 'utils/post';
import { PostDetailWithDate } from 'types/PostDetail';

export const useSelectedPostsState = (data: PostDetailWithDate[]) => {
  const [selectedPosts, setSelectedPosts] = useState<number[]>([]);

  function toggleSelectedPosts(postId: number, selectRange: boolean = false) {
    if (selectRange) {
      const selectedPostsBetween = getSelectedPostsBetweenRange({
        posts: data,
        startPostId: selectedPosts[0],
        endPostId: postId,
      });

      setSelectedPosts(selectedPostsBetween);
    } else {
      setSelectedPosts((prevPosts) =>
        togglePrimitiveItemInArray<number>(postId, prevPosts),
      );
    }
  }

  function selectAllPosts() {
    const allPostIds = data.map((post) => post.id);
    setSelectedPosts(allPostIds);
  }

  function clearSelectedPosts() {
    setSelectedPosts([]);
  }

  function clearSelectedPost(postId: number) {
    setSelectedPosts((prevSelected) =>
      prevSelected.filter((id) => id !== postId),
    );
  }

  function getPostIdsByMonth(monthStartDate: string) {
    return data
      .filter((post) => {
        return dayjs(post.date).isSame(monthStartDate, 'month');
      })
      .map((post) => post.id);
  }

  function areAllPostsInMonthSelected(monthStartDate: string) {
    const postsInMonth = getPostIdsByMonth(monthStartDate);
    return (
      postsInMonth.length > 0 &&
      postsInMonth.every((postId) => selectedPosts.includes(postId))
    );
  }

  function toggleSelectedPostsByMonth(monthStartDate: string) {
    const monthPostIds = getPostIdsByMonth(monthStartDate);

    if (monthPostIds.length > 0) {
      setSelectedPosts((prevSelectedPosts) => {
        return areAllPostsInMonthSelected(monthStartDate)
          ? prevSelectedPosts.filter((postId) => !monthPostIds.includes(postId))
          : getUniquePrimitiveItemsFromArray([
              ...prevSelectedPosts,
              ...monthPostIds,
            ]);
      });
    }
  }

  const allPostsSelected = useMemo(
    () => getAreAllPostsSelected(data, selectedPosts),
    [data, selectedPosts],
  );

  return {
    selectedPosts,
    clearSelectedPosts,
    clearSelectedPost,
    selectAllPosts,
    toggleSelectedPosts,
    toggleSelectedPostsByMonth,
    areAllPostsInMonthSelected,
    allPostsSelected,
  };
};
