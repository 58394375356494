import React, { FC } from 'react';
import { useBestPostsForAllMetricsQuery } from 'app/modules/insights/modules/postsPerformance/hooks/useBestPostsForAllMetricsQuery';
import { Page } from 'app/modules/insights/types/Page';
import { MetricProperty } from 'app/modules/insights/types/MetricProperty';
import InsightsConfig from 'app/config/insights';
import { Alert } from '@kontentino/ui';
import { Dayjs } from 'dayjs';

type Props = {
  page: Page;
  startDate: Dayjs;
  endDate: Dayjs;
  metric: MetricProperty;
  types: number[];
};

const BestPostsHasFewerCountAlert: FC<Props> = (props) => {
  const { posts } = useBestPostsForAllMetricsQuery(props);

  if (
    posts &&
    posts.length > 0 &&
    posts.length < InsightsConfig.MAX_POSTS_PER_METRIC
  ) {
    return (
      <Alert>
        You have <b>less than {InsightsConfig.MAX_POSTS_PER_METRIC} posts</b> of
        this type in your selected timeframe. In place of normal posts,
        placeholder posts appear. To see more, extend your timeframe.
      </Alert>
    );
  }

  return null;
};

export default BestPostsHasFewerCountAlert;
