import { isDevelopmentMode } from 'utils/helpers';
import { hotjar } from 'react-hotjar';
import Logger from 'utils/logger';

const HotjarUtils = {
  trackEvent: (event: string) => {
    try {
      if (isDevelopmentMode()) {
        Logger.info(`Skipping Hotjar event tracked: %s`, event);
        return;
      }

      return hotjar.event(event);
    } catch (e) {
      Logger.error(e);
    }
  },
  eventKey: {
    trialLogin: 'trial-login',
    profileSelectorOpen: 'profile-selector-open',
    profileSelectorAddProfileClick: 'add-profile-in-selector',
    addProfileClickInAddNewDropdown: 'add-profile-in-add-new',
  },
};

export default HotjarUtils;
