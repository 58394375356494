import React, { FC } from 'react';
import { formatDate } from 'app/utils/date';
import { PagePost } from 'types/insights/CompetitionAnalysis';
import Link from 'components/shared/Link';

type Props = {
  post: PagePost;
  children: React.ReactNode;
};

const BasePostRow: FC<Props> = ({ post, children }) => {
  return (
    <div className="tw-flex tw-flex-1 tw-items-center tw-border-t tw-border-grayscale-10 tw-py-4">
      <div className="tw-w-[7%]">
        <div
          className="tw-h-8 tw-w-8 tw-shrink-0 tw-rounded-full tw-bg-grayscale-30 tw-bg-cover tw-bg-no-repeat"
          style={
            post.pageInfo.picture
              ? { backgroundImage: `url('${post.pageInfo.picture}')` }
              : undefined
          }
        />
      </div>
      <Link
        className="tw-flex tw-w-[15%] hover:tw-underline"
        to={post.link}
        target="_blank"
      >
        <div
          className="tw-h-[56px] tw-w-[56px] tw-shrink-0 tw-rounded tw-bg-grayscale-20 tw-bg-cover tw-bg-no-repeat"
          style={
            post.picture
              ? { backgroundImage: `url('${post.picture}')` }
              : undefined
          }
        />
        <div className="tw-ml-2 tw-overflow-hidden">
          <div className="tw-mb-1 tw-text-sm tw-text-grayscale-100">
            {formatDate(post.createdTime)}
          </div>
          <div className="tw-line-clamp-2 tw-text-sm">{post.message}</div>
        </div>
      </Link>
      <div className="tw-w-[78%]">{children}</div>
    </div>
  );
};

export default BasePostRow;
