import { DropdownMenu, DropdownMenuItemProps } from '@kontentino/ui';
import { Action, ActionProps } from 'actions';
import UserPermissionGate from 'components/UserPermissionGate';
import { Fragment } from 'react';

export type ActionDropdownMenuItemProps<TAction extends Action> =
  DropdownMenuItemProps &
    ActionProps<TAction> & {
      separator?: {
        before?: boolean;
        after?: boolean;
      };
    };

export const ActionDropdownMenuItem = function <TAction extends Action>({
  action,
  actionParams,
  children,
  separator,
  ...props
}: ActionDropdownMenuItemProps<TAction>) {
  return (
    <UserPermissionGate scopes={action.scopes}>
      <Fragment>
        {separator?.before && <DropdownMenu.Separator />}
        <DropdownMenu.Item
          onClick={() => action.perform?.(actionParams)}
          {...props}
        >
          {children ?? (
            <Fragment>
              {action.icon}
              {action.label}
            </Fragment>
          )}
        </DropdownMenu.Item>
        {separator?.after && <DropdownMenu.Separator />}
      </Fragment>
    </UserPermissionGate>
  );
};
