import { Currencies } from 'types/Listings';
import CurrencyUtils from 'app/utils/currency';
import { ListPost } from 'types/PostDetail';

export function getPostsCurrencyBudgets(
  posts: ListPost[],
  currencies: Currencies,
): {
  code: string;
  amount: number;
  label: string;
}[] {
  const budgets: Record<string, number> = {};

  posts.forEach((post) => {
    if (post.budget && typeof post.budget_currency === 'number') {
      if (budgets[post.budget_currency]) {
        budgets[post.budget_currency] += post.budget;
      } else {
        budgets[post.budget_currency] = post.budget;
      }
    }
  });

  return Object.entries(budgets).map(([key, value]) => {
    const code = currencies.list[key];

    return {
      code: code,
      amount: value,
      label: CurrencyUtils.format(value, code, navigator.language),
    };
  });
}
