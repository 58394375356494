import { v4 as uuid } from 'uuid';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

type Dimensions = {
  width: number | null;
  height: number | null;
};

type Notifier = {
  id: string;
  notify: Dispatch<SetStateAction<Dimensions>>;
};

const notifiers: Record<string, Notifier> = {};

function notifyListeners() {
  const width = window.innerWidth;
  const height = window.innerHeight;

  Object.values(notifiers).forEach((notifier) => {
    notifier.notify({ width, height });
  });
}

export function useWindowSize(): Dimensions {
  const [id] = useState(uuid());
  const [windowSize, setWindowSize] = useState<Dimensions>({
    width: null,
    height: null,
  });

  useEffect(() => {
    if (Object.keys(notifiers).length === 0) {
      window.addEventListener('resize', notifyListeners);
    }

    notifiers[id] = {
      id,
      notify: setWindowSize,
    };

    notifyListeners();

    return () => {
      delete notifiers[id];

      if (Object.keys(notifiers).length === 0) {
        window.removeEventListener('resize', notifyListeners);
      }
    };
  }, [id]);

  return windowSize;
}
