import React, { FC } from 'react';
import PageUsersContainer, {
  AssignedUser,
} from 'components/page/PageUsersContainers';
import { mapUserToSelectOption } from 'app/modules/posts/utils/mapUserToSelectOption';
import StringUtils from 'utils/string';
import { Checkbox, Field, Select, TextArea } from '@kontentino/ui';
import { UserWorkflowPermissions } from 'utils/user/workflowPermissions';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

type Props = {
  pagesIds: number[];
  taskMessage: string;
  isUrgent: boolean;
  canSendEmail: boolean;
  onSelectedUsersChange: (users: number[]) => void;
  initialSelectedUsersIds: number[];
  onTaskMessageChange: (value: string) => void;
  onSelectUrgent: () => void;
  onSelectEmailNotification: () => void;
  formErrors: Record<string, string>;
  className?: string | null;
};

const Form: FC<Props> = ({
  pagesIds,
  taskMessage,
  canSendEmail,
  isUrgent,
  onTaskMessageChange,
  onSelectUrgent,
  onSelectEmailNotification,
  formErrors,
  className,
  onSelectedUsersChange,
  initialSelectedUsersIds,
}) => {
  const { t } = useTranslation();

  function getSelectedUsersDefaultValue(users: AssignedUser[]) {
    return (
      initialSelectedUsersIds
        .map((userId) => users.find((user) => user.id === userId))
        .filter(Boolean) as AssignedUser[]
    ).map(mapUserToSelectOption);
  }

  return (
    <div
      className={clsx(
        'tw-justify-content tw-flex tw-w-full tw-flex-col tw-gap-3',
        className,
      )}
      data-cy="assign-task-form"
    >
      <Field.Group>
        <Field.Label required>{t('assignees')}</Field.Label>
        <PageUsersContainer pageIds={pagesIds}>
          {(params) => (
            <Select
              isMulti
              closeMenuOnSelect={false}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (styles) => ({
                  ...styles,
                  zIndex: 1010,
                }),
              }}
              inputId="assign-task-users-selector"
              isDisabled={params.isLoading}
              options={params.users
                .filter((user) =>
                  UserWorkflowPermissions.canAssignPost(user.role),
                )
                .map(mapUserToSelectOption)}
              filterOption={(option, input) =>
                StringUtils.isSubstring(input, option.data.name)
              }
              defaultValue={getSelectedUsersDefaultValue(params.users)}
              onChange={(options) =>
                onSelectedUsersChange(options.map((option) => option.value))
              }
            />
          )}
        </PageUsersContainer>
        {formErrors.selectedUsers && (
          <Field.Error data-cy="assign-form-assignees-select-error">
            {formErrors.selectedUsers}
          </Field.Error>
        )}
      </Field.Group>
      <Field.Group>
        <Field.Label required>{t('task')}</Field.Label>
        <TextArea
          value={taskMessage}
          onChange={(e) => onTaskMessageChange(e.currentTarget.value)}
          placeholder={`${t('task')}...`}
          data-name="assign-user-to-post-form-task-textarea"
          data-cy="assign-user-to-post-form-task-textarea"
        />
      </Field.Group>
      <Field.Group className="!tw-flex-row tw-items-center">
        <Checkbox
          onChange={() => null}
          onClick={onSelectUrgent}
          checked={isUrgent}
          data-name="assign-task-form_mark-urgent-checkbox"
          data-cy="assign-task-form_mark-urgent-checkbox"
        />
        <Field.Label required>{t('markAsUrgent')}</Field.Label>
      </Field.Group>
      <Field.Group className="!tw-flex-row tw-items-center">
        <Checkbox
          onChange={() => null}
          onClick={onSelectEmailNotification}
          checked={canSendEmail}
          data-name="assign-task-form_send-email-checkbox"
          data-cy="assign-task-form_send-email-checkbox"
        />
        <Field.Label required>{t('sendEmailNotification')}</Field.Label>
      </Field.Group>
    </div>
  );
};

export default Form;
