import React, { FC } from 'react';
import { Comment } from 'app/modules/comments/types';
import { CommentAttachment } from './CommentAttachment';
type Props = {
  attachments: Comment['attachments'];
  onViewImageClick?(id: number): void;
};

const CommentAttachments: FC<Props> = ({ attachments, onViewImageClick }) => {
  return (
    <div className="tw-mt-1 tw-flex tw-flex-wrap tw-gap-2">
      {attachments.map((attachment) => (
        <CommentAttachment
          key={attachment.id}
          attachment={attachment}
          onViewImageClick={onViewImageClick}
          hasMultipleAttachments={attachments.length > 1}
        />
      ))}
    </div>
  );
};

export default CommentAttachments;
