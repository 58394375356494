import {
  PageNumberType,
  TYPE_FACEBOOK,
} from '@kontentino/kontentino-constants/Pages';
import { FACEBOOK_DOMAIN } from 'constants/socialNetwork';

const SocialNetworkUrlUtils = {
  postDetail(options: { pageType: PageNumberType; postId: string | number }) {
    switch (options.pageType) {
      case TYPE_FACEBOOK:
        return `${FACEBOOK_DOMAIN}/${options.postId}`;
    }

    return '';
  },
};

export default SocialNetworkUrlUtils;
