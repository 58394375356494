import {
  faEllipsisVertical,
  faPenToSquare,
  faReply,
  faThumbTack,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { Divider, DropdownMenu, Icon, Tooltip } from '@kontentino/ui';
import React from 'react';
import useCommentPin from '../../../hooks/useCommentPin';
import { usePostCommunicationContext } from '../../../../PostCommunicationProvider';
import { useDeleteComment } from '../../../hooks/useDeleteComment';
import { useCommentActionHover } from '../../providers/CommentActionHoverProvider';
import { useOutsideClick } from 'utils/hooks/useOutsideClick';
import { useTranslation } from 'react-i18next';
import { useActions } from 'app/hooks/useActions';
import { ActionDropdownMenuItem } from 'app/components/actions/ActionDropdownMenuItem';
import { Comment } from 'app/modules/comments/types';

interface CommentsListItemMoreProps {
  comment: Comment;
}

const CommentsListItemMore: React.FC<CommentsListItemMoreProps> = ({
  comment,
}) => {
  const { commentActions } = useActions('post-detail');
  const { t } = useTranslation();
  const isAuthor = comment.user.its_me;
  const {
    postId,
    initReplyCommentAction,
    initEditCommentAction,
    commentsListRef,
  } = usePostCommunicationContext();
  const { toggleCommentPin, isLoading } = useCommentPin(comment);
  const { onDelete } = useDeleteComment({ comment_id: comment.id });
  const { setIsDropdownClicked, hideActions } = useCommentActionHover();
  const { elementRef, addListener, removeListener } =
    useOutsideClick<HTMLUListElement>(onHide);

  function onShow() {
    setIsDropdownClicked(true);
    addListener();
  }

  function onHide() {
    hideActions();
    removeListener();
  }

  const handleOnEdit = () => {
    onHide();
    initEditCommentAction(comment);
  };

  const handleOnReply = () => {
    onHide();
    initReplyCommentAction(comment);
  };

  const handleOnTogglePin = () => {
    onHide();
    toggleCommentPin();
  };

  const handleOnDelete = () => {
    onHide();
    onDelete();
  };

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger onClick={onShow}>
        <div
          className="tw-flex tw-items-center tw-justify-center"
          data-name="comments_comment_more"
        >
          <Tooltip content={t('more')}>
            <Icon
              icon={faEllipsisVertical}
              className="tw-cursor-pointer tw-rounded-full"
              withoutBoundary
            />
          </Tooltip>
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        className="tw-z-10 tw-border tw-border-grayscale-30"
        side={!isAuthor ? 'right' : undefined}
        ref={elementRef}
        collisionBoundary={[commentsListRef.current]}
      >
        <DropdownMenu.Item
          data-name="comments_comment_more-reply"
          data-cy="comments_comment_more-reply"
          onClick={handleOnReply}
        >
          <Icon icon={faReply} className="tw-rounded-full" withoutBoundary />
          <span>{t('commentActions.reply.title')}</span>
        </DropdownMenu.Item>
        <DropdownMenu.Item
          disabled={isLoading}
          data-name="comments_comment_more-pin"
          data-cy="comments_comment_more-pin"
          onClick={handleOnTogglePin}
        >
          <Icon
            icon={faThumbTack}
            className="tw-rounded-full"
            withoutBoundary
          />
          <span>
            {t(`commentActions.${comment.is_pinned ? 'unpin' : 'pin'}.title`)}
          </span>
        </DropdownMenu.Item>
        {isAuthor && (
          <DropdownMenu.Item
            data-name="comments_comment_more-edit"
            data-cy="comments_comment_more-edit"
            onClick={handleOnEdit}
          >
            <Icon
              icon={faPenToSquare}
              className="tw-rounded-full"
              withoutBoundary
            />
            <span>{t('commentActions.edit.title')}</span>
          </DropdownMenu.Item>
        )}
        {comment.attachments.length > 1 && (
          <ActionDropdownMenuItem
            actionParams={{
              id: comment.id,
              postId,
            }}
            action={commentActions.downloadAttachments}
          />
        )}
        {isAuthor && (
          <>
            <Divider orientation="horizontal" />
            <DropdownMenu.Item
              data-name="comments_comment_more-delete"
              data-cy="comments_comment_more-delete"
              onClick={handleOnDelete}
            >
              <Icon
                icon={faTrash}
                className="tw-rounded-full"
                withoutBoundary
              />
              <span>{t('commentActions.delete.title')}</span>
            </DropdownMenu.Item>
          </>
        )}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default CommentsListItemMore;
