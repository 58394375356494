import {
  MEDIA_PRODUCT_TYPE_IG,
  POST_TYPE_IG,
} from 'app/modules/insights/constants/postType';

export const instagramPostTypeQueryMap: {
  [key: string]: { type: number[]; mediaProductTypes: string[] };
} = {
  [POST_TYPE_IG.IMAGE]: {
    type: [POST_TYPE_IG.IMAGE],
    mediaProductTypes: [MEDIA_PRODUCT_TYPE_IG.FEED],
  },
  [POST_TYPE_IG.VIDEO]: {
    type: [POST_TYPE_IG.VIDEO],
    mediaProductTypes: [MEDIA_PRODUCT_TYPE_IG.FEED],
  },
  [POST_TYPE_IG.CAROUSEL]: {
    type: [POST_TYPE_IG.CAROUSEL],
    mediaProductTypes: [MEDIA_PRODUCT_TYPE_IG.FEED],
  },
  [POST_TYPE_IG.STORY]: {
    type: [],
    mediaProductTypes: [MEDIA_PRODUCT_TYPE_IG.STORY],
  },
  [POST_TYPE_IG.REEL]: {
    type: [],
    mediaProductTypes: [MEDIA_PRODUCT_TYPE_IG.REEL],
  },
};
