import React, { FC } from 'react';
import DateRangePicker, {
  FocusedInputType,
  NumberOfMonthsType,
} from 'components/date-range-picker/DateRangePicker';
import ControlsWrapperFooter from 'components/date-range-picker/ControlsWrapperFooter';
import PresetsList from 'components/date-range-picker/presets/List';
import { DateRangeOnChangeParams } from 'types/Date';
import { DatePickerDataNames } from 'components/shared/DatePicker';
import { Dayjs } from 'dayjs';

type Props = {
  onDatesChange(values: DateRangeOnChangeParams): void;
  onFocusChange(): void;
  focusedInput: FocusedInputType;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  numberOfMonths: NumberOfMonthsType;
  onCancel(): void;
  onSubmit(values: DateRangeOnChangeParams): void;
  presets: string[];
  selectedPreset?: string | null;
  onPresetClick(value: string): void;
  isDayBlocked?: (day: Dayjs) => boolean;
  dataName?: DatePickerDataNames;
};

const ControlsWrapper: FC<Props> = ({
  onDatesChange,
  onFocusChange,
  focusedInput,
  startDate,
  endDate,
  numberOfMonths,
  onCancel,
  onSubmit,
  presets,
  selectedPreset,
  onPresetClick,
  isDayBlocked,
  dataName,
}) => (
  <div className="tw-relative tw-z-[1] tw-inline-flex tw-overflow-hidden tw-rounded-sm tw-bg-white tw-shadow-md">
    <div className="tw-flex tw-flex-col">
      <div className="tw-flex">
        {presets.length !== 0 && (
          <div
            className="tw-max-h-[300px] tw-overflow-y-auto tw-border-r tw-border-solid tw-border-grayscale-5 tw-p-3"
            data-name={dataName?.presetsList}
            data-cy="date-picker-presets-list"
          >
            <PresetsList
              onPresetClick={onPresetClick}
              selectedPreset={selectedPreset}
              presets={presets}
            />
          </div>
        )}
        <DateRangePicker
          onDatesChange={onDatesChange}
          onFocusChange={onFocusChange}
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
          numberOfMonths={numberOfMonths}
          isDayBlocked={isDayBlocked}
          dataName={dataName?.dateRangePicker}
        />
      </div>
      <ControlsWrapperFooter
        onCancel={onCancel}
        onSubmit={() => onSubmit({ startDate, endDate })}
        dataName={{
          cancelButton: dataName?.footer?.cancelButton,
          submitButton: dataName?.footer?.submitButton,
        }}
      />
    </div>
  </div>
);

export default ControlsWrapper;
