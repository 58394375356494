import { getUniquePrimitiveItemsFromArray } from 'utils/helpers';
import { instagramPostTypeQueryMap } from '../constants/instagramPostTypeQueryMap';

export const parseIgPostTypesToQueryVariables = (types: number[]) => {
  const queryVariables: { types: number[]; mediaProductTypes: string[] } = {
    types: [],
    mediaProductTypes: [],
  };
  types.forEach((type) => {
    const postTypeQueryMapped = instagramPostTypeQueryMap[type];

    if (postTypeQueryMapped) {
      queryVariables.types = getUniquePrimitiveItemsFromArray(
        queryVariables.types.concat(postTypeQueryMapped.type),
      );

      queryVariables.mediaProductTypes = getUniquePrimitiveItemsFromArray(
        queryVariables.mediaProductTypes.concat(
          postTypeQueryMapped.mediaProductTypes,
        ),
      );
    }
  });
  return queryVariables;
};
