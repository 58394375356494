import { modalRoutes } from 'constants/routes';
import { useNavigate } from 'react-router-dom';
import { createSearchFromParams } from 'utils/url';
import { PostsAddNewProfileReason } from 'app/modules/posts/components/postsAddNewProfileModal/hooks/useAddNewProfileReasonMessages';
import { usePostsContainer } from 'components/posts/context';

type HistoryUrl =
  | string
  | { pathname?: string; hash?: string; search?: string };

type CreateNewParams = { date?: string; text?: string };

export type PostsCreateNew = {
  createPost(params?: CreateNewParams): void;
  getCreatePostUrl(params?: CreateNewParams): HistoryUrl;
  createInspiration(params?: CreateNewParams): void;
  getCreateInspirationUrl(params?: CreateNewParams): HistoryUrl;
};

export const usePostsCreateNew = (): PostsCreateNew => {
  const { activeState, pages } = usePostsContainer();
  const navigate = useNavigate();

  function getRouteToCreate(
    toModal: string,
    params?: CreateNewParams,
  ): HistoryUrl {
    const profileIds = activeState.profiles.length
      ? activeState.profilesIds
      : pages.map((page) => page.id);

    if (profileIds.length === 0) {
      return {
        hash: modalRoutes.NEW_PROFILE,
        search: `?reason=${PostsAddNewProfileReason.noProfile}`,
      };
    }

    if (profileIds.length === 1) {
      return {
        hash: toModal,
        search: createSearchFromParams({
          compose: true,
          date: params?.date,
          text: params?.text,
          profileIds,
        }),
      };
    }

    return {
      hash: modalRoutes.SELECT_SINGLE_PROFILE_BEFORE_COMPOSE,
      search: createSearchFromParams({
        continueTo: toModal,
        profileIds,
        date: params?.date,
        text: params?.text,
      }),
    };
  }

  function getCreatePostUrl(params?: CreateNewParams) {
    return getRouteToCreate(modalRoutes.POST_DETAIL, params);
  }

  function createPost(params?: CreateNewParams) {
    navigate(getCreatePostUrl(params));
  }

  function getCreateInspirationUrl(params?: CreateNewParams) {
    return getRouteToCreate(modalRoutes.INSPIRATION_DETAIL, params);
  }

  function createInspiration(params?: CreateNewParams) {
    navigate(getCreateInspirationUrl(params));
  }

  return {
    createPost,
    getCreatePostUrl,
    createInspiration,
    getCreateInspirationUrl,
  };
};
