import React, { FC, forwardRef, ReactNode } from 'react';
import { IconButton, IconButtonProps, Tooltip } from '@kontentino/ui';
import { stopPropagation } from 'utils/mouseEvent';

export type OptionButtonProps = Props;

type Props = {
  onClick?: () => void;
  children: ReactNode;
  isDisabled?: boolean;
  name?: string;
  variant?: IconButtonProps['variant'];
  dataName?: string;
  className?: string;
};

const OptionButton: FC<Props> = forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      onClick,
      isDisabled,
      name,
      variant = 'ghost',
      dataName,
      className,
    },
    ref,
  ) => {
    return (
      <Tooltip content={name}>
        <IconButton
          onClick={stopPropagation(onClick)}
          disabled={isDisabled}
          size="small"
          variant={variant}
          data-name={dataName}
          className={className}
          ref={ref}
        >
          {children}
        </IconButton>
      </Tooltip>
    );
  },
);

export default OptionButton;
