import React, { FC, useState } from 'react';
import { MediaApi } from 'api/media';
import RecordEntriesList from 'components/shared/RecordEntriesList';
import FilesUtil from 'utils/files';
import { DropdownMenu, Icon, Tooltip } from '@kontentino/ui';
import { AttachmentsConfig } from 'config/attachments';
import { useActions } from 'app/hooks/useActions';
import { usePostCommunicationContext } from '../PostCommunicationProvider';
import { useTranslation } from 'react-i18next';
import { Comment } from 'app/modules/comments/types';
import MediaPreview from 'app/components/compoundMediaPreview';
import { faArrowDownToLine } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

type Props = {
  attachment: Comment['attachments'][0];
  onViewImageClick?(id: number): void;
  hasMultipleAttachments: boolean;
};

export const CommentAttachment: FC<Props> = ({
  attachment,
  onViewImageClick,
  hasMultipleAttachments,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { t } = useTranslation();
  const { postId } = usePostCommunicationContext();
  const { commentActions } = useActions('post-detail');

  const isImage = !!attachment.thumb_size_url;
  const onSingleDownload = () => {
    MediaApi.downloadMedium({
      id: attachment.medium_id,
      type: isImage ? 'image' : 'file',
    });
  };
  const onDownloadAll = () => {
    commentActions.downloadAttachments.perform({
      id: attachment.comment_id,
      postId,
    });
  };

  return (
    <Tooltip
      delay={AttachmentsConfig.TOOLTIP_DELAY}
      content={
        <RecordEntriesList
          record={{
            name: attachment.file_name ?? 'Unknown',
            size: FilesUtil.formatBytes(attachment.file_size ?? 0, 1),
            type: FilesUtil.getFileNameExtension(attachment.file_name ?? ''),
          }}
        />
      }
    >
      <MediaPreview.Root className="!tw-m-0 tw-h-16 tw-w-16 tw-rounded tw-border tw-border-grayscale-10">
        <MediaPreview.Image
          src={attachment.thumb_size_url}
          fileName={attachment.file_name}
        />
        <MediaPreview.Overlay />
        <MediaPreview.Actions
          className={clsx({
            'tw-opacity-100': isDropdownOpen,
          })}
        >
          {onViewImageClick && isImage && (
            <MediaPreview.ActionButton
              actionType="preview"
              onClick={() => onViewImageClick(attachment.id)}
            />
          )}
          {hasMultipleAttachments ? (
            <DropdownMenu.Root
              open={isDropdownOpen}
              onOpenChange={(open) => setIsDropdownOpen(open)}
            >
              <DropdownMenu.Trigger>
                <div>
                  <MediaPreview.ActionButton actionType="download" />
                </div>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content className="tw-z-10 tw-border tw-border-grayscale-30 tw-text-grayscale-180">
                <DropdownMenu.Item onClick={onSingleDownload}>
                  <Icon icon={faArrowDownToLine} />
                  {t('download')} {isImage ? ' image' : ' file'}
                </DropdownMenu.Item>
                <DropdownMenu.Item onClick={onDownloadAll}>
                  <Icon icon={faArrowDownToLine} />
                  {t('downloadAll')}
                </DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          ) : (
            <MediaPreview.ActionButton
              actionType="download"
              onClick={onSingleDownload}
            />
          )}
        </MediaPreview.Actions>
      </MediaPreview.Root>
    </Tooltip>
  );
};
