import {
  MetricCardData,
  MetricGraphLegendLabels,
} from 'app/modules/insights/types/PagePerformance';
import { Dayjs } from 'dayjs';
import { FilterState } from 'app/modules/insights/hooks/useFilterState';

const formatDate = (date: Dayjs) => {
  return date.format('D.M.YYYY');
};

export const getGraphLegendLabels = ({
  startDate,
  endDate,
  comparedStartDate,
  comparedEndDate,
  metricGraphDataItem,
  generalDataItem,
}: {
  startDate: Dayjs;
  endDate: Dayjs;
  comparedStartDate: Dayjs | null;
  comparedEndDate: Dayjs | null;
  metricGraphDataItem: MetricCardData['metricGraphData'][0];
  generalDataItem: MetricCardData['generalData'][0];
}) => {
  if (
    !!metricGraphDataItem.graphValues.data[0] &&
    Object.hasOwn(metricGraphDataItem.graphValues.data[0], 'secondValue') &&
    !!metricGraphDataItem.legendLabels.length
  ) {
    return {
      value: metricGraphDataItem.legendLabels[0],
      secondValue: metricGraphDataItem.legendLabels[1],
    };
  }

  return {
    value: `${generalDataItem.value} (${formatDate(startDate)} - ${formatDate(
      endDate,
    )})`,
    comparedValue:
      comparedStartDate && comparedEndDate
        ? `${generalDataItem.compareValue} (${formatDate(
            comparedStartDate,
          )} - ${formatDate(comparedEndDate)})`
        : undefined,
  };
};

export function getGraphTooltipLabels({
  isCombinedMetric,
  legendLabels,
  isCompared,
  filterState,
}: {
  isCombinedMetric?: boolean;
  legendLabels?: MetricGraphLegendLabels;
  isCompared?: boolean;
  filterState: Pick<
    FilterState,
    'startDate' | 'endDate' | 'comparedEndDate' | 'comparedStartDate'
  >;
}) {
  if (isCombinedMetric && !!legendLabels) {
    return [legendLabels.value!, legendLabels.secondValue!];
  }

  if (isCompared) {
    return [
      `${formatDate(filterState.startDate)} - ${formatDate(
        filterState.endDate,
      )}`,
      `${formatDate(filterState.comparedStartDate!)} - ${formatDate(
        filterState.comparedEndDate!,
      )}`,
    ];
  }

  return undefined;
}
