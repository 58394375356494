import { MODULE_STATUS } from 'constants/modules';
import { createAction } from 'modules/helpers';
import { ModuleStatus } from 'types/ModuleStatus';
import { Dispatch, Store } from 'types/Store';
import Logger from 'utils/logger';
import { ListingsApi } from 'app/api/listings';

export const ListingsActionName = {
  SET_LISTINGS_DATA: 'ListingsAction-SET_LISTINGS_DATA',
  SET_MODULE_STATUS: 'ListingsAction-SET_MODULE_STATUS',
};

export const ListingsActions = {
  setListingsData: createAction<any>(ListingsActionName.SET_LISTINGS_DATA),
  setModuleStatus: createAction<ModuleStatus>(
    ListingsActionName.SET_MODULE_STATUS,
  ),
};

export const ListingsThunks = {
  requestListings: () => async (dispatch: Dispatch, getState: () => Store) => {
    dispatch(ListingsActions.setModuleStatus(MODULE_STATUS.Loading));

    try {
      const listings = await ListingsApi.globalListings();

      dispatch(ListingsActions.setListingsData(listings));

      dispatch(ListingsActions.setModuleStatus(MODULE_STATUS.Succeeded));
    } catch (e) {
      Logger.error(e);
      dispatch(ListingsActions.setModuleStatus(MODULE_STATUS.Failed));
    }
  },
};
