import { FC } from 'react';
import Link from 'components/shared/Link';
import config from 'config';
import { Button } from '@kontentino/ui';

export const PreviewExpired: FC = () => {
  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
      <span>
        The post preview link has expired, please ask the sender to renew it or
        invite you to their Kontentino account.
      </span>
      <Link to={config.KONTENTINO_HOMEPAGE_URL}>
        <Button variant="primary">Back to homepage</Button>
      </Link>
    </div>
  );
};

export default PreviewExpired;
