import { useToast } from 'app/hooks/useToast';
import CommentsApi from 'app/modules/comments/api';
import { useMutation, useQueryClient } from 'react-query';
import { queryKey } from 'constants/queryKey';
import { ApiClientError } from 'api/client';
import i18n from 'i18n';
import { Comment, CommentReaction } from 'app/modules/comments/types';

type ReactionMutationProps = {
  reaction: string;
  onError?: () => void;
};

type UseAddReactionProps = {
  comment: Comment;
  postId: number;
};

export const useAddReaction = ({ comment, postId }: UseAddReactionProps) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const addReactionMutation = useMutation<
    ApiResponse<CommentReaction>,
    ApiClientError,
    ReactionMutationProps,
    {
      onError?: () => void;
    }
  >(
    async ({ reaction }) => {
      return CommentsApi.addReaction({
        postId,
        comment_id: comment.id,
        reaction: reaction,
      });
    },
    {
      onMutate: async ({ onError }) => {
        await queryClient.cancelQueries(queryKey.postCommentsThread());

        return {
          onError,
        };
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKey.postCommentsThread());
      },
      onError(e: ApiClientError, _, context) {
        toast(e.userMessage ?? i18n.somethingWentWrongTryAgain, 'error');
        context?.onError?.();
      },
    },
  );

  function onAddReaction(reactionData: ReactionMutationProps) {
    addReactionMutation.mutate(reactionData);
  }

  return {
    onAddReaction,
    isLoading: addReactionMutation.isLoading,
  };
};
