import React, { FC } from 'react';
import { Icon, TextInput, TextInputProps } from '@kontentino/ui';
import { useTranslation } from 'react-i18next';
import { faClose, faSearch } from '@fortawesome/pro-regular-svg-icons';

type SearchTextInputProps = {
  onClear?: () => void;
} & TextInputProps;

const SearchTextInput: FC<SearchTextInputProps> = ({
  onClear,
  value,
  placeholder,
  ref,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <TextInput
      iconBefore={<Icon icon={faSearch} className="tw-text-grayscale-100" />}
      iconAfter={
        onClear && value?.toString().length ? (
          <Icon
            icon={faClose}
            data-cy="search-field_close-button"
            data-name="search-field_close-button"
            className="tw-cursor-pointer"
            onClick={onClear}
          />
        ) : undefined
      }
      placeholder={placeholder ?? `${t('search')}...`}
      {...props}
    />
  );
};

export default SearchTextInput;
