import type { ModuleStatus } from 'types/ModuleStatus';
import { MODULE_STATUS } from 'constants/modules';
import { Currencies, PostTypesListings } from 'types/Listings';
import { ListingsActionName } from 'modules/listings/listingsActions';

export interface ListingsStore {
  status: ModuleStatus;
  currencies: Currencies;
  postTypes: PostTypesListings;
}

function getInitialState(): ListingsStore {
  return {
    status: MODULE_STATUS.Idle,
    currencies: {
      canHasPercentCurrency: false,
      list: {},
    },
    postTypes: {},
  };
}

function pageReducer(state = getInitialState(), action: any) {
  switch (action.type) {
    case ListingsActionName.SET_MODULE_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case ListingsActionName.SET_LISTINGS_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default pageReducer;
