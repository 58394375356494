import React, { FC } from 'react';
import { Page } from 'app/modules/insights/types/Page';
import BestPostsForMetrics from 'app/modules/insights/modules/postsPerformance/components/BestPostsForMetrics';
import { ALL_METRICS_PROPERTY } from 'app/modules/insights/modules/postsPerformance/utils/metricProperty';
import { MetricProperty } from 'app/modules/insights/types/MetricProperty';
import PageStatistics from 'app/modules/insights/modules/postsPerformance/components/PageStatistics';
import ReportTitle from 'app/modules/public/modules/pdfReports/modules/insightsPostsPerformance/components/reportPostsPerformance/ReportTitle';
import PostsByMetricList from 'app/modules/insights/modules/postsPerformance/components/PostsByMetricList';
import { ALL_PAGES_PAGINATION } from 'app/modules/insights/constants/pagination';
import { Dayjs } from 'dayjs';

type Props = {
  page: Page;
  startDate: Dayjs;
  endDate: Dayjs;
  metric: MetricProperty;
  types: number[];
};

const ReportPostsPerformance: FC<Props> = (props) => {
  return (
    <div>
      <ReportTitle {...props} className="tw-mb-16" />
      <div style={{ breakInside: 'avoid' }} className="tw-mb-4">
        <div className="tw-mb-2">
          <div className="tw-text-xl">Statistics</div>
        </div>
        <PageStatistics {...props} isExpandingDisabled columns={2} />
      </div>
      {props.metric.key === ALL_METRICS_PROPERTY.key && (
        <BestPostsForMetrics {...props} />
      )}
      {props.metric.key !== ALL_METRICS_PROPERTY.key && (
        <div style={{ breakInside: 'avoid' }}>
          <PostsByMetricList
            {...props}
            paginateBy={ALL_PAGES_PAGINATION}
            postCollapsedLayoutVariant="rows"
          />
        </div>
      )}
    </div>
  );
};

export default ReportPostsPerformance;
