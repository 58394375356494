import { Dispatch, Store } from 'types/Store';
import { UserThunks } from 'modules/user/userActions';
import { AccountThunks } from 'modules/account/accountActions';
import { createAction } from 'modules/helpers';
import { MODULE_STATUS } from 'constants/modules';
import { isProductionMode } from 'utils/helpers';
import { AuthApi } from 'api/auth';
import { ListingsThunks } from 'modules/listings/listingsActions';
import FacebookUtils from 'app/utils/facebook';
import Logger from 'utils/logger';
import { BrowserStorage } from 'utils/browserStorage';
import { getBasePath } from 'utils/url';
import i18n from 'i18n';
import { initializeThirdPartApplicationsProperties } from 'app/utils/initializeThirdPartApplicationsProperties';
import { setUserTimePreference } from 'app/utils/setUserTimePreference';
import { showToast } from 'app/hooks/useToast';
import { useAppLayoutState } from 'components/appLayout/appLayoutState';

export const AppActionNames = {
  SET_APP_INITIALIZED: 'AppActionNames-SET_APP_INITIALIZED',
  SET_AUTH_TOKEN: 'AppActionNames-SET_AUTH_TOKEN',
  SET_APP_UNAUTHORIZED: 'AppActionNames-SET_APP_UNAUTHORIZED',
};

export const AppActions = {
  setAppInitialized: createAction(AppActionNames.SET_APP_INITIALIZED),
  setAppUnAuthorized: createAction(AppActionNames.SET_APP_UNAUTHORIZED),
  setAuthToken: createAction<string | null>(AppActionNames.SET_AUTH_TOKEN),
};

const appLayout = useAppLayoutState.getState();

export const AppThunks = {
  initializeApp: () => async (dispatch: Dispatch, getState: () => Store) => {
    try {
      appLayout.hideBackdropLoader();
      const authToken = await AuthApi.getAuthToken();

      if (isProductionMode()) {
        //production uses cookies for getting token, so we make sure we delete it after login
        BrowserStorage.remove(BrowserStorage.keys.AuthToken);
      }

      await dispatch(AppActions.setAuthToken(authToken));

      await Promise.all([
        dispatch(UserThunks.requestUser()),
        dispatch(UserThunks.loadUserPermissions()),
        dispatch(AccountThunks.requestAccount()),
        dispatch(ListingsThunks.requestListings()),
      ]);

      const { user, account, listings } = getState();

      const hasReceivedInitData = [
        user.status,
        account.status,
        listings.status,
      ].every((status) => status === MODULE_STATUS.Succeeded);

      if (hasReceivedInitData) {
        initializeThirdPartApplicationsProperties({ account, user });
        FacebookUtils.init();
        setUserTimePreference(user.useMeridianTime);
        dispatch(AppActions.setAppInitialized());
        return;
      }

      throw new Error('Unable to Authorize User');
    } catch (e: any) {
      dispatch(AppActions.setAppUnAuthorized());
      Logger.warn('Cannot initialize application:', e);
      showToast(i18n.unableToAuthorizeLogin, 'warning');
    }
  },
  logoutUser: () => async (dispatch: Dispatch, getState: () => Store) => {
    try {
      appLayout.showBackdropLoader();
      await AuthApi.logout();
      appLayout.hideBackdropLoader();
      window.location.href = getBasePath();
    } catch (e) {
    } finally {
      appLayout.hideBackdropLoader();
    }
  },
};
