import React, { FC } from 'react';
import { Ad } from 'types/Ad';

const AdsPlannerContext = React.createContext<{
  selectedAds: Ad[];
  adAccountIdFromSelectedAds: number | undefined;
  selectAd: (ad: Ad) => void;
  resetAdsSelection: () => void;
}>({
  selectedAds: [],
  adAccountIdFromSelectedAds: undefined,
  selectAd: () => {},
  resetAdsSelection: () => {},
});

type Props = {
  children: React.ReactNode;
};

const AdsPlannerContextProvider: FC<Props> = ({ children }) => {
  const [selectedAds, setSelectedAds] = React.useState<Ad[]>([]);

  const selectAd = (ad: Ad) => {
    setSelectedAds((prevAds) => {
      const adIds = prevAds.map((ad) => ad.ad_id);

      return adIds.includes(ad.ad_id)
        ? prevAds.filter((prevAd) => prevAd.ad_id !== ad.ad_id)
        : [...prevAds, ad];
    });
  };

  const resetAdsSelection = () => {
    setSelectedAds([]);
  };

  return (
    <AdsPlannerContext.Provider
      value={{
        selectedAds,
        adAccountIdFromSelectedAds: selectedAds[0]?.ad_account.id,
        selectAd,
        resetAdsSelection,
      }}
    >
      {children}
    </AdsPlannerContext.Provider>
  );
};

export default AdsPlannerContextProvider;

export const useAdsPannerContext = () => {
  const context = React.useContext(AdsPlannerContext);

  if (!context) {
    throw new Error('Must be used within AdsPlannerContextProvider');
  }

  return context;
};
