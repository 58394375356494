import React, { createContext, FC, ReactNode, useState } from 'react';
import InfoBanner from 'app/components/InfoBanner';

const InfoBannerContext = createContext<{
  content: ReactNode | null;
  showBanner: (content: ReactNode | null) => void;
  closeBanner: () => void;
}>({
  content: null,
  showBanner: () => null,
  closeBanner: () => null,
});

type Props = {
  children?: ReactNode;
};

const InfoBannerProvider: FC<Props> = ({ children }) => {
  const [content, setContent] = useState<ReactNode>();

  const showBanner = (content: ReactNode | null) => {
    setContent(content);
  };

  const closeBanner = () => {
    setContent(null);
  };

  const getProviderValue = () => ({
    content,
    showBanner,
    closeBanner,
  });

  return (
    <InfoBannerContext.Provider value={getProviderValue()}>
      {content && <InfoBanner content={content} onClose={closeBanner} />}
      {children}
    </InfoBannerContext.Provider>
  );
};

export const useInfoBanner = () => {
  return React.useContext(InfoBannerContext);
};

export default InfoBannerProvider;
