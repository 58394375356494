import { restrictedTypesByMetric } from '../constants/postTypeFilter';

export function getIsMetricDisabled({
  metric,
  pageType,
  optionType,
}: {
  metric: string;
  pageType: number;
  optionType: number;
}) {
  const restrictedMetrics = restrictedTypesByMetric[pageType]?.[metric];
  return restrictedMetrics && !restrictedMetrics.includes(optionType);
}
