import { FC, ReactNode } from 'react';
import Container from 'components/adsPlanner/Container';
import RequestCampaigns from 'components/adsPlanner/campaign/CampaignsListContainer';
import ExportAdsToFacebook from 'components/adsPlanner/ad/exportToFacebook/Container';
import RequestAdAccounts from 'components/adsPlanner/adAccount/RequestAdAccounts';
import SectionLayout from 'components/shared/SectionLayout';
import AdAccountsInitLoader from 'components/adsPlanner/adAccount/AdAccountsInitLoader';
import AdAccountSelection from 'components/adsPlanner/adAccount/AdAccountSelection';
import BaseData from 'components/adsPlanner/BaseData';
import ConnectFirstAdAccount from 'components/adsPlanner/adAccount/ConnectFirstAdAccount';
import AdsPlannerModals from 'components/modals/AdsPlannerModals';
import AdsPlannerLayoutTopBar from 'app/modules/adsPlanner/components/AdsPlannerLayoutTopBar';
import Skeleton from 'react-loading-skeleton';
import { useAdsPannerContext } from 'app/modules/adsPlanner/context';

type Props = {
  children: ReactNode;
};

const AdsPlanner: FC<Props> = ({ children }) => {
  const { selectedAds, resetAdsSelection } = useAdsPannerContext();

  return (
    <>
      <RequestAdAccounts>
        {(adAccounts) => {
          if (adAccounts.isLoading || adAccounts.error.length) {
            return (
              <SectionLayout
                header={adAccounts.isLoading ? <Skeleton height={60} /> : <></>}
                main={<AdAccountsInitLoader error={adAccounts.error} />}
              />
            );
          }

          return (
            <AdAccountSelection data={adAccounts.data}>
              {(adAccount) => {
                if (adAccount.selected.id) {
                  return (
                    <BaseData adAccountId={adAccount.selected.id}>
                      <Container>
                        {(params) => (
                          <>
                            <SectionLayout
                              dataCy="ads-planner-section-layout"
                              header={
                                <ExportAdsToFacebook
                                  ads={selectedAds.map((ad) => ad.ad_id)}
                                  successCallback={() => resetAdsSelection()}
                                >
                                  {(exportAdsParams) => {
                                    return (
                                      <AdsPlannerLayoutTopBar
                                        selectedAdAccountId={
                                          adAccount.selected.id
                                        }
                                        onSelectAdAccount={adAccount.select}
                                        adAccounts={adAccounts.data}
                                        filterData={{
                                          startDate: params.filters.startDate,
                                          endDate: params.filters.endDate,
                                        }}
                                        eventHandlers={{
                                          onSearchChange:
                                            params.changeSearchQuery,
                                          onDateChange:
                                            params.setSelectedDateRange,
                                          onExportToFacebook:
                                            exportAdsParams.exportAds,
                                          onSortOptionSelect:
                                            params.changeOrderDirection,
                                        }}
                                      />
                                    );
                                  }}
                                </ExportAdsToFacebook>
                              }
                              main={
                                <ExportAdsToFacebook
                                  ads={selectedAds.map((ad) => ad.ad_id)}
                                  successCallback={() => resetAdsSelection()}
                                >
                                  {(exportAdsParams) => {
                                    if (!adAccounts.data.length) {
                                      return (
                                        <div className="tw-flex tw-h-full tw-items-center tw-justify-center">
                                          <ConnectFirstAdAccount />
                                        </div>
                                      );
                                    }

                                    return (
                                      <RequestCampaigns>
                                        {children}
                                      </RequestCampaigns>
                                    );
                                  }}
                                </ExportAdsToFacebook>
                              }
                            />
                            <AdsPlannerModals />
                          </>
                        )}
                      </Container>
                    </BaseData>
                  );
                }

                return (
                  <>
                    <SectionLayout
                      dataCy="ads-planner-no-ad-accounts-layout"
                      main={
                        <div className="tw-flex tw-h-full tw-items-center tw-justify-center">
                          <ConnectFirstAdAccount />
                        </div>
                      }
                    />
                    <AdsPlannerModals />
                  </>
                );
              }}
            </AdAccountSelection>
          );
        }}
      </RequestAdAccounts>
    </>
  );
};

export default AdsPlanner;
