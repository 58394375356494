import { TYPE } from '@kontentino/kontentino-constants/Posts';
import {
  COMBINED_MEDIA_DOWNLOAD_SIZES,
  VIDEO_MEDIA_DOWNLOAD_SIZES,
} from 'app/modules/posts/constants';
import { PostDetail } from 'types/PostDetail';

export const getMediaDownloadSizes = (type: PostDetail['type']) => {
  return type === TYPE.VIDEO
    ? VIDEO_MEDIA_DOWNLOAD_SIZES
    : COMBINED_MEDIA_DOWNLOAD_SIZES;
};
