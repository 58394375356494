import { Area, Bar, Line } from 'recharts';
import React, { FC } from 'react';
import GraphDot from './GraphDot';

type Props = {
  variant: 'line' | 'area' | 'bar';
  dataKey: string;
  secondDataKey?: string;
  comparedDataKey?: string;
  comparedSecondDataKey?: string;
  isCompared?: boolean;
};

const primaryColor = '#505FEE';
const secondaryColor = '#99E7FC';

const GraphContent: FC<Props> = ({
  variant,
  dataKey,
  secondDataKey,
  comparedDataKey,
  comparedSecondDataKey,
  isCompared,
}) => {
  const renderLineGraphContent = () => (
    <>
      <Line
        type="linear"
        dataKey={dataKey}
        stroke={primaryColor}
        strokeWidth={3}
        dot={false}
        activeDot={<GraphDot outerFill={primaryColor} />}
        strokeLinecap={'round'}
        isAnimationActive={false}
        strokeLinejoin="round"
      />
      {secondDataKey && (
        <Line
          type="linear"
          dataKey={secondDataKey}
          stroke={secondaryColor}
          strokeWidth={3}
          dot={false}
          activeDot={<GraphDot outerFill={secondaryColor} />}
          strokeLinecap={'round'}
          isAnimationActive={false}
          strokeLinejoin="round"
        />
      )}
      {!secondDataKey && isCompared && comparedDataKey && (
        <Line
          type="linear"
          dataKey={comparedDataKey}
          stroke={secondaryColor}
          strokeWidth={3}
          dot={false}
          activeDot={<GraphDot outerFill={secondaryColor} />}
          strokeLinecap={'round'}
          isAnimationActive={false}
          strokeLinejoin="round"
        />
      )}
    </>
  );

  const renderAreaGraphContent = () => (
    <>
      <Area
        type="monotone"
        dataKey={dataKey}
        stroke={primaryColor}
        strokeWidth={3}
        fill={
          secondDataKey || (isCompared && comparedDataKey)
            ? 'transparent'
            : 'url(#colorValue)'
        }
        activeDot={<GraphDot outerFill={primaryColor} />}
        strokeLinecap={'round'}
        isAnimationActive={false}
      />
      {secondDataKey && (
        <Area
          type="monotone"
          dataKey={secondDataKey}
          stroke={secondaryColor}
          strokeWidth={3}
          fill="transparent"
          activeDot={<GraphDot outerFill={secondaryColor} />}
          strokeLinecap={'round'}
          isAnimationActive={false}
        />
      )}
      {!secondDataKey && isCompared && comparedDataKey && (
        <Area
          type="monotone"
          dataKey={comparedDataKey}
          stroke={secondaryColor}
          strokeWidth={3}
          fill="transparent"
          activeDot={<GraphDot outerFill={secondaryColor} />}
          strokeLinecap={'round'}
          isAnimationActive={false}
        />
      )}
    </>
  );

  const renderBarGraphContent = () => (
    <>
      <Bar
        dataKey={dataKey}
        barSize={16}
        fill={primaryColor}
        radius={[4, 4, 0, 0]}
      />
      {isCompared && secondDataKey && (
        <Bar
          dataKey={secondDataKey}
          fill={secondaryColor}
          radius={[4, 4, 0, 0]}
          barSize={16}
          isAnimationActive={false}
        />
      )}
      {isCompared && comparedDataKey && (
        <Bar
          dataKey={comparedDataKey}
          fill={secondaryColor}
          radius={[4, 4, 0, 0]}
          isAnimationActive={false}
          barSize={16}
        />
      )}
    </>
  );

  switch (variant) {
    case 'line':
      return renderLineGraphContent();
    case 'area':
      return renderAreaGraphContent();
    case 'bar':
      return renderBarGraphContent();
    default:
      return null;
  }
};

export default GraphContent;
