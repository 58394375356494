import { LinkShortenerApi } from 'api/linkShortener';
import AppConfig from 'app/config/app';
import Modal from 'components/shared/Modal';
import { queryKey } from 'constants/queryKey';
import { ModalRouteProps } from 'context/ModalRouter';
import { useUser } from 'modules/user/userSelector';
import { FC } from 'react';
import { useQuery } from 'react-query';
import { createSearchFromParams } from 'utils/url';
import CopyToClipboard from './referralModal/CopyToClipboard';
import ReferralRewardsList from './referralModal/ReferralRewardsList';
import TOPIMAGE from './images/topImage.png';

type Props = ModalRouteProps;

const ReferralModal: FC<Props> = (props) => {
  const user = useUser();

  const { data, isLoading } = useQuery(
    queryKey.referralLink(user.email),
    () => {
      const params = createSearchFromParams({
        utm_source: 'referral',
        utm_medium: user.email,
      });

      return LinkShortenerApi.save({
        url: `${AppConfig.REFERRAL_BASE}${params}`,
      });
    },
  );

  return (
    <Modal
      modalManager={props.modalManager}
      dataName={{
        wrapper: 'referral-program-modal',
      }}
      dataCy={{
        wrapper: 'referral-program-modal',
        closeButton: 'referral-program-modal-close-button',
      }}
    >
      <Modal.Header />
      <Modal.Content>
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-6">
          <div className="tw-flex tw-justify-center">
            <img
              src={TOPIMAGE}
              alt="Referral specialist"
              width={254}
              height={202}
            />
          </div>

          <div className="tw-mt-8">
            <div className="tw-mb-6 tw-text-center">
              <div className="tw-text-xl">Refer us & get a gift 🎁</div>
            </div>

            <div className="tw-text-left tw-text-md tw-font-regular">
              Send this unique link to your friends. Once they register and opt
              in to one of our paid plans, you will receive:
            </div>

            <ReferralRewardsList />

            <div className="tw-mb-6 tw-text-md tw-font-regular">
              As a bonus, your friends <strong>will get a free month</strong>{' '}
              off their first subscription.
            </div>
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer>
        <div className="tw-w-full">
          <CopyToClipboard value={data?.shortUrl ?? ''} isLoading={isLoading} />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ReferralModal;
