import React from 'react';
import UserPermissionGate from 'components/UserPermissionGate';
import { UserPermission } from 'constants/userPermission';
import { useSwitchToOldModule } from 'app/hooks/useSwitchToOldModule';
import { DropdownMenu, Icon } from '@kontentino/ui';
import {
  faCog,
  faCreditCard,
  faFolder,
  faGear,
  faGlobe,
  faRepeat,
  faShareNodes,
  faUserGroup,
} from '@fortawesome/pro-regular-svg-icons';
import Link from 'components/shared/Link';
import VerticalNavigationMenuOption from '../VerticalNavigationMenuOption';
import VerticalNavigationDropdownOption from './UserActionDropdownOption';
import { VerticalMenuItemWrapper } from '../VerticalMenuItemWrapper';
import { Module } from 'config';
import { useTranslation } from 'react-i18next';
import useSubscriptionInfo from 'app/hooks/useSubscriptionInfo';
import { hasPermissions } from '@kontentino/react-permissions-gate';
import { useUser } from 'modules/user/userSelector';

type Props = {};

const Settings: React.FC<Props> = () => {
  const { switchToOldModule, label: switchToOldModuleLabel } =
    useSwitchToOldModule();
  const { permissionsKeys } = useUser();
  const { t } = useTranslation();
  const { isTrial } = useSubscriptionInfo();

  const hasAccessToAtLeastOneTab =
    hasPermissions(permissionsKeys, [
      UserPermission.ACCESS_PROFILES,
      UserPermission.ACCESS_USERS,
      UserPermission.ACCESS_PROFILE_GROUPS,
      isTrial
        ? UserPermission.ACCESS_GLOBAL_PAGES
        : UserPermission.ACCESS_USERS,
      UserPermission.ACCESS_BILLING,
      UserPermission.ACCESS_COMPANY_SETTINGS,
    ]) || !!switchToOldModuleLabel;

  if (!hasAccessToAtLeastOneTab) {
    return null;
  }

  return (
    <VerticalMenuItemWrapper>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger data-name="navbar_settings">
          <VerticalNavigationMenuOption
            icon={<Icon icon={faCog} />}
            label={t('navigation.userSubActions.settings')}
          />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
          side="right"
          sideOffset={-10}
          className="tw-z-10 tw-w-[240px]"
        >
          {switchToOldModuleLabel && (
            <>
              <DropdownMenu.Item
                data-name="navbar_settings_old-module"
                onSelect={switchToOldModule}
              >
                <VerticalNavigationDropdownOption
                  icon={<Icon icon={faRepeat} size="sm" />}
                  label={switchToOldModuleLabel}
                />
              </DropdownMenu.Item>
              <DropdownMenu.Separator />
            </>
          )}
          <UserPermissionGate scopes={UserPermission.ACCESS_PROFILES}>
            <Link to={Module.OldApp.URL.SETTINGS.PROFILES}>
              <DropdownMenu.Item data-name="navbar_settings_profiles">
                <VerticalNavigationDropdownOption
                  icon={<Icon icon={faShareNodes} size="sm" />}
                  label={t('navigation.userSubActions.profiles')}
                />
              </DropdownMenu.Item>
            </Link>
          </UserPermissionGate>
          <UserPermissionGate scopes={UserPermission.ACCESS_PROFILE_GROUPS}>
            <Link to={Module.OldApp.URL.SETTINGS.PROFILE_GROUPS}>
              <DropdownMenu.Item data-name="navbar_settings_profile-groups">
                <VerticalNavigationDropdownOption
                  icon={<Icon icon={faFolder} size="sm" />}
                  label={t('navigation.userSubActions.profileGroups')}
                />
              </DropdownMenu.Item>
            </Link>
          </UserPermissionGate>

          <UserPermissionGate scopes={UserPermission.ACCESS_USERS}>
            <Link to={Module.OldApp.URL.SETTINGS.USERS}>
              <DropdownMenu.Item data-name="navbar_settings_users">
                <VerticalNavigationDropdownOption
                  icon={<Icon icon={faUserGroup} size="sm" />}
                  label={t('navigation.userSubActions.users')}
                />
              </DropdownMenu.Item>
            </Link>
          </UserPermissionGate>

          <UserPermissionGate
            scopes={
              isTrial
                ? UserPermission.ACCESS_GLOBAL_PAGES
                : UserPermission.ACCESS_USERS
            }
          >
            <Link to={Module.OldApp.URL.SETTINGS.KONTENT_BASE}>
              <DropdownMenu.Item data-name="navbar_settings_kontentbase">
                <VerticalNavigationDropdownOption
                  icon={<Icon icon={faGlobe} size="sm" />}
                  label={t('navigation.userSubActions.kontentBase')}
                />
              </DropdownMenu.Item>
            </Link>
          </UserPermissionGate>

          <UserPermissionGate scopes={UserPermission.ACCESS_BILLING}>
            <>
              <DropdownMenu.Separator />
              <Link to={Module.OldApp.URL.SETTINGS.BILLING_DASHBOARD}>
                <DropdownMenu.Item data-name="navbar_settings_billing">
                  <VerticalNavigationDropdownOption
                    icon={<Icon icon={faCreditCard} size="sm" />}
                    label={t('navigation.userSubActions.billingDashboard')}
                  />
                </DropdownMenu.Item>
              </Link>
            </>
          </UserPermissionGate>

          <UserPermissionGate scopes={UserPermission.ACCESS_COMPANY_SETTINGS}>
            <>
              <DropdownMenu.Separator />
              <Link to={Module.OldApp.URL.SETTINGS.COMPANY_SETTINGS}>
                <DropdownMenu.Item data-name="navbar_settings_company">
                  <VerticalNavigationDropdownOption
                    icon={<Icon icon={faGear} size="sm" />}
                    label={t('navigation.userSubActions.general')}
                  />
                </DropdownMenu.Item>
              </Link>
            </>
          </UserPermissionGate>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </VerticalMenuItemWrapper>
  );
};

export default Settings;
