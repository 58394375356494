import React, { FC } from 'react';
import Container from 'components/insights/exports/Container';
import { PageNumberType } from '@kontentino/kontentino-constants/Pages';
import { Page } from 'app/modules/insights/types/Page';
import { DropdownMenu, Icon, IconButton } from '@kontentino/ui';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';

type Props = {
  page: Page;
};

const PagePerformanceExportsDropdown: FC<Props> = ({ page }) => {
  const { t } = useTranslation();

  return (
    <Container pageType={page._type as PageNumberType}>
      {(params) => (
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <IconButton
              icon={<Icon icon={faArrowDownToLine} />}
              variant="secondary"
              className="tw-h-[36px]"
              disabled={!page}
              data-name="insights-page-performance-download"
            />
          </DropdownMenu.Trigger>
          <DropdownMenu.Content className="tw-z-10">
            <DropdownMenu.Item
              onClick={() => params.onExport('csv')}
              data-name="insights-page-performance-download_csv"
            >
              <Icon icon={faArrowDownToLine} />
              {t('exportAs', { type: 'CSV' })}
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      )}
    </Container>
  );
};

export default PagePerformanceExportsDropdown;
