import React, { FC } from 'react';
import { Page } from 'app/modules/insights/types/Page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays } from '@fortawesome/pro-regular-svg-icons';
import DateUtils, { formatDate } from 'app/utils/date';
import { ProfileAvatar } from '@kontentino/kontentino-ui';
import { MetricProperty } from 'app/modules/insights/types/MetricProperty';
import { Dayjs } from 'dayjs';

type Props = {
  page: Page;
  startDate: Dayjs;
  endDate: Dayjs;
  metric: MetricProperty;
  className?: string;
};

const ReportTitle: FC<Props> = ({
  metric,
  startDate,
  endDate,
  page,
  className,
}) => {
  return (
    <div className={className}>
      <div className="tw-text-2xl">Posts Performance - {metric.title}</div>
      <div className="tw-mt-8 tw-flex tw-gap-8">
        <div className="tw-flex tw-items-center">
          <ProfileAvatar
            size={32}
            profileType={page._type}
            url={page.picture}
          />
          <div className="tw-ml-4">
            <span>{page.name}</span>
          </div>
        </div>
        <div className="tw-flex tw-items-center">
          <div className="tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-sm tw-bg-grayscale-5">
            <FontAwesomeIcon
              icon={faCalendarDays}
              size="lg"
              className="tw-text-grayscale-100"
            />
          </div>
          <div className="tw-ml-4">
            <span>{`${formatDate(
              DateUtils.toDateString(startDate),
            )} - ${formatDate(DateUtils.toDateString(endDate))}`}</span>
          </div>
        </div>
        <div className="tw-flex tw-items-center">
          <div className="tw-ml-4">
            <span>{metric.title}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportTitle;
