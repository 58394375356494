import React, { FC } from 'react';
import clsx from 'clsx';
import { formatDateTimeUTCToLocalTimezone } from 'app/utils/date';
import DemoBadge from 'app/components/DemoBadge';
import { useTranslation } from 'react-i18next';
import { User } from 'types/User';
import { Comment } from 'app/modules/comments/types';

type Props = {
  comment: Comment;
  isDemo?: boolean;
  className?: string;
  currentUser: Pick<User, 'role'>;
};

const CommentsListItemHeader: FC<Props> = ({
  comment: { user, created_at },
  isDemo,
  className,
  currentUser,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'tw-flex tw-items-center tw-gap-2',
        {
          'tw-justify-end': user.its_me,
        },
        className,
      )}
    >
      {user.role === 'client' && currentUser.role !== 'client' && (
        <span className="tw-text-sm tw-font-semibold tw-capitalize tw-text-purple-100">
          {user.role}
        </span>
      )}
      <span className="tw-text-sm tw-font-semibold tw-text-grayscale-190">
        {user.its_me ? t('me') : user.name}
      </span>
      <span className="tw-text-sm tw-font-regular tw-text-grayscale-50">
        {formatDateTimeUTCToLocalTimezone(created_at)}
      </span>
      {isDemo && <DemoBadge variant="light" />}
    </div>
  );
};

export default CommentsListItemHeader;
