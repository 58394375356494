import { useMutation, useQueryClient } from 'react-query';
import { useToast } from 'app/hooks/useToast';
import CommentApi from 'app/modules/comments/api';
import { queryKey } from 'constants/queryKey';
import { ApiClientError } from 'api/client';
import { usePostCommunicationContext } from 'app/modules/posts/modules/post/components/postCommunication/PostCommunicationProvider';
import { useTranslation } from 'react-i18next';
import Popup from 'utils/popup';

export type DeleteCommentParams = {
  postId: number;
};

type DeleteCommentProps = {
  comment_id: number;
};

export function useDeleteComment({ comment_id }: DeleteCommentProps) {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { t } = useTranslation();
  const { postId } = usePostCommunicationContext();

  const mutation = useMutation(
    async (values: DeleteCommentParams) => {
      return CommentApi.remove({
        ...values,
        comment_id,
      });
    },
    {
      onSuccess: async (response, { postId }) => {
        await queryClient.refetchQueries(queryKey.postCommentsThread());
        queryClient.invalidateQueries(queryKey.postPreview(postId));
        queryClient.invalidateQueries(queryKey.postDetail(postId));
        toast(t('commentActions.delete.success'));
      },
      onError(e: ApiClientError) {
        toast(e?.userMessage ?? t('somethingWentWrong'), 'error');
      },
    },
  );

  async function onDelete() {
    const { isConfirmed } = await Popup.confirm();

    if (isConfirmed) {
      mutation.mutate({
        postId,
      });
    }
  }

  return {
    onDelete,
    isLoading: mutation.isLoading,
  };
}
