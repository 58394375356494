import type { ModuleStatus } from 'types/ModuleStatus';
import { PostActionName } from 'modules/post/postActions';
import { MODULE_STATUS } from 'constants/modules';
import { appendIndexToPosts } from 'modules/post/utils';
import { PostDetail } from 'types/PostDetail';
import DateUtils from 'app/utils/date';

export interface PostStore {
  status: ModuleStatus;
  posts: PostDetail[];
  totalCount: number;
}

function getInitialState(): PostStore {
  return {
    status: MODULE_STATUS.Idle,
    posts: [],
    totalCount: 0,
  };
}

function postReducer(state = getInitialState(), action: any) {
  switch (action.type) {
    case PostActionName.SET_MODULE_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    case PostActionName.SET_POSTS_DATA:
      return {
        ...state,
        totalCount: action.payload.totalCount,
        posts: appendIndexToPosts(action.payload.posts),
      };

    case PostActionName.EDIT_POST_STATUS:
      const currentPosts = state.posts.map((post) => {
        return post.id === action.payload.postId
          ? {
              ...post,
              status: action.payload.status,
            }
          : post;
      });

      return {
        ...state,
        posts: currentPosts,
      };

    case PostActionName.EDIT_POST_DATE:
      const newDate = !!action.payload.date
        ? DateUtils.toDateString(action.payload.date)
        : null;

      const posts = state.posts.map((post) => {
        if (post.id === action.payload.id) {
          return {
            ...post,
            date: newDate,
            date_raw: newDate,
          };
        }

        return post;
      });

      return {
        ...state,
        posts: appendIndexToPosts(posts),
      };

    case PostActionName.APPEND_POST:
      return {
        ...state,
        posts: appendIndexToPosts([...state.posts, action.payload]),
      };
    case PostActionName.REMOVE_TEMPORARY_POSTS:
      return {
        ...state,
        posts: state.posts.filter((post) => post.id > 0),
      };
    case PostActionName.UPDATE_POST:
      return {
        ...state,
        posts: state.posts.map((post) =>
          post.id === action.payload.postId
            ? {
                ...post,
                ...action.payload.post,
              }
            : post,
        ),
      };
    case PostActionName.SWAP_POSTS_INDEX:
      const newPosts = [...state.posts];

      const post = newPosts[action.payload.from];
      newPosts[action.payload.from] = newPosts[action.payload.to];
      newPosts[action.payload.to] = post;

      return {
        ...state,
        posts: newPosts,
      };
    default:
      return state;
  }
}

export default postReducer;
