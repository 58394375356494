import { Icon, Popover, Tooltip } from '@kontentino/ui';
import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { faSmile } from '@fortawesome/pro-regular-svg-icons';
import EmojiPicker from 'app/components/EmojiPicker';
import { useTranslation } from 'react-i18next';
import { useReactionSummary } from '../../providers/ReactionSummaryProvider';
import { CommentsConfig } from 'app/modules/comments/config';

type Props = {
  size?: number;
  iconClassName?: string;
};

const CommentsListItemEmojiPicker: FC<Props> = ({ iconClassName, size }) => {
  const { t } = useTranslation();
  const { onEmojiSelect, reactions } = useReactionSummary();

  const [isEmojiPickerShown, setIsEmojiPickerShown] = useState(false);

  const canShowEmojiPicker =
    reactions.length < CommentsConfig.COMMENT_REACTIONS_LIMIT;

  const handleIsEmojiPickerShown = (val: boolean) => {
    if (canShowEmojiPicker) {
      setIsEmojiPickerShown(val);
    }
  };

  useEffect(() => {
    if (!canShowEmojiPicker) {
      setIsEmojiPickerShown(false);
    }
  }, [canShowEmojiPicker]);

  return (
    <Popover.Root
      open={isEmojiPickerShown}
      onOpenChange={handleIsEmojiPickerShown}
    >
      <Popover.Trigger>
        <Tooltip
          content={
            !canShowEmojiPicker ? t('reactionsLimitReached') : t('react')
          }
        >
          <div
            className="tw-flex tw-items-center tw-justify-center"
            data-name="comments_comment_reactions_emoji-picker"
          >
            <Icon
              icon={faSmile}
              size={size}
              className={clsx(`${iconClassName}`, {
                'tw-cursor-not-allowed tw-opacity-20': !canShowEmojiPicker,
              })}
            />
          </div>
        </Tooltip>
      </Popover.Trigger>
      {canShowEmojiPicker && (
        <Popover.Portal>
          <Popover.Content className="tw-z-[1002]">
            <EmojiPicker
              onEmojiSelect={(emoji) => {
                onEmojiSelect(emoji);
                setIsEmojiPickerShown(false);
              }}
            />
          </Popover.Content>
        </Popover.Portal>
      )}
    </Popover.Root>
  );
};

export default CommentsListItemEmojiPicker;
