import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const createApolloClient = (
  uri: string,
  headers?: Record<string, string>,
): ApolloClient<NormalizedCacheObject> => {
  const authLink = setContext((_, ctx) => ({
    headers: {
      ...ctx.headers,
      ...headers,
    },
  }));

  return new ApolloClient({
    link: ApolloLink.from([authLink, new HttpLink({ uri })]),
    cache: new InMemoryCache(),
  });
};

export default createApolloClient;
