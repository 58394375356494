import { FC, ReactNode, useState } from 'react';
import Header from 'components/insights/infoCard/Header';
import { GraphContent, GraphStructure } from 'types/insights/Graphs';
import { GraphType } from 'constants/analytics';
import Graphs from 'components/insights/Graphs';
import NoDataMessage from './postsPerformance/postsList/singlePost/NoDataMessage';

type Props = {
  title: string;
  graph: GraphStructure;
  data: GraphContent[];
  secondaryDataKey?: string | null;
  children?: React.ReactNode;
  isOnExport?: boolean;
  onChangeSelectedGraphType?: (type: GraphType) => void;
  selectedGraphType?: GraphType;
  primaryDataKey?: string;
  footer?: ReactNode;
  shouldDisplayAllTicks?: boolean;
  dataName?: {
    card?: string;
  };
};

const GraphInfoCard: FC<Props> = (props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(
    props.data.length !== 0 || !!props.isOnExport,
  );
  const [activeGraphType, setActiveGraphType] = useState<GraphType>(
    props.selectedGraphType ?? props.graph.selectedGraphType,
  );

  const handleSetGraphType = (type: GraphType) => {
    setActiveGraphType(type);
    props.onChangeSelectedGraphType?.(type);
  };

  return (
    <div
      className="tw-mb-6 tw-rounded-md tw-border tw-border-solid tw-border-grayscale-5 tw-bg-white tw-shadow-md"
      data-name={props.dataName?.card}
    >
      <Header
        title={props.title}
        isExpanded={isExpanded}
        onExpandToggle={() => setIsExpanded(!isExpanded)}
        availableGraphTypes={props.graph.availableGraphTypes}
        onChangeGraphType={handleSetGraphType}
        activeGraphType={activeGraphType}
        isOnExport={props.isOnExport}
      />
      {isExpanded && (
        <>
          {!!props.children && (
            <div className="tw-p-6 tw-pt-0">{props.children}</div>
          )}
          <div className="tw-h-[300px] tw-p-6 tw-pt-0">
            {props.data.length === 0 ? (
              <NoDataMessage />
            ) : (
              <Graphs
                primaryDataKey={
                  props.primaryDataKey ?? props.graph.primaryDataKey
                }
                graphType={activeGraphType}
                data={props.data}
                secondaryDataKey={props.secondaryDataKey}
                isOnExport={props.isOnExport}
                shouldDisplayAllTicks={props.shouldDisplayAllTicks}
              />
            )}
          </div>
          {props.footer}
        </>
      )}
    </div>
  );
};

export default GraphInfoCard;
