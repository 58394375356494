import { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEY } from 'components/adsPlanner/constants';
import { useToast } from 'app/hooks/useToast';
import i18n from 'i18n';
import AdsPlannerApi from 'api/adsplanner';

type Params = {
  updateStatuses(): void;
  isLoading: boolean;
};

type Props = {
  adAccountId: number;
  children(params: Params): JSX.Element;
};

const RequestUpdateAllStatuses: FC<Props> = ({ children, adAccountId }) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutate: updateStatuses, isLoading } = useMutation(
    () => AdsPlannerApi.adAccount.updateAllStatuses(adAccountId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.CAMPAIGNS);
        toast(i18n.adAccountStatusesUpdated, 'success');
      },
      onError: () => {
        toast(i18n.somethingWentWrongTryAgain, 'error');
      },
    },
  );

  return children({
    updateStatuses,
    isLoading,
  });
};

export default RequestUpdateAllStatuses;
