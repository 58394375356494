import { FC, useState } from 'react';
import { STEPS } from 'components/workflowOptions/sendForApproval/utils/constants';
import ModalRoute from 'components/workflowOptions/sendForApproval/modal/Route';
import { ModalManager } from 'context/ModalRouter';

type Props = {
  type: ApprovalType;
  onSuccessFormResponse?: AnyFunction;
  modalManager: ModalManager;
};

const steps = Object.values(STEPS);

const SendForApprovalWithPostsSelection: FC<Props> = ({
  type,
  onSuccessFormResponse,
  modalManager,
}) => {
  const [activeStep, setActiveStep] = useState<number>(steps[0].step);

  const goNext = () => {
    setActiveStep((prevStep) => {
      const nextStep = prevStep + 1;

      if (nextStep > steps[steps.length - 1].step) {
        return steps[steps.length - 1].step;
      }

      return nextStep;
    });
  };

  const goBack = () => {
    setActiveStep((prevStep) => {
      const nextStep = prevStep - 1;

      if (prevStep < steps[0].step) {
        return steps[0].step;
      }

      return nextStep;
    });
  };

  return (
    <ModalRoute
      type={type}
      activeStep={activeStep}
      onGoNext={goNext}
      onGoBack={goBack}
      onSuccessFormResponse={onSuccessFormResponse}
      modalManager={modalManager}
      dataNamePrefix={`workflow-options-dropdown_${type}-approval`}
    />
  );
};

export default SendForApprovalWithPostsSelection;
