import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { Popover } from '@kontentino/ui';
import { Editor, isNodeSelection, posToDOMRect } from '@tiptap/core';
import { preventDefault } from 'utils/mouseEvent';
import Modal from 'components/shared/Modal';

type Props = {
  children: ReactNode;
  onOpenChange: (value: boolean) => void;
  visible: boolean;
  editor: Editor;
  isRefining: boolean;
};

const HEIGHT_BREAKPOINT_TO_MODAL = 900;

const TextEditorAiContentRefineTextSelectionPopover: FC<Props> = ({
  children,
  onOpenChange,
  isRefining,
  visible,
  editor,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [hasModalView, setHasModalView] = useState(false);

  useEffect(() => {
    function createVirtualElement(editor: Editor) {
      const div = document.createElement('div');
      div.getBoundingClientRect = function getBoundingClientRect() {
        // The logic is taken from the positioning implementation in Tiptap's BubbleMenuPlugin
        // https://github.com/ueberdosis/tiptap/blob/16bec4e9d0c99feded855b261edb6e0d3f0bad21/packages/extension-bubble-menu/src/bubble-menu-plugin.ts#L183-L193
        const selection = editor.extensionStorage.aiContent.lastSelection as
          | Editor['state']['selection']
          | undefined;

        if (!selection) {
          return posToDOMRect(editor.view, 0, 0);
        }

        const { ranges } = selection;
        const from = Math.min(...ranges.map((range) => range.$from.pos));
        const to = Math.max(...ranges.map((range) => range.$to.pos));

        if (isNodeSelection(selection)) {
          const node = editor.view.nodeDOM(from) as HTMLElement;

          if (node) {
            return node.getBoundingClientRect();
          }
        }

        return posToDOMRect(editor.view, from, to);
      };

      return div;
    }

    ref.current = createVirtualElement(editor);
    setHasModalView(window.innerHeight < HEIGHT_BREAKPOINT_TO_MODAL);
  }, [ref, editor, setHasModalView]);

  if (hasModalView && isRefining) {
    return (
      <Modal
        open={visible}
        onClose={() => onOpenChange?.(false)}
        classNames={{
          modal: '!tw-w-[480px]',
        }}
      >
        {children}
      </Modal>
    );
  }

  return (
    <Popover.Root open={visible} onOpenChange={onOpenChange}>
      <Popover.Anchor virtualRef={ref} />
      <Popover.Portal>
        <Popover.Content
          side="bottom"
          sideOffset={8}
          onOpenAutoFocus={preventDefault()}
          onCloseAutoFocus={preventDefault()}
          className="tw-z-[1002]"
        >
          {children}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default TextEditorAiContentRefineTextSelectionPopover;
