import React, { FC } from 'react';
import { Switch } from '@kontentino/kontentino-ui/build/evergreenUI';
import { AnalyticsAdAccount } from 'types/AdAccount';

type Props = {
  adAccounts: AnalyticsAdAccount[];
  onChange: (adAccount: AnalyticsAdAccount) => void;
  isConnected: (adAccount: AnalyticsAdAccount) => boolean;
};

export const AdAccountsList: FC<Props> = ({
  adAccounts,
  onChange,
  isConnected,
}) => {
  return (
    <div className="tw-flex tw-flex-col">
      {adAccounts.map((adAccount) => (
        <div
          className="tw-flex tw-items-center tw-p-1"
          key={adAccount.facebookAdAccountId}
        >
          <Switch
            className="tw-my-0 tw-ml-0 tw-mr-3"
            height={20}
            onClick={(e) => {
              e.preventDefault();
              onChange(adAccount);
            }}
            checked={isConnected(adAccount)}
          />
          {adAccount.name}
        </div>
      ))}
    </div>
  );
};

export default AdAccountsList;
