import { useMemo, useState } from 'react';
import { AdAccount } from 'types/AdAccount';
import getFilteredDataBySearchQuery from 'app/components/profileSelector/utils/getFilteredDataBySearchQuery';

const useAdAccountsSelectorFilter = (adAccounts: AdAccount[]) => {
  const [searchQuery, setSearchQuery] = useState('');

  const filteredAdAccounts = useMemo(() => {
    return getFilteredDataBySearchQuery<AdAccount>(searchQuery, adAccounts);
  }, [adAccounts, searchQuery]);

  return {
    searchQuery,
    setSearchQuery,
    filteredAdAccounts,
  };
};

export default useAdAccountsSelectorFilter;
