import React, { FC, useContext } from 'react';
import type { InsightsState } from 'components/insights/Container';

const InsightsContext = React.createContext<InsightsState | undefined>(
  undefined,
);

type Props = {
  value: InsightsState;
  children: React.ReactNode;
};

export const Provider: FC<Props> = ({ value, children }) => (
  <InsightsContext.Provider value={value}>{children}</InsightsContext.Provider>
);

export const useInsightsContainer = () => {
  const context = useContext(InsightsContext);

  if (!context) {
    throw new Error('Must be used within InsightsContainerProvider');
  }

  return context;
};
