import { Account } from 'types/Account';
import { User } from 'types/User';
import IntercomUtils from 'app/utils/intercom';
import UserpilotUtils from 'app/utils/userpilot';
import SentryUtils from 'app/utils/sentry';
import SatismeterUtils from 'app/utils/satismeter';
import { identifyBugpilotUser } from 'app/utils/identifyBugpilotUser';
import HeapUtils from 'app/utils/heap';

export const initializeThirdPartApplicationsProperties = (inputData: {
  account: Account;
  user: User;
}) => {
  const { user, account } = inputData;

  if (user.intercom) {
    IntercomUtils.init(user.intercom);
    UserpilotUtils.identify({
      ...user.intercom,
      id: user.id,
      accountPublicId: account.publicId,
    });
    SentryUtils.identify(user.intercom);
    SatismeterUtils.init(user.intercom);
    identifyBugpilotUser(user.intercom);
    HeapUtils.identifyUser(account, user);
    HeapUtils.setEventProperties(account);
  }
};
