import 'scroll-behavior-polyfill';
import SentryUtils from 'app/utils/sentry';
import UserpilotUtils from 'app/utils/userpilot';
import Pusher from 'pusher-js';
import PusherConfig from 'app/config/pusher';

export default function beforeInit() {
  SentryUtils.init();
  UserpilotUtils.init();
  Pusher.logToConsole = PusherConfig.LOG_TO_CONSOLE;
}
