import Modal from 'components/shared/Modal';
import { ModalRouteProps } from 'context/ModalRouter';
import { FC } from 'react';
import PROFILE_IMAGE from './images/profileLimitReached@3x.png';
import { useTranslation } from 'react-i18next';
import { Button } from '@kontentino/ui';
import { openBlank } from 'utils/url';
import OldApp from 'config/oldApp';
import { useAccount } from 'modules/account/accountSelector';

type Props = ModalRouteProps & {
  variant?: keyof typeof MODAL_VARIANTS;
};

const MODAL_VARIANTS = {
  // this will contain more variants. example: user limit
  profile: {
    image: PROFILE_IMAGE,
    title: 'limitReached.profile.title',
    description: {
      starter: 'limitReached.profile.description.starter',
      standard: 'limitReached.profile.description.standard',
    },
    cta: 'limitReached.profile.cta',
  },
};

const PlanLimitReachedModal: FC<Props> = ({
  variant = 'profile',
  ...props
}) => {
  const key = `${variant}-limit-reached-modal`;
  const { t } = useTranslation();
  const account = useAccount();

  const planType =
    account.plan.label.toLowerCase() === 'starter' ? 'starter' : 'standard';

  const { image, title, description, cta } = MODAL_VARIANTS[variant];

  return (
    <Modal
      modalManager={props.modalManager}
      classNames={{
        modal: '!tw-w-[400px]',
      }}
      dataName={{
        wrapper: key,
      }}
      dataCy={{
        wrapper: key,
      }}
    >
      <Modal.Content>
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-pt-6">
          <img src={image} alt="plan limit" className="tw-h-auto tw-w-full" />

          <div className="tw-mb-4 tw-mt-4">
            <div className="tw-mb-1 tw-text-center">
              <div className="tw-text-lg tw-font-semibold tw-text-grayscale-180">
                {t(title)}
              </div>
            </div>

            <div className="tw-text-center tw-text-md tw-font-regular tw-text-grayscale-140">
              {t(description[planType])}
            </div>
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer className="tw-gap-3">
        <Button
          className="tw-flex-1"
          variant="secondary"
          onClick={props.modalManager.close}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="primary"
          className="tw-flex-1"
          onClick={() => openBlank(OldApp.URL.BILLING_PLANS)}
        >
          {t(cta)}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PlanLimitReachedModal;
