import { FC } from 'react';
import IntercomUtils from 'app/utils/intercom';
import { DropdownMenu, Icon } from '@kontentino/ui';
import {
  faBook,
  faCalendar,
  faComment,
  faCompass,
  faInfoCircle,
  faQuestionCircle,
  faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
import VerticalNavigationDropdownOption from './UserActionDropdownOption';
import VerticalNavigationMenuOption from '../VerticalNavigationMenuOption';
import { VerticalMenuItemWrapper } from '../VerticalMenuItemWrapper';
import { Module } from 'config';
import { useTranslation } from 'react-i18next';

type Props = {};

const HelpCenter: FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <VerticalMenuItemWrapper>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger data-name="navbar_help-center">
          <VerticalNavigationMenuOption
            icon={<Icon size="md" icon={faQuestionCircle} />}
            label={t('navigation.userSubActions.helpCenter')}
          />
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            side="right"
            sideOffset={-10}
            className="tw-z-[99999] tw-w-[244px]"
          >
            <Link to={Module.OldApp.URL.HELP_CENTER.TUTORIALS} target="_blank">
              <DropdownMenu.Item data-name="navbar_help-center_tutorials">
                <VerticalNavigationDropdownOption
                  icon={<Icon icon={faInfoCircle} />}
                  label={t('navigation.userSubActions.tutorials')}
                />
              </DropdownMenu.Item>
            </Link>
            <Link
              to={Module.OldApp.URL.HELP_CENTER.RESOURCE_CENTER}
              target="_blank"
            >
              <DropdownMenu.Item data-name="navbar_help-center_resource">
                <VerticalNavigationDropdownOption
                  icon={<Icon icon={faBook} />}
                  label={t('navigation.userSubActions.resourceCenter')}
                />
              </DropdownMenu.Item>
            </Link>

            <Link to={Module.OldApp.URL.HELP_CENTER.BOOK_DEMO} target="_blank">
              <DropdownMenu.Item data-name="navbar_help-center_demo">
                <VerticalNavigationDropdownOption
                  icon={<Icon icon={faCalendar} />}
                  label={t('navigation.userSubActions.bookDemo')}
                />
              </DropdownMenu.Item>
            </Link>
            <Link
              to={Module.OldApp.URL.HELP_CENTER.PRODUCT_ROADMAP}
              target="_blank"
            >
              <DropdownMenu.Item data-name="navbar_help-center_roadmap">
                <VerticalNavigationDropdownOption
                  icon={<Icon icon={faCompass} />}
                  label={t('navigation.userSubActions.productRoadmap')}
                />
              </DropdownMenu.Item>
            </Link>

            <DropdownMenu.Item
              data-name="navbar_help-center_help"
              onSelect={() =>
                IntercomUtils.newConversation('Hello! Just having a question')
              }
            >
              <VerticalNavigationDropdownOption
                icon={<Icon icon={faComment} />}
                label={t('navigation.userSubActions.getHelp')}
              />
            </DropdownMenu.Item>

            <DropdownMenu.Item
              data-name="navbar_help-center_report"
              onSelect={() =>
                IntercomUtils.newConversation(
                  'Hi! Having an issue with Kontentino here',
                )
              }
            >
              <VerticalNavigationDropdownOption
                icon={<Icon icon={faTriangleExclamation} />}
                label={t('navigation.userSubActions.reportIssue')}
              />
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </VerticalMenuItemWrapper>
  );
};

export default HelpCenter;
