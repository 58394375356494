import { useLocation, useNavigate } from 'react-router-dom';
import * as url from 'utils/url';
import { useCallback, useMemo } from 'react';

type ParamValue = string | number | string[] | number[] | boolean;

type Options = { push?: boolean };

const defaultOptions = { push: false };

export function useHistoryQueryParams(
  hookOptions: Options | undefined = defaultOptions,
) {
  const location = useLocation();
  const navigate = useNavigate();

  const navigateWithParams = useCallback(
    (urlParams: URLSearchParams, options: Options = hookOptions) => {
      const newPathWithQuery = `${location.pathname}?${url.formatURLQuery(
        urlParams.toString(),
      )}${location.hash}`;

      navigate(newPathWithQuery, { replace: !options.push });
    },
    [hookOptions, location.hash, location.pathname, navigate],
  );

  const setQueryParameter = useCallback(
    (name: string, value: ParamValue, options: Options = hookOptions) => {
      const urlParams = new URLSearchParams(location.search);

      if (Array.isArray(value)) {
        value = value.join();
      }

      urlParams.set(name, `${value}`);

      navigateWithParams(urlParams, options);
    },

    [hookOptions, location.search, navigateWithParams],
  );

  const deleteQueryParameter = useCallback(
    (key: string | string[], options: Options = hookOptions) => {
      const urlParams = new URLSearchParams(location.search);

      const keys = Array.isArray(key) ? key : [key];

      keys.forEach((key) => {
        urlParams.delete(key);
      });

      navigateWithParams(urlParams, options);
    },
    [hookOptions, location.search, navigateWithParams],
  );

  const updateQueryParameter = useCallback(
    (
      key: string,
      value: ParamValue | null | undefined,
      options: Options = hookOptions,
    ) => {
      if (Array.isArray(value)) {
        if (value.length) {
          setQueryParameter(key, value, options);
        } else {
          deleteQueryParameter(key, options);
        }
      } else if (
        (typeof value === 'string' && value.length === 0) ||
        value === null ||
        value === undefined
      ) {
        deleteQueryParameter(key, options);
      } else {
        setQueryParameter(key, value, options);
      }
    },
    [setQueryParameter, deleteQueryParameter, hookOptions],
  );

  const replaceQueryParameters = useCallback(
    (
      keys: { [key: string]: string },
      values: { [key: string]: ParamValue },
    ) => {
      const queryParams = new URLSearchParams();
      Object.entries(values).forEach(([key, value]) => {
        const paramKey = keys[key];
        if (Array.isArray(value) && value.length > 0) {
          queryParams.set(paramKey, value.join(','));
        } else if (!Array.isArray(value) && value !== '') {
          queryParams.set(paramKey, value.toString());
        }
      });

      navigate(`?${queryParams.toString()}`);
    },
    [navigate],
  );

  const getQueryParameter = useCallback(
    (name: string, defaultValue: string = '') => {
      const urlParams = new URLSearchParams(location.search);

      const value = urlParams.get(name);

      return url.parseValue(value) || defaultValue;
    },
    [location.search],
  );

  const getQueryParameters = useCallback(
    (names: string[], options: { parse: boolean } = { parse: true }) => {
      return url.getQueryParameters(names, location.search, options);
    },
    [location.search],
  );

  return useMemo(
    () => ({
      setQueryParameter,
      deleteQueryParameter,
      getQueryParameter,
      getQueryParameters,
      updateQueryParameter,
      replaceQueryParameters,
    }),
    [
      deleteQueryParameter,
      getQueryParameter,
      getQueryParameters,
      setQueryParameter,
      updateQueryParameter,
      replaceQueryParameters,
    ],
  );
}
