import {
  PAGE_TYPE_NAMES,
  PageNameType,
  PageNumberType,
  TYPE,
} from '@kontentino/kontentino-constants/Pages';
import {
  MANUAL_ONLY_PAGE_TYPES,
  SORTED_PAGE_TYPES,
} from 'app/constants/pageTypes';
import ArrayUtils from 'app/utils/array';

const PageTypeUtils = {
  name: {
    [TYPE.UNSPECIFIED]: 'unspecified',
    [TYPE.FACEBOOK]: 'facebook',
    [TYPE.INSTAGRAM]: 'instagram',
    [TYPE.TWITTER]: 'twitter',
    [TYPE.LINKEDIN]: 'linkedin',
    [TYPE.PINTEREST]: 'pinterest',
    [TYPE.GOOGLE_PLUS]: 'google_plus',
    [TYPE.WORDPRESS]: 'wordpress',
    [TYPE.GOOGLE_MY_BUSINESS]: 'google_my_business',
    [TYPE.FACEBOOK_AD]: 'facebook',
    [TYPE.TIKTOK]: 'tiktok',
    [TYPE.YOUTUBE]: 'youtube',
    [TYPE.THREADS]: 'threads',
  } as Record<number, string>,
  label: {
    [TYPE.UNSPECIFIED]: 'Unspecified',
    [TYPE.FACEBOOK]: 'Facebook',
    [TYPE.INSTAGRAM]: 'Instagram',
    [TYPE.TWITTER]: 'X',
    [TYPE.LINKEDIN]: 'LinkedIn',
    [TYPE.PINTEREST]: 'Pinterest',
    [TYPE.GOOGLE_PLUS]: 'Google Plus',
    [TYPE.WORDPRESS]: 'Wordpress',
    [TYPE.GOOGLE_MY_BUSINESS]: 'Google',
    [TYPE.FACEBOOK_AD]: 'Facebook',
    [TYPE.TIKTOK]: 'TikTok',
    [TYPE.YOUTUBE]: 'YouTube',
    [TYPE.THREADS]: 'Threads',
  } as Record<number, string>,
  getNameFromNumber(numberType: number): string {
    return PAGE_TYPE_NAMES[numberType as PageNumberType];
  },
  getNumberFromName(nameType: string): number {
    return TYPE[nameType.toUpperCase() as Uppercase<PageNameType>];
  },
  getLabel(numberType: number): string {
    return PageTypeUtils.label[numberType] ?? PageTypeUtils.label[0];
  },
  getName(numberType: number): string {
    return PageTypeUtils.name[numberType] ?? '';
  },
  isManualOnly(numberType?: number): boolean {
    if (!numberType) return false;
    return MANUAL_ONLY_PAGE_TYPES.includes(numberType);
  },
  sorted: () => {
    return SORTED_PAGE_TYPES;
  },
  sort: (input: any[], options: { key: string } = { key: 'key' }) => {
    return ArrayUtils.sortByArray(input, {
      key: options.key,
      order: PageTypeUtils.sorted(),
    });
  },
};

export default PageTypeUtils;
