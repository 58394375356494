import { StandardPostDetailedMetric } from 'app/types/StandardPostDetailed';

export const getPostBenchmarkValue = (
  benchmark: Record<string, number>,
  key: string,
) => (benchmark ? benchmark[key] : undefined);

export const getPostMetricValue = (metric: StandardPostDetailedMetric) =>
  metric ? metric.value : undefined;

export const getPostMaxValue = (
  benchmarkValue: number | undefined,
  metricValue: number | undefined | null,
) => {
  if (!benchmarkValue) return metricValue;
  else if (!metricValue) return benchmarkValue;
  else return benchmarkValue < metricValue ? metricValue : benchmarkValue;
};
