export enum TimeLabels {
  Seconds = 'seconds',
  Minutes = 'minutes',
  Hours = 'hours',
  Days = 'days',
  Weeks = 'weeks',
  Months = 'months',
  Years = 'years',
}

export type TimeLabelsMap = {
  [key in TimeLabels]?: string;
};
