import {
  StandardPost,
  StandardPostMedium,
} from 'app/types/StandardPostPreview';
import {
  TYPE,
  TYPE_FACEBOOK,
  TYPE_INSTAGRAM,
  TYPE_LINKEDIN,
} from '@kontentino/kontentino-constants/Pages';
import {
  TYPE as POST_TYPE,
  TYPE_LINK,
} from '@kontentino/kontentino-constants/Posts';
import { igPostsQueryNew_posts_edges_node } from 'graphql/insights/queries/__generated__/igPostsQueryNew';
import {
  fbPostsQueryNew_posts_edges_node,
  fbPostsQueryNew_posts_edges_node_attachments_subattachments,
} from 'graphql/insights/queries/__generated__/fbPostsQueryNew';
import {
  StandardPostDetailed,
  StandardPostDetailedMetric,
  StandardPostDetailedMetricAdditionalData,
  StandardPostDetailedMetrics,
} from 'app/types/StandardPostDetailed';
import { TotalExpenseObject } from 'types/AnalyticsPost';
import { liPostsQueryNew_posts_edges_node } from 'graphql/insights/queries/__generated__/liPostsQueryNew';
import { PostMetricsEdge } from '../modules/postsPerformance/types/PostMetricsEdge';
import { getPostTypeByMediaProduct } from './getPostTypeByMediaProduct';
import {
  getMetricForPageType,
  getMetricsForPageType,
} from 'app/modules/insights/modules/postsPerformance/utils/metricProperty';
import {
  MetricProperty,
  MetricPropertyCustomSubMetrics,
} from 'app/modules/insights/types/MetricProperty';
import { capitalize } from '@kontentino/kontentino-utils/string';

type PageParams = { name: string; logo: string | undefined | null };

function imgToMedium(src: string): StandardPostMedium {
  return { image: { src: src } };
}

function videoToMedium(src: string, thumbSrc: string = ''): StandardPostMedium {
  return { video: { src: src, thumbSrc } };
}

const getLabelFromMetricName = (name: string) =>
  capitalize(name.replaceAll('_', ' '));

function mapMetric(metricKey: string, node?: PostMetricsEdge['node']) {
  if (!node) {
    return {
      name: metricKey,
      value: 0,
    };
  }

  return {
    id: node.id,
    name: node.metricName,
    value: node.metricValue ?? 0,
    additionalData: node.additionalData
      ? node.additionalData.edges.map((edge) => ({
          name: getLabelFromMetricName(edge.node.name),
          value: edge.node.value ?? 0,
        }))
      : undefined,
  };
}

const getMissingMetricKey = (
  customSubMetrics: MetricPropertyCustomSubMetrics,
  edges: PostMetricsEdge[],
) =>
  customSubMetrics.filter(
    (metric) => !edges.find((edge) => edge.node.metricName === metric.name),
  )?.[0]?.name;

const getMissingMetricValues = (
  edges: PostMetricsEdge[],
  customSubMetrics: MetricPropertyCustomSubMetrics,
  type: number,
  metric: StandardPostDetailedMetric,
  customAdditionalData: StandardPostDetailedMetricAdditionalData[],
) => {
  const missingMetricKey = getMissingMetricKey(customSubMetrics, edges);

  if (!missingMetricKey) return undefined;

  const additionalMetricSum = getAdditionalMetricSum(customAdditionalData);
  const missingMetricValue = getMissingMetricValue(metric, additionalMetricSum);

  return {
    name:
      customSubMetrics.find((metric) => metric.name === missingMetricKey)
        ?.title ?? getLabelFromMetricName(missingMetricKey),
    value: missingMetricValue,
  };
};

const getAdditionalMetricSum = (
  customAdditionalData: StandardPostDetailedMetricAdditionalData[],
) => customAdditionalData.reduce((acc, curr) => acc + curr.value, 0);

const getMissingMetricValue = (
  metric: StandardPostDetailedMetric,
  additionalMetricSum: number,
) => {
  const metricValue = metric.value ?? 0;
  return metricValue - additionalMetricSum;
};

const mapMetricCustomAdditionalData = (
  edges: PostMetricsEdge[],
  customSubMetrics: MetricPropertyCustomSubMetrics,
  type: number,
  metric: StandardPostDetailedMetric,
) => {
  const additionalCustomData = edges
    .filter((edge) =>
      customSubMetrics
        .map((metric) => metric.name)
        .includes(edge.node.metricName),
    )
    .map((edge) => ({
      name:
        customSubMetrics.find((metric) => metric.name === edge.node.metricName)
          ?.title ?? getLabelFromMetricName(edge.node.metricName),
      value: edge.node.metricValue ?? 0,
    }));

  const missingMetricValue = getMissingMetricValues(
    edges,
    customSubMetrics,
    type,
    metric,
    additionalCustomData,
  );

  if (missingMetricValue) additionalCustomData.push(missingMetricValue);

  return additionalCustomData;
};

const getMetricEdge = (
  edges: PostMetricsEdge[],
  metricName: MetricProperty['name'],
) => edges?.find((edge) => edge.node.metricName === metricName);

const mapMetrics = (edges: PostMetricsEdge[] = [], pageType: number) => {
  const metrics: StandardPostDetailedMetrics = {};

  Object.keys(getMetricsForPageType(pageType)).forEach((metricKey) => {
    const metricForPageType = getMetricForPageType(pageType, metricKey);

    if (!metricForPageType) {
      return;
    }

    const edge = getMetricEdge(edges, metricForPageType.name);

    const mappedMetric = mapMetric(metricKey, edge?.node);

    if (metricForPageType?.customSubMetrics) {
      mappedMetric.additionalData = mapMetricCustomAdditionalData(
        edges,
        metricForPageType.customSubMetrics,
        pageType,
        mappedMetric,
      );
    }

    metrics[metricKey] = mappedMetric;
  });

  return metrics;
};

function getTotalExpenses(post: fbPostsQueryNew_posts_edges_node) {
  let totalExpenses: Record<string, TotalExpenseObject> = {};
  if (post.totalExpenses) {
    Object.keys(post.totalExpenses).forEach((key) => {
      const totalExpense = post.totalExpenses?.[
        key as keyof typeof post.totalExpenses
      ] as { budget: number | null; currency: string | null } | undefined;
      if (
        key !== '__typename' &&
        totalExpense?.budget &&
        totalExpense?.currency
      ) {
        totalExpenses[key] = {
          currency: totalExpense.currency,
          budget: totalExpense.budget,
        };
      }
    });
  }
  return totalExpenses;
}

export const StandardPostUtils = {
  fromIgPost(
    post: Pick<
      igPostsQueryNew_posts_edges_node,
      | 'createdTime'
      | 'type'
      | 'message'
      | 'children'
      | 'picture'
      | 'video'
      | 'mediaProductType'
    >,
    page: PageParams,
  ): StandardPost {
    const standardPost: StandardPost = {
      page: {
        name: page.name,
        type: TYPE_INSTAGRAM,
        logo: page.logo ?? null,
      },
      createdAt: post.createdTime,
      text: post.message ?? '',
      type: getPostTypeByMediaProduct(post),
      attachments: { cards: [] },
    };

    if (post.children) {
      post.children.forEach((child) => {
        if (child?.picture && child.video) {
          standardPost.attachments.cards.push({
            medium: videoToMedium(child.video, child.picture),
          });
        } else if (child?.picture) {
          standardPost.attachments.cards.push({
            medium: imgToMedium(child.picture),
          });
        }
      });
    } else if (post.picture) {
      standardPost.attachments.cards = [
        {
          medium: post.video
            ? videoToMedium(post.video, post.picture)
            : imgToMedium(post.picture),
        },
      ];
    }

    return standardPost;
  },
  fromLiPost(
    post: liPostsQueryNew_posts_edges_node,
    page: PageParams,
  ): StandardPost {
    const standardPost: StandardPost = {
      page: {
        name: page.name,
        type: TYPE_LINKEDIN,
        logo: page.logo ?? null,
      },
      createdAt: post.createdTime,
      text: post.message ?? '',
      type: post.type,
      attachments: { cards: [] },
    };

    post.media?.forEach((media) => {
      if (post.type === POST_TYPE.VIDEO) {
        const videoUrl = post.media?.[0]?.originalUrl;
        const videoThumbnailUrl =
          post.media?.[0]?.thumbnails?.[0]?.url ?? undefined;

        if (videoUrl) {
          standardPost.attachments.cards.push({
            medium: videoToMedium(videoUrl, videoThumbnailUrl),
          });
        }
      } else {
        const mediaUrl = media?.thumbnails?.[0]?.url || media?.originalUrl;

        if (mediaUrl) {
          standardPost.attachments.cards.push({
            medium: imgToMedium(mediaUrl),
          });
        }
      }
    });

    return standardPost;
  },
  fromFbPost(
    post: Pick<
      fbPostsQueryNew_posts_edges_node,
      'createdTime' | 'type' | 'message' | 'attachments' | 'picture'
    >,
    page: PageParams,
  ): StandardPost {
    const standardPost: StandardPost = {
      page: {
        name: page.name,
        type: TYPE_FACEBOOK,
        logo: page.logo ?? null,
      },
      createdAt: post.createdTime,
      text: post.message ?? '',
      type: post.type,
      attachments: { cards: [] },
    };

    if (post.attachments) {
      const presentAttachments: fbPostsQueryNew_posts_edges_node_attachments_subattachments[] =
        [];

      post.attachments.forEach((attachment) => {
        if (attachment?.subattachments) {
          attachment.subattachments.forEach((subAttachment) => {
            if (subAttachment?.media?.image?.source) {
              presentAttachments.push(subAttachment);
            }
          });
        } else if (attachment?.media?.image?.source) {
          presentAttachments.push(attachment);
        }
      });

      presentAttachments.forEach((attachmentToAdd) => {
        if (attachmentToAdd.media?.image?.source) {
          let attachmentCard: StandardPost['attachments']['cards'][0] = {
            medium:
              attachmentToAdd.media_type === 'video' &&
              attachmentToAdd.media.source
                ? videoToMedium(
                    attachmentToAdd.media.source,
                    attachmentToAdd.media.image.source,
                  )
                : imgToMedium(attachmentToAdd.media.image.source),
          };

          if (
            post.type === TYPE_LINK ||
            (attachmentToAdd.media_type &&
              ['link', 'share'].includes(attachmentToAdd.media_type))
          ) {
            attachmentCard = {
              ...attachmentCard,
              url:
                attachmentToAdd.unshimmed_url ??
                attachmentToAdd.url ??
                undefined,
              title: attachmentToAdd?.title ?? undefined,
              description: attachmentToAdd?.description ?? undefined,
            };
          }

          standardPost.attachments.cards.push(attachmentCard);
        }
      });
    } else if (post.picture) {
      standardPost.attachments.cards = [
        {
          medium: imgToMedium(post.picture),
        },
      ];
    }

    return standardPost;
  },
  fromFbPostDetailed(
    post: fbPostsQueryNew_posts_edges_node,
    page: PageParams,
  ): StandardPostDetailed {
    const standardPost = this.fromFbPost(post, page);
    return {
      ...standardPost,
      linkId: post._id,
      metrics: mapMetrics(post.metrics?.edges, TYPE.FACEBOOK),
      totalExpenses: getTotalExpenses(post),
    };
  },
  fromIgPostDetailed(
    post: igPostsQueryNew_posts_edges_node,
    page: PageParams,
  ): StandardPostDetailed {
    const standardPost = this.fromIgPost(post, page);

    return {
      ...standardPost,
      linkId: post._id,
      metrics: mapMetrics(post.metrics?.edges, TYPE.INSTAGRAM),
      link: post.permalink,
    };
  },
  fromLiPostDetailed(
    post: liPostsQueryNew_posts_edges_node,
    page: PageParams,
  ): StandardPostDetailed {
    const standardPost = this.fromLiPost(post, page);
    return {
      ...standardPost,
      linkId: post._id,
      metrics: mapMetrics(post.metrics?.edges, TYPE.LINKEDIN),
      link: post.permalink,
    };
  },
};
