import { toast, dismissToast } from '@kontentino/ui';
import AppConfig from 'app/config/app';
import { FC, Key, useEffect, useRef } from 'react';

const NETWORK_CONNECTION_TOAST_ID = 'NETWORK_CONNECTION_TOAST_ID';

const NetworkStatus: FC = () => {
  const toastId = useRef<Key>();

  const checkForInternetConnection = () => {
    if (!navigator.onLine) {
      toastId.current = toast('Trying to reconnect', {
        type: 'warning',
        autoClose: false,
        toastId: NETWORK_CONNECTION_TOAST_ID,
        closeButton: <></>,
      });
    } else {
      if (toastId.current) {
        dismissToast(toastId.current);
      }
    }
  };

  useEffect(() => {
    const checkForInternetConnectionWithDelay = () => {
      setTimeout(
        checkForInternetConnection,
        AppConfig.TIMEOUT_TO_ALERT_NO_INTERNET,
      );
    };

    window.addEventListener('online', checkForInternetConnection);
    window.addEventListener('offline', checkForInternetConnectionWithDelay);
    return () => {
      window.removeEventListener('online', checkForInternetConnection);
      window.removeEventListener(
        'offline',
        checkForInternetConnectionWithDelay,
      );
    };
  }, []);

  return null;
};

export default NetworkStatus;
