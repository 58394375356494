import React, { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import ReactQueryDevTools from 'components/shared/ReactQueryDevTools';
import { ModalRouter } from 'context/ModalRouter';
import { PermissionsProvider } from '@kontentino/react-permissions-gate';

// Workaround of code-splitting issue
import 'utils/hooks/useElementSize';
import 'utils/hooks/useWindowsSize';

type Props = {
  children: React.ReactNode;
  queryClient: QueryClient;
};

const PublicAppProviders: FC<Props> = ({ children, queryClient }) => (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevTools>
      <PermissionsProvider store={{ permissions: [] }}>
        <ModalRouter>{children}</ModalRouter>
      </PermissionsProvider>
    </ReactQueryDevTools>
  </QueryClientProvider>
);
export default PublicAppProviders;
