import { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { mergeTailwindClasses } from '@kontentino/ui';

export type MediaPreviewActionsProps = {
  children: ReactNode;
  className?: string;
};

export const MediaPreviewActions: FC<MediaPreviewActionsProps> = ({
  children,
  className,
}) => {
  return (
    <div
      className={mergeTailwindClasses(
        clsx(
          'tw-absolute tw-bottom-1 tw-right-1 tw-flex tw-overflow-hidden tw-rounded-md tw-opacity-0 tw-transition-all group-hover:tw-opacity-100',
        ),
        className,
      )}
    >
      {children}
    </div>
  );
};
