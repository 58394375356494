import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import {
  ModalContextValue,
  useModalContext,
} from 'components/shared/modal/ModalProvider';
import { ActivityIndicator, mergeTailwindClasses } from '@kontentino/ui';

type Props = {
  children?: ReactNode | ((context: ModalContextValue) => ReactNode);
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;

const ModalContent: FC<Props> = ({ children, className, ...rest }) => {
  const modalContext = useModalContext();

  return (
    <div
      className={mergeTailwindClasses(
        clsx(
          'tw-relative tw-px-6',
          {
            'tw-overflow-auto ': modalContext.isContentHeightAutoResizable,
          },
          className,
        ),
      )}
      data-name={modalContext.dataName?.wrapper}
      data-cy={modalContext.dataCy?.wrapper}
      {...rest}
    >
      {typeof children === 'function' ? children(modalContext) : children}
      {modalContext.isLoading && (
        <div className="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-flex tw-cursor-wait tw-items-center tw-justify-center tw-bg-grayscale-50  tw-bg-opacity-50">
          <ActivityIndicator className="tw-z-10 tw-h-9 tw-w-9 tw-text-grayscale-180" />
        </div>
      )}
    </div>
  );
};

export default ModalContent;
