import React, { FC } from 'react';
import Modal from 'components/shared/Modal';
import { ModalRouteProps } from 'context/ModalRouter';
import { Button, Field, TextInput } from '@kontentino/ui';

import Link from 'components/shared/Link';
import Popup from 'utils/popup';
import UserApi from 'api/user';
import { PublicUtils } from 'components/publicPreviews/publicUtils';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import Logger from 'utils/logger';
import { z } from 'zod';
import i18n from 'i18n';
import { zodResolver } from '@hookform/resolvers/zod';

type Props = ModalRouteProps & {
  token: string;
};

type Form = z.infer<typeof schema>;

const schema = z.object({
  name: z.string(),
  email: z.string().email(),
});

const PublicInviteUser: FC<Props> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Form>({
    resolver: zodResolver(schema),
  });
  const sharedBy = PublicUtils.getSharedData();
  const actionType = props.modalManager.state?.actionType;

  const { mutate, isLoading } = useMutation(UserApi.requestInvitation, {
    onSuccess: () => {
      Popup.success({
        text: `Request has been sent to ${sharedBy.name}. Please wait for your invitation or ask ${sharedBy.name} directly.`,
      });
      props.modalManager.close();
    },
    onError: (error) => {
      Popup.error({ text: i18n.somethingWentWrongTryAgain });
      Logger.error(error);
    },
  });

  const onSubmit = (values: { name: string; email: string }) => {
    mutate({
      name: values.name,
      email: values.email,
      userEmail: sharedBy.email,
      accountUsername: sharedBy.accountUsername,
      token: props.token,
    });
  };

  return (
    <Modal modalManager={props.modalManager}>
      <Modal.Header title="Oops, it seems you're not a Kontentino user yet." />
      <Modal.Content className="tw-mb-6">
        <span>
          {['cta', 'comment'].includes(actionType) && (
            <>
              To provide feedback to the post, you need to be invited to{' '}
              <strong>{sharedBy.name}</strong>'s company account.
            </>
          )}
          {actionType === 'navbar' && (
            <>
              To try this feature, you need to be invited to{' '}
              <strong>{sharedBy.name}</strong>’s company account or create your
              own.
            </>
          )}
          Ask <strong>{sharedBy.name}</strong> to invite you.
        </span>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="tw-mt-4 tw-flex tw-flex-col tw-gap-4"
        >
          <Field.Group>
            <Field.Label required>{i18n.yourName}</Field.Label>
            <TextInput
              placeholder="Your Name ..."
              {...register('name')}
              disabled={isLoading}
              error={!!errors.name?.message}
              data-cy="public-invite-user_name-input"
            />
            <Field.Error>{errors.name?.message}</Field.Error>
          </Field.Group>
          <Field.Group>
            <Field.Label required>{i18n.yourEmail}</Field.Label>
            <TextInput
              placeholder="Your Email ..."
              {...register('email')}
              disabled={isLoading}
              error={!!errors.email?.message}
              data-cy="public-invite-user_email-input"
            />
            <Field.Error>{errors.email?.message}</Field.Error>
          </Field.Group>
          <div>
            <Button
              type="submit"
              data-name="public-link-request-invitation"
              data-cy="public-link-request-invitation"
            >
              Request Invitation
            </Button>
          </div>
          <div>
            Already invited?&nbsp;
            <Link to="https://www.kontentino.com/login">Sign in</Link>
          </div>
        </form>
      </Modal.Content>
    </Modal>
  );
};

export default PublicInviteUser;
