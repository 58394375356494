import React, { FC, useState } from 'react';
import { createInsightsApolloClient } from 'app/modules/insights/utils/createAuthorizedInsightsApolloClient';
import { ApolloProvider } from '@apollo/client';
import {
  ArrayParam,
  BooleanParam,
  JsonParam,
  StringParam,
  useQueryParams,
} from 'use-query-params';
import { Page } from 'app/modules/insights/types/Page';
import ReportFailedToBuildMessage from 'app/modules/public/modules/pdfReports/components/ReportFailedToBuildMessage';
import CompetitionAnalysisExport from 'app/modules/public/modules/pdfReports/modules/InsightsCompetitionAnalysis/components/CompetitionAnalysisExport';

type Props = {};

const InsightsCompetitionAnalysisPDFReportRoute: FC<Props> = (props) => {
  const [query] = useQueryParams({
    token: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    page: JsonParam,
    showReactions: BooleanParam,
    comparedPageIds: ArrayParam,
  });

  const [client] = useState(() =>
    createInsightsApolloClient(query.token ?? ''),
  );

  if (
    query.token &&
    query.startDate &&
    query.endDate &&
    query.page &&
    query.comparedPageIds
  ) {
    const token = query.token;
    const startDate = query.startDate;
    const endDate = query.endDate;
    const page = query.page as Page;
    const showReactions = query.showReactions ?? false;
    const comparedPageIds =
      query.comparedPageIds?.filter(
        (id): id is string => typeof id === 'string',
      ) ?? [];

    return (
      <ApolloProvider client={client}>
        <CompetitionAnalysisExport
          showReactions={showReactions}
          startDate={startDate}
          endDate={endDate}
          page={page}
          token={token}
          comparedPageIds={comparedPageIds}
        />
      </ApolloProvider>
    );
  }

  return <ReportFailedToBuildMessage />;
};

export default InsightsCompetitionAnalysisPDFReportRoute;
