import React, { AnchorHTMLAttributes, forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styles from './link/RouterLink.module.scss';
import classes from 'react-style-classes';

type Props = {
  to:
    | string
    | {
        pathname?: string;
        hash?: string;
        search?: string;
      };
  isRouterLink?: boolean;
  state?: object;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

const Link = forwardRef<HTMLAnchorElement, Props>(
  ({ to, isRouterLink, className, children, state, ...rest }, ref) => {
    if (isRouterLink || typeof to === 'object') {
      return (
        <RouterLink
          className={classes(styles.routerLink, className)}
          to={to}
          state={state}
          ref={ref}
          {...rest}
        >
          {children}
        </RouterLink>
      );
    }

    return (
      <a className={className} href={to} ref={ref} {...rest}>
        {children}
      </a>
    );
  },
);

Link.defaultProps = {
  isRouterLink: false,
};

Link.displayName = 'Link';

export default Link;
