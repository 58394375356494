import React, { FC, ReactNode, Suspense } from 'react';
import SectionLayout from 'components/shared/SectionLayout';
import InitializationStatus from 'app/modules/insights/components/InitializationStatus';
import style from 'app/modules/insights/components/mainSections/MainSections.module.scss';
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router';
import routes from 'constants/routes';
import { useRedirectRestrictedViewToDefault } from '../hooks/useRedirectRestrictedViewToDefault';
import { useInsightsContainer } from 'components/insights/context';
import InsightsLayoutTobBar from 'app/modules/insights/components/InsightsLayoutTobBar';

type Props = {
  children: ReactNode;
};

const InsightsSection: FC<Props> = ({ children }) => {
  const location = useLocation();
  const insightsState = useInsightsContainer();

  useRedirectRestrictedViewToDefault(
    insightsState.activeState.page?._type,
    routes.INSIGHTS_POSTS_PERFORMANCE,
  );

  function renderMain() {
    if (insightsState.arePagesLoading) {
      return <InitializationStatus status="loading" />;
    }

    const activePage = insightsState.activeState.page;

    if (!activePage) {
      return (
        <InitializationStatus
          status="error"
          message={{
            main: 'No profile selected',
            secondary: 'Please select one of your profiles on top left corner',
          }}
        />
      );
    }

    return (
      <section className={style.mainSections}>
        <main
          className={style.mainRoute}
          id="insights-main-route"
          data-cy={getSectionDataCy(location.pathname)}
        >
          <Suspense fallback={<Skeleton width="100%" height="80vh" />}>
            {children}
          </Suspense>
        </main>
      </section>
    );
  }

  return (
    <SectionLayout
      header={<InsightsLayoutTobBar />}
      mainProps={{ style: { padding: 0, overflow: 'initial' } }}
      main={renderMain()}
      dataCy="insights-main"
    />
  );
};

function getSectionDataCy(route: string) {
  function getSectionName() {
    switch (route) {
      case routes.INSIGHTS:
      case routes.INSIGHTS_POSTS_PERFORMANCE:
        return 'posts-performance';
      case routes.INSIGHTS_PAGE_PERFORMANCE:
        return 'page-performance';
      case routes.INSIGHTS_COMPETITION_ANALYSIS:
        return 'competition-analysis';
      default:
        return '';
    }
  }

  return `insights-${getSectionName()}-module`;
}

export default InsightsSection;
