import React, { FC } from 'react';
import { StandardPostPreviewProps } from 'app/modules/insights/components/StandardPostPreview';
import { formatDateTimeUTC } from 'app/utils/date';
import {
  Avatar,
  mergeTailwindClasses,
  SocialMediaIcon,
  SocialMediaIconProps,
} from '@kontentino/ui';
import PageTypeUtils from 'app/utils/pageType';

type Props = Pick<StandardPostPreviewProps, 'post' | 'classNames'>;

const StandardPostProfile: FC<Props> = ({ post, classNames }) => {
  return (
    <div className={mergeTailwindClasses('tw-flex', classNames?.profileRoot)}>
      <Avatar
        name={post.page.name}
        src={post.page.logo ?? undefined}
        className={mergeTailwindClasses(
          '!tw-h-10 !tw-w-10',
          classNames?.profileAvatar,
        )}
      />
      <div className="tw-ml-4 tw-mr-auto">
        <div className="tw-text-md tw-font-semibold">{post.page.name}</div>
        <div className="tw-text-sm tw-text-grayscale-100">
          {formatDateTimeUTC(post.createdAt)}
        </div>
      </div>
      <SocialMediaIcon
        type={
          PageTypeUtils.getName(post.page.type) as SocialMediaIconProps['type']
        }
        className={mergeTailwindClasses(
          '!tw-h-6 !tw-w-6',
          classNames?.profilePageType,
        )}
      />
    </div>
  );
};

export default StandardPostProfile;
