import { FC } from 'react';
import Modal from 'components/shared/Modal';
import ModalBody from 'components/workflowOptions/sendForApproval/modal/Body';
import { STEPS } from 'components/workflowOptions/sendForApproval/utils/constants';
import StepsHeader from 'components/shared/workflowOptions/StepsHeader';
import FormStateContainer from 'components/workflowOptions/sendForApproval/FormStateContainer';
import RequestContainer from 'components/workflowOptions/sendForApproval/RequestContainer';
import { ModalManager } from 'context/ModalRouter';
import getPreselectedValuesFromParams from 'app/components/timeSavers/assignMultipleTasksModal/utils/getPreselectedValuesFromParams';

type Props = {
  type: ApprovalType;
  onGoNext: () => void;
  onGoBack: () => void;
  activeStep: number;
  onSuccessFormResponse?: () => void;
  modalManager: ModalManager;
  dataNamePrefix: string;
};

const steps = Object.values(STEPS);

const Route: FC<Props> = ({
  activeStep,
  onGoBack,
  onGoNext,
  type,
  onSuccessFormResponse,
  modalManager,
  dataNamePrefix,
}) => {
  return (
    <Modal size="lg" modalManager={modalManager}>
      <Modal.Header
        title={`Send for ${type} approval`}
        onGoBack={activeStep !== STEPS.SELECT_POSTS.step ? onGoBack : undefined}
      >
        <StepsHeader steps={steps} activeStep={activeStep} />
      </Modal.Header>
      <RequestContainer type={type} successCallback={onSuccessFormResponse}>
        {(requestParams) => (
          <FormStateContainer
            preselectedValues={getPreselectedValuesFromParams(modalManager)}
            onSubmit={requestParams.submitSendForApproval}
          >
            {(stateParams) => (
              <ModalBody
                type={type}
                selectedStatuses={stateParams.selectedStatuses}
                onSelectStatus={stateParams.togglePostStatus}
                onSelectPost={stateParams.togglePost}
                onSelectMultiplePosts={stateParams.selectPosts}
                selectedPosts={stateParams.formState.selectedPosts}
                activeStep={activeStep}
                onGoNext={onGoNext}
                subject={stateParams.formState.subject}
                bodyMessage={stateParams.formState.bodyMessage}
                onBodyMessageChange={stateParams.changeBodyMessage}
                onSubjectChange={stateParams.changeSubject}
                formErrors={stateParams.formErrors}
                onSelectedUsersChange={stateParams.handleChangeSelectedUsers}
                onSendForApprovalSubmit={stateParams.sendForApproval}
                initialSelectedUserIds={stateParams.formState.selectedUsers}
                onAdditionalEmailsChange={stateParams.changeAdditionalEmails}
                dataNamePrefix={dataNamePrefix}
              />
            )}
          </FormStateContainer>
        )}
      </RequestContainer>
    </Modal>
  );
};

export default Route;
