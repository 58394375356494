import { useToast } from 'app/hooks/useToast';
import CommentsApi from 'app/modules/comments/api';
import { useMutation, useQueryClient } from 'react-query';
import { queryKey } from 'constants/queryKey';
import { ApiClientError } from 'api/client';
import i18n from 'i18n';
import {
  Comment,
  CommentReaction,
  CommentReactionSummary,
} from 'app/modules/comments/types';

export type ReactionMutationProps = {
  reaction: CommentReactionSummary;
  onError?: () => void;
};

type UseAddReactionProps = {
  comment: Comment;
  postId: number;
};

export const useToggleReaction = ({ comment, postId }: UseAddReactionProps) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  const mutationOptions = {
    onMutate: async ({ onError }: ReactionMutationProps) => {
      await queryClient.cancelQueries(queryKey.postCommentsThread());

      return {
        onError,
      };
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(queryKey.postCommentsThread());
    },
    onError: (
      e: ApiClientError,
      _: ReactionMutationProps,
      context: { onError?: (() => void) | undefined } | undefined,
    ) => {
      toast(e.userMessage ?? i18n.somethingWentWrongTryAgain, 'error');
      context?.onError?.();
    },
  };

  const addReactionMutation = useMutation<
    ApiResponse<CommentReaction>,
    ApiClientError,
    ReactionMutationProps,
    {
      onError?: () => void;
    }
  >(async ({ reaction }) => {
    return CommentsApi.addReaction({
      postId,
      comment_id: comment.id,
      reaction: reaction.content,
    });
  }, mutationOptions);

  const removeReactionMutation = useMutation(async ({ reaction }) => {
    if (reaction.my_reaction?.id) {
      return CommentsApi.removeReaction({
        postId,
        reaction_id: reaction.my_reaction?.id,
      });
    }
  }, mutationOptions);

  function onToggle(mutationData: ReactionMutationProps) {
    if (mutationData.reaction.my_reaction) {
      removeReactionMutation.mutate(mutationData);
    } else {
      addReactionMutation.mutate(mutationData);
    }
  }

  return {
    onToggle,
    isLoading:
      removeReactionMutation.isLoading || addReactionMutation.isLoading,
  };
};
