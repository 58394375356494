import routes from 'constants/routes';
import { TYPE } from '@kontentino/kontentino-constants/Pages';

export const topbarExcludedViewsForPageType: Record<string, number[]> = {
  [routes.INSIGHTS_POSTS_PERFORMANCE]: [
    TYPE.FACEBOOK,
    TYPE.INSTAGRAM,
    TYPE.LINKEDIN,
  ],
  [routes.INSIGHTS_PAGE_PERFORMANCE]: [
    TYPE.FACEBOOK,
    TYPE.INSTAGRAM,
    TYPE.LINKEDIN,
  ],
  [routes.INSIGHTS_COMPETITION_ANALYSIS]: [TYPE.FACEBOOK, TYPE.INSTAGRAM],
};
