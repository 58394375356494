import React, { FC, ReactNode } from 'react';
import style from 'components/shared/sectionLayout/SectionLayout.module.scss';
import Head from 'components/Head';

type Props = {
  title?: string;
  header?: ReactNode | ReactNode[];
  headerProps?: React.HTMLAttributes<HTMLElement>;
  main: ReactNode | ReactNode[];
  mainProps?: React.HTMLAttributes<HTMLElement>;
  dataCy?: string;
  dataName?: string;
};

const SectionLayout: FC<Props> = (props) => {
  return (
    <>
      <Head title={props.title} />
      <section
        className={style.sectionLayout}
        data-cy={props.dataCy}
        data-name={props.dataName}
      >
        {props.header && <header {...props.headerProps}>{props.header}</header>}
        <main {...props.mainProps}>{props.main}</main>
      </section>
    </>
  );
};

export default SectionLayout;
