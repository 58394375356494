import { FC } from 'react';
import SectionLayout from 'components/shared/SectionLayout';
import InsightsLiteTopBar from 'app/modules/insightsLite/components/InsightsLiteTopBar';
import InsightsLiteContent from 'app/modules/insightsLite/components/InsightsLiteContent';

type Props = {};

const InsightsLite: FC<Props> = (props) => {
  return (
    <SectionLayout
      header={<InsightsLiteTopBar />}
      main={<InsightsLiteContent />}
      mainProps={{
        className: 'tw-flex-1 !tw-px-4 !tw-py-6',
      }}
    />
  );
};

export default InsightsLite;
