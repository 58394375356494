import { FC } from 'react';
import style from 'app/modules/insights/components/initializingStatus/initializingMessage/InitializingMessage.module.scss';
import { faMessageExclamation } from '@fortawesome/pro-regular-svg-icons';

import { Icon } from '@kontentino/ui';

type Props = {
  message: string;
  secondaryMessage?: string;
};

const InitializingMessage: FC<Props> = ({ message, secondaryMessage }) => {
  return (
    <div className={style.wrapper}>
      <div className={style.icon}>
        <Icon icon={faMessageExclamation} size={32} />
      </div>
      <div className={style.content}>
        <span className={style.main}>{message}</span>
        {secondaryMessage && (
          <span className={style.smaller}>{secondaryMessage}</span>
        )}
      </div>
    </div>
  );
};

export default InitializingMessage;
