import { FC, useMemo } from 'react';
import {
  DayPickerRangeController,
  DayPickerRangeControllerShape,
} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import 'components/date-range-picker/DateRangePicker.scss';
import { DateRangeOnChangeParams } from 'types/Date';
import DatePickerMonthElement from './DatePickerMonthElement';
import dayjs, { Dayjs } from 'dayjs';
import DateUtils from 'app/utils/date';
import moment, { Moment } from 'moment-timezone';

export type NumberOfMonthsType =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12;

export type FocusedInputType = 'startDate' | 'endDate' | null;

type Props = {
  onDatesChange(values: DateRangeOnChangeParams): void;
  onFocusChange(values: FocusedInputType): void;
  focusedInput: FocusedInputType;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  numberOfMonths?: NumberOfMonthsType;
  isDayBlocked?: (day: Dayjs) => boolean;
  dataName?: string;
};

const DateRangePicker: FC<Props> = ({
  onDatesChange,
  onFocusChange,
  focusedInput,
  startDate,
  endDate,
  numberOfMonths = 2,
  isDayBlocked,
  dataName,
}) => {
  const firstDayOfWeek = useMemo(() => {
    return dayjs.weekdays(true)[0] === 'Monday' ? 1 : 0;
  }, []);

  const _onDatesChange: DayPickerRangeControllerShape['onDatesChange'] = ({
    startDate,
    endDate,
  }) => {
    onDatesChange({
      startDate: dayjs(startDate?.toDate()),
      endDate: dayjs(endDate?.toDate()),
    });
  };

  const _isDayBlocked = isDayBlocked
    ? (day: Moment) => {
        return isDayBlocked?.(dayjs(day.toDate()));
      }
    : undefined;

  return (
    <div data-name={dataName}>
      <DayPickerRangeController
        renderMonthElement={DatePickerMonthElement}
        onDatesChange={_onDatesChange}
        onFocusChange={onFocusChange}
        focusedInput={focusedInput}
        startDate={moment(startDate?.toDate())}
        endDate={moment(endDate?.toDate())}
        numberOfMonths={numberOfMonths}
        minimumNights={0}
        firstDayOfWeek={firstDayOfWeek}
        initialVisibleMonth={
          startDate ? () => moment(startDate.toDate()) : moment
        }
        isDayBlocked={_isDayBlocked}
      />
      <input
        type="text"
        name="startDate"
        value={!!startDate ? DateUtils.toDateString(startDate) : ''}
        readOnly
        hidden
      />
      <input
        type="text"
        name="endDate"
        value={!!endDate ? DateUtils.toDateString(endDate) : ''}
        readOnly
        hidden
      />
    </div>
  );
};

export default DateRangePicker;
