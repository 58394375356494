import React, { FC, useEffect } from 'react';
import useTrackVisibility, {
  TrackVisibilityHookResult,
} from 'utils/hooks/useTrackVisibility';

type Props = {
  children: React.ReactElement;
  onIsVisibleChange?: (isVisible: boolean) => void;
  onVisibilityChange?: (result: TrackVisibilityHookResult[1]) => void;
};

const TrackVisibility: FC<Props> = ({
  onIsVisibleChange,
  onVisibilityChange,
  children,
}) => {
  const [ref, data] = useTrackVisibility();

  useEffect(() => {
    onIsVisibleChange?.(data.isVisible);
  }, [data.isVisible, onIsVisibleChange]);

  useEffect(() => {
    onVisibilityChange?.(data);
  }, [data, onVisibilityChange]);

  return React.cloneElement(children, {
    ref,
  });
};

export default TrackVisibility;
