import React, { forwardRef } from 'react';
import { Icon, mergeTailwindClasses } from '@kontentino/ui';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';

type Props = {
  label: string | React.ReactNode;
  isActive?: boolean;
  className?: string | null;
  isDisabled?: boolean;
  isSelected?: boolean;
  dataName?: string;
  dataCy?: string;
  onClick?: () => void;
};

const Placeholder = forwardRef<HTMLDivElement, Props>(
  (
    {
      label,
      isActive,
      isDisabled,
      isSelected,
      className,
      dataName,
      dataCy,
      onClick,
    },
    ref,
  ) => (
    <div
      ref={ref}
      onClick={onClick}
      className={mergeTailwindClasses(
        clsx(
          'tw-z-0 tw-flex tw-h-9 tw-w-[160px] tw-cursor-pointer tw-items-center tw-overflow-hidden tw-whitespace-nowrap tw-rounded tw-border-0 tw-bg-white tw-px-3 tw-text-md tw-font-regular tw-text-grayscale-180 tw-outline-none tw-ring-1 xl:tw-min-w-[200px]',
          {
            'tw-ring-primary-100': isActive,
            'tw-ring-grayscale-30': !isActive,
            'tw-text-grayscale-100': !isSelected,
            'tw-cursor-not-allowed tw-bg-grayscale-5 tw-text-grayscale-50':
              isDisabled,
          },
          className,
        ),
      )}
      tabIndex={0}
      data-name={dataName}
      data-cy={dataCy}
    >
      <span className="tw-text-grayscale-180">{label}</span>
      <Icon icon={faChevronDown} className="tw-ml-auto tw-text-grayscale-100" />
    </div>
  ),
);

export default Placeholder;
