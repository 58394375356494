import { XAxis, YAxis } from 'recharts';
import React, { FC, useMemo } from 'react';
import { formatDateValue } from 'app/modules/insights/modules/pagePerformance/utils/format';
import { ReferenceLine } from 'recharts';
import NumberUtil from 'utils/number';
import { Locales } from 'app/config/locales';
import { useTheme } from 'utils/hooks/useTheme';

type Props = {
  dataKey: string;
  showAxis?: {
    xAxis: boolean;
    yAxis: boolean;
  };
  isCompared?: boolean;
  customTickFormat?: boolean;
};

const GraphAxis: FC<Props> = ({
  dataKey,
  showAxis = { xAxis: true, yAxis: true },
  customTickFormat,
}) => {
  const theme = useTheme();
  const tickProps = useMemo(
    () => ({
      fill: theme.colors.grayscale['100'],
      fontSize: 10,
      fontWeight: 400,
    }),
    [theme],
  );

  return (
    <>
      {showAxis.xAxis && (
        <XAxis
          dataKey={dataKey}
          tickFormatter={customTickFormat ? undefined : formatDateValue}
          type={'category'}
          tick={tickProps}
          interval="preserveStartEnd"
          tickLine={false}
          axisLine={false}
          dy={9}
        />
      )}
      <ReferenceLine y={0} strokeWidth={0} />

      {showAxis.yAxis && (
        <YAxis
          axisLine={false}
          tickLine={false}
          type="number"
          tickFormatter={(value) =>
            NumberUtil.format(value, {
              notation: 'compact',
              compactDisplay: 'short',
              locales: Locales.EN_US,
              maximumFractionDigits: 1,
            })
          }
          tick={tickProps}
          orientation="right"
          dx={8}
          width={40}
        />
      )}
    </>
  );
};

export default GraphAxis;
