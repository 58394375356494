import { gql } from '@apollo/client';
import {
  FB_BENCHMARKS_VALUES_FRAGMENT,
  IG_BENCHMARKS_VALUES_FRAGMENT,
  LI_BENCHMARKS_VALUES_FRAGMENT,
} from 'graphql/insights/fragments/benchmarksValues';
import { CONNECTED_FB_AD_ACCOUNT_FRAGMENT } from 'graphql/insights/fragments/adAccounts';

export const FB_AD_ACCOUNTS_QUERY = gql`
  ${CONNECTED_FB_AD_ACCOUNT_FRAGMENT}
  query fbAdAccountsQuery($id: String!) {
    facebookPage(id: $id) {
      name
      id
      _id
      picture
      type
      ...ConnectedAdAccounts
    }
  }
`;

export const POSTS_ON_FB_PAGE_QUERY = gql`
  ${FB_BENCHMARKS_VALUES_FRAGMENT}
  ${CONNECTED_FB_AD_ACCOUNT_FRAGMENT}
  query fbPostsQuery($id: String!, $dateFrom: String!, $dateTo: String!) {
    facebookPage(id: $id) {
      name
      id
      _id
      picture
      type
      ...ConnectedAdAccounts
      benchmarksForPosts {
        type_1 {
          ...FbBenchmarksValues
        }
        type_2 {
          ...FbBenchmarksValues
        }
        type_3 {
          ...FbBenchmarksValues
        }
        type_4 {
          ...FbBenchmarksValues
        }
        type_5 {
          ...FbBenchmarksValues
        }
      }
      statistics(dateFrom: $dateFrom, dateTo: $dateTo) {
        paidReach
      }
      postTypesCount(dateFrom: $dateFrom, dateTo: $dateTo) {
        status
        link
        photo
        gallery
        video
      }
    }
  }
`;

export const POSTS_ON_IG_PAGE_QUERY = gql`
  ${IG_BENCHMARKS_VALUES_FRAGMENT}
  query igPostsQuery($id: String!, $dateFrom: String!, $dateTo: String!) {
    instagramPage(id: $id) {
      name
      id
      _id
      picture
      type
      benchmarksForPosts {
        type_1 {
          ...IgBenchmarksValues
        }
        type_2 {
          ...IgBenchmarksValues
        }
        type_3 {
          ...IgBenchmarksValues
        }
      }
      postTypesCount(dateFrom: $dateFrom, dateTo: $dateTo) {
        image
        video
        carousel_album
      }
    }
  }
`;

export const POSTS_ON_LI_PAGE_QUERY = gql`
  ${LI_BENCHMARKS_VALUES_FRAGMENT}
  query liPostsQuery($id: String!) {
    linkedInPage(id: $id) {
      name
      id
      _id
      picture
      type
      benchmarksForPosts {
        type_1 {
          ...LiBenchmarksValues
        }
        type_2 {
          ...LiBenchmarksValues
        }
        type_3 {
          ...LiBenchmarksValues
        }
        type_4 {
          ...LiBenchmarksValues
        }
        type_5 {
          ...LiBenchmarksValues
        }
      }
    }
  }
`;
