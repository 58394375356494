import { FC } from 'react';
import SimpleGrid from 'app/components/SimpleGrid';
import ArrayUtils from 'app/utils/array';
import StatisticCardSkeleton from 'app/modules/insights/modules/postsPerformance/components/pageStatistics/StatisticCardSkeleton';

type Props = {};

const PageStatisticsSkeleton: FC<Props> = () => {
  return (
    <SimpleGrid columns={4} gap={16}>
      {ArrayUtils.generate(4).map((i) => (
        <StatisticCardSkeleton key={i} />
      ))}
    </SimpleGrid>
  );
};

export default PageStatisticsSkeleton;
