import BaseSubscriptionModal from 'app/components/subscriptionModals/BaseSubscriptionModal';
import Benefits from 'app/components/subscriptionModals/Benefits';
import postIcon from 'app/images/postsModalGreet.svg';
import { FC } from 'react';
import { SUBSCRIPTION_MODAL_BENEFITS } from './subscriptionModalBenefits';
import SubscriptionExpiredButtons from './SubscriptionExpiredButtons';
import SubscriptionExpiredFooter from './SubscriptionExpiredFooter';

type Props = {
  open: boolean;
};

const SubscriptionExpired: FC<Props> = ({ open }) => {
  return (
    <BaseSubscriptionModal
      open={open}
      classNames={{
        modal: 'tw-max-h-[min(800px,82vh)]',
      }}
      title={
        <div className="tw-text-xl tw-font-medium">
          Your <strong>subscription</strong> has expired
        </div>
      }
      image={<img src={postIcon} alt="Post icon" />}
      buttons={<SubscriptionExpiredButtons primaryText={'Choose your plan'} />}
      benefits={
        <Benefits
          title={
            'Subscribe to one of our plans, so you don’t lose the benefit of:'
          }
          lists={SUBSCRIPTION_MODAL_BENEFITS.posts}
        />
      }
      footer={
        <SubscriptionExpiredFooter
          text={
            'Not interested in Kontentino anymore? Sorry to hear that, you can leave us feedback and cancel your plan'
          }
        />
      }
    />
  );
};

export default SubscriptionExpired;
