import { TYPE } from '@kontentino/kontentino-constants/Pages';
import {
  FACEBOOK_PAGE,
  INSTAGRAM_PAGE,
} from 'graphql/insights/queries/pageDetailQuery';

export const getQueryToUse = (pageType: number) => {
  if (pageType === TYPE.FACEBOOK) {
    return FACEBOOK_PAGE;
  }

  return INSTAGRAM_PAGE;
};
