import { Button } from '@kontentino/ui';
import { FC } from 'react';
import { openUrl } from 'utils/url';
import { Module } from 'config';
import IntercomUtils from 'app/utils/intercom';

type Props = {
  primaryText: string;
};

const SubscriptionExpiredButtons: FC<Props> = ({ primaryText }) => {
  return (
    <>
      <Button
        onClick={() => openUrl(Module.OldApp.URL.BILLING)}
        className="tw-w-full"
      >
        {primaryText}
      </Button>

      <div>
        or{' '}
        <span
          onClick={() =>
            IntercomUtils.newConversation('Hello! Just having a question')
          }
          className="tw-cursor-pointer tw-text-grayscale-100 tw-underline"
        >
          contact sales
        </span>
      </div>
    </>
  );
};

export default SubscriptionExpiredButtons;
