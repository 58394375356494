import { Button, DropdownMenu, Icon } from '@kontentino/ui';
import {
  faClone,
  faFolder,
  faLayerGroup,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { modalRoutes } from 'constants/routes';
import { UserPermission } from 'constants/userPermission';
import UserPermissionGate from 'components/UserPermissionGate';
import Link from 'components/shared/Link';
import { useTimeSavers } from 'components/adsPlanner/filterBar/timeSavers/TimeSavers.hooks';
import { createSearchFromParams } from 'utils/url';
import { faUserPlus, faUsers } from '@fortawesome/pro-light-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { FC } from 'react';
import { useAdsPannerContext } from 'app/modules/adsPlanner/context';
import { useAdAccountSelectionContext } from 'components/adsPlanner/adAccount/AdAccountSelection';

type Props = {
  onExportToFacebookSelect: () => void;
};

const AdsPlannerTimeSaversDropdown: FC<Props> = ({
  onExportToFacebookSelect,
}) => {
  const { selectedAds, resetAdsSelection } = useAdsPannerContext();
  const { selectedAdAccount } = useAdAccountSelectionContext();
  const timeSavers = useTimeSavers(selectedAds);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger disabled={selectedAds.length === 0}>
        <Button
          variant="secondary"
          className="tw-h-[36px]"
          data-name="ads-planner_time-savers"
          iconBefore={<Icon icon={faLayerGroup} />}
        >
          Time savers
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className="tw-z-10">
        <UserPermissionGate scopes={[UserPermission.POST_MOVE]}>
          <Link isRouterLink to={modalRoutes.MOVE_ADS}>
            <DropdownMenu.Item data-name="ads-planner_time-savers_option-move-ads">
              <Icon icon={faFolder} />
              Move multiple ads
            </DropdownMenu.Item>
          </Link>
        </UserPermissionGate>
        <UserPermissionGate scopes={[UserPermission.POST_DUPLICATE]}>
          <Link isRouterLink to={modalRoutes.DUPLICATE_ADS}>
            <DropdownMenu.Item data-name="ads-planner_time-savers_option-duplicate-ads">
              <Icon icon={faClone} />
              Duplicate multiple ads
            </DropdownMenu.Item>
          </Link>
        </UserPermissionGate>
        <Link
          to={
            timeSavers.canAssignAds
              ? {
                  hash: modalRoutes.ASSIGN_MULTIPLE_TASKS,
                  search: createSearchFromParams({
                    posts: selectedAds.map((adPost) => adPost.ad_post_id),
                    profiles: selectedAdAccount ? [selectedAdAccount.id] : [],
                  }),
                }
              : {}
          }
        >
          <DropdownMenu.Item
            disabled={!timeSavers.canAssignAds}
            data-name="ads-planner_time-savers_option-assign-tasks"
          >
            <Icon icon={faUserPlus} />
            Assign multiple tasks
          </DropdownMenu.Item>
        </Link>
        <UserPermissionGate
          scopes={[UserPermission.POST_SEND_FOR_CLIENT_APPROVAL]}
        >
          <Link
            isRouterLink
            to={
              timeSavers.canSendAdsForClientApproval
                ? {
                    hash: modalRoutes.SEND_FOR_CLIENT_APPROVAL,
                    search: createSearchFromParams({
                      posts: selectedAds.map((adPost) => adPost.ad_post_id),
                      profiles: selectedAdAccount ? [selectedAdAccount.id] : [],
                    }),
                  }
                : {}
            }
          >
            <DropdownMenu.Item
              disabled={!timeSavers.canSendAdsForClientApproval}
              data-name="ads-planner_time-savers_option-send-ads-for-client-approval"
            >
              <Icon icon={faUsers} />
              Send multiple ads for client approval
            </DropdownMenu.Item>
          </Link>
        </UserPermissionGate>
        <UserPermissionGate
          scopes={[UserPermission.POST_SEND_FOR_INTERNAL_APPROVAL]}
        >
          <Link
            isRouterLink
            to={
              timeSavers.canSendAdsForInternalApproval
                ? {
                    hash: modalRoutes.SEND_FOR_INTERNAL_APPROVAL,
                    search: createSearchFromParams({
                      posts: selectedAds.map((adPost) => adPost.ad_post_id),
                      profiles: selectedAdAccount ? [selectedAdAccount.id] : [],
                    }),
                  }
                : {}
            }
          >
            <DropdownMenu.Item
              disabled={!timeSavers.canSendAdsForInternalApproval}
              data-name="ads-planner_time-savers_option-send-ads-for-internal-approval"
            >
              <Icon icon={faUser} />
              Send multiple ads for internal approval
            </DropdownMenu.Item>
          </Link>
        </UserPermissionGate>
        <UserPermissionGate scopes={UserPermission.POST_SCHEDULE}>
          <DropdownMenu.Item
            disabled={!timeSavers.canExportAds}
            data-name="ads-planner_time-savers_option-export-ads"
            onSelect={onExportToFacebookSelect}
          >
            <Icon icon={faFacebook} />
            Export to FB Ads Manager
          </DropdownMenu.Item>
        </UserPermissionGate>
        <DropdownMenu.Item
          onClick={() => resetAdsSelection()}
          data-name="ads-planner_time-savers_option-reset-selection"
        >
          Reset selection
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default AdsPlannerTimeSaversDropdown;
