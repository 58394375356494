import React, { FC } from 'react';
import CommentsListItemEmojiPicker from './CommentsListItemEmojiPicker';
import CommentsListItemMore from './CommentsListItemMore';
import { usePostCommunicationContext } from '../../../../PostCommunicationProvider';
import { Comment } from 'app/modules/comments/types';

type Props = {
  comment: Comment;
};

const CommentsListItemActions: FC<Props> = ({ comment }) => {
  const { activeContent } = usePostCommunicationContext();

  if (activeContent === 'pinned') return null;

  return (
    <>
      <CommentsListItemMore comment={comment} />
      <CommentsListItemEmojiPicker />
    </>
  );
};

export default CommentsListItemActions;
