import { FC } from 'react';
import style from 'components/insights/postsPerformance/postsList/noPostsMessage/NoPostsMessage.module.scss';
import { Icon } from '@kontentino/ui';
import { faWavePulse } from '@fortawesome/pro-regular-svg-icons';

type Props = {
  selectedStartDate: string;
  selectedEndDate: string;
};

const NoPostsMessage: FC<Props> = ({ selectedEndDate, selectedStartDate }) => {
  return (
    <div className={style.wrapper}>
      <div className={style.icon}>
        <Icon icon={faWavePulse} size={'lg'} />
      </div>
      <div className={style.content}>
        <span className={style.main}>
          There are no posts for date:{' '}
          {`${selectedStartDate} - ${selectedEndDate}`}
        </span>
        <span className={style.smaller}>Try using different filters.</span>
      </div>
    </div>
  );
};

export default NoPostsMessage;
