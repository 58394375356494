import { getMetricsForPageType } from 'app/modules/insights/modules/postsPerformance/utils/metricProperty';
import { PageStatisticsData } from 'app/modules/insights/types/PageStatistics';
import { MetricProperty } from 'app/modules/insights/types/MetricProperty';

export function getPageStatisticsCards(
  { metrics, pageType }: PageStatisticsData,
  {
    highlightedCardKey,
    maxLength,
  }: {
    highlightedCardKey?: string;
    maxLength?: number;
  },
) {
  const availableMetrics = getMetricsForPageType(pageType);

  const availableCards: (MetricProperty & {
    key: string;
    value: number | null;
  })[] = [];

  metrics.forEach(([key, value]) => {
    if (availableMetrics[key]) {
      availableCards.push({ value, ...availableMetrics[key] });
    }
  });

  const visibleCards = availableCards.slice(0, maxLength);

  if (
    highlightedCardKey &&
    !visibleCards.map(({ key }) => key).includes(highlightedCardKey)
  ) {
    const cardToPrepend = availableCards.find(
      ({ key }) => key === highlightedCardKey,
    );

    if (cardToPrepend) {
      visibleCards.unshift(cardToPrepend);
      visibleCards.splice(-1);
    }
  }

  return { visible: visibleCards, available: availableCards };
}
