import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'types/Store';
import { AppStore } from 'modules/app/appReducer';
import { AppThunks } from 'modules/app/appActions';

export function useApp(): AppStore {
  return useSelector((state: Store) => state.app);
}

export function useLogoutUser() {
  const dispatch = useDispatch();

  return () => dispatch(AppThunks.logoutUser());
}
