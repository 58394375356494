import { useSelector } from 'react-redux';
import { Store } from 'types/Store';
import { Currencies } from 'types/Listings';

export function useCurrencies(): Currencies {
  return useSelector(
    (state: Store) => state.listings.currencies,
    () => true,
  );
}

export function useListings() {
  return useSelector(
    (state: Store) => state.listings,
    () => true,
  );
}
