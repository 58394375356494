import AdsPlannerApi from 'api/adsplanner';
import { FC, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from 'app/hooks/useToast';
import { Provider } from './context';
import type {
  AggregatedErrors,
  ExportAdsToFacebookApiResponse,
} from 'types/Ad';
import Logger from 'utils/logger';
import { QUERY_KEY } from 'components/adsPlanner/constants';
import i18n from 'i18n';
import UserpilotUtils from 'app/utils/userpilot';
import { useAdAccountSelectionContext } from 'components/adsPlanner/adAccount/AdAccountSelection';

type Params = {
  exportAds: AnyFunction;
  isLoading: boolean;
};

type Props = {
  children: (params: Params) => JSX.Element;
  successCallback?: (data: ExportAdsToFacebookApiResponse) => void;
  ads: number[];
};

export type ExportAdsToFacebookState = {
  exportingAds: number[];
};

interface NewArgs {
  adIds: number[];
  selectedAdAccount: number;
}

const Container: FC<Props> = ({ ads, children, successCallback }) => {
  const [exportingAds, setExportingAds] = useState<number[]>([]);
  const queryClient = useQueryClient();
  const { selectedAdAccount } = useAdAccountSelectionContext();
  const toast = useToast();
  const { mutate: mutateExportAds, isLoading: isExportingLoading } =
    useMutation(
      (newArgs: NewArgs) =>
        AdsPlannerApi.ad.export(newArgs.adIds, newArgs.selectedAdAccount),
      {
        onSuccess: (response) => {
          refetch();
          validateSuccessResponse(response.aggregatedErrors);
          setExportingAds([]);
          UserpilotUtils.track(UserpilotUtils.events.AD_EXPORTED);
          successCallback?.(response);
        },
        onError: (error) => {
          toast(i18n.somethingWentWrongTryAgain, 'error');
          Logger.error(error);
        },
      },
    );

  const refetch = () => {
    // TODO: add ad set id as identification once it comes from API
    queryClient.invalidateQueries([QUERY_KEY.AD_DATA_REQUEST, ads[0]]);
  };

  const exportAds = () => {
    if (selectedAdAccount) {
      setExportingAds(ads);
      mutateExportAds({
        adIds: ads,
        selectedAdAccount: selectedAdAccount.id,
      });
    } else {
      toast(i18n.somethingWentWrongTryAgain, 'error');
    }
  };

  const validateSuccessResponse = (aggregatedErrors: AggregatedErrors) => {
    Logger.log('Aggregated errors:', aggregatedErrors);
    if (aggregatedErrors.length === 0) {
      toast(i18n.adsAreScheduledForExportToFacebook, 'success');
    } else {
      toast(aggregatedErrors.join(), 'warning');
    }
  };

  return (
    <Provider
      value={{
        exportingAds,
      }}
    >
      {children({
        exportAds,
        isLoading: isExportingLoading,
      })}
    </Provider>
  );
};

export default Container;
