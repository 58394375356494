import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

export type Props = {
  selected?: 'all' | 'none';
  onSelect(value: 'all' | 'none'): void;
  dataCy?: {
    wrapper?: string;
    allButton?: string;
    noneButton?: string;
  };
};

const AllSelectionToggler: FC<Props> = ({ selected, dataCy, onSelect }) => {
  const { t } = useTranslation();

  return (
    <div
      data-cy={dataCy?.wrapper}
      className="tw-inline-flex tw-select-none tw-justify-between tw-p-2 tw-text-md tw-font-medium"
    >
      <div className="tw-flex">
        <div
          onClick={() => onSelect('all')}
          data-cy={dataCy?.allButton}
          className={clsx('tw-cursor-pointer', {
            'tw-text-primary-100': selected === 'all',
          })}
        >
          {t('all')}
        </div>
        <div className="tw-mx-1">/</div>
        <div
          onClick={() => onSelect('none')}
          data-cy={dataCy?.noneButton}
          className={clsx('tw-cursor-pointer', {
            'tw-text-primary-100': selected === 'none',
          })}
        >
          {t('clear')}
        </div>
      </div>
    </div>
  );
};

export default AllSelectionToggler;
