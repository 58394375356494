import { Locales } from './locales';

const AdsPlannerConfig = {
  MAX_CAMPAIGN_METRICS_GOAL_VALUE: 1000000,
  NUMBER_FORMAT_LOCALE: Locales.EN_US,
  DEFAULT_CURRENCY_CODE: 'EUR',
  SEARCH_DELAY: 500,
  FILTER_DELAY: 800,
  MAX_FILES_TO_ACCEPT: 30,
  MIN_FILES_TO_ACCEPT: 1,
};

export default AdsPlannerConfig;
