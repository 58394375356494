import React, { FC } from 'react';
import { Alert, Select } from '@kontentino/ui';
import { getHostname } from 'utils/url';
import { useQuery } from 'react-query';
import { queryKey } from 'constants/queryKey';
import { LinkShortenerApi } from 'api/linkShortener';
import { useTranslation } from 'react-i18next';
import { AvailableDomain } from 'types/LinkShortener';

type Props = {
  value: string;
  onChange: (value: string) => void;
};

const CustomDomainSelect: FC<Props> = (props) => {
  const { t } = useTranslation();
  const availableDomains = useQuery<AvailableDomain[], Error>(
    queryKey.linkShortenerAvailableDomains(),
    LinkShortenerApi.availableDomains,
    {
      onSuccess: (data) => {
        if (!props.value && data.length) {
          props.onChange(data[0].name);
        }
      },
    },
  );

  return (
    <div>
      <Select
        isLoading={!availableDomains.isLoading}
        value={{
          value: props.value,
          label: getHostname(props.value),
        }}
        options={
          availableDomains.data?.map((domain) => ({
            value: domain.name,
            label: getHostname(domain.name),
          })) ?? []
        }
        onChange={(option) => props.onChange(option?.value ?? '')}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (styles) => ({
            ...styles,
            zIndex: 1010,
          }),
        }}
      />

      {availableDomains.isError && (
        <Alert variant="danger" className="tw-mt-2">
          {availableDomains.error?.message ?? t('somethingWentWrongTryAgain')}
        </Alert>
      )}
    </div>
  );
};

export default CustomDomainSelect;
