import { useDispatch } from 'react-redux';
import { usePostsContainer } from 'components/posts/context';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { queryKey } from 'constants/queryKey';
import { DemoContentApi } from 'app/modules/posts/api/demoContent';
import { PageThunk } from 'modules/page/pageActions';
import Logger from 'utils/logger';
import { DemoContentStatus } from 'types/DemoContent';
import { PageData, ProfileGroup } from 'types/Page';
import UserpilotUtils from 'app/utils/userpilot';

export function useDemoContentToggle() {
  const dispatch = useDispatch();
  const container = usePostsContainer();
  const queryClient = useQueryClient();
  const { data } = useQuery(
    queryKey.demoContentIsEnabled(),
    DemoContentApi.getIsEnabled,
  );

  const toggle = useMutation(
    (variables: { enabled: boolean }) =>
      DemoContentApi.toggle({ enabled: variables.enabled }),
    {
      async onSettled(data, error, variables) {
        try {
          const res = await (dispatch(
            PageThunk.requestPages(),
          ) as unknown as Promise<
            | {
                profiles: PageData[];
                profileGroups: ProfileGroup[];
              }
            | undefined
          >);

          if (res) {
            selectProfileOnSettled(res.profiles, variables.enabled);
          }

          UserpilotUtils.track(
            variables.enabled
              ? UserpilotUtils.events.DEMO_CONTENT_SWITCHED_ON
              : UserpilotUtils.events.DEMO_CONTENT_SWITCHED_OFF,
          );
        } catch (e) {
          Logger.error(e);
        }
        queryClient.invalidateQueries(queryKey.demoContentIsEnabled());
      },
      onMutate(variables) {
        queryClient.setQueryData<DemoContentStatus>(
          queryKey.demoContentIsEnabled(),
          { enabled: variables.enabled },
        );
      },
    },
  );

  function selectProfileOnSettled(
    profiles: PageData[],
    selectDemoProfile: boolean,
  ) {
    const profileToSelect = profiles.find((profile) =>
      selectDemoProfile ? profile.isDemo : !profile.isDemo,
    );

    container.changeFilter({
      key: 'selectedProfiles',
      value: profileToSelect ? [profileToSelect.id] : [],
    });
  }

  return { toggle, isEnabled: !!data?.enabled };
}
