import Modal, { ModalProps } from 'components/shared/Modal';
import { FC, ReactNode } from 'react';

type Props = {
  title: ReactNode;
  description?: ReactNode;
  image: ReactNode;
  buttons: JSX.Element;
  benefits?: JSX.Element;
  footer?: ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'responsive';
  open: boolean;
} & Pick<ModalProps, 'classNames'>;

const BaseSubscriptionModal: FC<Props> = ({
  title,
  description,
  image,
  buttons,
  benefits,
  footer,
  size = 'sm',
  open,
  classNames,
}) => {
  return (
    <Modal
      classNames={classNames}
      open={open}
      styles={{
        root: { zIndex: 1009 },
      }}
      size={size}
    >
      <Modal.Content className="tw-py-6">
        <div className="tw-flex tw-flex-col tw-items-start tw-gap-6">
          <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-6 tw-p-0">
            <div>{image}</div>
            <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-6 tw-p-0">
              {title}

              {description && (
                <div className="tw-text-center tw-text-md ">{description}</div>
              )}
            </div>

            <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-gap-4 tw-p-0">
              {buttons}
              {benefits}
              {footer && (
                <>
                  <hr className="tw-boder-solid tw-m-0 tw-w-full tw-border-t tw-border-none tw-border-grayscale-20" />
                  <div className="tw-items-center tw-text-center tw-text-sm tw-font-regular tw-text-grayscale-100">
                    {footer}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default BaseSubscriptionModal;
