import {
  TEXT_EDITOR_SUGGESTIONS_DEBOUNCE_DELAY,
  TEXT_EDITOR_SUGGESTIONS_QUERY_START_LENGTH,
  TextEditorFeature,
} from 'app/modules/textEditor/constants/textEditorFeature';
import useDebounce from 'utils/hooks/useDebounce';
import { FC, useMemo } from 'react';
import {
  CoreSuggestion,
  HashtagSuggestion,
  MentionSuggestion,
} from 'types/Suggestions';
import { useQuery } from 'react-query';
import { SuggestionApi } from 'api/suggestions';
import { queryKey } from 'constants/queryKey';

type Props = {
  pageType: number;
  feature: TextEditorFeature;
  query: string;
  children: (
    data: (CoreSuggestion & Partial<HashtagSuggestion & MentionSuggestion>)[],
  ) => JSX.Element;
};

const SuggestionsQueryData: FC<Props> = ({
  pageType,
  query,
  feature,
  children,
}) => {
  const debouncedSearch = useDebounce(
    feature === TextEditorFeature.PageMention
      ? query.replaceAll('_', ' ')
      : query,
    TEXT_EDITOR_SUGGESTIONS_DEBOUNCE_DELAY,
  );

  const { data: suggestions } = useQuery<
    (CoreSuggestion & Partial<HashtagSuggestion & MentionSuggestion>)[]
  >(
    queryKey.textEditorSuggestions([debouncedSearch, pageType]),
    () =>
      SuggestionApi[
        feature === TextEditorFeature.HashtagMention ? 'hashtags' : 'mentions'
      ]({
        query: debouncedSearch,
        pageType: pageType,
      }),
    {
      enabled:
        debouncedSearch.length >= TEXT_EDITOR_SUGGESTIONS_QUERY_START_LENGTH,
    },
  );

  const suggestionsData = useMemo(() => {
    return (
      suggestions?.map((item) =>
        feature === TextEditorFeature.HashtagMention
          ? {
              ...item,
              id: item.name,
            }
          : item,
      ) ?? []
    );
  }, [feature, suggestions]);

  return children(suggestionsData);
};

export default SuggestionsQueryData;
