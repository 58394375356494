import { queryKey } from 'constants/queryKey';
import { useQueryClient } from 'react-query';
import Logger from 'utils/logger';

export const useInvalidatePostsQueries = () => {
  const queryClient = useQueryClient();

  return () => {
    try {
      const queries = [
        queryKey.posts(),
        queryKey.postsMeta(),
        queryKey.dashboardPosts(),
        queryKey.dashboardMyTasks(),
        queryKey.dashboardTasksStatus(),
      ];

      queries.forEach((query) => queryClient.invalidateQueries(query));
    } catch (error) {
      Logger.error(error);
    }
  };
};

export const useInvalidatePostDetailQueries = (id: number) => {
  const queryClient = useQueryClient();

  return () => {
    try {
      const queries = [
        queryKey.postPreview(id),
        queryKey.postCommentsThread(),
        queryKey.postCommentsSummary(),
        queryKey.postDetail(id),
        queryKey.postScheduleValidation(id),
      ];

      queries.forEach((query) => queryClient.invalidateQueries(query));
    } catch (error) {
      Logger.error(error);
    }
  };
};
