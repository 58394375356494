import React, { useState } from 'react';
import LOGO_SPINNER from 'app/images/logo-spinner.gif';
import { animated, useSpring } from '@react-spring/web';
import { useEffectOnce } from 'utils/hooks/useEffectOnce';

const BackdropLoader = () => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffectOnce(() => {
    setHasMounted(true);
  });

  const fadeIn = useSpring({
    opacity: hasMounted ? 1 : 0,
    from: { opacity: 0 },
    config: { duration: 200 },
  });

  return (
    <animated.div
      style={fadeIn}
      data-cy="backdrop-loader"
      data-name="backdrop-loader"
      className="tw-fixed tw-left-0 tw-top-0 tw-z-[10000] tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-bg-white tw-bg-opacity-70"
    >
      <img
        alt="Loading"
        src={LOGO_SPINNER}
        className="tw-h-[56px] tw-w-[56px]"
      />
    </animated.div>
  );
};

export default BackdropLoader;
