import React, { FC } from 'react';
import getPreselectedValuesFromParams from 'app/components/timeSavers/assignMultipleTasksModal/utils/getPreselectedValuesFromParams';
import { ModalRouteProps } from 'context/ModalRouter';
import { useToast } from 'app/hooks/useToast';
import AssignTasksPreselectedPosts from 'app/components/timeSavers/assignMultipleTasksModal/AssignTasksPreselectedPosts';
import AssignTasksWithPostsSelection from 'app/components/timeSavers/assignMultipleTasksModal/AssignTasksWithPostsSelection';
import { useAdsPannerContext } from 'app/modules/adsPlanner/context';

type Props = ModalRouteProps;

const AssignMultipleTasksPage: FC<Props> = (props) => {
  const toast = useToast();
  const { resetAdsSelection } = useAdsPannerContext();

  const handleSuccessRequestTasks = () => {
    resetAdsSelection();
    toast('Tasks are successfully assigned', 'success');
    props.modalManager.close();
  };

  const preselectedValues = getPreselectedValuesFromParams(props.modalManager);

  return preselectedValues.posts.length !== 0 ? (
    <AssignTasksPreselectedPosts
      modalManager={props.modalManager}
      onSuccess={handleSuccessRequestTasks}
      initialValues={preselectedValues}
    />
  ) : (
    <AssignTasksWithPostsSelection
      modalManager={props.modalManager}
      onSuccess={handleSuccessRequestTasks}
    />
  );
};

export default AssignMultipleTasksPage;
