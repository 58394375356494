export const STEPS = {
  SELECT_POSTS: {
    step: 1,
    label: 'Select posts',
  },
  SELECT_RECIPIENTS: {
    step: 2,
    label: 'Select recipients',
  },
};

export const sendForApprovalFormFields = {
  bodyMessage: 'bodyMessage',
  subject: 'subject',
  selectedUsers: 'selectedUsers',
};
