import { faGear } from '@fortawesome/pro-regular-svg-icons';
import { Button, Icon, IconButton, Tooltip } from '@kontentino/ui';
import React, { FC, ReactNode } from 'react';
import Link from '../../../../components/shared/Link';
import clsx from 'clsx';
import SearchTextInput from 'app/components/SearchTextInput';

type Props = {
  children: ReactNode;
  title: string;
  iconAfter?: ReactNode;
  tooltipContent?: string;
  itemAction: {
    label?: string;
    onClick: () => void;
    icon?: ReactNode;
  };
  search: {
    value: string;
    action: (newValue: string) => void;
  };
  dataName?: string;
  manageHref?: string;
};

const OptionsBarFeature: FC<Props> = ({
  children,
  title,
  iconAfter,
  tooltipContent,
  itemAction,
  search,
  dataName,
  manageHref,
}) => {
  return (
    <>
      <div
        className="tw-h-auto tw-max-w-[480px] tw-rounded-md tw-border tw-border-solid tw-border-grayscale-20 tw-bg-white tw-p-4"
        data-name={dataName}
      >
        <div className="tw-mb-4 tw-flex tw-items-center tw-gap-1">
          <span className="tw-text-lg tw-font-semibold">{title}</span>
          {iconAfter && (
            <Tooltip content={tooltipContent} className="tw-text-center">
              <span className="tw-flex tw-cursor-pointer tw-items-center">
                {iconAfter}
              </span>
            </Tooltip>
          )}
        </div>
        <div className="tw-mb-4 tw-flex tw-w-full tw-justify-between tw-gap-2">
          <SearchTextInput
            data-name={dataName ? `${dataName}_search-input` : null}
            value={search.value}
            onChange={(event) => search.action(event.currentTarget.value)}
            className={clsx('tw-flex !tw-h-9 !tw-w-[18.375rem]', {
              '!tw-w-[22.5rem]': manageHref,
            })}
          />
          <div className="tw-flex tw-space-x-2">
            {itemAction.label && itemAction.icon && (
              <Button
                variant="primary"
                size="medium"
                onClick={itemAction.onClick}
                className="tw-whitespace-nowrap"
                iconBefore={itemAction.icon}
                data-name="listItem_new"
              >
                {itemAction.label}
              </Button>
            )}

            {!itemAction.label && itemAction.icon && (
              <IconButton
                icon={itemAction.icon}
                onClick={itemAction.onClick}
                size="medium"
                data-name="listItem_new"
              />
            )}
            {manageHref && (
              <Link
                to={manageHref}
                target="_blank"
                rel="noopener noreferrer"
                className="tw-text-sm"
                data-name="listView_manage"
              >
                <IconButton
                  icon={<Icon icon={faGear} />}
                  variant="secondary"
                  size="medium"
                />
              </Link>
            )}
          </div>
        </div>
        <div className="tw-max-h-64 tw-space-y-4 tw-overflow-y-auto">
          {children}
        </div>
      </div>
    </>
  );
};

export default OptionsBarFeature;
