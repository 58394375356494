import React, { FC } from 'react';
import SegmentDistributionBar from './SegmentDistributionBar';
import { colors } from '@kontentino/ui';
import { formatMetricValue } from '../utils/formatMetricValue';

type Props = {
  metricKey: string;
  metricLabel: string;
  metricValue: number;
  metricValueTotal: number;
  benchmark: number;
};

const greenColor = colors.status2;
const redColor = colors.danger.default;
const defaultColor = colors.gray20;
const defaultValueColor = colors.gray80;

const BenchmarkBars: FC<Props> = ({
  metricLabel,
  metricValue,
  metricValueTotal,
  benchmark,
  metricKey,
}) => {
  const benchmarkWidth = Math.round((benchmark / metricValueTotal) * 100);
  const metricValueWidth = Math.round((metricValue / metricValueTotal) * 100);

  const metricBar = {
    value: formatMetricValue(metricKey, metricValue),
    metricKey,
    color: metricValue >= benchmark ? greenColor : redColor,
    width: `${metricValueWidth}%`,
  };

  const benchmarkBar = {
    value: formatMetricValue(metricKey, benchmark),
    metricKey,
    color: defaultColor,
    valueColor: defaultValueColor,
    width: `${benchmarkWidth}%`,
  };

  return (
    <>
      <SegmentDistributionBar
        segments={[metricBar]}
        labelPosition="top"
        label={metricLabel}
      />
      <SegmentDistributionBar
        segments={[benchmarkBar]}
        labelPosition="top"
        label="Benchmark"
        tooltip="This is the average performance of all link posts published on your profile in the past six months."
      />
    </>
  );
};

export default BenchmarkBars;
