import React, { FC } from 'react';
import { useReactionSummary } from '../providers/ReactionSummaryProvider';
import CommentsListItemReaction from './CommentsListItemReaction';
import CommentsListItemEmojiPicker from './commentsListItemActions/CommentsListItemEmojiPicker';
import clsx from 'clsx';

type Props = {
  itsMe: boolean;
};

const CommentsListItemReactionsList: FC<Props> = ({ itsMe }) => {
  const { reactions } = useReactionSummary();

  return (
    <div
      data-name="comments_comment-reactions"
      className={clsx(`tw-flex tw-flex-wrap tw-gap-2`, {
        'tw-justify-end': itsMe,
        'tw-justify-start': !itsMe,
      })}
    >
      {reactions.map((reaction) => {
        return (
          <CommentsListItemReaction
            key={`CommentsListItemReaction-${reaction.content}`}
            reaction={reaction}
          />
        );
      })}
      <div
        className={clsx('tw-flex tw-items-center tw-justify-center', {
          'tw-order-first': itsMe,
        })}
      >
        <div className="tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-grayscale-5 tw-p-1">
          <CommentsListItemEmojiPicker
            size={14}
            iconClassName="tw-text-grayscale-100"
          />
        </div>
      </div>
    </div>
  );
};

export default CommentsListItemReactionsList;
