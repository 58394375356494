import { BrowserStorage } from 'utils/browserStorage';

export const getGraphsConfigurationForPages = () => {
  const configFromStorage = BrowserStorage.get(
    BrowserStorage.keys.Insights.InsightsGraphsConfig,
  );
  const pageFansActiveTab = BrowserStorage.get(
    BrowserStorage.keys.Insights.InsightsPageFansActiveTab,
  );
  const pagePostCumulativeReachActiveTab = BrowserStorage.get(
    BrowserStorage.keys.Insights.InsightsPagePostReachActiveTab,
  );

  const areAllNeededValuesAvailable =
    !!configFromStorage &&
    !!pageFansActiveTab &&
    !!pagePostCumulativeReachActiveTab;

  if (areAllNeededValuesAvailable) {
    const parsedConfig = JSON.parse(configFromStorage);

    return `&${Object.keys(parsedConfig)
      .map((key) => key + '=' + parsedConfig[key])
      .join(
        '&',
      )}&pageFansActiveTab=${pageFansActiveTab}&cumulativeReachActiveTab=${pagePostCumulativeReachActiveTab}`;
  }
};
