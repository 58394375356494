import { FC } from 'react';
import { PageData, ProfileGroup } from 'types/Page';
import ProfileSelector from 'app/components/ProfileSelector';
import PostsProfileSelectorDataList from './postsProfileSelector/PostsProfileSelectorDataList';
import PostsProfileSelectorFooter from './postsProfileSelector/PostsProfileSelectorFooter';
import usePostsSelectorFilter from './postsProfileSelector/hooks/usePostsSelectorFilter';
import { ProfileSelectorFilterOptionKey } from 'app/components/profileSelector/types/profileSelectorFilter';
import getProfileSelectorTitleByFilterOption from 'app/components/profileSelector/utils/getProfileSelectorTitleByFilterOption';
import { ChangePostsFilterHandler } from 'app/modules/posts/hooks/useFilterState';

type Props = {
  profiles: PageData[];
  profileGroups: ProfileGroup[];
  selectedProfilesIds: number[];
  selectedProfileGroupsIds: number[];
  onChange: ChangePostsFilterHandler;
};

const PostsProfileSelector: FC<Props> = ({
  profiles,
  profileGroups,
  selectedProfilesIds,
  selectedProfileGroupsIds,
  onChange,
}) => {
  const {
    sidebarFilterOptions,
    searchQuery,
    selectedFilter,
    filteredOptions,
    selectFilter,
    setSearchQuery,
  } = usePostsSelectorFilter({
    profiles,
    profileGroups,
    selectedProfileGroupsIds,
    selectedProfilesIds,
  });

  const handleToggleAllSelection = (selectionOption: 'all' | 'none') => {
    if (selectionOption === 'none') {
      const profileIds = profiles.map((profile) => profile.id);

      if (profileIds.length) {
        onChange(
          {
            key: 'selectedProfiles',
            value: [profileIds[0]],
          },
          {
            key: 'selectedGroups',
            value: [],
          },
        );
        selectFilter(ProfileSelectorFilterOptionKey.All);
      }
    } else {
      if (getIsSelectedAll()) return;

      switch (selectedFilter) {
        case ProfileSelectorFilterOptionKey.All:
        case ProfileSelectorFilterOptionKey.Selected:
          selectProfileGroups();
          selectProfiles();
          selectFilter(ProfileSelectorFilterOptionKey.All);
          break;
        case ProfileSelectorFilterOptionKey.ProfileGroups:
          selectProfileGroups();
          break;
        case ProfileSelectorFilterOptionKey.Profiles:
        default:
          selectProfiles();
          break;
      }
    }
  };

  const selectProfileGroups = () => {
    const profileGroupsIds = (
      filteredOptions as (ProfileGroup & {
        isGroup?: boolean;
      })[]
    )
      .filter((option) => option.isGroup)
      .map((profileGroup) => profileGroup.id);

    onChange({
      key: 'selectedGroups',
      value: profileGroupsIds,
    });
  };

  const selectProfiles = () => {
    const profileIds = (
      filteredOptions as (PageData & {
        isGroup?: boolean;
      })[]
    )
      .filter((option) => !option.isGroup)
      .map((profile) => profile.id);

    onChange({
      key: 'selectedProfiles',
      value: profileIds,
    });
  };

  const getIsSelectedAll = () => {
    switch (selectedFilter) {
      case ProfileSelectorFilterOptionKey.All:
        return (
          profileGroups.length === selectedProfileGroupsIds.length &&
          profiles.length === selectedProfilesIds.length
        );
      case ProfileSelectorFilterOptionKey.ProfileGroups:
        return profileGroups.length === selectedProfileGroupsIds.length;
      case ProfileSelectorFilterOptionKey.Selected:
        return true;
      default:
        return filteredOptions
          .map((profile) => profile.id)
          .every((profileId) => selectedProfilesIds.includes(profileId));
    }
  };

  const getPlaceholderData = () => {
    const pagesData = profiles.filter((page) =>
      selectedProfilesIds.includes(page.id),
    );
    const profileGroupsData = profileGroups.filter((profileGroup) =>
      selectedProfileGroupsIds.includes(profileGroup.id),
    );

    return [...profileGroupsData, ...pagesData].map((option) => ({
      ...option,
      logoSrc: option.logo?.src,
    }));
  };

  return (
    <ProfileSelector
      placeholderData={getPlaceholderData()}
      dataName={{ placeholder: 'posts-profile-select' }}
      title={getProfileSelectorTitleByFilterOption(selectedFilter)}
      isSelectedAll={getIsSelectedAll()}
      initialOpen={profiles.length === 0}
      filter={{
        searchQuery,
        onSearch: setSearchQuery,
        selectedFilterOption: selectedFilter,
        options: sidebarFilterOptions,
        onSelectFilterOption: selectFilter,
      }}
      optionsElement={
        <PostsProfileSelectorDataList
          data={filteredOptions}
          selectedProfileGroupsIds={selectedProfileGroupsIds}
          onSelectProfileGroup={(id, multiple) => {
            if (multiple) {
              onChange({ key: 'selectedGroups', value: id });
            } else {
              onChange(
                {
                  key: 'selectedProfiles',
                  value: [],
                },
                {
                  key: 'selectedGroups',
                  value: [id],
                },
              );
            }
          }}
          selectedProfilesIds={selectedProfilesIds}
          onSelectProfile={(id, multiple) => {
            if (multiple) {
              onChange({ key: 'selectedProfiles', value: id });
            } else {
              onChange(
                {
                  key: 'selectedProfiles',
                  value: [id],
                },
                {
                  key: 'selectedGroups',
                  value: [],
                },
              );
            }
          }}
        />
      }
      onToggleAllSelection={handleToggleAllSelection}
      footer={<PostsProfileSelectorFooter />}
    />
  );
};

export default PostsProfileSelector;
