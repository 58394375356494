import {
  applyMiddleware,
  combineReducers,
  createStore,
  Middleware,
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import { isDevelopmentMode, isReduxLogerOn } from 'utils/helpers';
import type { Store } from 'types/Store';
import userReducer from 'modules/user/userReducer';
import appReducer from 'modules/app/appReducer';
import accountReducer from 'modules/account/accountReducer';
import postReducer from 'modules/post/postReducer';
import pageReducer from 'modules/page/pageReducer';
import listingsReducer from 'modules/listings/listingsReducer';

function createMiddleware() {
  const middleware: Array<Middleware> = [thunkMiddleware];

  if (isDevelopmentMode()) {
    if (isReduxLogerOn()) {
      middleware.push(createLogger());
    }

    return composeWithDevTools(applyMiddleware(...middleware));
  }

  return applyMiddleware(...middleware);
}

function configureStore(preloadedState?: Object) {
  return createStore(
    combineReducers<Store>({
      account: accountReducer,
      user: userReducer,
      app: appReducer,
      post: postReducer,
      page: pageReducer,
      listings: listingsReducer,
    }),
    preloadedState,
    createMiddleware(),
  );
}

const store = configureStore();

export default store;
