import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';

export type PostCollapsedLayoutVariant = 'grid' | 'rows';

type Props = {
  variant: PostCollapsedLayoutVariant;
  thumbnail: ReactNode;
  benchmarkBars: ReactNode;
  performanceInfo: ReactNode;
  metricsValueBoxes: ReactNode;
  publishedAt: ReactNode;
  actionButtons: ReactNode;
};

const border = `tw-border tw-border-solid tw-border-grayscale-20`;

const PostCollapsedLayout: FC<Props> = (props) => {
  return (
    <div
      className={clsx('tw-mb-4 tw-flex tw-rounded-md tw-bg-white tw-p-4 ', {
        border: props.variant === 'rows',
      })}
    >
      {props.variant === 'grid' && (
        <>
          {props.thumbnail}
          <div className={`tw-min-h-[192px] tw-flex-1 tw-rounded-md ${border}`}>
            <div className="tw-flex tw-border-b tw-border-solid tw-border-grayscale-20">
              <div className="tw-flex-1 tw-px-4 tw-pb-[6px] tw-pt-[9px]">
                {props.benchmarkBars}
              </div>
              <div className="tw-w-[324px] tw-border-l tw-border-solid tw-border-grayscale-20 tw-p-4 ">
                {props.performanceInfo}
              </div>
            </div>
            <div className="tw-flex">
              <div className="tw-flex-1">{props.metricsValueBoxes}</div>
              <div className="tw-w-[324px] tw-border-l tw-border-solid tw-border-grayscale-20 tw-p-4 ">
                {props.publishedAt}
                {props.actionButtons}
              </div>
            </div>
          </div>
        </>
      )}
      {props.variant === 'rows' && (
        <>
          {props.thumbnail}
          <div className={`tw-flex-1 ${border} tw-rounded-md`}>
            <div>
              <div className="tw-flex-1 tw-border-b tw-border-solid tw-border-grayscale-20 tw-px-4 tw-pb-[6px] tw-pt-[9px]">
                {props.benchmarkBars}
              </div>
              <div className="tw-border-b tw-border-solid tw-border-grayscale-20 tw-p-4  ">
                {props.performanceInfo}
              </div>
            </div>
            <div className="tw-border-b tw-border-solid tw-border-grayscale-20">
              {props.metricsValueBoxes}
            </div>
            <div className="tw-p-4">{props.publishedAt}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default PostCollapsedLayout;
