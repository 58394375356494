import Logger from 'utils/logger';

const CurrencyUtils = {
  format(
    value: number,
    currencyCode: string,
    locale: string = 'en',
    options?: Intl.NumberFormatOptions,
  ) {
    try {
      if (currencyCode === '%') {
        return `${value.toLocaleString(locale)} %`;
      }

      const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        ...options,
      });

      return formatter.format(value).replace(/\s/g, ' ');
    } catch (e) {
      Logger.error(e);
      return value.toString();
    }
  },
};

export default CurrencyUtils;
