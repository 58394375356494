import React, { FC, useState } from 'react';
import { InputLabel, ListView, TextInput } from '@kontentino/kontentino-ui';
import { FormGroup } from '@kontentino/kontentino-ui/build/form';
import i18n from 'i18n';
import { useMutation } from 'react-query';
import { SuggestionApi } from 'api/suggestions';
import { preventDefault } from 'utils/mouseEvent';
import { useToast } from 'app/hooks/useToast';
import TextEditorConvertor from 'app/modules/textEditor/utils/textEditorConvertor';
import { Button } from '@kontentino/ui';

type Props = {
  onCopyToText(link: string): void;
};

const DirectFbMention: FC<Props> = (props) => {
  const toast = useToast();
  const [value, setValue] = useState('');
  const findMention = useMutation(SuggestionApi.getFacebookMention, {
    onSettled(res) {
      if (res) {
        toast('Mention added.');
        props.onCopyToText(TextEditorConvertor.suggestionToMentionTag(res));
      } else {
        toast('Unable to find mention.', 'warning');
      }
    },
  });

  return (
    <ListView
      title="Add a page mention by name slug or url"
      className="!tw-rounded"
    >
      <form
        onSubmit={preventDefault(() => findMention.mutate(value))}
        style={{ paddingRight: 4 }}
      >
        <FormGroup>
          <InputLabel>Name slug or url</InputLabel>
          <TextInput
            placeholder="e.g. @nutella or facebook.com/nutella..."
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Button
            type="submit"
            className="tw-mb-1 tw-ml-auto"
            isLoading={findMention.isLoading}
          >
            {i18n.add}
          </Button>
        </FormGroup>
      </form>
    </ListView>
  );
};

export default DirectFbMention;
