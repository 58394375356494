import React, { FC } from 'react';
import { colors } from '@kontentino/ui';

type Props = {
  cx?: number;
  cy?: number;
  outerFill: string;
  outerRadius?: number;
  innerRadius?: number;
  innerFill?: string;
  payload?: Record<string, any>;
  dataKey?: string;
};

const GraphDot: FC<Props> = ({
  cx,
  cy,
  outerFill,
  outerRadius = 6,
  innerRadius = 3,
  innerFill = colors.white,
  payload,
  dataKey,
}) => {
  if (typeof payload?.[dataKey ?? ''] !== 'number') return null;

  return (
    <svg width="100%" height="100%">
      <circle cx={cx} cy={cy} fill={outerFill} r={outerRadius} />
      <circle cx={cx} cy={cy} fill={innerFill} r={innerRadius} />
    </svg>
  );
};
export default GraphDot;
