import { TYPE_FACEBOOK_AD } from '@kontentino/kontentino-constants/Pages';
import ProfileSelectorOption from 'app/components/profileSelector/components/ProfileSelectorOption';
import { AdAccount, AdAccountConnectionTokenStatus } from 'types/AdAccount';
import { FC } from 'react';
import useAdAccountReconnect from 'app/modules/adsPlanner/hooks/useAdAccountReconnect';

type Props = {
  adAccount: AdAccount;
  isChecked: boolean;
  onSelect: (adAccount: AdAccount) => void;
};

const AdAccountsSelectorOption: FC<Props> = ({
  adAccount,
  isChecked,
  onSelect,
}) => {
  const { reconnect } = useAdAccountReconnect(adAccount);

  const shouldReconnectAdAccount = [
    AdAccountConnectionTokenStatus.Expired,
    AdAccountConnectionTokenStatus.Invalid,
  ].includes(adAccount.connectionStatus);

  return (
    <ProfileSelectorOption
      isSingleSelect
      option={{
        label: adAccount.name,
        socialMediaType: TYPE_FACEBOOK_AD,
      }}
      isChecked={isChecked}
      eventHandlers={{
        onClick: () => onSelect(adAccount),
        onReconnectClick: shouldReconnectAdAccount ? reconnect : undefined,
      }}
    />
  );
};

export default AdAccountsSelectorOption;
