import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistoryQueryParams } from './useHistoryQueryParams';

export function useQueryParamState<TAllowedValues extends string>(
  paramKey: string,
  {
    initialValue,
    allowedValues,
  }: {
    initialValue: TAllowedValues;
    allowedValues: TAllowedValues[];
  },
) {
  const history = useHistoryQueryParams();
  const [paramValue, setParamValue] = useState<TAllowedValues>(initialValue);

  const isAllowedValue = useCallback(
    (value: string): value is TAllowedValues =>
      allowedValues.includes(value as TAllowedValues),
    [allowedValues],
  );

  useEffect(() => {
    const value = history.getQueryParameter(paramKey);

    if (isAllowedValue(value)) {
      setParamValue(value);
    }
  }, [history, paramKey, isAllowedValue]);

  const set = useCallback(
    (value: TAllowedValues) => {
      if (!isAllowedValue(value)) return;
      history.updateQueryParameter(paramKey, value);
    },
    [history, paramKey, isAllowedValue],
  );

  return useMemo(() => [paramValue, set] as const, [paramValue, set]);
}
