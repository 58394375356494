import { FacebookPages, InstagramPages } from 'types/Facebook';
import { post } from 'api/client';
import ArrayUtils from 'app/utils/array';

type FBResponse<T> = {
  data: T;
  paging: {
    next?: string;
  };
};

function fbGraphApi<T>(url: string): Promise<FBResponse<T>> {
  return new Promise((resolve, reject) => {
    window.FB.api(url, (res: any) => {
      if (res) {
        resolve(res);
      }
      reject(res?.error);
    });
  });
}

const FacebookAPI = {
  async allFacebookPages(accessToken: string): Promise<FacebookPages> {
    let pages: FacebookPages = [];

    let res = await fbGraphApi<FacebookPages>(
      `/me/accounts?limit=30&fields=id,name,pages,picture.width(320).fields(url),username,access_token,global_brand_root_id,global_brand_page_name,name_with_location_descriptor&access_token=${accessToken}`,
    );

    while (Array.isArray(res.data) && res.data.length > 0) {
      pages = pages.concat(res.data);

      if (typeof res.paging.next === 'string') {
        res = await fbGraphApi(res.paging.next);
      } else break;
    }

    return ArrayUtils.sortByKey(pages, 'name_with_location_descriptor');
  },

  async allInstagramPages(accessToken: string): Promise<InstagramPages> {
    let pages: InstagramPages = [];

    let res = await fbGraphApi<InstagramPages>(
      `/me/accounts?fields=name,access_token,instagram_business_account.fields(id,name,profile_picture_url,username)&limit=100&access_token=${accessToken}`,
    );

    while (Array.isArray(res.data) && res.data.length > 0) {
      pages = pages.concat(res.data);

      if (typeof res.paging.next === 'string') {
        res = await fbGraphApi(res.paging.next);
      } else break;
    }
    pages = pages.filter((page) => !!page.instagram_business_account);

    return ArrayUtils.sortByKey(pages, 'name');
  },

  updateFacebookAccessToken(token: string, scopes: string[]): Promise<void> {
    return post('user/ajaxUpdateFacebookAccessToken', null, {
      params: {
        token,
        scopes: scopes.join(),
      },
    });
  },
};

export default FacebookAPI;
