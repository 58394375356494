import React, { FC } from 'react';
import { PermissionsProvider } from '@kontentino/react-permissions-gate';
import { useUser } from 'modules/user/userSelector';

type Props = {
  children: React.ReactNode;
};

const UserPermissionsProvider: FC<Props> = ({ children }) => {
  const { permissionsKeys } = useUser();

  return (
    <PermissionsProvider store={{ permissions: permissionsKeys }}>
      {children}
    </PermissionsProvider>
  );
};

export default UserPermissionsProvider;
