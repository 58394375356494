/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useMemo, useState } from 'react';
import { togglePrimitiveItemInArray } from 'utils/helpers';
import type { AssignTasksParams } from 'api/post';
import { validateForm } from 'app/components/timeSavers/assignMultipleTasksModal/utils/form';
import useSetState from 'utils/hooks/useSetState';
import { ListPost } from 'types/PostDetail';

type Params = {
  assignTasks: () => void;
  selectedStatuses: number[];
  selectedPosts: ListPost[];
  togglePostStatus: (status: number) => void;
  togglePost: (post: ListPost) => void;
  selectPosts: (posts: ListPost[]) => void;
  selectedPagesIds: number[];
  handleChangeSelectedUsers: (users: number[]) => void;
  changeMessage: (value: string) => void;
  selectUrgent: () => void;
  selectEmailNotification: () => void;
  formErrors: Record<string, string>;
  formState: FormState;
};

type Props = {
  children: (params: Params) => JSX.Element;
  onSubmit?: (params: AssignTasksParams) => void;
  preselectedValues?: PreSelectedValues;
};

export type PreSelectedValues = {
  profiles: number[];
  posts: number[];
};

export type FormState = {
  selectedPosts: ListPost[];
  selectedUsers: number[];
  taskMessage: string;
  isUrgent: boolean;
  canSendEmailNotification: boolean;
};

const Container: FC<Props> = ({ children, onSubmit, preselectedValues }) => {
  const [formState, setFormState] = useSetState<FormState>({
    selectedPosts: [],
    selectedUsers: [],
    taskMessage: '',
    isUrgent: false,
    canSendEmailNotification: true,
  });
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [selectedStatuses, setSelectedStatuses] = useState<number[]>([]);

  const getPreSelectedValues = (): PreSelectedValues => ({
    profiles: preselectedValues?.profiles ?? [],
    posts: preselectedValues?.posts ?? [],
  });

  const handleTogglePostStatus = (status: number): void => {
    setSelectedStatuses((prevSelectedStatuses) => {
      return togglePrimitiveItemInArray<number>(status, prevSelectedStatuses);
    });
  };

  const handleTogglePost = (post: ListPost) => {
    setFormState((prevState) => {
      const posts = [...prevState.selectedPosts];

      if (posts.map((item) => item.id).includes(post.id)) {
        const itemIndex = posts.findIndex((item) => item.id === post.id);

        if (itemIndex !== -1) {
          posts.splice(itemIndex, 1);
        }
      } else {
        posts.push(post);
      }

      return {
        selectedPosts: posts,
      };
    });
  };

  const handleSelectPosts = (posts: ListPost[]) => {
    setFormState({ selectedPosts: posts });
  };

  const selectedPagesIds = useMemo(() => {
    if (!!getPreSelectedValues().profiles.length) {
      return getPreSelectedValues().profiles;
    }

    const pagesIds: number[] = [];

    formState.selectedPosts.forEach((post) => {
      if (!pagesIds.includes(post.page.id)) {
        pagesIds.push(post.page.id);
      }
    });

    return pagesIds;
  }, [formState.selectedPosts]);

  const handleChangeTaskMessage = (value: string) => {
    setFormState({
      taskMessage: value,
    });
  };

  const handleChangeSelectedUsers = (selectedUsers: number[]) => {
    setFormState({ selectedUsers });

    setFormErrors((prevState) => {
      delete prevState.selectedUsers;

      return prevState;
    });
  };

  const handleSubmitForm = () => {
    let postsIds = [];
    if (!!getPreSelectedValues().posts.length) {
      postsIds = getPreSelectedValues().posts;
    } else {
      postsIds = formState.selectedPosts.map((post) => post.id);
    }

    const validationErrors = validateForm(formState, postsIds);

    if (Object.keys(validationErrors).length !== 0) {
      setFormErrors(validationErrors);
      return;
    }

    onSubmit?.({
      posts: postsIds,
      users: formState.selectedUsers,
      message: formState.taskMessage.length ? formState.taskMessage : '➡️👨‍💻',
      isUrgent: formState.isUrgent,
      canSendEmail: formState.canSendEmailNotification,
    });
  };

  return children({
    assignTasks: handleSubmitForm,
    selectedStatuses,
    togglePostStatus: handleTogglePostStatus,
    selectedPosts: formState.selectedPosts,
    togglePost: handleTogglePost,
    selectPosts: handleSelectPosts,
    selectedPagesIds,
    handleChangeSelectedUsers,
    changeMessage: handleChangeTaskMessage,
    selectUrgent: () =>
      setFormState((prevState) => ({
        isUrgent: !prevState.isUrgent,
      })),
    selectEmailNotification: () =>
      setFormState((prevState) => ({
        canSendEmailNotification: !prevState.canSendEmailNotification,
      })),
    formErrors,
    formState,
  });
};

export default Container;
