import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PostDetail } from 'types/PostDetail';
import { useQuery } from 'react-query';
import { queryKey } from 'constants/queryKey';
import PostApi from 'api/post';
import { getPostAttachmentCount } from 'app/modules/posts/utils/getPostAttachmentCount';

type Props = {
  postId: PostDetail['id'];
};

const MediaCountLabel: React.FC<Props> = ({ postId }) => {
  const { t } = useTranslation();
  const { data } = useQuery<PostDetail>(queryKey.postDetail(postId), () =>
    PostApi.detail(postId),
  );

  const label = useMemo(() => {
    const { images, videos } = getPostAttachmentCount(
      data?.attachments?.cards || [],
    );

    const tImage = t('imageWithCount', { count: images });
    const tVideo = t('videoWithCount', { count: videos });

    if (images > 0 && videos > 0) {
      return `${tImage} and ${tVideo}`;
    } else if (images > 0) {
      return tImage;
    } else if (videos > 0) {
      return tVideo;
    } else {
      return t('noMediaFound');
    }
  }, [data?.attachments, t]);

  return (
    <div className="tw-w-full tw-px-2 tw-text-left tw-text-xs tw-text-grayscale-100">
      {label}
    </div>
  );
};

export default MediaCountLabel;
