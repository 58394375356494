import React, { FC } from 'react';
import Item from 'components/date-range-picker/presets/Item';
import { ALL_DATE_PRESETS } from '@kontentino/kontentino-constants/Presets';

type Props = {
  presets: string[];
  onPresetClick(value: string | null): void;
  selectedPreset?: string | null;
};

const List: FC<Props> = ({ presets, onPresetClick, selectedPreset }) => (
  <>
    {presets.map((preset) => (
      <Item
        key={preset}
        isActive={selectedPreset === preset}
        label={ALL_DATE_PRESETS[preset]}
        onClick={() => onPresetClick(preset)}
      />
    ))}
    <Item
      isActive={selectedPreset === null}
      label="Custom"
      onClick={() => onPresetClick(null)}
    />
  </>
);

export default List;
