import { FC } from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from 'react-router-dom';
import { UIThemeProvider } from '@kontentino/ui';
import * as Sentry from '@sentry/react';
import InfoBannerProvider from 'app/components/InfoBannerProvider';
import { QueryParamProvider } from 'use-query-params';
import { CookiesProvider } from 'react-cookie';
import { WindowHistoryAdapter } from 'use-query-params/adapters/window';
import { getBasePath } from 'utils/url';
import routeConfig from 'app/config/routes';
import { publicRouter } from 'app/modules/public';
import { privateRouter } from 'app';
import FatalError from 'app/components/FatalError';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(
  [
    {
      errorElement: <FatalError type="notfound" />,
      element: (
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      ),
      children: [
        {
          index: true,
          element: <Navigate to={routeConfig.posts.path} />,
        },
        publicRouter,
        privateRouter,
      ],
    },
  ],
  {
    basename: getBasePath(),
  },
);

export const App: FC = () => {
  return (
    <CookiesProvider>
      <UIThemeProvider>
        <ErrorBoundary>
          <InfoBannerProvider>
            <QueryParamProvider adapter={WindowHistoryAdapter}>
              <RouterProvider router={router} />
            </QueryParamProvider>
          </InfoBannerProvider>
        </ErrorBoundary>
      </UIThemeProvider>
    </CookiesProvider>
  );
};

export default Sentry.withProfiler(App);
