import { Tag } from '@kontentino/ui';
import ColorUtils from 'app/utils/color';
import clsx from 'clsx';
import React, { ComponentProps, FC } from 'react';
import { useTheme } from 'utils/hooks/useTheme';

export type LabelTagProps = {
  name: string;
  color?: string;
} & ComponentProps<typeof Tag>;

const LabelTag: FC<LabelTagProps> = ({
  name,
  color,
  className,
  style,
  ...props
}) => {
  const theme = useTheme();
  const backgroundColor = color ?? theme.colors.white;

  return (
    <Tag
      size="medium"
      style={{
        backgroundColor,
        color: ColorUtils.hasLowContrastRatio(
          theme.colors.grayscale['180'],
          backgroundColor,
        )
          ? theme.colors.white
          : theme.colors.grayscale['180'],
        ...style,
      }}
      className={clsx('tw-max-w-[250px] tw-overflow-hidden', className)}
      {...props}
    >
      <span className="tw-line-clamp-1 tw-text-ellipsis tw-break-all">
        {name}
      </span>
    </Tag>
  );
};

export default LabelTag;
