import type { FormState } from 'components/workflowOptions/sendForApproval/FormStateContainer';

export const validateForm = (
  formState: FormState,
  preSelectedPosts: number[],
): Record<string, string> => {
  let errors: Record<string, string> = {};

  if (!formState.selectedPosts.length && !preSelectedPosts.length) {
    errors.selectedPosts = 'At least one post must be selected';
  }

  if (!formState.selectedUsers.length && !formState.additionalEmails) {
    errors.selectedUsers = 'At least one user must be selected or invited';
  }

  if (formState.subject?.trim().length === 0) {
    errors.subject = 'Subject line is required';
  }

  if (formState.bodyMessage?.trim().length === 0) {
    errors.bodyMessage = 'Body message is required';
  }

  return errors;
};
