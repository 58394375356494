import React, { FC, ReactNode } from 'react';
import Container from 'components/posts/Container';
import SectionLayout from 'components/shared/SectionLayout';
import PostsModals from 'components/modals/PostsModals';
import getPostsTitleFromPagesAndProfileGroups from '../app/modules/posts/utils/getPostsTitleFromPagesAndProfileGroups';
import PostsLayoutContentBar from 'app/modules/posts/components/postsLayout/PostsLayuoutContentBar';
import { usePostsLayoutActiveViewController } from 'app/modules/posts/hooks/usePostsLayoutActiveViewController';
import PostsLayoutTopBar from 'app/modules/posts/components/postsLayout/PostsLayoutTopBar';

type Props = {
  children: ReactNode;
};

const Posts: FC<Props> = (props) => {
  usePostsLayoutActiveViewController();

  return (
    <Container>
      {(params) => (
        <>
          <SectionLayout
            title={getPostsTitleFromPagesAndProfileGroups({
              profiles: params.pages,
              profileGroups: params.profileGroups,
              selectedProfilesIds: params.filterState.selectedProfiles,
              selectedProfileGroupsIds: params.filterState.selectedGroups,
            })}
            header={
              <>
                <PostsLayoutTopBar />
                <div className="tw-px-4 tw-pt-2">
                  <PostsLayoutContentBar />
                </div>
              </>
            }
            main={props.children}
          />
          <PostsModals />
        </>
      )}
    </Container>
  );
};

export default Posts;
