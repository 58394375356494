import { useMemo, useState } from 'react';

type InitialState = boolean | (() => boolean);

export default function useBoolean(initialState: InitialState = false) {
  const [boolean, setBoolean] = useState<boolean>(initialState);
  const callbacks = useMemo(
    () => ({
      on: () => setBoolean(true),
      off: () => setBoolean(false),
      toggle: () => setBoolean((prev) => !prev),
    }),
    [],
  );

  return [boolean, callbacks] as const;
}
