import { useQuery } from '@apollo/client';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import { useMemo } from 'react';
import {
  POSTS_ON_FB_PAGE_QUERY,
  POSTS_ON_IG_PAGE_QUERY,
  POSTS_ON_LI_PAGE_QUERY,
} from 'graphql/insights/queries/pagePostsQuery';
import { PageType } from 'graphql/insights/types';
import { MetricProperty } from 'app/modules/insights/types/MetricProperty';
import { BenchmarksForPosts } from 'app/types/BenchmarksForPosts';
import { OrderDirection } from 'app/types';
import { Dayjs } from 'dayjs';
import DateUtils from 'app/utils/date';

type Props = {
  page: PageType;
  startDate: Dayjs;
  endDate: Dayjs;
  metric: MetricProperty;
  onPostsCountChanged?: (count: Record<string, number> | undefined) => void;
};

export const usePostsPageQuery = ({
  page,
  startDate,
  endDate,
  metric,
  onPostsCountChanged,
}: Props) => {
  const variables = {
    id: page.id,
    dateFrom: DateUtils.toDateString(startDate),
    dateTo: DateUtils.toDateString(endDate),
    orderBy: [
      {
        field: metric.queryParameter,
        direction: OrderDirection.Desc.toUpperCase(),
      },
    ],
  };

  const fb = useQuery(POSTS_ON_FB_PAGE_QUERY, {
    variables,
    skip: page._type !== TYPE.FACEBOOK,
    onCompleted: (data) =>
      onPostsCountChanged?.(data?.facebookPage?.postTypesCount),
  });

  const ig = useQuery(POSTS_ON_IG_PAGE_QUERY, {
    variables,
    skip: page._type !== TYPE.INSTAGRAM,
    onCompleted: (data) =>
      onPostsCountChanged?.(data?.instagramPage?.postTypesCount),
  });

  const li = useQuery(POSTS_ON_LI_PAGE_QUERY, {
    variables,
    skip: page._type !== TYPE.LINKEDIN,
    onCompleted: (data) => {
      // TODO: Need BE changes first
      // setPostTypesCount(data?.linkedInPage?.postTypesCount);
    },
  });

  const data:
    | {
        hasPaidReach?: boolean;
        benchmarks: BenchmarksForPosts;
      }
    | undefined = useMemo(() => {
    switch (page._type) {
      case TYPE.INSTAGRAM:
        return { benchmarks: ig.data?.instagramPage?.benchmarksForPosts ?? {} };
      case TYPE.FACEBOOK:
        return {
          hasPaidReach: fb.data?.facebookPage?.statistics?.paidReach > 0,
          benchmarks: fb.data?.facebookPage?.benchmarksForPosts ?? {},
        };
      case TYPE.LINKEDIN:
        return { benchmarks: li.data?.linkedInPage?.benchmarksForPosts ?? {} };
    }
  }, [fb.data, ig.data, li.data, page._type]);

  return {
    data: data,
    loading: fb.loading || ig.loading || li.loading,
    error: fb.error || ig.error || li.error,
  };
};
