import { useCallback, useEffect, useState } from 'react';
import InsightsConfig from 'app/config/insights';
import dayjs, { Dayjs } from 'dayjs';
import TimeUtils from 'app/utils/time';
import useInterval from 'app/hooks/useInterval';

export function useIsSyncDisabled(lastFinishedAt: Dayjs | undefined) {
  const [isSyncDisabled, setIsSyncDisabled] = useState(false);
  const [remainingMinutes, setRemainingMinutes] = useState(0);

  const getIsSyncDisabled = useCallback(() => {
    if (lastFinishedAt === undefined) {
      return false;
    }

    const diffInMinutes = getDiffInMinutes(lastFinishedAt);

    return (
      diffInMinutes < InsightsConfig.REPEAT_SYNC_LIMIT_IN_MINUTES &&
      diffInMinutes >= 0
    );
  }, [lastFinishedAt]);

  const setValues = useCallback(() => {
    setIsSyncDisabled(getIsSyncDisabled());
    setRemainingMinutes(
      Math.abs(
        getDiffInMinutes(lastFinishedAt) -
          InsightsConfig.REPEAT_SYNC_LIMIT_IN_MINUTES,
      ),
    );
  }, [getIsSyncDisabled, lastFinishedAt]);

  useInterval(() => {
    setValues();
  }, TimeUtils.calcMilliseconds.minutes(1));

  useEffect(() => {
    setValues();
  }, [setValues]);

  return {
    isSyncDisabled,
    remainingMinutes,
  };
}

function getDiffInMinutes(date: Dayjs | undefined) {
  return dayjs().diff(date, 'minutes');
}
