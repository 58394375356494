import { TYPE } from '@kontentino/kontentino-constants/Pages';
import { FACEBOOK_GRAPHS, INSTAGRAM_GRAPHS } from 'constants/analyticsGraphs';

export const getGraphsToUse = (pageType: number) => {
  switch (pageType) {
    case TYPE.FACEBOOK:
      return FACEBOOK_GRAPHS;
    case TYPE.INSTAGRAM:
      return INSTAGRAM_GRAPHS;
    default:
      return [];
  }
};
