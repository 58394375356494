import React, { FC } from 'react';
import { modalRoutes } from 'constants/routes';
import ModalRoute from 'context/ModalRouter';
import ReferralModal from 'app/components/modals/referral/ReferralModal';
import PlanLimitReachedModal from 'app/components/modals/planLimitReached/PlanLimitReachedModal';
import InsightsLiteInvitationModal from 'app/components/modals/insightsLiteInvitation/InsightsLiteInvitationModal';

const CommonModals: FC = () => (
  <>
    <ModalRoute path={modalRoutes.REFERRAL_PROGRAM} component={ReferralModal} />
    <ModalRoute
      path={modalRoutes.PROFILE_LIMIT_REACHED}
      component={PlanLimitReachedModal}
    />
    <InsightsLiteInvitationModal />
  </>
);

export default CommonModals;
