import { FC } from 'react';
import style from 'components/insights/competitionAnalysis/pagesPerformance/pageRowHeader/instagramPage/InstagramPage.module.scss';

type Props = {};

const InstagramPage: FC<Props> = () => {
  return (
    <div className={style.rowsHeader}>
      <span
        className={style.pageName}
        data-cy="insights_competition-analysis_page-performance-table-page-name-instagram"
      >
        Page
      </span>
      <div
        className={style.metrics}
        data-cy="insights_competition-analysis_page-performance-table-metrics-instagram"
      >
        <span className={style.valueTitle}>No. of followers</span>
        <span className={style.valueTitle}>No. of posts</span>
        <span className={style.valueTitle}>Interactions</span>
        <span className={style.valueTitle}>Comments</span>
        <span className={style.valueTitle}>Reactions</span>
      </div>
    </div>
  );
};

export default InstagramPage;
