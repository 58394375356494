import { FC } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import Logger from 'utils/logger';
import { QUERY_KEY } from 'components/adsPlanner/constants';
import PostTimeSaversApi, {
  SendForApprovalParams,
} from 'app/api/postTimeSavers';
import { useToast } from 'app/hooks/useToast';
import { queryKey } from 'constants/queryKey';
import { ApiClientError } from 'api/client';
import { useTranslation } from 'react-i18next';

type Params = {
  submitSendForApproval: (params: Omit<SendForApprovalParams, 'type'>) => void;
  isLoading: boolean;
};

type Props = {
  children: (params: Params) => JSX.Element;
  successCallback?: () => void;
  type: ApprovalType;
};

const RequestContainer: FC<Props> = ({ children, successCallback, type }) => {
  const toast = useToast();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate: mutateSendForApproval, isLoading: isSendForApprovalLoading } =
    useMutation(PostTimeSaversApi.sendForApproval, {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.ADS_FOR_AD_SET);
        queryClient.invalidateQueries(queryKey.dashboardPosts());
        queryClient.invalidateQueries(queryKey.postRevisions());

        successCallback?.();
      },
      onError: (error: ApiClientError) => {
        toast(error?.userMessage ?? t('somethingWentWrongTryAgain'), 'error');
        Logger.error(error);
      },
    });

  const handleSubmitSendForApproval = (
    formValues: Omit<SendForApprovalParams, 'type'>,
  ) => {
    mutateSendForApproval({ type, ...formValues });
  };

  return children({
    submitSendForApproval: handleSubmitSendForApproval,
    isLoading: isSendForApprovalLoading,
  });
};

export default RequestContainer;
