enum Success {
  OK = 200,
  CREATED,
  ACCEPTED,
  NO_CONTENT,
  RESET_CONTENT,
  PARTIAL_CONTENT,
}

enum Redirect {
  MULTIPLE_CHOICE = 300,
  MOVED_PERMANENTLY,
  FOUND,
  SEE_OTHER,
  NOT_MODIFIED,
  TEMPORARY_REDIRECT = 307,
  PERMANENT_REDIRECT,
}

enum Error {
  BAD_REQUEST = 400,
  UNAUTHORIZED,
  PAYMENT_REQUIRED,
  FORBIDDEN,
  NOT_FOUND,
  NOT_ALLOWED,
  NOT_ACCEPTABLE,
  PROXY_AUTH_REQUIRED,
  TIMEOUT,
  CONFLICT,
  GONE,
  UNPROCESSABLE = 422,
  TOO_MANY_REQUESTS = 429,
  INTERNAL_SERVER_ERRROR = 500,
  NOT_IMPLEMENTED,
  BAD_GATEWAY,
  SERVICE_UNAVAILABLE,
  GATEWAY_TIMEOUT,
  NETWORK_AUTHENTICATION_REQUIRED = 511,
}

export const Responses = {
  SUCCESSFUL: Success,
  REDIRECT: Redirect,
  ERROR: Error,
};
