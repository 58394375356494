import { FC } from 'react';
import {
  AreaChart as AreaGraph,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  ResponsiveContainer,
} from 'recharts';
import { GraphContent } from 'types/insights/Graphs';
import { GraphKeysForComparing } from 'constants/analyticsGraphs';
import { colors } from '@kontentino/ui';

type Props = {
  data: GraphContent[];
  primaryDataKey: string;
  secondaryDataKey?: string | null;
  isAnimationActive?: boolean;
};

const AreaChart: FC<Props> = ({
  data,
  primaryDataKey,
  secondaryDataKey,
  isAnimationActive,
}) => {
  return (
    <ResponsiveContainer>
      <AreaGraph data={data}>
        <defs>
          <linearGradient id="colorPrimary" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={colors.primary.default}
              stopOpacity={0.8}
            />
            <stop
              offset="95%"
              stopColor={colors.primary.default}
              stopOpacity={0}
            />
          </linearGradient>
          <linearGradient id="colorSecondary" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={colors.orange.default}
              stopOpacity={0.8}
            />
            <stop
              offset="95%"
              stopColor={colors.orange.default}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="key"
          fontSize={14}
          tick={primaryDataKey !== GraphKeysForComparing.default}
        />
        <YAxis fontSize={14} domain={['dataMin', 'dataMax']} />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Area
          type="monotone"
          dataKey={primaryDataKey}
          stroke={colors.primary.default}
          fillOpacity={1}
          fill="url(#colorPrimary)"
          isAnimationActive={isAnimationActive}
        />
        {!!secondaryDataKey && (
          <Area
            type="monotone"
            dataKey={secondaryDataKey}
            stroke={colors.orange.default}
            fillOpacity={1}
            fill="url(#colorSecondary)"
            isAnimationActive={isAnimationActive}
          />
        )}
      </AreaGraph>
    </ResponsiveContainer>
  );
};

export default AreaChart;
