import { get, post } from 'api/client';
import { jsonToFormData } from 'utils/formData';
import {
  MediaLibraryAlbum,
  MediaLibraryImageV1,
  MediaLibraryItems,
  VideoThumbnail,
} from 'types/MediaLibrary';
import { getExtendedTimeout } from 'utils/request';
import { CancelToken } from 'axios';
import FilesUtil from 'utils/files';
import config from 'config';
import { padStart } from '@kontentino/kontentino-utils/string';
import { Video } from 'types/Video';
import { openBlank } from 'utils/url';

type UploadImageParams = {
  file: File;
  albumId?: number;
  cancelToken?: CancelToken;
  onProgress?(progress: number): void;
};

type GetAlbumMediaParams = {
  albumId: number;
  offset: number;
  type?: string;
};

type UploadVideoParams = {
  file: File;
  userId: number;
  pageId?: number;
  albumId?: number;
  userAccountPublicId: string;
  cancelToken: CancelToken;
  onProgress?(progress: number): void;
};

export const MediaApi = {
  uploadImage({
    file,
    albumId,
    cancelToken,
    onProgress,
  }: UploadImageParams): Promise<Record<string, MediaLibraryImageV1>> {
    const data = new FormData();

    if (albumId) {
      data.append('album_id', albumId.toString());
    }

    data.append('medialibrary_files_photos[0]', file);

    return post('/gallery/ajaxJustUpload', data, {
      timeout: getExtendedTimeout(2),
      cancelToken,
      onUploadProgress: (progressEvent: ProgressEvent) => {
        if (progressEvent.lengthComputable && !!onProgress) {
          onProgress(
            Math.ceil((progressEvent.loaded / progressEvent.total) * 100),
          );
        }
      },
    });
  },
  async uploadVideo(params: UploadVideoParams) {
    function startPhase(): Promise<{ sessionId: number }> {
      const data = new FormData();

      data.append('userId', params.userId.toString());
      data.append('targetFileSize', params.file.size.toString());
      data.append('filenameWithExtension', params.file.name);
      data.append('userAccountPublicId', params.userAccountPublicId.toString());

      if (params.pageId) {
        data.append('pageId', params.pageId.toString());
      }

      if (params.albumId) {
        data.append('albumId', params.albumId.toString());
      }

      return post(`/videos/create-video-upload-session `, data, {
        cancelToken: params.cancelToken,
        baseURL: config.MEDIA_UPLOAD_SERVICE_ENDPOINT,
      });
    }

    async function transferPhase(sessionId: number) {
      const chunks = FilesUtil.splitFileToChunks(params.file);

      for (let i = 0; i < chunks.length; i++) {
        const partName = padStart(i, 5, '0');
        const fileChunk = new File([chunks[i]], partName);
        const data = new FormData();

        data.append('sessionId', sessionId.toString());
        data.append('chunk', fileChunk);
        data.append('partName', partName);

        const percentagesByChunk = 100 / chunks.length;

        await post(`/videos/transfer-video-chunk`, data, {
          cancelToken: params.cancelToken,
          baseURL: config.MEDIA_UPLOAD_SERVICE_ENDPOINT,
          timeout: getExtendedTimeout(10),
          onUploadProgress: (progressEvent) => {
            if (progressEvent.lengthComputable && !!params.onProgress) {
              const chunkProgress =
                (progressEvent.loaded / progressEvent.total) *
                  percentagesByChunk +
                i * percentagesByChunk;

              params.onProgress(Math.ceil(chunkProgress));
            }
          },
        });

        params.onProgress?.(Math.ceil(percentagesByChunk * (i + 1)));
      }
    }

    function finishPhase(sessionId: number): Promise<{ videoId: number }> {
      const data = new FormData();
      data.append('sessionId', sessionId.toString());

      params.onProgress?.(100);

      return post(`/videos/complete-video-and-upload-to-cloud`, data, {
        cancelToken: params.cancelToken,
        baseURL: config.MEDIA_UPLOAD_SERVICE_ENDPOINT,
        timeout: getExtendedTimeout(10),
      });
    }

    const { sessionId } = await startPhase();
    await transferPhase(sessionId);
    const finishPhaseResponse = await finishPhase(sessionId);

    if (finishPhaseResponse?.videoId) {
      return { videoId: finishPhaseResponse.videoId };
    }

    throw new Error('Can not get video ID');
  },
  getVideo(id: number): Promise<Video> {
    return get('/videos/ajaxGetVideo', {
      params: { id },
    });
  },
  getAlbumMedia: ({
    albumId,
    offset,
    type,
  }: GetAlbumMediaParams): Promise<MediaLibraryItems> => {
    return get(`/albums/ajaxGetAlbumMediaAndVideos`, {
      params: {
        offset,
        type,
        album_id: albumId,
      },
    });
  },
  deleteMedium(id: number): Promise<void> {
    return post(`gallery/ajaxRemoveMedium`, jsonToFormData({ id }));
  },
  getAlbums(): Promise<MediaLibraryAlbum[]> {
    return get(`/albums/ajaxGetAlbums`);
  },
  uploadVideoThumbnail(data: {
    videoId: number;
    thumbnail: File;
    thumbnailOffsetMilliseconds?: number;
  }): Promise<VideoThumbnail> {
    const requestData: {
      video_id: number;
      'medialibrary_files_photos[0]': File;
      thumbnail_offset_milliseconds?: number;
    } = {
      video_id: data.videoId,
      'medialibrary_files_photos[0]': data.thumbnail,
    };

    if (data.thumbnailOffsetMilliseconds) {
      requestData['thumbnail_offset_milliseconds'] =
        data.thumbnailOffsetMilliseconds;
    }

    return post(
      '/Posts/ajaxUploadVideoThumbnail',
      jsonToFormData(requestData),
      {
        timeout: getExtendedTimeout(3),
      },
    );
  },
  removeVideoThumbnail(videoId: number) {
    return post(
      '/Posts/ajaxRemoveVideoThumbnail',
      jsonToFormData({ video_id: videoId }),
    );
  },
  downloadMedium(params: { id: string | number; type: 'image' | 'file' }) {
    const url =
      params.type === 'image'
        ? `/posts2/downloadMedium?id=${params.id}`
        : `/media/ajaxDownloadFileMedium?id=${params.id}`;

    openBlank(url);
  },
};
