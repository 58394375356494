import React from 'react';
import Link from 'components/shared/Link';
import { NavigationItem } from 'components/navigation/utils/libs';
import { getNavigationItemUrl } from 'components/navigation/verticalNavigation/mainMenu/MenuItems';
import { Icon, IconProps, Tooltip } from '@kontentino/ui';
import clsx from 'clsx';
import { VerticalMenuItemWrapper } from '../VerticalMenuItemWrapper';
import { useVerticalNavigation } from '../provider/VerticalNavigationProvider';
import useBoolean from 'utils/hooks/useBoolean';

type Props = {
  item: NavigationItem;
  isActive?: boolean;
  onMouseLeave?: () => void;
  showLabel?: boolean;
  size?: IconProps['size'];
};

const MenuLink: React.FC<Props> = ({
  item,
  isActive,
  onMouseLeave,
  showLabel,
  size = 'lg',
}) => {
  const [isHovered, setIsHovered] = useBoolean(false);
  const { onMouseEnter } = useVerticalNavigation();
  const slug = `navigation-link-${item.key}`;

  const LinkComponent = (
    <Link
      onClick={(e) => {
        if (!(item.url || item.routerUrl || item.hash)) {
          e.preventDefault();
        }
      }}
      to={item.hash ? { hash: item.hash } : getNavigationItemUrl(item)}
      isRouterLink={!!item.routerUrl}
      data-name={slug}
      data-cy={slug}
      onMouseEnter={() => {
        setIsHovered.on();
        if (item.key && !item.isPageSubmenu) {
          onMouseEnter(item);
        }
      }}
      onMouseLeave={() => {
        setIsHovered.off();
        onMouseLeave?.();
      }}
    >
      <div>
        <VerticalMenuItemWrapper
          isActiveMenu={isActive}
          className=" hover:tw-text-primary-100"
        >
          <span
            className={clsx(
              'tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-md',
              {
                'tw-bg-primary-10': isHovered,
              },
            )}
          >
            {item.icon && (
              <Icon
                className={clsx(
                  'tw-transition-all tw-duration-100 tw-ease-in-out ',
                  {
                    'tw-text-grayscale-100': showLabel,
                  },
                )}
                icon={item.icon}
                size={size}
              />
            )}
          </span>
        </VerticalMenuItemWrapper>
      </div>
    </Link>
  );

  if (item.items) {
    return LinkComponent;
  }

  return (
    <Tooltip
      placement="right"
      content={item.label}
      arrow={false}
      className="tw-shadow-md"
    >
      {LinkComponent}
    </Tooltip>
  );
};

export default MenuLink;
