import { get } from 'api/client';

export const ListingsApi = {
  async timezones(): Promise<{
    iterable: { value: string; label: string }[];
    record: Record<string, string>;
  }> {
    const timezones = await get<Record<string, string>>(
      `/pages/ajaxGetTimezones`,
    );

    return {
      record: timezones,
      iterable: Object.entries(timezones).map(([value, label]) => ({
        value,
        label,
      })),
    };
  },
  globalListings() {
    return get('/api/ajaxGetGlobalListings');
  },
};
