import {
  STATUSES_ALLOWED_FOR_TASK_ASSIGNMENT,
  STATUSES_ALLOWED_FOR_EXPORT_TO_SOCIAL_MEDIA,
  STATUSES_ALLOWED_FOR_SEND_TO_INTERNAL_APPROVAL,
  STATUSES_ALLOWED_FOR_SEND_TO_CLIENT_APPROVAL,
  STATUSES_ALLOWED_FOR_CONVERT_PRIVATE_DRAFT_TO_DRAFT,
  STATUSES_ALLOWED_FOR_CONVERT_WAITING_FOR_TO_DONE,
  STATUSES_ALLOWED_FOR_INTERNAL_APPROVAL,
  STATUSES_ALLOWED_FOR_CLIENT_APPROVAL,
} from '@kontentino/kontentino-constants/Posts';
import type { PostStatus } from '@kontentino/kontentino-constants/Posts';
import { useMemo } from 'react';

export const PostApprovalWorkflowPermissions = (postStatus: PostStatus) => ({
  canAssign: (STATUSES_ALLOWED_FOR_TASK_ASSIGNMENT as PostStatus[]).includes(
    postStatus,
  ),
  canBeSendToInternalApproval: (STATUSES_ALLOWED_FOR_SEND_TO_INTERNAL_APPROVAL as PostStatus[]).includes(
    postStatus,
  ),
  canBeSendToClientApproval: (STATUSES_ALLOWED_FOR_SEND_TO_CLIENT_APPROVAL as PostStatus[]).includes(
    postStatus,
  ),
  canSchedule: (STATUSES_ALLOWED_FOR_EXPORT_TO_SOCIAL_MEDIA as PostStatus[]).includes(
    postStatus,
  ),
  canConvertPrivateDraftToDraft: (STATUSES_ALLOWED_FOR_CONVERT_PRIVATE_DRAFT_TO_DRAFT as PostStatus[]).includes(
    postStatus,
  ),
  canConvertWaitingForToDone: (STATUSES_ALLOWED_FOR_CONVERT_WAITING_FOR_TO_DONE as PostStatus[]).includes(
    postStatus,
  ),
  canBeClientApprovable: (STATUSES_ALLOWED_FOR_CLIENT_APPROVAL as PostStatus[]).includes(
    postStatus,
  ),
  canBeInternalApprovable: (STATUSES_ALLOWED_FOR_INTERNAL_APPROVAL as PostStatus[]).includes(
    postStatus,
  ),
});

export const usePostApprovalWorkflowPermissions = (postStatus: PostStatus) =>
  useMemo(() => PostApprovalWorkflowPermissions(postStatus), [postStatus]);
