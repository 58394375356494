import React, { FC } from 'react';
import { PagePost } from 'types/insights/CompetitionAnalysis';
import clsx from 'clsx';
import { formatNumberValue } from 'app/modules/insights/modules/pagePerformance/utils/format';

type Props = {
  showReactions: boolean;
  post: PagePost;
};

const FacebookPostRow: FC<Props> = ({ post, showReactions }) => {
  const baseMetrics = [
    post.interactions ?? 0,
    post.commentsTotal,
    post.sharesTotal,
  ];

  return (
    <div className="tw-flex">
      <div
        className={clsx('tw-flex tw-justify-center tw-text-center tw-text-md', {
          'tw-w-[45%]': showReactions,
          'tw-w-[100%]': !showReactions,
        })}
      >
        {(showReactions
          ? baseMetrics
          : [...baseMetrics, post.reactionsTotal]
        ).map((value, index) => (
          <div className="tw-flex-1" key={index}>
            {formatNumberValue(value)}
          </div>
        ))}
      </div>
      {showReactions && (
        <div className="tw-flex tw-w-[55%] tw-text-center tw-text-md">
          {[
            post.reactions.like,
            post.reactions.love,
            post.reactions.haha,
            post.reactions.wow,
            post.reactions.sad,
            post.reactions.angry,
          ].map((value, index) => (
            <div className="tw-flex-1" key={index}>
              {formatNumberValue(value)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FacebookPostRow;
