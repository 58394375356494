import React, { FC, ReactElement, ReactNode } from 'react';
import clsx from 'clsx';

type Props = {
  color: string;
  icon: ReactElement;
  message: string | ReactNode;
  height?: number | string;
  ignoreIconSize?: boolean;
  className?: string;
};

const EmptyMessageLayout: FC<Props> = ({
  color,
  icon,
  message,
  ignoreIconSize = false,
  height = 'auto',
  className,
}) => {
  return (
    <div
      className={clsx(
        'tw-flex tw-flex-row tw-items-center tw-justify-center',
        className,
      )}
      style={{
        height,
      }}
    >
      <div
        className="tw-flex tw-h-[2.5rem] tw-w-[2.5rem] tw-flex-shrink-0 tw-items-center tw-justify-center tw-rounded-[50%] tw-bg-opacity-10"
        style={{
          backgroundColor: color,
        }}
      >
        {React.cloneElement(icon, {
          color: color,
          size: ignoreIconSize ? undefined : 18,
        })}
      </div>
      {message && <div className="tw-ml-3 tw-text-sm">{message}</div>}
    </div>
  );
};

export default EmptyMessageLayout;
