import React, { FC } from 'react';
import AdAccountsList from './AdAccountsList';
import Modal from 'components/shared/Modal';
import { AnalyticsAdAccount } from 'types/AdAccount';

type Props = {
  open: boolean;
  title: string;
  onHide: () => void;
  controls: {
    availableAdAccounts: AnalyticsAdAccount[];
    handleAdAccountChange: (facebookAdAccount: AnalyticsAdAccount) => void;
    isAdAccountConnected: (adAccount: AnalyticsAdAccount) => boolean;
  };
};

export const ConnectAdAccountModal: FC<Props> = ({
  open,
  title,
  onHide,
  controls,
}) => {
  return (
    <Modal open={open} onClose={onHide}>
      <Modal.Header title={title} />
      <AdAccountsList
        adAccounts={controls.availableAdAccounts}
        onChange={controls.handleAdAccountChange}
        isConnected={controls.isAdAccountConnected}
      />
    </Modal>
  );
};
