import { MediaPreviewRoot } from './MediaPreviewRoot';
import { MediaPreviewOverlay } from './MediaPreviewOverlay';
import { MediaPreviewImage } from './MediaPreviewImage';
import { MediaPreviewActionButton } from './MediaPreviewActionButton';
import { MediaPreviewActions } from './MediaPreviewActions';

const MediaPreview = {
  Root: MediaPreviewRoot,
  Overlay: MediaPreviewOverlay,
  Image: MediaPreviewImage,
  Actions: MediaPreviewActions,
  ActionButton: MediaPreviewActionButton,
};

export default MediaPreview;
