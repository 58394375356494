import env from 'config';
import { get, post, httpDelete } from 'api/client';
import type { AdSet, Filter, Pagination } from 'types/AdSet';
import { CreateAdSetParameters, EditAdSetParams } from 'api/adsplanner';

export type AdSetsResponse = Omit<ApiResponse, 'data'> & { data: AdSet[] };

export const adSet = {
  get: (id: number): Promise<AdSet> => {
    return get(`ads-planner/ad-set/${id}`, {
      baseURL: env.API_SERVICE_ENDPOINT,
    });
  },
  all: (
    campaignId: number,
    pagination?: Pagination,
    filter?: Filter,
  ): Promise<AdSetsResponse> => {
    return get('ads-planner/ad-set', {
      baseURL: env.API_SERVICE_ENDPOINT,
      params: {
        campaignId,
        limit: pagination?.limit,
        offset: pagination?.offset,
        start_date: filter?.startDate,
        end_date: filter?.endDate,
        tags: filter?.tags.length ? filter.tags.join() : [],
        workflow_statuses: filter?.workflowStatuses.length
          ? filter.workflowStatuses.join()
          : [],
      },
    });
  },
  create: (params: CreateAdSetParameters): Promise<AdSet> => {
    const queryParams: Pick<
      CreateAdSetParameters,
      'name' | 'startDate' | 'endDate'
    > = {
      name: params.name,
      startDate: params.startDate,
      endDate: params.endDate,
    };
    const optionalParams: Pick<
      CreateAdSetParameters,
      'destination_type' | 'promoted_object_value' | 'promoted_object_key'
    > = {};

    if (params.destination_type) {
      optionalParams.destination_type = params.destination_type;
    }

    if (params.promoted_object_value) {
      optionalParams.promoted_object_key = 'page_id';
      optionalParams.promoted_object_value = params.promoted_object_value;
    }

    return post(
      'ads-planner/ad-set',
      {
        ...queryParams,
        ...optionalParams,
        ...params.budgetManagement,
      },
      {
        baseURL: env.API_SERVICE_ENDPOINT,
        params: {
          campaignId: params.campaignId,
        },
      },
    );
  },
  edit: (params: EditAdSetParams): Promise<AdSet> => {
    const requiredParams: Pick<
      EditAdSetParams,
      'id' | 'name' | 'startDate' | 'endDate'
    > = {
      id: params.id,
      name: params.name,
      startDate: params.startDate,
      endDate: params.endDate,
    };
    const optionalParams: Pick<EditAdSetParams, 'destination_type'> = {};

    if (params.destination_type) {
      optionalParams.destination_type = params.destination_type;
    }

    return post(
      `ads-planner/ad-set/${params.id}`,
      {
        ...requiredParams,
        ...optionalParams,
        ...params.budgetManagement,
      },
      {
        baseURL: env.API_SERVICE_ENDPOINT,
      },
    );
  },
  delete: (id: number): Promise<[]> => {
    return httpDelete(`ads-planner/ad-set/${id}`, {
      baseURL: env.API_SERVICE_ENDPOINT,
    });
  },
  duplicate: (params: {
    adAccountId: number;
    adSetsIds: number[];
    campaignsIds: number[];
  }): Promise<AdSetsResponse> => {
    return post('ads-planner/ad-set/duplicate', null, {
      baseURL: env.API_SERVICE_ENDPOINT,
      params: {
        adAccountId: params.adAccountId,
        adSets: params.adSetsIds.join(),
        campaigns: params.campaignsIds.join(),
      },
    });
  },
};
