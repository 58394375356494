import Logger from 'utils/logger';
import { IntercomUser } from 'types/User';
import config from 'config';

declare global {
  interface Window {
    Adopto?: any;
  }
}

export const identifyBugpilotUser = (user: IntercomUser) => {
  try {
    if (!config.BUGPILOT_ID) {
      Logger.log('Skipping bugpilot identify');
      return;
    }

    if (typeof window?.Adopto?.identify === 'function') {
      const { user_id, ...rest } = user;

      return window.Adopto.identify(user_id, rest);
    }

    throw new Error('Bugpilot (Adopto) is not defined on window');
  } catch (e) {
    Logger.error(e);
  }
};
