import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';

type Props = {
  isCurrent: boolean;
  colorVariant: 'primary' | 'purple';
  children: ReactNode;
  isReplyBubble?: boolean;
  className?: string;
  innerClassName?: string;
};

const CommentsListItemBubbleWrapper: FC<Props> = ({
  isCurrent,
  colorVariant,
  children,
  isReplyBubble,
  className,
  innerClassName,
}) => {
  return (
    <div
      data-name="comments_comment-bubble"
      className={clsx(
        'tw-group/comment-bubble tw-flex',
        {
          'tw-justify-end': isCurrent,
          '-tw-mt-5': isReplyBubble,
        },
        className,
      )}
    >
      <div
        className={clsx(
          'tw-max-w-[496px] tw-flex-grow-0 tw-gap-2 tw-rounded-xl tw-px-4 tw-py-[6px] tw-text-md tw-font-regular',
          {
            'tw-bg-primary-90 tw-text-white':
              isCurrent && colorVariant === 'primary',
            'tw-bg-grayscale-5 tw-text-grayscale-180':
              !isCurrent && colorVariant === 'primary',
            'tw-bg-purple-90 tw-text-white':
              isCurrent && colorVariant === 'purple',
            'tw-bg-purple-10 tw-text-grayscale-180':
              !isCurrent && colorVariant === 'purple',
          },
          innerClassName,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default CommentsListItemBubbleWrapper;
