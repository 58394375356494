import React, { FC, ReactNode } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Field,
  Icon,
  mergeTailwindClasses,
  Select,
  TextArea,
  Tooltip,
} from '@kontentino/ui';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { AIContentOptionValue } from 'app/modules/posts/api/aiContent';
import z from 'zod';
import { AiContentTextFormState } from 'app/modules/aiContent/components/aiContent/AiContentText';
import TextEditorAiContentPopupGenerateFooter from 'app/modules/textEditor/components/textEditorAiContent/TextEditorAiContentPopupGenerateTextFooter';
import TextGeneratedResults from 'app/modules/aiContent/components/results/TextGeneratedResults';
import { useTextEditorAiContent } from 'app/modules/textEditor/components/textEditorAiContent/TextEditorAiContentProvider';
import { useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';

type Props = {
  renderResultAction?: (text: string) => ReactNode;
  pageType?: number;
};

function mapOptionToSelect(option: AIContentOptionValue) {
  return {
    value: option.key,
    label: option.label,
  };
}

const DEFAULT_LANGUAGE_KEY = 'english';

const defaultValues: AiContentTextFormState = {
  prompt: '',
  language: DEFAULT_LANGUAGE_KEY,
  social_network: null,
  style: null,
};

const optionalString = z.string().optional().nullable();

const schema = z.object({
  prompt: z.string().trim().min(1),
  language: optionalString,
  social_network: optionalString,
  style: optionalString,
});

const TextEditorAiContentPopupGenerateText: FC<Props> = ({
  renderResultAction,
}) => {
  const { t } = useTranslation();
  const {
    queries: {
      generateText,
      options: { data: options },
    },
  } = useTextEditorAiContent();

  const form = useForm<AiContentTextFormState>({
    defaultValues: defaultValues,
    resolver: zodResolver(schema),
    reValidateMode: 'onSubmit',
  });

  function onSubmit(values: AiContentTextFormState) {
    if (generateText.isLoading) return;

    generateText.mutate({
      prompt: values.prompt,
      social_network: values.social_network,
      language: values.language,
      style: values.style,
    });
  }

  const submit = form.handleSubmit(onSubmit);

  return (
    <form
      onSubmit={submit}
      className={mergeTailwindClasses(
        clsx('tw-flex tw-h-[410px] tw-w-full tw-flex-col tw-overflow-hidden', {
          'tw-h-[374px]': !generateText.isLoading && generateText.data,
        }),
      )}
      data-name="ai-content-generate-text"
    >
      {!generateText.data && !generateText.isLoading && (
        <div className="tw-flex-grow tw-space-y-4 tw-p-4">
          <>
            <Field.Group>
              <Field.Label required>{t('whatIsYourPostAbout')}</Field.Label>
              {form.formState.errors.prompt?.message && (
                <Field.Error>
                  {form.formState.errors.prompt?.message}
                </Field.Error>
              )}
              <TextArea
                data-name="ai-content-text_prompt"
                disabled={generateText.isLoading}
                placeholder={t('describeYourPost')}
                rows={5}
                {...form.register('prompt')}
              />
            </Field.Group>

            <Field.Group>
              <Field.Label required>Output language</Field.Label>
              <Controller
                name="language"
                control={form.control}
                render={({ field: { onChange, value } }) => {
                  const languages = (options?.language ?? []).map(
                    mapOptionToSelect,
                  );
                  const selectValue = languages.find(
                    (option) => option.value === value,
                  );

                  return (
                    <Select
                      options={languages}
                      isDisabled={generateText.isLoading}
                      onChange={(option) => onChange(option?.value ?? null)}
                      value={selectValue ?? null}
                      menuPortalTarget={document.body}
                      menuPlacement="auto"
                      styles={{
                        menuPortal: (styles) => ({
                          ...styles,
                          zIndex: 1010,
                        }),
                      }}
                      data-name="ai-content-language-select"
                    />
                  );
                }}
              />
              <Field.Error>
                {form.formState.errors.language?.message}
              </Field.Error>
            </Field.Group>
            <Field.Group>
              <Field.Label required>
                {t('toneOfVoice')}
                <Tooltip content={t('toneOfVoiceDescription')}>
                  <Icon
                    className="tw-ml-2 tw-text-grayscale-100"
                    icon={faInfoCircle}
                    size="sm"
                  />
                </Tooltip>
              </Field.Label>
              <Controller
                name="style"
                control={form.control}
                render={({ field: { onChange, onBlur, value } }) => {
                  const selectOptions = (options?.text ?? []).map(
                    mapOptionToSelect,
                  );
                  const selectValue = selectOptions.find(
                    (option) => option.value === value,
                  );

                  return (
                    <Select
                      placeholder={t('selectToneOfVoice')}
                      options={selectOptions}
                      onChange={(option) => onChange(option?.value ?? '')}
                      value={selectValue ?? null}
                      menuPortalTarget={document.body}
                      menuPlacement="auto"
                      styles={{
                        menuPortal: (styles) => ({
                          ...styles,
                          zIndex: 1010,
                        }),
                      }}
                      isDisabled={generateText.isLoading}
                      data-name="ai-content-style-select"
                    />
                  );
                }}
              />
              <Field.Error>{form.formState.errors.style?.message}</Field.Error>
            </Field.Group>
          </>
        </div>
      )}
      {(generateText.data || generateText.isLoading) && (
        <TextGeneratedResults
          suggestions={generateText.data ?? []}
          isLoading={generateText.isLoading}
          renderAction={renderResultAction}
          classNames={{
            root: 'tw-overflow-y-auto tw-flex-grow',
            loadingImg: 'tw-h-[8.5rem]',
            emptyImg: 'tw-h-[8.5rem]',
          }}
        />
      )}
      {!generateText.isLoading && (
        <TextEditorAiContentPopupGenerateFooter
          isLoading={generateText.isLoading}
          hasResults={!!generateText.data}
          onSubmit={submit}
        />
      )}
    </form>
  );
};

export default TextEditorAiContentPopupGenerateText;
