import { STATUS } from '@kontentino/kontentino-constants/Posts';
import { t } from 'i18next';

export const WorkflowStatusUtils = {
  statuses: Object.values(STATUS) as number[],
  getStatus(status: number) {
    if (!WorkflowStatusUtils.statuses.includes(status)) {
      throw new Error(`Status ${status} not found`);
    }

    return status;
  },
  getLabel(status: number) {
    return t(`workflowStatus.label.${WorkflowStatusUtils.getStatus(status)}`);
  },
  getShortLabel(status: number) {
    return t(
      `workflowStatus.shortLabel.${WorkflowStatusUtils.getStatus(status)}`,
    );
  },
};
