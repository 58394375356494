import { getUniquePrimitiveItemsFromArray } from 'utils/helpers';
import { PROFILE_SELECTOR_FILTER_OPTIONS_PROPERTIES } from '../constants';
import { ProfileSelectorPageTypes } from '../types/profileSelectorFilter';
import PageTypeUtils from 'app/utils/pageType';

const getPageTypeFilterOptions = (types: ProfileSelectorPageTypes[]) => {
  return PageTypeUtils.sort(
    getUniquePrimitiveItemsFromArray(types)
      .filter((type) => PROFILE_SELECTOR_FILTER_OPTIONS_PROPERTIES[type])
      .map((type) => ({
        key: type,
        ...PROFILE_SELECTOR_FILTER_OPTIONS_PROPERTIES[type],
      })),
  );
};
export default getPageTypeFilterOptions;
