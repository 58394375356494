import { useCallback, useRef } from 'react';
import { useEffectOnce } from 'utils/hooks/useEffectOnce';

export function useOutsideClick<T extends Element>(
  onOutsideClickCallback: AnyFunction,
  selectorsToSkip?: string[],
) {
  const elementRef = useRef<T>(null);

  useEffectOnce(() => {
    return () => {
      removeListener();
    };
  });

  function removeListener() {
    document.removeEventListener('mousedown', onClickOutside);
    document.removeEventListener('keydown', onEscClick);
  }

  function addListener() {
    document.addEventListener('mousedown', onClickOutside);
    document.addEventListener('keydown', onEscClick);
  }

  const onEscClick = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      onOutsideClickCallback();
      removeListener();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickOutside = useCallback(
    (click: MouseEvent) => {
      if (elementRef.current && click?.target instanceof Element) {
        const elementsToSkip: Element[] = [elementRef.current];

        selectorsToSkip?.forEach((selector) => {
          const element = document.querySelector(selector);

          if (element) {
            elementsToSkip.push(element);
          }
        });

        if (elementsToSkip.some((e) => e.contains(click.target as Node))) {
          return;
        }
      }
      onOutsideClickCallback();
      removeListener();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [elementRef],
  );

  return { elementRef, addListener, removeListener };
}
