import React, { lazy, Suspense } from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import routeConfig from 'app/config/routes';
import Skeleton from 'react-loading-skeleton';
import { ResetPasswordContextProvider } from 'app/modules/public/modules/forgotPassword/context';

const SubmitEmail = lazy(() => import('./modules/submitEmail'));
const SubmitCode = lazy(() => import('./modules/submitCode'));
const SetPassword = lazy(() => import('./modules/setPassword'));

export const resetPasswordRouter: RouteObject = {
  path: routeConfig.public.routes.forgotPassword.path,
  element: (
    <ResetPasswordContextProvider>
      <Suspense fallback={<Skeleton width="100%" height="100%" />}>
        <Outlet />
      </Suspense>
    </ResetPasswordContextProvider>
  ),
  children: [
    {
      index: true,
      element: (
        <Navigate
          to={routeConfig.public.routes.forgotPassword.routes.submitEmail.path}
        />
      ),
    },
    {
      path: routeConfig.public.routes.forgotPassword.routes.submitEmail.path,
      element: <SubmitEmail />,
    },
    {
      path: routeConfig.public.routes.forgotPassword.routes.submitCode.path,
      element: <SubmitCode />,
    },
    {
      path: routeConfig.public.routes.forgotPassword.routes.setPassword.path,
      element: <SetPassword />,
    },
  ],
};
