import { useMemo } from 'react';

import resolveConfig from 'tailwindcss/resolveConfig';
// @ts-expect-error
import tailwindConfig from '@kontentino/ui/dist/tailwind.config';

export function useTheme() {
  const theme = useMemo(() => resolveConfig(tailwindConfig).theme, []);

  return theme;
}
