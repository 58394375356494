import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ModuleStatus } from 'types/ModuleStatus';
import { MODULE_STATUS } from 'constants/modules';
import {
  AuthorizationDoneResponse,
  AuthorizationErrorResponse,
} from 'types/SocialLogin';
import {
  FACEBOOK_NEW_PROFILE_SCOPES,
  INSTAGRAM_NEW_PROFILE_SCOPES,
  SocialLoginError,
  SocialLoginMessageTypes,
  SocialLoginUrls,
  THREADS_NEW_PROFILE_SCOPES,
} from 'constants/socialLogin';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import Logger from 'utils/logger';
import { createSearchFromParams, openPopup } from 'utils/url';
import AppConfig from 'app/config/app';

type SocialLoginContextValue = {
  status: ModuleStatus;
  authorization: AuthorizationDoneResponse & {
    authorizationOptions: {
      pageType: number;
      scopes: string[];
    };
  };
  error: AuthorizationErrorResponse | undefined;
  isLoading: boolean;
  isError: boolean;
  authorize: (options: {
    pageId?: number;
    pageType: number;
    scopes?: string[];
  }) => void;
  reset: () => void;
};

const SocialLoginContext = createContext<SocialLoginContextValue | undefined>(
  undefined,
);

const allowedMessages: string[] = Object.values(SocialLoginMessageTypes);

function getDefaultScopes(pageType: number) {
  if (pageType === TYPE.FACEBOOK) {
    return FACEBOOK_NEW_PROFILE_SCOPES;
  }

  if (pageType === TYPE.INSTAGRAM) {
    return INSTAGRAM_NEW_PROFILE_SCOPES;
  }

  if (pageType === TYPE.THREADS) {
    return THREADS_NEW_PROFILE_SCOPES;
  }

  return [];
}

const SocialLoginContextProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [status, setStatus] = useState<ModuleStatus>(MODULE_STATUS.Idle);
  const [authorization, setAuthorization] = useState<any>();
  const [error, setError] = useState<AuthorizationErrorResponse | undefined>();
  const popup = useRef<Window | null>(null);
  const broadcastChannel = useRef(
    new BroadcastChannel(AppConfig.SOCIAL_LOGIN_BROADCAST_CHANNEL_NAME),
  );
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);
  const authorizationOptions = useRef<{
    pageType: number | null;
    scopes: string[];
  }>({
    pageType: null,
    scopes: [],
  });

  useEffect(() => {
    const eventMessageListener = (
      event: MessageEvent<{
        data: { reason: string };
        type: string;
      }>,
    ) => {
      const { data } = event;

      try {
        if (
          !allowedMessages.includes(data.type) ||
          status !== MODULE_STATUS.Loading
        ) {
          return;
        }

        const authorizationStatus: string | undefined =
          data.type.match(/(done|error)/)?.[0];

        if (authorizationStatus !== 'done') {
          throw new Error('Social login failed');
        }

        const authorizationSucceededData = {
          ...data.data,
          authorizationOptions: authorizationOptions.current,
        };

        setAuthorization(authorizationSucceededData);
        setStatus(MODULE_STATUS.Succeeded);
        popup.current?.close();
      } catch (e) {
        const error = {
          reason:
            (data.data as { reason?: string })?.reason ||
            (e instanceof Error && e.message) ||
            SocialLoginError.unableToAuthorize,
          authorizationOptions: authorizationOptions.current,
        };

        Logger.error(error);
        setError(error);
        setStatus(MODULE_STATUS.Failed);
      }
    };

    broadcastChannel.current.onmessage = eventMessageListener;

    const popupClosedListener = () => {
      const isWatchDisabled =
        authorizationOptions.current.pageType &&
        (
          [
            TYPE.THREADS,
            TYPE.TWITTER,
            TYPE.GOOGLE_MY_BUSINESS,
            TYPE.PINTEREST,
          ] as number[]
        ).includes(authorizationOptions.current.pageType);

      if (isWatchDisabled) return;

      const isClosedWhileLoading =
        (popup.current?.closed || popup.current === null) &&
        status === MODULE_STATUS.Loading;

      if (isClosedWhileLoading) {
        const error = {
          reason: SocialLoginError.socialLoginClosed,
          authorizationOptions: authorizationOptions.current,
        };
        if (interval.current) {
          clearInterval(interval.current);
        }
        Logger.error(SocialLoginError.socialLoginClosed);
        setError(error);
        setStatus(MODULE_STATUS.Failed);
      }
    };

    if (status === MODULE_STATUS.Loading) {
      interval.current = setInterval(popupClosedListener, 1000);
    }

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [status]);

  useEffect(() => {
    return () => {
      popup.current?.close();
      // eslint-disable-next-line react-hooks/exhaustive-deps
      broadcastChannel.current.close();
    };
  }, [popup, broadcastChannel]);

  function authorize(options: {
    pageType: number;
    pageId?: number;
    scopes?: string[];
  }) {
    try {
      const scopes = options.scopes
        ? options.scopes
        : getDefaultScopes(options.pageType);

      authorizationOptions.current.pageType = options?.pageType;
      authorizationOptions.current.scopes = scopes;

      setStatus(MODULE_STATUS.Loading);
      setAuthorization(undefined);
      setError(undefined);

      const pageId = options.pageType === TYPE.TWITTER ? options.pageId : null;

      const params = pageId
        ? createSearchFromParams({
            pageId,
          })
        : '';

      popup.current = openPopup(
        `${SocialLoginUrls[authorizationOptions.current.pageType]?.(
          scopes.join(),
        )}${params}`,
      );
    } catch (e) {
      const error = {
        reason:
          (e instanceof Error && e.message) ||
          SocialLoginError.unableToAuthorize,
        authorizationOptions: authorizationOptions.current,
      };

      Logger.error(error);
      setError(error);
      setStatus(MODULE_STATUS.Failed);
    }
  }

  const reset = () => {
    setStatus(MODULE_STATUS.Idle);
    if (interval.current) {
      clearInterval(interval.current);
    }
  };

  return (
    <SocialLoginContext.Provider
      value={{
        status,
        authorization,
        error,
        isLoading: status === MODULE_STATUS.Loading,
        isError: status === MODULE_STATUS.Failed,
        authorize,
        reset,
      }}
    >
      {children}
    </SocialLoginContext.Provider>
  );
};

export default SocialLoginContextProvider;

export const useSocialLoginContext = () => {
  const context = useContext(SocialLoginContext);

  if (!context) {
    throw new Error(
      'useSocialLoginContext must be used within a SocialLoginContextProvider',
    );
  }

  return context;
};
