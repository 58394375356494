import React, { FC } from 'react';
import clsx from 'clsx';

type Props = {
  shadow?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const LayoutTopBar: FC<Props> = ({ shadow, className, children, ...props }) => {
  return (
    <div
      className={clsx(
        'tw-relative tw-flex tw-h-[56px] tw-items-center tw-gap-3 tw-bg-white tw-px-4',
        {
          'tw-shadow-sm': shadow,
        },
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default LayoutTopBar;
