import { TYPE } from '@kontentino/kontentino-constants/Pages';
import SelectNewProfileType from 'components/modals/newSocialMediaProfile/SelectNewProfileType';
import Modal from 'components/shared/Modal';
import React, { FC } from 'react';
import useInsightsSocialLogin from 'app/modules/insights/hooks/useInsightsSocialLogin';
import { ModalRouteProps } from 'context/ModalRouter';
import AnalyticsApi from 'api/analytics';
import { ActivityIndicator } from '@kontentino/ui';

type Props = ModalRouteProps & {};

const availableTypes = [TYPE.FACEBOOK, TYPE.INSTAGRAM, TYPE.LINKEDIN];

export const InsightsAddNewProfileModal: FC<Props> = ({ modalManager }) => {
  const { authorize, isLoading } = useInsightsSocialLogin({
    async onBeforeSuccess() {
      await AnalyticsApi.loadPages();
    },
    onSuccess() {
      modalManager.close();
    },
  });

  return (
    <Modal
      modalManager={modalManager}
      size="responsive"
      dataName={{
        wrapper: 'insights_add-profile-modal',
        closeButton: 'insights_add-profile-modal_close-icon',
      }}
      classNames={{ modal: '!tw-max-w-[440px]' }}
    >
      <Modal.Header title="Add a profile" />
      <Modal.Content className="tw-mb-6">
        {!isLoading && (
          <SelectNewProfileType
            socialMediaTypes={availableTypes}
            onSelectSocialMediaType={authorize}
            dataNamePrefix={{
              socialTypeOption: 'insights_add-profile-modal_',
            }}
          />
        )}
        {isLoading && (
          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-6">
            <ActivityIndicator className="tw-h-12 tw-w-12" />
            <p className="tw-mt-6 tw-text-md">Authorizing...</p>
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
};
