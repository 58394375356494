import React, { FC } from 'react';
import Modal from 'components/shared/Modal';
import { useQuery } from 'react-query';
import { queryKey } from 'constants/queryKey';
import { LinkShortenerApi } from 'api/linkShortener';
import ArrayUtils from 'app/utils/array';
import { formatDateTimeUTCToLocalTimezone } from 'app/utils/date';
import { Button, Field } from '@kontentino/ui';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

type Props = {
  onClose: AnyFunction;
  linkId?: string;
};

const skeletons = ArrayUtils.generate(4);

const StatisticsModal: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { data, isLoading } = useQuery(
    [queryKey.shortenLinkDetail, props.linkId],
    () => LinkShortenerApi.detail(props.linkId as string),
    { enabled: !!props.linkId },
  );

  return (
    <Modal
      id="shorten-link-detail-modal"
      onClose={props.onClose}
      open={!!props.linkId}
      classNames={{
        root: '!tw-z-[1002]',
      }}
    >
      <Modal.Header title="Link statistics" />
      <Modal.Content>
        {data && (
          <div className="tw-flex tw-flex-col tw-gap-4 tw-break-all">
            <Field.Group>
              <Field.Label required>Shortened URL</Field.Label>
              <span>{data.shortUrl}</span>
            </Field.Group>
            <Field.Group>
              <Field.Label required>URL</Field.Label>
              <span>{data.url}</span>
            </Field.Group>
            <Field.Group>
              <Field.Label required>Created</Field.Label>
              <span>{formatDateTimeUTCToLocalTimezone(data.createdAt)}</span>
            </Field.Group>
            <Field.Group>
              <Field.Label required>Updated</Field.Label>
              <span>{formatDateTimeUTCToLocalTimezone(data.updatedAt)}</span>
            </Field.Group>
            <Field.Group>
              <Field.Label required>No. of clicks</Field.Label>
              <span>{data.visits.totalCount}</span>
            </Field.Group>
          </div>
        )}
        {isLoading &&
          skeletons.map((i) => (
            <div className="tw-mt-2" key={i}>
              <Skeleton height={40} />
            </div>
          ))}
      </Modal.Content>
      <Modal.Footer>
        <Button onClick={props.onClose} variant="secondary">
          {t('close')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StatisticsModal;
