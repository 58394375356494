import env, { Module } from 'config';
import { get, post } from 'api/client';
import type {
  AdAccounts,
  AdAccountTypeLabel,
  BaseDataApiResponse,
  FacebookAvailableAdAccount,
} from 'types/AdAccount';
import client from 'components/adsPlanner/graphqlClient';
import { gql } from 'graphql-request';
import { UpdateAllStatusesResponseData } from 'api/adsplanner';
import { DEFAULT_TIMEOUT } from 'constants/request';

export const adAccount = {
  baseData: (): Promise<BaseDataApiResponse> => {
    return get('ads-planner/base-data', {
      baseURL: env.API_SERVICE_ENDPOINT,
    });
  },
  all: (
    type: AdAccountTypeLabel = 'facebook',
    limit: number = Module.AdsPlanner.DEFAULT_AD_ACCOUNTS_REQUEST_LIMIT,
  ): Promise<Omit<ApiResponse, 'data'> & { data: AdAccounts }> => {
    return get('ads-planner/ad-account', {
      baseURL: env.API_SERVICE_ENDPOINT,
      params: {
        type,
        limit,
      },
    });
  },
  facebookAvailable: (
    accessToken: string,
  ): Promise<{
    availableAdAccounts: FacebookAvailableAdAccount[];
  }> => {
    const adsPlannerGraphqlClient = client();

    return adsPlannerGraphqlClient.request(gql`query {
      availableAdAccounts(access_token: "${accessToken}") {
        id
        name
      }
    }`);
  },
  create: (params: {
    adAccountId: string;
    accessToken: string;
  }): Promise<{
    createAdAccount: FacebookAvailableAdAccount;
  }> => {
    const adsPlannerGraphqlClient = client();

    return adsPlannerGraphqlClient.request(gql`mutation {
      createAdAccount (
        adaccount_id: ${params.adAccountId},
        access_token: "${params.accessToken}",
      ){
        id
        name
        internalId
      }
    }`);
  },
  associate: (
    adAccount: {
      id: number;
      externalId: string;
    },
    accessToken: string,
  ): Promise<{
    associateAdAccount: Omit<FacebookAvailableAdAccount, 'internalId'>;
  }> => {
    const adsPlannerGraphqlClient = client();

    return adsPlannerGraphqlClient.request(gql`mutation {
      associateAdAccount (
        adaccount_id: "${adAccount.externalId}",
        page_id: ${adAccount.id},
        access_token: "${accessToken}",
      ) {
        id
        name
      }
    }`);
  },
  updateAllStatuses: (
    adAccountId: number,
  ): Promise<UpdateAllStatusesResponseData> => {
    return post('ads-planner/ad-account/update-all-statuses', null, {
      baseURL: env.API_SERVICE_ENDPOINT,
      params: {
        adAccountId,
      },
      timeout: DEFAULT_TIMEOUT * 3,
    });
  },
};
