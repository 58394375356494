import { StringParam, useQueryParam } from 'use-query-params';

export enum PostsAddNewProfileReason {
  'noProfile' = 'noProfile',
  'demo' = 'demo',
}

export function useAddNewProfileReasonMessages(props: {
  isInFirstStep: boolean;
}) {
  const [reason] = useQueryParam('reason', StringParam);

  function getReasonWording(
    reason: `${PostsAddNewProfileReason}` | string | null | undefined,
  ) {
    switch (props.isInFirstStep ? reason : undefined) {
      case 'noProfile':
        return {
          title: `Let’s connect/select your social media profiles first!`,
          description: `You must add/select at least one social media profile to continue with this action.`,
        };
      case 'demo':
        return {
          title: `Yay, you're ready to go on your own!`,
          description: `You can't schedule demo posts - add your first social media profile
            and start creating now.`,
        };
      default:
        return {
          title: props.isInFirstStep ? 'Add profile' : 'Select profile',
          description: undefined,
        };
    }
  }

  return getReasonWording(reason);
}
