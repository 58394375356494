import Logger from 'utils/logger';
import config from 'config';

export const isResponseLoggerOn = (): boolean => {
  return config.RESPONSE_LOGGER_ON === 'true';
};

export const isRequestLoggerOn = (): boolean => {
  return config.REQUEST_LOGGER_ON === 'true';
};

export const logRequest = (...args: any): void => {
  if (isRequestLoggerOn()) {
    Logger.log(...args);
  }
};

export const logResponse = (...args: any): void => {
  if (isResponseLoggerOn()) {
    Logger.log(...args);
  }
};
