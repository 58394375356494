import { openBlank } from 'utils/url';
import SocialNetworkUrlUtils from 'app/utils/socialNetworkUrl';
import { TYPE_FACEBOOK } from '@kontentino/kontentino-constants/Pages';

export const openFacebookPost = (facebookId: string | number) => {
  openBlank(
    SocialNetworkUrlUtils.postDetail({
      pageType: TYPE_FACEBOOK,
      postId: facebookId,
    }),
  );
};
