import { DropdownMenu, Icon, IconButton } from '@kontentino/ui';
import {
  faArrowDownRight,
  faArrowUpRight,
  faBarsSort,
} from '@fortawesome/pro-regular-svg-icons';
import { OrderDirection } from 'app/types';
import { FC } from 'react';

const SORT_POSTS_OPTIONS = [
  {
    key: OrderDirection.Desc,
    title: (
      <>
        <Icon icon={faArrowDownRight} />
        Newest to oldest
      </>
    ),
  },
  {
    key: OrderDirection.Asc,
    title: (
      <>
        <Icon icon={faArrowUpRight} />
        Oldest to newest
      </>
    ),
  },
];

type Props = {
  onSortOptionSelect: (option: OrderDirection) => void;
};

const AdsPlannerAdditionalActionsDropdown: FC<Props> = ({
  onSortOptionSelect,
}) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <IconButton
          className="tw-h-[36px]"
          variant="secondary"
          icon={<Icon icon={faBarsSort} />}
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className="tw-z-10">
        {SORT_POSTS_OPTIONS.map((sortOption) => (
          <DropdownMenu.Item
            key={sortOption.key}
            onClick={() => onSortOptionSelect(sortOption.key)}
          >
            {sortOption.title}
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default AdsPlannerAdditionalActionsDropdown;
