import React, { FC } from 'react';
import { Button } from '@kontentino/ui';

type Props = {
  onCancel: () => void;
  onSubmit: () => void;
  dataName?: {
    cancelButton?: string;
    submitButton?: string;
  };
};

const ControlsWrapperFooter: FC<Props> = ({ onCancel, onSubmit, dataName }) => (
  <div className="tw-flex tw-items-center tw-justify-between tw-border-t tw-border-solid tw-border-grayscale-5 tw-p-2">
    <Button
      variant="secondary"
      onClick={onCancel}
      data-name={dataName?.cancelButton}
      data-cy="date-picker-cancel-button"
    >
      Cancel
    </Button>
    <Button
      onClick={onSubmit}
      data-name={dataName?.submitButton}
      data-cy="date-picker-submit-button"
    >
      Submit
    </Button>
  </div>
);

export default ControlsWrapperFooter;
