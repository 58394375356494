import React, { FC } from 'react';
import { Alert, Badge } from '@kontentino/ui';
import Skeleton from 'react-loading-skeleton';
import { UseQueryResult } from 'react-query/types/react';
import { AIContentOptions } from 'app/modules/posts/api/aiContent';

type Props = {
  options: UseQueryResult<AIContentOptions, unknown>;
};

const AiContentRemainingAttemptsBadge: FC<Props> = ({ options }) => {
  if (options.isError) {
    return <Alert variant="danger">Unable to load remaining attempts</Alert>;
  }

  if (options.isLoading) {
    return (
      <div className="tw-leading-6">
        <Skeleton width="135px" />
      </div>
    );
  }

  return (
    <Badge variant="purple" size="medium">
      Available uses&nbsp;
      {options?.data?.remainingAttempts.all}/
      {options?.data?.availableAttempts.all}
    </Badge>
  );
};

export default AiContentRemainingAttemptsBadge;
