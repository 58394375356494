import React, { Suspense, lazy } from 'react';
import { RouteObject } from 'react-router-dom';
import routeConfig from 'app/config/routes';
import { Outlet } from 'react-router';
import Skeleton from 'react-loading-skeleton';
import Container from 'components/posts/Container';

const Dashboard = lazy(() => import('../pages/Dashboard'));

export const dashboardRouter: RouteObject = {
  path: routeConfig.dashboard.path,
  element: (
    <>
      <Container>
        {() => (
          <Suspense
            fallback={
              <div className="tw-h-full tw-p-4">
                <Skeleton width="100%" height="100%" />
              </div>
            }
          >
            <Outlet />
          </Suspense>
        )}
      </Container>
    </>
  ),
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={<Skeleton width="100%" height="100%" />}>
          <Dashboard />
        </Suspense>
      ),
    },
  ],
};
