import { UserPermission } from 'constants/userPermission';
import { PostCommunicationContent } from 'app/modules/posts/modules/post/components/postCommunication/types/postCommunicationContent';
import { useEffect, useMemo } from 'react';
import { useUserPermissions } from 'app/hooks/useUserPermissions';
import { useQueryParamState } from 'app/hooks/useQueryParamState';
import PostsConfig from 'app/config/posts';

export function useActiveContent() {
  const { hasSome } = useUserPermissions();

  const allowedActiveContent = useMemo(() => {
    const allowedActiveContent: PostCommunicationContent[] = [
      PostCommunicationContent.PINNED,
      PostCommunicationContent.VERSIONS,
    ];

    if (hasSome(UserPermission.POST_COMMUNICATION_CLIENT_SHOW)) {
      allowedActiveContent.unshift(PostCommunicationContent.CLIENT);
    }

    if (hasSome(UserPermission.POST_COMMUNICATION_INTERNAL_SHOW)) {
      allowedActiveContent.unshift(
        PostCommunicationContent.INTERNAL,
        PostCommunicationContent.ACTIVITY,
      );
    }

    return allowedActiveContent;
  }, [hasSome]);

  const [activeContent, setActiveContent] = useQueryParamState(
    PostsConfig.COMMUNICATION_TAB_QUERY_PARAM_KEY,
    {
      initialValue: allowedActiveContent[0],
      allowedValues: allowedActiveContent,
    },
  );

  useEffect(() => {
    if (!allowedActiveContent.includes(activeContent)) {
      const firstAllowed: PostCommunicationContent | undefined =
        allowedActiveContent[0];

      if (firstAllowed) {
        setActiveContent(firstAllowed);
      }
    }
  }, [activeContent, setActiveContent, allowedActiveContent]);

  return {
    allowedActiveContent,
    activeContent,
    setActiveContent,
  };
}
