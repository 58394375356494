import { FC } from 'react';
import PageStatisticsSkeleton from 'app/modules/insights/modules/postsPerformance/components/pageStatistics/PageStatisticsSkeleton';
import { usePageStatisticsQuery } from 'app/modules/insights/modules/postsPerformance/hooks/usePageStatisticsQuery';
import SimpleGrid from 'app/components/SimpleGrid';
import StatisticCard from 'app/modules/insights/modules/postsPerformance/components/pageStatistics/StatisticCard';
import LinearToggleDivider from 'app/modules/insights/modules/postsPerformance/components/pageStatistics/LinearToggleDivider';
import useBoolean from 'utils/hooks/useBoolean';
import { getPageStatisticsCards } from 'app/modules/insights/modules/postsPerformance/utils/getPageStatisticsCards';
import Insights from 'config/insights';
import { Page } from 'app/modules/insights/types/Page';
import { PageStatisticsData } from 'app/modules/insights/types/PageStatistics';
import { MetricProperty } from 'app/modules/insights/types/MetricProperty';
import { Dayjs } from 'dayjs';
import { Alert } from '@kontentino/ui';

type Props = {
  page: Page;
  startDate: Dayjs;
  endDate: Dayjs;
  selectedMetricKey?: string;
  onSelectClick?(metric: MetricProperty): void;
  onDeselectCLick?(): void;
  isExpandingDisabled?: boolean;
  columns?: number;
  onSuccess?(metrics: PageStatisticsData): void;
};

const COLLAPSED_CARDS_COUNT = Insights.PAGE_STATISTICS_COLLAPSED_CARDS_COUNT;

const PageStatistics: FC<Props> = ({ columns = 4, ...props }) => {
  const { error, data, loading } = usePageStatisticsQuery(props);
  const [isExpanded, setIsExpanded] = useBoolean(
    props.isExpandingDisabled ?? false,
  );

  return (
    <div data-cy="insights-posts-performance-statistics">
      {loading && <PageStatisticsSkeleton />}
      {error && (
        <Alert
          title="Unable to fetch statistics"
          variant="danger"
          className="tw-mb-3"
        />
      )}
      {data &&
        !loading &&
        ((cards: ReturnType<typeof getPageStatisticsCards>) => (
          <div className="tw-mb-4">
            <SimpleGrid gap={16} columns={columns}>
              {cards.visible.map((card) => (
                <StatisticCard
                  key={card.key}
                  name={card.key}
                  value={card.value}
                  title={card.title}
                  color={card.color}
                  icon={card.icon}
                  info={card.info}
                  onClick={() => {
                    if (card.key === props.selectedMetricKey) {
                      props.onDeselectCLick?.();
                    } else {
                      props.onSelectClick?.(card);
                      setIsExpanded.off();
                    }
                  }}
                  isHighlighted={props.selectedMetricKey === card.key}
                  disabled={card.value === null}
                />
              ))}
            </SimpleGrid>
            {!props.isExpandingDisabled &&
              cards.available.length > COLLAPSED_CARDS_COUNT && (
                <LinearToggleDivider
                  onClick={setIsExpanded.toggle}
                  isExpanded={isExpanded}
                />
              )}
          </div>
        ))(
          getPageStatisticsCards(data, {
            maxLength: isExpanded ? data.metrics.length : COLLAPSED_CARDS_COUNT,
            highlightedCardKey: props.selectedMetricKey,
          }),
        )}
    </div>
  );
};

export default PageStatistics;
