import { useMutation, useQuery, useQueryClient } from 'react-query';
import { queryKey } from 'constants/queryKey';
import { HashtagPresetsApi } from 'api/hashtagPresets';
import { useToast } from 'app/hooks/useToast';
import i18n from 'i18n';
import { copyTextToClipboard } from '@kontentino/kontentino-utils/clipboard';
import Popup from 'utils/popup';
import { HashtagPreset } from 'types/HashtagPresets';
import { useState } from 'react';

type Props = {
  pageId: number;
};

export function useHashtagsPresetsContainer(props: Props) {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { isLoading, data } = useQuery(
    [queryKey.hashtagPresets, props.pageId],
    () => HashtagPresetsApi.getAll(props.pageId),
  );
  const deletePreset = useMutation(HashtagPresetsApi.delete, {
    onSuccess() {
      queryClient.invalidateQueries([queryKey.hashtagPresets, props.pageId]);
      toast('Hashtag preset successfully removed.');
    },
  });
  const [filter, setFilter] = useState('');
  const [hashtagToEdit, setHashtagToEdit] = useState<
    null | undefined | HashtagPreset
  >();

  async function copyToClipboard(hashtags: string) {
    try {
      await copyTextToClipboard(hashtags);
      toast(i18n.copiedToClipboard);
    } catch (e) {
      toast(i18n.somethingWentWrong, 'error');
    }
  }

  async function remove(presetId: number) {
    const { isConfirmed } = await Popup.confirm();

    if (isConfirmed) {
      deletePreset.mutate(presetId);
    }
  }

  return {
    isLoading,
    hashtagPresets: data,
    copyToClipboard,
    remove,
    hashtagToEdit,
    setHashtagToEdit,
    filter,
    setFilter,
  };
}
