import { FC } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';
import { QUERY_KEY } from 'components/adsPlanner/constants';
import { AdAccounts } from 'types/AdAccount';
import AdsPlannerApi from 'api/adsplanner';
import { ApiClientError } from 'api/client';

export const useAdAccountsRequest = (
  options?: UseQueryOptions<
    Omit<ApiResponse, 'data'> & { data: AdAccounts },
    ApiClientError,
    Omit<ApiResponse, 'data'> & { data: AdAccounts },
    string
  >,
) => {
  return useQuery(QUERY_KEY.AD_ACCOUNTS, () => AdsPlannerApi.adAccount.all(), {
    refetchOnWindowFocus: false,
    ...options,
  });
};

type Props = {
  children(params: {
    data: AdAccounts;
    isLoading: boolean;
    error: string;
  }): JSX.Element;
};

export const RequestAdAccounts: FC<Props> = ({ children }) => {
  const { data, isLoading, error } = useAdAccountsRequest();

  return children({
    data: data?.data ?? [],
    isLoading,
    // @ts-ignore
    error: error?.userMessage ?? '',
  });
};

export default RequestAdAccounts;
