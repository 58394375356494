import { mergeTailwindClasses } from '@kontentino/ui';
import clsx from 'clsx';
import {
  FC,
  MouseEventHandler,
  ReactNode,
  createContext,
  forwardRef,
  useContext,
} from 'react';

type ContextType = {
  orderIndex?: number;
  isSelected?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  isPending?: boolean;
};

const MediaPreviewContext = createContext<ContextType>({
  orderIndex: undefined,
  isSelected: undefined,
  isLoading: undefined,
  isError: undefined,
  isPending: undefined,
});

export function useMediaPreview() {
  const context = useContext(MediaPreviewContext);
  if (!context) {
    throw new Error(
      'useMediaPreview returned is "undefined". Seems you forgot to wrap the components in "<MediaPreview />"',
    );
  }
  return context;
}

type MediaPreviewRootProps = {
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  className?: string;
} & ContextType;

export const MediaPreviewRoot: FC<MediaPreviewRootProps> = forwardRef<
  HTMLDivElement,
  MediaPreviewRootProps
>(
  (
    {
      orderIndex,
      isSelected,
      isLoading,
      isError,
      isPending,
      children,
      onClick,
      className,
    },
    ref,
  ) => {
    return (
      <MediaPreviewContext.Provider
        value={{ orderIndex, isSelected, isLoading, isError, isPending }}
      >
        <div
          data-name="media-preview"
          ref={ref}
          className={mergeTailwindClasses(
            clsx(
              'tw-group tw-relative tw-h-[5.75rem] tw-w-[5.75rem] tw-overflow-hidden tw-rounded-md tw-bg-grayscale-180/25 ',
              { 'tw-cursor-pointer': onClick },
              className,
            ),
          )}
          onClick={onClick}
        >
          {children}
        </div>
      </MediaPreviewContext.Provider>
    );
  },
);
