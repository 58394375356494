import { FC } from 'react';
import { useInsightsContainer } from 'components/insights/context';
import { PageNumberType } from '@kontentino/kontentino-constants/Pages';
import { useLocation } from 'react-router';
import routes from 'constants/routes';
import AnalyticsExportsApi from 'api/analytics/exports';
import { EntityTypes } from 'constants/analytics';
import { Module } from 'config';
import { getInsightsPageTypeAlias } from 'app/modules/insights/utils/getInsightsPageTypeAlias';
import DateUtils from 'app/utils/date';

type ExportsState = {
  onExport: (type: 'csv' | 'pdf') => void;
};

type ExportQueryParams = {
  socialMediaType: string;
  exportEntityType: string;
  id: string;
};

type Props = {
  children: (props: ExportsState) => JSX.Element;
  pageType: PageNumberType;
};

const Container: FC<Props> = ({ children, pageType }) => {
  const location = useLocation();
  const { filterState, activeState } = useInsightsContainer();

  const getUrlParamEntityType = () => {
    if (location.pathname.includes(routes.INSIGHTS_PAGE_PERFORMANCE)) {
      return EntityTypes.Pages;
    }

    if (location.pathname.includes(routes.INSIGHTS_POSTS_PERFORMANCE)) {
      return EntityTypes.Posts;
    }

    return EntityTypes.CompetitionAnalysis;
  };

  const entityType = getUrlParamEntityType();

  const queryParams: ExportQueryParams = {
    socialMediaType: getInsightsPageTypeAlias(pageType),
    exportEntityType: entityType,
    id: activeState.page?.id ?? '',
  };

  const handleExport = async (type: 'csv' | 'pdf') => {
    if (type === 'csv') {
      AnalyticsExportsApi.exportCSV(
        queryParams.socialMediaType,
        queryParams.exportEntityType,
        DateUtils.toDateString(filterState.startDate),
        DateUtils.toDateString(filterState.endDate),
        queryParams.id,
      );
    }

    if (type === 'pdf') {
      AnalyticsExportsApi.exportPDF({
        entityType: entityType,
        id: queryParams.id,
        dateFrom: DateUtils.toDateString(filterState.startDate),
        dateTo: DateUtils.toDateString(filterState.endDate),
        pageType,
        order: filterState.orderDirection.toUpperCase(),
        postTypes: filterState.selectedPostTypes,
        metric: activeState.metric.key ?? '',
        comparedStartDate:
          filterState.comparedStartDate &&
          DateUtils.toDateString(filterState.comparedStartDate),
        comparedEndDate:
          filterState.comparedEndDate &&
          DateUtils.toDateString(filterState.comparedEndDate),
        areMetricsRestricted:
          !!activeState.page?.fans &&
          activeState.page.fans <
            Module.Insights.FANS_LIMIT_FOR_METRICS_RESTRICTION,
      });
    }
  };

  return children({
    onExport: handleExport,
  });
};

export default Container;
