import {
  PLAYBACK_TIME_METRIC_VALUE_KEYS,
  PERCENTAGE_METRIC_VALUE_KEYS,
} from '../constants/metricValue';
import TimeUtils from 'app/utils/time';
import NumberUtil from 'utils/number';
import { Locales } from 'app/config/locales';

export function formatMetricValue(name: string, value: string | number | null) {
  if (typeof value === 'string') return value;
  value = value ?? 0;

  if (PLAYBACK_TIME_METRIC_VALUE_KEYS.includes(name)) {
    return TimeUtils.formatMilliSeconds(value);
  }

  if (PERCENTAGE_METRIC_VALUE_KEYS.includes(name)) {
    const roundedNumberValue = NumberUtil.format(value);
    return `${roundedNumberValue}%`;
  }

  return NumberUtil.format(value, {
    notation: 'compact',
    compactDisplay: 'short',
    locales: Locales.EN_US,
    maximumFractionDigits: 1,
  });
}
