import { mergeTailwindClasses } from '@kontentino/ui';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  isActiveMenu?: boolean;
  className?: string;
};

export const VerticalMenuItemWrapper: FC<Props> = ({
  children,
  isActiveMenu,
  className,
}) => (
  <div
    className={mergeTailwindClasses(
      clsx(
        "before:content-'' tw-group tw-relative tw-flex tw-h-8 tw-items-center tw-justify-center before:tw-invisible before:tw-absolute before:tw-right-0 before:tw-block before:tw-h-full before:tw-w-[2px] before:tw-bg-primary-100 before:tw-opacity-0 ",
        {
          'tw-text-primary-100 before:tw-visible before:tw-opacity-100':
            isActiveMenu,
        },
        className,
      ),
    )}
  >
    {children}
  </div>
);
