import type { StoreBaseData } from 'types/Ad';

export type AdAccountType = 101;
export type AdAccountTypeLabel = 'facebook';

export type BaseDataApiResponse = StoreBaseData;

export type AdAccount = {
  id: number;
  name: string;
  type: AdAccountType;
  currency: string | null;
  externalId: string | null;
  connectionStatus: AdAccountConnectionTokenStatus;
};

export interface FacebookAvailableAdAccount {
  id: string;
  name: string;
  internalId: number;
}

export interface AnalyticsAdAccount {
  facebookAdAccountId: string;
  name: string;
  currency: string;
}

export type AdAccounts = AdAccount[];

export enum AdAccountConnectionTokenStatus {
  NotExist = 1,
  Ok = 2,
  Expired = 3,
  Invalid = 4,
}
