import { FC } from 'react';
import ModalBodyWithSidebarLayout from 'components/shared/workflowOptions/ModalBodyWithSidebarLayout';
import Form from 'app/components/timeSavers/assignMultipleTasksModal/assignForm/Form';

type Props = {
  pagesIds: number[];
  taskMessage: string;
  isUrgent: boolean;
  canSendEmail: boolean;
  onSelectedUsersChange: (users: number[]) => void;
  initialSelectedUsersIds: number[];
  onTaskMessageChange: (value: string) => void;
  onSelectUrgent: AnyFunction;
  onSelectEmailNotification: AnyFunction;
  formErrors: Record<string, string>;
};

const AssignForm: FC<Props> = ({
  pagesIds,
  taskMessage,
  isUrgent,
  canSendEmail,
  onSelectedUsersChange,
  initialSelectedUsersIds,
  onTaskMessageChange,
  onSelectUrgent,
  onSelectEmailNotification,
  formErrors,
}) => {
  return (
    <ModalBodyWithSidebarLayout
      mainContent={
        <Form
          pagesIds={pagesIds}
          taskMessage={taskMessage}
          isUrgent={isUrgent}
          canSendEmail={canSendEmail}
          onTaskMessageChange={onTaskMessageChange}
          onSelectUrgent={onSelectUrgent}
          onSelectEmailNotification={onSelectEmailNotification}
          onSelectedUsersChange={onSelectedUsersChange}
          initialSelectedUsersIds={initialSelectedUsersIds}
          formErrors={formErrors}
        />
      }
    />
  );
};

export default AssignForm;
