import InAppNotificationsApi from 'app/api/inAppNotifications';
import { IN_APP_NOTIFICATIONS_QUERY } from 'components/in-app-notifications/InAppNotifications.constants';
import { useQuery } from 'react-query';

const useInAppNotificationsCountQuery = () => {
  return useQuery(
    IN_APP_NOTIFICATIONS_QUERY.NOTICIATIONS_COUNT,
    InAppNotificationsApi.getUnseenCount,
  );
};

export default useInAppNotificationsCountQuery;
