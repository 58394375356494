import NumberUtil from 'utils/number';
import { pluralizeWithoutCount } from 'utils/lang';
import { TimeLabels } from 'app/types/Time';
import DateTimeConfig from 'app/config/dateTime';

// https://github.com/HenrikJoreteg/milliseconds/blob/master/milliseconds.js
function createCalcMilliseconds(reverse: boolean = false) {
  function calc(coefficient: number) {
    return (number: number) =>
      reverse ? number / coefficient : number * coefficient;
  }

  return {
    [TimeLabels.Seconds]: calc(1e3),
    [TimeLabels.Minutes]: calc(6e4),
    [TimeLabels.Hours]: calc(36e5),
    [TimeLabels.Days]: calc(864e5),
    [TimeLabels.Weeks]: calc(6048e5),
    [TimeLabels.Months]: calc(26298e5),
    [TimeLabels.Years]: calc(315576e5),
  };
}

const TimeUtils = {
  calcMilliseconds: createCalcMilliseconds(),
  calcFromMilliseconds: createCalcMilliseconds(true),

  formatMinutesToHours(minutes: number) {
    let h: string | number = Math.floor(minutes / 60);
    let m: string | number = minutes % 60;

    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;

    return h + ':' + m;
  },
  formatMilliSeconds(value: number) {
    const options = { maximumFractionDigits: 2 };

    const minuteInMS = this.calcMilliseconds.minutes(1);

    const hourInMS = this.calcMilliseconds.hours(1);

    const unit =
      value >= hourInMS
        ? TimeLabels.Hours
        : value >= minuteInMS
        ? TimeLabels.Minutes
        : TimeLabels.Seconds;

    const converted = TimeUtils.calcFromMilliseconds[unit](value);

    const formatted = NumberUtil.format(converted, options);

    if (unit in DateTimeConfig.timeDisplay) {
      return `${formatted}${DateTimeConfig.timeDisplay[unit]}`;
    }

    return `${formatted}${pluralizeWithoutCount(converted, unit.slice(0, -1))}`;
  },
  calculateDurationFromMilliseconds(milliseconds: number) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  },
};

export default TimeUtils;
