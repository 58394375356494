import React from 'react';
import 'styles/all.scss';
import reportWebVitals from './reportWebVitals';
import beforeInit from 'beforeInit';
import 'core-js';
import 'utils/polyfills/broadcastChannell';
import { createRoot } from 'react-dom/client';
import 'app/utils/i18n';
import App from 'components/App';

beforeInit();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
