import { FC } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useQuery as useApolloQuery } from '@apollo/client';
import { QueryKey } from 'components/insights/constants';
import AnalyticsAdAccountsApi from 'api/analytics/adAccount';
import { FB_AD_ACCOUNTS_QUERY } from 'graphql/insights/queries/pagePostsQuery';
import { useToast } from 'app/hooks/useToast';
import { AnalyticsAdAccount } from 'types/AdAccount';

type adAccountState = {
  availableAdAccounts: AnalyticsAdAccount[];
  handleAdAccountChange: (adAccount: AnalyticsAdAccount) => void;
  isAdAccountConnected: (adAccount: AnalyticsAdAccount) => boolean;
};

type Props = {
  children: (props: adAccountState) => JSX.Element;
  page: string;
};

export const AdAccountConnectionContainer: FC<Props> = ({ children, page }) => {
  const toast = useToast();
  const { data: availableAdAccounts } = useQuery(
    QueryKey.getAdAccounts,
    AnalyticsAdAccountsApi.getAvailableAdAccounts,
  );

  const { data: adAccounts, refetch } = useApolloQuery(FB_AD_ACCOUNTS_QUERY, {
    variables: {
      id: page,
    },
  });

  const toggleAdAccount = useMutation(AnalyticsAdAccountsApi.toggleAdAccount, {
    onError(e, vars) {
      refetch();
      toast(`Unable to ${vars.connect ? '' : 'dis'}connect Ad account.`);
    },
    onSuccess(data, vars) {
      refetch();
      toast(`Ad account successfully ${vars.connect ? '' : 'dis'}connected.`);
    },
  });

  const isAdAccountConnected = (
    facebookAdAccount: AnalyticsAdAccount,
  ): boolean => {
    const connectedAdAccounts =
      adAccounts?.facebookPage?.connectedAdAccounts?.edges.map(
        (adAccount: { node: AnalyticsAdAccount }) => ({
          currency: adAccount.node.currency,
          name: adAccount.node.name,
          facebookAdAccountId: adAccount.node.facebookAdAccountId,
        }),
      ) ?? [];
    return (
      connectedAdAccounts.filter(
        (adAccount: AnalyticsAdAccount) =>
          adAccount.facebookAdAccountId ===
          facebookAdAccount.facebookAdAccountId,
      ).length > 0
    );
  };

  const handleAdAccountChange = (adAccount: AnalyticsAdAccount): void => {
    toggleAdAccount.mutate({
      pageId: page,
      facebookAdAccountId: adAccount.facebookAdAccountId,
      connect: !isAdAccountConnected(adAccount),
    });
  };

  return children({
    availableAdAccounts: availableAdAccounts ?? [],
    handleAdAccountChange,
    isAdAccountConnected,
  });
};

export default AdAccountConnectionContainer;
