import { useEffect, useState } from 'react';

const useIsHoldingCtrlOrCmdKey = () => {
  const [isCtrlOrCmdActive, setIsCtrlOrCmdActive] = useState(false);

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, []);

  function onKeyDown(e: KeyboardEvent) {
    if (e.ctrlKey || e.metaKey) {
      setIsCtrlOrCmdActive(true);
    }
  }

  function onKeyUp() {
    setIsCtrlOrCmdActive(false);
  }

  return {
    isCtrlOrCmdActive,
  };
};

export default useIsHoldingCtrlOrCmdKey;
