import { Comment } from 'app/modules/comments/types';
import dayjs from 'dayjs';
import PostsConfig from 'app/config/posts';

export function isNeighborOfComment(
  currentComment: Comment,
  neighborComment: Comment | undefined,
) {
  if (!neighborComment) return false;

  if (currentComment.user.id !== neighborComment.user.id) return false;

  const current = dayjs(currentComment.created_at);
  const neighbor = dayjs(neighborComment.created_at);

  return (
    Math.abs(neighbor.diff(current, 'minutes')) <
    PostsConfig.COMMENTS.NEIGHBOR_COMMENT_LIMIT_MINUTES
  );
}
