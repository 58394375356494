import queryString from 'query-string';
import config from 'config';
import { Path } from '@remix-run/router/history';

export const formatURLQuery = (query: string): string => {
  return query.replace(/%2C/g, ',');
};

export function parseValue(value: string | null) {
  if (value?.includes(',')) {
    return JSON.stringify(
      value.split(',').map((item) => {
        const parsedItem = parseInt(item);
        return isNaN(parsedItem) ? item : parsedItem;
      }),
    );
  }

  return value;
}

/**
 * @deprecated Function is deprecated because it's using window.location instead of router location. Use useHistoryQueryParams instead.
 */
export function getQueryParameter(
  name: string,
  defaultValue: string = '',
  search?: string,
): string {
  const urlParams = new URLSearchParams(search ?? window.location.search);
  const value = urlParams.get(name);

  return parseValue(value) || defaultValue;
}

export function getQueryParameters(
  names: string[],
  search: string,
  options: { parse: boolean } = { parse: true },
): Record<string, string | null> {
  const urlParams = new URLSearchParams(search);
  const parameters: Record<string, string | null> = {};

  names.forEach((name) => {
    const value = urlParams.get(name);
    parameters[name] = options.parse ? parseValue(value) : value;
  });

  return parameters;
}

type ParamValue = string | number | string[] | number[] | boolean;

/**
 * @deprecated Function is deprecated because it's using window.location instead of router location. Use useHistoryQueryParams instead.
 */
export function setQueryParameter(
  name: string,
  value: ParamValue,
  pushState: boolean = false,
): void {
  const urlParams = new URLSearchParams(window.location.search);

  if (Array.isArray(value)) {
    value = value.join();
  }

  urlParams.set(name, value as string);
  const newRelativePathQuery =
    window.location.pathname + '?' + urlParams.toString();

  if (pushState) {
    window.history.pushState(null, '', formatURLQuery(newRelativePathQuery));
  } else {
    window.history.replaceState(null, '', formatURLQuery(newRelativePathQuery));
  }
}

/**
 * @deprecated Function is deprecated because it's using window.location instead of router location. Use useHistoryQueryParams instead.
 */
export function deleteQueryParameter(
  name: string,
  pushState: boolean = false,
): void {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.delete(name);
  const newRelativePathQuery =
    window.location.pathname + '?' + urlParams.toString();

  if (pushState) {
    window.history.pushState(null, '', formatURLQuery(newRelativePathQuery));
  } else {
    window.history.replaceState(null, '', formatURLQuery(newRelativePathQuery));
  }
}

export function createSearchFromParams(
  params: Record<string, ParamValue | null | undefined>,
): string {
  const search = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      if (Array.isArray(value)) {
        search.set(key, value.join());
      } else {
        search.set(key, value.toString());
      }
    }
  });

  return formatURLQuery(`?${search.toString()}`);
}

export function createStandardSearch(
  params: Record<string, ParamValue | null | undefined>,
): string {
  return `?${queryString.stringify(params)}`;
}

export function areSomeQueryParamsPresent(
  targetedSearchParamKeys?: string[],
): boolean {
  const search = new URLSearchParams(window.location.search);

  const currentSearchParamKeys = Array.from(search.keys());

  if (targetedSearchParamKeys) {
    return targetedSearchParamKeys.some((key) =>
      currentSearchParamKeys.includes(key),
    );
  }

  return currentSearchParamKeys.length > 0;
}

export function parseQueryParamToNumbers(parameter?: string | null): number[] {
  try {
    if (!parameter) {
      return [];
    }

    const parsed: number[] = JSON.parse(
      parameter.startsWith('[') ? parameter : `[${parameter}]`,
    );

    if (Array.isArray(parsed)) {
      return parsed.map(Number).filter(Number.isFinite);
    }
  } catch {}

  return [];
}

export function parseQueryParamToStrings(parameter?: string | null): string[] {
  try {
    if (!parameter) {
      return [];
    }

    if (
      !parameter.includes(',') &&
      !parameter.includes('[') &&
      !parameter.includes(']')
    ) {
      return [parameter];
    }

    const parsed: string[] = JSON.parse(parameter);

    if (Array.isArray(parsed)) {
      return parsed.map(String);
    }

    return [String(parsed)];
  } catch {}

  return [];
}

export function openPopup(url: string, width = 700, height = 600) {
  return window.open(url, '_blank', `width=${width},height=${height}`);
}

export function openBlank(url: string) {
  return window.open(url, '_blank');
}

export const openUrl = (url: string) => {
  window.open(url, '_self');
};

export const getBasePath = () => {
  return config.ROUTER_BASE_URL;
};

export const routePathToUrl = (route: Partial<Path>) => {
  const pathname = route.pathname
    ? route.pathname.startsWith('/')
      ? route.pathname
      : `/${route.pathname}`
    : '';

  const search = route.search
    ? route.search.startsWith('?')
      ? route.search
      : `?${route.search}`
    : '';

  const hash = route.hash
    ? route.hash.startsWith('#')
      ? route.hash
      : `#${route.hash}`
    : '';

  return `${getBasePath()}${pathname}${search}${hash}`;
};

// https://github.com/sindresorhus/prepend-http/blob/main/index.js
export function prependHttp(url: string, { https = true } = {}) {
  url = url.trim();

  if (/^\.*\/|^(?!localhost)\w+?:/.test(url)) {
    return url;
  }

  return url.replace(/^(?!(?:\w+?:)?\/\/)/, https ? 'https://' : 'http://');
}

export function getHostname(url: string) {
  try {
    return new URL(url).hostname;
  } catch (e) {
    return url;
  }
}

export function getFullPath(url: string) {
  try {
    return new URL(url).pathname + new URL(url).search;
  } catch (e) {
    return url;
  }
}

export const getCleanUrl = (
  url: string,
  { path = true, search = true } = {},
) => {
  try {
    const validUrl = new URL(prependHttp(url));

    return `${validUrl.hostname.replace('www.', '')}${
      path ? validUrl.pathname : ''
    }${search ? validUrl.search : ''}`;
  } catch {
    return url;
  }
};
