import Logger from 'utils/logger';

const DomUtils = {
  scrollTo(id: string, position: number) {
    const elm = document.getElementById(id);

    if (elm) {
      elm.scrollTo({
        top: position,
        behavior: 'smooth',
      });
    } else {
      Logger.error("It can't be scroll, because element not found");
    }
  },

  scrollToBottom<T extends Element>(
    element?: T | null,
    options?: {
      delay?: number;
      behaviour?: ScrollBehavior;
    },
  ) {
    setTimeout(() => {
      if (element) {
        element.scrollTo({
          top: element.scrollHeight,
          behavior: options?.behaviour || 'smooth',
        });
      }
    }, options?.delay || 0);
  },
  scrollParentToChild(parent: Element, child: Element, offset: number = 0) {
    const parentRect = parent.getBoundingClientRect();
    const childRect = child.getBoundingClientRect();

    const top = childRect.top + parent.scrollTop - parentRect.top - offset;

    if (typeof parent?.scrollTo == 'function') {
      parent.scrollTo({
        top,
        behavior: 'smooth',
      });
    } else {
      parent.scrollTop = top;
    }
  },
  querySelectorFirst<T extends HTMLElement>(selector: string | string[]) {
    const selectors = Array.isArray(selector) ? selector : [selector];

    for (const select of selectors) {
      const element = document.querySelector<T>(select);

      if (element) {
        return element;
      }
    }

    return null;
  },
  getElementById<T extends HTMLElement>(id: string) {
    return document.querySelector<T>(`#${id}`);
  },
};

export default DomUtils;
