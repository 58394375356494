import { CustomRoute, CustomRouteConfig, NestedKeyOf } from 'app/types/routes';
import { routeNames } from 'constants/routes';

export function getRouteName(pathName: string): string {
  let routeNameKey;
  const names = Object.keys(routeNames);

  // exact match
  routeNameKey = names.find((key) => pathName === key);

  // partial match
  if (!routeNameKey) {
    routeNameKey = names.find((key) => pathName.includes(key));
  }

  if (routeNameKey) {
    return routeNames[routeNameKey];
  }

  return 'Kontentino';
}

export function createComposeRoute<T extends CustomRouteConfig>(inputData: T) {
  return (inputPath: NestedKeyOf<typeof inputData>) => {
    const keys = inputPath.split('.');
    const paths: string[] = [];

    let currentPathConfiguration:
      | CustomRouteConfig
      | CustomRoute
      | string
      | undefined = inputData;

    for (const key of keys) {
      if (!currentPathConfiguration) {
        break;
      }

      currentPathConfiguration = currentPathConfiguration[key];

      if (
        typeof currentPathConfiguration === 'object' &&
        typeof currentPathConfiguration?.path === 'string'
      ) {
        paths.push(currentPathConfiguration.path);
      }
    }

    return '/' + paths.join('/');
  };
}
