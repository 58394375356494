import React, { FC, useEffect, useMemo, useState } from 'react';
import { usePage } from 'modules/page/pageSelector';
import { MODULE_STATUS } from 'constants/modules';
import { PageData, ProfileGroup } from 'types/Page';
import { useDispatch } from 'react-redux';
import { PageThunk } from 'modules/page/pageActions';
import { useEffectOnce } from 'utils/hooks/useEffectOnce';
import { PostsContainerProvider } from 'components/posts/context';
import { useUser } from 'modules/user/userSelector';
import { useToast } from 'app/hooks/useToast';
import {
  ActiveState,
  useActiveState,
} from 'app/modules/posts/hooks/useActiveState';
import {
  ChangePostsFilterHandler,
  FilterState,
  useFilterState,
} from 'app/modules/posts/hooks/useFilterState';
import { useUrlSideEffectForUserPilot } from 'app/modules/posts/hooks/useUrlSideEffectForUserPilot';
import { usePostsQueries } from 'app/modules/posts/hooks/usePostsQueries';
import { Inspiration } from 'types/Inspiration';
import { UserActions } from 'modules/user/userActions';
import { useSelectedPostsState } from 'app/modules/posts/hooks/useSelectedPostsState';
import {
  type SegmentedPosts,
  useSegmentedPosts,
} from 'app/modules/posts/hooks/useSegmentedPosts';
import { PostsMeta } from 'types/Post';
import StringUtils from 'utils/string';
import dayjs from 'dayjs';

export type PostsState = {
  posts: SegmentedPosts;
  pages: PageData[];
  inspirations: Record<string, Inspiration[]>;
  profileGroups: ProfileGroup[];
  isInspirationsLoading: boolean;
  isPostsLoading: boolean;
  isPagesLoading: boolean;
  filterState: FilterState;
  activeState: ActiveState;
  prefetchPostsForMonth: (date: Date | dayjs.Dayjs) => void;
  changeSearchedText(text: string): void;
  clearAllFilters(): void;
  selectAllPosts(): void;
  clearSelectedPosts(): void;
  allPostsSelected: boolean;
  selectedPosts: number[];
  toggleSelectedPosts(postId: number, selectRange?: boolean): void;
  clearSelectedPost(postId: number): void;
  toggleSelectedPostsByMonth(monthStartDate: string): void;
  areAllPostsInMonthSelected: (monthStartDate: string) => boolean;
  meta: PostsMeta | null;
  changeFilter: ChangePostsFilterHandler;
};

type Props = {
  children: (props: PostsState) => JSX.Element;
};

const Container: FC<Props> = ({ children }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const user = useUser();
  const { pages, profileGroups, status: pagesStatus } = usePage();
  const [searchQuery, setSearchQuery] = useState('');
  const {
    value: filterState,
    handlers: postsFilterHandlers,
    debouncedValue: debouncedFilterState,
  } = useFilterState();

  const activeState = useActiveState({
    filterState,
    profiles: pages,
    profileGroups,
  });

  const {
    posts,
    isPostsLoading,
    inspirations,
    isInspirationsLoading,
    meta,
    prefetchPostsForMonth,
  } = usePostsQueries({
    activeState,
    filterState: debouncedFilterState,
  });

  const filteredPosts = useMemo(() => {
    return !searchQuery.length
      ? posts
      : posts.filter((post) => StringUtils.isSubstring(searchQuery, post.text));
  }, [posts, searchQuery]);

  const postsData = useSegmentedPosts(filteredPosts);
  const postSelection = useSelectedPostsState(postsData.postsWithDate);

  useUrlSideEffectForUserPilot({ activeState });

  useEffectOnce(() => {
    dispatch(PageThunk.requestPages());
  });

  useEffect(() => {
    if (postSelection.selectedPosts.length > 0) {
      toast(
        'Your post selection was cleared because you have changed the filters',
      );
      postSelection.clearSelectedPosts();
    }

    dispatch(
      UserActions.saveLastFilterState({
        dateFrom: filterState.startDate,
        dateTo: filterState.endDate,
        orderDirection: filterState.orderDirection,
        assignedUsers: filterState.selectedUserIds,
        labels: filterState.selectedLabels,
        profileGroups: filterState.selectedGroups,
        profiles: filterState.selectedProfiles,
        projects: filterState.selectedProjects,
        pseudoTypes: filterState.selectedPseudoTypes,
        statuses: filterState.selectedStatuses,
      }),
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState]);

  const assignedToMePostsCount =
    meta.data?.assignedUser.find((metaDataUser) => metaDataUser.id === user.id)
      ?.postCount ?? 0;

  useEffect(() => {
    if (meta.isFetched && assignedToMePostsCount === 0) {
      postsFilterHandlers.changeFilter({
        key: 'selectedUserIds',
        value: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedToMePostsCount, meta.isFetched]);

  const postsState: PostsState = {
    posts: postsData,
    prefetchPostsForMonth,
    pages,
    inspirations,
    profileGroups,
    isInspirationsLoading,
    isPostsLoading,
    isPagesLoading: pagesStatus === MODULE_STATUS.Loading,
    filterState,
    activeState,
    meta: meta.data || null,
    changeSearchedText: setSearchQuery,
    ...postsFilterHandlers,
    ...postSelection,
  };

  return (
    <PostsContainerProvider value={postsState}>
      {children(postsState)}
    </PostsContainerProvider>
  );
};

export default Container;
