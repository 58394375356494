import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEmptySet } from '@fortawesome/pro-regular-svg-icons';

type Props = {};

const PostPreviewPlaceholder: FC<Props> = () => {
  return (
    <div className="tw-flex tw-flex-col tw-justify-end tw-rounded-md tw-border-[2px] tw-border-solid tw-border-grayscale-20">
      <div className="tw-flex tw-h-full tw-items-center tw-justify-center">
        <div className="tw-flex tw-h-20 tw-w-20 tw-items-center tw-justify-center tw-rounded-[50%] tw-bg-grayscale-20">
          <div className="tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center">
            <FontAwesomeIcon
              icon={faEmptySet}
              className="tw-text-2xl tw-text-grayscale-100"
            />
          </div>
        </div>
      </div>
      <div className="tw-px-4">
        <div className="tw-h-4 tw-w-full tw-rounded-md tw-bg-grayscale-20" />
        <div className="tw-flex tw-flex-row tw-py-4 ">
          <div className="tw-min-[40px] tw-h-10 tw-min-h-[40px] tw-w-10 tw-rounded-[50%] tw-bg-grayscale-20" />
          <div className="tw-ml-4 tw-flex tw-w-full tw-flex-col tw-gap-2">
            <div className="tw-h-4 tw-w-full tw-rounded-md tw-bg-grayscale-20" />
            <div className="tw-h-4 tw-w-full tw-rounded-md tw-bg-grayscale-20" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostPreviewPlaceholder;
