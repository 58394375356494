import { gql } from '@apollo/client';

export const FACEBOOK_PAGE = gql`
  query fbDetailQuery(
    $id: String!
    $dateFrom: String
    $dateTo: String
    $metricName: String
    $first: Int = -1
  ) {
    facebookPage(id: $id) {
      id
      _id
      name
      type
      picture
      metrics(
        name: $metricName
        dateFrom: $dateFrom
        dateTo: $dateTo
        first: $first
        orderBy: [{ field: DATE, direction: ASC }]
      ) {
        edges {
          node {
            metricName
            metricValue
            date
            additionalData {
              edges {
                node {
                  name
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const FACEBOOK_PAGE_AGGREGATED_METRIC = gql`
  query fbDetailQueryWithAggregatedMetric(
    $id: String!
    $dateFrom: String
    $dateTo: String
    $metricName: String!
    $first: Int
    $orderBy: [PageAggregatedMetricOrder] = [{ field: VALUE, direction: DESC }]
  ) {
    facebookPage(id: $id) {
      name
      aggregatedMetrics(
        first: $first
        name: $metricName
        dateFrom: $dateFrom
        dateTo: $dateTo
        orderBy: $orderBy
      ) {
        edges {
          node {
            name
            value
          }
        }
      }
    }
  }
`;

export const INSTAGRAM_PAGE_AGGREGATED_METRIC = gql`
  query igDetailQueryWithAggregatedMetric(
    $id: String!
    $dateFrom: String
    $dateTo: String
    $metricName: String!
    $first: Int
    $orderBy: [PageAggregatedMetricOrder] = [{ field: VALUE, direction: DESC }]
  ) {
    instagramPage(id: $id) {
      name
      aggregatedMetrics(
        first: $first
        name: $metricName
        dateFrom: $dateFrom
        dateTo: $dateTo
        orderBy: $orderBy
      ) {
        edges {
          node {
            name
            value
          }
        }
      }
    }
  }
`;

export const INSTAGRAM_PAGE = gql`
  query igDetailQuery(
    $id: String!
    $dateFrom: String
    $dateTo: String
    $metricName: String
    $first: Int = -1
  ) {
    instagramPage(id: $id) {
      id
      _id
      username
      name
      type
      picture
      metrics(
        name: $metricName
        dateFrom: $dateFrom
        dateTo: $dateTo
        first: $first
        orderBy: { field: DATE, direction: ASC }
      ) {
        edges {
          node {
            metricName
            metricValue
            date
          }
        }
      }
    }
  }
`;
