import { FC } from 'react';
import style from 'components/insights/infoCard/header/Header.module.scss';
import GraphTypeSelect from 'components/insights/infoCard/header/GraphTypeSelect';
import classes from 'react-style-classes';
import { GraphType } from 'constants/analytics';
import { Icon } from '@kontentino/ui';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';

type Props = {
  title: string;
  isExpanded: boolean;
  onExpandToggle: AnyFunction;
  availableGraphTypes?: GraphType[];
  activeGraphType?: GraphType;
  onChangeGraphType?: (type: GraphType) => void;
  isOnExport?: boolean;
};

const Header: FC<Props> = (props) => {
  const shouldDisplayGraphSelect =
    !!props.availableGraphTypes &&
    !props.isOnExport &&
    props.availableGraphTypes.length !== 1;

  return (
    <div className={style.header} onClick={props.onExpandToggle}>
      <span className={style.title}>{props.title}</span>
      <div className={style.actions}>
        {shouldDisplayGraphSelect && (
          <GraphTypeSelect
            availableGraphTypes={props.availableGraphTypes as GraphType[]}
            activeGraphType={props.activeGraphType as GraphType}
            onChangeGraphType={(type) => props.onChangeGraphType?.(type)}
          />
        )}
        {!props.isOnExport && (
          <Icon
            icon={faChevronDown}
            className={classes(
              style.toggler,
              props.isExpanded && style.inverted,
            )}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
