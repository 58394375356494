import { useEffectOnce } from 'utils/hooks/useEffectOnce';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useInfoBanner } from 'app/components/InfoBannerProvider';
import useLocalStorage from 'utils/hooks/useLocalStorage';
import { useAccount } from 'modules/account/accountSelector';
import { useTranslation } from 'react-i18next';
import DateUtils from 'app/utils/date';
import { BrowserStorage } from 'utils/browserStorage';

const INFO_BANNER_DAYS_INTERVAL = 1;

export const useLastPaymentStatusBanner = () => {
  const account = useAccount();
  const { t } = useTranslation();
  const { content: bannerContent, showBanner } = useInfoBanner();
  const [closedBannerDate, setClosedBannerDate] = useLocalStorage<
    string | null
  >(BrowserStorage.keys.PaymentInfoBannerStatus, null);

  useEffectOnce(() => {
    const isCorrectDateToTriggerBanner =
      (closedBannerDate !== null &&
        dayjs().diff(dayjs(closedBannerDate), 'd') >=
          INFO_BANNER_DAYS_INTERVAL) ||
      closedBannerDate === null;

    if (account.isLastPaymentFailed && isCorrectDateToTriggerBanner) {
      showBanner(
        <div className="tw-flex tw-flex-col tw-items-center tw-text-md">
          <span className="tw-font-bold">{t('paymentFailed')}:</span>
          <span>{t('paymentFailedDescription')}</span>
        </div>,
      );
    }
  });

  useEffect(() => {
    if (bannerContent === null) {
      setClosedBannerDate(DateUtils.toDateString(dayjs()));
    }
  }, [bannerContent, setClosedBannerDate]);
};
