import React, { FC, useState } from 'react';
import KAI_HAND from 'app/modules/aiContent/assets/kai-hand.svg';
import useInterval from 'app/hooks/useInterval';
import clsx from 'clsx';
import { mergeTailwindClasses } from '@kontentino/ui';

type Props = {
  title: string;
  classNames?: {
    img?: string;
  };
};

const loadingPhrases = [
  'Warming up, preparing for action...',
  'Gears turning, getting ready to go...',
  'System priming, almost there...',
  'Engines revving, just a moment...',
  'Loading data, please stand by...',
  'Cogs spinning, powering up...',
  'Syncing connections, almost ready...',
  'Calibrating instruments, just a moment...',
  'Buffers filling, hang on tight...',
  'Analyzing inputs, nearly there...',
  'Gathering energy, just a little longer...',
  'Optimizing processes, stay patient...',
  'Establishing links, nearly done...',
  'Charging capacitors, hold on...',
  'Finalizing preparations, almost finished...',
  'Printing, your request is coming up...',
  'Ink flowing, completing the task...',
  'Wrapping up, thank you for waiting...',
];

const LoadingGeneratedResults: FC<Props> = (props) => {
  const [phraseIndex, setPhraseIndex] = useState(0);

  useInterval(() => {
    setPhraseIndex((prevIndex) =>
      prevIndex + 1 === loadingPhrases.length ? prevIndex : prevIndex + 1,
    );
  }, 3000);

  return (
    <div className="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-p-4">
      <img
        alt="Kai hand"
        src={KAI_HAND}
        className={mergeTailwindClasses(
          clsx(
            'tw-mb-8 tw-h-auto tw-w-full tw-max-w-[14.5rem]',
            props.classNames?.img,
          ),
        )}
      />
      <div className="tw-mb-2 tw-text-md tw-font-medium tw-text-grayscale-180">
        {props.title}
      </div>
      <div className="tw-relative tw-mb-2 tw-h-5 tw-w-full tw-max-w-[20.5rem] tw-text-center tw-text-md tw-text-grayscale-180">
        {loadingPhrases.map((desc, index) => (
          <div
            key={`description-${index}`}
            className={clsx(
              'tw-absolute tw-left-0 tw-top-0 tw-w-full tw-transition-opacity',
              {
                'tw-opacity-1': index === phraseIndex,
                'tw-opacity-0': index !== phraseIndex,
              },
            )}
          >
            {desc}
          </div>
        ))}
      </div>
      <div
        className="tw-border-t-opacity-0 tw-inline-flex tw-h-4 tw-w-4 tw-animate-spin tw-rounded-full tw-border-[2px] tw-border-t-white tw-border-opacity-0 tw-text-primary-100"
        role="status"
        aria-label="loading"
      />
    </div>
  );
};

export default LoadingGeneratedResults;
