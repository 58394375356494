import React, { Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import routeConfig from 'app/config/routes';
import Skeleton from 'react-loading-skeleton';
import { Outlet } from 'react-router';

const AIContent = React.lazy(
  () => import('app/modules/aiContent/components/AiContent'),
);

export const aiContentRouter: RouteObject = {
  path: routeConfig.aiContent.path,
  element: (
    <Suspense
      fallback={
        <div className="tw-h-full tw-p-4">
          <Skeleton width="100%" height="100%" />
        </div>
      }
    >
      <Outlet />
    </Suspense>
  ),
  children: [
    {
      index: true,
      element: <AIContent />,
    },
  ],
};
