import useSetState from 'utils/hooks/useSetState';
import {
  areSomeQueryParamsPresent,
  getQueryParameters,
  parseQueryParamToNumbers,
  parseQueryParamToStrings,
} from 'utils/url';
import { PseudoPostType } from 'constants/post';
import Logger from 'utils/logger';
import { OrderDirection } from 'app/types';
import { useHistoryQueryParams } from 'app/hooks/useHistoryQueryParams';
import { UserLastPostsQueryParams } from 'types/User';
import dayjs from 'dayjs';
import DateUtils from 'app/utils/date';
import { useUser } from 'modules/user/userSelector';
import { togglePrimitiveItemInArray } from 'utils/helpers';
import useDebounce from 'utils/hooks/useDebounce';
import PostsConfig from 'app/config/posts';
import { getValidPostsFilterDateRange } from 'app/modules/posts/utils/getValidPostsFilterDateRange';

type FilterChange<T> = T extends keyof FilterState
  ? {
      key: T;
      value: FilterState[T] extends Array<infer TArrayMember>
        ? FilterState[T] | TArrayMember
        : FilterState[T];
    }
  : never;

export type ChangePostsFilterHandler = (
  ...data: FilterChange<keyof FilterState>[]
) => void;

export type FilterState = {
  selectedProfiles: number[];
  selectedGroups: number[];
  selectedProjects: number[];
  startDate: string;
  endDate: string;
  orderDirection: OrderDirection;
  selectedStatuses: number[];
  selectedLabels: number[];
  selectedPseudoTypes: PseudoPostType[];
  selectedUserIds: number[];
};

export const defaultStartDate = DateUtils.toDateString(
  dayjs().startOf('month'),
);
export const defaultEndDate = DateUtils.toDateString(dayjs().endOf('month'));

const defaultInitialState: FilterState = {
  selectedProfiles: [],
  selectedGroups: [],
  selectedProjects: [],
  startDate: defaultStartDate,
  endDate: defaultEndDate,
  orderDirection: OrderDirection.Asc,
  selectedStatuses: [],
  selectedLabels: [],
  selectedPseudoTypes: [],
  selectedUserIds: [],
};

const QUERY_PARAMS_BY_FILTER_KEY = {
  startDate: 'startDate',
  endDate: 'endDate',
  selectedProfiles: 'profiles',
  selectedStatuses: 'statuses',
  selectedProjects: 'project',
  selectedGroups: 'groups',
  orderDirection: 'order',
  selectedLabels: 'labels',
  selectedUserIds: 'users',
  selectedPseudoTypes: 'types',
};

function getFilterStateFromUrl(
  initialState: FilterState,
  params: Record<string, string | null>,
) {
  try {
    const { startDate, endDate } = getValidPostsFilterDateRange(
      params.startDate || defaultStartDate,
      params.endDate || defaultEndDate,
    );

    if (startDate && DateUtils.isValid(startDate)) {
      initialState.startDate = DateUtils.toDateString(dayjs(startDate));
    }

    if (endDate && DateUtils.isValid(endDate)) {
      initialState.endDate = DateUtils.toDateString(dayjs(endDate));
    }

    initialState.selectedProfiles = parseQueryParamToNumbers(params.profiles);
    initialState.selectedGroups = parseQueryParamToNumbers(params.groups);
    initialState.selectedProjects = parseQueryParamToNumbers(params.project);
    initialState.selectedStatuses = parseQueryParamToNumbers(params.statuses);
    initialState.selectedLabels = parseQueryParamToNumbers(params.labels);
    initialState.selectedPseudoTypes = parseQueryParamToStrings(
      params.types,
    ) as PseudoPostType[];

    if (params.order === OrderDirection.Desc) {
      initialState.orderDirection = OrderDirection.Desc;
    }
  } catch (e) {
    Logger.error(e);
  }

  return initialState;
}

function getInitialFilterState(
  userLastPostsQueryParams: UserLastPostsQueryParams,
): FilterState {
  let filtersState = defaultInitialState;

  if (areSomeQueryParamsPresent(Object.values(QUERY_PARAMS_BY_FILTER_KEY))) {
    const urlValues = getQueryParameters(
      Object.values(QUERY_PARAMS_BY_FILTER_KEY),
      window.location.search,
    );

    filtersState = getFilterStateFromUrl(filtersState, urlValues);
  } else {
    if (userLastPostsQueryParams) {
      filtersState.selectedProfiles = Array.isArray(
        userLastPostsQueryParams.profiles,
      )
        ? userLastPostsQueryParams.profiles
        : defaultInitialState.selectedProfiles;
      filtersState.selectedGroups = Array.isArray(
        userLastPostsQueryParams.profileGroups,
      )
        ? userLastPostsQueryParams.profileGroups ||
          defaultInitialState.selectedGroups
        : [];
    }
  }

  return filtersState;
}

export function useFilterState() {
  const user = useUser();
  const { deleteQueryParameter, replaceQueryParameters } =
    useHistoryQueryParams();
  const [filterState, setFilterState] = useSetState<FilterState>(() =>
    getInitialFilterState(user.lastPostsQueryParams),
  );
  const debouncedFilterState = useDebounce(
    filterState,
    PostsConfig.POSTS_FILTER_DEBOUNCE,
  );

  function clearAllFilters() {
    setFilterState({
      selectedStatuses: [],
      selectedLabels: [],
      selectedProjects: [],
      selectedPseudoTypes: [],
      selectedUserIds: [],
    });
    deleteQueryParameter([
      'statuses',
      'labels',
      'project',
      'search',
      'types',
      'users',
    ]);
  }

  const removeProjectsIfProfileGroupIsSelected = (
    filters: FilterChange<keyof FilterState>[],
  ) => {
    const changedProfileGroupFilter = filters.find(
      ({ key }) => key === 'selectedGroups',
    );

    const filtersWithoutProjects = [
      ...filters,
      {
        key: 'selectedProjects',
        value: [],
      },
    ] as FilterChange<keyof FilterState>[];

    switch (true) {
      case !!changedProfileGroupFilter &&
        Array.isArray(changedProfileGroupFilter.value) &&
        changedProfileGroupFilter.value.length > 0:
      case !!changedProfileGroupFilter &&
        !Array.isArray(changedProfileGroupFilter.value) &&
        !!changedProfileGroupFilter.value:
        return filtersWithoutProjects;
      default:
        return filters;
    }
  };

  const handleFilterChange: ChangePostsFilterHandler = (...data) => {
    setFilterState((prevState) => {
      const newState = { ...prevState };
      const changedFilters = removeProjectsIfProfileGroupIsSelected(data);

      changedFilters.forEach((item) => {
        const { key, value } = item;

        if (Array.isArray(newState[key])) {
          newState[key] = Array.isArray(value)
            ? value
            : (togglePrimitiveItemInArray(
                value,
                newState[key] as any[],
              ) as any);
        } else {
          newState[key] = value as any;
        }
      });

      replaceQueryParameters(QUERY_PARAMS_BY_FILTER_KEY, newState);

      return newState;
    });
  };

  return {
    value: filterState,
    debouncedValue: debouncedFilterState,
    handlers: {
      clearAllFilters,
      changeFilter: handleFilterChange,
    },
  };
}
