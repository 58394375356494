import config from 'config';
import { getQueryParameter } from 'utils/url';

export function isProductionMode(): boolean {
  return config.MODE === 'production';
}

export function isDevelopmentMode(): boolean {
  return config.MODE === 'development';
}

export const isDebugMode = (): boolean => {
  const debugQueryParameter = getQueryParameter('debug');

  return debugQueryParameter === 'true';
};

export const isReduxLogerOn = (): boolean => {
  return config.REDUX_LOGGER_ON === 'true';
};

export const sleep = async (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export function togglePrimitiveItemInArray<T>(item: T, itemsArray: T[]): T[] {
  if (itemsArray.includes(item)) {
    return itemsArray.filter((itemId) => itemId !== item);
  }

  return [...itemsArray, item];
}

export function isMacintosh(): boolean {
  return navigator.platform.indexOf('Mac') > -1;
}

export const getUniquePrimitiveItemsFromArray = <T>(items: T[]): T[] => {
  const uniqueItems = new Set([...items]);
  return Array.from(uniqueItems);
};

export const convertHexToRGBA = (hexCode: string, opacity: number = 1) => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgba(${r},${g},${b}, ${opacity} )`;
};
