import { useQuery } from '@apollo/client';
import {
  fbPageStatistics,
  fbPageStatistics_facebookPage_statistics,
} from 'graphql/insights/queries/__generated__/fbPageStatistics';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import {
  igPageStatistics,
  igPageStatistics_instagramPage_statistics,
} from 'graphql/insights/queries/__generated__/igPageStatistics';
import {
  FB_PAGE_STATISTICS,
  IG_PAGE_STATISTICS,
  LI_PAGE_STATISTICS,
} from 'graphql/insights/queries/pageStatistics';
import { Page } from 'app/modules/insights/types/Page';
import {
  liPageStatistics,
  liPageStatistics_linkedInPage_statistics,
} from 'graphql/insights/queries/__generated__/liPageStatistics';
import { useState } from 'react';
import {
  MetricValue,
  PageStatisticsData,
} from 'app/modules/insights/types/PageStatistics';
import { Dayjs } from 'dayjs';
import DateUtils from 'app/utils/date';

export function usePageStatisticsQuery({
  page,
  startDate,
  endDate,
  onSuccess,
}: {
  page: Page;
  startDate: Dayjs;
  endDate: Dayjs;
  onSuccess?: (metrics: PageStatisticsData) => void;
}) {
  const [data, setData] = useState<PageStatisticsData | undefined>();
  const variables = {
    id: page.id,
    dateFrom: DateUtils.toDateString(startDate),
    dateTo: DateUtils.toDateString(endDate),
  };

  const getFilteredMetrics = (
    statistics:
      | fbPageStatistics_facebookPage_statistics
      | igPageStatistics_instagramPage_statistics
      | liPageStatistics_linkedInPage_statistics,
  ) => {
    return Object.entries(statistics)
      .filter(([key, value]) => key[0] !== '_')
      .map(([key, value]) => [key, value]) as MetricValue[];
  };

  const handleOnCompleted = (
    statistics:
      | fbPageStatistics_facebookPage_statistics
      | igPageStatistics_instagramPage_statistics
      | liPageStatistics_linkedInPage_statistics,
  ) => {
    const data = {
      metrics: getFilteredMetrics(statistics),
      pageType: page._type,
    };

    setData(data);
    onSuccess?.(data);
  };

  const fb = useQuery<fbPageStatistics>(FB_PAGE_STATISTICS, {
    variables,
    skip: page._type !== TYPE.FACEBOOK,
    onCompleted: (data) => {
      if (data.facebookPage?.statistics) {
        handleOnCompleted(data.facebookPage?.statistics);
      }
    },
  });

  const ig = useQuery<igPageStatistics>(IG_PAGE_STATISTICS, {
    variables,
    skip: page._type !== TYPE.INSTAGRAM,
    onCompleted: (data) => {
      if (data.instagramPage?.statistics) {
        handleOnCompleted(data.instagramPage?.statistics);
      }
    },
  });

  const li = useQuery<liPageStatistics>(LI_PAGE_STATISTICS, {
    variables,
    skip: page._type !== TYPE.LINKEDIN,
    onCompleted: (data) => {
      if (data.linkedInPage?.statistics) {
        handleOnCompleted(data.linkedInPage?.statistics);
      }
    },
  });

  return {
    error: fb.error || ig.error || li.error,
    loading: fb.loading || ig.loading || li.loading,
    data,
  };
}
