import { Inspiration, InspirationDetail } from 'types/Inspiration';
import { get, post } from './client';
import { jsonToFormData } from 'utils/formData';
import config from 'config';
import dayjs from 'dayjs';
import { formatRawDate } from 'app/utils/date';

type GetInspirationParams = {
  startDate: string;
  endDate: string;
  pages: number[];
};

type SaveParams = {
  id?: number | null;
  pageId: number;
  date: Date;
  text: string;
  isRecurring?: boolean;
};

const InspirationsApi = {
  async get(
    params: GetInspirationParams,
  ): Promise<Record<string, Inspiration[]>> {
    if (params.pages.length === 0) {
      return {};
    }

    const inspirationsRes = await get<{ data: Inspiration[] }>('/inspiration', {
      baseURL: config.API_SERVICE_ENDPOINT,
      params: {
        startDate: params.startDate,
        endDate: params.endDate,
        pages: JSON.stringify(params.pages),
      },
    }).then((res) => res.data);

    const regular = inspirationsRes.filter(
      (inspiration) => !inspiration.is_recurring,
    );
    const recurring = inspirationsRes.filter(
      (inspiration) => inspiration.is_recurring,
    );
    const recurringWithCorrectDate: Inspiration[] = [];

    const startYear = dayjs(params.startDate).year();
    const endYear = dayjs(params.endDate).year();

    recurring.forEach((inspiration) => {
      for (let i = startYear; i <= endYear; i++) {
        const recurringWithYearOfSelection = dayjs(inspiration.date).year(i);

        const isWithinDateSelection = recurringWithYearOfSelection.isBetween(
          params.startDate,
          params.endDate,
          'day',
          '[]',
        );

        if (isWithinDateSelection) {
          recurringWithCorrectDate.push({
            ...inspiration,
            date: formatRawDate(recurringWithYearOfSelection),
          });
        }
      }
    });

    const inspirationsByDate: Record<string, Inspiration[]> = {};

    [...regular, ...recurringWithCorrectDate].forEach((inspiration) => {
      const date = inspiration.date;

      if (Array.isArray(inspirationsByDate[date])) {
        inspirationsByDate[date].push(inspiration);
      } else {
        inspirationsByDate[date] = [inspiration];
      }
    });

    return inspirationsByDate;
  },
  async getDetail(id: number): Promise<InspirationDetail> {
    return get(`/inspiration/${id}`, {
      baseURL: config.API_SERVICE_ENDPOINT,
    });
  },
  save(params: SaveParams) {
    const data: Record<string, string> = {
      text: params.text,
      isRecurring: params.isRecurring ? '1' : '0',
      filter: `p${params.pageId}`,
      date: formatRawDate(params.date),
    };

    if (params.id) {
      data.id = `${params.id}`;
    }

    return post('/inspirations/ajaxSaveInspiration', jsonToFormData(data));
  },
  delete(id: number) {
    return post('/inspirations/ajaxDeleteInspiration', jsonToFormData({ id }));
  },
};

export default InspirationsApi;
