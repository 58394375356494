import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import { mergeTailwindClasses } from '@kontentino/ui';
import clsx from 'clsx';

type Props = {
  className?: string;
};

const StandardPostPreviewSkeleton: FC<Props> = ({ className }) => {
  return (
    <div
      className={mergeTailwindClasses(
        clsx(
          'tw-flex tw-h-[680px] tw-w-[514px] tw-flex-col tw-overflow-hidden tw-rounded-md tw-border tw-border-solid tw-border-grayscale-20 tw-bg-white',
          className,
        ),
      )}
    >
      <div className="tw-relative tw-flex-1 tw-overflow-hidden">
        <Skeleton
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: 0,
          }}
        />
      </div>
      <div className="tw-p-4">
        <div className="tw-flex tw-items-center">
          <div>
            <Skeleton width={40} height={40} circle />
          </div>
          <div className="tw-ml-4 tw-flex tw-flex-col">
            <Skeleton width={70} height={16} />
            <Skeleton width={100} height={16} />
          </div>
          <div className="tw-ml-auto">
            <Skeleton width={24} height={24} />
          </div>
        </div>
        <Skeleton width="100%" height={16} className="tw-mt-4" />
        <div className="tw-my-4 tw-h-px tw-bg-grayscale-20" />
        <div className="tw-flex tw-items-center">
          <div>
            <Skeleton width={70} height={16} style={{ marginLeft: 8 }} />
          </div>
          <div className="tw-ml-auto">
            <Skeleton width={104} height={20} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StandardPostPreviewSkeleton;
