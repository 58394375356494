import React, { FC } from 'react';

const GraphGradient: FC = () => {
  return (
    <defs>
      <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor="#505FEE" stopOpacity={0.3} />
        <stop offset="103.47%" stopColor="#505FEE" stopOpacity={0.05} />
      </linearGradient>
      <linearGradient id="colorComparedValue" x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor="#99E7FC" stopOpacity={0.3} />
        <stop offset="103.47%" stopColor="#99E7FC" stopOpacity={0.05} />
      </linearGradient>
    </defs>
  );
};

export default GraphGradient;
