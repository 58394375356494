import React, { FC, useMemo } from 'react';
import BestPostsForMetric from 'app/modules/insights/modules/postsPerformance/components/bestPostsForMetrics/BestPostsForMetric';
import { getMetricsForPageType } from '../utils/metricProperty';
import { MetricProperty } from 'app/modules/insights/types/MetricProperty';
import { Page } from 'app/modules/insights/types/Page';
import BestPostsHasFewerCountAlert from 'app/modules/insights/modules/postsPerformance/components/bestPostsForMetrics/BestPostsHasFewerCountAlert';
import { Dayjs } from 'dayjs';

type Props = {
  page: Page;
  startDate: Dayjs;
  endDate: Dayjs;
  types: number[];
  onMetricShowMore?: (metric: MetricProperty) => void;
};

const BestPostsForMetrics: FC<Props> = ({ onMetricShowMore, ...props }) => {
  const queryableMetrics = useMemo(
    () =>
      Object.values(getMetricsForPageType(props.page._type)).filter(
        (metric) => metric.queryParameter,
      ),
    [props.page._type],
  );

  return (
    <>
      <BestPostsHasFewerCountAlert {...props} metric={queryableMetrics[0]} />
      {queryableMetrics.map((metric, index) => (
        <BestPostsForMetric
          {...props}
          marginTop={index === 0 ? 16 : 32}
          onShowMore={
            onMetricShowMore ? () => onMetricShowMore(metric) : undefined
          }
          metric={metric}
          key={metric.key}
        />
      ))}
    </>
  );
};

export default BestPostsForMetrics;
