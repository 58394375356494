import { Badge, BadgeProps } from '@kontentino/ui';
import clsx from 'clsx';
import React from 'react';

const FeatureBadge = React.forwardRef<HTMLSpanElement, BadgeProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <Badge
        {...props}
        ref={ref}
        className={clsx(
          'tw-inline-flex tw-h-5 tw-bg-grayscale-10 tw-text-grayscale-100 group-hover:tw-text-inherit',
          className,
        )}
      >
        {children}
      </Badge>
    );
  },
);
export default FeatureBadge;
