import { StandardPostDetailed } from 'app/types/StandardPostDetailed';
import { restrictedTypesByMetric } from '../constants/postTypeFilter';

export function shouldMetricShow(
  pageType: number,
  key: string,
  post: StandardPostDetailed,
) {
  const restrictedTypesForMetric = restrictedTypesByMetric[pageType]?.[key];

  return (
    !restrictedTypesForMetric || restrictedTypesForMetric.includes(post.type)
  );
}
