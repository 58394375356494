import React, { FC, useContext, useEffect, useState } from 'react';
import { Comment, CommentReactionSummary } from 'app/modules/comments/types';
import { useAddReaction } from '../hooks/useAddReaction';
import {
  ReactionMutationProps,
  useToggleReaction,
} from '../commentsListItem/hooks/useToggleReaction';
import { Emoji } from 'app/components/EmojiPicker';
import { usePostCommunicationContext } from '../../../PostCommunicationProvider';

type ContextValue = {
  reactions: CommentReactionSummary[];
  setReactions: (reactions: CommentReactionSummary[]) => void;
  onToggle: (params: ReactionMutationProps) => void;
  isToggleLoading: boolean;
  onEmojiSelect: (emoji: Emoji) => void;
};

const ReactionSummaryContext = React.createContext<ContextValue | undefined>(
  undefined,
);

type Props = {
  comment: Comment;
  children: React.ReactNode;
};

export const ReactionSummaryProvider: FC<Props> = ({ comment, children }) => {
  const [reactions, setReactions] = useState<CommentReactionSummary[]>(
    comment.reactions_summary,
  );
  const { postId } = usePostCommunicationContext();

  const { onAddReaction } = useAddReaction({ comment, postId });
  const { onToggle, isLoading: isToggleLoading } = useToggleReaction({
    comment,
    postId,
  });

  useEffect(() => {
    setReactions(comment.reactions_summary);
  }, [comment.reactions_summary]);

  const onEmojiSelect = (emoji: Emoji) => {
    const prevState = [...reactions];

    const createNewReactionSummary = (emoji: Emoji) => {
      return {
        content: emoji.native,
        count: 1,
        users: [],
        my_reaction: {
          id: -1,
        },
      };
    };

    const addReaction = (reaction: CommentReactionSummary) => {
      return {
        ...reaction,
        count: reaction.count + 1,
        my_reaction: { id: -1 },
        users: [...reaction.users],
      };
    };

    setReactions((prevReactionSummaries) => {
      const existingIndex = prevReactionSummaries.findIndex(
        (e) => e.content === emoji.native,
      );

      if (existingIndex !== -1) {
        const updatedReactionSums = [...prevReactionSummaries];

        if (!updatedReactionSums[existingIndex]?.my_reaction?.id) {
          updatedReactionSums[existingIndex] = addReaction(
            updatedReactionSums[existingIndex],
          );
        }

        return updatedReactionSums;
      }

      return [...prevReactionSummaries, createNewReactionSummary(emoji)];
    });

    onAddReaction({
      reaction: emoji.native,
      onError: () => setReactions(prevState),
    });
  };

  const value = {
    reactions,
    setReactions,
    onToggle,
    isToggleLoading,
    onAddReaction,
    onEmojiSelect,
  };

  return (
    <ReactionSummaryContext.Provider value={value}>
      {children}
    </ReactionSummaryContext.Provider>
  );
};

export const useReactionSummary = () => {
  const context = useContext(ReactionSummaryContext);

  if (!context) {
    throw new Error('Must be used within ReactionSummaryProvider');
  }

  return context;
};
