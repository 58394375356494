/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { ModuleStatus } from 'types/ModuleStatus';
import { MODULE_STATUS } from 'constants/modules';
import { openPopup } from 'utils/url';
import {
  SocialLoginError,
  SocialLoginMessageTypes,
  SocialLoginUrls,
} from 'constants/socialLogin';
import Logger from 'utils/logger';
import {
  AuthorizationDoneResponse,
  AuthorizationErrorResponse,
  AuthorizationOptions,
} from 'types/SocialLogin';
import { TYPE } from '@kontentino/kontentino-constants/Pages';

type Props<T> = {
  onAuthorized?: (data: T) => void;
  onError?: (data: AuthorizationErrorResponse) => void;
} & AuthorizationOptions;

const allowedMessages: string[] = Object.values(SocialLoginMessageTypes);

const channelName = 'social-login';

export function useSocialLogin<T extends AuthorizationDoneResponse>({
  pageType,
  scopes,
  onError,
  onAuthorized,
}: Props<T> = {}) {
  const [status, setStatus] = useState<ModuleStatus>(MODULE_STATUS.Idle);
  const [authorization, setAuthorization] = useState<T>();
  const [error, setError] = useState<AuthorizationErrorResponse>();
  const popup = useRef<Window | null>(null);
  const broadcastChannel = useRef(new BroadcastChannel(channelName));
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);
  const authorizationOptions = useRef<AuthorizationOptions>({
    pageType,
    scopes,
  });

  useEffect(() => {
    const eventMessageListener = (
      event: MessageEvent<{
        data: T | { reason: string };
        type: string;
      }>,
    ) => {
      const { data } = event;

      try {
        if (
          !allowedMessages.includes(data.type) ||
          status !== MODULE_STATUS.Loading
        ) {
          return;
        }

        const authorizationStatus: string | undefined =
          data.type.match(/(done|error)/)?.[0];

        if (authorizationStatus !== 'done') {
          throw new Error('Social login failed');
        }

        const authorizationSucceededData = {
          ...(data.data as T),
          authorizationOptions: authorizationOptions.current,
        };

        setAuthorization(authorizationSucceededData);
        setStatus(MODULE_STATUS.Succeeded);
        popup.current?.close();
        onAuthorized?.(authorizationSucceededData);
      } catch (e) {
        const error = {
          reason:
            (data.data as { reason?: string })?.reason ||
            (e instanceof Error && e.message) ||
            SocialLoginError.unableToAuthorize,
          authorizationOptions: authorizationOptions.current,
        };

        Logger.error(error);
        onError?.(error);
        setError(error);
        setStatus(MODULE_STATUS.Failed);
      }
    };

    broadcastChannel.current.onmessage = eventMessageListener;
  }, [status]);

  useEffect(() => {
    const popupClosedListener = () => {
      const isWatchDisabled =
        authorizationOptions.current.pageType &&
        (
          [
            TYPE.THREADS,
            TYPE.TWITTER,
            TYPE.GOOGLE_MY_BUSINESS,
            TYPE.PINTEREST,
          ] as number[]
        ).includes(authorizationOptions.current.pageType);

      if (isWatchDisabled) return;

      const isClosedWhileLoading =
        (popup.current?.closed || popup.current === null) &&
        status === MODULE_STATUS.Loading;

      if (isClosedWhileLoading) {
        const error = {
          reason: SocialLoginError.socialLoginClosed,
          authorizationOptions: authorizationOptions.current,
        };

        Logger.error(SocialLoginError.socialLoginClosed);
        onError?.({
          reason: SocialLoginError.socialLoginClosed,
          authorizationOptions: authorizationOptions.current,
        });
        setError(error);
        setStatus(MODULE_STATUS.Failed);
      }
    };

    interval.current = setInterval(popupClosedListener, 1000);

    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, [status]);

  useEffect(() => {
    return () => {
      popup.current?.close();
      broadcastChannel.current.close();
    };
  }, [popup, broadcastChannel]);

  useEffect(() => {
    overrideOptions({ pageType, scopes });
  }, [pageType, scopes]);

  function overrideOptions(optionsOverride?: AuthorizationOptions) {
    if (optionsOverride?.pageType) {
      authorizationOptions.current.pageType = optionsOverride?.pageType;
    }
    if (optionsOverride?.scopes) {
      authorizationOptions.current.scopes = optionsOverride?.scopes;
    }
  }

  function authorize(optionsOverride?: AuthorizationOptions) {
    try {
      overrideOptions(optionsOverride);

      if (!authorizationOptions.current.pageType) {
        throw new Error('Page type not defined');
      }

      setStatus(MODULE_STATUS.Loading);
      setAuthorization(undefined);
      setError(undefined);

      const scopes = authorizationOptions.current?.scopes?.join();

      popup.current = openPopup(
        `${SocialLoginUrls[authorizationOptions.current.pageType]?.(scopes)}`,
      );
    } catch (e) {
      const error = {
        reason:
          (e instanceof Error && e.message) ||
          SocialLoginError.unableToAuthorize,
        authorizationOptions: authorizationOptions.current,
      };

      Logger.error(error);
      onError?.(error);
      setError(error);
      setStatus(MODULE_STATUS.Failed);
    }
  }

  function isLoading() {
    return status === MODULE_STATUS.Loading;
  }

  function isError() {
    return status === MODULE_STATUS.Failed;
  }

  return {
    authorize,
    status,
    authorization,
    error,
    isLoading: isLoading(),
    isError: isError(),
  };
}
