import { LightBoxInstance } from '@kontentino/ui';
import React, { FC } from 'react';
import CommentsHyperText from 'app/modules/posts/modules/post/components/postCommunication/comments/commentsList/CommentsHyperText';
import { Comment } from 'app/modules/comments/types';
import CommentWorkflow from 'app/modules/posts/modules/post/components/postCommunication/comments/commentsList/CommentWorkflow';
import CommentAttachments from '../CommentAttachments';
import CommentDeleted from '../CommentDeleted';
import { HyperTextOptions } from '@kontentino/kontentino-post-previews/dist/components/hyperText/HyperText';

type Props = {
  comment: Comment;
  enableRespondToApprovalRequest?: boolean;
  hideUserInteractiveComponents?: boolean;
  onWorkflowsIsVisibleChange?: (isVisible: boolean) => void;
  lightbox?: React.MutableRefObject<LightBoxInstance | undefined>;
  allImages?: {
    id: number;
    src: string | undefined;
    thumb: string | undefined;
    alt: string;
  }[];
  textOptions?: HyperTextOptions;
  colorVariant?: 'primary' | 'purple';
  disableMentionStyle?: boolean;
};

const CommentsListItemContent: FC<Props> = ({
  comment,
  enableRespondToApprovalRequest,
  hideUserInteractiveComponents,
  onWorkflowsIsVisibleChange,
  lightbox,
  allImages,
  textOptions,
  colorVariant = 'primary',
  disableMentionStyle,
}) => {
  if (comment.deleted_at) {
    return (
      <CommentDeleted
        isAuthor={comment.user.its_me}
        colorVariant={colorVariant}
        disableMentionStyle={disableMentionStyle}
      />
    );
  }

  return (
    <>
      <CommentsHyperText
        isCurrentUser={comment.user.its_me}
        colorVariant={colorVariant}
        disableMentionStyle={disableMentionStyle}
        mentionedUsers={comment.mentioned_users ?? []}
        options={textOptions}
      >
        {comment.content}
      </CommentsHyperText>
      {lightbox &&
        allImages &&
        comment.attachments &&
        comment.attachments.length !== 0 &&
        !hideUserInteractiveComponents && (
          <CommentAttachments
            attachments={comment.attachments}
            onViewImageClick={(id) => {
              lightbox.current?.openGallery(
                allImages.findIndex((image) => image.id === id),
              );
            }}
          />
        )}
      {!hideUserInteractiveComponents && (
        <CommentWorkflow
          comment={comment}
          onIsVisibleChange={onWorkflowsIsVisibleChange}
          enableRespondToApprovalRequest={enableRespondToApprovalRequest}
        />
      )}
    </>
  );
};

export default CommentsListItemContent;
