import { FC, useState } from 'react';
import Placeholder from 'components/date-range-picker/Placeholder';
import DateRangePicker from 'components/DateRangePicker';
import { DATE_PRESETS } from '@kontentino/kontentino-constants/Presets';
import { useOutsideClick } from 'utils/hooks/useOutsideClick';
import DateUtils, { formatDate } from 'app/utils/date';
import { NumberOfMonthsType } from 'components/date-range-picker/DateRangePicker';
import i18n from 'i18n';
import { DateRangeOnChangeParams } from 'types/Date';
import { getDatesWithConstraints } from 'components/date-range-picker/utils/libs';
import clsx from 'clsx';
import { Dayjs } from 'dayjs';

export type DatePickerDataNames = {
  presetsList?: string;
  dateRangePicker?: string;
  footer?: {
    cancelButton?: string;
    submitButton?: string;
  };
};

type Props = {
  onDateChange(values: DateRangeOnChangeParams): void;
  startDate?: Dayjs | null;
  endDate?: Dayjs | null;
  maxEndDate?: Dayjs | null;
  className?: string | null;
  placeholderClassName?: string | null;
  numberOfMonths?: NumberOfMonthsType;
  presets?: string[];
  withPresets?: boolean;
  shouldSubmitOnRangeSelect?: boolean;
  shouldPreselect?: boolean;
  autoSelectPeriod?: number;
  blockFutureDays?: boolean;
  placeholderDataName?: string;
  dataName?: DatePickerDataNames;
  dataCy?: string;
};

const DEFAULT_PRESETS = Object.keys(DATE_PRESETS.PRESETS_FUTURE);

const DatePicker: FC<Props> = ({
  onDateChange,
  startDate,
  endDate,
  className,
  placeholderClassName,
  numberOfMonths = 2,
  presets,
  withPresets = true,
  shouldSubmitOnRangeSelect = true,
  shouldPreselect = true,
  autoSelectPeriod,
  maxEndDate,
  placeholderDataName,
  dataName,
  dataCy,
}) => {
  const [preselected, setPreselected] = useState<DateRangeOnChangeParams>({
    startDate: startDate ?? null,
    endDate: endDate ?? null,
  });
  const [isShown, setIsShown] = useState(false);
  const { elementRef, addListener, removeListener } =
    useOutsideClick<HTMLDivElement>(onHide);

  function onToggle() {
    if (isShown) {
      onHide();
    } else {
      onShow();
    }
  }

  function onSubmit(dates: DateRangeOnChangeParams) {
    onDateChange(getDatesWithConstraints(dates, { maxEndDate }));
    onHide();
  }

  function onShow() {
    setIsShown(true);
    addListener();
  }

  function onHide() {
    setIsShown(false);
    removeListener();
  }

  function getPlaceholderLabel() {
    const start = formatDate(
      DateUtils.toDateString(
        shouldPreselect ? preselected.startDate ?? null : startDate ?? null,
      ),
    );
    const end = formatDate(
      DateUtils.toDateString(
        shouldPreselect ? preselected.endDate ?? null : endDate ?? null,
      ),
    );

    if ([start, end].every((item) => item === i18n.invalidDate)) {
      return 'Select date range...';
    }

    return `${start === i18n.invalidDate ? i18n.notSelected : start} - ${
      end === i18n.invalidDate ? i18n.notSelected : end
    }`;
  }

  const getPresets = () => {
    if (withPresets && !autoSelectPeriod) {
      return presets?.length ? presets : DEFAULT_PRESETS;
    }

    return [];
  };

  const preselect = (dates: DateRangeOnChangeParams) => {
    if (shouldSubmitOnRangeSelect && dates.startDate && dates.endDate) {
      onDateChange(getDatesWithConstraints(dates, { maxEndDate }));
    }

    if (shouldPreselect) {
      setPreselected(getDatesWithConstraints(dates, { maxEndDate }));
    }
  };

  return (
    <div
      ref={elementRef}
      className={clsx('tw-relative tw-inline-flex tw-flex-col', className)}
    >
      <Placeholder
        onClick={onToggle}
        label={getPlaceholderLabel()}
        isActive={isShown}
        className={placeholderClassName}
        dataName={placeholderDataName}
        dataCy={dataCy}
      />
      {isShown && (
        <div
          className={clsx(
            'tw-absolute tw-left-0 tw-top-[calc(100%+1px)] tw-z-10 tw-overflow-hidden tw-rounded-md tw-shadow-sm',
          )}
        >
          <DateRangePicker
            onSubmit={onSubmit}
            onCancel={onHide}
            presets={getPresets()}
            initialStartDate={startDate}
            initialEndDate={endDate}
            numberOfMonths={numberOfMonths}
            onPreselect={preselect}
            autoSelectPeriod={autoSelectPeriod}
            maxEndDate={maxEndDate}
            dataName={dataName}
          />
        </div>
      )}
    </div>
  );
};

export default DatePicker;
