import { post } from 'api/client';
import { jsonToFormData } from 'utils/formData';
import { BrowserStorage } from 'utils/browserStorage';
import config from 'config';

type TokenResponse = {
  token: string;
};

type LoginResponse = {
  token: string;
  relocate: string;
};

export const AuthApi = {
  async getAuthToken(): Promise<string> {
    let authToken;

    const localStorageToken = BrowserStorage.get(BrowserStorage.keys.AuthToken);

    if (localStorageToken) {
      authToken = localStorageToken;
    } else {
      const response = (await post(
        '/user/ajaxRetrieveUserJWTToken',
      )) as TokenResponse;
      authToken = response.token;
    }

    return authToken;
  },
  async login(params: {
    email: string;
    password: string;
  }): Promise<LoginResponse> {
    const response: LoginResponse = await post(
      '/user/ajaxLogin',
      jsonToFormData({ login: params.email, password: params.password }),
    );

    BrowserStorage.set(BrowserStorage.keys.AuthToken, response.token);

    return response;
  },
  logout() {
    BrowserStorage.logoutClear();
    return post('/user/logout');
  },
  resetPasswordCode(params: { email: string }) {
    return post('/retrieve_password_recover_code', params, {
      baseURL: config.PUBLIC_API_SERVICE_ENDPOINT,
    });
  },
  submitResetPasswordCode(params: { code: string }): Promise<{
    status: boolean;
    hash: string;
  }> {
    return post('/submit_password_recover_code', params, {
      baseURL: config.PUBLIC_API_SERVICE_ENDPOINT,
    });
  },
  submitNewPassword(params: { password: string; hash: string }) {
    return post('/submit_user_password', params, {
      baseURL: config.PUBLIC_API_SERVICE_ENDPOINT,
    });
  },
};
