import { AggregatedMetric } from 'app/modules/insights/types/PagePerformance';
import React, { FC } from 'react';
import MetricBadge from '../../MetricBadge';

type Props = MarkOptional<AggregatedMetric, 'label' | 'metricKey'> & {
  isCompared?: boolean;
  dataName?: string;
};

const AggregatedMetricValue: FC<Props> = ({
  label,
  value,
  difference,
  dataName,
}) => {
  return (
    <div className="tw-flex tw-flex-col" data-name={dataName}>
      {label && <div className="tw-text-sm tw-text-grayscale-100">{label}</div>}
      <div className="tw-flex tw-items-center tw-gap-[5px]">
        <div className="tw-text-xl tw-font-semibold">{value}</div>
        {typeof difference === 'number' && (
          <MetricBadge percentage={difference} />
        )}
      </div>
    </div>
  );
};

export default AggregatedMetricValue;
