import UserPermissionGate from 'components/UserPermissionGate';
import { modalRoutes } from 'constants/routes';
import { UserPermission } from 'constants/userPermission';
import { FC, lazy } from 'react';
import AdsPlannerWelcomeModal from 'app/components/modals/welcome/AdsPlannerWelcomeModal';
import AssignMultipleTasksPage from 'app/modules/adsPlanner/modules/assignMultipleTasks';
import ModalRoute from 'context/ModalRouter';
import SendForClientApprovalRoute from 'app/modules/adsPlanner/timeSavers/modules/sendForClientApproval';

const CreateCampaign = lazy(() => import('pages/adsPlanner/campaign/Create'));
const EditCampaign = lazy(() => import('pages/adsPlanner/campaign/Edit'));
const CreateAdSet = lazy(() => import('pages/adsPlanner/adSet/Create'));
const EditAdSet = lazy(() => import('pages/adsPlanner/adSet/Edit'));
const AdSetDuplicate = lazy(
  () => import('pages/adsPlanner/adSet/AdSetDuplicate'),
);
const ImportRemoteCampaigns = lazy(
  () => import('pages/adsPlanner/ImportRemoteCampaigns'),
);
const Ad = lazy(() => import('pages/adsPlanner/Ad'));
const DuplicateAds = lazy(() => import('pages/adsPlanner/ad/DuplicateAds'));
const MoveAds = lazy(() => import('pages/adsPlanner/ad/MoveAds'));
const AddAdaccount = lazy(
  () => import('pages/adsPlanner/adAccount/AddAdAccount'),
);

const AdsPlannerModals: FC = () => (
  <>
    <UserPermissionGate scopes={UserPermission.CAMPAIGN_MANAGE}>
      <ModalRoute
        path={modalRoutes.WELCOME}
        component={AdsPlannerWelcomeModal}
      />
      <ModalRoute
        path={modalRoutes.CREATE_CAMPAIGN}
        component={CreateCampaign}
      />
      <ModalRoute path={modalRoutes.CAMPAIGN.EDIT} component={EditCampaign} />
      <ModalRoute
        path={modalRoutes.IMPORT_REMOTE_FACEBOOK_CAMPAIGNS}
        component={ImportRemoteCampaigns}
      />
    </UserPermissionGate>
    <UserPermissionGate scopes={UserPermission.AD_SET_MANAGE}>
      <ModalRoute path={modalRoutes.CREATE_AD_SET} component={CreateAdSet} />
      <ModalRoute path={modalRoutes.AD_SET.EDIT} component={EditAdSet} />
      <ModalRoute
        path={modalRoutes.AD_SET.DUPLICATE}
        component={AdSetDuplicate}
      />
    </UserPermissionGate>
    <ModalRoute path={modalRoutes.AD} component={Ad} />
    <UserPermissionGate scopes={UserPermission.AD_ACCOUNT_MANAGE}>
      <ModalRoute path={modalRoutes.ADD_AD_ACCOUNT} component={AddAdaccount} />
    </UserPermissionGate>
    <UserPermissionGate scopes={UserPermission.USER_ASSIGN_TASK}>
      <ModalRoute
        path={modalRoutes.ASSIGN_MULTIPLE_TASKS}
        component={AssignMultipleTasksPage}
      />
    </UserPermissionGate>
    <ModalRoute path={modalRoutes.DUPLICATE_ADS} component={DuplicateAds} />
    <ModalRoute path={modalRoutes.MOVE_ADS} component={MoveAds} />
    <UserPermissionGate scopes={UserPermission.POST_SEND_FOR_CLIENT_APPROVAL}>
      <ModalRoute
        path={modalRoutes.SEND_FOR_CLIENT_APPROVAL}
        component={SendForClientApprovalRoute}
      />
    </UserPermissionGate>
    <UserPermissionGate scopes={UserPermission.POST_SEND_FOR_INTERNAL_APPROVAL}>
      <ModalRoute
        path={modalRoutes.SEND_FOR_INTERNAL_APPROVAL}
        component={SendForClientApprovalRoute}
      />
    </UserPermissionGate>
  </>
);

export default AdsPlannerModals;
