import { FC } from 'react';
import PostRowHeaderValueTitle from './PostRowHeaderValueTitle';

type Props = {};

const InstagramPostRowHeader: FC<Props> = () => {
  return (
    <div className="tw-flex tw-items-center tw-pb-2 tw-text-md tw-font-semibold">
      <span className="tw-w-[7%]">Page</span>
      <span className="tw-w-[15%]">Post</span>
      <div className="tw-flex tw-w-[78%] tw-items-center tw-justify-between">
        <PostRowHeaderValueTitle>Interactions</PostRowHeaderValueTitle>
        <PostRowHeaderValueTitle>Comments</PostRowHeaderValueTitle>
        <PostRowHeaderValueTitle>Likes</PostRowHeaderValueTitle>
      </div>
    </div>
  );
};

export default InstagramPostRowHeader;
