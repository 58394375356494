export const SUBSCRIPTION_MODAL_BENEFITS = {
  adsPlanner: [
    [
      'Create & plan ads and campaigns',
      'Collaborate with your team on ads',
      'See dynamic ad previews',
    ],
    [
      'Approve ads with one click only',
      'Manage multiple accounts and projects',
      ' …and more!',
    ],
  ],
  posts: [
    [
      'Internal/client workflow & collaboration',
      'Organized social media content',
      'Live desktop/mobile post previews',
    ],
    ['Scheduling and auto-publishing', 'Analytics & reporting', ' …and more!'],
  ],
  insights: [
    [
      'In depth page performance',
      'Overview of best performing posts',
      'Competition analysis',
    ],
    ['Customizable reports', 'Data export to PDF and CSV', ' …and more!'],
  ],
};
