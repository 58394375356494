import { create } from 'zustand';
import { PageStatisticsData } from 'app/modules/insights/types/PageStatistics';
import { MetricProperty } from 'app/modules/insights/types/MetricProperty';

type PostsPerformanceState = {
  metricHeader: MetricProperty | undefined;
  setMetricHeader(metric: MetricProperty | undefined): void;
  lastPageStatistics: PageStatisticsData | undefined;
  setLastPageStatistics: (data: PageStatisticsData) => void;
  getDisabledMetrics(): string[];
};

export const usePostsPerformanceState = create<PostsPerformanceState>(
  (set, get) => ({
    metricHeader: undefined,
    setMetricHeader: (metricHeader) => set({ metricHeader }),
    lastPageStatistics: undefined,
    setLastPageStatistics: (lastPageStatistics) => {
      set({ lastPageStatistics });
    },
    getDisabledMetrics() {
      return (
        get()
          .lastPageStatistics?.metrics.filter(([key, value]) => value === null)
          .map(([key]) => key) ?? []
      );
    },
  }),
);
