import React, { FC } from 'react';
import { useBestPostsForAllMetricsQuery } from 'app/modules/insights/modules/postsPerformance/hooks/useBestPostsForAllMetricsQuery';
import { MetricProperty } from 'app/modules/insights/types/MetricProperty';
import MetricHeader from 'app/modules/insights/modules/postsPerformance/components/MetricHeader';
import { getMedalRankFromIndex } from 'app/modules/insights/utils/getMedalRankFromIndex';
import SimpleGrid from 'app/components/SimpleGrid';
import StandardPostPreview from 'app/modules/insights/components/StandardPostPreview';
import ArrayUtils from 'app/utils/array';
import StandardPostPreviewSkeleton from 'app/modules/insights/components/StandardPostPreviewSkeleton';
import PostsUnavailable from 'app/modules/insights/modules/postsPerformance/components/bestPostsForMetrics/PostsUnavailable';
import { Page } from 'app/modules/insights/types/Page';
import PostPreviewPlaceholder from './PostPreviewPlaceholder';
import InsightsConfig from 'app/config/insights';
import { getMetricValue } from 'app/modules/insights/utils/getMetricValue';
import { Dayjs } from 'dayjs';

type Props = {
  page: Page;
  startDate: Dayjs;
  endDate: Dayjs;
  metric: MetricProperty;
  types: number[];
  onShowMore?: () => void;
  marginTop: number;
};

const BestPostsForMetric: FC<Props> = (props) => {
  const { posts, loading, error } = useBestPostsForAllMetricsQuery(props);

  if (!loading && (posts === undefined || posts.length === 0)) return null;

  return (
    <div
      style={{ breakInside: 'avoid', marginTop: props.marginTop }}
      data-name={`insights_posts-performance_top3-${props.metric.key}`}
    >
      <div className="tw-mb-6 tw-flex tw-items-center">
        <MetricHeader metric={props.metric} />
        {props.onShowMore && (
          <div
            onClick={props.onShowMore}
            className="tw-ml-auto tw-cursor-pointer tw-text-md tw-font-medium tw-text-primary-100"
            data-name={`insights_posts-performance_showMore-${props.metric.key}`}
          >
            Show more
          </div>
        )}
      </div>
      {posts && (
        <>
          {posts.length > 0 && (
            <SimpleGrid columns={3} gap={16} className="tw-mb-4">
              {posts.map(({ post, metrics }, index) => (
                <StandardPostPreview
                  key={`post-${index}`}
                  post={post}
                  medalRank={getMedalRankFromIndex(index)}
                  metric={{
                    ...props.metric,
                    value: getMetricValue(metrics, props.metric),
                  }}
                  classNames={{
                    root: 'tw-h-[432px] tw-w-full',
                  }}
                />
              ))}
              {ArrayUtils.generate(
                InsightsConfig.MAX_POSTS_PER_METRIC - posts.length,
              ).map((value) => (
                <PostPreviewPlaceholder
                  key={`post-preview-placeholder-${value}`}
                />
              ))}
            </SimpleGrid>
          )}
        </>
      )}
      {error && (
        <PostsUnavailable variant="danger">
          {error.message ??
            `We were unable to get posts available for this metric and selected
          time frame in your profile.`}
        </PostsUnavailable>
      )}
      {loading && (
        <SimpleGrid columns={3} gap={16} className="tw-mb-4">
          {ArrayUtils.generate(3).map((item) => (
            <StandardPostPreviewSkeleton
              key={`skeleton-${item}`}
              className="tw-h-[432px] tw-w-full"
            />
          ))}
        </SimpleGrid>
      )}
    </div>
  );
};

export default BestPostsForMetric;
