import { useMutation, useQuery, useQueryClient } from 'react-query';
import { queryKey } from 'constants/queryKey';
import { LinkShortenerApi } from 'api/linkShortener';
import { useToast } from 'app/hooks/useToast';
import i18n from 'i18n';
import { copyTextToClipboard } from '@kontentino/kontentino-utils/clipboard';
import Popup from 'utils/popup';
import { useState } from 'react';
import { ShortenLink } from 'types/LinkShortener';

type Props = {};

export function useLinkShortenerContainer(props: Props) {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { isLoading, data } = useQuery(
    [queryKey.shortenLinks],
    LinkShortenerApi.allLinks,
  );
  const deleteLink = useMutation(LinkShortenerApi.delete, {
    onSuccess() {
      queryClient.invalidateQueries([queryKey.shortenLinks]);
      toast('Shortened link successfully removed.');
    },
  });
  const [filter, setFilter] = useState('');
  const [hashtagToEdit, setShortenLinkToEdit] = useState<
    null | undefined | ShortenLink
  >();
  const [linkStatsToSee, setLinkStatsToSee] = useState<string>();

  async function copyToClipboard(hashtags: string) {
    try {
      await copyTextToClipboard(hashtags);
      toast(i18n.copiedToClipboard);
    } catch (e) {
      toast(i18n.somethingWentWrong, 'error');
    }
  }

  async function remove(linkId: string) {
    const { isConfirmed } = await Popup.confirm();

    if (isConfirmed) {
      deleteLink.mutate(linkId);
    }
  }

  return {
    isLoading,
    shortenLinks: data,
    copyToClipboard,
    remove,
    hashtagToEdit,
    setShortenLinkToEdit,
    linkIdStatsToSee: linkStatsToSee,
    setLinkIdStatsToSee: setLinkStatsToSee,
    filter,
    setFilter,
  };
}
