import { NavigationItemKey } from 'components/navigation/utils/libs';
import { isUSDateTimeFormat } from '@kontentino/kontentino-utils/date';
import {
  faCircleDollar,
  faFileLines,
  faGrid2,
  faImage,
  faImagePolaroidUser,
  faObjectsColumn,
  faPaperPlane,
} from '@fortawesome/pro-regular-svg-icons';

type SharedBy = {
  name: string;
  email: string;
  accountUsername: string;
};

export const PublicUtils = {
  publicMock: {
    accountPlan: {
      label: 'trial',
      pageCount: 10,
      userCount: 20,
      freeUserCount: 5,
    },
    user: {
      id: 0,
      name: 'Guest',
      role: 'admin',
      avatar: null,
      userMeridianTime: !isUSDateTimeFormat(),
    } as const,
    menuItems: [
      {
        key: NavigationItemKey.Dashboard,
        label: 'Dashboard',
        icon: faObjectsColumn,
      },
      {
        key: NavigationItemKey.Posts,
        label: 'Posts',
        icon: faImagePolaroidUser,
      },
      {
        featureTitle: 'Ads planner',
        key: NavigationItemKey.AdsPlanner,
        label: 'Ads planner',
        icon: faCircleDollar,
      },
      {
        featureTitle: 'Engage',
        key: NavigationItemKey.Engage,
        label: 'Engage',
        icon: faPaperPlane,
      },
      {
        featureTitle: 'Analytics & Reporting:',
        key: NavigationItemKey.Insights,
        label: 'Insights',
        icon: faGrid2,
      },
      {
        featureTitle: 'Analytics & Reporting:',
        key: NavigationItemKey.Reports,
        label: 'Reports',
        icon: faFileLines,
      },
      {
        key: NavigationItemKey.Albums,
        label: 'Media Library',
        icon: faImage,
      },
    ],
  },
  setSharedData: (user: SharedBy) => {
    PublicUtils.sharedBy = user;
  },
  getSharedData: () => PublicUtils.sharedBy,
  sharedBy: {
    name: '',
    email: '',
    accountUsername: '',
  } as SharedBy,
};

export default PublicUtils;
