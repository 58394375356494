import { mergeTailwindClasses } from '@kontentino/ui';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  isActiveMenu?: boolean;
  className?: string;
}

export const VerticalMenuSecondaryItemWrapper: FC<Props> = ({
  children,
  isActiveMenu,
  className,
  ...rest
}) => (
  <div
    {...rest}
    className={mergeTailwindClasses(
      clsx(
        'tw-group tw-relative tw-flex tw-h-8 tw-items-center tw-gap-x-2 tw-overflow-hidden tw-rounded-sm tw-px-2 tw-py-[6px] hover:tw-bg-primary-10 hover:!tw-text-primary-100',
        {
          'tw-bg-primary-10 tw-text-primary-100': isActiveMenu,
        },
        className,
      ),
    )}
  >
    {children}
  </div>
);
