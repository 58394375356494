import { Button, ButtonProps } from '@kontentino/ui';
import { Action, ActionProps } from 'actions';
import UserPermissionGate from 'components/UserPermissionGate';
import React from 'react';

export type ActionButtonProps<TAction extends Action> = ActionProps<TAction> &
  ButtonProps;

export const ActionButton = function <TAction extends Action>({
  action,
  actionParams,
  children,
  ...props
}: ActionButtonProps<TAction>) {
  return (
    <UserPermissionGate scopes={action.scopes}>
      <Button onClick={() => action.perform?.(actionParams)} {...props}>
        {children ?? action.label}
      </Button>
    </UserPermissionGate>
  );
};
