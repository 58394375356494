import { FC, useCallback, useEffect, useState } from 'react';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';
import { postTypeFilterOptions } from 'app/modules/insights/modules/postsPerformance/constants/postTypeFilter';
import { pluralize } from 'utils/lang';
import { Checkbox, Chip, DropdownMenu, Icon, IconButton } from '@kontentino/ui';
import { ActiveState } from 'app/modules/insights/hooks/useActiveState';
import { FilterState } from 'app/modules/insights/hooks/useFilterState';
import { getIsMetricDisabled } from '../../../utils/getIsMetricDisabled';

type Props = {
  activeState: ActiveState;
  filterState: FilterState;
  postTypesCount?: Record<string, number>;
  onClearSelectedTypes(): void;
  onSelectedTypeChange(type: number): void;
};

const PostTypeSelect: FC<Props> = ({
  activeState,
  filterState,
  postTypesCount,
  onClearSelectedTypes,
  onSelectedTypeChange,
}) => {
  const [open, setOpen] = useState(false);
  const deselectUnsupportedPostTypes = useCallback(() => {
    filterState.selectedPostTypes.forEach((postType) => {
      const shouldDeselect = getIsMetricDisabled({
        optionType: postType,
        pageType: activeState.page!._type,
        metric: activeState.metric.key,
      });

      if (shouldDeselect) {
        onSelectedTypeChange(postType);
      }
    });
  }, [
    activeState.metric.key,
    activeState.page,
    onSelectedTypeChange,
    filterState.selectedPostTypes,
  ]);

  useEffect(() => {
    deselectUnsupportedPostTypes();
  }, [filterState.selectedMetric, deselectUnsupportedPostTypes]);

  return (
    <span className="tw-relative">
      <DropdownMenu.Root
        open={open}
        onOpenChange={(open) => {
          if (!open) return;
          setOpen(open);
        }}
      >
        <DropdownMenu.Trigger>
          <IconButton
            variant="secondary"
            className="tw-h-[36px]"
            icon={
              <Icon
                icon={faFilter}
                data-name="insights-posts-performance-filters-post-type"
              />
            }
            data-name="insights_posts-performance_filterBar-postType"
          />
        </DropdownMenu.Trigger>
        <DropdownMenu.Content
          className="tw-z-10"
          onEscapeKeyDown={() => setOpen(false)}
          onInteractOutside={() => setOpen(false)}
        >
          {activeState.page?._type &&
            postTypeFilterOptions[activeState.page._type]?.map((option) => {
              const selected = filterState.selectedPostTypes.includes(
                option.type,
              );
              const disabled = getIsMetricDisabled({
                optionType: option.type,
                pageType: activeState.page!._type,
                metric: activeState.metric.key,
              });

              return (
                <DropdownMenu.Item
                  className="tw-capitalize"
                  key={`type-${option.type}`}
                  onSelect={() => onSelectedTypeChange(option.type)}
                  selected={selected}
                  disabled={disabled}
                  data-name={`insights_posts-performance_filterBar-postType-${option.label.toLowerCase()}`}
                >
                  <Checkbox
                    size="small"
                    disabled={disabled}
                    checked={selected}
                    onChange={() => null}
                  />
                  <Icon icon={option.icon} />
                  {option.label}
                  {postTypesCount?.[option.countKey] !== undefined && (
                    <>
                      {pluralize(postTypesCount[option.countKey], 'post', 's')}
                    </>
                  )}
                </DropdownMenu.Item>
              );
            })}
          <DropdownMenu.Separator />
          <DropdownMenu.Item onClick={onClearSelectedTypes}>
            <span className="tw-w-full tw-text-right tw-text-md tw-font-medium">
              Clear all
            </span>
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
      {filterState.selectedPostTypes.length > 0 && (
        <Chip
          className="tw-absolute -tw-right-2 -tw-top-2 tw-border tw-border-white"
          variant="alert"
        >
          {filterState.selectedPostTypes.length}
        </Chip>
      )}
    </span>
  );
};

export default PostTypeSelect;
