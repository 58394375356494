import { Button } from '@kontentino/ui';
import BaseSubscriptionModal from 'app/components/subscriptionModals/BaseSubscriptionModal';
import adsPlannerIcon from 'app/images/adsplannerModalGreet.svg';
import IntercomUtils from 'app/utils/intercom';
import { FC } from 'react';

type Props = {
  open: boolean;
  onStartTrialNow(): void;
};

const AdsPlannerTrialStart: FC<Props> = ({ open, onStartTrialNow }) => {
  return (
    <BaseSubscriptionModal
      open={open}
      title={
        <div className="tw-text-xl tw-font-medium">
          Enjoy a <strong>free</strong> trial of <strong>Ads Planner</strong>
        </div>
      }
      description={
        <div className="tw-text-center tw-text-md ">
          Explore more of Kontentino with a{' '}
          <span className="tw-font-bold">
            free trial of Ads Planner until June 30, 2023
          </span>
          {'. '}
          🥳 You’ll be able to create, collaborate on, and approve all your
          social media ads on a single platform.
        </div>
      }
      image={<img src={adsPlannerIcon} alt="Ads planner icon" />}
      buttons={
        <>
          <Button onClick={onStartTrialNow} className="tw-w-full">
            Start trial now
          </Button>
          <Button
            variant={'secondary'}
            onClick={() =>
              IntercomUtils.newConversation('Hello! Just having a question')
            }
            className="tw-w-full"
          >
            Contact us
          </Button>
        </>
      }
    />
  );
};

export default AdsPlannerTrialStart;
