import React, { FC } from 'react';
import { ModalManager } from 'context/ModalRouter';
import { useQueryParam } from 'use-query-params';
import Modal from 'components/shared/Modal';
import RequestContainer from 'components/workflowOptions/sendForApproval/RequestContainer';
import FormStateContainer from 'components/workflowOptions/sendForApproval/FormStateContainer';
import getPreselectedValuesFromParams from 'app/components/timeSavers/assignMultipleTasksModal/utils/getPreselectedValuesFromParams';
import SendForApprovalForm from 'components/workflowOptions/sendForApproval/SendForApprovalForm';
import { Button, Icon } from '@kontentino/ui';
import { faUser } from '@fortawesome/pro-regular-svg-icons';

type Props = {
  type: ApprovalType;
  onSuccessFormResponse?: () => void;
  modalManager: ModalManager;
};

const SendForApprovalPreselectedPosts: FC<Props> = ({
  type,
  onSuccessFormResponse,
  modalManager,
}) => {
  const [profilesSearchParam] = useQueryParam<string>('profiles');
  const pageIds =
    profilesSearchParam?.split(',').map((id) => Number.parseInt(id)) ?? [];

  return (
    <Modal size="md" modalManager={modalManager}>
      <Modal.Header title={`Send for ${type} approval`} />
      <RequestContainer type={type} successCallback={onSuccessFormResponse}>
        {(requestParams) => (
          <FormStateContainer
            preselectedValues={getPreselectedValuesFromParams(modalManager)}
            onSubmit={requestParams.submitSendForApproval}
          >
            {(stateParams) => (
              <>
                <Modal.Content>
                  <SendForApprovalForm
                    type={type}
                    onSelectedUsersChange={
                      stateParams.handleChangeSelectedUsers
                    }
                    initialSelectedUserIds={stateParams.formState.selectedUsers}
                    formErrors={stateParams.formErrors}
                    pageIds={pageIds}
                    subject={stateParams.formState.subject}
                    bodyMessage={stateParams.formState.bodyMessage}
                    onSubjectChange={stateParams.changeSubject}
                    onBodyMessageChange={stateParams.changeBodyMessage}
                  />
                </Modal.Content>
                <Modal.Footer>
                  <Button
                    onClick={stateParams.sendForApproval}
                    isLoading={requestParams.isLoading}
                    iconBefore={<Icon icon={faUser} />}
                    data-name={`workflow-options-dropdown_${type}-approval_submit`}
                  >
                    Send for approval
                  </Button>
                </Modal.Footer>
              </>
            )}
          </FormStateContainer>
        )}
      </RequestContainer>
    </Modal>
  );
};

export default SendForApprovalPreselectedPosts;
