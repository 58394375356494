import React, { FC, useEffect, useRef, useState } from 'react';
import { NavigationItem } from 'components/navigation/utils/libs';

const VerticalNavigationContext = React.createContext<{
  hoveredItem?: NavigationItem;
  wrapperRef: React.RefObject<HTMLDivElement>;
  onMouseEnter: (key: NavigationItem) => void;
  isOpen: boolean;
}>({
  hoveredItem: undefined,
  wrapperRef: { current: null },
  onMouseEnter: () => {},
  isOpen: false,
});

type VerticalNavigationProviderProps = {
  menuItems: NavigationItem[];
  children: React.ReactNode;
};

export const VerticalNavigationProvider: FC<
  VerticalNavigationProviderProps
> = ({ children }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isTempExpanded, setIsTempExpanded] = useState<boolean>(false);
  const [hoveredItem, setHoveredItem] = useState<NavigationItem>();
  const isOpen = isTempExpanded;

  const onMouseEnter = (item: NavigationItem) => {
    setHoveredItem(item);
    setIsTempExpanded(!!item.items?.length);
  };

  useEffect(() => {
    const handleMouseLeave = () => {
      setIsTempExpanded(false);
      setHoveredItem(undefined);
    };

    if (wrapperRef.current) {
      wrapperRef.current.addEventListener('mouseleave', handleMouseLeave);
    }

    return () => {
      if (wrapperRef.current) {
        //eslint-disable-next-line react-hooks/exhaustive-deps
        wrapperRef.current.removeEventListener('mouseleave', handleMouseLeave);
      }
    };
  }, []);

  return (
    <VerticalNavigationContext.Provider
      value={{
        hoveredItem,
        wrapperRef,
        onMouseEnter,
        isOpen,
      }}
    >
      {children}
    </VerticalNavigationContext.Provider>
  );
};

export const useVerticalNavigation = () => {
  const context = React.useContext(VerticalNavigationContext);

  if (!context) {
    throw new Error('Must be used within VerticalNavigationProvider ');
  }

  return context;
};
