import { FC } from 'react';
import Link from 'components/shared/Link';
import UserPermissionGate from 'components/UserPermissionGate';
import { modalRoutes } from 'constants/routes';
import { AdAccount, AdAccountConnectionTokenStatus } from 'types/AdAccount';
import { UserPermission } from 'constants/userPermission';
import AdAccountsSelector from 'app/modules/adsPlanner/components/AdAccountsSelector';
import { Button, DropdownMenu, Icon } from '@kontentino/ui';
import LayoutTopBar from 'app/components/layout/LayoutTopBar';
import {
  faAd,
  faChevronDown,
  faDownload,
  faFolder,
  faRefresh,
} from '@fortawesome/pro-regular-svg-icons';
import DatePicker from 'components/shared/DatePicker';
import { OrderDirection } from 'app/types';
import RequestUpdateAllStatuses from 'components/adsPlanner/adAccount/RequestUpdateAllStatuses';
import { createSearchFromParams } from 'utils/url';
import style from 'components/adsPlanner/filterBar/FilterBar.module.scss';
import AdsPlannerTimeSaversDropdown from './AdsPlannerTimeSaversDropdown';
import TopBarToggleSearchField from 'app/components/layout/TopBarToggleSearchField';
import AdsPlannerFiltersDropdown from './AdsPlannerFiltersDropdown';
import AdsPlannerAdditionalActionsDropdown from './AdsPlannerAdditionalActionsDropdown';
import AdsPlannerConfig from 'app/config/adsPlanner';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useAdAccountSelectionContext } from 'components/adsPlanner/adAccount/AdAccountSelection';

type Props = {
  selectedAdAccountId?: number | null;
  adAccounts?: AdAccount[];
  onSelectAdAccount?: (adAccount: AdAccount) => void;
  filterData: {
    startDate: Dayjs | null;
    endDate: Dayjs | null;
  };
  eventHandlers: {
    onDateChange: (from: Dayjs | null, to: Dayjs | null) => void;
    onSearchChange: (value: string) => void;
    onExportToFacebook: () => void;
    onSortOptionSelect: (option: OrderDirection) => void;
  };
};

const AdsPlannerLayoutTopBar: FC<Props> = ({
  selectedAdAccountId = null,
  adAccounts = [],
  onSelectAdAccount = () => null,
  filterData,
  eventHandlers,
}) => {
  const { t } = useTranslation();
  const { selectedAdAccount } = useAdAccountSelectionContext();

  const dropdownOptions = [
    {
      key: 'ad-account',
      label: 'Ad account',
      icon: faAd,
      permissionKeys: [UserPermission.AD_ACCOUNT_MANAGE],
      link: { hash: modalRoutes.ADD_AD_ACCOUNT },
    },
    {
      key: 'campaign',
      label: 'Campaign',
      icon: faFolder,
      permissionKeys: [UserPermission.CAMPAIGN_MANAGE],
      link: {
        hash: modalRoutes.CREATE_CAMPAIGN,
        search: `?adAccount=${selectedAdAccountId}`,
      },
    },
  ];

  return (
    <LayoutTopBar
      className="tw-box-border tw-justify-between tw-border-b tw-border-b-grayscale-10"
      shadow
    >
      <div className="tw-flex tw-gap-x-2">
        <AdAccountsSelector
          adAccounts={adAccounts}
          selectedAdAccountId={selectedAdAccountId}
          onSelectAdAccount={onSelectAdAccount}
        />
        <DatePicker
          shouldSubmitOnRangeSelect={false}
          startDate={filterData.startDate}
          endDate={filterData.endDate}
          onDateChange={(dates) =>
            eventHandlers.onDateChange(dates.startDate, dates.endDate)
          }
          className="tw-h-[36px] tw-max-w-[230px]"
        />
      </div>
      <div className="tw-flex tw-gap-x-2">
        <span className="tw-ml-auto">
          <AdsPlannerTimeSaversDropdown
            onExportToFacebookSelect={eventHandlers.onExportToFacebook}
          />
        </span>
        <TopBarToggleSearchField
          debounceDelay={AdsPlannerConfig.SEARCH_DELAY}
          onDebouncedSearchChange={eventHandlers.onSearchChange}
        />
        <AdsPlannerFiltersDropdown />
        <AdsPlannerAdditionalActionsDropdown
          onSortOptionSelect={eventHandlers.onSortOptionSelect}
        />
        {!!selectedAdAccount?.id && (
          <>
            <UserPermissionGate scopes={UserPermission.AD_ACCOUNT_MANAGE}>
              <div className={style.innerItem}>
                <RequestUpdateAllStatuses adAccountId={selectedAdAccount.id}>
                  {(params) => (
                    <Button
                      variant="secondary"
                      className="tw-h-[36px]"
                      onClick={params.updateStatuses}
                      disabled={params.isLoading}
                      data-name="adsplanner-sync"
                    >
                      <Icon icon={faRefresh} />
                    </Button>
                  )}
                </RequestUpdateAllStatuses>
              </div>
            </UserPermissionGate>
            {selectedAdAccount.connectionStatus ===
              AdAccountConnectionTokenStatus.Ok && (
              <UserPermissionGate scopes={UserPermission.CAMPAIGN_MANAGE}>
                <Link
                  to={{
                    hash: modalRoutes.IMPORT_REMOTE_FACEBOOK_CAMPAIGNS,
                    search: createSearchFromParams({
                      adAccountId: selectedAdAccount.id,
                    }),
                  }}
                >
                  <Button
                    variant="secondary"
                    iconBefore={<Icon icon={faDownload} />}
                    className="tw-h-[36px]"
                    data-name="adsplanner-import-content"
                  >
                    {t('import')}
                  </Button>
                </Link>
              </UserPermissionGate>
            )}
          </>
        )}
        {selectedAdAccountId !== null && (
          <UserPermissionGate
            exact={false}
            scopes={[
              UserPermission.CAMPAIGN_MANAGE,
              UserPermission.AD_ACCOUNT_MANAGE,
            ]}
          >
            <DropdownMenu.Root>
              <DropdownMenu.Trigger>
                <Button
                  className="tw-ml-auto tw-h-[36px] tw-capitalize"
                  variant="primary"
                  data-name="ads_planner-add-new"
                  data-cy="ads_planner-add-new-dropdown"
                  iconAfter={<Icon icon={faChevronDown} />}
                >
                  {t('new')}
                </Button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content className="tw-z-10 tw-w-56">
                {dropdownOptions.map((option) => (
                  <UserPermissionGate
                    scopes={option.permissionKeys}
                    key={`create-new-${option.key}-option`}
                  >
                    <Link to={option.link}>
                      <DropdownMenu.Item
                        data-name={`ads_planner-add-new-${option.key}`}
                        data-cy={`ads_planner-add-new-${option.key}-button`}
                        className="tw-group"
                      >
                        <Icon icon={option.icon} />
                        {option.label}
                      </DropdownMenu.Item>
                    </Link>
                  </UserPermissionGate>
                ))}
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          </UserPermissionGate>
        )}
      </div>
    </LayoutTopBar>
  );
};

export default AdsPlannerLayoutTopBar;
