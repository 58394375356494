import { campaign } from 'api/adsplanner/campaign';
import { adProfile } from 'api/adsplanner/adProfile';
import { adAccount } from 'api/adsplanner/adAccount';
import { adSet } from 'api/adsplanner/adSet';
import { ad } from 'api/adsplanner/ad';
import { Campaign } from 'types/Campaign';
import { Ad, Ads, ExternalAdPreviews } from 'types/Ad';
import type { AdSet } from 'types/AdSet';
import { AdProfileAssociatedInstagramAccount } from 'types/AdProfile';
import { BudgetManagementParams } from 'types/Campaign';
import { AdSetDestinationType } from 'components/adsPlanner/adSet/constants';

export type UpdateAllStatusesResponseData = {
  ads: Record<number, Ad> | [];
  adSets: Record<number, AdSet> | [];
  campaigns: Record<number, Campaign> | [];
};

export type AssociatedInstagramAccountsResponse = {
  associatedInstagramAccounts: AdProfileAssociatedInstagramAccount[];
};

export type EditAdSetParams = Pick<
  CreateAdSetParameters,
  'name' | 'startDate' | 'endDate' | 'destination_type' | 'budgetManagement'
> & { id: number };

export type CreateAdSetParameters = {
  campaignId: number;
  name: string;
  startDate: string;
  endDate: string;
  destination_type?: AdSetDestinationType | null;
  budgetManagement?: Pick<
    BudgetManagementParams,
    'budgetDailyAmount' | 'budgetLifetimeAmount' | 'budgetType'
  >;
  promoted_object_key?: 'page_id';
  promoted_object_value?: string;
};

export type AdsAPIResponse = Omit<ApiResponse, 'data'> & { data: Ads };

export type ExternalAdPreviewsResponse = {
  adPreviews: ExternalAdPreviews;
};

const AdsPlannerApi = {
  adProfile,
  adAccount,
  campaign,
  adSet,
  ad,
};

export default AdsPlannerApi;
