import React, { FC } from 'react';
import { Alert } from '@kontentino/ui';

type Props = {};

const ReportFailedToBuildMessage: FC<Props> = (props) => {
  return (
    <div className="tw-p-8">
      <Alert variant="danger">
        We were unable to build your report.
        <br />
        Please try it again and if problem still persists, please contact our
        support.
      </Alert>
    </div>
  );
};

export default ReportFailedToBuildMessage;
