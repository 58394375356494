import React, { FC, ReactNode } from 'react';
import { Alert, AlertProps } from '@kontentino/ui';

type Props = {
  variant: AlertProps['variant'];
  children: ReactNode;
};

const PostsUnavailable: FC<Props> = ({ variant, children }) => {
  return (
    <div className="tw-flex tw-h-[300px] tw-items-center tw-justify-center">
      <Alert variant={variant}>{children}</Alert>
    </div>
  );
};

export default PostsUnavailable;
