import { ComparedPagePerformance } from 'types/insights/CompetitionAnalysis';

export const getMinMaxValueAndCount = (
  key: string,
  index: number,
  pages: ComparedPagePerformance[],
) => {
  const statisticValues = [
    ...pages.map((page) => page.statistics[key] as number),
  ];

  const count = pages[index].statistics[key] as number;
  const min = Math.min(...statisticValues);
  const max = Math.max(...statisticValues);

  const hasMultipleMinValues =
    statisticValues.filter((value) => value === min).length > 1;
  const hasMultipleMaxValues =
    statisticValues.filter((value) => value === max).length > 1;

  return {
    count,
    min,
    max,
    hasMultipleMinValues,
    hasMultipleMaxValues,
  };
};
