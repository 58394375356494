export const STEPS = {
  SELECT_POSTS: {
    step: 1,
    label: 'Select posts',
  },
  SELECT_ASSIGNEES: {
    step: 2,
    label: 'Select assignees',
  },
};
