import React, { FC, ReactElement } from 'react';
import { PermissionsGate } from '@kontentino/react-permissions-gate';
import { UserPermission } from 'constants/userPermission';

export type UserPermissionGateProps = {
  RenderError?: FC;
  deniedProps?:
    | Record<string, any>
    | ((
        scopes: Partial<Record<UserPermission, boolean>>,
      ) => Record<string, any>);
  scopes?: UserPermission[] | UserPermission;
  exact?: boolean;
  children: ReactElement | ReactElement[];
};

const UserPermissionGate: FC<UserPermissionGateProps> = ({
  children,
  ...props
}) => {
  return <PermissionsGate {...props} children={children} />;
};

export default UserPermissionGate;
