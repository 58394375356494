import MediaUtils from 'app/utils/media';
import { FILE_EXTENSIONS } from '@kontentino/kontentino-constants/Files';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import TimeUtils from 'app/utils/time';

const PostsConfig = {
  FACEBOOK_VIDEO_THUMBNAIL_MAX_UPLOAD_FILES: 1,
  FACEBOOK_VIDEO_THUMBNAIL_ACCEPT_FILES: MediaUtils.getAcceptFromExtensions(
    'image',
    [FILE_EXTENSIONS.jpg, FILE_EXTENSIONS.jpeg, FILE_EXTENSIONS.png],
  ),
  POST_COMPOSE_PREVIEW_VIDEO_PLAYER_ID: 'postCompose_preview_videoPlayer',
  POST_COMPOSE_THUMBNAIL_SETUP_VIDEO_PLAYER_ID:
    'postCompose_thumbnailSetup_videoPlayer',
  MAX_FILES_TO_ACCEPT: 30,
  MIN_FILES_TO_ACCEPT: 1,
  MAX_IMAGE_SIZE: '20 MB',
  MAX_VIDEO_SIZE: '3000 MB',
  GOOGLE_FORM: {
    MAXIMUM_COUPON_CODE_CHARS: 10,
    MAXIMUM_TITLE_CHARS: 58,
    MAXIMUM_TERMS_AND_CONDITIONS_CHARS: 5000,
  },
  SEARCH_POSTS_DELAY: 500,
  FIRST_COMMENT_CHARACTER_LIMIT: {
    [TYPE.TWITTER]: 280,
    [TYPE.FACEBOOK]: 8000,
    [TYPE.INSTAGRAM]: 2200,
    [TYPE.LINKEDIN]: 1300,
  } as { [key: number]: number },
  QUICK_POST_PREVIEW_TOOLTIP_DELAY: 1000,
  DEFAULT_PROJECT_IDENTIFIER: -1,
  COMMENTS: {
    NEIGHBOR_COMMENT_LIMIT_MINUTES: 5,
  },
  COMMUNICATION_AVATAR_TOOLTIP_DELAY: 500,
  COMMUNICATION_TAB_QUERY_PARAM_KEY: 'communication-tab',
  POSTS_LIMIT: 1500,
  POSTS_FILTER_DEBOUNCE: 500,
  MIN_PREVIOUS_NUMBER_OF_YEARS: 5,
  MAX_NEXT_NUMBER_OF_YEARS: 3,
  POST_QUERIES_STALE_TIME: TimeUtils.calcMilliseconds.minutes(1.5),
  POST_QUERIES_CACHE_TIME: TimeUtils.calcMilliseconds.minutes(2),
  FILTER_DATE_RANGE_MAX_MONTHS: 12,
  INSTAGRAM_THUMBNAIL_CONFIG: {
    MAX_SIZE: 8388608, // 8 MB
    ACCEPT: MediaUtils.getAcceptFromExtensions('image', [
      FILE_EXTENSIONS.jpg,
      FILE_EXTENSIONS.jpeg,
    ]),
    MAX_FILES: 1,
  },
};

export default PostsConfig;
