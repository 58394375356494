import React, {
  cloneElement,
  Fragment,
  isValidElement,
  ReactNode,
} from 'react';

export type WrapComponentProps<ChildrenType = ReactNode> = {
  children: ChildrenType;
  if: boolean;
  with: (children: ChildrenType) => JSX.Element;
  elseWith?: (children: ChildrenType) => JSX.Element;
};

export const WrapComponent = function WrapComponent<T extends ReactNode>({
  if: condition,
  with: wrap,
  elseWith,
  children,
}: WrapComponentProps<T>) {
  return condition ? (
    isValidElement(children) ? (
      cloneElement(wrap(children))
    ) : (
      wrap(children)
    )
  ) : elseWith ? (
    isValidElement(children) ? (
      cloneElement(elseWith(children))
    ) : (
      elseWith(children)
    )
  ) : (
    <Fragment>{children}</Fragment>
  );
};
