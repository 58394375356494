import { PostDetail } from 'types/PostDetail';

type Props = {
  allowedStatuses: number[];
  selectedStatuses: number[];
  posts: PostDetail[];
};

export function filterPostsByStatusSelection({
  posts,
  allowedStatuses,
  selectedStatuses,
}: Props) {
  return posts
    .filter((post) => allowedStatuses.includes(post.status))
    .filter((post) => {
      if (!!selectedStatuses.length) {
        return selectedStatuses.includes(post.status);
      }

      return true;
    });
}
