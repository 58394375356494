import { useEffect } from 'react';
import { ActiveState } from 'app/modules/posts/hooks/useActiveState';
import PageTypeUtils from 'app/utils/pageType';
import { getUniquePrimitiveItemsFromArray } from 'utils/helpers';
import { useHistoryQueryParams } from 'app/hooks/useHistoryQueryParams';

export function useUrlSideEffectForUserPilot({
  activeState,
}: {
  activeState: ActiveState;
}) {
  const { setQueryParameter, deleteQueryParameter } = useHistoryQueryParams();

  useEffect(() => {
    const hasSomeDemoProfileActive = activeState.profiles.some(
      (profile) => profile.isDemo,
    );

    if (hasSomeDemoProfileActive) {
      setQueryParameter('demo', 'true');
    } else {
      deleteQueryParameter('demo');
    }

    const profileTypes = getUniquePrimitiveItemsFromArray(
      activeState.profiles.map((profile) =>
        PageTypeUtils.getNameFromNumber(profile.type),
      ),
    );

    if (profileTypes.length) {
      setQueryParameter('social', profileTypes);
    } else {
      deleteQueryParameter('social');
    }
  }, [activeState.profiles, setQueryParameter, deleteQueryParameter]);
}
