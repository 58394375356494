import { Checkbox, DropdownMenu, Icon } from '@kontentino/ui';
import {
  faBolt,
  faBullseyeArrow,
  faChevronRight,
  faCopyright,
  faSquare,
  faTrafficCone,
  faVideo,
} from '@fortawesome/pro-regular-svg-icons';
import { Module } from 'config';
import {
  OBJECTIVE,
  OBJECTIVE_NAME,
} from '@kontentino/kontentino-constants/Ads';
import { useAdsPlannerContainer } from 'components/adsPlanner/context';
import { useEffect, useRef, useState } from 'react';
import { Objective } from 'types/Ad';
import _debounce from 'lodash.debounce';
import { togglePrimitiveItemInArray } from 'utils/helpers';
import AdsPlannerConfig from 'app/config/adsPlanner';

const getObjectiveOptionIcon = (objective: number) => {
  switch (objective) {
    case OBJECTIVE.BRAND_AWARENESS:
      return faCopyright;
    case OBJECTIVE.TRAFFIC:
      return faTrafficCone;
    case OBJECTIVE.POST_ENGAGEMENT:
      return faBolt;
    case OBJECTIVE.REACH:
      return faBullseyeArrow;
    case OBJECTIVE.VIDEO_VIEWS:
      return faVideo;
    default:
      return faSquare;
  }
};

const AdsPlannerFiltersObjectivesSubmenu = () => {
  const { filters, changeSelectedObjectives } = useAdsPlannerContainer();
  const [selectedValues, setSelectedValues] = useState<Objective[]>(
    filters.selectedObjectives,
  );

  const notifyChangedObjective = useRef(
    _debounce((data: Objective[]) => {
      changeSelectedObjectives(data);
    }, AdsPlannerConfig.FILTER_DELAY),
  );

  useEffect(() => {
    const notifyChangedObjectiveCancel = () => {
      notifyChangedObjective.current.cancel();
    };

    return () => {
      notifyChangedObjectiveCancel();
    };
  }, []);

  const handleChangeObjective = (objective: Objective) => {
    setSelectedValues((prevSelectedValues) => {
      const objectives = togglePrimitiveItemInArray<Objective>(
        objective,
        prevSelectedValues,
      );

      notifyChangedObjective.current(objectives);

      return [...objectives];
    });
  };

  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger>
        <DropdownMenu.Item data-name="" data-cy="">
          <Icon icon={faBullseyeArrow} />
          Objectives
          <Icon size="sm" className="tw-ml-auto" icon={faChevronRight} />
        </DropdownMenu.Item>
      </DropdownMenu.SubTrigger>
      <DropdownMenu.Portal>
        <DropdownMenu.SubContent className="tw-z-10">
          {Module.AdsPlanner.SUPPORTED_OBJECTIVES.map((objective) => (
            <DropdownMenu.Item
              key={`objective-${objective}`}
              onSelect={() => handleChangeObjective(objective)}
            >
              <Checkbox
                size="small"
                onChange={() => null}
                checked={selectedValues.includes(objective)}
              />
              <Icon icon={getObjectiveOptionIcon(objective)} />
              {OBJECTIVE_NAME[objective]}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.SubContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Sub>
  );
};

export default AdsPlannerFiltersObjectivesSubmenu;
