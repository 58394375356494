import React, { FC, useState } from 'react';
import { createInsightsApolloClient } from 'app/modules/insights/utils/createAuthorizedInsightsApolloClient';
import { ApolloProvider } from '@apollo/client';
import {
  JsonParam,
  NumericArrayParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import ReportPostsPerformance from 'app/modules/public/modules/pdfReports/modules/insightsPostsPerformance/components/ReportPostsPerformance';
import { Page } from 'app/modules/insights/types/Page';
import ReportFailedToBuildMessage from 'app/modules/public/modules/pdfReports/components/ReportFailedToBuildMessage';
import { getMetricForPageType } from 'app/modules/insights/modules/postsPerformance/utils/metricProperty';
import dayjs from 'dayjs';

type Props = {};

const InsightsPostsPerformancePDFReportRoute: FC<Props> = (props) => {
  const [query] = useQueryParams({
    token: StringParam,
    startDate: StringParam,
    endDate: StringParam,
    page: JsonParam,
    metricKey: StringParam,
    types: withDefault(NumericArrayParam, []),
  });

  const [client] = useState(() =>
    createInsightsApolloClient(query.token ?? ''),
  );

  if (
    query.token &&
    query.startDate &&
    query.endDate &&
    query.page &&
    query.metricKey &&
    query.types
  ) {
    const startDate = dayjs(query.startDate);
    const endDate = dayjs(query.endDate);
    const page = query.page as Page;
    const metric = getMetricForPageType(page._type, query.metricKey, true);
    const types = query.types.flatMap((f) => (f !== null ? [f] : []));

    if (metric) {
      return (
        <ApolloProvider client={client}>
          <ReportPostsPerformance
            startDate={startDate}
            endDate={endDate}
            page={page}
            metric={metric}
            types={types}
          />
        </ApolloProvider>
      );
    }
  }

  return <ReportFailedToBuildMessage />;
};

export default InsightsPostsPerformancePDFReportRoute;
