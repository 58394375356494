import React, { FC } from 'react';
import style from 'app/modules/insights/modules/postsPerformance/components/pageStatistics/statisticCard/Card.module.scss';
import classes from 'react-style-classes';
import Skeleton from 'react-loading-skeleton';

type Props = {
  className?: string;
};

const StatisticCardSkeleton: FC<Props> = ({ className }) => {
  return (
    <div className={classes(style.card, className)} tabIndex={0}>
      <div className={style.textSide}>
        <span className={style.title}>
          <Skeleton height="18px" width="112px" />
        </span>
        <span className={style.value}>
          <Skeleton height="20px" width="68px" />
        </span>
      </div>
      <Skeleton
        height="48px"
        width="48px"
        circle
        style={{ lineHeight: 'inherit' }}
      />
    </div>
  );
};

export default StatisticCardSkeleton;
