import { Badge } from '@kontentino/ui';
import clsx from 'clsx';
import React, { FC } from 'react';

type Props = {
  variant?: 'light' | 'dark';
};
const DemoBadge: FC<Props> = ({ variant = 'dark' }) => {
  return (
    <Badge
      className={clsx({
        'tw-bg-secondary-100 tw-text-white': variant === 'dark',
        'tw-bg-secondary-10 tw-text-purple-100': variant === 'light',
      })}
    >
      Demo
    </Badge>
  );
};

export default DemoBadge;
