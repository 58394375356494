import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { InputLabel, TextArea } from '@kontentino/kontentino-ui';
import i18n from 'i18n';
import { ShortenLink } from 'types/LinkShortener';
import { FormGroup } from '@kontentino/kontentino-ui/build/form';
import { Switch } from '@kontentino/kontentino-ui/build/evergreenUI';
import UtmTagBuilder from 'components/shared/UtmTagBuilder';
import { getHostname, prependHttp } from 'utils/url';
import { hasUrlAnyUtmTag } from 'components/shared/utmTagBuilder/UtmTagBuilder.utils';
import { Module } from 'config';
import CustomDomainSelect from 'app/modules/textEditor/components/linkShortener/createEditShortenLinkModal/CustomDomainSelect';
import { Button, Field, Icon, TextInput, Tooltip } from '@kontentino/ui';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import Modal from 'components/shared/Modal';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

type Props = {
  onClose: () => void;
  onSave(values: Omit<FormState, 'showCustomUrl' | 'showUtmTags'>): void;
  onSaveAndInsert(
    values: Omit<FormState, 'showCustomUrl' | 'showUtmTags'>,
  ): void;
  defaultValues?: ShortenLink | null;
  isLoading: boolean;
  pageId?: number;
};

type FormState = z.infer<typeof schema>;

const schema = z.object({
  name: z.string().max(Module.Pages.LINK_SHORTENER_NAME_MAX_CHARS),
  url: z.string().url(),
  note: z.string().nullish(),
  domain: z.string(),
  id: z.string(),
  pageId: z.string().nullish(),
  customUrl: z.string().nullish(),
  showCustomUrl: z.boolean(),
  showUtmTags: z.boolean(),
});

const Form: FC<Props> = (props) => {
  const {
    handleSubmit,
    register,
    watch,
    control,
    formState,
    getValues,
    setValue,
  } = useForm<FormState>({
    resolver: zodResolver(schema),
    defaultValues: parseDefaultValues(),
  });
  const isEditing = !!props.defaultValues?._id;

  function parseDefaultValues(): Partial<FormState> {
    if (props.defaultValues) {
      const domain = prependHttp(getHostname(props.defaultValues.shortUrl));
      const customUrl = props.defaultValues.shortUrl
        .replace(domain, '')
        .slice(1);

      return {
        ...props.defaultValues,
        id: !!props.defaultValues._id ? props.defaultValues._id : '',
        showCustomUrl: !!props.defaultValues._id,
        domain,
        customUrl,
        showUtmTags: hasUrlAnyUtmTag(props.defaultValues.url),
      };
    }

    return {
      url: '',
      pageId: props.pageId ? String(props.pageId) : null,
      showCustomUrl: false,
      showUtmTags: false,
    };
  }

  const [showUtmTags, showCustomUrl, domain, url, name] = watch([
    'showUtmTags',
    'showCustomUrl',
    'domain',
    'url',
    'name',
  ]);

  function onSubmit(
    callback: (value: Omit<FormState, 'showCustomUrl' | 'showUtmTags'>) => void,
  ) {
    return handleSubmit(({ showCustomUrl, showUtmTags, ...values }) =>
      callback(values),
    );
  }

  return (
    <form>
      <Modal.Content>
        <input type="hidden" {...register('id')} />
        <FormGroup>
          <Field.Group>
            <Field.Label required>URL</Field.Label>
            <TextInput
              required
              {...register('url')}
              placeholder="https://"
              onBlur={() => {
                if (getValues().url !== '') {
                  setValue('url', prependHttp(getValues().url));
                }
              }}
              error={!!formState.errors.url?.message}
            />
            <Field.Error>{formState.errors.url?.message}</Field.Error>
          </Field.Group>
        </FormGroup>
        <FormGroup>
          <Field.Group>
            <Field.Label>Name</Field.Label>
            <TextInput
              {...register('name')}
              placeholder="Fill a name only if you want to save the link"
            />
            <Field.Error>{formState.errors.name?.message}</Field.Error>
          </Field.Group>
        </FormGroup>
        {!isEditing && (
          <FormGroup>
            <InputLabel error={formState.errors.domain?.message}>
              Domain
            </InputLabel>
            <Controller
              name="domain"
              control={control}
              render={({ field: { value, onChange } }) => (
                <CustomDomainSelect value={value} onChange={onChange} />
              )}
            />
          </FormGroup>
        )}
        <FormGroup>
          <Controller
            defaultValue={false}
            name="showCustomUrl"
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                <div className="tw-flex ">
                  <InputLabel>Custom URL</InputLabel>
                  <Switch
                    height={20}
                    marginLeft={8}
                    checked={value}
                    onChange={() => onChange(!value)}
                    disabled={isEditing}
                  />
                </div>
                {showCustomUrl && (
                  <div className="tw-flex tw-items-center">
                    <div className="tw-mr-2 tw-text-md">
                      {domain && prependHttp(domain)}/
                    </div>
                    <TextInput
                      {...register('customUrl')}
                      placeholder="custom-link"
                      disabled={isEditing}
                    />
                  </div>
                )}
              </>
            )}
          />
        </FormGroup>
        <FormGroup>
          <Controller
            name="pageId"
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                <div className="tw-flex">
                  <InputLabel>Global link</InputLabel>
                  <Switch
                    height={20}
                    marginLeft={8}
                    checked={value === null}
                    onChange={() =>
                      onChange(
                        typeof value === 'string' ? null : String(props.pageId),
                      )
                    }
                  />
                  <Tooltip content="When turned on, the shortened link will be saved across all profiles.">
                    <Icon icon={faQuestionCircle} className="tw-ml-2" />
                  </Tooltip>
                </div>
              </>
            )}
          />
        </FormGroup>
        <Controller
          defaultValue={false}
          name="showUtmTags"
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormGroup>
              <div className="tw-flex">
                <InputLabel>UTM tags</InputLabel>
                <Switch
                  height={20}
                  marginLeft={8}
                  checked={value}
                  onChange={() => onChange(!value)}
                />
              </div>
            </FormGroup>
          )}
        />
        {showUtmTags && (
          <>
            <FormGroup>
              <UtmTagBuilder
                value={url}
                onChange={(url) => setValue('url', url)}
                parseFieldsOnUrlsChange
              />
            </FormGroup>
            <FormGroup>
              <InputLabel>Note</InputLabel>
              <TextArea
                {...register('note')}
                style={{ resize: 'none', height: 100 }}
              />
            </FormGroup>
          </>
        )}
      </Modal.Content>
      <Modal.Footer>
        <Button variant="secondary" type="button" onClick={props.onClose}>
          {i18n.close}
        </Button>
        {name && (
          <Button
            variant="secondary"
            type="button"
            isLoading={props.isLoading}
            onClick={onSubmit(props.onSaveAndInsert)}
          >
            Save and insert
          </Button>
        )}
        <Tooltip
          content={!name ? 'To save the link please add a name.' : undefined}
        >
          <div>
            <Button
              variant={name ? 'primary' : 'secondary'}
              style={{ marginLeft: 8 }}
              isLoading={props.isLoading}
              type="button"
              onClick={
                name
                  ? onSubmit(props.onSave)
                  : () =>
                      document
                        .querySelector<HTMLInputElement>('input[name="name"]')
                        ?.focus()
              }
            >
              Save
            </Button>
          </div>
        </Tooltip>
        {!name && (
          <Button
            variant="primary"
            style={{ marginLeft: 8 }}
            isLoading={props.isLoading}
            onClick={onSubmit(props.onSaveAndInsert)}
          >
            Shorten and insert
          </Button>
        )}
      </Modal.Footer>
    </form>
  );
};

export default Form;
