import InsightsLiteConfig from 'app/config/InsightsLiteConfig';
import { User } from 'types/User';
import { AccountStore } from 'modules/account/accountReducer';

export function isIncludedInInsightsLiteExperiment({
  account,
  user,
}: {
  account: AccountStore;
  user: User;
}) {
  return (
    InsightsLiteConfig.INCLUDED_PLANS_IN_EXPERIMENT.includes(
      account.plan.label,
    ) &&
    account.insightsLiteInvitationAccess.hasInsightsLite &&
    InsightsLiteConfig.INCLUDED_ROLES_IN_EXPERIMENT.includes(user.role)
  );
}
