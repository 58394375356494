import React, { useContext, FC } from 'react';
import type { ExportAdsToFacebookState } from './Container';

const ExportAdsToFacebookContext = React.createContext<ExportAdsToFacebookState>(
  // @ts-ignore we do not need to implement default value
  {},
);

type Props = {
  value: ExportAdsToFacebookState;
  children: React.ReactNode;
};

export const Provider: FC<Props> = ({ value, children }) => (
  <ExportAdsToFacebookContext.Provider value={value}>
    {children}
  </ExportAdsToFacebookContext.Provider>
);

export const useExportAdsToFacebook = () => {
  return useContext(ExportAdsToFacebookContext);
};
