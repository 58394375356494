import config from 'config';
import Logger from 'utils/logger';
import * as Sentry from '@sentry/react';
import { IntercomUser } from 'types/User';
import { BrowserTracing } from '@sentry/tracing';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import {
  createRoutesFromChildren,
  matchRoutes,
  useNavigationType,
} from 'react-router-dom';

const SentryUtils = {
  init() {
    if (this.shouldSkip()) {
      Logger.info('Skipping sentry initialisation');
      return;
    }

    try {
      Sentry.init({
        dsn: config.SENTRY_DSN,
        release: process.env.REACT_APP_VERSION,
        integrations: [
          new BrowserTracing({
            tracePropagationTargets: [
              config.API_SERVICE_ENDPOINT,
              config.AJAX_SERVICE_ENDPOINT,
            ],
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
              useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes,
            ),
          }),
        ],
        tracesSampleRate: 0.2,
        sampleRate: 0.25,
      });
    } catch (e) {
      Logger.error(e);
    }
  },
  identify({ user_hash, app_id, user_id, ...user }: IntercomUser) {
    if (this.shouldSkip()) {
      Logger.info('Skipping sentry identification');
      return;
    }

    try {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: user_id,
          ...user,
          appRelease: config.APP_VERSION,
        });

        [
          ['account_name', user.company.name],
          ['account_id', user.company.id],
          ['user_email', user.email],
          ['user_role', user.role],
        ].forEach(([key, value]) => {
          scope.setTag(key, value);
        });
      });
    } catch (e) {
      Logger.error(e);
    }
  },
  error(exception: any, extra?: string | Record<string, any>) {
    try {
      Sentry.captureException(exception, {
        extra: typeof extra === 'string' ? { message: extra } : extra,
      });
    } catch (e) {}
  },
  message(exception: any, severity?: Sentry.Severity) {
    try {
      Sentry.captureMessage(exception, severity);
    } catch (e) {}
  },
  event(event: Sentry.Event) {
    try {
      Sentry.captureEvent(event);
    } catch (e) {}
  },
  shouldSkip() {
    return !config.SENTRY_DSN;
  },
};

export default SentryUtils;
