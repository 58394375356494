import React, { FC } from 'react';
import { LegendProps } from 'recharts';

type Props = {
  labels: {
    [labelKey: string]: string;
  };
  isCombinedMetric?: boolean;
  isCompared?: boolean;
} & LegendProps;

const GraphLegend: FC<Props> = ({
  payload,
  isCompared,
  isCombinedMetric,
  labels,
}) => {
  const data = isCombinedMetric && isCompared ? payload?.slice(0, 2) : payload;

  return (
    <div className="tw-flex tw-gap-4 tw-pb-4">
      {data?.map((item, index) => (
        <div key={`graph-legend-${index}`} className="tw-flex tw-items-center">
          <div
            className="tw-h-4 tw-w-4 tw-rounded-sm"
            style={{ backgroundColor: item.color }}
          />
          <div className="tw-pl-2 tw-text-sm tw-font-regular tw-capitalize tw-text-grayscale-90">
            {labels[item.value] ?? ''}
          </div>
        </div>
      ))}
    </div>
  );
};

export default GraphLegend;
