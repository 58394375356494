import {
  formatDate as formatDateUtil,
  formatDateTime as formatDateTimeUtil,
  formatDateTimeUTC as formatDateTimeUTCUtil,
  formatDateTimeUTCToLocalTimezone as formatDateTimeUTCToLocalTimezoneUtil,
  formatDateUTC as formatDateUTCUtil,
  formatTime as formatTimeUtil,
  formatTimeUTC as formatTimeUTCUtil,
} from '@kontentino/kontentino-utils/date';
import dayjs, { Dayjs } from 'dayjs';

import store from 'store';
import DateTimeConfig from 'app/config/dateTime';

type DayjsDateTimeInput =
  | string
  | number
  | Date
  | dayjs.Dayjs
  | null
  | undefined;

const is12HourFormat = () => !store?.getState().user.useMeridianTime;

export const formatDate = (date: DayjsDateTimeInput) => {
  return formatDateUtil(date, is12HourFormat());
};

export const formatDateTimeUTCToLocalTimezone = (date: DayjsDateTimeInput) => {
  return formatDateTimeUTCToLocalTimezoneUtil(date, is12HourFormat());
};

export const formatDateTimeUTC = (dateTime: DayjsDateTimeInput) => {
  return formatDateTimeUTCUtil(dateTime, is12HourFormat());
};

export const formatDateToMonthAndDay = (date: DayjsDateTimeInput) => {
  const FORMAT = is12HourFormat() ? 'MM/DD' : 'D.M.';

  return dayjs(date).format(FORMAT);
};

export const formatTimeUTC = (
  time: DayjsDateTimeInput,
  format?: string,
): string => {
  return formatTimeUTCUtil(time, is12HourFormat());
};

export const formatTime = (
  time: DayjsDateTimeInput,
  format?: string,
): string => {
  return formatTimeUtil(time, is12HourFormat(), format);
};

export const formatDateTime = (dateTime: DayjsDateTimeInput): string => {
  return formatDateTimeUtil(dateTime, is12HourFormat());
};

export const formatDateUTC = (date: DayjsDateTimeInput): string => {
  return formatDateUTCUtil(date, is12HourFormat());
};

export const formatRawDate = (date: DayjsDateTimeInput) => {
  return dayjs(date).format(DateTimeConfig.DEFAULT_DATE_RAW_FORMAT);
};

const isDateYesterday = (date: Dayjs) => date.isYesterday();
const isDateToday = (date: Dayjs) => date.isToday();
const isDateTomorrow = (date: Dayjs) => date.isTomorrow();

export const getYesterdayTodayTomorrow = (date: Dayjs) => {
  switch (true) {
    case isDateYesterday(date):
      return 'Yesterday';
    case isDateToday(date):
      return 'Today';
    case isDateTomorrow(date):
      return 'Tomorrow';
    default:
      return null;
  }
};

const DateUtils = {
  toMonthYearString(date: Dayjs | null | undefined) {
    return !date ? '' : date.format('MMMM, YYYY');
  },
  toDateString(date: Dayjs | null | undefined) {
    return !date ? '' : date.format(DateTimeConfig.DEFAULT_DATE_RAW_FORMAT);
  },
  toTimeString(date: Dayjs | null | undefined) {
    return !date ? '' : date.format('HH:mm');
  },
  isValid(date: DayjsDateTimeInput) {
    return dayjs(date).isValid();
  },
  isDateRangeMatchesMonth(start: string, end: string) {
    const startDate = dayjs(start).startOf('day').format('YYYY-MM-DD');
    const endDate = dayjs(end).endOf('day').format('YYYY-MM-DD');

    const isStartDateFirstDayOfMonth =
      startDate === dayjs(start).startOf('month').format('YYYY-MM-DD');
    const isEndDateLastDayOfMonth =
      endDate === dayjs(end).endOf('month').format('YYYY-MM-DD');

    return isStartDateFirstDayOfMonth && isEndDateLastDayOfMonth;
  },
};

export default DateUtils;
