import { Editor } from '@tiptap/core';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDebounce from 'utils/hooks/useDebounce';
import { Button, DropdownMenu, Icon, Tooltip } from '@kontentino/ui';
import {
  faArrowRightToBracket,
  faIndent,
  faPenSwirl,
  faSparkles,
  faSpellCheck,
} from '@fortawesome/pro-regular-svg-icons';
import { AIContentEnhanceFormState } from 'app/modules/aiContent/components/aiContent/AiContentEnhance';
import TextEditorAiContentRefineTextSelectionResults from 'app/modules/textEditor/components/textEditorAiContent/textEditorAiContentRefineTextSelection/TextEditorAiContentRefineTextSelectionResults';
import { useTextEditorAiContent } from 'app/modules/textEditor/components/textEditorAiContent/TextEditorAiContentProvider';
import TextEditorAiContentRefineTextSelectionPopover from 'app/modules/textEditor/components/textEditorAiContent/textEditorAiContentRefineTextSelection/TextEditorAiContentRefineTextSelectionPopover';
import { preventDefault } from 'utils/mouseEvent';

type Props = {
  editor: Editor;
};

const TextEditorAiContentRefineTextSelection: FC<Props> = ({
  editor,
}: Props) => {
  const { t } = useTranslation();
  const {
    refineTextSelectionVisible,
    setRefineTextSelectionVisible,
    queries: { enhanceText },
  } = useTextEditorAiContent();
  const visible = refineTextSelectionVisible;
  const debouncedVisible = useDebounce(visible, 750);
  const [textToRefine, setTextToRefine] = useState('');
  const [lastStyleUsed, setLastStyleUsed] = useState<
    AIContentEnhanceFormState['style'] | undefined
  >();

  function changeTextToRefine(value: string) {
    enhanceText.reset();
    setTextToRefine(value);
  }

  function hidePopover() {
    setTextToRefine('');
    setRefineTextSelectionVisible(false);
    enhanceText.reset();
  }

  function submit(value: Pick<AIContentEnhanceFormState, 'style'>) {
    if (enhanceText.isLoading) return;

    enhanceText.mutate({
      prompt: textToRefine,
      style: value.style,
    });

    setLastStyleUsed(value.style);
  }

  function resubmit() {
    if (enhanceText.isLoading || !lastStyleUsed) return;

    enhanceText.mutate({
      prompt: textToRefine,
      style: lastStyleUsed,
    });
  }

  function replaceContent(value: string) {
    editor.commands.insertContentAt(
      {
        from: editor.extensionStorage.aiContent?.lastSelection?.from ?? 0,
        to: editor.extensionStorage.aiContent?.lastSelection?.to ?? 0,
      },
      value,
    );
    hidePopover();
  }

  function onDropdownMenuOpenChange(isOpen: boolean) {
    if (!isOpen) {
      hidePopover();
    }
  }

  const isRefining = !!(enhanceText.isLoading || enhanceText.data);

  return (
    <TextEditorAiContentRefineTextSelectionPopover
      editor={editor}
      onOpenChange={onDropdownMenuOpenChange}
      visible={!visible ? visible : debouncedVisible}
      isRefining={isRefining}
    >
      <>
        {isRefining && (
          <TextEditorAiContentRefineTextSelectionResults
            onResubmit={resubmit}
            renderResultAction={(text) => (
              <Tooltip content={t('replaceSelection')}>
                <Button
                  onClick={() => replaceContent(text)}
                  data-name="ai-content_replace_text"
                  variant="plain"
                  size="small"
                  className="tw-mr-3"
                >
                  <Icon
                    icon={faArrowRightToBracket}
                    className="tw-text-grayscale-100"
                  />
                  <span className="text-sm">{t('replace')}</span>
                </Button>
              </Tooltip>
            )}
          />
        )}
        {!enhanceText.isLoading && !enhanceText.data && (
          <DropdownMenu.Root onOpenChange={onDropdownMenuOpenChange}>
            <DropdownMenu.Trigger>
              <Button
                iconBefore={
                  <Icon icon={faSparkles} className="tw-text-grayscale-100" />
                }
                size="small"
                variant="ghost"
                className="tw-border tw-border-grayscale-20 tw-shadow-xl enabled:tw-bg-white enabled:hover:tw-bg-grayscale-5"
                data-name="ai-content-text-selection-refine-text"
                onClick={() =>
                  changeTextToRefine(
                    editor.extensionStorage.aiContent.lastSelectionText,
                  )
                }
              >
                {t('refineWithAI')}
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content className="tw-text-grayscale-180">
              <DropdownMenu.Item
                onPointerDown={preventDefault()}
                data-name="ai-content-text-selection-refine-text-fix"
                onSelect={() => submit({ style: 'fix' })}
              >
                <Icon icon={faSpellCheck} />
                <span className="">{t('fixGrammar')}</span>
              </DropdownMenu.Item>
              <DropdownMenu.Item
                onPointerDown={preventDefault()}
                data-name="ai-content-text-selection-refine-text-rewrite"
                onSelect={() => submit({ style: 'rewrite' })}
              >
                <Icon icon={faPenSwirl} />
                <span className="">{t('rewrite')}</span>
              </DropdownMenu.Item>
              <DropdownMenu.Item
                onPointerDown={preventDefault()}
                data-name="ai-content-text-selection-refine-text-expand"
                onSelect={() => submit({ style: 'expand' })}
              >
                <Icon icon={faIndent} />
                <span className="">{t('expand')}</span>
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        )}
      </>
    </TextEditorAiContentRefineTextSelectionPopover>
  );
};

export default TextEditorAiContentRefineTextSelection;
