import React, { FC } from 'react';
import { Icon, IconProps } from '@kontentino/ui';
import {
  faCalendarStar,
  faCircleDot,
  faClapperboardPlay,
  faGalleryThumbnails,
  faImage,
  faImages,
  faLinkSimple,
  faPercentage,
  faSquare,
  faText,
  faVideo,
} from '@fortawesome/pro-regular-svg-icons';
import { PseudoPostType } from 'constants/post';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

type Props = {
  type: PseudoPostType;
} & Omit<IconProps, 'icon'>;

const ICON_BY_PSEUDO_TYPE: {
  [x: string]: IconDefinition;
} = {
  [PseudoPostType.Link]: faLinkSimple,
  [PseudoPostType.Photo]: faImage,
  [PseudoPostType.Gallery]: faImages,
  [PseudoPostType.Video]: faVideo,
  [PseudoPostType.Status]: faText,
  [PseudoPostType.Story]: faCircleDot,
  [PseudoPostType.Reel]: faClapperboardPlay,
  [PseudoPostType.Event]: faCalendarStar,
  [PseudoPostType.Offer]: faPercentage,
  [PseudoPostType.Carousel]: faGalleryThumbnails,
};

const PostTypeIcon: FC<Props> = ({ type, ...props }) => {
  const icon = ICON_BY_PSEUDO_TYPE[type] || faSquare;

  return (
    <Icon icon={icon} className="tw-text-grayscale-100" size="md" {...props} />
  );
};

export default PostTypeIcon;
