import { FC } from 'react';
import { PageNumberType, TYPE } from '@kontentino/kontentino-constants/Pages';
import FacebookPage from 'components/insights/competitionAnalysis/pagesPerformance/pageRow/FacebookPage';
import InstagramPage from 'components/insights/competitionAnalysis/pagesPerformance/pageRow/InstagramPage';

export type CountObject = {
  count: number;
  min: number;
  max: number;
  hasMultipleMinValues: boolean;
  hasMultipleMaxValues: boolean;
};

type Props = {
  pageType: PageNumberType;
  imageUrl: string;
  pageName: string;
  followersTotal: CountObject;
  posts: CountObject;
  interactions: CountObject;
  comments: CountObject;
  shares: CountObject;
  reactionsTotal: CountObject;
  likes: CountObject;
  isHighlighted: boolean;
};

const PageRow: FC<Props> = (props) => {
  const commonProps = {
    imageUrl: props.imageUrl,
    pageName: props.pageName,
    followersTotal: props.followersTotal,
    posts: props.posts,
    interactions: props.interactions,
    comments: props.comments,
    isHighlighted: props.isHighlighted,
  };

  switch (props.pageType) {
    case TYPE.FACEBOOK:
      return (
        <FacebookPage
          shares={props.shares}
          reactionsTotal={props.reactionsTotal}
          {...commonProps}
        />
      );
    case TYPE.INSTAGRAM:
      return <InstagramPage likes={props.likes} {...commonProps} />;
    default:
      return null;
  }
};

export default PageRow;
