import config from 'config';
import Logger from 'utils/logger';
import { IntercomUser } from 'types/User';
import { isDevelopmentMode } from 'utils/helpers';

const IntercomUtils = {
  init: function (user: IntercomUser) {
    try {
      if (typeof window.Intercom === 'function') {
        return window.Intercom('boot', {
          ...user,
          avatar: {
            ...user.avatar,
            type: 'avatar',
          },
          app_id: config.INTERCOM_APP_ID,
        });
      }

      throw new Error('Intercom is not defined on window');
    } catch (e) {
      Logger.error(e);
    }
  },
  newConversation: function (message: string) {
    try {
      if (typeof window.Intercom === 'function') {
        return window.Intercom('showNewMessage', message);
      }

      throw new Error('Intercom is not defined on window');
    } catch (e) {
      Logger.error(e);
    }
  },

  track(eventName: string) {
    try {
      if (isDevelopmentMode()) {
        Logger.info('Track intercom event: %s', eventName);
        return;
      }

      if (typeof window.Intercom === 'function') {
        return window.Intercom('trackEvent', eventName);
      }
    } catch (e) {
      Logger.error(e);
    }
  },

  events: {
    POSTS_SEARCH_FIELD_FOCUSED: 'post filter search field focused',
    POST_NOTE_FIELD_FOCUSED: 'post field note focused',
    POST_LABELS_SELECT_FOCUSED: 'post labels input focused',
    POSTS_TIME_SAVERS_DUPLICATE: 'posts time savers duplicate posts',
    POSTS_TIME_SAVERS_MOVE: 'posts time savers move posts',
    POSTS_TIME_SAVERS_CHANGE_STATUS: 'posts time savers change posts status',
    POSTS_TIME_SAVERS_ASSIGN: 'posts time savers assign multiple tasks',
    POSTS_TIME_SAVERS_CLIENT_APPROVAL:
      'posts time savers send for client approval',
    POSTS_TIME_SAVERS_INTERNAL_APPROVAL:
      'posts time savers send for internal approval',
    POSTS_TIME_SAVERS_SCHEDULE: 'posts time savers schedule posts',
    POST_DETAIL_DUPLICATE_POST: 'post detail duplicate post',
  },
};

export default IntercomUtils;
