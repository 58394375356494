import { ReactNode, forwardRef } from 'react';

type Props = {
  label: ReactNode;
  icon: React.ReactNode;
};

const VerticalNavigationDropdownOption = forwardRef<HTMLSpanElement, Props>(
  ({ label, icon, ...props }, ref) => {
    return (
      <span
        ref={ref}
        {...props}
        className="tw-flex tw-h-full tw-w-full tw-items-center tw-text-inherit tw-transition-all tw-duration-100 tw-ease-in-out"
      >
        <span className="tw-flex tw-items-center tw-justify-center tw-text-grayscale-100">
          {icon}
        </span>
        <span className="tw-absolute tw-left-10 tw-right-0 tw-flex tw-items-center tw-whitespace-nowrap tw-text-md tw-font-regular tw-text-inherit">
          {label}
        </span>
      </span>
    );
  },
);

export default VerticalNavigationDropdownOption;
