import { FC } from 'react';
import EmptyMessageLayout from 'components/shared/emptyMessages/EmptyMessageLayout';
import { Icon } from '@kontentino/ui';
import { faSignalGood } from '@fortawesome/pro-light-svg-icons';

type Props = {
  message?: string;
};

const NoDataMessage: FC<Props> = ({ message }) => {
  return (
    <EmptyMessageLayout
      message={message ?? 'No data available'}
      icon={<Icon icon={faSignalGood} />}
      color="#FF991F"
    />
  );
};

export default NoDataMessage;
