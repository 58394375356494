import { useQueryClient, UseQueryOptions } from 'react-query';
import { PostPreview } from 'types/PostPreview';
import { queryKey } from 'constants/queryKey';
import PostApi from 'api/post';
import { PostStatus } from '@kontentino/kontentino-constants/Posts';
import dayjs from 'dayjs';
import DateUtils from 'app/utils/date';
import useQueryNoCache from 'utils/hooks/useQueryNoCache';
import {
  useInvalidatePostDetailQueries,
  useInvalidatePostsQueries,
} from 'app/modules/posts/hooks/useInvalidatePostsQueries';

export function usePostOverviewQuery(
  id: number,
  options?: UseQueryOptions<PostPreview>,
) {
  return useQueryNoCache<PostPreview>(
    queryKey.postPreview(id),
    () => PostApi.preview(id),
    options,
  );
}

export function usePostOverviewOptimisticQueryUpdater(postId: number) {
  const queryClient = useQueryClient();
  const invalidatePostDetails = useInvalidatePostDetailQueries(postId);
  const invalidatePosts = useInvalidatePostsQueries();

  return {
    update: (
      transform: (
        p: undefined | PostPreview,
      ) => undefined | Partial<PostPreview>,
    ) => {
      const prevPost = queryClient.getQueryData<PostPreview>(
        queryKey.postPreview(postId),
      );

      if (!prevPost) return;

      const transformedPostPreviewPartial = transform(prevPost);

      if (!transformedPostPreviewPartial) return;

      queryClient.setQueryData(queryKey.postPreview(postId), {
        ...prevPost,
        ...transformedPostPreviewPartial,
      });
    },
    invalidateData: () => {
      invalidatePostDetails();
      invalidatePosts();
    },
    transformers: {
      assignedTaskToggle(
        taskId: number,
        userId: number,
        prevPost?: PostPreview,
      ) {
        const assignedUser = prevPost?.assigned_users.find(
          (t) => t.task_id === taskId && t.user_id === userId,
        );

        if (assignedUser && prevPost?.assigned_users) {
          return {
            assigned_users: prevPost.assigned_users.map((t) =>
              t.task_id === taskId && t.user_id === userId
                ? {
                    ...t,
                    completed_at: !!t.completed_at
                      ? null
                      : DateUtils.toDateString(dayjs.utc()),
                  }
                : t,
            ),
          };
        }
      },
      requirementToggle(requirementId: number, prevPost?: PostPreview) {
        const requirement = prevPost?.requirements.find(
          (req) => req.id === requirementId,
        );

        if (requirement && prevPost?.requirements) {
          return {
            requirements: prevPost.requirements.map((req) =>
              req.id === requirement.id
                ? { ...requirement, checked: !requirement.checked }
                : req,
            ),
          };
        }
      },
      postStatus(status: PostStatus) {
        return { status };
      },
    },
  };
}
