import React, { FC } from 'react';
import { StandardPostDetailedMetricAdditionalData } from 'app/types/StandardPostDetailed';
import { reactionProperties } from '../../constants/reactionProperties';

type Props = {
  reactions: StandardPostDetailedMetricAdditionalData[];
  pageType: number;
};

const Reactions: FC<Props> = ({ reactions, pageType }) => {
  return (
    <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-[44px] tw-px-7 tw-py-4">
      {reactionProperties[pageType]?.map((reactionProperty, index) => (
        <div
          key={`reaction-${index}`}
          className="tw-flex tw-flex-row tw-items-center"
        >
          <img
            width={16}
            height={16}
            src={reactionProperty.svg}
            alt={reactionProperty.key}
          />
          <div className="tw-ml-2 tw-text-md tw-font-medium tw-text-grayscale-100">
            {reactions.find(
              (reaction) => reaction.name === reactionProperty.key,
            )?.value ?? 0}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Reactions;
