import { PageData, ProfileGroup } from 'types/Page';
import arraySemanticJoin from 'array-semantic-join';

export const getPostsTitleFromPagesAndProfileGroups = (input: {
  profiles: PageData[];
  profileGroups: ProfileGroup[];
  selectedProfilesIds: number[];
  selectedProfileGroupsIds: number[];
}) => {
  const {
    profiles,
    profileGroups,
    selectedProfilesIds,
    selectedProfileGroupsIds,
  } = input;

  const selectedProfilesNames = profiles
    .filter((profile) => selectedProfilesIds.includes(profile.id))
    .map((profile) => profile.name);
  const selectedProfileGroupsProfilesNames = profileGroups
    .filter((profileGroup) =>
      selectedProfileGroupsIds.includes(profileGroup.id),
    )
    .map((profileGroup) => profileGroup.name);

  return arraySemanticJoin([
    ...selectedProfileGroupsProfilesNames,
    ...selectedProfilesNames,
  ]);
};

export default getPostsTitleFromPagesAndProfileGroups;
