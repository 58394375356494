import React, { FC, useState } from 'react';
import { StandardPostDetailed } from 'app/types/StandardPostDetailed';
import { BenchmarksForPosts } from 'app/types/BenchmarksForPosts';
import PostExpanded from './PostExpanded';
import PostCollapsed from './PostCollapsed';
import {
  getPostBenchmarkValue,
  getPostMaxValue,
  getPostMetricValue,
} from '../utils/postPerformance';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import { openBlank } from 'utils/url';
import { openFacebookPost } from 'app/modules/insights/utils/openFacebookPost';
import { PostCollapsedLayoutVariant } from 'app/modules/insights/modules/postsPerformance/components/postCollapsed/PostCollapsedLayout';
import { MetricProperty } from 'app/modules/insights/types/MetricProperty';

type Props = {
  pageType: number;
  post: StandardPostDetailed;
  metric: MetricProperty;
  benchmarks: BenchmarksForPosts;
  index: number;
  collapsedLayoutVariant?: PostCollapsedLayoutVariant;
};

const SinglePost: FC<Props> = (props) => {
  const [expanded, setExpanded] = useState(false);

  const benchmarkValue = getPostBenchmarkValue(
    props.benchmarks[`type_${props.post.type}`],
    props.metric.key,
  );
  const metricValue = getPostMetricValue(props.post.metrics[props.metric.key]);

  const performanceValues = {
    benchmark: benchmarkValue ?? 0,
    metric: metricValue ?? 0,
    max: getPostMaxValue(benchmarkValue, metricValue) ?? 0,
  };

  const getSeeOnClickHandler = () => {
    switch (props.pageType) {
      case TYPE.FACEBOOK:
        return () => openFacebookPost(props.post.linkId);
      case TYPE.INSTAGRAM:
        return props.post.link ? () => openBlank(props.post.link!) : undefined;
      case TYPE.LINKEDIN:
        return props.post.link ? () => openBlank(props.post.link!) : undefined;
      default:
        return undefined;
    }
  };

  return expanded ? (
    <PostExpanded
      {...props}
      performanceValues={performanceValues}
      toggleExpanded={() => setExpanded((prevState) => !prevState)}
      expanded={expanded}
      seeOnClickHandler={getSeeOnClickHandler()}
    />
  ) : (
    <PostCollapsed
      {...props}
      layoutVariant={props.collapsedLayoutVariant}
      performanceValues={performanceValues}
      toggleExpanded={() => setExpanded((prevState) => !prevState)}
      expanded={expanded}
      seeOnClickHandler={getSeeOnClickHandler()}
    />
  );
};

export default SinglePost;
