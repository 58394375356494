import React, { FC } from 'react';
import {
  ALL_METRICS_PROPERTY,
  getMetricsForPageType,
} from 'app/modules/insights/modules/postsPerformance/utils/metricProperty';
import PostMetricsSelect from 'app/modules/insights/modules/postsPerformance/modules/filterBar/components/PostMetricsSelect';
import ExportAsSelect from 'app/modules/insights/modules/postsPerformance/modules/filterBar/components/ExportAsSelect';
import { usePostsPerformanceState } from 'app/modules/insights/modules/postsPerformance/hooks/usePostsPerformanceState';
import { useInsightsContainer } from 'components/insights/context';
import PostTypeSelect from './components/PostTypeSelect';
import SyncProfileInsightsButton from 'app/modules/insights/components/SyncProfileInsightsButton';
import { Button } from '@kontentino/ui';

type Props = {};

const PostsPerformanceFilterBar: FC<Props> = (props) => {
  const { getDisabledMetrics } = usePostsPerformanceState();
  const {
    filterState,
    activeState,
    changeSelectedMetric,
    changeSelectedTypes,
    clearSelectedTypes,
  } = useInsightsContainer();

  const onClearAll = () => {
    clearSelectedTypes();
    changeSelectedMetric(ALL_METRICS_PROPERTY.key);
  };

  return (
    <>
      {activeState.page && (
        <div className="tw-flex tw-gap-2">
          {(filterState.selectedPostTypes.length ||
            filterState.selectedMetric !== ALL_METRICS_PROPERTY.key) && (
            <Button
              variant="plain"
              onClick={onClearAll}
              className="tw-h-[36px]"
              data-name="insights_posts-performance_filterBar_clearAll"
            >
              Clear all
            </Button>
          )}
          <PostMetricsSelect
            onChange={(metric) => changeSelectedMetric(metric.key)}
            value={activeState.metric}
            options={getMetricsForPageType(activeState.page?._type, true)}
            disabledMetrics={getDisabledMetrics()}
          />
          <PostTypeSelect
            filterState={filterState}
            activeState={activeState}
            onClearSelectedTypes={clearSelectedTypes}
            onSelectedTypeChange={changeSelectedTypes}
          />
          <ExportAsSelect activeState={activeState} filterState={filterState} />
          <SyncProfileInsightsButton page={activeState.page} />
        </div>
      )}
    </>
  );
};

export default PostsPerformanceFilterBar;
