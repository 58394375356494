import { FC, Suspense, lazy } from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import routeConfig from 'app/config/routes';
import Skeleton from 'react-loading-skeleton';
import Container from 'app/components/Container';

type Props = {};
const History = lazy(() => import('components/in-app-notifications/History'));

const InAppNotifications: FC<Props> = () => {
  return (
    <Container>
      <Suspense fallback={<Skeleton width="100%" height="100%" />}>
        <Outlet />
      </Suspense>
    </Container>
  );
};

export const inAppNotificationsRouter: RouteObject = {
  path: routeConfig.notifications.path,
  element: <InAppNotifications />,
  children: [
    {
      index: true,
      element: <Navigate to={routeConfig.notifications.routes.history.path} />,
    },
    {
      path: routeConfig.notifications.routes.history.path,
      element: <History />,
    },
  ],
};
