import React, { FC } from 'react';
import { mergeTailwindClasses } from '@kontentino/ui';
import clsx from 'clsx';

type Props = {
  link: {
    title?: string;
    description?: string;
  };
  className?: string;
};

const LinkInfo: FC<Props> = ({ link, className }) => {
  return (
    <div
      className={mergeTailwindClasses(
        clsx(
          'tw-bg-grayscale-5 tw-px-6 tw-py-3 tw-text-md tw-font-medium',
          className,
        ),
      )}
    >
      {link.title && (
        <div className="tw-mb-2 tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap ">
          {link.title}
        </div>
      )}
      {link.description && (
        <div className="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-text-grayscale-90">
          {link.description}
        </div>
      )}
    </div>
  );
};

export default LinkInfo;
