import { get, httpDelete, post } from 'api/client';
import env from 'config';
import config from 'config';
import { getExtendedTimeout } from 'utils/request';
import { CancelToken } from 'axios';
import { MentionableUser } from 'types/User';
import PageApi from 'api/page';
import PostApi from 'api/post';
import {
  Comment,
  CommentAction,
  CommentAttachment,
  CommentAttachmentType,
  CommentReaction,
  CommentsSummary,
  CommentThread,
} from 'app/modules/comments/types';

import { CommentsConfig } from 'app/modules/comments/config';

export type AddReactionRequestData = {
  postId: number;
  comment_id: number;
  reaction: string;
};
type PinCommentRequestData = {
  postId: number;
  comment_id: number;
  pinned: boolean;
};

export type CreateCommentRequestData = {
  postId: number;
  thread: CommentThread;
  text: string;
  parent_comment?: number;
  attachments?: {
    type: CommentAttachmentType;
    medium_id: string;
  }[];
  action?: CommentAction | null;
};

type UpdateCommentRequestData = {
  postId: number;
  comment_id: number;
  text: string;
  attachments?: {
    type: CommentAttachmentType;
    medium_id: string;
  }[];
};

type CommentsRequestParams = {
  page?: number;
  limit?: number;
  thread?: CommentThread;
  pinned?: number;
};

const CONFIG = {
  baseURL: env.API_SERVICE_ENDPOINT,
};

const CommentsApi = {
  comments(
    postId: number,
    params: CommentsRequestParams,
  ): Promise<ApiResponse<Comment[], { limit: number; pageCount: number }>> {
    return get(`posts/${postId}/getComments`, {
      ...CONFIG,
      params: {
        ...params,
        limit: params.limit ?? CommentsConfig.DEFAULT_COMMENTS_LIMIT,
      },
    });
  },
  publicComments(
    postId: number,
    params: Omit<CommentsRequestParams, 'thread' | 'pinned'> & {
      token: string;
    },
  ): Promise<ApiResponse<Comment[]>> {
    return get(`posts/${postId}/publicComments`, {
      baseURL: config.PUBLIC_API_SERVICE_ENDPOINT,
      params: {
        ...params,
        limit: params.limit ?? CommentsConfig.DEFAULT_COMMENTS_LIMIT,
      },
    });
  },
  commentsSummary(postId: number): Promise<CommentsSummary> {
    return get(`posts/${postId}/getCommentsSummary`, {
      ...CONFIG,
    });
  },
  create(data: CreateCommentRequestData): Promise<ApiResponse<Comment>> {
    const { postId, ...bodyData } = data;

    return post(`posts/${postId}/addComment`, bodyData, CONFIG);
  },
  update(data: UpdateCommentRequestData): Promise<ApiResponse<Comment>> {
    const { postId, ...bodyData } = data;

    return post(`posts/${postId}/updateComment`, bodyData, CONFIG);
  },
  remove(data: {
    postId: number;
    comment_id: number;
  }): Promise<ApiResponse<{ id: number }>> {
    const { postId, ...bodyData } = data;

    return httpDelete(`posts/${postId}/removeComment`, {
      ...CONFIG,
      params: bodyData,
    });
  },
  pin(data: PinCommentRequestData): Promise<Comment> {
    const { postId, ...bodyData } = data;
    return post(
      `posts/${postId}/pinComment`,
      { ...bodyData, pinned: bodyData.pinned ? 1 : 0 },
      CONFIG,
    );
  },
  addReaction(
    data: AddReactionRequestData,
  ): Promise<ApiResponse<CommentReaction>> {
    const { postId, ...bodyData } = data;

    return post(`posts/${postId}/addCommentReaction`, bodyData, CONFIG);
  },
  removeReaction(data: {
    postId: number;
    reaction_id: number;
  }): Promise<ApiResponse<{ id: number }>> {
    const { postId, ...bodyData } = data;

    return httpDelete(`posts/${postId}/removeCommentReaction`, {
      ...CONFIG,
      params: bodyData,
    });
  },
  uploadCommentFile({
    file,
    cancelToken,
    onProgress,
  }: {
    file: File;
    cancelToken?: CancelToken;
    onProgress?(progress: number): void;
  }): Promise<Omit<CommentAttachment, 'comment_id' | 'medium_id'>> {
    const data = new FormData();

    data.append('medialibrary_files_photos[0]', file);

    return post<Omit<CommentAttachment, 'comment_id' | 'medium_id'>[]>(
      '/gallery/ajaxUploadCommentFile',
      data,
      {
        timeout: getExtendedTimeout(3),
        cancelToken,
        onUploadProgress: (progressEvent: ProgressEvent) => {
          if (progressEvent.lengthComputable && !!onProgress) {
            onProgress(
              Math.ceil((progressEvent.loaded / progressEvent.total) * 100),
            );
          }
        },
      },
    ).then((response) => response[0]);
  },
  async mentionableUsers(params: {
    pageId?: number;
    postId?: number;
  }): Promise<MentionableUser[]> {
    if (params.pageId) {
      return PageApi.mentionableUsers(params.pageId);
    }

    if (params.postId) {
      const { page } = await PostApi.detail(params.postId);
      return PageApi.mentionableUsers(page.id);
    }

    return Promise.reject('pageId or postId not provided.');
  },
};

export default CommentsApi;
