import { get } from 'api/client';
import { User } from 'types/User';
import env from 'config';
import { UserPermission } from 'constants/userPermission';

type RequestInvitationParams = {
  name: string;
  email: string;
  userEmail: string;
  accountUsername: string;
  token: string;
};

const UserApi = {
  getData(): Promise<User> {
    return get('/api/ajaxGetUserData');
  },
  getPermissions(): Promise<UserPermission[]> {
    return get<UserPermission[]>('/user/ajaxGetPermissions');
  },
  requestInvitation(params: RequestInvitationParams): Promise<boolean> {
    return get('/user/requestInvitation', {
      baseURL: env.PUBLIC_API_SERVICE_ENDPOINT,
      params,
    });
  },
};

export default UserApi;
