import React, { FC, ReactNode } from 'react';
import config from 'config';
import { ReactQueryDevtools } from 'react-query/devtools';

type Props = {
  children: ReactNode | ReactNode[];
};

const ReactQueryDevTools: FC<Props> = (props) => {
  return (
    <>
      {props.children}
      {config.REACT_QUERY_DEV_TOOLS_ENABLED && <ReactQueryDevtools />}
    </>
  );
};

export default ReactQueryDevTools;
