import { AppActionNames } from 'modules/app/appActions';
import { Action } from 'types/Store';

export type AppStore = {
  isInitialized: boolean;
  isUnauthorized: boolean;
  authToken: string | null;
};

function getInitialState(): AppStore {
  return {
    isInitialized: false,
    isUnauthorized: false,
    authToken: null,
  };
}

function appReducer(state = getInitialState(), action: Action) {
  switch (action.type) {
    case AppActionNames.SET_APP_INITIALIZED:
      return {
        ...state,
        isInitialized: true,
      };
    case AppActionNames.SET_APP_UNAUTHORIZED:
      return {
        ...state,
        isUnauthorized: true,
      };
    case AppActionNames.SET_AUTH_TOKEN:
      return {
        ...state,
        authToken: action.payload,
      };
    default:
      return state;
  }
}

export default appReducer;
