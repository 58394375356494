import React, { FC } from 'react';
import { getMedalRankFromIndex } from 'app/modules/insights/utils/getMedalRankFromIndex';
import { StandardPostDetailed } from 'app/types/StandardPostDetailed';
import BenchmarkBars from '../../../components/BenchmarkBars';
import { getMetricsForPageType } from '../utils/metricProperty';
import { MetricProperty } from 'app/modules/insights/types/MetricProperty';
import PerformanceInfo from 'app/modules/insights/components/PerformanceInfo';
import ActionButtons from './ActionButtons';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import StandardPostPreview from '../../../components/StandardPostPreview';
import MetricRow from './postExpanded/MetricRow';
import Divider from './Divider';
import PostInsights from 'app/modules/insights/modules/postsPerformance/components/postExpanded/PostInsights';
import { shouldMetricShow } from '../utils/shouldMetricshow';

type Props = {
  pageType: number;
  post: StandardPostDetailed;
  metric: MetricProperty;
  index: number;
  toggleExpanded: () => void;
  expanded?: boolean;
  performanceValues: {
    benchmark: number;
    metric: number;
    max: number;
  };
  seeOnClickHandler?: () => void;
};

const PostExpanded: FC<Props> = ({
  post,
  index,
  pageType,
  metric,
  toggleExpanded,
  expanded,
  performanceValues,
  seeOnClickHandler,
}) => {
  const renderMetricRow = (key: string, index: number) => {
    const properties = getMetricsForPageType(pageType)[key];
    const metric = post.metrics[key];

    if (!shouldMetricShow(pageType, key, post) || !metric || !properties) {
      return null;
    }

    return (
      <React.Fragment key={`metric-row-${index}`}>
        <div>
          {index > 0 && <Divider />}
          <MetricRow
            name={key}
            value={metric.value}
            icon={properties.icon}
            label={properties.title}
            info={properties.info}
            additionalData={metric.additionalData}
            pageType={pageType}
          />
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="tw-mb-4 tw-flex tw-flex-col tw-rounded-md tw-bg-white">
      <div className="tw-flex tw-flex-row tw-px-4 tw-py-4">
        <div className="tw-mr-4 tw-flex tw-min-w-[514px] tw-overflow-hidden">
          <StandardPostPreview
            key={`post-${index}`}
            post={post}
            medalRank={getMedalRankFromIndex(index)}
            allowVideoPlayback
            classNames={{
              root: 'tw-max-h-[680px] tw-w-[514px] tw-border tw-border-solid tw-border-grayscale-20',
            }}
          />
        </div>
        <div className="tw-flex tw-w-full tw-flex-col">
          <div className="tw-flex tw-h-fit tw-max-h-[628px] tw-w-full tw-flex-1 tw-flex-col tw-overflow-auto tw-overflow-x-hidden tw-rounded-md tw-border tw-border-solid tw-border-grayscale-20">
            <div className="tw-flex tw-max-h-[106px] tw-flex-1 tw-flex-row tw-border-b tw-border-solid tw-border-grayscale-20">
              <div className="tw-flex-[5] tw-p-4">
                <BenchmarkBars
                  metricLabel={metric.title}
                  metricValue={performanceValues.metric}
                  metricValueTotal={performanceValues.max}
                  benchmark={performanceValues.benchmark}
                  metricKey={metric.key}
                />
              </div>
              <div className="tw-min-w-[232px]  tw-flex-1 tw-gap-4 tw-border-l tw-border-solid tw-border-grayscale-20 tw-p-4">
                <PerformanceInfo
                  metricValue={performanceValues.metric}
                  benchmark={performanceValues.benchmark}
                />
              </div>
            </div>
            <div className="tw-flex-1 tw-px-4 tw-pt-4">
              {pageType === TYPE.FACEBOOK && post.totalExpenses && (
                <PostInsights
                  totalExpenses={post.totalExpenses}
                  pageType={pageType}
                />
              )}
              <div className="tw-mt-4 tw-text-md tw-font-medium tw-text-grayscale-70">
                Post Insights
              </div>
              {Object.keys(getMetricsForPageType(pageType)).map((key, index) =>
                renderMetricRow(key, index),
              )}
            </div>
          </div>
          <ActionButtons
            pageType={pageType}
            showMoreClick={toggleExpanded}
            seeOnClick={seeOnClickHandler}
            expanded={expanded}
            className="tw-self-end tw-pt-4"
          />
        </div>
      </div>
    </div>
  );
};

export default PostExpanded;
