import AdsPlannerApi from 'api/adsplanner';
import { FilterState } from 'components/adsPlanner/Container';
import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query';
import { QUERY_KEY } from 'components/adsPlanner/constants';
import { Campaign, Filter, Order } from 'types/Campaign';
import { Module } from 'config';
import useCampaignsQueryKey from 'components/adsPlanner/hooks/useCampaignsQueryKey';
import { ApiClientError } from 'api/client';
import DateUtils from 'app/utils/date';

export const useCampaignsRequest = (
  requestParams: {
    adAccountId: number;
    filter?: Filter;
    order?: Order;
  },
  options?: UseInfiniteQueryOptions<
    Omit<ApiResponse, 'data'> & { data: Campaign[] },
    ApiClientError,
    Omit<ApiResponse, 'data'> & { data: Campaign[] },
    Omit<ApiResponse, 'data'> & { data: Campaign[] },
    any
  >,
) => {
  const campaignsQueryKey = useCampaignsQueryKey();

  return useInfiniteQuery(
    campaignsQueryKey,
    ({ pageParam: pageParamOffset }) =>
      AdsPlannerApi.campaign.campaigns(
        requestParams.adAccountId,
        {
          limit: Module.AdsPlanner.CAMPAIGNS_REUEST_LIMIT,
          offset: pageParamOffset,
        },
        requestParams.order,
        requestParams.filter,
      ),
    {
      enabled: !!requestParams.adAccountId,
      keepPreviousData: true,
      ...options,
    },
  );
};

export const useDatelessCampaignsRequest = (
  requestParams: {
    adAccountId: number;
    filter?: Pick<Filter, 'objectives'>;
  },
  options?: UseQueryOptions<
    Omit<ApiResponse, 'data'> & { data: Campaign[] },
    ApiClientError,
    Omit<ApiResponse, 'data'> & { data: Campaign[] },
    (string | number)[]
  >,
) => {
  return useQuery(
    [QUERY_KEY.DATELESS_CAMPAIGNS, requestParams.adAccountId],
    () =>
      AdsPlannerApi.campaign.datelessCampaigns(
        requestParams.adAccountId,
        requestParams.filter,
      ),
    {
      enabled: !!requestParams.adAccountId,
      ...options,
    },
  );
};

export const useCampaignsListContainerData = (
  adAccount: number,
  filters: FilterState,
) => {
  const {
    data: datelessCampaigns,
    isLoading: isDatelessCampaignsLoading,
    remove: removeDatelessCampaigns,
  } = useDatelessCampaignsRequest(
    {
      adAccountId: adAccount,
      filter: {
        objectives: filters.selectedObjectives,
      },
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );

  const {
    data: campaigns,
    isIdle,
    isLoading: isCampaignsLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    remove,
  } = useCampaignsRequest(
    {
      adAccountId: adAccount,
      filter: {
        startDate: !!filters.startDate
          ? DateUtils.toDateString(filters.startDate)
          : null,
        endDate: !!filters.endDate
          ? DateUtils.toDateString(filters.endDate)
          : null,
        tags: filters.selectedLabels,
        workflowStatuses: filters.selectedWorkflowStatuses,
        effectiveStatuses: filters.selectedEffectiveStatuses,
        objectives: filters.selectedObjectives,
      },
      order: {
        orderBy: 'created_at',
        orderDirection: filters.orderDirection,
      },
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      getNextPageParam: (lastPage) => {
        return (
          lastPage.data.length === Module.AdsPlanner.CAMPAIGNS_REUEST_LIMIT
        );
      },
    },
  );

  return {
    data: {
      campaigns: campaigns?.pages.flatMap((item) => item.data) ?? [],
      datelessCampaigns: datelessCampaigns?.data ?? [],
      totalCount:
        campaigns?.pages.reduce(
          (prev, current) => prev + current.pagination.totalCount,
          datelessCampaigns?.pagination.totalCount ?? 0,
        ) ?? 0,
    },
    isLoading: isCampaignsLoading || isDatelessCampaignsLoading,
    isIdle,
    isFetching,
    hasNextPage,
    fetchNextPage,
    remove: () => {
      remove();
      removeDatelessCampaigns();
    },
  };
};
