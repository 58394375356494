import React, { forwardRef } from 'react';
import { animated } from 'react-spring';
import {
  Chip,
  DropdownMenu,
  Icon,
  IconButton,
  mergeTailwindClasses,
  Tooltip,
} from '@kontentino/ui';
import {
  faArrowRightToArc,
  faChartSimple,
  faChevronDown,
  faCopy,
  faEllipsisVertical,
  faPenToSquare,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { useAccordionWidget } from 'app/modules/posts/components/accordionWidget/hooks/useAccordionWidget';
import { useTranslation } from 'react-i18next';

type Props = {
  initialIsOpen?: boolean;
  onCopyToClipboard?: () => void;
  onCopyToText?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onStatisticsClick?: () => void;
  title: string;
  count?: number;
} & Omit<React.HTMLAttributes<HTMLDivElement>, 'title'>;

const OptionsBarFeatureItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      title,
      children,
      className,
      initialIsOpen = false,
      onCopyToClipboard,
      onCopyToText,
      onEdit,
      onDelete,
      count,
      onStatisticsClick,
      ...rest
    },
    ref,
  ) => {
    const {
      bodyStyle,
      headerStyle,
      chevronStyle,
      bodyRef,
      isOpen,
      setIsOpen,
      bodyMaxHeight,
    } = useAccordionWidget({ initialIsOpen });

    const { t } = useTranslation();

    return (
      <div
        className={mergeTailwindClasses(
          clsx(
            'tw-overflow-hidden tw-rounded tw-bg-white tw-py-2',
            { 'tw-bg-grayscale-10': isOpen, 'tw-max-h-9': !isOpen },
            className,
          ),
        )}
        ref={ref}
        {...rest}
      >
        <div
          className={clsx(
            'tw-flex tw-w-full tw-items-center tw-justify-between tw-px-3',
          )}
        >
          <div
            className={clsx('tw-flex tw-w-full tw-items-center', {
              'tw-border-b tw-border-b-grayscale-20 tw-pb-2': isOpen,
            })}
          >
            <animated.div
              style={headerStyle}
              className="tw-group/accordionTitle tw-flex tw-flex-1 tw-cursor-pointer tw-select-none tw-items-center tw-gap-2 !tw-p-0 "
              onClick={setIsOpen.toggle}
            >
              <div
                className="tw-flex tw-w-full tw-max-w-xs tw-items-center tw-gap-2"
                data-name="list-item"
              >
                <animated.div style={chevronStyle}>
                  <IconButton
                    variant="plain"
                    className="tw-flex tw-text-grayscale-180 group-hover/accordionTitle:tw-text-primary-100"
                    icon={<Icon icon={faChevronDown} />}
                    size="small"
                    data-name="listItem_accordion"
                  />
                </animated.div>

                <div
                  className={clsx(
                    ' tw-text-md tw-font-regular tw-text-grayscale-180',
                    {
                      'tw-truncate': !isOpen,
                    },
                  )}
                >
                  {title}
                </div>
              </div>
            </animated.div>
            <div>
              <div className="tw-ml-auto tw-flex tw-items-center ">
                {!!count && (
                  <Chip variant="darkGrey" className="tw-mr-4">
                    {count}
                  </Chip>
                )}
                <div className="tw-flex tw-items-center tw-gap-2">
                  <Tooltip content={t('insertToText')}>
                    <IconButton
                      icon={<Icon icon={faArrowRightToArc} />}
                      variant="plain"
                      onClick={onCopyToText}
                      data-name="listItem_insert"
                    />
                  </Tooltip>
                  <Tooltip content={t('copyToClipboard')}>
                    <IconButton
                      icon={<Icon icon={faCopy} />}
                      variant="plain"
                      onClick={onCopyToClipboard}
                      data-name="listItem_clipboard"
                    />
                  </Tooltip>

                  <DropdownMenu.Root>
                    <DropdownMenu.Trigger>
                      <IconButton variant="ghost" size="small">
                        <Icon icon={faEllipsisVertical} />
                      </IconButton>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content className="tw-z-10">
                      <DropdownMenu.Item
                        onClick={onEdit}
                        data-name="list-item_edit"
                      >
                        <Icon
                          icon={faPenToSquare}
                          className="tw-text-grayscale-100"
                        />
                        {t('edit')}
                      </DropdownMenu.Item>
                      {onStatisticsClick && (
                        <DropdownMenu.Item
                          onClick={onStatisticsClick}
                          data-name="listItem_statistics"
                        >
                          <Icon
                            icon={faChartSimple}
                            className="tw-text-grayscale-100"
                          />
                          {t('statistics')}
                        </DropdownMenu.Item>
                      )}
                      <DropdownMenu.Item
                        onClick={onDelete}
                        data-name="list-item_delete"
                      >
                        <Icon
                          icon={faTrash}
                          className="tw-text-grayscale-100"
                        />
                        {t('delete')}
                      </DropdownMenu.Item>
                    </DropdownMenu.Content>
                  </DropdownMenu.Root>
                </div>
              </div>
            </div>
          </div>
        </div>
        <animated.div
          style={{
            ...bodyStyle,
            visibility: bodyStyle.opacity.to((opacity) =>
              opacity === 0 ? 'hidden' : 'visible',
            ),
            overflow: bodyStyle.opacity.to((opacity) =>
              opacity < 1 ? 'hidden' : 'unset',
            ),
            maxHeight: bodyStyle.maxHeight.to((maxHeight) =>
              `${bodyMaxHeight}px` === maxHeight ? 'unset' : maxHeight,
            ),
          }}
          className="tw-px-3 tw-py-2 tw-text-sm"
          ref={bodyRef}
        >
          {children}
        </animated.div>
      </div>
    );
  },
);

export default OptionsBarFeatureItem;
