import React, { FC } from 'react';

import { Icon, IconButton } from '@kontentino/ui';
import { SimpleDatePickerHeaderProps } from 'app/components/simpleDatePicker/types';
import dayjs from 'dayjs';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';

const SimpleDatePickerHeaderDefault: FC<SimpleDatePickerHeaderProps> = ({
  decreaseYear,
  increaseYear,
  maxDate,
  minDate,
  date,
}) => {
  const year = date.getFullYear();

  const isNextYearDisabled = maxDate
    ? dayjs(date).add(1, 'year').isAfter(maxDate)
    : false;
  const isPreviousYearDisabled = minDate
    ? dayjs(date).subtract(1, 'year').isBefore(minDate)
    : false;

  return (
    <div className="tw-flex tw-items-center tw-justify-between">
      <IconButton
        onClick={decreaseYear}
        variant="light"
        icon={<Icon size={'md'} icon={faChevronLeft} />}
        size={'small'}
        disabled={isPreviousYearDisabled}
      />
      <span className="tw-text-md">{year}</span>
      <IconButton
        onClick={increaseYear}
        variant="light"
        icon={<Icon size={'md'} icon={faChevronRight} />}
        size={'small'}
        disabled={isNextYearDisabled}
      />
    </div>
  );
};

export default SimpleDatePickerHeaderDefault;
