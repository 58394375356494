import { useMutation, useQueryClient } from 'react-query';
import { PostStatus } from '@kontentino/kontentino-constants/Posts';
import { ApiClientError } from 'api/client';
import i18n from 'i18n';
import Logger from 'utils/logger';
import { usePostOverviewOptimisticQueryUpdater } from 'app/modules/posts/modules/post/hooks/usePostOverview';
import { useToast } from 'app/hooks/useToast';
import CommentsApi from 'app/modules/comments/api';
import PostApi from 'api/post';
import { useTranslation } from 'react-i18next';
import { CommentAction, CommentThread } from 'app/modules/comments/types';
import { CommentConvertor } from 'app/modules/comments/utils/commentConvertor';
import { queryKey } from 'constants/queryKey';

export function useUpdateApprovalPostStatus(id: number) {
  const queryClient = useQueryClient();

  const toast = useToast();
  const updater = usePostOverviewOptimisticQueryUpdater(id);
  const { t } = useTranslation();

  const commonOptions = {
    onError(e: ApiClientError) {
      toast(e.userMessage ?? i18n.somethingWentWrongTryAgain, 'error');
      Logger.error(e);
    },
    onSettled() {
      updater.invalidateData();
    },
  };

  const updateOptimistically = (status: PostStatus) => {
    updater.update(() => updater.transformers.postStatus(status));

    updater.invalidateData();

    toast(
      `${t('post_one')} ${t(`workflowStatusMovedTo.${status}`)}`,
      'success',
    );
  };

  const updateManually = useMutation(
    (status: PostStatus) => PostApi.editPostStatus({ id, status }),
    {
      onMutate(status) {
        updater.update(() => updater.transformers.postStatus(status));
        queryClient.invalidateQueries(queryKey.postsMeta());
        toast(
          `${t('post_one')} ${t(`workflowStatusMovedTo.${status}`)}`,
          'success',
        );
      },
      ...commonOptions,
    },
  );

  const updateThroughComment = useMutation(
    (comment: {
      text: string;
      thread: CommentThread;
      action: CommentAction;
      fileAttachments?: File[];
    }) =>
      CommentsApi.create({
        ...comment,
        postId: id,
      }),
    {
      onMutate(comment) {
        const status = CommentConvertor.commentActionToStatus(comment.action);
        updater.update(() => updater.transformers.postStatus(status));
        toast(
          `${t('post_one')} ${t(`workflowStatusMovedTo.${status}`)}`,
          'success',
        );
      },
      ...commonOptions,
    },
  );

  return {
    updateManually,
    updateThroughComment,
    updateOptimistically,
  };
}
