import { normalizeSync } from 'normalize-diacritics';

const StringUtils = {
  /**
   * Function is comparing if first string is included second string.
   * Compared strings are processed to find broader match.
   * It's ordinary useful for filtering functionality.
   * @example
   * isIncludedAt('kontentino','we are k o n t e nt i n o'); // returns true
   * @example
   * isIncludedAt('kontentino','kóntentinó'); // returns true
   * @example
   * isIncludedAt('kontentino','konstantine'); // returns false
   */
  isSubstring(val1: string, val2: string): boolean {
    const string1 = normalizeSync(val1.toLowerCase().replace(/\s/g, ''));
    const string2 = normalizeSync(val2.toLowerCase().replace(/\s/g, ''));

    return string2.includes(string1);
  },
  truncate(text: string, maxLength = 200) {
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  },
  toCamelCase(value: string): string {
    if (/^[a-z][a-zA-Z]*$/.test(value)) {
      return value;
    }

    let trimmedValue = value.replace(/^[-_]+|[-_]+$/g, '');

    return trimmedValue
      .split(/[-_]/)
      .map((part, index) =>
        index === 0
          ? part.toLowerCase()
          : part.charAt(0).toUpperCase() + part.slice(1).toLowerCase(),
      )
      .join('');
  },
  upperCaseAt(text: string, index: number) {
    return `${text.substring(0, index)}${text[
      index
    ].toUpperCase()}${text.substring(index + 1)}`;
  },
  REGEX: {
    URLS: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  },
};

export default StringUtils;
