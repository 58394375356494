import { FC, useState } from 'react';
import { ApprovalDataUser } from 'types/PostWorkflow';
import { mapUserToSelectOption } from 'app/modules/posts/utils/mapUserToSelectOption';
import StringUtils from 'utils/string';
import { Field, Select, TextArea, TextInput } from '@kontentino/ui';
import clsx from 'clsx';
import { openBlank } from 'utils/url';
import { useTranslation } from 'react-i18next';

type Props = {
  type: ApprovalType;
  dataNamePrefix?: string;
  className?: string;
  initialSelectedUserIds: number[];
  onSelectedUsersChange: (selectedUsers: number[]) => void;
  subject: string;
  bodyMessage: string;
  onSubjectChange: (value: string) => void;
  onBodyMessageChange: (value: string) => void;
  formErrors: Record<string, string>;
  availableUsers: ApprovalDataUser[];
  additionalEmails?: string;
  onAdditionalEmailsChange?: (value: string) => void;
  isLoading?: boolean;
};

const Form: FC<Props> = ({
  type,
  className,
  onSelectedUsersChange,
  initialSelectedUserIds,
  subject,
  bodyMessage,
  onSubjectChange,
  onBodyMessageChange,
  formErrors,
  availableUsers,
  additionalEmails,
  onAdditionalEmailsChange,
  isLoading,
  dataNamePrefix = 'posts_send-for-approval',
}) => {
  const [isAdditionalFieldVisible, setAdditionalFieldVisible] =
    useState<boolean>(false);
  const { t } = useTranslation();

  function getLabel() {
    return type === 'client' ? t('clients') : t('recipients');
  }

  function getSelectedUsersDefaultValue() {
    return (
      initialSelectedUserIds
        .map((userId) => availableUsers.find((user) => user.id === userId))
        .filter(Boolean) as ApprovalDataUser[]
    ).map(mapUserToSelectOption);
  }

  return (
    <div className={clsx('tw-flex tw-w-full tw-flex-col tw-gap-4', className)}>
      <Field.Group>
        <Field.Label required>{getLabel()}</Field.Label>
        <div
          data-name={`${dataNamePrefix}_recipients-input`}
          data-cy="posts_send-for-approval_recipients-input"
        >
          <Select
            isMulti
            aria-invalid={!!formErrors.selectedUsers}
            closeMenuOnSelect={false}
            isDisabled={isLoading}
            defaultValue={getSelectedUsersDefaultValue()}
            options={availableUsers.map(mapUserToSelectOption)}
            filterOption={(option, input) =>
              StringUtils.isSubstring(input, option.data.name)
            }
            onChange={(options) =>
              onSelectedUsersChange(options.map((option) => option.value))
            }
            placeholder={`${t('select')} ${getLabel().toLowerCase()}...`}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (styles) => ({
                ...styles,
                zIndex: 1010,
              }),
            }}
          />
        </div>
        <Field.Error>{formErrors.selectedUsers}</Field.Error>
        {type === 'client' && !isAdditionalFieldVisible && (
          <Field.Caption
            cta={{
              label: t('inviteClientsByEmail'),
              onClick: () => setAdditionalFieldVisible(true),
              htmlAttributes: {
                'data-name': `${dataNamePrefix}_invite-clients-by-emails`,
              },
            }}
          />
        )}
      </Field.Group>
      {type === 'client' && isAdditionalFieldVisible && (
        <Field.Group>
          <Field.Label>{`${t('additionalClientsByMail')}:`}</Field.Label>
          <TextInput
            placeholder={`${t(
              'forExample',
            )}: mail1@example.com, mail@example.com, ...`}
            value={additionalEmails}
            onChange={(e) => onAdditionalEmailsChange?.(e.currentTarget.value)}
            disabled={isLoading}
          />
          <Field.Caption>
            {`${t('separatedByComma')}, ${t(
              'forExample',
            )}: mail1@example.com, mail2@example.com...`}
          </Field.Caption>
        </Field.Group>
      )}
      <Field.Group>
        <Field.Label required>{t('subject')}</Field.Label>
        <TextInput
          placeholder={`${t('subject')}...`}
          value={subject}
          onChange={(e) => onSubjectChange(e.currentTarget.value)}
          disabled={isLoading}
          error={!!formErrors.subject}
          data-cy="send-post-for-approval_subject-input"
        />
        <Field.Error>{formErrors.subject}</Field.Error>
        <Field.Caption
          cta={{
            label: t('customizeSubject'),
            onClick: () =>
              openBlank(`/dashboard/settings#${type}-approval-message`),
          }}
        />
      </Field.Group>
      <Field.Group>
        <Field.Label required>{t('body')}</Field.Label>
        <TextArea
          value={bodyMessage}
          onChange={(e) => onBodyMessageChange(e.currentTarget.value)}
          placeholder={`${t('body')}...`}
          disabled={isLoading}
          rows={6}
          error={!!formErrors.bodyMessage}
          data-cy="send-post-for-approval_textarea"
        />
        <Field.Error>{formErrors.bodyMessage}</Field.Error>
        <Field.Caption
          cta={{
            label: t('customizeMessage'),
            onClick: () =>
              openBlank(`/dashboard/settings#${type}-approval-message`),
          }}
        />
      </Field.Group>
    </div>
  );
};

export default Form;
