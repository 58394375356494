import { PostMetricsEdge } from 'app/modules/insights/modules/postsPerformance/types/PostMetricsEdge';
import { MetricProperty } from 'app/modules/insights/types/MetricProperty';
import NumberUtil from 'utils/number';

export function getMetricValue(
  metricNodes: PostMetricsEdge['node'][],
  metricProperty: MetricProperty,
) {
  let value: number | null | undefined;

  const metricName = metricProperty.name?.toLowerCase();

  for (const metricNode of metricNodes) {
    if (metricNode.metricName.toLowerCase() === metricName) {
      value = metricNode.metricValue;
      break;
    }

    if (metricNode.additionalData) {
      const subMetric = metricNode.additionalData.edges.find(
        ({ node }) => node.name.toLowerCase() === metricName,
      )?.node;

      if (subMetric) {
        value = subMetric.value;
        break;
      }
    }
  }

  return NumberUtil.format(value ?? 0);
}
