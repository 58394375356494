import React, { FC } from 'react';
import { Icon } from '@kontentino/ui';
import { MetricTableDataRow } from 'app/modules/insights/types/PagePerformance';
import MetricBadge from './MetricBadge';
import NumberUtil from 'utils/number';

type Props = {
  metrics: MetricTableDataRow[];
  isCompared?: boolean;
  dataName?: {
    table?: string;
  };
};

const MetricTable: FC<Props> = ({ metrics, isCompared, dataName }) => {
  return (
    <div className="tw-max-h-[256px] tw-w-full" data-name={dataName?.table}>
      <div>
        {metrics.map((metric, index) => {
          const difference = metric.comparedValue
            ? Number(metric.value) - Number(metric.comparedValue)
            : undefined;
          return (
            <div
              key={index}
              className="tw-flex tw-flex-row tw-items-center tw-border-b tw-border-grayscale-5 tw-py-2 last:tw-border-b-0"
            >
              <div className="tw-flex tw-w-[60%] tw-min-w-[120px] tw-items-center">
                <Icon
                  icon={metric.icon}
                  className="tw-mr-2 tw-text-grayscale-100"
                />
                <span className="tw-text-sm tw-font-medium">
                  {metric.label}
                </span>
              </div>
              <div className="tw-ml-auto tw-flex tw-items-center tw-gap-2 tw-text-sm tw-font-medium">
                <span className="tw-text-sm tw-font-medium">
                  {NumberUtil.compact(Number(metric.value))}
                </span>
                {isCompared && difference !== undefined && (
                  <MetricBadge size="sm" percentage={difference} />
                )}
              </div>
              {isCompared && metric.comparedValue && (
                <div className="tw-w-[60px] tw-text-right tw-text-sm tw-font-medium">
                  {NumberUtil.compact(Number(metric.comparedValue))}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MetricTable;
