import { FC } from 'react';
import { NavigationItem } from 'components/navigation/utils/libs';
import UserPermissionGate from 'components/UserPermissionGate';
import MenuLink from './MenuLink';

type Props = {
  menuItems: NavigationItem[];
  activeItem?: NavigationItem;
  onMouseLeave?: () => void;
  showLabel?: boolean;
};

export const getNavigationItemUrl = (item: NavigationItem) => {
  return item.routerUrl ? item.routerUrl : item.url ?? '';
};

const MenuItems: FC<Props> = ({
  menuItems,
  activeItem,
  onMouseLeave,
  showLabel,
}) => {
  return (
    <div className="tw-flex tw-flex-col tw-gap-y-4">
      {menuItems
        .filter((item) => !item.isDisabled)
        .map((item, index) => (
          <UserPermissionGate
            key={`${item.key}-${index}`}
            scopes={item.permissionsScope}
            exact={false}
          >
            <MenuLink
              onMouseLeave={onMouseLeave}
              showLabel={showLabel}
              item={item}
              isActive={activeItem?.key === item.key}
            />
          </UserPermissionGate>
        ))}
    </div>
  );
};

export default MenuItems;
