import React, { FC, useState } from 'react';
import AdAccountConnectionContainer from './AdAccountConnectionContainer';
import { ConnectAdAccountModal } from './ConnectAdAccountModal';
import { AD_ACCOUNT_CONNECT_MESSAGE } from 'components/insights/constants';
import { Alert, Button } from '@kontentino/ui';

type Props = {
  pageId: string;
  onToggled: () => void;
};

export const AdAccountsConnection: FC<Props> = ({ pageId, onToggled }) => {
  const [connectModalShown, setConnectModalShown] = useState(false);

  const onModalHide = () => {
    onToggled();
    setConnectModalShown(false);
  };

  return (
    <AdAccountConnectionContainer page={pageId}>
      {(adAccountsParams) => (
        <>
          <Alert
            variant="info"
            className="tw-mb-4"
            title={AD_ACCOUNT_CONNECT_MESSAGE}
          >
            <Button
              variant="tertiary"
              className="tw-ml-auto"
              onClick={() => setConnectModalShown(true)}
            >
              Connect Ad Accounts
            </Button>
          </Alert>

          <ConnectAdAccountModal
            open={connectModalShown}
            title="Connect Ad Account"
            onHide={onModalHide}
            controls={adAccountsParams}
          />
        </>
      )}
    </AdAccountConnectionContainer>
  );
};
