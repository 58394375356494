import React, { FC } from 'react';
import Modal from 'components/shared/Modal';
import { HashtagPreset } from 'types/HashtagPresets';
import Form from 'app/modules/textEditor/components/hashtagsPresets/createEditHashtagModal/Form';
import { useMutation, useQueryClient } from 'react-query';
import { HashtagPresetsApi } from 'api/hashtagPresets';
import { useToast } from 'app/hooks/useToast';
import i18n from 'i18n';
import { queryKey } from 'constants/queryKey';

type Props = {
  hashtagToEdit?: null | HashtagPreset;
  onClose: AnyFunction;
};

const CreateEditHashtagModal: FC<Props> = (props) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(HashtagPresetsApi.save, {
    onSuccess() {
      toast(
        `Hashtag preset successfully ${
          props.hashtagToEdit?.id ? 'updated' : 'created'
        }`,
      );
      props.onClose();
      queryClient.invalidateQueries([queryKey.hashtagPresets]);
    },
    onError() {
      toast(i18n.somethingWentWrong);
    },
  });

  return (
    <Modal
      id="create-edit-hashtags-presets-modal"
      open={props.hashtagToEdit !== undefined}
      onClose={props.onClose}
      isLoading={isLoading}
      classNames={{
        root: '!tw-z-[1002]',
      }}
    >
      <Modal.Header
        title={`${props.hashtagToEdit?.id ? 'Edit' : 'Create'} hashtag preset`}
      />
      <Form
        onClose={props.onClose}
        onSubmit={mutate}
        defaultValues={props.hashtagToEdit}
        isLoading={isLoading}
      />
    </Modal>
  );
};

export default CreateEditHashtagModal;
