import config from 'config';
import { gql, GraphQLClient, RequestOptions, Variables } from 'graphql-request';
import {
  AvailableDomain,
  ShortenLink,
  ShortenLinkDetail,
} from 'types/LinkShortener';
import store from 'store';
import semanticJoin from 'array-semantic-join';

export type CreateShortLinkInput = {
  id?: string;
  _id?: string;
  pageId?: string | null;
  name?: string;
  url: string;
  note?: string | null;
  domain?: string;
  customUrl?: string | null;
};

async function request<T, V extends Variables = Variables>(
  options: RequestOptions<V, T> & { skipAuth?: boolean },
) {
  async function getHeaders() {
    return { Authorization: `Bearer ${store.getState().app.authToken}` };
  }

  const client = new GraphQLClient(
    `${config.LINK_SHORTENER_SERVICE_ENDPOINT}/graphql`,
    options.skipAuth ? undefined : { headers: await getHeaders() },
  );

  return client.request(options).catch((e) => {
    if (Array.isArray(e?.response?.errors)) {
      e.userMessage = semanticJoin(
        e.response.errors.map((err: { message: string }) => err.message),
      );
    }

    return Promise.reject(e);
  });
}

export const LinkShortenerApi = {
  allLinks() {
    return request<{
      links: {
        edges: { node: ShortenLink }[];
        totalCount: number;
        pageInfo: {
          hasNextPage: boolean;
        };
      };
    }>({
      document: gql`
        query allLinksQuery(
          $first: Int = 1000
          $orderBy: [LinksOrder] = { field: CREATED_AT, direction: DESC }
          $url: String
        ) {
          links(first: $first, orderBy: $orderBy, pageIds: null, url: $url) {
            totalCount
            pageInfo {
              hasNextPage
            }
            edges {
              node {
                id
                _id
                pageId
                name
                url
                shortUrl
                note
              }
            }
          }
        }
      `,
    }).then((res) => res.links);
  },
  save(input: CreateShortLinkInput) {
    const domain = input.domain ?? config.LINK_SHORTENER_SERVICE_ENDPOINT;

    if (input.id) {
      return request<{
        updateLink: { id: string; shortUrl: string };
      }>({
        document: gql`
          mutation updateLinkMutation($input: UpdateLink!) {
            updateLink(input: $input) {
              id
              shortUrl
            }
          }
        `,
        variables: {
          input: {
            url: input.url,
            id: input.id,
            pageId: input.pageId,
            name: input.name,
            note: input.note,
          },
        },
      }).then((res) => res.updateLink);
    }

    return request<{ createLink: { id: string; shortUrl: string } }>({
      document: gql`
        mutation createLinkMutation($input: NewLink!) {
          createLink(input: $input) {
            id
            shortUrl
          }
        }
      `,
      variables: {
        input: {
          url: input.url,
          id: input.customUrl ?? '',
          domain: domain,
          pageId: input.pageId,
          name: input.name ?? '',
          note: input.note,
        },
      },
      skipAuth: !input.name,
    }).then((res) => res.createLink);
  },
  delete(id: string) {
    return request({
      document: gql`
        mutation deleteLinkMutation($id: ID!) {
          deleteLink(id: $id) {
            id
          }
        }
      `,
      variables: { id },
    });
  },
  availableDomains() {
    return request<{
      domains: {
        edges: { node: AvailableDomain }[];
      };
    }>({
      document: gql`
        query availableDomains {
          domains {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      `,
    }).then((res) => res.domains?.edges.map((domain) => domain.node) ?? []);
  },
  detail(id: string) {
    return request<{ link: ShortenLinkDetail }>({
      document: gql`
        query linkDetailQuery($id: ID!) {
          link(id: $id) {
            id
            _id
            pageId
            name
            url
            shortUrl
            note
            createdAt
            updatedAt
            visits {
              totalCount
              edges {
                node {
                  id
                  _id
                  browser
                  version
                  operatingSystem
                  ip
                  city
                  country
                  createdAt
                }
              }
            }
          }
        }
      `,
      variables: {
        id,
      },
    }).then((res) => res.link);
  },
};
