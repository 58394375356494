import { FC } from 'react';
import {
  BarChart as BarGraph,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
} from 'recharts';
import { GraphContent } from 'types/insights/Graphs';
import { Module } from 'config';
import StringUtils from 'utils/string';
import { colors } from '@kontentino/ui';

type Props = {
  data: GraphContent[];
  primaryDataKey: string;
  secondaryDataKey?: string | null;
  isAnimationActive?: boolean;
  shouldDisplayAllTicks?: boolean;
};

const BarChart: FC<Props> = ({
  data,
  primaryDataKey,
  secondaryDataKey,
  isAnimationActive,
  shouldDisplayAllTicks,
}) => {
  const tickFormatter = (tick: string) => {
    return shouldDisplayAllTicks
      ? StringUtils.truncate(tick, Module.Insights.GRAPH_TICK_MAX_CHARS)
      : tick;
  };

  return (
    <ResponsiveContainer>
      <BarGraph data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          tickFormatter={tickFormatter}
          dataKey="key"
          fontSize={14}
          interval={shouldDisplayAllTicks ? 0 : undefined}
        />
        <YAxis fontSize={14} domain={['dataMin', 'dataMax']} />
        <Tooltip />
        <Bar
          isAnimationActive={isAnimationActive}
          dataKey={primaryDataKey}
          fill={colors.primary.default}
        />
        {!!secondaryDataKey && (
          <Bar
            isAnimationActive={isAnimationActive}
            dataKey={secondaryDataKey}
            fill={colors.orange.default}
          />
        )}
      </BarGraph>
    </ResponsiveContainer>
  );
};

export default BarChart;
