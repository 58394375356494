import { PageData, ProfileGroup } from 'types/Page';
import { useMemo } from 'react';
import { FilterState } from 'app/modules/posts/hooks/useFilterState';

export type ActiveState = {
  profiles: PageData[];
  profilesIds: number[];
};

type UseActiveState = {
  filterState: Pick<FilterState, 'selectedProfiles' | 'selectedGroups'>;
  profiles: PageData[];
  profileGroups: ProfileGroup[];
};

export function useActiveState({
  filterState,
  profiles,
  profileGroups,
}: UseActiveState): ActiveState {
  return useMemo(() => {
    const selectedProfileIdsFromGroups = profileGroups
      .filter((group) => filterState.selectedGroups.includes(group.id))
      .flatMap((group) => group.pages.map((page) => page.id));

    const uniqueSelectedProfileIds = [
      ...selectedProfileIdsFromGroups,
      ...filterState.selectedProfiles,
    ];

    const activeProfiles = profiles.filter((profile) =>
      uniqueSelectedProfileIds.includes(profile.id),
    );

    const activeProfilesIds = activeProfiles.map((profile) => profile.id);

    return {
      profilesIds: activeProfilesIds,
      profiles: activeProfiles,
    };
  }, [filterState, profileGroups, profiles]);
}
