import { ExpenseName, TotalExpenseObject } from 'types/AnalyticsPost';
import { capitalize } from '@kontentino/kontentino-utils/string';

export const getObjectiveName = (objective: string) => {
  return objective
    .split('_')
    .map((word) => capitalize(word))
    .join(' ');
};

export const getObjectivesArray = (
  objectives?: Record<ExpenseName, TotalExpenseObject>,
) => {
  const objectivesKeys = objectives ? Object.keys(objectives) : [];
  return objectivesKeys.length > 0
    ? objectivesKeys.map((key) => getObjectiveName(key)).join()
    : 'Not available';
};
