import { FC } from 'react';
import { STEPS } from 'app/components/timeSavers/assignMultipleTasksModal/utils/constants';
import { Button, Icon } from '@kontentino/ui';
import { faChevronRight, faUserPlus } from '@fortawesome/pro-regular-svg-icons';
import Modal from 'components/shared/Modal';

type Props = {
  onGoNext: () => void;
  activeStep: number;
  canGoNext: boolean;
  isLoading: boolean;
  onAssignTasks: () => void;
};

const Footer: FC<Props> = ({
  onGoNext,
  activeStep,
  canGoNext,
  isLoading,
  onAssignTasks,
}) => {
  if (activeStep === STEPS.SELECT_POSTS.step) {
    return (
      <Modal.Footer>
        <Button
          data-name="workflow-options-dropdown_assign-tasks_next-step"
          key="next-step"
          onClick={onGoNext}
          disabled={!canGoNext}
          iconAfter={<Icon icon={faChevronRight} />}
        >
          Next step
        </Button>
      </Modal.Footer>
    );
  }

  return (
    <Modal.Footer>
      <Button
        data-name="workflow-options-dropdown_assign-tasks_confirm"
        onClick={onAssignTasks}
        isLoading={isLoading}
        iconAfter={<Icon icon={faUserPlus} />}
      >
        Assign tasks
      </Button>
    </Modal.Footer>
  );
};

export default Footer;
