import React, { FC } from 'react';
import NOTFOUND from 'images/scenes/not-found.svg';
import SOMETHING_WENT_WRONG from 'images/scenes/something-went-wrong.svg';
import { Button } from '@kontentino/ui';
import { getBasePath } from 'utils/url';

type Props = {
  type?: FatalErrorType;
};

export type FatalErrorType = 'unknown' | 'notfound';

const FatalError: FC<Props> = ({ type = 'unknown' }) => {
  function getConfig() {
    switch (type) {
      case 'notfound':
        return {
          img: NOTFOUND,
          title:
            'Oops! This page seems to be exploring the Bermuda Triangle. 🌀',
          description: 'How about we navigate you out of it?',
          button: 'Return home',
          action: () => {
            document.location.href = document.location.origin + getBasePath();
          },
        };
      case 'unknown':
      default:
        return {
          img: SOMETHING_WENT_WRONG,
          title: 'Looks like a glitch in the matrix… 😵‍💫',
          description:
            'But no worries, our team has been informed and will resolve it in no time.',
          button: 'Try again',
          action: () => {
            window.location.reload();
          },
        };
    }
  }

  const config = getConfig();

  return (
    <main
      data-cy={`error-screen-${type}`}
      data-name={`error-screen-${type}`}
      className="tw-flex tw-h-[100vh] tw-flex-col tw-items-center tw-justify-center tw-bg-grayscale-20 tw-text-center"
    >
      <div className="tw-flex tw-w-[330px] tw-flex-col tw-items-center tw-justify-center tw-gap-8">
        <img className="tw-w-[260px]" src={config.img} alt={config.title} />
        <div className="tw-flex tw-flex-col tw-gap-3">
          <h1 className="tw-text-xl tw-font-semibold tw-text-black">
            {config.title}
          </h1>
          <p className="tw-text-md tw-text-grayscale-90">
            {config.description}
          </p>
        </div>
        <Button
          data-cy={`error-screen_button`}
          data-name={`error-screen_button`}
          onClick={config.action}
          size="large"
          className="tw-w-3/4"
        >
          {config.button}
        </Button>
      </div>
    </main>
  );
};

export default FatalError;
