import { createContext, ReactNode, useContext, useState } from 'react';
import useAiContentQueries from 'app/modules/aiContent/hooks/useAiContentQueries';
import { Account } from 'types/Account';

export type TextEditorAiContentContextState = {
  refineTextSelectionVisible: boolean;
  setRefineTextSelectionVisible: (value: boolean) => void;
  queries: ReturnType<typeof useAiContentQueries>;
};

const AiContentPopupContext = createContext<
  TextEditorAiContentContextState | undefined
>(undefined);

const TextEditorAiContentProvider = ({
  children,
  accountPlan,
  disabled,
}: {
  children: ReactNode;
  accountPlan: Account['plan'];
  disabled: boolean;
}) => {
  const [refineTextSelectionVisible, setRefineTextSelectionVisible] =
    useState(false);

  const queries = useAiContentQueries({ accountPlan, disabled });

  return (
    <AiContentPopupContext.Provider
      value={{
        refineTextSelectionVisible,
        setRefineTextSelectionVisible,
        queries,
      }}
    >
      {children}
    </AiContentPopupContext.Provider>
  );
};

export const useTextEditorAiContent = () => {
  const context = useContext(AiContentPopupContext);

  if (context === undefined) {
    throw new Error(
      'useAiContentPopup must be used within a AiContentPopupProvider',
    );
  }

  return context;
};

export default TextEditorAiContentProvider;
