import { EXTENDED_TIMEOUT } from 'constants/request';
import i18n from 'i18n';
import { AxiosResponse } from 'axios';

export function getExtendedTimeout(multiplier = 1) {
  return EXTENDED_TIMEOUT * multiplier;
}

function getApiUserErrorMessage(response?: AxiosResponse) {
  return (
    Object.values(response?.data?.errors ?? {})
      .flatMap((item) => item)
      .join(' ') || i18n.somethingWentWrong
  );
}

export function getUserMessage(response: AxiosResponse | undefined) {
  let userMessage;

  if (response?.data?.error_msg) {
    userMessage = response.data.error_msg;
  }

  if (response?.data?.user_error_msg) {
    userMessage = response?.data?.user_error_msg;
  }

  if (response?.data.errors) {
    userMessage = getApiUserErrorMessage(response);
  }

  if (response?.data?.error?.message) {
    userMessage = response.data.error.message;
  }

  return userMessage;
}
