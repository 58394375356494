import { FC } from 'react';
import ProfileSelectorDropdown from 'app/components/profileSelector/components/ProfileSelectorDropdown';
import ProfileSelectorPlaceholder from 'app/components/profileSelector/components/ProfileSelectorPlaceholder';
import { ProfileSelectorFilterProps } from 'app/components/profileSelector/types/filterProps';
import useProfileSelectorOpenState from 'app/components/profileSelector/hooks/useProfileSelectorOpenState';
import { ProfileSelectorPlaceholderData } from 'app/components/profileSelector/types/profileSelectorPlaceholder';

type Props = {
  optionsElement: JSX.Element;
  filter: ProfileSelectorFilterProps;
  footer?: JSX.Element;
  isSelectedAll: boolean;
  title: string;
  dataName?: {
    placeholder?: string;
    filter?: string;
    options?: string;
  };
  classNames?: {
    dropdownWrapperInner?: string;
  };
  placeholderData: ProfileSelectorPlaceholderData;
  onToggleAllSelection?: (value: 'all' | 'none') => void;
  initialOpen?: boolean;
};

const ProfileSelector: FC<Props> = ({
  optionsElement,
  placeholderData,
  title,
  filter,
  footer,
  dataName,
  onToggleAllSelection,
  isSelectedAll,
  initialOpen = false,
  classNames,
}) => {
  const { elementRef, isOpen, toggleOpen } =
    useProfileSelectorOpenState(initialOpen);

  return (
    <div ref={elementRef} className="tw-relative">
      <ProfileSelectorPlaceholder
        data={placeholderData}
        onClick={toggleOpen}
        dataName={dataName?.placeholder}
      />
      {isOpen && (
        <ProfileSelectorDropdown
          optionsElement={optionsElement}
          title={title}
          filter={filter}
          footer={footer}
          onToggleAllSelection={onToggleAllSelection}
          isSelectedAll={isSelectedAll}
          classNames={classNames}
          dataName={{
            options: dataName?.options,
            filter: dataName?.filter,
          }}
        />
      )}
    </div>
  );
};

export default ProfileSelector;
