import { FC, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useInsightsContainer } from 'components/insights/context';
import GraphInfoCard from 'components/insights/GraphInfoCard';
import Skeleton from 'components/insights/graphs/Skeleton';
import { GraphStructure } from 'types/insights/Graphs';
import { GraphType } from 'constants/analytics';
import { getPreselectedGraphType } from 'components/insights/pagePerformance/utils';
import { getQueryToUse } from 'components/insights/pagePerformance/utils/query';
import { getTransformedDates } from 'components/insights/pagePerformance/utils/dates';
import { Query } from 'graphql/insights/types';
import GraphDataFromatters from '../utils/graphDataFormatters';
import { GraphKeysForComparing } from 'constants/analyticsGraphs';
import { TYPE } from '@kontentino/kontentino-constants/Pages';

type Props = {
  id: string;
  pageType: number;
  graph: GraphStructure;
  onChangeSelectedGraphType?: (type: GraphType) => void;
  startDate?: string;
  endDate?: string;
  comparedStartDate?: string;
  comparedEndDate?: string;
  isOnExport?: boolean;
  dataName?: {
    card: string;
  };
};

const GraphSection: FC<Props> = ({ pageType, ...props }) => {
  const { filterState } = useInsightsContainer();

  const commonDatesParams = {
    filterDates: {
      startDate: filterState?.startDate,
      endDate: filterState?.endDate,
      comparedStartDate: filterState?.comparedStartDate,
      comparedEndDate: filterState?.comparedEndDate,
    },
    dateFrom: props.startDate,
    dateTo: props.endDate,
    comparedStartDate: props.comparedStartDate,
    comparedEndDate: props.comparedEndDate,
  };

  const { startDate, endDate } = getTransformedDates(commonDatesParams);
  const { startDate: comparedStartDate, endDate: comparedEndDate } =
    getTransformedDates(commonDatesParams, true);

  const skip =
    !comparedStartDate ||
    !comparedEndDate ||
    !([TYPE.FACEBOOK, TYPE.INSTAGRAM] as number[]).includes(pageType);
  const query = getQueryToUse(pageType);

  const { data: dataToCompare } = useQuery<
    Pick<Query, 'facebookPage'> | Pick<Query, 'instagramPage'>
  >(query, {
    variables: {
      id: props.id,
      metricName: props.graph.metricParameter,
      dateFrom: comparedStartDate,
      dateTo: comparedEndDate,
    },
    skip,
  });

  const { data, loading } = useQuery<
    Pick<Query, 'facebookPage'> | Pick<Query, 'instagramPage'>
  >(query, {
    variables: {
      id: props.id,
      dateFrom: startDate,
      dateTo: endDate,
      metricName: props.graph.metricParameter,
    },
  });

  const dataToCompareEdges = GraphDataFromatters.getEdges(dataToCompare);
  const edges = GraphDataFromatters.getEdges(data);

  const graphData = useMemo(() => {
    if (dataToCompareEdges && edges) {
      return (
        GraphDataFromatters.getComparedGraphData(edges, dataToCompareEdges) ??
        []
      );
    }

    return edges
      ? GraphDataFromatters.getBasicGraphData(edges, props.graph.primaryDataKey)
      : [];
  }, [edges, dataToCompareEdges, props.graph.primaryDataKey]);

  if (loading) {
    return <Skeleton />;
  }

  return (
    <GraphInfoCard
      title={props.graph.title}
      graph={props.graph}
      data={graphData}
      onChangeSelectedGraphType={props.onChangeSelectedGraphType}
      selectedGraphType={getPreselectedGraphType(props.graph)}
      isOnExport={props.isOnExport}
      primaryDataKey={
        dataToCompare
          ? GraphKeysForComparing.default
          : props.graph.primaryDataKey
      }
      secondaryDataKey={dataToCompare ? GraphKeysForComparing.compared : null}
      dataName={{
        card: props.dataName?.card,
      }}
    />
  );
};

export default GraphSection;
