import { FC } from 'react';
import Link from 'components/shared/Link';
import { KontentinoLogo } from '@kontentino/ui';
import routes from 'constants/routes';
import clsx from 'clsx';

type Props = {
  linkProps?: Record<string, any>;
  className?: string;
};

const Logo: FC<Props> = ({ linkProps, className, ...props }) => {
  return (
    <div
      className={clsx(
        'tw-overflow-hidden tw-pb-0 tw-pl-[17px] tw-pr-4 tw-pt-4',
        className,
      )}
      {...props}
    >
      <Link
        to={routes.POSTS_CALENDAR}
        className="tw-flex tw-overflow-hidden"
        isRouterLink
        {...linkProps}
      >
        <KontentinoLogo
          variant="primary-background"
          className="tw-w-[30px] tw-object-left"
        />
      </Link>
    </div>
  );
};

export default Logo;
