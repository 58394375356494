import { useState } from 'react';
import { useOutsideClick } from 'utils/hooks/useOutsideClick';
import { useEffectOnce } from 'utils/hooks/useEffectOnce';
import HotjarUtils from 'app/utils/hotjar';

const useProfileSelectorOpenState = (initialOpenState?: boolean) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    elementRef,
    addListener,
    removeListener,
  } = useOutsideClick<HTMLDivElement>(onHide);

  useEffectOnce(() => {
    if (initialOpenState) {
      onShow();
    }
  });

  function onToggle() {
    if (isOpen) {
      onHide();
    } else {
      onShow();
    }
  }

  function onShow() {
    HotjarUtils.trackEvent(HotjarUtils.eventKey.profileSelectorOpen);
    setIsOpen(true);
    addListener();
  }

  function onHide() {
    setIsOpen(false);
    removeListener();
  }

  return {
    elementRef,
    isOpen,
    toggleOpen: onToggle,
  };
};

export default useProfileSelectorOpenState;
