import React, { FC, lazy } from 'react';
import { modalRoutes } from 'constants/routes';
import UserPermissionGate from 'components/UserPermissionGate';
import { UserPermission } from 'constants/userPermission';
import ModalRoute from 'context/ModalRouter';

type Props = {};

const MovePosts = lazy(() => import('app/modules/posts/modules/movePosts'));

const RespondToApprovalModal = lazy(
  () =>
    import(
      'app/modules/posts/modules/respontToApprovalRequest/RespondToApprovalModal'
    ),
);
const InspirationDetail = lazy(() => import('pages/posts/InspirationDetail'));
const DeletePostModalRoute = lazy(
  () => import('app/modules/posts/modules/deletePost'),
);
const ManageProfileGroup = lazy(
  () => import('pages/posts/post/ManageProfileGroup'),
);
const CreateContentPlanPage = lazy(
  () => import('app/modules/posts/modules/createContentPlan'),
);
const AssignMultipleTasksPage = lazy(
  () => import('app/modules/posts/modules/assignMultipleTasks'),
);
const DuplicatePostsRoute = lazy(
  () => import('app/modules/posts/modules/duplicatePosts'),
);
const PostModal = lazy(() => import('app/modules/posts/modules/post'));
const PostsWelcomeModal = lazy(
  () => import('app/components/modals/welcome/PostsWelcomeModal'),
);
const PostsAddNewProfileModal = lazy(
  () => import('app/modules/posts/components/PostsAddNewProfileModal'),
);
const SelectSingleProfileModalRoute = lazy(
  () => import('app/modules/posts/modules/detail/modules/selectSingleProfile'),
);
const ChangePostsStatus = lazy(
  () => import('pages/posts/workflowOptions/ChangePostsStatus'),
);
const SharePreview = lazy(() => import('pages/posts/post/SharePreview'));
const SchedulePosts = lazy(
  () => import('pages/posts/workflowOptions/SchedulePosts'),
);
const SendForClientApproval = lazy(
  () => import('pages/posts/workflowOptions/SendForClientApproval'),
);
const SendForInternalApproval = lazy(
  () => import('pages/posts/workflowOptions/SendForInternalApproval'),
);
const InspirationUpsellModal = lazy(
  () => import('app/modules/posts/components/InspirationUpsellModal'),
);
const PostsAssociateProfileModal = lazy(
  () => import('app/modules/posts/components/PostsAssociateProfileModal'),
);

const PostRevisionDetailModal = lazy(
  () =>
    import('app/modules/posts/modules/post/components/PostRevisionDetailModal'),
);
const PostsModals: FC<Props> = (props) => {
  return (
    <>
      <ModalRoute path={modalRoutes.WELCOME} component={PostsWelcomeModal} />
      <ModalRoute path={modalRoutes.POST_DETAIL} component={PostModal} />
      <ModalRoute
        path={modalRoutes.POST_REVISION_DETAIL}
        component={PostRevisionDetailModal}
      />

      <ModalRoute
        path={modalRoutes.SELECT_SINGLE_PROFILE_BEFORE_COMPOSE}
        component={SelectSingleProfileModalRoute}
      />
      <UserPermissionGate
        scopes={[UserPermission.ACCESS_INSPIRATIONS]}
        deniedProps={{
          component: InspirationUpsellModal,
        }}
      >
        <ModalRoute
          path={modalRoutes.INSPIRATION_DETAIL}
          component={InspirationDetail}
        />
      </UserPermissionGate>
      <UserPermissionGate scopes={UserPermission.POST_DELETE}>
        <ModalRoute
          path={modalRoutes.DELETE_POST}
          component={DeletePostModalRoute}
        />
      </UserPermissionGate>
      <UserPermissionGate scopes={UserPermission.POST_MOVE}>
        <ModalRoute path={modalRoutes.MOVE_POSTS} component={MovePosts} />
      </UserPermissionGate>
      <UserPermissionGate scopes={UserPermission.POST_DUPLICATE}>
        <ModalRoute
          path={modalRoutes.DUPLICATE_POSTS}
          component={DuplicatePostsRoute}
        />
      </UserPermissionGate>
      <UserPermissionGate scopes={UserPermission.USER_ASSIGN_TASK}>
        <ModalRoute
          path={modalRoutes.ASSIGN_MULTIPLE_TASKS}
          component={AssignMultipleTasksPage}
        />
      </UserPermissionGate>
      <UserPermissionGate scopes={UserPermission.POST_MASS_CHANGE_POST_STATUS}>
        <ModalRoute
          path={modalRoutes.CHANGE_POSTS_STATUS}
          component={ChangePostsStatus}
        />
      </UserPermissionGate>
      <ModalRoute
        path={modalRoutes.RESPOND_TO_APPROVAL_REQUEST}
        component={RespondToApprovalModal}
      />
      <UserPermissionGate scopes={UserPermission.POST_SHARE_PREVIEW}>
        <ModalRoute path={modalRoutes.SHARE_PREVIEW} component={SharePreview} />
      </UserPermissionGate>
      <UserPermissionGate scopes={UserPermission.POST_SCHEDULE}>
        <ModalRoute
          path={modalRoutes.SCHEDULE_POSTS}
          component={SchedulePosts}
        />
      </UserPermissionGate>
      <UserPermissionGate scopes={UserPermission.POST_SEND_FOR_CLIENT_APPROVAL}>
        <ModalRoute
          path={modalRoutes.SEND_FOR_CLIENT_APPROVAL}
          component={SendForClientApproval}
        />
      </UserPermissionGate>
      <UserPermissionGate
        scopes={UserPermission.POST_SEND_FOR_INTERNAL_APPROVAL}
      >
        <ModalRoute
          path={modalRoutes.SEND_FOR_INTERNAL_APPROVAL}
          component={SendForInternalApproval}
        />
      </UserPermissionGate>
      <UserPermissionGate scopes={UserPermission.PAGE_CREATE}>
        <ModalRoute
          path={modalRoutes.NEW_PROFILE}
          component={PostsAddNewProfileModal}
        />
        <ModalRoute
          path={modalRoutes.ASSOCIATE_POSTS_PROFILE}
          component={PostsAssociateProfileModal}
        />
      </UserPermissionGate>
      <UserPermissionGate scopes={UserPermission.PAGE_PRESETS_MANAGE}>
        <ModalRoute
          path={modalRoutes.MANAGE_PROFILE_GROUP}
          component={ManageProfileGroup}
        />
      </UserPermissionGate>
      <UserPermissionGate scopes={UserPermission.PROJECT_CREATE}>
        <ModalRoute
          path={modalRoutes.NEW_CONTENT_PLAN}
          component={CreateContentPlanPage}
        />
      </UserPermissionGate>
    </>
  );
};

export default PostsModals;
