import { ExpenseName, TotalExpenseObject } from 'types/AnalyticsPost';
import { getObjectiveName } from './getObjectivesArray';

export const transformObjectives = (
  objectives: Record<ExpenseName, TotalExpenseObject> | undefined,
) =>
  objectives && Object.keys(objectives).length > 0
    ? Object.keys(objectives).map((objectiveKey) => ({
        name: getObjectiveName(objectiveKey),
        value: objectives[objectiveKey as keyof typeof objectives].budget,
      }))
    : undefined;
