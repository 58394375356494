import React, {
  FC,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Fading } from '@kontentino/kontentino-ui';
import Skeleton from 'react-loading-skeleton';
import { PublicUtils } from 'components/publicPreviews/publicUtils';
import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import { queryKey } from 'constants/queryKey';
import TextEditor from 'app/modules/textEditor';
import { TextEditorFeature } from 'app/modules/textEditor/constants/textEditorFeature';
import { Avatar, Icon, Tabs } from '@kontentino/ui';
import { useModalHistory } from 'context/ModalRouter';
import { modalRoutes } from 'constants/routes';
import { faMessage } from '@fortawesome/pro-regular-svg-icons';
import AccordionWidget from 'app/modules/posts/components/AccordionWidget';
import clsx from 'clsx';
import CommentsApi from 'app/modules/comments/api';
import { CommentsList } from 'app/modules/posts/modules/post/components/postCommunication/CommentsList';

interface CommentSectionProps {
  token: string;
}

const PUBLIC_USER = PublicUtils.publicMock.user;
const PUBLIC_ACCOUNT_PLAN = PublicUtils.publicMock.accountPlan;

export const CommentSection: FC<CommentSectionProps> = ({ token }) => {
  const [fadingVisible, setFadingVisible] = useState(true);
  const modalHistory = useModalHistory();
  const { postId } = useParams<{ postId: string }>();
  const listRef = useRef<HTMLDivElement>();

  const { data: commentsData, isLoading: areCommentsLoading } = useQuery(
    queryKey.publicPostComments({
      postId: Number(postId),
      token,
    }),
    () => CommentsApi.publicComments(Number(postId), { token }),
    { enabled: !!token && !!postId },
  );

  useEffect(() => {
    if (!!listRef.current) {
      const isScrollable =
        listRef?.current?.scrollHeight > listRef?.current?.clientHeight;

      if (isScrollable) {
        setFadingVisible(true);
      }
    }
  }, []);

  const onScroll = (e: any): void => {
    const distanceToEnd =
      e.target.scrollHeight -
      Math.abs(e.target.scrollTop) -
      e.target.clientHeight;

    if (distanceToEnd < 15) {
      setFadingVisible(false);
    } else {
      setFadingVisible(true);
    }
  };

  return (
    <AccordionWidget
      className="tw-w-full"
      title={
        <Tabs.Root>
          <Tabs.List>
            <Tabs.Tab value="comments">
              <Icon icon={faMessage} />
              Comments
              <div className="te-text-s tw-flex tw-h-4 tw-w-5 tw-items-center tw-justify-center tw-rounded tw-bg-danger-100 tw-p-1 tw-text-white">
                {commentsData?.data.length ?? 0}
              </div>
            </Tabs.Tab>
          </Tabs.List>
        </Tabs.Root>
      }
    >
      <div className={clsx({ 'tw-relative': fadingVisible })}>
        <div
          ref={listRef as MutableRefObject<any>}
          className="tw-my-3 tw-flex tw-max-h-[30vw] tw-flex-1 tw-flex-col-reverse tw-gap-1 tw-overflow-auto"
          onScroll={onScroll}
        >
          {areCommentsLoading ? (
            <Skeleton />
          ) : (
            commentsData && (
              <CommentsList
                comments={commentsData.data}
                hideUserInteractiveComponents
                currentUser={PUBLIC_USER}
              />
            )
          )}
        </div>
        <Fading isVisible={fadingVisible} className="tw-h-[50px]" />
      </div>
      <div
        className="tw-mt-3 tw-flex tw-items-center"
        onClick={() =>
          modalHistory.push({
            hash: modalRoutes.PUBLIC_INVITE_USER,
            state: { actionType: 'comment' },
          })
        }
      >
        <Avatar name={PUBLIC_USER.name} size={36} />
        <div className="tw-ml-2 tw-min-w-0 tw-flex-1">
          <TextEditor
            accountPlan={PUBLIC_ACCOUNT_PLAN}
            dataPrefix="comments"
            classNames={{
              root: 'tw-rounded-[1.5rem]',
              dropOverlay: 'tw-rounded-[1.5rem]',
              editorWrapper: 'tw-flex',
              editorContent: 'tw-flex-1',
              optionsBar: 'tw-p-0 tw-pl-2 tw-pr-2 tw-flex-shrink-0',
              editor: 'tw-min-h-[initial]',
            }}
            placeholder="Write a comment or @mention to notify..."
            features={[TextEditorFeature.EmojiPicker]}
          />
        </div>
      </div>
    </AccordionWidget>
  );
};
