export const INSIGHTS_FB_AND_IG_LOGIN_SCOPES = [
  'pages_manage_ads',
  'pages_manage_metadata',
  'pages_read_engagement',
  'pages_read_user_content',
  'pages_show_list',
  'read_insights',
  'ads_management',
  'instagram_basic',
  'instagram_manage_insights',
];
