import { OrderDirection } from 'app/types';

const ArrayUtils = {
  move<T extends unknown[]>(array: T, oldIndex: number, newIndex: number) {
    const clone = [...array];

    if (newIndex >= clone.length) {
      newIndex = clone.length - 1;
    }

    clone.splice(newIndex, 0, clone.splice(oldIndex, 1)[0]);

    return clone;
  },
  generate(length: number) {
    let generateArrayLength = length;

    if (generateArrayLength < 0) {
      generateArrayLength = 0;
    }

    return Array.from(Array(generateArrayLength).keys());
  },
  sortByKey: <T extends { [x: string]: any }>(
    data: T[],
    key: string,
    direction: OrderDirection = OrderDirection.Asc,
  ) => {
    const items = [...data];

    items.sort((a, b) => {
      const value1 = a[key].toString();
      const value2 = b[key].toString();

      return value1.localeCompare(value2);
    });

    return direction === OrderDirection.Desc ? items.reverse() : items;
  },
  sortByArray: (
    input: any[],
    options: {
      order: any[];
      key: string;
    },
  ) => {
    const { order, key } = options;

    return [...input].sort((a, b) => {
      const aIndex = order.indexOf(a[key]);
      const bIndex = order.indexOf(b[key]);

      return (
        (aIndex === -1 ? Infinity : aIndex) -
        (bIndex === -1 ? Infinity : bIndex)
      );
    });
  },
  shuffle: <T>(arr: T[]): T[] => {
    return Array(arr.length)
      .fill(null)
      .map((_, i) => [Math.random(), i])
      .sort(([a], [b]) => a - b)
      .map(([, i]) => arr[i]);
  },
};

export default ArrayUtils;
