import React, { FC } from 'react';
import { ShortenLink } from 'types/LinkShortener';
import Link from 'components/shared/Link';
import { useTranslation } from 'react-i18next';
import OptionsBarFeatureItem from '../optionsBarFeatureItem/OptionsBarFeatureItem';

type Props = {
  shortenLink: ShortenLink;
  onDelete(linkId: string): void;
  onEdit(link: ShortenLink): void;
  onCopyToClipboard(link: string): void;
  onCopyToText?(link: string): void;
  onStatistics?(linkId: string): void;
};

const LinkShortenerItem: FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <OptionsBarFeatureItem
      title={props.shortenLink.name}
      onDelete={() => props.onDelete(props.shortenLink.id)}
      onEdit={() => props.onEdit(props.shortenLink)}
      onCopyToClipboard={() =>
        props.onCopyToClipboard(props.shortenLink.shortUrl)
      }
      onStatisticsClick={() => props.onStatistics?.(props.shortenLink.id)}
      onCopyToText={
        props.onCopyToText
          ? () => props.onCopyToText?.(props.shortenLink.shortUrl)
          : undefined
      }
    >
      <div className="tw-flex tw-flex-col tw-gap-y-2">
        <div className="tw-text-sm">
          <span>{t('originalUrl')}:&nbsp;</span>
          <Link
            to={props.shortenLink.url}
            target="_blank"
            className="tw-break-words tw-font-medium tw-underline hover:tw-no-underline"
          >
            {props.shortenLink.url}
          </Link>
        </div>

        <div className="tw-text-sm">
          <span>{t('shortenedUrl')}:&nbsp;</span>
          <Link
            to={props.shortenLink.shortUrl}
            target="_blank"
            className="tw-break-words tw-font-medium tw-underline hover:tw-no-underline"
          >
            {props.shortenLink.shortUrl}
          </Link>
        </div>
      </div>
    </OptionsBarFeatureItem>
  );
};

export default LinkShortenerItem;
