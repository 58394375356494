import { Checkbox, DropdownMenu } from '@kontentino/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  name?: string;
  label: string;
  postsCount: number;
  iconBefore?: React.ReactNode;
  onSelect: () => void;
  isChecked: boolean;
  disabled?: boolean;
};

const PostsFiltersDropdownOption = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { t } = useTranslation();

    return (
      <div
        data-name={props.name}
        data-cy={props.name}
        className="tw-select-none"
        ref={ref}
      >
        <DropdownMenu.Item onSelect={props.onSelect} disabled={props.disabled}>
          <Checkbox
            size="small"
            onChange={() => null}
            checked={props.isChecked}
          />
          {props.iconBefore}
          <span className="tw-max-w-[200px] tw-truncate">{props.label}</span>
          <span className="tw-ml-auto tw-whitespace-nowrap tw-text-sm tw-lowercase tw-text-grayscale-100">
            {props.postsCount} {t('post', { count: props.postsCount })}
          </span>
        </DropdownMenu.Item>
      </div>
    );
  },
);

export default PostsFiltersDropdownOption;
