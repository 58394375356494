import {
  faBolt,
  faChartUser,
  faCircleEllipsisVertical,
  faComment,
  faDiamondTurnRight,
  faDollarSign,
  faHandHoldingSeedling,
  faHandPointer,
  faImage,
  faLinkSimple,
  faPhone,
  faShare,
  faThumbsUp,
  faVideo,
} from '@fortawesome/pro-regular-svg-icons';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import { PageMetricCardProperties } from '../types/graphs';
import {
  formatCompactPercentageValue,
  formatCompactValue,
} from 'app/modules/insights/modules/pagePerformance/utils/format';

export const LI_PAGE_METRIC_CARD_PROPERTIES: PageMetricCardProperties = {
  followerGrowth: {
    graphType: 'area',
    valueFormatter: formatCompactValue,
    graphLabels: {
      total: 'Total',
      organic: 'Organic',
      paid: 'Paid',
    },
    generalDataLabels: {
      total: 'Total',
      organic: 'Organic',
      paid: 'Paid',
      daily_average: 'Daily average',
    },
    detailDataLabels: {
      daily_average: {
        label: 'Daily avg.',
        icon: faChartUser,
      },
      average_organic: {
        label: 'Organic avg.',
        icon: faHandHoldingSeedling,
      },
      average_paid: {
        label: 'Paid avg.',
        icon: faDollarSign,
      },
    },
  },
  impressions: {
    graphType: 'area',
    valueFormatter: formatCompactValue,
    generalDataLabels: {
      total: 'Total',
      daily_average: 'Daily average',
    },
  },
  engagement: {
    graphType: 'area',
    valueFormatter: (value, { metricKey }) =>
      ['total', 'daily_average'].includes(metricKey)
        ? formatCompactPercentageValue(value)
        : formatCompactValue(value),
    graphLabels: {
      total: 'Total',
      shares: 'Shares',
      likes: 'Likes',
      comments: 'Comments',
      clicks: 'Clicks',
    },
    generalDataLabels: {
      total: 'Total',
      shares: 'Shares',
      likes: 'Likes',
      comments: 'Comments',
      clicks: 'Clicks',
      daily_average: 'Daily average',
    },
    detailDataLabels: {
      daily_average: {
        label: 'Daily avg.',
        icon: faBolt,
      },
      average_shares: {
        label: 'Shares avg.',
        icon: faShare,
      },
      average_likes: {
        label: 'Likes avg.',
        icon: faThumbsUp,
      },
      average_comments: {
        label: 'Comments avg.',
        icon: faComment,
      },
      average_clicks: {
        label: 'Clicks avg.',
        icon: faHandPointer,
      },
    },
  },
};

export const IG_PAGE_METRIC_CARD_PROPERTIES: PageMetricCardProperties = {
  followerGrowth: {
    valueFormatter: formatCompactValue,
    graphType: 'area',
    graphLabels: {
      total: 'Total',
      organic: 'Organic',
      paid: 'Paid',
    },
    generalDataLabels: {
      total: 'Total',
      organic: 'Organic',
      paid: 'Paid',
      daily_average: 'Daily average',
    },
    detailDataLabels: {
      daily_average: {
        label: 'Daily avg.',
        icon: faChartUser,
      },
      average_organic: {
        label: 'Organic avg.',
        icon: faHandHoldingSeedling,
      },
      average_paid: {
        label: 'Paid avg.',
        icon: faDollarSign,
      },
    },
  },
  impressions: {
    valueFormatter: formatCompactValue,
    graphType: 'area',
    generalDataLabels: {
      total: 'Total',
      daily_average: 'Daily average',
    },
  },
  reach: {
    valueFormatter: formatCompactValue,
    graphType: 'area',
    generalDataLabels: {
      total: 'Total',
      daily_average: 'Daily average',
    },
  },
  emailContacts: {
    valueFormatter: formatCompactValue,
    graphType: 'area',
    generalDataLabels: {
      total: 'Total',
      daily_average: 'Daily average',
    },
  },
  clicks: {
    valueFormatter: formatCompactValue,
    graphType: 'area',
    graphLabels: {
      total: 'Total',
      phone_call_clicks: 'Phone call clicks',
      get_directions_clicks: 'Get directions clicks',
      text_message_clicks: 'Text message clicks',
      website_clicks: 'Website clicks',
      email_contacts: 'Email contacts',
    },
    generalDataLabels: {
      total: 'Total',
      daily_average: 'Daily average',
      phone_call_clicks: 'Phone call clicks',
      get_directions_clicks: 'Get directions clicks',
      text_message_clicks: 'Text message clicks',
      website_clicks: 'Website clicks',
      email_contacts: 'Email contacts',
    },
    detailDataLabels: {
      daily_average: {
        label: 'Daily avg.',
        icon: faHandPointer,
      },
      average_phone_call_clicks: {
        label: 'Phone call clicks avg.',
        icon: faPhone,
      },
      average_get_directions_clicks: {
        label: 'Get directions clicks avg.',
        icon: faDiamondTurnRight,
      },
    },
  },
  engagement: {
    valueFormatter: formatCompactPercentageValue,
    graphType: 'area',
    graphLabels: {
      total: 'Total',
      shares: 'Shares',
      likes: 'Likes',
      comments: 'Comments',
      clicks: 'Clicks',
    },
    generalDataLabels: {
      total: 'Total',
      shares: 'Shares',
      likes: 'Likes',
      comments: 'Comments',
      clicks: 'Clicks',
      daily_average: 'Daily average',
    },
    detailDataLabels: {
      daily_average: {
        label: 'Daily avg.',
        icon: faBolt,
      },
      average_shares: {
        label: 'Shares avg.',
        icon: faShare,
      },
      average_likes: {
        label: 'Likes avg.',
        icon: faThumbsUp,
      },
      average_comments: {
        label: 'Comments avg.',
        icon: faComment,
      },
      average_clicks: {
        label: 'Clicks avg.',
        icon: faHandPointer,
      },
    },
  },
};

export const FB_PAGE_METRIC_CARD_PROPERTIES: PageMetricCardProperties = {
  engagement: {
    valueFormatter: formatCompactPercentageValue,
    graphType: 'area',
    generalDataLabels: {
      total: 'Total',
      daily_average: 'Daily average',
    },
  },
  engaged_users: {
    valueFormatter: formatCompactValue,
    graphType: 'area',
    generalDataLabels: {
      total: 'Total',
      daily_average: 'Daily average',
    },
  },
  post_engagement: {
    valueFormatter: formatCompactValue,
    graphType: 'area',
    generalDataLabels: {
      total: 'Total',
      daily_average: 'Daily average',
    },
  },
  page_consumption: {
    valueFormatter: formatCompactValue,
    graphType: 'area',
    withTable: true,
    graphLabels: {
      total: 'Total',
      link: 'Link',
      photo: 'Photo',
      video: 'Video',
      other: 'Other',
    },
    generalDataLabels: {
      total: 'Total',
      daily_average: 'Daily average',
      link: 'Link',
      photo: 'Photo',
      video: 'Video',
      other: 'Other',
    },
    detailDataLabels: {
      link: {
        label: 'Link',
        icon: faLinkSimple,
      },
      photo: {
        label: 'Photo',
        icon: faImage,
      },
      video: {
        label: 'Video',
        icon: faVideo,
      },
      other: {
        label: 'Other',
        icon: faCircleEllipsisVertical,
      },
    },
  },
  unique_page_consumption: {
    valueFormatter: formatCompactValue,
    graphType: 'area',
    withTable: true,
    graphLabels: {
      total: 'Total',
      link: 'Link',
      photo: 'Photo',
      video: 'Video',
      other: 'Other',
    },
    generalDataLabels: {
      total: 'Total',
      daily_average: 'Daily average',
      link: 'Link',
      photo: 'Photo',
      video: 'Video',
      other: 'Other',
    },
    detailDataLabels: {
      link: {
        label: 'Link',
        icon: faLinkSimple,
      },
      photo: {
        label: 'Photo',
        icon: faImage,
      },
      video: {
        label: 'Video',
        icon: faVideo,
      },
      other: {
        label: 'Other',
        icon: faCircleEllipsisVertical,
      },
    },
  },
  page_impressions: {
    valueFormatter: formatCompactValue,
    graphType: 'area',
    generalDataLabels: {
      total: 'Total',
      daily_average: 'Daily average',
    },
  },
  page_unique_impressions: {
    valueFormatter: formatCompactValue,
    graphType: 'area',
    generalDataLabels: {
      total: 'Total',
      daily_average: 'Daily average',
    },
  },
  paid_vs_organic: {
    valueFormatter: formatCompactValue,
    graphType: 'area',
    generalDataLabels: {
      total: 'Total',
      daily_average: 'Daily average',
      paid: 'Paid',
      organic: 'Organic',
    },
  },
  paid_vs_organic_unique: {
    valueFormatter: formatCompactValue,
    graphType: 'area',
    generalDataLabels: {
      total: 'Total',
      daily_average: 'Daily average',
      paid: 'Paid unique',
      organic: 'Organic unique',
    },
  },
  viral_vs_nonviral: {
    valueFormatter: formatCompactValue,
    graphType: 'area',
    generalDataLabels: {
      total: 'Total',
      daily_average: 'Daily average',
      viral: 'Viral',
      nonviral: 'Nonviral',
    },
  },
  viral_vs_nonviral_unique: {
    valueFormatter: formatCompactValue,
    graphType: 'area',
    generalDataLabels: {
      total: 'Total',
      daily_average: 'Daily average',
      viral: 'Viral unique',
      nonviral: 'Nonviral unique',
    },
  },
  viral_frequency_distribution: {
    valueFormatter: formatCompactValue,
    graphType: 'area',
    generalDataLabels: {
      total: 'Total',
      daily_average: 'Daily average',
    },
  },
};

export const pageMetricCardProperty: Record<string, PageMetricCardProperties> =
  {
    [TYPE.FACEBOOK]: FB_PAGE_METRIC_CARD_PROPERTIES,
    [TYPE.INSTAGRAM]: IG_PAGE_METRIC_CARD_PROPERTIES,
    [TYPE.LINKEDIN]: LI_PAGE_METRIC_CARD_PROPERTIES,
  };
