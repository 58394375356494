import { postTypeFilterOptions } from '../constants/postTypeFilter';
import { getIsMetricDisabled } from './getIsMetricDisabled';

export const getSupportedPostTypesForMetric = (
  metricKey: string,
  pageType: number,
) => {
  return postTypeFilterOptions[pageType]
    .filter(
      (postType) =>
        !getIsMetricDisabled({
          metric: metricKey,
          pageType,
          optionType: postType.type,
        }),
    )
    .map((postType) => postType.type);
};
