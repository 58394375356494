import { useMemo, useRef } from 'react';
import { LightBoxInstance } from '@kontentino/ui';
import { Comment } from 'app/modules/comments/types';
import MediaUtils from 'app/utils/media';

export function useCommentsLightbox(comments: Comment[]) {
  const lightbox = useRef<LightBoxInstance>();

  const allImages = useMemo(
    () =>
      comments
        .map((comment) =>
          comment.attachments
            .filter((file) =>
              MediaUtils.getAcceptedImageAttachments().mimeTypes.includes(
                file.mime_type?.toLowerCase() || '',
              ),
            )
            .map((image) => ({
              id: image.id,
              src: image.large_size_url,
              thumb: image.thumb_size_url,
              alt: `Send by: ${comment.user.name}`,
            })),
        )
        .flat(),
    [comments],
  );

  return {
    allImages,
    lightbox,
  };
}
