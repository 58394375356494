import React, { FC } from 'react';
import Picker from '@emoji-mart/react';

export type Emoji = {
  id: string;
  keywords: string[];
  name: string;
  native: string;
  shortcodes: string;
  unified: string;
};

type Props = {
  onEmojiSelect: (emoji: Emoji, event: React.MouseEvent) => void;
  theme?: 'auto' | 'light' | 'dark';
  perLine?: number;
};

const DEFAULT_EMOJI_PER_LINE = 10;

const EmojiPicker: FC<Props> = ({
  onEmojiSelect,
  theme = 'light',
  perLine = DEFAULT_EMOJI_PER_LINE,
}) => {
  return (
    <Picker onEmojiSelect={onEmojiSelect} theme={theme} perLine={perLine} />
  );
};

export default EmojiPicker;
