import { CommentAction, CommentType } from 'app/modules/comments/types';
import { STATUS } from '@kontentino/kontentino-constants/Posts';

export const CommentConvertor = {
  commentTypeToStatus(commentType: CommentType): number | undefined {
    const commentTypeToStatus: Partial<Record<CommentType, number>> = {
      client_approval_request: STATUS.AT_CLIENT,
      internal_approval_request: STATUS.WAITING_FOR_INTERNAL_APPROVAL,
      client_approved: STATUS.APPROVED,
      internally_approved: STATUS.INTERNAL_APPROVED,
      client_rejected: STATUS.REJECTED_BY_CLIENT,
      internally_rejected: STATUS.REJECTED,
      scheduled: STATUS.SCHEDULED,
      task_done: STATUS.DONE,
      task_assigned: STATUS.WAITING_FOR,
      comment: undefined,
    };

    return commentTypeToStatus[commentType];
  },
  commentActionToStatus(action: CommentAction | null) {
    if (action === null || action === 'edit' || action === 'reply') {
      throw new Error('Cannot convert edit or reply action to status');
    }

    const commentActionToStatus = {
      client_rework: STATUS.REJECTED_BY_CLIENT,
      client_approval: STATUS.APPROVED,
      internal_rework: STATUS.REJECTED,
      internal_approval: STATUS.INTERNAL_APPROVED,
    } as const;

    return commentActionToStatus[action];
  },
  statusToCommentType(status: number): CommentType {
    const statusToCommentType: Partial<Record<number, CommentType>> = {
      [STATUS.AT_CLIENT]: 'client_approval_request',
      [STATUS.WAITING_FOR_INTERNAL_APPROVAL]: 'internal_approval_request',
      [STATUS.APPROVED]: 'client_approved',
      [STATUS.INTERNAL_APPROVED]: 'internally_approved',
      [STATUS.REJECTED_BY_CLIENT]: 'client_rejected',
      [STATUS.REJECTED]: 'internally_rejected',
      [STATUS.SCHEDULED]: 'scheduled',
      [STATUS.DONE]: 'task_done',
      [STATUS.WAITING_FOR]: 'task_assigned',
    };

    const commentType = statusToCommentType[status];

    if (commentType === undefined) {
      throw new Error('Cannot convert post status to comment type');
    }

    return commentType;
  },
  statusToCommentAction(status: number): CommentAction {
    const postStatusToCommentAction: Partial<Record<number, CommentAction>> = {
      [STATUS.REJECTED_BY_CLIENT]: 'client_rework',
      [STATUS.APPROVED]: 'client_approval',
      [STATUS.REJECTED]: 'internal_rework',
      [STATUS.INTERNAL_APPROVED]: 'internal_approval',
    };

    const action = postStatusToCommentAction[status];

    if (action === undefined) {
      throw new Error('Cannot convert post status to comment action');
    }

    return action;
  },
};
