import React, { FC } from 'react';
import { Icon } from '@kontentino/ui';
import { faBan } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

type Props = {
  isAuthor: boolean;
  colorVariant?: 'primary' | 'purple';
  disableMentionStyle?: boolean;
};

const CommentDeleted: FC<Props> = ({
  disableMentionStyle,
  isAuthor,
  colorVariant,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx('tw-flex tw-items-center tw-gap-x-1', {
        'tw-text-primary-30':
          !disableMentionStyle && isAuthor && colorVariant === 'primary',
        'tw-text-purple-30':
          !disableMentionStyle && isAuthor && colorVariant === 'purple',
        'tw-text-grayscale-70': !isAuthor || disableMentionStyle,
      })}
    >
      <Icon icon={faBan} size={12} />
      <span>
        {isAuthor && t('commentMessage.deleted.sent')}
        {!isAuthor && t('commentMessage.deleted.received')}
      </span>
    </div>
  );
};

export default CommentDeleted;
