import { ApiClientError, queryClient } from 'api/client';
import { LinkShortenerApi } from 'api/linkShortener';
import { showToast } from 'app/hooks/useToast';
import { queryKey } from 'constants/queryKey';
import Logger from 'utils/logger';
import { copyToClipboard } from 'app/hooks/useCopyToClipboard';
import { BrowserUtils } from 'utils/browser';
import i18n from 'app/utils/i18n';

export const copyLinkToClipboard = async (url: string) => {
  if (BrowserUtils.isSafari()) {
    copyToClipboard(url, { message: i18n.t('linkCopied') });
  } else {
    await queryClient.executeMutation({
      mutationFn: async () => {
        const res = await LinkShortenerApi.save({ url });
        await copyToClipboard(res.shortUrl, {
          message: i18n.t('linkCopied'),
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey.shortenLinks]);
      },
      onError: (e: ApiClientError) => {
        Logger.error(e);
        showToast(e.userMessage ?? i18n.t('linkCopyFailed'), 'error');
      },
    });
  }
};
