import { gql } from '@apollo/client';
import { OBJECTIVE_ITEM_FRAGMENT } from '../fragments/objectives';

export const FB_POSTS_QUERY_NEW = gql`
  ${OBJECTIVE_ITEM_FRAGMENT}
  query fbPostsQueryNew(
    $pageId: String!
    $first: Int
    $after: String
    $startDate: String!
    $endDate: String!
    $orderBy: [FacebookPostsOrder]
    $types: [Int]
  ) {
    posts: facebookPosts(
      facebookPageId: $pageId
      first: $first
      after: $after
      dateFrom: $startDate
      dateTo: $endDate
      orderBy: $orderBy
      types: $types
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          _id
          type
          picture
          message
          createdTime
          attachments {
            title
            description
            url
            unshimmed_url
            media_type
            media {
              source
              image {
                source
              }
            }
            subattachments {
              title
              description
              url
              unshimmed_url
              media_type
              media {
                source
                image {
                  source
                }
              }
            }
          }
          metrics {
            edges {
              node {
                id
                metricName
                metricValue
                additionalData {
                  edges {
                    node {
                      name
                      value
                    }
                  }
                }
              }
            }
          }
          totalExpenses {
            APP_INSTALLS {
              ...ObjectiveItem
            }
            BRAND_AWARENESS {
              ...ObjectiveItem
            }
            CONVERSIONS {
              ...ObjectiveItem
            }
            EVENT_RESPONSES {
              ...ObjectiveItem
            }
            LEAD_GENERATION {
              ...ObjectiveItem
            }
            LINK_CLICKS {
              ...ObjectiveItem
            }
            LOCAL_AWARENESS {
              ...ObjectiveItem
            }
            MESSAGES {
              ...ObjectiveItem
            }
            OFFER_CLAIMS {
              ...ObjectiveItem
            }
            PAGE_LIKES {
              ...ObjectiveItem
            }
            POST_ENGAGEMENT {
              ...ObjectiveItem
            }
            PRODUCT_CATALOG_SALES {
              ...ObjectiveItem
            }
            REACH {
              ...ObjectiveItem
            }
            STORE_VISITS {
              ...ObjectiveItem
            }
            VIDEO_VIEWS {
              ...ObjectiveItem
            }
            OUTCOME_AWARENESS {
              ...ObjectiveItem
            }
            OUTCOME_ENGAGEMENT {
              ...ObjectiveItem
            }
            OUTCOME_SALES {
              ...ObjectiveItem
            }
            OUTCOME_TRAFFIC {
              ...ObjectiveItem
            }
          }
        }
      }
    }
  }
`;
