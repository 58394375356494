import { FC, useMemo } from 'react';
import VerticalNavigation from 'components/navigation/VerticalNavigation';
import { getNavigationItems } from 'components/navigation/utils/libs';
import { useUser } from 'modules/user/userSelector';
import { useAccount } from 'modules/account/accountSelector';
import { useLocation } from 'react-router-dom';
import { useAppLayoutState } from './appLayout/appLayoutState';

type Props = {};

const Navigation: FC<Props> = () => {
  const { pathname } = useLocation();
  const user = useUser();
  const account = useAccount();
  const { insightSelectedPageType } = useAppLayoutState();
  const items = getNavigationItems({
    account,
    user,
    insightSelectedPageType,
  });

  const activeMenuItem = useMemo(
    () =>
      items.find(
        (item) =>
          (item.routerUrl && pathname === item.routerUrl) ||
          pathname.includes(`${item.routerUrl}/`),
      ),
    [pathname, items],
  );

  return (
    <VerticalNavigation
      menuItems={items}
      activeItem={activeMenuItem}
      user={user}
    />
  );
};

export default Navigation;
