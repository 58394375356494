import { Module } from 'config';

export const QUERY_KEY = {
  AD_SET_DETAIL: 'adSetDetail',
  AD_SETS_FOR_CAMPAIGN: 'adSetsForCampaign',
  ADS_FOR_AD_SET: 'adsForAdSet',
  AD_DATA_REQUEST: 'adDataRequest',
  CAMPAIGNS: 'campaigns',
  DATELESS_CAMPAIGNS: 'datelessCampaigns',
  AD_REQUIREMENTS: 'adRequirements',
  CAMPAIGNS_ON_REMOTE: 'campaignsOnRemote',
  SYNC_SELECTED_REMOTE_CAMPAIGNS: 'syncSelectedRemoteCampaigns',
  AD_ACCOUNTS: 'adAccounts',
  ASSOCIATED_INSTAGRAM_ACCOUNTS: 'associatedInstagramAccounts',
  EXTERNAL_AD_PREVIEWS: 'externalAdPreviews',
};

export const FACEBOOK_SCOPES = {
  CONNECT_AD_ACCOUNT: [
    'pages_manage_ads',
    'pages_show_list',
    'pages_read_engagement',
    'pages_manage_posts',
    'public_profile',
    'ads_read',
    'ads_management',
  ],
};

export enum CampaignBudgetType {
  Daily = 'daily_budget',
  Lifetime = 'lifetime_budget',
}

export enum FacebookAdFormat {
  DesktopFeedStandard = 'DESKTOP_FEED_STANDARD',
  FacebookStoryMobile = 'FACEBOOK_STORY_MOBILE',
  InstantArticleStandard = 'INSTANT_ARTICLE_STANDARD',
  InstreamVideoDekstop = 'INSTREAM_VIDEO_DESKTOP',
  InstreamVideoMobile = 'INSTREAM_VIDEO_MOBILE',
  MarketplaceDesktop = 'MARKETPLACE_DESKTOP',
  MarketplaceMobile = 'MARKETPLACE_MOBILE',
  MobileFeedBasic = 'MOBILE_FEED_BASIC',
  MobileFeedStandard = 'MOBILE_FEED_STANDARD',
  RightColumnStandard = 'RIGHT_COLUMN_STANDARD',
  SuggestedVideoDesktop = 'SUGGESTED_VIDEO_DESKTOP',
  SuggestedVideoMobile = 'SUGGESTED_VIDEO_MOBILE',
  WatchFeedMobile = 'WATCH_FEED_MOBILE',
}

export const BrowserStorageKey = {
  AD_PREVIEW_TYPE: `${Module.AdsPlanner.BROWSER_STORAGE_PREFIX}preview-type`,
  AD_ACCOUNT: `${Module.AdsPlanner.BROWSER_STORAGE_PREFIX}ad-account`,
  AD_ACCOUNT_DATA: `${Module.AdsPlanner.BROWSER_STORAGE_PREFIX}ad-account-data`,
  CAMPAIGN_ANALYTICS_OPEN_STATE: `${Module.AdsPlanner.BROWSER_STORAGE_PREFIX}campaign-analytics`,
};
