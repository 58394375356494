import React, { FC } from 'react';
import getPreselectedValuesFromParams from 'app/components/timeSavers/assignMultipleTasksModal/utils/getPreselectedValuesFromParams';
import { ModalRouteProps } from 'context/ModalRouter';
import { useToast } from 'app/hooks/useToast';
import SendForApprovalPreselectedPosts from 'pages/posts/workflowOptions/sendForApproval/SendForApprovalPreselectedPosts';
import SendForApprovalWithPostsSelection from 'pages/posts/workflowOptions/sendForApproval/SendForApprovalWithPostsSelection';
import { useAdsPannerContext } from 'app/modules/adsPlanner/context';

type Props = ModalRouteProps;

const SendForClientApprovalRoute: FC<Props> = (props) => {
  const toast = useToast();
  const { resetAdsSelection } = useAdsPannerContext();

  const hasPreselectedAds = (): boolean => {
    const preselectedValues = getPreselectedValuesFromParams(
      props.modalManager,
    );

    return preselectedValues.posts.length !== 0;
  };

  const handleSuccessRequestTasks = () => {
    resetAdsSelection();
    toast('Ads successfully sent for approval.', 'success');
    props.modalManager.close();
  };

  if (hasPreselectedAds()) {
    return (
      <SendForApprovalPreselectedPosts
        type="client"
        onSuccessFormResponse={handleSuccessRequestTasks}
        modalManager={props.modalManager}
      />
    );
  }

  return (
    <SendForApprovalWithPostsSelection
      type="client"
      onSuccessFormResponse={handleSuccessRequestTasks}
      modalManager={props.modalManager}
    />
  );
};

export default SendForClientApprovalRoute;
