import { get } from 'api/client';
import config from 'config';
import { sleep } from 'utils/helpers';
import { Job, JobBatchStatus } from 'app/modules/insights/types/Job';
import TimeUtils from 'app/utils/time';

type JobOptions = {
  abortSignal: AbortSignal;
  onProgress: (value: JobBatchStatus | null) => void;
  recheckInterval: number;
  timeout: number;
};

const AnalyticsJobsApi = {
  checkJobStatus: (name: string): Promise<Job> => {
    return get(`api/jobs/check/${name}`, {
      baseURL: config.ANALYTICS_ENDPOINT,
    });
  },
  checkJobBatchStatus: (name: string): Promise<JobBatchStatus> => {
    return get(`api/jobs/check/batch/${name}`, {
      baseURL: config.ANALYTICS_ENDPOINT,
    });
  },
  async waitUntilJobCompletion(jobs: Job[], options?: Partial<JobOptions>) {
    const { wait, clear, abortSignal } = prepareJobCompletion(options);

    let completed = false;

    while (!completed && !abortSignal.aborted) {
      const statuses = await Promise.all(
        jobs.map((job) => AnalyticsJobsApi.checkJobStatus(job.name)),
      );

      if (statuses.some((job) => job.isFailed)) {
        return Promise.reject('Queued job to fetch data has failed.');
      }

      if (statuses.every((job) => job.isDone)) {
        completed = true;
        break;
      }

      await wait();
    }

    clear();

    return Promise.resolve({ completed });
  },
  async waitUntilJobBatchCompletion(
    jobBatchId: string,
    options?: Partial<JobOptions>,
  ) {
    const { onProgress, clear, wait, abortSignal } = prepareJobCompletion(
      options,
    );

    let jobBatchStatus: undefined | JobBatchStatus;
    let completed = false;

    while (!completed && !abortSignal.aborted) {
      jobBatchStatus = await AnalyticsJobsApi.checkJobBatchStatus(jobBatchId);
      onProgress(jobBatchStatus);

      if (['finished', 'failed'].includes(jobBatchStatus.status)) {
        completed = true;
        break;
      }

      await wait();
    }

    clear();

    return Promise.resolve({ completed, jobBatchStatus });
  },
};

function prepareJobCompletion(options?: Partial<JobOptions>) {
  const { abortSignal, onProgress, recheckInterval, timeout } = {
    abortSignal: options?.abortSignal ?? new AbortController().signal,
    onProgress: options?.onProgress ?? (() => {}),
    recheckInterval:
      options?.recheckInterval ?? TimeUtils.calcMilliseconds.seconds(3),
    timeout: options?.timeout ?? TimeUtils.calcMilliseconds.minutes(5),
  };

  const abortTimeout = setTimeout(
    () => abortSignal.dispatchEvent(new Event('abort')),
    timeout,
  );

  const clear = () => {
    if (abortTimeout) {
      clearTimeout(abortTimeout);
    }
  };

  const wait = () => sleep(recheckInterval);

  return {
    onProgress,
    wait,
    clear,
    abortSignal,
  };
}

export default AnalyticsJobsApi;
