import { FilterState } from 'app/modules/insights/hooks/useFilterState';
import DateUtils from 'app/utils/date';

type TransformDateParams = {
  dateFrom?: string;
  dateTo?: string;
  comparedStartDate?: string;
  comparedEndDate?: string;
  filterDates?: Pick<
    FilterState,
    'startDate' | 'endDate' | 'comparedStartDate' | 'comparedEndDate'
  >;
};

const getDates = (
  params: TransformDateParams,
  returnComparedDates?: boolean,
) => {
  if (!!params.filterDates?.startDate && !!params.filterDates?.endDate) {
    const startDate = returnComparedDates
      ? params.filterDates.comparedStartDate
      : params.filterDates.startDate;
    const endDate = returnComparedDates
      ? params.filterDates.comparedEndDate
      : params.filterDates.endDate;

    return {
      dateFrom: !!startDate ? DateUtils.toDateString(startDate) : null,
      dateTo: !!endDate ? DateUtils.toDateString(endDate) : null,
    };
  }
  if (returnComparedDates) {
    return {
      dateFrom: params.comparedStartDate,
      dateTo: params.comparedEndDate,
    };
  }
  if (!!params.dateFrom && !!params.dateTo) {
    return {
      dateFrom: params.dateFrom,
      dateTo: params.dateTo,
    };
  }
};

export const getTransformedDates = (
  params: TransformDateParams,
  returnComparedDates?: boolean,
) => {
  const dates = getDates(
    {
      dateFrom: params.dateFrom,
      dateTo: params.dateTo,
      comparedStartDate: params.comparedStartDate,
      comparedEndDate: params.comparedEndDate,
      filterDates: params.filterDates,
    },
    returnComparedDates,
  );

  return {
    startDate: dates ? dates.dateFrom : undefined,
    endDate: dates ? dates.dateTo : undefined,
  };
};
