import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

type Props = {};

const SinglePostSkeleton: FC<Props> = () => {
  return (
    <div className="tw-mt-4 tw-flex tw-rounded-md tw-bg-white tw-p-4">
      <div className="tw-mr-4 tw-h-[192px] tw-w-[192px] tw-overflow-hidden tw-rounded-md">
        <Skeleton width="100%" height="100%" style={{ lineHeight: 'unset' }} />
      </div>
      <div className="tw-flex tw-flex-1 tw-rounded-md tw-border tw-border-solid tw-border-grayscale-20">
        <div className="tw-h-full tw-flex-[2]">
          <div className="tw-h-[50%] tw-gap-4 tw-p-4">
            <Skeleton width="100%" height="20px" />
            <Skeleton width="35%" height="20px" style={{ marginTop: 16 }} />
          </div>
          <div className="tw-flex tw-h-[50%]  tw-border-t tw-border-solid tw-border-grayscale-20">
            <div className="tw-flex-1 tw-p-4">
              <Skeleton width="45%" height="16px" />
              <Skeleton width="100%" height="20px" style={{ marginTop: 16 }} />
            </div>
            <div className="tw-h-full tw-w-[1px] tw-bg-grayscale-20" />
            <div className="tw-flex-[2] tw-p-4">
              <Skeleton width="45%" height="16px" />
              <Skeleton width="100%" height="20px" style={{ marginTop: 16 }} />
            </div>
            <div className="tw-h-full tw-w-[1px] tw-bg-grayscale-20" />
            <div className="tw-flex-1 tw-p-4">
              <Skeleton width="45%" height="16px" />
              <Skeleton width="100%" height="20px" style={{ marginTop: 16 }} />
            </div>
          </div>
        </div>
        <div className="tw-h-full tw-flex-1 tw-border-l tw-border-solid tw-border-grayscale-20">
          <div className="tw-h-[50%] tw-gap-4 tw-p-4">
            <Skeleton width="35%" height="16px" />
            <Skeleton width="100%" height="20px" style={{ marginTop: 16 }} />
          </div>
          <div className="tw-flex tw-h-[50%] tw-border tw-border-solid tw-border-grayscale-20">
            <div className="tw-flex tw-p-4">
              <Skeleton width="40%" height="16px" />
              <div className="tw-flex tw-w-full tw-gap-4">
                <div className="tw-flex-1">
                  <Skeleton height="36px" />
                </div>
                <div className="tw-flex-1">
                  <Skeleton height="36px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SinglePostSkeleton;
