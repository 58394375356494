import React, { FC } from 'react';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import InstagramValueBoxes from './metricsValueBoxes/InstagramValueBoxes';
import LinkedInValueBoxes from './metricsValueBoxes/LinkedInValueBoxes';
import FacebookValueBoxes from './metricsValueBoxes/FacebookValueBoxes';
import { StandardPostDetailed } from 'app/types/StandardPostDetailed';

type Props = {
  post: StandardPostDetailed;
  pageType: number;
};

const MetricsValueBoxes: FC<Props> = ({ post, pageType }) => {
  switch (pageType) {
    case TYPE.FACEBOOK:
      return (
        <FacebookValueBoxes
          totalExpenses={post.totalExpenses}
          metrics={post.metrics}
        />
      );
    case TYPE.INSTAGRAM:
      return <InstagramValueBoxes metrics={post.metrics} />;
    case TYPE.LINKEDIN:
      return <LinkedInValueBoxes metrics={post.metrics} />;
    default:
      return null;
  }
};

export default MetricsValueBoxes;
