import { FC, useEffect } from 'react';
import { PageNumberType, TYPE } from '@kontentino/kontentino-constants/Pages';
import { getQueryParameters } from 'utils/url';
import { useQuery } from '@apollo/client';
import { getQueryToUse } from 'components/insights/pagePerformance/utils/query';

export type PagePerformanceParams = {
  id: string;
  dateFrom: string;
  dateTo: string;
  comparedStartDate?: string;
  comparedEndDate?: string;
  pageType: PageNumberType;
  restricted: boolean;
};

type PageExportState = {
  graphData: PagePerformanceParams | null;
  pageName: string;
};

type Props = {
  children: (props: PageExportState) => JSX.Element;
};

const Container: FC<Props> = ({ children }) => {
  const {
    pageType,
    id,
    dateFrom,
    dateTo,
    comparedStartDate,
    comparedEndDate,
    restricted,
  } = getQueryParameters(
    [
      'pageType',
      'id',
      'dateFrom',
      'dateTo',
      'comparedStartDate',
      'comparedEndDate',
      'restricted',
    ],
    window.location.search,
  );

  const { data } = useQuery(getQueryToUse(Number(pageType) as PageNumberType), {
    variables: {
      id,
      dateFrom: dateFrom,
      dateTo: dateTo,
    },
  });

  useEffect(() => {
    if (data) {
      document.body.classList.add('ready');
    }
  }, [data]);

  const getParams = () => {
    if (!!pageType && !!id && !!dateFrom && !!dateTo && !!restricted) {
      const params: PagePerformanceParams = {
        id,
        dateFrom,
        dateTo,
        pageType: Number(pageType) as PageNumberType,
        restricted: !!Number(restricted),
      };

      if (!!comparedStartDate && !!comparedEndDate) {
        params.comparedStartDate = comparedStartDate;
        params.comparedEndDate = comparedEndDate;
      }

      return params;
    }

    return null;
  };

  const getPageName = () => {
    if (!!data) {
      if (Number(pageType) === TYPE.FACEBOOK) {
        return `${data.facebookPage.name} - Facebook`;
      }

      return `${data.instagramPage.name} - Instagram`;
    }

    return '';
  };

  return children({
    graphData: getParams(),
    pageName: getPageName(),
  });
};

export default Container;
