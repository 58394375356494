const OldApp = {
  URL: {
    DASHBOARD: '/dashboard',
    DASHBOARD_FORCE: '/dashboard?forceOldDashboard=true',
    WORKFLOW: '/workflow',
    QR_LOGIN: '/user/qrCodeLogin',
    MY_PROFILE: '/user/myProfile',
    MY_DEVICES: '/user/myDevices',
    BUSINESS_INTEGRATIONS: {
      FACEBOOK: '/user/businessIntegrations?channel=facebook',
      LINKED_IN: '/user/businessIntegrations?channel=linkedin',
      PINTEREST: '/user/businessIntegrations?channel=pinterest',
    },
    NOTIFICATIONS_SETTINGS: '/UserNotifications/settings',
    BILLING: '/billing/index',
    BILLING_PLANS: '/billing/selectPlan',
    MEDIA_LIBRARY: '/Albums/index',
    POSTS: '/posts',
    ENGAGE: '/engage/manage',
    ANALYTICS: '/analytics',
    SETTINGS: {
      PROFILES: '/projects/pages',
      PROFILE_GROUPS: '/presets',
      KONTENT_BASE: '/projects/pages/type:global',
      USERS: '/user/listUsers',
      USER: '/user/list/id:',
      BILLING_DASHBOARD: '/billing/index',
      COMPANY_SETTINGS: '/dashboard/settings',
    },
    HELP_CENTER: {
      TUTORIALS: 'https://vimeo.com/showcase/9925454',
      RESOURCE_CENTER:
        'https://intercom.help/kontentino/en/collections/3375428-resource-centre',
      BOOK_DEMO: 'https://www.kontentino.com/book-a-demo',
      PRODUCT_ROADMAP: 'https://www.kontentino.com/roadmap',
    },
  },
};

export default OldApp;
