import React, { FC } from 'react';

type Props = {
  legendColor?: string;
  label: string;
  value: number;
};

const AdditionalDataRow: FC<Props> = ({ legendColor, label, value }) => {
  return (
    <div className="tw-my-3 tw-flex tw-flex-row tw-items-center tw-justify-between">
      <div className="tw-flex tw-flex-row tw-items-center">
        {legendColor && (
          <div
            style={{
              backgroundColor: legendColor,
            }}
            className="tw-mr-2 tw-h-2 tw-w-2 tw-rounded-[50%]"
          />
        )}
        <div className="tw-text-md tw-font-medium tw-text-grayscale-100">
          {label}
        </div>
      </div>
      <div className="tw-flex tw-flex-col">
        <div className="tw-text-base tw-font-bold tw-text-grayscale-100">
          {value}
        </div>
      </div>
    </div>
  );
};

export default AdditionalDataRow;
