import { mergeTailwindClasses } from '@kontentino/ui';
import React from 'react';

type RedDotIndicatorProps = {
  className?: string;
};

export const RedDotIndicator = ({ className }: RedDotIndicatorProps) => {
  return (
    <div
      className={mergeTailwindClasses(
        'tw-h-2 tw-w-2 tw-rounded-full tw-bg-danger-100',
        className,
      )}
    ></div>
  );
};
