import { SUBTYPE, TYPE } from '@kontentino/kontentino-constants/Posts';
import { PseudoPostType } from 'constants/post';
import { AttachmentCardMedium, Attachments } from 'types/Attachments';
import { ListPost } from 'types/PostDetail';

export function getPseudoTypeByPost(post: { type: number; subtype: number }) {
  switch (post.type) {
    case TYPE.AD:
      return PseudoPostType.Ad;
    case TYPE.COMMENT:
      return PseudoPostType.Status;
    case TYPE.PHOTO:
      return PseudoPostType.Photo;
    case TYPE.LINK:
      return PseudoPostType.Link;
    case TYPE.GALLERY:
      if (post.subtype === SUBTYPE.INSTAGRAM_STORY) {
        return PseudoPostType.Story;
      }
      return PseudoPostType.Gallery;
    case TYPE.VIDEO:
      if (post.subtype === SUBTYPE.REEL) {
        return PseudoPostType.Reel;
      }
      return PseudoPostType.Video;
  }
}

type GetSelectedPostsBetweenRangeParams = {
  posts: ListPost[];
  startPostId?: number;
  endPostId: number;
};

export function getSelectedPostsBetweenRange({
  endPostId,
  posts,
  startPostId,
}: GetSelectedPostsBetweenRangeParams) {
  if (posts.length) {
    const startPost = posts.find((post) => post.id === startPostId) ?? posts[0];
    const endPost = posts.find((post) => post.id === endPostId);

    if (startPost && endPost) {
      let startIndex = startPost.index;
      let endIndex = endPost.index;

      if (startIndex > endIndex) {
        const temp = startIndex;
        startIndex = endIndex;
        endIndex = temp;
      }

      const postsBetween = posts.filter((post) => {
        return post.index >= startIndex && post.index <= endIndex;
      });

      return postsBetween.map((post) => post.id);
    }
  }

  return [];
}

export function getAreAllPostsSelected(
  filteredPosts: ListPost[],
  selectedPosts: number[],
) {
  return filteredPosts
    .map((post) => post.id)
    .every((postId) => selectedPosts.includes(postId));
}

function getSanitizedMedium(medium: AttachmentCardMedium) {
  if (medium) {
    const outputMedium: {
      id: number;
      type: 'video' | 'image';
      custom_thumbnail_id?: number | string;
    } = {
      id: medium.id,
      type: medium.type,
    };

    if (medium.type === 'video') {
      outputMedium['custom_thumbnail_id'] = medium.custom_thumb?.id;
    }

    return outputMedium;
  }

  return null;
}

export const sanitizeNotAllowedMediumKeys = (
  data: Record<string, any> & { attachments: Attachments },
) => {
  return {
    ...data,
    attachments: {
      ...data.attachments,
      cards: data.attachments.cards.map((card) => ({
        ...card,
        medium: getSanitizedMedium(card.medium),
      })),
    },
  };
};
