/* eslint-disable no-console */
import { isProductionMode } from 'utils/helpers';
import SentryUtils from 'app/utils/sentry';

const Logger = {
  log: (...args: any) => {
    if (!isProductionMode()) {
      console.log(...args);
    }
  },
  info: (...args: any) => {
    if (!isProductionMode()) {
      console.info(...args);
    }
  },
  warn: (...args: any) => {
    if (!isProductionMode()) {
      console.warn(...args);
    }
  },
  error: (exception: any, extra?: string | Record<string, any>) => {
    if (!isProductionMode()) {
      console.error(exception, extra);
    } else {
      SentryUtils.error(exception, extra);
    }
  },
  trace: (...args: any) => {
    if (!isProductionMode()) {
      console.trace(...args);
    }
  },
};

export default Logger;
