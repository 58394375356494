import { gql } from '@apollo/client';

export const ALL_PAGES = gql`
  query allPagesQuery($first: Int = -1) {
    pages(first: $first) {
      edges {
        node {
          id
          _id
          username
          name
          picture
          _type
          fans
          userPageConfiguration {
            expanded
            metricKey
          }
        }
      }
    }
  }
`;
