import { useLocation } from 'react-router';
import UserpilotUtils from 'app/utils/userpilot';
import { useEffect } from 'react';

export function useUserPilotReload() {
  const location = useLocation();

  useEffect(() => {
    UserpilotUtils.reload();
  }, [location]);
}
