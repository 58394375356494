import { colors } from '@kontentino/ui';
import { FC } from 'react';
import {
  PieChart as PieGraph,
  Pie,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { GraphContent } from 'types/insights/Graphs';

type Props = {
  data: GraphContent[];
  primaryDataKey: string;
  isAnimationActive?: boolean;
};

const PieChart: FC<Props> = ({ data, primaryDataKey, isAnimationActive }) => {
  return (
    <ResponsiveContainer>
      <PieGraph>
        <Tooltip />
        <Pie
          data={data}
          dataKey={primaryDataKey}
          nameKey="key"
          cx="50%"
          cy="50%"
          outerRadius={50}
          fill={colors.primary.default}
          isAnimationActive={isAnimationActive}
        />
      </PieGraph>
    </ResponsiveContainer>
  );
};

export default PieChart;
