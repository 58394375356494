import { FC, useRef, useState } from 'react';
import { Icon, IconButton } from '@kontentino/ui';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import _debounce from 'lodash.debounce';
import { useEffectOnce } from 'utils/hooks/useEffectOnce';
import useSubscriptionInfo from 'app/hooks/useSubscriptionInfo';
import clsx from 'clsx';
import SearchTextInput from '../SearchTextInput';

type Props = {
  onFocus?: () => void;
  onChange?: (value: string) => void;
  onDebouncedSearchChange: (value: string) => void;
  debounceDelay: number;
  placeholder?: string;
};

const TopBarToggleSearchField: FC<Props> = ({
  placeholder,
  onFocus,
  onChange,
  onDebouncedSearchChange,
  debounceDelay,
}) => {
  const [isInputShown, toggleInputShown] = useState(false);
  const [value, setValue] = useState('');
  const { isTrial } = useSubscriptionInfo();

  const notifySearchChanged = useRef(
    _debounce(onDebouncedSearchChange, debounceDelay),
  );

  useEffectOnce(() => {
    return () => {
      notifySearchChanged.current.cancel();
    };
  });

  const handleSearchFieldValueChange = (value: string) => {
    onChange?.(value);
    notifySearchChanged.current(value);
    setValue(value);
  };

  const handleToggleInputShown = () => {
    toggleInputShown((prev) => {
      if (prev) {
        handleSearchFieldValueChange('');
        return false;
      }

      return true;
    });
  };

  const handleInputBlur = () => {
    toggleInputShown(value.length !== 0);
  };

  if (isInputShown) {
    return (
      <SearchTextInput
        onClear={handleToggleInputShown}
        autoFocus
        placeholder={placeholder}
        data-cy="topbar-search-input"
        data-name="topbar-search-input"
        value={value}
        onChange={(e) => handleSearchFieldValueChange(e.target.value)}
        className={clsx('!tw-h-9', {
          '!tw-w-[110px] xl:!tw-w-[180px] 2xl:!tw-w-[260px]': isTrial,
          '!tw-w-[130px] xl:!tw-w-[180px] 2xl:!tw-w-[260px]': !isTrial,
        })}
        onFocus={onFocus}
        onBlur={handleInputBlur}
      />
    );
  }

  return (
    <IconButton
      variant="secondary"
      data-cy="topbar-search-toggle-button"
      data-name="topbar-search-toggle-button"
      className="tw-h-9"
      size={'medium'}
      onClick={handleToggleInputShown}
      icon={<Icon icon={faSearch} />}
    />
  );
};

export default TopBarToggleSearchField;
