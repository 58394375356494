import React, { FC } from 'react';
import Modal from 'components/shared/Modal';
import Form from 'app/modules/textEditor/components/linkShortener/createEditShortenLinkModal/Form';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from 'app/hooks/useToast';
import i18n from 'i18n';
import { queryKey } from 'constants/queryKey';
import { ShortenLink } from 'types/LinkShortener';
import { CreateShortLinkInput, LinkShortenerApi } from 'api/linkShortener';
import { ApiClientError } from 'api/client';
import Logger from 'utils/logger';

type Props = {
  onCopyToText?(link: string): void;
  shortenLink?: null | ShortenLink;
  onClose: AnyFunction;
  pageId?: number;
};

const CreateEditShortenLinkModal: FC<Props> = (props) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    (variables: CreateShortLinkInput & { insertAfterShortened: boolean }) =>
      LinkShortenerApi.save(variables),
    {
      onSuccess(res, { insertAfterShortened }) {
        toast(
          `Shortened link successfully ${
            props.shortenLink?.id ? 'updated' : 'created'
          }.`,
        );

        if (insertAfterShortened) {
          props.onCopyToText?.(res.shortUrl);
        }
        props.onClose();
        queryClient.invalidateQueries([queryKey.shortenLinks]);
      },
      onError(e: ApiClientError) {
        Logger.error(e);
        toast(e.userMessage ?? i18n.somethingWentWrong, 'error');
      },
    },
  );

  return (
    <Modal
      id="create-edit-shorten-link-modal"
      open={props.shortenLink !== undefined}
      onClose={props.onClose}
      isLoading={isLoading}
      classNames={{
        root: '!tw-z-[1002]',
      }}
    >
      <Modal.Header
        title={`${props.shortenLink?.id ? 'Edit' : 'Create'} shortened link`}
      />
      <Form
        onClose={props.onClose}
        onSaveAndInsert={(values) =>
          mutate({ ...values, insertAfterShortened: true })
        }
        onSave={(values) => mutate({ ...values, insertAfterShortened: false })}
        defaultValues={props.shortenLink}
        isLoading={isLoading}
        pageId={props.pageId}
      />
    </Modal>
  );
};

export default CreateEditShortenLinkModal;
