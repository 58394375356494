import { lazy, Suspense } from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import routeConfig from 'app/config/routes';
import { queryClient } from 'api/client';
import { PublicPostOverview } from 'pages/public/PublicPostOverview';
import AnalyticsPdfExports from 'pages/public/AnalyticsPdfExports';
import { publicPDFReportsRouter } from 'app/modules/public/modules/pdfReports';
import PublicAppProviders from 'app/modules/public/components/PublicAppProviders';
import Skeleton from 'react-loading-skeleton';
import { resetPasswordRouter } from 'app/modules/public/modules/forgotPassword';

const Login = lazy(() => import('app/modules/public/modules/login'));

export const publicRouter: RouteObject = {
  path: `/${routeConfig.public.path}`,
  element: (
    <PublicAppProviders queryClient={queryClient}>
      <Outlet />
    </PublicAppProviders>
  ),
  children: [
    {
      index: true,
      element: <Navigate to={routeConfig.public.routes.login.path} />,
    },
    {
      path: `${routeConfig.public.routes.post.path}/:postId`,
      element: <PublicPostOverview />,
    },
    {
      path: routeConfig.public.routes.reports.path,
      element: <AnalyticsPdfExports />,
    },
    publicPDFReportsRouter,
    {
      path: routeConfig.public.routes.login.path,
      element: (
        <Suspense fallback={<Skeleton width="100%" height="100%" />}>
          <Login />
        </Suspense>
      ),
    },
    resetPasswordRouter,
  ],
};
