import React, { FC, useCallback } from 'react';
import ControlsContainer from 'components/date-range-picker/ControlsContainer';
import ControlsWrapper from 'components/date-range-picker/ControlsWrapper';
import { NumberOfMonthsType } from 'components/date-range-picker/DateRangePicker';
import { DateRangeOnChangeParams } from 'types/Date';
import { DatePickerDataNames } from './shared/DatePicker';
import { Dayjs } from 'dayjs';

type Props = {
  numberOfMonths?: NumberOfMonthsType;
  onSubmit(values: DateRangeOnChangeParams): void;
  onCancel(): void;
  presets: string[];
  initialStartDate?: Dayjs | null;
  initialEndDate?: Dayjs | null;
  onPreselect?(value: DateRangeOnChangeParams): void;
  autoSelectPeriod?: number;
  maxEndDate?: Dayjs | null;
  dataName?: DatePickerDataNames;
};

const DateRangePicker: FC<Props> = ({
  numberOfMonths = 2,
  onSubmit,
  onCancel,
  presets,
  initialStartDate,
  initialEndDate,
  onPreselect,
  autoSelectPeriod,
  maxEndDate,
  dataName,
}) => {
  const isDayBlocked = useCallback(
    (date: Dayjs) => {
      return maxEndDate ? date.isAfter(maxEndDate, 'day') : false;
    },
    [maxEndDate],
  );

  return (
    <ControlsContainer
      initialEndDate={initialEndDate}
      initialStartDate={initialStartDate}
      onPreselect={onPreselect}
      autoSelectPeriod={autoSelectPeriod}
      maxEndDate={maxEndDate}
    >
      {(params) => (
        <ControlsWrapper
          onDatesChange={params.onDatesChange}
          onFocusChange={params.onFocusChange}
          focusedInput={params.focusedInput}
          startDate={params.startDate}
          endDate={params.endDate}
          numberOfMonths={numberOfMonths}
          onCancel={onCancel}
          onSubmit={onSubmit}
          presets={presets}
          selectedPreset={params.selectedPreset}
          onPresetClick={params.onPresetClick}
          isDayBlocked={isDayBlocked}
          dataName={dataName}
        />
      )}
    </ControlsContainer>
  );
};

export default DateRangePicker;
