import React, { FC, useState } from 'react';
import { Checkbox, DropdownMenu, Tag } from '@kontentino/ui';
import LabelTag from 'components/shared/LabelTag';
import { PostsMeta } from 'types/Post';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'utils/hooks/useTheme';

type Props = {
  labels: PostsMeta['label'];
  onSelectLabel: (id: number) => void;
  selectedLabelsIds: number[];
};

const MAXIMUM_TAG_TO_SHOW_LIMIT = 3;

const PostsLayoutContentBarLabels: FC<Props> = ({
  labels,
  onSelectLabel,
  selectedLabelsIds,
}) => {
  const { t } = useTranslation();
  const [isLabelsDropdownOpen, setLabelsDropdownOpen] = useState(false);
  const labelsToShow = [...labels].slice(0, MAXIMUM_TAG_TO_SHOW_LIMIT);
  const restLabels = [...labels].slice(MAXIMUM_TAG_TO_SHOW_LIMIT);
  const theme = useTheme();

  return (
    <div className="tw-flex tw-items-center tw-gap-1">
      {labelsToShow.map((label) => (
        <LabelTag
          key={label.id}
          size="small"
          onClick={() => onSelectLabel(label.id)}
          name={label.name}
          color={
            selectedLabelsIds.length === 0 ||
            selectedLabelsIds.includes(label.id)
              ? label.color
              : theme.colors.grayscale['20']
          }
        />
      ))}
      {restLabels.length > 0 && (
        <DropdownMenu.Root
          open={isLabelsDropdownOpen}
          onOpenChange={(open) => {
            if (!open) return;
            setLabelsDropdownOpen(true);
          }}
        >
          <DropdownMenu.Trigger
            className="tw-cursor-pointer"
            data-name="posts-content-rest-labels-button"
            data-cy="posts-content-rest-labels-button"
          >
            <Tag size="small">+ {restLabels.length}</Tag>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content
            className="tw-z-10 tw-max-h-[300px] tw-overflow-y-auto"
            onInteractOutside={() => setLabelsDropdownOpen(false)}
            onEscapeKeyDown={() => setLabelsDropdownOpen(false)}
          >
            {restLabels.map((label) => (
              <div key={`label-${label.id}`}>
                <DropdownMenu.Item
                  onSelect={() => onSelectLabel(label.id)}
                  data-cy="posts-content-rest-labels-single-option"
                  data-name="posts-content-rest-labels-single-option"
                >
                  <Checkbox
                    size="small"
                    onChange={() => null}
                    checked={selectedLabelsIds.includes(label.id)}
                  />
                  <div
                    className="tw-h-[12px] tw-w-[12px] tw-rounded-full"
                    style={{ backgroundColor: label.color }}
                  />
                  <span className="tw-max-w-[200px] tw-truncate">
                    {label.name}
                  </span>
                  <span className="tw-ml-auto">
                    {t('postsCount', { count: label.postCount })}
                  </span>
                </DropdownMenu.Item>
              </div>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      )}
    </div>
  );
};

export default PostsLayoutContentBarLabels;
