import { UserRole } from 'types/User';
import { ROLE } from '@kontentino/kontentino-constants/User';

export const UserWorkflowPermissions = {
  canMarkPostAsDone: (userRole: UserRole): boolean => {
    return [ROLE.ADMIN, ROLE.MANAGER, ROLE.DESIGNER, ROLE.TRANSLATOR].includes(
      userRole,
    );
  },
  canAssignPost: (userRole: UserRole): boolean => {
    return [ROLE.ADMIN, ROLE.MANAGER, ROLE.DESIGNER, ROLE.TRANSLATOR].includes(
      userRole,
    );
  },
};
export const ApprovalWorkflowPermissions = {
  canSendForInternalApproval: (userRole: UserRole): boolean => {
    return [ROLE.ADMIN, ROLE.MANAGER, ROLE.DESIGNER].includes(userRole);
  },
  canInternallyRejectPost: (userRole: UserRole): boolean => {
    return [ROLE.ADMIN, ROLE.MANAGER].includes(userRole);
  },
  canInternallyApprovePost: (userRole: UserRole): boolean => {
    return [ROLE.ADMIN, ROLE.MANAGER].includes(userRole);
  },
  canSendForClientApproval: (userRole: UserRole): boolean => {
    return [ROLE.MANAGER, ROLE.ADMIN].includes(userRole);
  },
  canApprovePostAsClient: (userRole: UserRole): boolean => {
    return [ROLE.ADMIN, ROLE.MANAGER, ROLE.CLIENT].includes(userRole);
  },
  canRejectPostAsClient: (userRole: UserRole): boolean => {
    return [ROLE.ADMIN, ROLE.MANAGER, ROLE.CLIENT].includes(userRole);
  },
};
