import { CountObject } from '../pagesPerformance/PageRow';

export const positionClassName = (
  metricCountObj: CountObject,
  isHighlighted: boolean,
) => {
  const {
    count,
    min,
    max,
    hasMultipleMaxValues,
    hasMultipleMinValues,
  } = metricCountObj;

  if (!isHighlighted) return '';

  if (count === min && !hasMultipleMinValues) {
    return 'min';
  } else if (count === max && !hasMultipleMaxValues) {
    return 'max';
  }
  return '';
};
