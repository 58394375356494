import { OBJECTIVE } from '@kontentino/kontentino-constants/Ads';
import { PostPreviewVariant } from '@kontentino/kontentino-post-previews';
import { Objective } from 'types/Ad';

const AdsPlanner = {
  DEFAULT_AD_ACCOUNTS_REQUEST_LIMIT: 50,
  DEFAULT_CBO_VALUE: 20,
  CAMPAIGNS_REUEST_LIMIT: 10,
  AD_SETS_REQUEST_LIMIT: 10,
  ADS_REQUEST_LIMIT: 10,
  BROWSER_STORAGE_PREFIX: 'ads-planner_',
  SUPPORTED_OBJECTIVES: [
    OBJECTIVE.BRAND_AWARENESS,
    OBJECTIVE.TRAFFIC,
    OBJECTIVE.POST_ENGAGEMENT,
    OBJECTIVE.REACH,
    OBJECTIVE.VIDEO_VIEWS,
  ] as Objective[],
  AVAILABLE_PREVIEW_TYPES: [
    'desktop',
    'mobile',
    'rightColumn',
    'story',
  ] as PostPreviewVariant[],
  FACEBOOK_ADS_MANAGER_ADS_LINK:
    'https://www.facebook.com/adsmanager/manage/ads',
};

export default AdsPlanner;
