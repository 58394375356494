import React, { FC } from 'react';
import { HashtagPreset } from 'types/HashtagPresets';
import OptionsBarFeatureItem from '../optionsBarFeatureItem/OptionsBarFeatureItem';

type Props = {
  hashtagPreset: HashtagPreset;
  onDelete(presetId: number): void;
  onEdit(preset: HashtagPreset): void;
  onCopyToClipboard(hashtags: string): void;
  onCopyToText?(hashtags: string): void;
};

const HashtagsPresetItem: FC<Props> = (props) => {
  function getHashtagsCount() {
    return props.hashtagPreset.hashtags.split('#').length - 1;
  }

  return (
    <OptionsBarFeatureItem
      title={props.hashtagPreset.name}
      onCopyToClipboard={() =>
        props.onCopyToClipboard(props.hashtagPreset.hashtags)
      }
      onCopyToText={
        props.onCopyToText
          ? () => props.onCopyToText?.(props.hashtagPreset.hashtags)
          : undefined
      }
      onEdit={() => props.onEdit(props.hashtagPreset)}
      onDelete={() => props.onDelete(props.hashtagPreset.id)}
      count={getHashtagsCount()}
    >
      {props.hashtagPreset.hashtags}
    </OptionsBarFeatureItem>
  );
};

export default HashtagsPresetItem;
