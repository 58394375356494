import { colors } from '@kontentino/ui';
import { FC } from 'react';
import {
  LineChart as LineGraph,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ResponsiveContainer,
} from 'recharts';
import { GraphContent } from 'types/insights/Graphs';

type Props = {
  data: GraphContent[];
  primaryDataKey: string;
  secondaryDataKey?: string | null;
  isAnimationActive?: boolean;
};

const LineChart: FC<Props> = ({
  data,
  primaryDataKey,
  secondaryDataKey,
  isAnimationActive,
}) => {
  return (
    <ResponsiveContainer>
      <LineGraph data={data}>
        <XAxis dataKey="key" fontSize={14} />
        <YAxis fontSize={14} domain={['dataMin', 'dataMax']} />
        <Tooltip />
        <Line
          type="monotone"
          dataKey={primaryDataKey}
          stroke={colors.primary.default}
          isAnimationActive={isAnimationActive}
        />
        {!!secondaryDataKey && (
          <Line
            type="monotone"
            dataKey={secondaryDataKey}
            stroke={colors.orange.default}
            isAnimationActive={isAnimationActive}
          />
        )}
      </LineGraph>
    </ResponsiveContainer>
  );
};

export default LineChart;
