import config from 'config';
import { DEFAULT_HEADERS } from 'constants/request';
import { GraphQLClient } from 'graphql-request';
import store from 'store';

const client = () => {
  const graphqlClient = new GraphQLClient(config.API_SERVICE_ENDPOINT_GRAPHQL, {
    headers: DEFAULT_HEADERS,
  });

  const token = store.getState().app.authToken;

  if (token) {
    graphqlClient.setHeader('x-http-jwt-token', token);
  }

  return graphqlClient;
};

export default client;
