import React, { FC, useState } from 'react';
import { Icon } from '@kontentino/ui';
import StringUtils from 'utils/string';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import clsx from 'clsx';

type Props = {
  title: string;
  content: string;
  icon: IconDefinition;
  className?: string;
  textClassName?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const COLLAPSED_TEXT_LENGTH = 150;

const PostPreviewContentBox: FC<Props> = ({
  content,
  title,
  icon,
  textClassName,
  className,
  ...props
}) => {
  const [isFullTextShown, setIsFullTextShown] = useState(false);

  return (
    <div
      className={clsx(
        'tw-flex tw-w-full tw-rounded tw-bg-grayscale-10 tw-p-2',
        className,
      )}
      {...props}
    >
      <Icon icon={icon} />
      <div className="tw-ml-2 tw-min-w-0">
        <div className="tw-text-sm tw-font-semibold">{title}</div>
        <p className={clsx('tw-break-words tw-text-sm', textClassName)}>
          {isFullTextShown
            ? content
            : StringUtils.truncate(content, COLLAPSED_TEXT_LENGTH)}
          {content.length > COLLAPSED_TEXT_LENGTH && (
            <span
              className="tw-cursor-pointer tw-font-medium"
              onClick={() => setIsFullTextShown(!isFullTextShown)}
              data-name={`post-preview-post-text-${
                isFullTextShown ? 'less' : 'more'
              }`}
            >
              {isFullTextShown ? ' Show less' : ' Show more'}
            </span>
          )}
        </p>
      </div>
    </div>
  );
};

export default PostPreviewContentBox;
