import { gql } from '@apollo/client';

export const CONNECTED_FB_AD_ACCOUNT_FRAGMENT = gql`
  fragment ConnectedAdAccounts on FacebookPageType {
    connectedAdAccounts {
      edges {
        node {
          facebookAdAccountId
          name
          currency
        }
      }
    }
  }
`;
