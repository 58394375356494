import NumberUtil from 'utils/number';
import dayjs from 'dayjs';

export function convertToNumber(value: number | string) {
  return Number(typeof value === 'string' ? value.replace(',', '') : value);
}

export function formatNumberValue(value: number | string) {
  return NumberUtil.format(convertToNumber(value), {
    maximumFractionDigits: 2,
  });
}

export function formatCompactPercentageValue(value: number | string) {
  return `${formatCompactValue(value)}%`;
}

export function formatCompactValue(value: number | string) {
  return NumberUtil.compact(convertToNumber(value));
}

export const formatDateValue = (value: string) => {
  return dayjs(value).format('MMM DD');
};
