import dayjs from 'dayjs';
import { PostDetail } from 'types/PostDetail';

export function appendIndexToPosts(posts: PostDetail[]) {
  let index = -1;

  const postsWithoutDate = posts.filter((post) => !post.date);
  const postsWithDate = posts
    .filter((post) => post.date)
    .sort((a, b) => (dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1));

  const appendIndex = (post: PostDetail) => ({
    ...post,
    index: ++index,
  });

  return [
    ...postsWithDate.map(appendIndex),
    ...postsWithoutDate.map(appendIndex),
  ];
}
