import { FC } from 'react';
import { FormStepper } from '@kontentino/kontentino-ui/build/form';
import style from './stepsHeader/StepsHeader.module.scss';

type Props = {
  steps: Record<string, string | number>[];
  activeStep: number;
};

const StepsHeader: FC<Props> = ({ steps, activeStep }) => {
  return (
    <div className={style.headerWrapper}>
      <FormStepper
        items={steps}
        className={style.stepper}
        activeStep={activeStep}
        isClickable={false}
      />
    </div>
  );
};

export default StepsHeader;
