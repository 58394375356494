import React, { FC } from 'react';
import BaseWelcomeModal from './BaseWelcomeModal';
import ANALYTICS_ILLUSTRATION from './illustrations/analytics_welcome.svg';
import { Button } from '@kontentino/ui';
import { ModalRouteProps } from 'context/ModalRouter';
import { useNavigate } from 'react-router';
import { modalRoutes } from 'constants/routes';
import { useTranslation } from 'react-i18next';

type Props = ModalRouteProps & {};

const InsightsWelcomeModal: FC<Props> = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <BaseWelcomeModal
      modalManager={{
        ...props.modalManager,
        close() {},
      }}
      image={<img src={ANALYTICS_ILLUSTRATION} alt="Analytics icon" />}
      title={t('welcomeModals.analytics.title')}
      description={t('welcomeModals.analytics.description')}
      buttons={
        <Button
          variant="primary"
          onClick={() => navigate({ hash: modalRoutes.NEW_PROFILE })}
          className="tw-w-full"
        >
          {t('welcomeModals.connectMyProfiles')}
        </Button>
      }
    />
  );
};

export default InsightsWelcomeModal;
