import { FC } from 'react';
import { Button, DropdownMenu, Icon, Tooltip } from '@kontentino/ui';
import {
  MetricProperties,
  MetricProperty,
} from 'app/modules/insights/types/MetricProperty';
import i18n from 'i18n';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';

type Props = {
  onChange: (metric: MetricProperty) => void;
  value: MetricProperty;
  options: MetricProperties;
  dataName?: string;
  disabledMetrics?: string[];
};

const PostMetricsSelect: FC<Props> = (props) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <Button
          iconBefore={props.value.icon && <Icon icon={props.value.icon} />}
          iconAfter={<Icon icon={faChevronDown} />}
          variant="secondary"
          data-name="insights-posts-performance-metrics"
          className="tw-h-[36px] tw-w-[200px]"
        >
          <div className="tw-mr-auto">{props.value.title}</div>
        </Button>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className="tw-z-10">
        {Object.values(props.options).map((metric: MetricProperty) => {
          const isDisabled = props.disabledMetrics?.includes(metric.key);

          return (
            <Tooltip
              key={metric.key}
              content={isDisabled ? i18n.disabledMetricMessage : undefined}
              placement="right-start"
            >
              <DropdownMenu.Item
                className="tw-capitalize"
                onSelect={() => props.onChange(metric)}
                selected={props.value.key === metric.key}
                disabled={isDisabled}
                key={metric.key}
                data-name={`insights_posts-performance_filterBar_statistics-dropdown-${metric.key}`}
              >
                {metric.icon && <Icon icon={metric.icon} />}
                {metric.title}
              </DropdownMenu.Item>
            </Tooltip>
          );
        })}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default PostMetricsSelect;
