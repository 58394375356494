import {
  FB_METRICS_PROPERTIES,
  LI_METRICS_PROPERTIES,
} from '../modules/postsPerformance/utils/metricProperty';

export const PERCENTAGE_METRIC_VALUE_KEYS = [
  FB_METRICS_PROPERTIES.engagementRate.key,
  LI_METRICS_PROPERTIES.engagementRate.key,
];

export const PLAYBACK_TIME_METRIC_VALUE_KEYS = [
  FB_METRICS_PROPERTIES.videoAverageTimeWatched.key,
  LI_METRICS_PROPERTIES.timeWatched.key,
];
