import { FC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const PostRowHeaderValueTitle: FC<Props> = ({ children }) => {
  return (
    <span className="tw-inline-block tw-w-[calc(100%/3)] tw-text-center">
      {children}
    </span>
  );
};

export default PostRowHeaderValueTitle;
