import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LayoutTopBar from 'app/components/layout/LayoutTopBar';
import { Button, Icon, IconButton } from '@kontentino/ui';
import {
  faArrowDownToLine,
  faArrowsRotate,
  faChevronDown,
} from '@fortawesome/pro-regular-svg-icons';
import ProfileSelector from 'app/components/ProfileSelector';
import ProfileSelectorOption from 'app/components/profileSelector/components/ProfileSelectorOption';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import SimpleDatePicker from 'app/components/simpleDatePicker/SimpleDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import useSetState from 'utils/hooks/useSetState';
import { useToast } from 'app/hooks/useToast';

type Props = {};

const InsightsLiteTopBar: FC<Props> = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const [searchQuery, setSearchQuery] = useState('');
  const [customDateRangeActive, setCustomDateRangeActive] = useState(false);
  const [dateRange, setDateRange] = useSetState<{
    startDate: Dayjs | null;
    endDate: Dayjs | null;
  }>({
    startDate: dayjs().add(-1, 'month').startOf('month'),
    endDate: dayjs().add(-1, 'month').endOf('month'),
  });

  function showInfoToRequestAccess() {
    toast(t('insightsLite.requestAccessToEnableInsightsLite'), 'info');
  }

  return (
    <LayoutTopBar shadow className="tw-justify-between">
      <div className="tw-flex tw-gap-x-2">
        <ProfileSelector
          title="Profiles"
          optionsElement={
            <ProfileSelectorOption
              isSingleSelect
              dataCy="page-selector-page-item"
              option={{
                label: 'Kontentino',
                socialMediaType: TYPE.FACEBOOK,
                logoSrc:
                  'https://www.kontentino.com/android-chrome-192x192.png',
              }}
              isChecked
            />
          }
          filter={{
            searchQuery: searchQuery,
            onSearch: setSearchQuery,
          }}
          isSelectedAll={false}
          placeholderData={[
            {
              name: 'Kontentino',
              logoSrc: 'https://www.kontentino.com/android-chrome-192x192.png',
              type: TYPE.FACEBOOK,
            },
          ]}
          dataName={{
            placeholder: 'insights_profile-management_dropdown',
            options: 'insights_profile-management_profileList',
            filter: 'insights_profile-management_filter',
          }}
          classNames={{
            dropdownWrapperInner: '!tw-h-[180px]',
          }}
        />
        <SimpleDatePicker
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          onDateChange={(dateRange) => {
            setDateRange(dateRange);
            showInfoToRequestAccess();
          }}
          isRangeDateViewActive={customDateRangeActive}
          onToggleCustomDateRangeView={setCustomDateRangeActive}
          maxDate={dayjs()}
          dataName={{
            picker: 'insights-lite_date-picker',
            placeholder: 'insights-lite_date-picker_placeholder',
          }}
          className={{
            wrapper: 'tw-max-h-[36px] tw-max-w-[200px]',
          }}
        />
      </div>
      <div className="tw-flex tw-gap-x-2">
        <IconButton
          data-name="insights-lite_export-button"
          icon={<Icon icon={faArrowDownToLine} />}
          variant="secondary"
          onClick={showInfoToRequestAccess}
        />

        <IconButton
          data-name="insights-lite_syncbutton"
          className="tw-h-[36px]"
          variant="secondary"
          icon={<Icon icon={faArrowsRotate} />}
          onClick={showInfoToRequestAccess}
        />

        <div className="tw-ml-auto tw-flex tw-flex-1 tw-items-center tw-justify-end">
          <Button
            className="tw-h-9 tw-capitalize"
            variant="primary"
            data-name="insights-lite_add-new-button"
            iconAfter={<Icon icon={faChevronDown} />}
            onClick={showInfoToRequestAccess}
          >
            {t('new')}
          </Button>
        </div>
      </div>
    </LayoutTopBar>
  );
};

export default InsightsLiteTopBar;
