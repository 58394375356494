import React, { FC } from 'react';
import dayjs from 'dayjs';
import moment, { Moment } from 'moment-timezone';
import { Select } from '@kontentino/ui';

type Props = {
  month: Moment;
  onMonthSelect: (currentMonth: Moment, newMonthVal: string) => void;
  onYearSelect: (currentMonth: Moment, newMonthVal: string) => void;
  isVisible: boolean;
};

const DatePickerMonthElement: FC<Props> = ({
  month,
  onMonthSelect,
  onYearSelect,
}) => {
  const getAvailableYears = () => {
    let maxYear = dayjs().year() + 1;
    let minYear = dayjs().year() - 5;

    if (month.year() > maxYear) {
      maxYear = month.year();
    }

    if (month.year() < minYear) {
      minYear = month.year();
    }

    const years: { label: string; value: number }[] = [];

    for (let i = minYear; i <= maxYear; i++) {
      years.push({ label: i.toString(), value: i });
    }

    return years;
  };

  return (
    <div className="tw-relative tw-flex tw-flex-row  tw-justify-center tw-gap-2">
      <Select
        className="tw-w-[112px]"
        value={{
          label: moment.months()[month.month()],
          value: month.month(),
        }}
        onChange={(selectedOption) => {
          if (selectedOption) {
            onMonthSelect(month, selectedOption.value.toString());
          }
        }}
        options={moment.months().map((label, value) => ({
          label,
          value,
        }))}
        isSearchable={false}
        styles={{
          menuList: (styles) => ({
            ...styles,
            maxHeight: '200px',
            marginTop: '22px',
          }),
          valueContainer: (styles) => ({
            ...styles,
            paddingRight: '0px',
          }),
          singleValue: (styles) => ({
            ...styles,
            fontSize: '12px',
          }),
          option: (styles, { isSelected, isFocused }) => ({
            ...styles,
            fontSize: '12px !important',

            backgroundColor:
              !isSelected && isFocused
                ? 'transparent !important'
                : styles?.backgroundColor,
            '&:hover': {
              backgroundColor: `#E9EDFD !important`,
            },
          }),
        }}
      />

      <Select
        className="tw-min-w-[80px]"
        value={{ label: month.year().toString(), value: month.year() }}
        onChange={(selectedOption) => {
          if (selectedOption) {
            onYearSelect(month, selectedOption.value.toString());
          }
        }}
        isSearchable={false}
        options={getAvailableYears()}
        styles={{
          menu: (styles) => ({
            ...styles,
            marginTop: '22px',
          }),
          menuList: (styles) => ({
            ...styles,
            maxHeight: '200px',
            marginTop: '22px',
          }),
          singleValue: (styles) => ({
            ...styles,
            fontSize: '12px',
          }),
          option: (styles, { isSelected, isFocused }) => ({
            ...styles,
            fontSize: '12px !important',

            backgroundColor:
              !isSelected && isFocused
                ? 'transparent !important'
                : styles?.backgroundColor,
            '&:hover': {
              backgroundColor: `#E9EDFD !important`,
            },
          }),
        }}
      />
    </div>
  );
};

export default DatePickerMonthElement;
