import { PageNumberType, TYPE } from '@kontentino/kontentino-constants/Pages';
import { FC } from 'react';
import FacebookPage from 'components/insights/competitionAnalysis/pagesPerformance/pageRowHeader/FacebookPage';
import InstagramPage from 'components/insights/competitionAnalysis/pagesPerformance/pageRowHeader/InstagramPage';

type Props = {
  pageType: PageNumberType;
};

const PageRowHeader: FC<Props> = ({ pageType }) => {
  switch (pageType) {
    case TYPE.FACEBOOK:
      return <FacebookPage />;
    case TYPE.INSTAGRAM:
      return <InstagramPage />;
    default:
      return null;
  }
};

export default PageRowHeader;
