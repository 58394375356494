import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { BrowserStorage } from 'utils/browserStorage';

type AppLayoutState = {
  isNavigationExpanded: boolean;
  backdropLoaderShown: boolean;
  showBackdropLoader(): void;
  hideBackdropLoader(): void;
  toggleNavigationExpanded(): void;
  navigationClassName: string;
  setNavigationClassName(className: string): void;
  showGridViewOptionInNavigation: boolean;
  setShowGridViewOptionInNavigation: (
    showGridViewOptionInNavigation: boolean,
  ) => void;
  insightSelectedPageType: number | undefined;
  setInsightSelectedPageType: (pageType?: number) => void;
};

export const useAppLayoutState = create<AppLayoutState>()(
  persist(
    (set, get) => ({
      isNavigationExpanded: false,
      navigationClassName: '',
      backdropLoaderShown: false,
      showGridViewOptionInNavigation: false,
      insightSelectedPageType: undefined,
      showBackdropLoader: () => set(() => ({ backdropLoaderShown: true })),
      hideBackdropLoader: () => set(() => ({ backdropLoaderShown: false })),
      toggleNavigationExpanded: () =>
        set((state) => ({ isNavigationExpanded: !state.isNavigationExpanded })),
      setNavigationClassName: (className: string) =>
        set(() => ({ navigationClassName: className })),
      setShowGridViewOptionInNavigation: (
        showGridViewOptionInNavigation: boolean,
      ) => set(() => ({ showGridViewOptionInNavigation })),
      setInsightSelectedPageType: (insightSelectedPageType?: number) =>
        set(() => ({ insightSelectedPageType })),
    }),
    { name: BrowserStorage.keys.Stores.AppLayout },
  ),
);
