import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  PostsView,
  usePostsLayoutState,
} from 'app/modules/posts/hooks/usePostsLayoutState';
import { composeRoute } from 'constants/routes';

const viewByPathName: { [x: string]: PostsView } = {
  [composeRoute('posts.routes.kanban.path')]: 'board',
  [composeRoute('posts.routes.list.path')]: 'list',
  [composeRoute('posts.routes.grid.path')]: 'grid',
  [composeRoute('posts.routes.calendar.path')]: 'calendar',
};

export function usePostsLayoutActiveViewController() {
  const { pathname } = useLocation();
  const { setActiveView } = usePostsLayoutState();

  useEffect(() => {
    const view = viewByPathName[pathname] ?? 'calendar';

    setActiveView(view);
  }, [pathname, setActiveView]);
}
