import { faBullseye, faSackDollar } from '@fortawesome/pro-regular-svg-icons';
import { getAmountSpent } from 'app/modules/insights/utils/getAmountSpent';
import { getObjectivesArray } from 'app/modules/insights/utils/getObjectivesArray';
import { transformObjectives } from 'app/modules/insights/utils/transformObjectives';
import MetricRowSimple from './MetricRowSimple';
import Divider from '../Divider';
import MetricRow from './MetricRow';
import React, { FC } from 'react';
import { ExpenseName, TotalExpenseObject } from 'types/AnalyticsPost';

type Props = {
  totalExpenses: Record<ExpenseName, TotalExpenseObject>;
  pageType: number;
};

const PostInsights: FC<Props> = ({ totalExpenses, pageType }) => (
  <>
    <div className="tw-text-md tw-font-medium tw-text-grayscale-90">
      Post Information
    </div>
    <MetricRowSimple
      name="amountSpent"
      icon={faSackDollar}
      label="Amount Spent"
      value={getAmountSpent(totalExpenses)}
    />
    <Divider />
    <MetricRow
      pageType={pageType}
      name="usedObjectives"
      icon={faBullseye}
      label="Used Objectives"
      value={getObjectivesArray(totalExpenses)}
      additionalData={transformObjectives(totalExpenses)}
    />
    <Divider />
  </>
);

export default PostInsights;
