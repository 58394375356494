import { useHistoryQueryParams } from 'app/hooks/useHistoryQueryParams';
import { useEffect } from 'react';
import PageTypeUtils from 'app/utils/pageType';
import { ActiveState } from './useActiveState';
import { PageNumberType } from '@kontentino/kontentino-constants/Pages';

export function useUrlSideEffectForUserPilot({
  activeState,
}: {
  activeState: ActiveState;
}) {
  const { setQueryParameter, deleteQueryParameter } = useHistoryQueryParams();

  useEffect(() => {
    if (activeState.page?._type) {
      const pageType = PageTypeUtils.getName(
        activeState.page._type as PageNumberType,
      );
      setQueryParameter('pageType', pageType);
    } else {
      deleteQueryParameter('pageType');
    }
  }, [activeState.page, setQueryParameter, deleteQueryParameter]);
}
