import React, { FC } from 'react';
import { Button, Icon, mergeTailwindClasses } from '@kontentino/ui';
import {
  faFacebookF,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import PageTypeUtils from 'app/utils/pageType';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import clsx from 'clsx';

type Props = {
  seeOnClick?: () => void;
  showMoreClick: () => void;
  pageType?: number;
  expanded?: boolean;
  className?: string;
};

const ActionButtons: FC<Props> = ({
  seeOnClick,
  showMoreClick,
  pageType,
  expanded,
  className,
}) => {
  return (
    <div className={mergeTailwindClasses(clsx('tw-flex tw-gap-4', className))}>
      {pageType && (
        <Button
          variant="secondary"
          onClick={seeOnClick}
          className="tw-shrink-0"
          iconBefore={<Icon icon={getIcon(pageType)} />}
          data-name={`insights_posts-performance_single-metric_seeOn-${PageTypeUtils.getLabel(
            pageType,
          ).toLowerCase()}`}
        >
          See on {PageTypeUtils.getLabel(pageType)}
        </Button>
      )}
      <Button
        variant="primary"
        className="tw-flex-1"
        onClick={showMoreClick}
        data-name={`insights_post_show-${expanded ? 'less' : 'more'}`}
      >
        {expanded ? 'Show less' : 'Show more'}
      </Button>
    </div>
  );
};

function getIcon(pageType: number) {
  switch (pageType) {
    case TYPE.INSTAGRAM:
      return faInstagram;
    case TYPE.LINKEDIN:
      return faLinkedin;
    default:
      return faFacebookF;
  }
}

export default ActionButtons;
