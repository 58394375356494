import { FC } from 'react';
import routes from 'constants/routes';
import { useLocation } from 'react-router';
import FEATURES from '@kontentino/kontentino-constants/Features';
import AdsPlannerTrialExpired from 'app/components/subscriptionModals/AdsPlannerTrialExpired';
import InsightsTrialExpired from 'app/components/subscriptionModals/InsightsTrialExpired';
import SubscriptionExpired from 'app/components/subscriptionModals/SubscriptionExpired';
import TrialExpired from 'app/components/subscriptionModals/TrialExpired';
import useSubscriptionInfo from 'app/hooks/useSubscriptionInfo';
import AdsPlannerTrialStart from 'app/components/subscriptionModals/AdsPlannerTrialStart';
import InsightsTrialStart from 'app/components/subscriptionModals/InsightsTrialStart';
import { FeaturesSlugs } from 'types/Account';
import { useMutation } from 'react-query';
import { requestFeatureTrial } from 'app/api/features';
import { useToast } from 'app/hooks/useToast';
import { ApiClientError } from 'api/client';
import i18n from 'i18n';

type Props = {};

const SubscriptionModals: FC<Props> = () => {
  const location = useLocation();
  const toast = useToast();
  const { isTrialExpired, isSubscriptionExpired, featuresPermissions } =
    useSubscriptionInfo();

  const requestTrial = useMutation(requestFeatureTrial, {
    onSuccess() {
      document.location.reload();
    },
    onError(e: ApiClientError) {
      toast(e?.userMessage ?? i18n.somethingWentWrongTryAgain, 'error');
    },
  });

  function getFeature(slug: FeaturesSlugs) {
    return Object.values(featuresPermissions).find(
      (feature) => feature.slug === slug,
    );
  }

  function canHasFeatureTrial(slug: FeaturesSlugs) {
    const feature = getFeature(slug);

    return !feature?.hasAccess && !feature?.wasTrialed;
  }

  function hasFeatureTrialExpired(slug: FeaturesSlugs) {
    const feature = getFeature(slug);

    return !!(!feature?.hasAccess && feature?.wasTrialed);
  }

  return (
    <>
      <TrialExpired open={isTrialExpired} />
      <SubscriptionExpired open={isSubscriptionExpired} />

      {!isTrialExpired && !isSubscriptionExpired && (
        <>
          {location.pathname.includes(routes.ADS_PLANNER) && (
            <>
              <AdsPlannerTrialStart
                open={canHasFeatureTrial(FEATURES.ADS_MANAGEMENT)}
                onStartTrialNow={() =>
                  requestTrial.mutate(FEATURES.ADS_MANAGEMENT)
                }
              />
              <AdsPlannerTrialExpired
                open={hasFeatureTrialExpired(FEATURES.ADS_MANAGEMENT)}
              />
            </>
          )}

          {location.pathname.includes(routes.INSIGHTS) &&
            !location.pathname.includes(routes.INSIGHTS_LITE) && (
              <>
                <InsightsTrialStart
                  open={canHasFeatureTrial(FEATURES.NEW_ANALYTICS)}
                  onStartTrialNow={() =>
                    requestTrial.mutate(FEATURES.NEW_ANALYTICS)
                  }
                />
                <InsightsTrialExpired
                  open={hasFeatureTrialExpired(FEATURES.NEW_ANALYTICS)}
                />
              </>
            )}
        </>
      )}
    </>
  );
};

export default SubscriptionModals;
