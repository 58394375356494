import {
  getNavigationItems,
  NavigationItemKey,
} from 'components/navigation/utils/libs';
import { useMutation } from 'react-query';
import AppPreferencesApi from 'app/api/appPreferences';
import { useLocation } from 'react-router';
import { useAccount } from 'modules/account/accountSelector';
import { useMemo } from 'react';
import { useToast } from 'app/hooks/useToast';
import i18n from 'i18n';
import { ApiClientError } from 'api/client';
import { useUser } from 'modules/user/userSelector';
import { useAppLayoutState } from 'components/appLayout/appLayoutState';

export function useSwitchToOldModule() {
  const location = useLocation();
  const account = useAccount();
  const user = useUser();
  const toast = useToast();
  const { insightSelectedPageType } = useAppLayoutState();

  const switchModule = useMutation(AppPreferencesApi.toggleToOldModule, {
    onSuccess({ old }, navigationItemKey) {
      if (old) {
        const navigationItems = getNavigationItems({
          account,
          user,
          insightSelectedPageType,
        });

        const targetUrl = navigationItems.find(
          (item) => item.key === navigationItemKey,
        )?.url;

        if (targetUrl) {
          document.location.href = targetUrl;
        }
      }
    },
    onError(e: ApiClientError) {
      toast(e.userMessage ?? i18n.somethingWentWrongTryAgain);
    },
  });

  const activeSwitchableModule = useMemo(() => {
    const switchableModules = getNavigationItems({
      account,
      user,
      insightSelectedPageType,
    }).filter((item) => item.routerUrl && item.url);

    const currentModuleRouterUrl = location.pathname
      .split('/')
      .filter(Boolean)[0];

    return switchableModules.find(
      (module) => module.routerUrl?.replace('/', '') === currentModuleRouterUrl,
    );
  }, [location.pathname, account, user, insightSelectedPageType]);

  async function switchToOldModule() {
    if (activeSwitchableModule?.key) {
      switchModule.mutate(activeSwitchableModule.key);
    }
  }

  function getLabel() {
    switch (activeSwitchableModule?.key) {
      case NavigationItemKey.Posts:
        return account.isTrial
          ? account.featuresPermissions.global_pages?.hasAccess
            ? 'Switch to old Calendar'
            : undefined
          : 'Switch to old Calendar';
      case NavigationItemKey.Insights:
        return account.isTrial ? undefined : 'Switch to old Analytics';
    }
  }

  return {
    label: getLabel(),
    switchToOldModule,
  };
}
