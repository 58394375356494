import {
  ModuleStatusFailed,
  ModuleStatusIdle,
  ModuleStatusLoading,
  ModuleStatusSucceeded,
} from 'types/ModuleStatus';

type ModuleStatusObject = {
  Idle: ModuleStatusIdle;
  Failed: ModuleStatusFailed;
  Loading: ModuleStatusLoading;
  Succeeded: ModuleStatusSucceeded;
};

export const MODULE_STATUS: ModuleStatusObject = {
  Idle: 'idle',
  Loading: 'loading',
  Succeeded: 'succeeded',
  Failed: 'failed',
};
