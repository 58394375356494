import React, { FC } from 'react';
import Notifications from 'components/navigation/verticalNavigation/userActionsMenu/Notifications';
import HelpCenter from './HelpCenter';
import Settings from './Settings';
import { useAccount } from 'modules/account/accountSelector';
import Referral from 'components/navigation/verticalNavigation/userActionsMenu/Referral';

type Props = {};

const UserSubActions: FC<Props> = () => {
  const { isSubscription } = useAccount();

  return (
    <div className="tw-flex tw-flex-col tw-gap-y-3">
      {isSubscription && <Referral />}
      <Notifications />
      <HelpCenter />
      <Settings />
    </div>
  );
};

export default UserSubActions;
