import { get, post } from 'api/client';
import config from 'config';
import {
  FacebookMentionSuggestion,
  FacebookMentionSuggestions,
  HashtagSuggestion,
  LinkedinMentionSuggestions,
  MentionSuggestion,
  TwitterHashtagSuggestions,
  TwitterMentionSuggestions,
} from 'types/Suggestions';
import { TYPE } from '@kontentino/kontentino-constants/Pages';
import { CancelToken } from 'axios';

type SuggestionsParams = {
  pageType: number;
  query: string;
  cancelToken?: CancelToken;
};

type facebookMentionsParams = {
  query: string;
  cancelToken?: CancelToken;
  jwtToken?: string | null;
  filterEmptyLocations?: boolean;
};

export const SuggestionApi = {
  facebookMentionsPageInfo(
    query: string,
    jwtToken?: string | null,
  ): Promise<FacebookMentionSuggestion> {
    return get('facebook-mentions/page-info', {
      baseURL: config.MENTIONS_SERVICE_ENDPOINT,
      params: { q: query, jwtToken },
    });
  },
  twitterHashtags(
    query: string,
    cancelToken?: CancelToken,
  ): Promise<TwitterHashtagSuggestions> {
    return get('twitter-mentions/search', {
      baseURL: config.MENTIONS_SERVICE_ENDPOINT,
      params: { q: query, type: 'hashtags' },
      cancelToken,
    });
  },
  twitterMentions(
    query: string,
    cancelToken?: CancelToken,
  ): Promise<TwitterMentionSuggestions> {
    return get('twitter-mentions/search', {
      baseURL: config.MENTIONS_SERVICE_ENDPOINT,
      params: { q: query, type: 'users' },
      cancelToken,
    });
  },
  facebookMentions(
    params: facebookMentionsParams,
  ): Promise<FacebookMentionSuggestions> {
    const { query, jwtToken, filterEmptyLocations, cancelToken } = params;

    return get('facebook-mentions/search', {
      baseURL: config.MENTIONS_SERVICE_ENDPOINT,
      params: { q: query, jwtToken, filterEmptyLocations },
      cancelToken,
    });
  },
  getFacebookMention(
    query: string,
  ): Promise<{ id: string; type: string; name: string }> {
    const data = new FormData();
    data.append('q', query);

    return post('/pages/ajaxRemoteSearchForFBPage', data);
  },
  linkedinMentions(
    query: string,
    cancelToken?: CancelToken,
  ): Promise<LinkedinMentionSuggestions> {
    return get('/pages/ajaxRemoteRetrieveLinkedInMentions', {
      params: { q: query },
      cancelToken,
    });
  },
  mentions(params: SuggestionsParams): Promise<MentionSuggestion[]> {
    switch (params.pageType) {
      case TYPE.FACEBOOK:
      case TYPE.FACEBOOK_AD:
        return SuggestionApi.facebookMentions({
          query: params.query,
          cancelToken: params.cancelToken,
        }).then((mentions) =>
          mentions.map((mention) => ({
            id: `${mention.id}`,
            isDemo: false,
            name: mention.name,
            username: mention.name,
            description: mention.additional_data.link,
            image: `https://graph.facebook.com/${mention.id}/picture?type=square`,
            isVerified:
              mention.additional_data.verification_status === 'blue_verified',
            pageType: TYPE.FACEBOOK,
            location: mention.additional_data.location,
          })),
        );

      case TYPE.TWITTER:
        return SuggestionApi.twitterMentions(
          params.query,
          params.cancelToken,
        ).then((mentions) =>
          mentions.map((mention) => ({
            id: `${mention.id}`,
            username: mention.name,
            name: mention.screen_name,
            description: `@${mention.screen_name}`,
            image: mention.avatar,
            isVerified: false,
            pageType: TYPE.TWITTER,
            isDemo: false,
          })),
        );

      case TYPE.LINKEDIN:
        return SuggestionApi.linkedinMentions(
          params.query,
          params.cancelToken,
        ).then((mentions) =>
          mentions.map((mention) => ({
            id: `${mention.id}`,
            username: mention.name,
            name: mention.name,
            description: [
              mention.additional_data.location.country,
              mention.additional_data.location.city,
            ]
              .filter(Boolean)
              .join(', '),
            image: mention.additional_data.logo_urn ?? undefined,
            isVerified: false,
            pageType: TYPE.LINKEDIN,
            isDemo: false,
          })),
        );
      default:
        return Promise.reject({ message: 'Unsupported pageType' });
    }
  },
  hashtags(params: SuggestionsParams): Promise<HashtagSuggestion[]> {
    switch (params.pageType) {
      case TYPE.TWITTER:
        return SuggestionApi.twitterHashtags(
          params.query,
          params.cancelToken,
        ).then((hashtags) =>
          hashtags.map((hashtag) => ({
            id: `${hashtag.id}`,
            pageType: TYPE.TWITTER,
            name: hashtag.screen_name,
            score: hashtag.rounded_score,
            image: null,
          })),
        );
      default:
        return Promise.reject({ message: 'Unsupported pageType' });
    }
  },
};
