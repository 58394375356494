import React, { FC } from 'react';
import { PagePost } from 'types/insights/CompetitionAnalysis';
import { formatNumberValue } from 'app/modules/insights/modules/pagePerformance/utils/format';

type Props = {
  post: PagePost;
};

const InstagramPostRow: FC<Props> = ({ post }) => {
  return (
    <div className="tw-flex tw-justify-center tw-text-center tw-text-md">
      {[post.interactions ?? 0, post.commentsTotal, post.likesTotal ?? 0].map(
        (value, index) => (
          <div className="tw-flex-1" key={index}>
            {formatNumberValue(value)}
          </div>
        ),
      )}
    </div>
  );
};

export default InstagramPostRow;
