import { get, post } from 'api/client';
import config from 'config';
import { getExtendedTimeout } from 'utils/request';
import { createSearchFromParams } from 'utils/url';

export type AIContentResponse = {
  text: string;
  image: string;
}[];

export type AIContentRequestParams = {
  prompt: string;
  plan_type: string;
  social_network: string | null;
  style: string | null;
  language: string | null;
};

export type AIContentOptionValue = {
  label: string;
  key: string;
};

export type AIContentOptions = {
  text: AIContentOptionValue[];
  image: AIContentOptionValue[];
  enhance: AIContentOptionValue[];
  language: AIContentOptionValue[];
  imagePromptSuggestions: AIContentOptionValue[];
  remainingAttempts: {
    image: number;
    text: number;
    all: number;
  };
  availableAttempts: {
    image: number;
    text: number;
    all: number;
  };
};

export const AIContentApi = {
  options({
    plan_type,
  }: Pick<AIContentRequestParams, 'plan_type'>): Promise<AIContentOptions> {
    return get(`/options${createSearchFromParams({ plan_type })}`, {
      baseURL: config.AI_API_ENDPOINT,
    });
  },
  generateImages(props: AIContentRequestParams): Promise<AIContentResponse> {
    return post('/generate/image', props, {
      baseURL: config.AI_API_ENDPOINT,
      timeout: getExtendedTimeout(3),
    });
  },
  generateTexts(props: AIContentRequestParams): Promise<AIContentResponse> {
    return post('/generate/text', props, {
      baseURL: config.AI_API_ENDPOINT,
      timeout: getExtendedTimeout(3),
    });
  },
  generateHashtags(
    props: Omit<
      AIContentRequestParams,
      'social_network' | 'language' | 'style'
    >,
  ): Promise<AIContentResponse> {
    return post('/generate/hashtag', props, {
      baseURL: config.AI_API_ENDPOINT,
      timeout: getExtendedTimeout(3),
    });
  },
  enhanceText(
    props: Omit<AIContentRequestParams, 'social_network' | 'language'>,
  ): Promise<AIContentResponse> {
    return post('/enhance/text', props, {
      baseURL: config.AI_API_ENDPOINT,
      timeout: getExtendedTimeout(3),
    });
  },
};
