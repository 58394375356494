import {
  PostStatus,
  STATUS_COMPLETED,
} from '@kontentino/kontentino-constants/Posts';
import { get, post } from 'api/client';
import { ShiftPostsVariant } from 'app/modules/posts/hooks/useShiftPosts';
import { Post } from 'types/Post';
import { ApprovalData } from 'types/PostWorkflow';

type AssignTasksParams = {
  posts: number[];
  users: number[];
  message: string;
  isUrgent: boolean;
  canSendEmail: boolean;
};

export type SendForApprovalParams = {
  type: ApprovalType;
  posts: number[];
  users: number[];
  additionalUsers?: string;
  subject: string;
  message: string;
};

export type ChangeStatusParams = {
  posts: number[];
  status: PostStatus;
};

export type ShiftToProjectParams = {
  ids: number[];
  projectId: number;
  cloneAsDraft?: boolean;
  variant: ShiftPostsVariant;
};

const PostTimeSaversApi = {
  assignTasks(params: AssignTasksParams): Promise<Post[]> {
    const data = new FormData();
    const urgentValue = params.isUrgent ? '1' : '0';
    const notificationValue = params.canSendEmail ? '1' : '0';

    data.append('postIds', JSON.stringify(params.posts));
    data.append('userIds', JSON.stringify(params.users));
    data.append('text', params.message);
    data.append('isUrgent', urgentValue);
    data.append('isSendNotificationOn', notificationValue);
    data.append('extended', '1');

    return post('/posts2/ajaxAssignMassTasks', data);
  },
  shiftToProject(params: ShiftToProjectParams): Promise<Post[]> {
    return Promise.all(
      params.ids.map((id) => {
        const data = new FormData();

        data.append('id', id.toString());
        data.append('projectId', params.projectId.toString());

        if (params.cloneAsDraft) {
          data.append('forceStatus', `${STATUS_COMPLETED}`);
        }

        return post(
          `/posts/${
            params.variant === 'move'
              ? 'ajaxMovePostToAnotherContentPlan'
              : 'ajaxClonePostToAnotherContentPlan'
          }`,
          data,
        ) as Promise<Post>;
      }),
    );
  },
  changeStatus(params: ChangeStatusParams): Promise<{ status: boolean }> {
    const data = new FormData();

    data.append('newStatus', params.status.toString());
    data.append('ids', JSON.stringify(params.posts));

    return post('/posts2/ajaxMassChangePostsStatus', data);
  },
  async sendForApproval(
    params: SendForApprovalParams,
  ): Promise<{ status: boolean }> {
    const data = new FormData();

    data.append('type', params.type);
    data.append('emailSubject', params.subject);
    data.append('emailBody', params.message);
    data.append('selectedPosts', JSON.stringify(params.posts));
    data.append('selectedUsers', JSON.stringify(params.users));

    if (params.type === 'client' && !!params.additionalUsers?.length) {
      data.append('additionalUsers', params.additionalUsers);
    }

    return post('/posts2/ajaxSendPostsForApproval', data);
  },
  getApprovalData(pages: number[], type: ApprovalType): Promise<ApprovalData> {
    const modifyResponse = (res: ApprovalData<{ surname: string }>) => ({
      ...res,
      users: res.users.map((user) => ({
        ...user,
        name: `${user.name} ${user.surname}`,
      })),
    });

    return get<ApprovalData<{ surname: string }>>(
      'posts/ajaxRetrievePostApprovalData',
      {
        params: {
          'page_ids[]': pages.join(),
          type,
        },
      },
    ).then(modifyResponse);
  },
};

export default PostTimeSaversApi;
