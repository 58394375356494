import React, { ReactNode, useContext } from 'react';

type ResetPasswordContextValues = {
  email: string;
  setEmail: (email: string) => void;
};

const ResetPasswordContext = React.createContext<
  ResetPasswordContextValues | undefined
>(undefined);

export function ResetPasswordContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [email, setEmail] = React.useState('');

  return (
    <ResetPasswordContext.Provider
      value={{
        email,
        setEmail,
      }}
    >
      {children}
    </ResetPasswordContext.Provider>
  );
}

export function useResetPasswordContext() {
  const context = useContext(ResetPasswordContext);

  if (!context) {
    throw new Error('Must be used within ResetPasswordContextProvider');
  }

  return context;
}
