import { FC } from 'react';
import style from 'components/analyticsPdfExports/tableRowItem/TableRowItem.module.scss';

type Props = {
  children: any;
  className?: string;
};

const TableRowItem: FC<Props> = ({ children, className }) => {
  return (
    <tr className={style.item}>
      <td className={className}>{children}</td>
    </tr>
  );
};

export default TableRowItem;
