import { FC, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { Provider } from 'components/adsPlanner/campaign/context';
import { useAdsPlannerContainer } from 'components/adsPlanner/context';
import { Campaigns } from 'types/Campaign';
import { useCampaignsListContainerData } from 'components/adsPlanner/campaign/CampaignsListContainer.hooks';
import StringUtils from 'utils/string';
import { Module } from 'config';
import { useAdAccountSelectionContext } from 'components/adsPlanner/adAccount/AdAccountSelection';

export type CampaignsState = {
  campaigns: Campaigns;
  datelessCampaigns: Campaigns;
  loadMore: () => void;
  isLoadingMore: boolean;
  canLoadMore: boolean;
  isLoadingCampaigns: boolean;
  totalCount: number;
};

type Props = {
  children: ReactNode;
};

const CampaignsListContainer: FC<Props> = ({ children }) => {
  const offset = useRef(0);
  const { filters } = useAdsPlannerContainer();
  const { selectedAdAccount } = useAdAccountSelectionContext();

  useEffect(() => {
    resetCampaignsData();

    return () => {
      resetCampaignsData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedAdAccount?.id,
    filters.orderDirection,
    filters.startDate,
    filters.endDate,
    filters.selectedLabels,
    filters.selectedWorkflowStatuses,
    filters.selectedEffectiveStatuses,
    filters.selectedObjectives,
  ]);

  const {
    data,
    isLoading,
    isIdle,
    isFetching,
    hasNextPage,
    fetchNextPage,
    remove,
  } = useCampaignsListContainerData(selectedAdAccount?.id as number, filters);

  const loadMore = () => {
    fetchNextPage({
      pageParam: (offset.current += Module.AdsPlanner.CAMPAIGNS_REUEST_LIMIT),
    });
  };

  const isSearchQueryActive = useCallback(() => {
    return filters.searchQuery.length > 0;
  }, [filters.searchQuery.length]);

  const resetOffset = () => {
    offset.current = 0;
  };

  const resetCampaignsData = () => {
    remove();
    resetOffset();
  };

  const filterCampaigns = () => {
    if (isSearchQueryActive()) {
      return data.campaigns.filter((item) =>
        StringUtils.isSubstring(filters.searchQuery, item.name),
      );
    }

    return data.campaigns;
  };

  const filterDatelessCampaigns = () => {
    if (isSearchQueryActive()) {
      return data.datelessCampaigns.filter((item) =>
        StringUtils.isSubstring(filters.searchQuery, item.name),
      );
    }

    return data.datelessCampaigns;
  };

  const filteredCampaigns = useMemo(
    filterCampaigns,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, filters.searchQuery],
  );

  const filteredDatelessCampaigns = useMemo(
    filterDatelessCampaigns,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, filters.searchQuery],
  );

  const canLoadMore = useMemo(() => {
    return !!hasNextPage && !isSearchQueryActive();
  }, [hasNextPage, isSearchQueryActive]);

  return (
    <Provider
      value={{
        campaigns: filteredCampaigns,
        datelessCampaigns: filteredDatelessCampaigns,
        canLoadMore,
        loadMore,
        isLoadingCampaigns: isLoading || isIdle,
        isLoadingMore: isFetching,
        totalCount: data.totalCount,
      }}
    >
      {children}
    </Provider>
  );
};

export default CampaignsListContainer;
