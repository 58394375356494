import { get, post } from 'api/client';
import {
  HashtagPreset,
  HashtagPresetAssignablePage,
  HashtagPresets,
} from 'types/HashtagPresets';
import { jsonToFormData } from 'utils/formData';

type SaveParams = {
  id?: number;
  name: string;
  hashtags: string;
  pages: number[];
  isGlobal: boolean;
};

export const HashtagPresetsApi = {
  getAll(pageId: number): Promise<HashtagPresets> {
    return get('/hashtagpresets/ajaxGetHashtagPresets', {
      params: { page_id: pageId },
    });
  },
  getAssignablePages() {
    return get<HashtagPresetAssignablePage[]>(
      '/hashtagpresets/ajaxGetAssignablePages',
    );
  },
  save(params: SaveParams): Promise<HashtagPreset> {
    const data = jsonToFormData({
      ...params,
      pages: JSON.stringify(params.isGlobal ? [] : params.pages),
    });

    if (params.id) {
      return post('/hashtagpresets/ajaxUpdateHashtagPreset', data, {
        params: { preset_id: params.id },
      });
    }

    return post('/hashtagpresets/ajaxCreateHashtagPreset', data);
  },
  delete(presetId: number): Promise<unknown> {
    return post('/hashtagpresets/ajaxDeleteHashtagPreset', null, {
      params: { preset_id: presetId },
    });
  },
};
