import React, { FC } from 'react';

type Props = {
  record: Record<string, string | number>;
  className?: string;
  itemClassName?: string;
};

const RecordEntriesList: FC<Props> = (props) => {
  return (
    <div className={props.className}>
      {Object.entries(props.record).map(([key, value]) => (
        <div key={key} className={props.itemClassName}>
          <strong>{key}</strong>: {value}
        </div>
      ))}
    </div>
  );
};

export default RecordEntriesList;
