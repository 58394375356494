import { get } from 'api/client';
import config from 'config';
import { CompetitionAnalysisResponse } from 'types/insights/CompetitionAnalysis';
import { getInsightsPageTypeAlias } from 'app/modules/insights/utils/getInsightsPageTypeAlias';
import { getExtendedTimeout } from 'utils/request';
import { CompetitionAnalysisPage } from 'app/modules/insights/modules/competitionAnalysis/types/page';

type ExternalPagesParams = {
  pageType: number;
  dateFrom: string;
  dateTo: string;
  paginationPage: number;
  token?: string;
  pageIds: string[];
};

const CompetitionAnalysisApi = {
  searchExternalPages: (
    query: string,
    type: number,
  ): Promise<CompetitionAnalysisPage[]> => {
    return get(`api/${getInsightsPageTypeAlias(type)}/search`, {
      baseURL: config.ANALYTICS_ENDPOINT,
      params: { q: query },
    });
  },
  getPagesAnalysis: ({
    dateFrom,
    dateTo,
    paginationPage,
    pageIds,
    pageType,
    token,
  }: ExternalPagesParams): Promise<CompetitionAnalysisResponse> => {
    const pageTypeAlias = getInsightsPageTypeAlias(pageType);

    const headers = token ? { Authorization: `Bearer ${token}` } : undefined;

    return get(`api/${pageTypeAlias}/competition-analysis`, {
      baseURL: config.ANALYTICS_ENDPOINT,
      params: {
        dateFrom,
        dateTo,
        page: paginationPage,
        [pageTypeAlias === 'fb' ? 'externalIds' : 'usernames']: pageIds.join(),
      },
      timeout: getExtendedTimeout(5),
      headers,
    });
  },
};

export default CompetitionAnalysisApi;
