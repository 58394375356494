import { QueryFunction, QueryKey } from 'react-query/types/core/types';
import { UseQueryOptions, UseQueryResult } from 'react-query/types/react/types';
import { useQuery } from 'react-query';

export default function useQueryNoCache<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
): UseQueryResult<TData, TError> {
  return useQuery(queryKey, queryFn, {
    ...options,
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: Infinity,
  });
}
