import { formatDateUTC } from 'app/utils/date';
import { GraphKeysForComparing } from 'constants/analyticsGraphs';
import { Maybe, PageMetricEdge, Query } from 'graphql/insights/types';

type ComparedTotalParams = {
  primaryMetric: Maybe<PageMetricEdge>[];
  metricToAdd: Maybe<PageMetricEdge>[];
  primaryMetricCompared: Maybe<PageMetricEdge>[];
  metricToAddCompared: Maybe<PageMetricEdge>[];
};

type GraphWithAdditionalDataParams = {
  edges: Maybe<PageMetricEdge>[];
  primaryDataKey: string;
  secondaryDataKey?: string;
};

type GraphWithAdditionalComparedDataParams = {
  edges: Maybe<PageMetricEdge>[];
  edgesToCompare: Maybe<PageMetricEdge>[];
  primaryDataKey: string;
};

const getFilteredAdditionalDataByKey = (
  edge: Maybe<PageMetricEdge>,
  key: string,
) => {
  return edge?.node?.additionalData?.edges?.find(
    (edge) => edge?.node?.name === key,
  )?.node?.value;
};

const GraphDataFormatters = {
  getEdges(
    data:
      | Pick<Query, 'facebookPage'>
      | Pick<Query, 'instagramPage'>
      | undefined,
  ) {
    if (data && 'facebookPage' in data)
      return data.facebookPage?.metrics?.edges;
    else if (data && 'instagramPage' in data)
      return data.instagramPage?.metrics?.edges;
    else return undefined;
  },

  getBasicGraphData(edges: Maybe<PageMetricEdge>[], primaryDataKey: string) {
    return edges.reduce(
      (
        result: { key: string; [primaryDataKey: string]: string | number }[],
        edge,
      ) => {
        if (edge?.node?.metricValue && edge?.node?.date) {
          result.push({
            key: formatDateUTC(edge.node.date),
            [primaryDataKey]: Number(edge.node.metricValue),
          });
        }

        return result;
      },
      [],
    );
  },

  getComparedGraphData(
    edges: Maybe<PageMetricEdge>[],
    edgesToCompare: Maybe<PageMetricEdge>[],
  ) {
    return edgesToCompare.reduce(
      (
        result: {
          key: string;
          [periodKey: string]: string | number;
        }[],
        edge,
        index,
      ) => {
        if (edge?.node?.metricValue) {
          result.push({
            key: 'impressions',
            [GraphKeysForComparing.default]: Number(edge.node.metricValue),
            [GraphKeysForComparing.compared]: Number(
              edges[index]?.node?.metricValue ?? 'n/a',
            ),
          });
        }

        return result;
      },
      [],
    );
  },

  getTwoMetricsTotalData(
    primaryMetric: Maybe<PageMetricEdge>[],
    metricToAdd: Maybe<PageMetricEdge>[],
  ) {
    return primaryMetric.reduce(
      (
        result: {
          key: string;
          [metricKey: string]: string | number;
        }[],
        edge,
        index,
      ) => {
        if (edge?.node?.date && edge?.node?.metricValue) {
          result.push({
            key: formatDateUTC(edge.node.date),
            impressions:
              Number(edge.node.metricValue) +
              Number(metricToAdd[index]?.node?.metricValue),
          });
        }

        return result;
      },
      [],
    );
  },

  getTwoMetricsComparedTotalData(params: ComparedTotalParams) {
    return params.primaryMetric.reduce(
      (
        result: {
          key: string;
          [periodKey: string]: string | number;
        }[],
        edge,
        index,
      ) => {
        if (edge?.node?.date && edge?.node?.metricValue) {
          result.push({
            key: formatDateUTC(edge.node.date),
            [GraphKeysForComparing.default]:
              Number(edge.node.metricValue) +
              Number(params.metricToAdd[index]?.node?.metricValue),
            [GraphKeysForComparing.compared]:
              Number(params.primaryMetricCompared[index]?.node?.metricValue) +
              Number(params.metricToAddCompared[index]?.node?.metricValue),
          });
        }

        return result;
      },
      [],
    );
  },

  getGraphDataFromAdditionalData(params: GraphWithAdditionalDataParams) {
    return params.edges.reduce(
      (
        result: {
          key: string;
          [metricKey: string]: string | number;
        }[],
        edge,
      ) => {
        if (edge?.node?.date) {
          result.push({
            key: formatDateUTC(edge.node.date),
            [params.primaryDataKey]:
              getFilteredAdditionalDataByKey(edge, params.primaryDataKey) ?? 0,
            ...(params.secondaryDataKey && {
              [params.secondaryDataKey]:
                getFilteredAdditionalDataByKey(edge, params.secondaryDataKey) ??
                0,
            }),
          });
        }

        return result;
      },
      [],
    );
  },

  getComparedGraphDataFromAdditionalData(
    params: GraphWithAdditionalComparedDataParams,
  ) {
    return params.edges.reduce(
      (
        result: {
          key: string;
          [metricKey: string]: string | number;
        }[],
        edge,
        index,
      ) => {
        if (edge?.node?.date) {
          result.push({
            key: 'fans',
            [GraphKeysForComparing.default]:
              getFilteredAdditionalDataByKey(edge, params.primaryDataKey) ?? 0,
            [GraphKeysForComparing.compared]:
              getFilteredAdditionalDataByKey(
                params.edgesToCompare[index],
                params.primaryDataKey,
              ) ?? 0,
          });
        }

        return result;
      },
      [],
    );
  },
};

export default GraphDataFormatters;
