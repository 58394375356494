import { FC, useState } from 'react';
import { togglePrimitiveItemInArray } from 'utils/helpers';
import { validateForm } from 'components/workflowOptions/sendForApproval/form/Form.utils';
import { PreSelectedValues } from 'app/components/timeSavers/assignMultipleTasksModal/Container';
import useSetState from 'utils/hooks/useSetState';
import { SendForApprovalParams } from 'app/api/postTimeSavers';
import { sendForApprovalFormFields } from './utils/constants';
import { ListPost } from 'types/PostDetail';

type Params = {
  selectedStatuses: number[];
  togglePostStatus: (status: number) => void;
  togglePost: (post: ListPost) => void;
  selectPosts: (posts: ListPost[]) => void;
  changeSubject: (value: string) => void;
  changeBodyMessage: (value: string) => void;
  handleChangeSelectedUsers: (selectedUsers: number[]) => void;
  changeAdditionalEmails(value: string): void;
  sendForApproval: () => void;
  formErrors: Record<string, string>;
  formState: FormState;
};

type Props = {
  children: (params: Params) => JSX.Element;
  onSubmit?: (params: Omit<SendForApprovalParams, 'type'>) => void;
  preselectedValues?: PreSelectedValues;
};

export type FormState = {
  selectedPosts: ListPost[];
  selectedUsers: number[];
  subject: string | null;
  bodyMessage: string | null;
  additionalEmails: string;
};

const FormStateContainer: FC<Props> = ({
  children,
  onSubmit,
  preselectedValues,
}) => {
  const [formState, setFormState] = useSetState<FormState>({
    selectedPosts: [],
    selectedUsers: [],
    subject: null,
    bodyMessage: null,
    additionalEmails: '',
  });

  const [selectedStatuses, setSelectedStatuses] = useState<number[]>([]);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  const handleTogglePost = (post: ListPost) => {
    setFormState((prevState) => {
      const posts = [...prevState.selectedPosts];

      if (posts.map((item) => item.id).includes(post.id)) {
        const itemIndex = posts.findIndex((item) => item.id === post.id);

        if (itemIndex !== -1) {
          posts.splice(itemIndex, 1);
        }
      } else {
        posts.push(post);
      }

      return {
        selectedPosts: posts,
      };
    });
  };

  const handleSelectPosts = (posts: ListPost[]) => {
    setFormState({ selectedPosts: posts });
  };

  const handleTogglePostStatus = (status: number) => {
    setSelectedStatuses((prevSelectedStatuses) => {
      return togglePrimitiveItemInArray<number>(status, prevSelectedStatuses);
    });
  };

  const handleChangeSubject = (subject: string) => {
    setFormState({ subject });
    resetFieldError(sendForApprovalFormFields.subject);
  };

  const handleChangeBodyMessage = (bodyMessage: string) => {
    setFormState({ bodyMessage });
    resetFieldError(sendForApprovalFormFields.bodyMessage);
  };

  const resetFieldError = (fieldName: string) => {
    setFormErrors((prevState) => {
      return { ...prevState, [fieldName]: '' };
    });
  };

  const handleChangeSelectedUsers = (selectedUsers: number[]) => {
    setFormState({ selectedUsers });
    resetFieldError(sendForApprovalFormFields.selectedUsers);
  };

  const handleChangeAdditionalEmails = (value: string) => {
    setFormState((prevState) => {
      return {
        ...prevState,
        additionalEmails: value,
      };
    });
  };

  const handleSubmitForm = () => {
    let postsIds = [];
    if (!!preselectedValues?.posts.length) {
      postsIds = preselectedValues.posts;
    } else {
      postsIds = formState.selectedPosts.map((post) => post.id);
    }

    const validationErrors = validateForm(formState, postsIds);

    if (Object.keys(validationErrors).length !== 0) {
      setFormErrors(validationErrors);
      return;
    }

    onSubmit?.({
      posts: postsIds,
      users: formState.selectedUsers,
      additionalUsers: formState.additionalEmails,
      subject: formState.subject ?? '',
      message: formState.bodyMessage ?? '',
    });
  };

  return children({
    selectedStatuses,
    togglePostStatus: handleTogglePostStatus,
    togglePost: handleTogglePost,
    selectPosts: handleSelectPosts,
    changeBodyMessage: handleChangeBodyMessage,
    changeSubject: handleChangeSubject,
    handleChangeSelectedUsers,
    sendForApproval: handleSubmitForm,
    changeAdditionalEmails: handleChangeAdditionalEmails,
    formErrors,
    formState,
  });
};

export default FormStateContainer;
