import React, { FC } from 'react';
import { Button, Icon, Tooltip } from '@kontentino/ui';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { useTextEditorAiContent } from 'app/modules/textEditor/components/textEditorAiContent/TextEditorAiContentProvider';
import { useTranslation } from 'react-i18next';

type Props = {
  hasResults?: boolean;
  isLoading?: boolean;
  onSubmit(): void;
};

const TextEditorAiContentPopupGenerateFooter: FC<Props> = ({
  hasResults,
  isLoading,
  onSubmit,
}) => {
  const {
    queries: { options },
  } = useTextEditorAiContent();
  const { t } = useTranslation();

  const limitReached = options?.data?.remainingAttempts.all === 0;

  return (
    <div className="tw-flex tw-justify-end tw-border-t tw-border-grayscale-20 tw-p-4">
      <Tooltip content={limitReached ? t('aiContentLimitReached') : undefined}>
        <div>
          <Button
            onClick={onSubmit}
            className={clsx({
              'tw-bg-gradient-to-r tw-from-purple-100 tw-from-0% tw-to-primary-100 tw-to-100% hover:tw-from-purple-120 hover:tw-to-primary-120':
                !hasResults,
            })}
            isLoading={isLoading}
            data-name={`ai-content_${hasResults ? 'regenerate' : 'generate'}`}
            variant={hasResults ? 'secondary' : 'primary'}
            disabled={limitReached}
          >
            <Icon icon={faSparkles} />
            {hasResults ? t('regenerate') : t('generate')}
          </Button>
        </div>
      </Tooltip>
    </div>
  );
};

export default TextEditorAiContentPopupGenerateFooter;
