import { PROFILE_SELECTOR_FILTER_OPTIONS_PROPERTIES } from '../constants';
import {
  ProfileSelectorFilterOptionKey,
  ProfileSelectorPageTypes,
} from '../types/profileSelectorFilter';

const getProfileSelectorTitleByFilterOption = (
  option: ProfileSelectorPageTypes | ProfileSelectorFilterOptionKey,
) => {
  return PROFILE_SELECTOR_FILTER_OPTIONS_PROPERTIES[option]?.name ?? '';
};

export default getProfileSelectorTitleByFilterOption;
