import { TYPE } from '@kontentino/kontentino-constants/Pages';

import fbLikeSvg from 'images/svg/fbReactions/like.svg';
import fbLoveSvg from 'images/svg/fbReactions/love.svg';
import fbHahaSvg from 'images/svg/fbReactions/haha.svg';
import fbWowSvg from 'images/svg/fbReactions/wow.svg';
import fbSadSvg from 'images/svg/fbReactions/sad.svg';
import fbAngrySvg from 'images/svg/fbReactions/angry.svg';
import fbCareSvg from 'images/svg/fbReactions/care.svg';
import fbPrideSvg from 'images/svg/fbReactions/pride.svg';
import fbThankfulSvg from 'images/svg/fbReactions/thankful.png';
import liCelebrateSvg from 'images/svg/liReactions/celebrate.svg';
import liSupportSvg from 'images/svg/liReactions/support.svg';
import liLoveSvg from 'images/svg/liReactions/love.svg';
import liInsightfulSvg from 'images/svg/liReactions/insightful.svg';
import liLikeSvg from 'images/svg/liReactions/like.svg';
import liCuriousSvg from 'images/svg/liReactions/curious.svg';

type Reaction = {
  svg: string;
  key: string;
};

export const reactionProperties: Record<string, Reaction[]> = {
  [TYPE.FACEBOOK]: [
    {
      svg: fbLikeSvg,
      key: 'Like',
    },
    {
      svg: fbLoveSvg,
      key: 'Love',
    },
    {
      svg: fbHahaSvg,
      key: 'Haha',
    },
    {
      svg: fbWowSvg,
      key: 'Wow',
    },
    {
      svg: fbSadSvg,
      key: 'Sad',
    },
    {
      svg: fbAngrySvg,
      key: 'Angry',
    },
    {
      svg: fbCareSvg,
      key: 'Care',
    },
    {
      svg: fbPrideSvg,
      key: 'Pride',
    },
    {
      svg: fbThankfulSvg,
      key: 'Thankful',
    },
  ],
  [TYPE.LINKEDIN]: [
    {
      svg: liLikeSvg,
      key: 'Like',
    },
    {
      svg: liCelebrateSvg,
      key: 'Praise',
    },
    {
      svg: liSupportSvg,
      key: 'Appreciation',
    },
    {
      svg: liLoveSvg,
      key: 'Empathy',
    },
    {
      svg: liInsightfulSvg,
      key: 'Interest',
    },
    {
      svg: liCuriousSvg,
      key: 'Maybe',
    },
  ],
};
