import { FC } from 'react';
import style from './connectFirstAdAccount/ConnectFirstAdAccount.module.scss';
import Link from 'components/shared/Link';
import { modalRoutes } from 'constants/routes';
import UserPermissionGate from 'components/UserPermissionGate';
import { UserPermission } from 'constants/userPermission';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Button, Icon, colors } from '@kontentino/ui';

const LIST_OPTIONS = [
  'Create ad campaigns with live previews for multiple accounts',
  'Collaborate with your team and clients right next to previews',
  'Easily send ads for approval and rework',
  'Export and copy all your ads to Facebook Ads Manager with one click',
  'Monitor performance of your campaigns',
];

const ConnectFirstAdAccount: FC = () => {
  return (
    <div className={style.wrapper}>
      <div className={style.header}></div>
      <div className={style.body}>
        <div className={style.title}>
          Ads planning and collaboration made simple
        </div>
        <div className={style.description}>
          Introducing Ads Planner — the one place for teams to meet, plan,
          andcollaborate on ads creation hassle-free.
        </div>
        <div className={style.list}>
          {LIST_OPTIONS.map((option, index) => (
            <div className={style.listOption} key={`list-item-${index}`}>
              <FontAwesomeIcon
                icon={faCircleCheck}
                color={colors.success.default}
              />
              <span>{option}</span>
            </div>
          ))}
        </div>
        <UserPermissionGate scopes={UserPermission.AD_ACCOUNT_MANAGE}>
          <div className={style.footer}>
            <Link
              to={{
                hash: modalRoutes.ADD_AD_ACCOUNT,
              }}
            >
              <Button
                className={style.connectButton}
                iconBefore={<Icon icon={faPlus} />}
                data-cy="no-ad-accounts-layout-connect-button"
              >
                Connect new Ad account
              </Button>
            </Link>
          </div>
        </UserPermissionGate>
      </div>
    </div>
  );
};

export default ConnectFirstAdAccount;
