export const en = {
  yourName: 'Your name',
  yourEmail: 'Your email',
  somethingWentWrongTryAgain: 'Something went wrong. Please try again.',
  somethingWentWrong: 'Something went wrong.',
  areYouSure: 'Are you sure?',
  create: 'Create',
  edit: 'Edit',
  or: 'or',
  yes: 'Yes',
  no: 'No',
  save: 'Save',
  add: 'Add',
  back: 'Back',
  confirm: 'Confirm',
  addProfile: 'Add profile',
  close: 'Close',
  select: 'Select',
  cancel: 'Cancel',
  typeNewLabel: 'Type new label and press enter...',
  isRequired: 'Is required',
  canNotChangeChecklistItem: 'Can not change checklist item.',
  doYouWantToCancelAdComposing:
    'Are you sure you want to stop editing the ad? Your unsaved changes will be lost.',
  noResultsFound: 'No results found.',
  noInstagramAccountForAdYet:
    "You don't have any Instagram account selected yet.",
  canNotCreateEmptyComment: "You can't create empty comment",
  copiedToClipboard: 'Successfully copied to clipboard.',
  adsAreScheduledForExportToFacebook:
    'Selected ads were scheduled for export to Facebook. It may take a few minutes.',
  unableToAuthorizeLogin: 'Unable to Authorize User. Please log in.',
  wrongLoginCredentials: 'Wrong login credentials. Please try again.',
  adAccountStatusesUpdated:
    'Campaigns, Ad sets and Ads were successfully updated.',
  authorizationSuccessful: 'Authorization has been successfully granted.',
  invalidDate: 'Invalid Date',
  notSelected: 'Not selected',
  postLinkCopied: 'Link was successfully copied to your clipboard.',
  postLinkCopyFailed: 'Link was not copied to your clipboard. Please try again',
  loadingAdsPlanner: 'Loading Ads planner. Please wait ...',
  noInstagramAccountsForSelectedFacebookProfile:
    'No accounts found for selected Facebook profile',
  noProfilesFound: 'No profiles found',
  gridIgProfileBio: `Save Time and Simplify Social Media Management across Teams 💙. <br /> Learn more about social media marketing on our blog: `,
  gridIgProfileStats:
    "This doesn't reflect real values. It's just placeholder.",
  postUrlCopiedToClipboard: 'Post URL was successfully copied to clipboard.',
  campaign: {
    confirmDuplicateMessage: 'Confirm to duplicate selected campaign',
    successfullyDuplicated: 'Campaign was duplicated',
  },
  adSet: {
    successfullyDuplicated: 'Ad set was duplicated',
  },
  noMediaIncluded: 'No media included',
  unableToAddYourProfiles: 'Adding of your profiles was unsuccessful',
  yourProfilesWereAdded: 'Your profiles were added successfully',
  disabledMetricMessage:
    'This metric is disabled, because you don’t have any posts with that metric in the selected timeframe.',
  profileSelector: {
    reconnect: 'Reconnect',
    profileGroupsDisconnectTooltip: `A profile in this group is currently disconnected,<br />expand the group to resolve the issue`,
    profilesDisconnectTooltip: `The profile is not connected. You can still create posts but they can't be scheduled or published.`,
  },
  translationsSuccessfullySwapped: 'Versions swapped successfully',
  modal: {
    associateProfileTitle: 'Select profile',
  },
};
