// https://github.com/streamich/react-use/blob/master/src/useSetState.ts
import { useCallback, useState } from 'react';

export type UseSetStateAction<T> = (
  patch: Partial<T> | ((prevState: T) => Partial<T>),
) => void;

const useSetState = <T extends object>(
  initialState: T | (() => T) = {} as T,
): [T, UseSetStateAction<T>] => {
  const [state, set] = useState<T>(initialState);

  const setState: UseSetStateAction<T> = useCallback((patch) => {
    set((prevState) => ({
      ...prevState,
      ...(patch instanceof Function ? patch(prevState) : patch),
    }));
  }, []);

  return [state, setState];
};

export default useSetState;
