import React, { FC } from 'react';
import { ModalProvider, ModalProviderProps } from './modal/ModalProvider';
import ModalDialog, { ModalDialogProps } from './modal/ModalDialog';
import ModalFooter from 'components/shared/modal/ModalFooter';
import ModalHeader from 'components/shared/modal/ModalHeader';
import ModalContent from 'components/shared/modal/ModalContent';

export type ModalProps = ModalDialogProps & ModalProviderProps;

const Modal: FC<ModalProps> & {
  Header: typeof ModalHeader;
  Content: typeof ModalContent;
  Footer: typeof ModalFooter;
} = (props) => {
  return (
    <ModalProvider
      modalManager={props.modalManager}
      open={props.open}
      onClose={props.onClose}
      onEscKeyDown={props.onEscKeyDown}
      isContentHeightAutoResizable={props.isContentHeightAutoResizable}
      isLoading={props.isLoading}
      dataName={props.dataName}
      dataCy={props.dataCy}
    >
      <ModalDialog
        id={props.id}
        size={props.size}
        styles={props.styles}
        classNames={props.classNames}
        focusTrapped={props.focusTrapped}
      >
        {props.children}
      </ModalDialog>
    </ModalProvider>
  );
};

Modal.Header = ModalHeader;
Modal.Content = ModalContent;
Modal.Footer = ModalFooter;

export default Modal;
