import React, { FC, lazy, ReactNode, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import Container from 'components/insights/Container';
import { createInsightsApolloClient } from 'app/modules/insights/utils/createAuthorizedInsightsApolloClient';
import { useApp } from 'modules/app/appSelector';
import InsightsInitializationGate from 'app/modules/insights/components/InsightsInitializationGate';
import { InsightsAddNewProfileModal } from './components/modals/InsightsAddNewProfileModal';
import ModalRoute from 'context/ModalRouter';
import { modalRoutes } from 'constants/routes';
import InsightsWelcomeModal from 'app/components/modals/welcome/InsightsWelcomeModal';
import InsightsSection from 'app/modules/insights/components/InsightsSections';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';
import routeConfig from 'app/config/routes';

const PostsPerformanceRoute = lazy(
  () => import('app/modules/insights/modules/postsPerformance'),
);
const PagePerformanceRoute = lazy(
  () => import('app/modules/insights/modules/pagePerformance'),
);
const CompetitionAnalysisRoute = lazy(
  () => import('components/insights/CompetitionAnalysis'),
);

type Props = {
  children: ReactNode;
};

const Insights: FC<Props> = ({ children }) => {
  const { authToken } = useApp();
  const [client] = useState(() =>
    createInsightsApolloClient(authToken as string),
  );

  return (
    <ApolloProvider client={client}>
      <InsightsInitializationGate>
        <Container>
          <InsightsSection>{children}</InsightsSection>
        </Container>
      </InsightsInitializationGate>
      <ModalRoute
        component={InsightsAddNewProfileModal}
        path={modalRoutes.NEW_PROFILE}
      />
      <ModalRoute component={InsightsWelcomeModal} path={modalRoutes.WELCOME} />
    </ApolloProvider>
  );
};

export const insightsRouter: RouteObject = {
  path: routeConfig.insights.path,
  element: (
    <Insights>
      <Outlet />
    </Insights>
  ),
  children: [
    {
      index: true,
      element: (
        <Navigate to={routeConfig.insights.routes.postsPerformance.path} />
      ),
    },
    {
      path: routeConfig.insights.routes.postsPerformance.path,
      element: <PostsPerformanceRoute />,
    },
    {
      path: routeConfig.insights.routes.pagePerformance.path,
      element: <PagePerformanceRoute />,
    },
    {
      path: routeConfig.insights.routes.competitionAnalysis.path,
      element: <CompetitionAnalysisRoute />,
    },
  ],
};
