import React, { FC } from 'react';
import { Checkbox, Radio } from '@kontentino/ui';

type Props = {
  isChecked: boolean;
  isSingleSelect?: boolean;
  dataCy?: string;
};

const ProfileSelectorOptionCheckIndicator: FC<Props> = ({
  isChecked,
  isSingleSelect,
  dataCy,
}) => {
  if (isSingleSelect) {
    return (
      <Radio
        data-cy={dataCy}
        size="small"
        checked={isChecked}
        onChange={() => null}
      />
    );
  }

  return (
    <Checkbox
      data-cy={dataCy}
      checked={isChecked}
      onChange={() => null}
      size="small"
    />
  );
};

export default ProfileSelectorOptionCheckIndicator;
