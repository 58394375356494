import { get, post } from 'api/client';
import { DemoContentStatus } from 'types/DemoContent';
import TimeUtils from 'app/utils/time';

const SWITCH_REQUEST_TIMEOUT = TimeUtils.calcMilliseconds.minutes(2);

export const DemoContentApi = {
  init(): Promise<void> {
    return post('/DemoContent/ajaxInit');
  },
  enable(): Promise<void> {
    return post('/DemoContent/ajaxEnable', null, {
      timeout: SWITCH_REQUEST_TIMEOUT,
    });
  },
  disable(): Promise<void> {
    return post('/DemoContent/ajaxDisable', null, {
      timeout: SWITCH_REQUEST_TIMEOUT,
    });
  },
  toggle({ enabled }: DemoContentStatus): Promise<void> {
    return enabled ? this.enable() : this.disable();
  },
  getIsEnabled(): Promise<DemoContentStatus> {
    return get('/DemoContent/ajaxIsEnabledDemoContent');
  },
};
