import { Form } from 'components/shared/UtmTagBuilder';
import { prependHttp } from 'utils/url';

export function generateUrlWithUtmTags(form: Form) {
  const { url: urlValue, ...utmQueryParams } = form;

  try {
    const url = new URL(prependHttp(urlValue));

    Object.entries(utmQueryParams).forEach(([key, value]) => {
      if (!value) {
        url.searchParams.delete(key);
      } else {
        url.searchParams.set(key, value);
      }
    });

    return {
      status: true,
      data: url.toString(),
    };
  } catch (e) {
    return { status: false, data: 'Invalid Url' };
  }
}

export function parseUrlToUtmFields(url: string): Form {
  const initialState: Form = {
    url,
    utm_campaign: '',
    utm_content: '',
    utm_medium: '',
    utm_term: '',
    utm_source: '',
  };

  try {
    const parsedUrl = new URL(prependHttp(initialState.url));

    const { url, ...queryParams } = initialState;

    Object.keys(queryParams).forEach((key) => {
      const value = parsedUrl.searchParams.get(key);

      if (value) {
        initialState[key as keyof Form] = value;
      }
    });
  } catch (e) {}

  return initialState;
}

export function hasUrlAnyUtmTag(url: string) {
  try {
    const parsedUrl = new URL(url);

    return Array.from(parsedUrl.searchParams.keys()).some(
      (key) => key.split('_')[0] === 'utm',
    );
  } catch (e) {}

  return false;
}
