const Insights = {
  DAY_CHART_FIRST_ITEMS_COUNT: 24,
  MAX_INSIGHTS_VISITS_SORT_DROPDOWN_OPEN: 5,
  AGGREGATED_METRICS_FIRST_COUNT: 10,
  GRAPH_TICK_MAX_CHARS: 8,
  FANS_LIMIT_FOR_METRICS_RESTRICTION: 100,
  COMPETITION_ANALYSIS_MAX_SELECTED_PAGES: 11,
  PAGE_STATISTICS_COLLAPSED_CARDS_COUNT: 4,
};

export default Insights;
