import { Locales } from 'app/config/locales';

const NumberUtil = {
  roundToDecimals(value: number | string, decimals: number = 2) {
    return parseFloat(value.toString()).toFixed(decimals);
  },
  format(
    number: number,
    options?: Intl.NumberFormatOptions & { locales?: string | string[] },
  ) {
    return number.toLocaleString(options?.locales, {
      maximumFractionDigits: 0,
      ...options,
    });
  },
  randomInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  },
  clamp(value: number, minValue: number, maxValue: number) {
    return Math.max(minValue, Math.min(value, maxValue));
  },
  compact(value: number) {
    return NumberUtil.format(value, {
      maximumFractionDigits: 2,
      notation: 'compact',
      compactDisplay: 'short',
      locales: Locales.EN_US,
    });
  },
};

export default NumberUtil;
