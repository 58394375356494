import { get, post } from 'api/client';
import config from 'config';
import AnalyticsJobsApi from 'api/analytics/jobs';
import { getInsightsPageTypeAlias } from 'app/modules/insights/utils/getInsightsPageTypeAlias';
import { Job, JobBatchStatus } from 'app/modules/insights/types/Job';

type UserStatusResponse = {
  isFirstTimeInAnalytics: boolean;
  hasFacebookConnected: boolean;
  hasLinkedInConnected: boolean;
};

type PageSyncParams = {
  pageType: number;
  id: string;
};

const AnalyticsApi = {
  async userStatus(): Promise<UserStatusResponse> {
    return get<UserStatusResponse>('api/user', {
      baseURL: config.ANALYTICS_ENDPOINT,
    });
  },
  runPagesLoad: (): Promise<Job[]> => {
    return get('api/pages', {
      baseURL: config.ANALYTICS_ENDPOINT,
    });
  },
  runPageSync(params: PageSyncParams): Promise<JobBatchStatus> {
    return post(
      `/api/${getInsightsPageTypeAlias(params.pageType)}/${params.id}/sync`,
      {},
      {
        baseURL: config.ANALYTICS_ENDPOINT,
      },
    );
  },
  async loadPages() {
    const jobsToBeDone = await AnalyticsApi.runPagesLoad();

    return AnalyticsJobsApi.waitUntilJobCompletion(jobsToBeDone);
  },
  pageSyncStatus(params: PageSyncParams): Promise<JobBatchStatus | null> {
    return get(`/api/${params.id}/sync-status`, {
      baseURL: config.ANALYTICS_ENDPOINT,
    });
  },
};

export default AnalyticsApi;
