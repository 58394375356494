import { faGift } from '@fortawesome/pro-regular-svg-icons';
import { Icon } from '@kontentino/ui';
import { FC } from 'react';
import VerticalNavigationMenuOption from '../VerticalNavigationMenuOption';
import Link from 'components/shared/Link';
import { modalRoutes } from 'constants/routes';
import { VerticalMenuItemWrapper } from '../VerticalMenuItemWrapper';
import { useTranslation } from 'react-i18next';

type Props = {};

const Referral: FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <Link
      to={{ hash: modalRoutes.REFERRAL_PROGRAM }}
      data-cy="vertical-navigation-link-referral-program"
    >
      <VerticalMenuItemWrapper>
        <VerticalNavigationMenuOption
          label={t('navigation.userSubActions.referralProgram')}
          icon={<Icon icon={faGift} />}
        />
      </VerticalMenuItemWrapper>
    </Link>
  );
};

export default Referral;
