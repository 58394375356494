import React, { FC } from 'react';
import Form from 'components/workflowOptions/sendForApproval/Form';
import { useQuery } from 'react-query';
import { queryKey } from 'constants/queryKey';
import PostTimeSaversApi from 'app/api/postTimeSavers';
import i18n from 'i18n';
import Logger from 'utils/logger';
import { ApiClientError } from 'api/client';
import { useToast } from 'app/hooks/useToast';

type Props = {
  type: ApprovalType;
  onSelectedUsersChange: (users: number[]) => void;
  initialSelectedUserIds: number[];
  formErrors: Record<string, string>;
  pageIds: number[];
  subject: string | null;
  bodyMessage: string | null;
  additionalEmails?: string;
  onSubjectChange: (value: string) => void;
  onBodyMessageChange: (value: string) => void;
  onAdditionalEmailsChange?: (value: string) => void;
  className?: string;
};

const SendForApprovalForm: FC<Props> = ({
  type,
  initialSelectedUserIds,
  formErrors,
  onSelectedUsersChange,
  pageIds,
  subject,
  bodyMessage,
  additionalEmails,
  onAdditionalEmailsChange,
  onSubjectChange,
  onBodyMessageChange,
  className,
}) => {
  const toast = useToast();

  const { data, isLoading } = useQuery(
    queryKey.approvalData(pageIds, type),
    () => PostTimeSaversApi.getApprovalData(pageIds, type),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      onError: (error: ApiClientError) => {
        toast(error?.userMessage ?? i18n.somethingWentWrongTryAgain, 'error');
        Logger.error(error, 'Cannot load post approval data');
      },
      onSuccess: (approvalData) => {
        if (!subject) {
          onSubjectChange(approvalData.emailSubject);
        }

        if (!bodyMessage) {
          onBodyMessageChange(approvalData.emailBody);
        }
      },
    },
  );

  return (
    <Form
      className={className}
      isLoading={isLoading}
      onSelectedUsersChange={onSelectedUsersChange}
      initialSelectedUserIds={initialSelectedUserIds}
      subject={subject ?? ''}
      bodyMessage={bodyMessage ?? ''}
      onSubjectChange={onSubjectChange}
      onBodyMessageChange={onBodyMessageChange}
      formErrors={formErrors}
      type={type}
      availableUsers={data?.users ?? []}
      additionalEmails={additionalEmails}
      onAdditionalEmailsChange={onAdditionalEmailsChange}
    />
  );
};

export default SendForApprovalForm;
