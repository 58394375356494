import React, { FC, useState } from 'react';
import { Badge, Tabs } from '@kontentino/ui';
import PageMetricCardCollapsedContent from 'app/modules/insights/modules/pagePerformance/components/pageMetricCard/PageMetricCardCollapsedContent';
import PageMetricCardContent from 'app/modules/insights/modules/pagePerformance/components/pageMetricCard/PageMetricCardContent';
import { useInsightsLiteQueries } from 'app/modules/insightsLite/hooks/useInsightsLiteQueries';
import StandardPostPreview from 'app/modules/insights/components/StandardPostPreview';
import { getMedalRankFromIndex } from 'app/modules/insights/utils/getMedalRankFromIndex';
import InsightsLiteRequestAccess from 'app/modules/insightsLite/components/InsightsLiteRequestAccess';
import { FB_METRICS_PROPERTIES } from 'app/modules/insights/modules/postsPerformance/utils/metricProperty';

type Props = {};

const InsightsLiteContent: FC<Props> = (props) => {
  const { data } = useInsightsLiteQueries();
  const [activeMetric, setActiveMetric] = useState(
    FB_METRICS_PROPERTIES.engagement.key,
  );

  return (
    <section className="tw-space-y-4">
      <h1 className="tw-mb-6 tw-flex tw-items-center tw-text-lg tw-text-grayscale-180">
        <span className="tw-mr-1 tw-font-semibold">Analytics</span>
        <Badge variant="gray">Lite</Badge>
      </h1>
      <div className="tw-grid tw-grid-cols-3 tw-gap-4">
        {data?.pagePerformance.map((metric, index) => (
          <PageMetricCardContent
            {...metric.card}
            key={`${metric.card.metric.key}-${index}`}
          >
            <PageMetricCardCollapsedContent {...metric.content} />
          </PageMetricCardContent>
        ))}
        <InsightsLiteRequestAccess />
      </div>
      <div className="tw-shadow-s tw-space-y-8 tw-rounded tw-bg-white tw-p-4 tw-pb-2">
        <h2 className="tw-mb-6 tw-text-base tw-font-semibold tw-text-grayscale-180">
          Top performing posts
        </h2>
        <Tabs.Root value={activeMetric}>
          <Tabs.List>
            <Tabs.Tab
              data-name={`insights-lite_tab-${FB_METRICS_PROPERTIES.engagement.key}`}
              value={FB_METRICS_PROPERTIES.engagement.key}
              onClick={() =>
                setActiveMetric(FB_METRICS_PROPERTIES.engagement.key)
              }
            >
              {FB_METRICS_PROPERTIES.engagement.title}
            </Tabs.Tab>
            <Tabs.Tab
              data-name={`insights-lite_tab-${FB_METRICS_PROPERTIES.postImpressions.key}`}
              value={FB_METRICS_PROPERTIES.postImpressions.key}
              onClick={() =>
                setActiveMetric(FB_METRICS_PROPERTIES.postImpressions.key)
              }
            >
              {FB_METRICS_PROPERTIES.postImpressions.title}
            </Tabs.Tab>
            <Tabs.Tab
              data-name={`insights-lite_tab-${FB_METRICS_PROPERTIES.reactions.key}`}
              value={FB_METRICS_PROPERTIES.reactions.key}
              onClick={() =>
                setActiveMetric(FB_METRICS_PROPERTIES.reactions.key)
              }
            >
              {FB_METRICS_PROPERTIES.reactions.title}
            </Tabs.Tab>
          </Tabs.List>
        </Tabs.Root>
        <div className="tw-flex tw-gap-4 tw-overflow-x-auto tw-pb-2">
          {data?.postsPerformance.map((postPreview, index) => (
            <StandardPostPreview
              key={postPreview.post.text.slice(0, 10)}
              allowVideoPlayback={false}
              medalRank={getMedalRankFromIndex(index)}
              classNames={{
                root: 'tw-h-[400px] tw-w-[300px] tw-min-w-[300px] tw-border tw-border-grayscale-20',
                content: 'tw-gap-2 tw-p-3',
                profileAvatar: '!tw-h-7 !tw-w-7',
                profilePageType: '!tw-h-4 !tw-w-4',
              }}
              {...postPreview}
              metric={{
                ...postPreview.metric,
                ...FB_METRICS_PROPERTIES[activeMetric],
                value: postPreview.metric!.value,
              }}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default InsightsLiteContent;
