import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import { ComponentProps } from 'react';
import PageMetricCardContent from 'app/modules/insights/modules/pagePerformance/components/pageMetricCard/PageMetricCardContent';
import PageMetricCardCollapsedContent from 'app/modules/insights/modules/pagePerformance/components/pageMetricCard/PageMetricCardCollapsedContent';
import StandardPostPreview from 'app/modules/insights/components/StandardPostPreview';
import { getMetricValue } from 'app/modules/insights/utils/getMetricValue';
import { FB_METRICS_PROPERTIES } from 'app/modules/insights/modules/postsPerformance/utils/metricProperty';
import { FB_PAGE_METRIC_CARD_PROPERTIES } from 'app/modules/insights/modules/pagePerformance/constants/pageMetricCardProperties';
import { MOCKED_IMAGES } from 'app/modules/insightsLite/utils/mockedImages';
import { formatCompactValue } from 'app/modules/insights/modules/pagePerformance/utils/format';

function getMetric(total: number) {
  return {
    ...FB_METRICS_PROPERTIES.totalReachUnique,
    value: getMetricValue(
      [
        {
          __typename: 'PostMetricType',
          id: 'cG9zdE1ldHJpYy0xMzk0MjM3',
          metricName: 'post_engagement',
          metricValue: total,
          additionalData: null,
        },
        {
          __typename: 'PostMetricType',
          id: 'cG9zdE1ldHJpYy0xMzk0MjM4',
          metricName: 'post_engagement_rate',
          metricValue: total,
          additionalData: null,
        },
        {
          __typename: 'PostMetricType',
          id: 'cG9zdE1ldHJpYy0xMzk0MjIz',
          metricName: 'post_impressions',
          metricValue: total,
          additionalData: null,
        },
        {
          __typename: 'PostMetricType',
          id: 'cG9zdE1ldHJpYy0xMzk0MjMy',
          metricName: 'post_reactions_by_type_total',
          metricValue: total,
          additionalData: null,
        },
        {
          __typename: 'PostMetricType',
          id: 'cG9zdE1ldHJpYy0xMzk0MjM5',
          metricName: 'post_total_reach',
          metricValue: total,
          additionalData: null,
        },
        {
          __typename: 'PostMetricType',
          id: 'cG9zdE1ldHJpYy0xMzk0MjM2',
          metricName: 'post_total_reach_unique',
          metricValue: total,
          additionalData: null,
        },
      ],
      FB_METRICS_PROPERTIES.totalReachUnique,
    ),
  };
}

export function useInsightsLiteQueries() {
  return useQuery('zss', () => {
    return {
      pagePerformance: [
        {
          card: {
            metric: {
              key: FB_PAGE_METRIC_CARD_PROPERTIES.reacch,
              title: 'pagePerformance.metricProperties.common.totalReach',
              queryParameter: 'page_unique_impressions',
            },
            expanded: false,
            isCombinedMetric: false,
          },
          content: {
            filterState: {
              startDate: dayjs(),
              endDate: dayjs(),
            },
            generalData: {
              difference: 35,
              label: 'Total',
              value: formatCompactValue('26400'),
            },
            graphData: {
              key: 'total',
              label: '',
              legendLabels: [''],
              graphValues: {
                variant: 'area',
                data: [
                  { value: 600, date: '2024-06-01' },
                  { value: 500, date: '2024-06-03' },
                  { value: 700, date: '2024-06-05' },
                  { value: 300, date: '2024-06-07' },
                  { value: 600, date: '2024-06-09' },
                  { value: 780, date: '2024-06-11' },
                  { value: 1100, date: '2024-06-13' },
                  { value: 830, date: '2024-06-15' },
                  { value: 600, date: '2024-06-17' },
                  { value: 900, date: '2024-06-19' },
                  { value: 800, date: '2024-06-21' },
                  { value: 1200, date: '2024-06-23' },
                  { value: 800, date: '2024-06-25' },
                  { value: 700, date: '2024-06-27' },
                  { value: 1300, date: '2024-06-29' },
                ],
              },
            },
            isCompared: false,
            combinedMetricComparedData: [],
            isCombinedMetric: false,
            title: 'pagePerformance.metricProperties.common.totalReach',
          },
        },
        {
          card: {
            metric: {
              key: 'page_unique_impressions',
              title: 'pagePerformance.metricProperties.common.impressions',
              queryParameter: 'page_unique_impressions',
            },
            expanded: false,
          },
          content: {
            filterState: {
              startDate: dayjs(),
              endDate: dayjs(),
              comparedStartDate: null,
              comparedEndDate: null,
            },
            generalData: {
              difference: 35,
              label: 'Total',
              value: formatCompactValue('9000'),
            },
            graphData: {
              key: 'total',
              label: '',
              legendLabels: [''],
              graphValues: {
                variant: 'area',
                data: [
                  { value: 700, date: '2024-06-01' },
                  { value: 900, date: '2024-06-03' },
                  { value: 850, date: '2024-06-05' },
                  { value: 1000, date: '2024-06-07' },
                  { value: 650, date: '2024-06-09' },
                  { value: 1100, date: '2024-06-11' },
                  { value: 600, date: '2024-06-13' },
                  { value: 800, date: '2024-06-15' },
                  { value: 1200, date: '2024-06-17' },
                  { value: 600, date: '2024-06-19' },
                  { value: 800, date: '2024-06-21' },
                  { value: 900, date: '2024-06-23' },
                  { value: 1000, date: '2024-06-25' },
                  { value: 800, date: '2024-06-27' },
                  { value: 1300, date: '2024-06-29' },
                ],
              },
            },

            isCompared: false,
            combinedMetricComparedData: [],
            isCombinedMetric: false,
            title: 'pagePerformance.metricProperties.common.impressions',
          },
        },
      ] as {
        card: Omit<ComponentProps<typeof PageMetricCardContent>, 'children'>;
        content: ComponentProps<typeof PageMetricCardCollapsedContent>;
      }[],
      // eslint-disable-next-line
      postsPerformance: [
        {
          post: {
            page: {
              name: 'Kontentino',
              type: 2,
              logo: 'https://cache-image.kontentinoservices.com/image?url=https%3A%2F%2Fmedia.licdn.com%2Fdms%2Fimage%2FD4E0BAQEOt-CKPZuN5w%2Fcompany-logo_400_400%2F0%2F1709555248120%2Fkontentino_logo%3Fe%3D1726099200%26v%3Dbeta%26t%3DbXGRafhYXH62X49DGxj59jFOdS1T6OuHzUw8CG_ISCg',
            },
            createdAt: '2024-06-05 08:00:17',
            text: "Happiness is a state of mind that arises not from external circumstances but from one's own perception and attitude towards life. It is cultivated through gratitude, positive thinking",
            type: 5,
            attachments: {
              cards: [
                {
                  medium: {
                    image: {
                      src: MOCKED_IMAGES['1'],
                    },
                  },
                },
              ],
            },
          },
          metric: getMetric(417),
        },
        {
          post: {
            page: {
              name: 'Kontentino',
              type: 2,
              logo: 'https://cache-image.kontentinoservices.com/image?url=https%3A%2F%2Fmedia.licdn.com%2Fdms%2Fimage%2FD4E0BAQEOt-CKPZuN5w%2Fcompany-logo_400_400%2F0%2F1709555248120%2Fkontentino_logo%3Fe%3D1726099200%26v%3Dbeta%26t%3DbXGRafhYXH62X49DGxj59jFOdS1T6OuHzUw8CG_ISCg',
            },
            createdAt: '2024-06-02 08:00:08',
            text: 'Climbing is the only activity that challenges both the body and mind to their fullest extent, requiring a unique combination of physical strength',
            type: 5,
            attachments: {
              cards: [
                {
                  medium: {
                    image: {
                      src: MOCKED_IMAGES['2'],
                    },
                  },
                },
              ],
            },
          },
          metric: getMetric(408),
        },
        {
          post: {
            page: {
              name: 'Kontentino',
              type: 2,
              logo: 'https://cache-image.kontentinoservices.com/image?url=https%3A%2F%2Fmedia.licdn.com%2Fdms%2Fimage%2FD4E0BAQEOt-CKPZuN5w%2Fcompany-logo_400_400%2F0%2F1709555248120%2Fkontentino_logo%3Fe%3D1726099200%26v%3Dbeta%26t%3DbXGRafhYXH62X49DGxj59jFOdS1T6OuHzUw8CG_ISCg',
            },
            createdAt: '2024-06-19 08:00:08',
            text: 'Breakfast is considered the most important meal of the day, as it provides the essential nutrients and energy needed to kickstart your metabolism and fuel your activities.',
            type: 4,
            attachments: {
              cards: [
                {
                  medium: {
                    image: {
                      src: MOCKED_IMAGES['3'],
                    },
                  },
                },
              ],
            },
          },
          metric: getMetric(205),
        },
        {
          post: {
            page: {
              name: 'Kontentino',
              type: 2,
              logo: 'https://cache-image.kontentinoservices.com/image?url=https%3A%2F%2Fmedia.licdn.com%2Fdms%2Fimage%2FD4E0BAQEOt-CKPZuN5w%2Fcompany-logo_400_400%2F0%2F1709555248120%2Fkontentino_logo%3Fe%3D1726099200%26v%3Dbeta%26t%3DbXGRafhYXH62X49DGxj59jFOdS1T6OuHzUw8CG_ISCg',
            },
            createdAt: '2024-06-25 08:00:08',
            text: 'Dogs are not only just loyal companions but also bring immense joy and comfort into our lives. They have an uncanny ability to sense our emotions,',
            type: 2,
            attachments: {
              cards: [
                {
                  medium: {
                    image: {
                      src: MOCKED_IMAGES['4'],
                    },
                  },
                },
              ],
            },
          },
          metric: getMetric(89),
        },
        {
          post: {
            page: {
              name: 'Kontentino',
              type: 2,
              logo: 'https://cache-image.kontentinoservices.com/image?url=https%3A%2F%2Fmedia.licdn.com%2Fdms%2Fimage%2FD4E0BAQEOt-CKPZuN5w%2Fcompany-logo_400_400%2F0%2F1709555248120%2Fkontentino_logo%3Fe%3D1726099200%26v%3Dbeta%26t%3DbXGRafhYXH62X49DGxj59jFOdS1T6OuHzUw8CG_ISCg',
            },
            createdAt: '2024-06-17 08:00:08',
            text: "There's something magical about stepping into the great outdoors and immersing yourself in nature's beauty. Hiking offers the perfect escape from the hustle and bustle of daily life, allowing you to reconnect with yourself and the world around you.",
            type: 5,
            attachments: {
              cards: [
                {
                  medium: {
                    image: {
                      src: MOCKED_IMAGES['5'],
                    },
                  },
                },
              ],
            },
          },
          metric: getMetric(56),
        },
        ,
      ] as ComponentProps<typeof StandardPostPreview>[],
    };
  });
}
