import React, { FC } from 'react';
import style from './linearToggleDivider/ToggleExpander.module.scss';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { Icon, IconButton } from '@kontentino/ui';
import classes from 'react-style-classes';

type Props = {
  onClick(): void;
  isExpanded: boolean;
  className?: string;
};

const LinearToggleDivider: FC<Props> = (props) => {
  return (
    <div className={classes(style.linearToggleDivider, props.className)}>
      <IconButton
        variant="secondary"
        onClick={props.onClick}
        data-name={`insights_posts-performance_statistics_show-${
          props.isExpanded ? 'less' : 'more'
        }`}
        icon={<Icon icon={props.isExpanded ? faChevronUp : faChevronDown} />}
      />
    </div>
  );
};

export default LinearToggleDivider;
