import React, { FC } from 'react';
import { faArrowDownToLine, faFile } from '@fortawesome/pro-regular-svg-icons';
import { DropdownMenu, Icon, IconButton } from '@kontentino/ui';
import { useMutation } from 'react-query';
import AnalyticsExportsApi from 'api/analytics/exports';
import { CompetitionAnalysisPage } from 'app/modules/insights/modules/competitionAnalysis/types/page';
import { Page } from 'app/modules/insights/types/Page';

type Props = {
  showReactions: boolean;
  selectedPages: CompetitionAnalysisPage[];
  startDate: string;
  endDate: string;
  page: Page;
};

const ExportAsDropdown: FC<Props> = ({
  page,
  startDate,
  endDate,
  showReactions,
  selectedPages,
}) => {
  const { mutate } = useMutation(() => {
    if (page) {
      return AnalyticsExportsApi.exportPDFCompetitionAnalysis({
        startDate,
        endDate,
        page,
        showReactions,
        comparedPageIds: selectedPages.map((page) => page._id),
      });
    }

    return Promise.reject();
  });

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <IconButton
          icon={<Icon icon={faArrowDownToLine} />}
          variant="secondary"
          className="tw-h-[36px]"
          disabled={!page}
          data-name="insights-competition-analysis-download"
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className="tw-z-10">
        <DropdownMenu.Item
          className="tw-capitalize"
          onSelect={() => mutate()}
          data-name="insights_competition-analysis_download_pdf"
        >
          <Icon icon={faFile} />
          PDF
        </DropdownMenu.Item>
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

export default ExportAsDropdown;
